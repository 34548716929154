import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { customDownloader } from '../../../utils/downloaderFiles';
import { notificationDanger, notificationSuccess } from '../../../utils/toastify';

const getJournalEntryTableData = (filter) => {
  delete filter.document_type;
  return axios.get(`/me/journal-entry`, { params: { ...filter } });
};

const getTransactionsByClientId = (filter) => {
  return axios.get(`/me/transaction/${filter}`);
};

const createJournalEntry = (data) => {
  return axios.post('/me/journal-entry', data);
};

export const useGetJournalEntryTableData = (filter) => {
  return useQuery(
    ['journal-entry-table', filter.page, filter.pageSize, filter.sort_by, filter.order_by, filter.full_ledgers],
    () => getJournalEntryTableData(filter),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetTransactionsByClientId = (clientId) => {
  return useQuery(['transactions-by-client-id', clientId], () => getTransactionsByClientId(clientId), {
    enabled: !!clientId,
    refetchOnWindowFocus: true,
  });
};

export const useCreateJournalEntry = (successCb) => {
  const queryClient = useQueryClient();
  return useMutation(createJournalEntry, {
    onSuccess: () => {
      queryClient.invalidateQueries(['journal-entry-table']);
      successCb && successCb();
    },
    onError: (err) => {
      console.log(err);
      if (err?.response?.status === 422) {
        notificationDanger(err.response.data.error);
      }
    },
  });
};

const uploadJournalEntries = (data) => {
  return axios.post(`/me/journal-entry/upload-journal-entries`, data.formData);
};

export const useUploadJournalEntryMutation = (errorCb, successCb) => {
  const queryClient = useQueryClient();
  return useMutation(uploadJournalEntries, {
    onSuccess: () => {
      queryClient.invalidateQueries(['journal-entry-table']);
      successCb();
    },
    onError: (error) => {
      if (error.response.status === 422) {
        errorCb(error?.response?.data?.error);
      }
    },
  });
};

const exampleUploadExcelDownload = () => {
  return customDownloader({ url: '/me/journal-entry/example-file', fileType: 'EXCEL' });
};

export const useExampleUploadExcelDownload = () => {
  return useMutation(exampleUploadExcelDownload);
};

//Options for created by
const selectCreatedBy = () => {
  return axios.get(`/me/journal-entry/options-for-created-by`);
};

export const useSelectCreatedBy = () => {
  return useQuery(['users-created-by'], () => selectCreatedBy(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const saveAsDraft = (data) => {
  return axios.post(`/me/journal-entry/save-journal-entries-drafts`, data);
};

export const useSaveAsDraft = (errorCb) => {
  const queryClient = useQueryClient();
  return useMutation(saveAsDraft, {
    onSuccess: () => {
      queryClient.invalidateQueries(['journal-entry-drafts']);
      notificationSuccess('Draft Saved!');
    },
    onError: (error) => {
      if (error.response.status === 422) {
        errorCb(error?.response?.data?.error);
      }
    },
  });
};

const getJournalEntriesDrafts = async (filter) => {
  const response = await axios.get(`/me/journal-entry/get-journal-entries-drafts`, { params: { ...filter } });

  return response.data;
};

export const useGetJournalEntriesDrafts = (filter) => {
  return useQuery(['journal-entry-drafts', filter.page, filter.pageSize, filter.sort_by, filter.order_by], () => getJournalEntriesDrafts(filter), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const deleteDraft = (id) => {
  return axios.delete(`/me/journal-entry/delete-journal-entries-draft/${id}`);
};

export const useDeleteDraft = (errorCb) => {
  const queryClient = useQueryClient();
  return useMutation(deleteDraft, {
    onSuccess: () => {
      queryClient.invalidateQueries(['journal-entry-drafts']);
      notificationSuccess('Draft Deleted!');
    },
    onError: (error) => {
      if (error.response.status === 422) {
        errorCb(error?.response?.data?.error);
      }
    },
  });
};

const duplicateDraft = (id) => {
  return axios.post(`/me/journal-entry/duplicate-journal-entries-draft/${id}`);
};

export const useDuplicateDraft = (errorCb) => {
  const queryClient = useQueryClient();
  return useMutation(duplicateDraft, {
    onSuccess: () => {
      queryClient.invalidateQueries(['journal-entry-drafts']);
      notificationSuccess('Draft Duplicated!');
    },
    onError: (error) => {
      if (error.response.status === 422) {
        errorCb(error?.response?.data?.error);
      }
    },
  });
};

const updateDraft = (data) => {
  return axios.patch(`/me/journal-entry/update-journal-entries-drafts`, data);
};

export const useUpdateDraft = (errorCb) => {
  const queryClient = useQueryClient();
  return useMutation(updateDraft, {
    onSuccess: () => {
      queryClient.invalidateQueries(['journal-entry-drafts']);
      notificationSuccess('Draft Updated!');
    },
    onError: (error) => {
      if (error.response.status === 422) {
        errorCb(error?.response?.data?.error);
      }
    },
  });
};
