export const CUSTOMER_TYPE_STRING = 'customers';
export const VENDOR_TYPE_STRING = 'vendors';

export const CUSTOMER_CLIENT = 1;
export const VENDOR_CLIENT = 2;

const map = new Map();

map.set(CUSTOMER_TYPE_STRING, CUSTOMER_CLIENT);
map.set(VENDOR_TYPE_STRING, VENDOR_CLIENT);

export const getClientTypeFromName = (name) => {
  const clientType = map.get(name);

  return clientType || -1;
};
