import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import { List, ListItem, ListItemButton, ListItemText, Divider, useMediaQuery, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useTheme } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { logoutUser } from '../config/auth/auth';
import logo from '../assets/logo/logo.png';
import { CustomPageableAsyncSelect } from '../common/Inputs/CustomPageableAsyncSelect';
import { useAsyncPageable } from '../hooks/useAsyncPageable';
import ClientSelectBox from '../component/SelectBox/ClientSelectBox';
import { ChangePassword } from '../app/view/Settings/User/component/ChangePassword';
import { useCustomModalToggle } from '../common/Dialog/CustomModal';

const LogoContainer = styled(Box)(({ theme }) => ({
  height: theme.options.appBar.height,
  fontSize: '24px',
  color: theme.palette.common.white,
  background: theme.palette.appBar.dark,
  // backgroundColor: '#fff',
  // boxShadow: '0 -1px 0 #404854 inset',
  padding: theme.spacing(1),
  cursor: 'pointer',
  // width: '250px',
}));

const LogoImg = styled('img')(() => ({
  display: 'block',
  width: '170px',
  height: '2rem',
  marginTop: '3px',
}));

export const Header = ({ onDrawerToggle, isSidebarOpen, customStyles }) => {
  const { isAuthenticated } = useSelector((state) => ({ isAuthenticated: state.auth.isAuthenticated }));
  const { userId } = useSelector((state) => ({ userId: state.auth.user.user_id }));
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();
  const screenSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const [open, setOpen] = React.useState(false);
  const { open: openPasswordModal, toggleCustomModal } = useCustomModalToggle();

  useEffect(() => {
    if (!isAuthenticated) {
      history.push('/login');
    }
  }, [isAuthenticated, history]);

  const onLogoutClick = () => {
    dispatch(logoutUser());
    history.push('/login');
  };

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const onClientSelect = (e) => {
    const type = e.type.toString() === '1' ? 'customers' : 'vendors';
    history.push('/client/' + type + '/360/' + e.value + '/');
  };

  return (
    <React.Fragment>
      <AppBar
        color='primary'
        enableColorOnDark
        elevation={5}
        sx={{
          zIndex: '110',
          position: 'fixed',
          paddingRight: '0px',
          // paddingLeft: '250px',
          right: 0,
          top: 0,
          height: (theme) => theme.options.appBar.height,
          justifyContent: 'center',
          backgroundColor: theme.palette.appBar.main,
          marginLeft: isSidebarOpen ? '250px' : '0px',
        }}
      >
        <Toolbar>
          <Grid container spacing={1} alignItems='center'>
            <Grid item>
              <LogoContainer sx={{ ...customStyles }}>
                <LogoImg src={logo} />
              </LogoContainer>
            </Grid>
            {/* <Hidden smUp> */}
            <Grid item>
              <IconButton onClick={onDrawerToggle}>
                <MenuIcon sx={{ fill: 'rgba(0,0,0,.7)' }} />
              </IconButton>
            </Grid>
            <Grid item xs />
            {/* <Grid item sx={{ display: isSidebarOpen && !screenSmUp ? 'none' : 'block' }}>
              <ThemeChanger />
            </Grid> */}
            {/* <Grid item>
              <Tooltip title='Alerts • No alerts'>
                <IconButton color='inherit'>
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
            <Grid item sx={{ minWidth: '280px' }}>
              <ClientSelectBox name='client' field='client' onSelectSearch={onClientSelect} isHeader={true} />
            </Grid>
            <Grid item xs />

            <Grid item sx={{ alignItems: 'center', display: isSidebarOpen && !screenSmUp ? 'none' : 'flex' }}>
              <ClickAwayListener mouseEvent='onMouseDown' touchEvent='onTouchStart' onClickAway={handleClickAway}>
                <Box sx={{ position: 'relative' }}>
                  <IconButton sx={{ padding: 1 }} onClick={handleClick}>
                    <AccountCircleIcon sx={{ fontSize: 40, fill: 'rgba(0,0,0,.7)' }} />
                  </IconButton>
                  {open && (
                    <Box
                      sx={{
                        position: 'absolute',
                        width: '200px',
                        top: 55,
                        right: 0,
                        left: -150,
                        zIndex: 1,
                        border: `1px solid ${theme.palette.primary.dark}`,
                        borderRadius: '5px',
                        p: 1,
                        background: theme.palette.background.paper,
                        color: theme.mode === 'dark' ? theme.palette.grey[50] : theme.palette.grey[900],
                      }}
                    >
                      <List>
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => {
                              toggleCustomModal();
                            }}
                          >
                            <ListItemText primary='Change Password' />
                          </ListItemButton>
                        </ListItem>
                        <Divider />
                        <ListItem disablePadding onClick={onLogoutClick}>
                          <ListItemButton>
                            <ListItemText primary='Log Out' />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Box>
                  )}
                </Box>
              </ClickAwayListener>
              {/* {screenSmUp && (
                <Typography variant='body2' ml={1}>
                  {user.name}
                </Typography>
              )} */}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <ChangePassword isAdmin={false} open={openPasswordModal} toggleCustomModal={toggleCustomModal} userId={userId} />
    </React.Fragment>
  );
};

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
  isSidebarOpen: PropTypes.bool.isRequired,
};
