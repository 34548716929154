import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';

const defaultPaginationOptions = {
  page: 0,
  pageSize: 50,
};

const baseInputStyles = {
  container: (provided) => {
    return {
      ...provided,
      flex: 1,
    };
  },
};

/**
 * @description use this when you need to load and display value from db
 * Async Select With Infinite Scrolling Component
 * @param {function} loadOptions - @description loadOptions(search:string, pagination:{ page: number, pageSize: number })  => loadOptions returns { options: { value: any, label: string }[], hasMore: bool }
 */
const CustomPageableAsyncSelect = ({
  defKey,
  initFilters,
  label,
  loadOptions,
  onInputChange,
  onChange,
  name,
  formClassName,
  styles,
  className,
  placeholder,
  loadingMessage,
  noOptionsMessage,
  paginationOptions,
  prependAddon,
  initValue,
  errorMess,
  classNamePrefix,
}) => {
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    return () => {
      setSelectedValue(null);
    };
  }, []);

  const loadPageOptions = async (searchBy, _, { page }) => {
    const { options, hasMore } = await loadOptions(searchBy || initValue, {
      page,
      pageSize: paginationOptions.pageSize,
      ...initFilters,
    });

    if (initValue) {
      setSelectedValue(options.find((o) => o.value == initValue));
    } else {
      setSelectedValue(null);
    }

    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <>
      <FormGroup className={formClassName}>
        {label && <Label>{label}</Label>}
        <InputGroup style={{ display: 'flex', flexDirection: 'row' }}>
          {prependAddon && (
            <InputGroupAddon addonType='prepend'>
              <InputGroupText style={{ padding: '0 5px' }}>{prependAddon}</InputGroupText>
            </InputGroupAddon>
          )}
          <AsyncPaginate
            key={`${JSON.stringify(loadOptions)}-${initValue}-${defKey}`}
            menuPosition={'fixed'}
            cacheOptions
            defaultOptions
            loadOptionsOnMenuOpen
            isClearable
            additional={defaultPaginationOptions}
            name={name}
            value={selectedValue}
            loadOptions={loadPageOptions}
            onChange={(s, e) => {
              setSelectedValue({ label: s?.label, value: s?.value || s?.id });
              onChange(s, e);
            }}
            onInputChange={onInputChange}
            loadingMessage={() => loadingMessage}
            noOptionsMessage={() => noOptionsMessage}
            placeholder={placeholder}
            styles={{ ...styles, ...baseInputStyles }}
            className={className}
            classNamePrefix={classNamePrefix || ''}
          />
        </InputGroup>
        <small className='text-danger'>{errorMess}</small>
      </FormGroup>
    </>
  );
};

CustomPageableAsyncSelect.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  loadOptions: PropTypes.func.isRequired,
  name: PropTypes.string,
  formClassName: PropTypes.string,
  styles: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  loadingMessage: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  paginationOptions: PropTypes.shape({ page: PropTypes.number, pageSize: PropTypes.number }),
  prependAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Element)]),
  initFilters: PropTypes.object,
};

CustomPageableAsyncSelect.defaultProps = {
  paginationOptions: defaultPaginationOptions,
  loadingMessage: 'Loading...',
  noOptionsMessage: 'No results found',
  prependAddon: '',
  initFilters: {},
};

export { CustomPageableAsyncSelect };
