import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Checkbox } from '@mui/material';
import { Label } from 'reactstrap';

import { CustomModal } from '../../../../../common/Dialog/CustomModal';
import { useUpdateUser, useCreateUser, useGetUser } from '../apiHooks';
import { CustomInput } from '../../../../../common/CustomInput';
import { CustomSelect } from '../../../../../common/CustomSelect';
import { useGetRolesFroSelect } from '../../Roles/apiHooks';

export const AddEditComponent = ({ action, id, open, toggleCustomModal }) => {
  const [data, setData] = React.useState({
    is_active: true,
    role_id: null,
  });
  const [errors, setErrors] = React.useState({});

  const cleanUp = (err) => {
    if (err) {
      setErrors(err);
    } else {
      setData({
        is_active: true,
        role_id: null,
      });
      toggleCustomModal();
    }
  };

  const { mutate: createMutation } = useCreateUser(cleanUp);
  const { mutate: updateMutation } = useUpdateUser(cleanUp);
  const { data: userData } = useGetUser(id);
  const { data: rolesForSelect } = useGetRolesFroSelect();

  useEffect(() => {
    if (userData?.data && action !== 'create') {
      setData({
        name: userData.data.name,
        email: userData.data.email,
        is_active: userData.data.is_active,
        role_id: userData.data.role_id,
      });
    } else {
      setData({ is_active: true, role_id: null });
    }
  }, [open, userData?.data]);

  const handleChangeInput = (e) => {
    const { value, name } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeCheckbox = (e) => {
    const { checked, name } = e.target;
    setData((prev) => ({ ...prev, [name]: checked }));
  };

  const handleChangeSelect = (val, e) => {
    setData((oldFilter) => ({ ...oldFilter, role_id: val?.value ? val.value : null }));
  };

  const onSave = () => {
    if (action === 'create') {
      createMutation(data);
    } else {
      updateMutation({ id, ...data });
    }
  };

  return (
    <CustomModal
      modalTitle={action === 'create' ? 'Create User' : 'Update User'}
      isOpen={open}
      toggleCustomModal={() => {
        setData({
          is_active: true,
          role_id: null,
        });
        toggleCustomModal();
      }}
      onSave={onSave}
    >
      <Row>
        <Col md='12'>
          <CustomInput value={data.name} onChange={handleChangeInput} name='name' label='User Name' placeholder='User Name' errorMess={errors.name} />
        </Col>
        <Col md='12'>
          <CustomInput
            value={data.email}
            onChange={handleChangeInput}
            name='email'
            label='User Email'
            placeholder='User Email'
            errorMess={errors.email}
            autoComplete={'off'}
          />
        </Col>
        {action === 'create' && (
          <Col md='12'>
            <CustomInput
              value={data.password}
              onChange={handleChangeInput}
              name='password'
              label='User Password'
              placeholder='User Password'
              type='password'
              errorMess={errors.password}
              autoComplete={'new-password'}
            />
          </Col>
        )}
        <Col md='12'>
          <CustomSelect
            defaultValue={data.role_id}
            options={rolesForSelect?.data || []}
            onChange={handleChangeSelect}
            name='role_id'
            label='Role'
            placeholder='Select Role...'
            errorMess={errors.role_id}
          />
        </Col>
        <Col md='12'>
          <Label>Is Active</Label>
          <Checkbox checked={!!data.is_active} onChange={handleChangeCheckbox} name='is_active' color='primary' />
        </Col>
      </Row>
    </CustomModal>
  );
};
