import { useState } from 'react';
import ReactTable from 'react-table';
import { Col, Row } from 'reactstrap';
import { CustomDialog } from '../../../../../common/Dialog/CustomDialog';
import DataTablePagination from '../../../../../component/DataTable/component/DataTablePagination';
import { formatAmount } from '../../../../../utils/formatAmout';
import { JournalEntryTable } from '../../../JournalEntry/JournalEntryTable';
import { columns } from '../columns';
import './../styles.scss';

export const ReportTable = ({ data = {}, series = [], filter = {}, onFilterChange }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitleData, setModalTitleData] = useState({});
  const [account, setAccount] = useState(null);

  const onFilterPageChange = (e) => {
    const f = { page: e };
    onFilterChange(f);
  };

  const onFilterPageSizeChange = (e) => {
    const f = { pageSize: e };
    onFilterChange(f);
  };
  const onSortChange = (e) => {
    const column = e[0].id;
    const method = e[0].desc ? 'DESC' : 'ASC';
    const f = { sort_by: column, order_by: method };
    onFilterChange(f);
  };

  const onAccountClick = (accountNumber) => {
    setAccount(accountNumber);
    setIsModalOpen(true);
  };

  const onColumnClick = (row, column) => {
    const { isAccountNumberColumn } = column;

    if (isAccountNumberColumn) {
      if (row?.original?.number) {
        setModalTitleData(row.original);
        onAccountClick(row.original.number);
      }
    }
  };

  return (
    <>
      <CustomDialog
        isOpen={isModalOpen}
        actionButtonDisabled
        disableEscapeKeyDown
        disableBackdropClick
        hideActions
        hideTitleButton
        modalSize={'xl'}
        modalTitle={<ModalTitle data={modalTitleData} />}
        onClose={() => setIsModalOpen(false)}
      >
        <Row>
          <Col>
            <JournalEntryTable
              account={account}
              modal
              displayFullLedgers
              initialFilters={{
                ...filter,
                postingDate: { from: filter.start ?? '', to: filter.end ?? '' },
              }}
            />
          </Col>
        </Row>
      </CustomDialog>
      <div>
        <ReactTable
          data={data}
          columns={columns({ series, options: { percentageIncluded: filter.percentageIncluded } })}
          showPaginationBottom={false}
          // PaginationComponent={DataTablePagination}
          className={`-striped -highlight`}
          getFooterProps={() => ({ style: { display: 'block' } })}
          minRows={1}
          NoDataComponent={() => null}
          // onPageSizeChange={onFilterPageSizeChange}
          // onPageChange={onFilterPageChange}
          onSortedChange={onSortChange}
          pageSize={1000}
          // page={filter.page}
          getTdProps={(_, rowInfo, column) => {
            return {
              onClick: () => {
                const columnHeader = column.Header?.props?.children || '';
                const id = column.id !== 'number' ? column.id : null;
                const isAccountNumberColumn = column.id === 'number';
                onColumnClick(rowInfo, { header: columnHeader, id, isAccountNumberColumn });
              },
            };
          }}
        />
      </div>
    </>
  );
};

const ModalTitle = ({ data = {} }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span>
        {data.number} - {data.name}
      </span>
      <span style={{ marginRight: '3rem' }}>{formatAmount(data.total_sum)}</span>
    </div>
  );
};
