import { DeleteForever } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, CircularProgress, DialogActions, DialogContent, Divider, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { CustomCard } from '../../../../../../common/Card/CustomCard';
import { CustomInput } from '../../../../../../common/CustomInput';
import { useCustomModalToggle } from '../../../../../../common/Dialog/CustomDialog';
import { CustomModal } from '../../../../../../common/Dialog/CustomModal';
import { CustomCurrencyInput } from '../../../../../../common/Inputs/CurrencyInput';
import { Loader } from '../../../../../../common/Loader';
import { CustomPopup } from '../../../../../../common/Popup/CustomPopup';
import { notificationDanger } from '../../../../../../utils/toastify';
import { useBillingPaymentItem, useBillingTransactionClearing, useCancelBillingTransactionClearing } from '../../hooks';
import { UnpaidClientTransactions } from '../clients/components/UnpaidClientTransactions';
import { roundNumber } from '../../../../../../utils/formatAmout';

export const BillingPaymentItemClearing = ({}) => {
  const { clientType, billingId, billingItemId } = useParams();
  const history = useHistory();

  const { open, toggleCustomModal } = useCustomModalToggle();

  const [clearingData, setClearingData] = useState({
    amount: null,
    documentNumber: null,
    transactions_due_date_id: null,
    transaction_id: null,
    transactions_due_date_balance: null,
    realAmount: null,
    transactionType: null,
  });

  const [cancelData, setCancelData] = useState({
    isModalOpen: false,
    modalAnchor: null,
  });

  const { data: billingPaymentItemData } = useBillingPaymentItem(clientType, billingItemId, billingId);
  const { mutate: clearTransaction, isLoading } = useBillingTransactionClearing({
    clientType,
    successCallback: toggleCustomModal,
  });

  const { mutate: cancelTransactionClearing } = useCancelBillingTransactionClearing({ clientType });

  const handleCurrencyInputChange = (value) => {
    let realValue = value;

    if ((+value > 0 && +clearingData.maxAmount < 0) || (+value < 0 && +clearingData.maxAmount > 0)) {
      realValue = 0;
    }

    if (Math.abs(+value) > Math.abs(+clearingData.maxAmount)) {
      realValue = +clearingData.maxAmount;
    }

    setClearingData((oldData) => ({ ...oldData, amount: realValue, realAmount: oldData.transactionType === 'Payment' ? -1 * realValue : realValue }));
  };

  const onSaveBalance = () => {
    if (+clearingData.amount === 0) return notificationDanger('Applying Amount must me different than 0!');
    if (Math.abs(+clearingData.amount) > Math.abs(+clearingData.maxAmount)) {
      return notificationDanger(
        `Applying Amount is to high, please reduce it! Remaining Balance for deposit item is ${+clearingData.maxAmount.toFixed(2)}`
      );
    }

    const data = {
      billingId,
      billingItemId,
      date_applied: clearingData.date_applied,
      amount: clearingData.realAmount,
      transactions_due_date_id: clearingData.transactions_due_date_id,
      transaction_id: clearingData.transaction_id,
      transactions_due_date_balance: clearingData.transactions_due_date_balance,
    };
    clearTransaction(data);
  };

  const onClearClick = (row) => {
    const amount = roundNumber(row['transactions_due_date.balance'] - row['total_clear_balance']);

    setClearingData((oldData) => ({
      ...oldData,
      maxAmount: amount,
      amount,
      realAmount: amount,
      documentNumber: row['transactions.document_number'],
      date_applied: billingPaymentItemData?.data?.posting_date,
      transactions_due_date_id: row['transactions_due_date.id'],
      transaction_id: row['transactions.id'],
      transactions_due_date_balance: row['transactions_due_date.balance'],
      alreadyAppliedAmount: row['billing_payment_clearing_invoice.amount'],
      transactionType: row.transaction_type,
    }));

    toggleCustomModal();
  };

  const toggleCancelModal = (e, row) => {
    if (!e) {
      setCancelData({ isModalOpen: false, modalAnchor: null, transaction_due_date_id: null });
    } else {
      setCancelData({ isModalOpen: true, modalAnchor: e.currentTarget, transactions_due_date_id: row['transactions_due_date.id'] });
    }
  };

  const onCancelClearing = () => {
    cancelTransactionClearing({ billingId, billingItemId, transactions_due_date_id: cancelData.transactions_due_date_id });
    toggleCancelModal(false);
  };

  const handleBackClick = () => {
    history.push(`/client/${clientType}/payment/${billingId}`);
  };

  if (!billingPaymentItemData?.data) return <CircularProgress />;

  return (
    <>
      <Row>
        <Col>
          <Button className='btn btn-sm' onClick={handleBackClick}>
            <i className='fas fa-angle-left mr-2'></i>Go Back To Payments View
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <CustomCard headerTitle={'Billing Item Info'} sxCardContainer={{ my: 2 }}>
            <Row>
              <Col md='3'>
                <CustomInput
                  name='account_type'
                  value={billingPaymentItemData?.data?.account_type_info?.name}
                  onChange={() => {}}
                  label='Account Type'
                  placeholder='Account Type'
                />
              </Col>
              <Col md='6'>
                <CustomInput
                  name='account'
                  value={billingPaymentItemData?.data?.account?.number + ' - ' + billingPaymentItemData?.data?.account?.name}
                  onChange={() => {}}
                  label='Account'
                  placeholder='Account'
                />
              </Col>
            </Row>
            <Divider sx={{ my: 2 }} />
            <Row>
              <Col md='3'>
                <CustomCurrencyInput
                  name='amount'
                  value={billingPaymentItemData?.data?.amount}
                  onChange={() => {}}
                  prefix={'$ '}
                  label='Credit Amount'
                  placeholder='Credit Amount'
                />
              </Col>
              <Col md='3'>
                <CustomCurrencyInput
                  name='charge'
                  value={billingPaymentItemData?.data?.balance}
                  onChange={() => {}}
                  prefix={'$ '}
                  label='Applied Amount'
                  placeholder='Charge'
                />
              </Col>
              <Col md='3'>
                <CustomCurrencyInput
                  name='remaining_balance'
                  value={+(billingPaymentItemData?.data?.amount - billingPaymentItemData?.data?.balance).toFixed(2)}
                  onChange={() => {}}
                  prefix={'$ '}
                  label='Remaining Balance'
                  placeholder='Remaining Balance'
                />
              </Col>
            </Row>
          </CustomCard>

          <CustomPopup
            anchor={cancelData.modalAnchor}
            content={
              <>
                <CancelClearingPopupContent onConfirm={onCancelClearing} onCancel={toggleCancelModal} />
              </>
            }
            arrow
            placement='bottom'
            onClose={() => toggleCancelModal(false)}
            open={cancelData.isModalOpen}
          />
          <CustomModal
            modalTitle='Add Balance'
            modalSize='sm'
            buttonTitle='Add Balance'
            buttonClassName='btn-success'
            saveButtonTitle='Save'
            onSave={onSaveBalance}
            toggleCustomModal={toggleCustomModal}
            isOpen={open}
          >
            <Row>
              {isLoading && <Loader isFullScreen />}
              <Col md='6'>
                <CustomCurrencyInput
                  name='amount'
                  value={clearingData.amount}
                  onChange={handleCurrencyInputChange}
                  prefix={'$ '}
                  label='Applying Amount'
                  placeholder='Applying Amount'
                />
              </Col>
              <Col md='6'>
                <CustomInput
                  name='document_number'
                  value={clearingData.documentNumber}
                  onChange={() => {}}
                  label='Document Number'
                  placeholder='Document Number'
                  disabled
                />
              </Col>
            </Row>
          </CustomModal>
        </Col>
      </Row>
      <Row>
        <Col>
          <UnpaidClientTransactions
            billingPaymentItem={billingPaymentItemData?.data}
            clientId={billingPaymentItemData?.data['client_id']}
            onClearClick={onClearClick}
            onCancelClearClick={toggleCancelModal}
          />
        </Col>
      </Row>
    </>
  );
};

const CancelClearingPopupContent = ({ onConfirm, onCancel, message = `Are you sure you wish to cancel application for this transaction?` }) => {
  return (
    <>
      <Box>
        <DialogContent sx={{ padding: '5px' }}>{message}</DialogContent>
        <DialogActions sx={{ padding: 0 }}>
          <IconButton>
            <DeleteForever color='error' fontSize='small' onClick={onConfirm} />
          </IconButton>
          <IconButton onClick={() => onCancel(false)}>
            <ClearIcon />
          </IconButton>
        </DialogActions>
      </Box>
    </>
  );
};
