import React, { useState } from 'react';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { hasPermissionHook } from '../../../../../../hooks/hasPermission';
import { ALL_TRANSACTIONS, EDIT, CUSTOMERS, VENDORS } from '../../../../../../constants/permissions.constants';
import axios from 'axios';
import { notificationSuccess } from '../../../../../../utils/toastify';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export const EditDocumentNumberAction = ({ documentNumber, clientId, transactionsId, filter, refetch }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState(documentNumber);
  const [isValid, setIsValid] = useState(true);

  const location = useLocation();
  const { hasPermission } = hasPermissionHook();

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setIsValid(true);
  };

  const handleSave = async () => {
    const response = await axios.get(`/me/journal-entry/check-document-number`, { params: { documentNumber: inputValue, clientId, transactionsId } });
    setIsValid(response.data);

    if (response.data) {
      refetch(filter);

      handleCloseModal();
      notificationSuccess('Document Number changed successfully!');
    }
  };

  const showInAllTransactions = location.pathname === '/transactions/all' && hasPermission(ALL_TRANSACTIONS, EDIT);
  const showInCustomers = location.pathname.includes('/client/customers/') && hasPermission(CUSTOMERS, EDIT);
  const showInVendors = location.pathname.includes('/client/vendors/') && hasPermission(VENDORS, EDIT);

  return (
    <>
      {(showInAllTransactions || showInCustomers || showInVendors) && (
        <Tooltip title='Edit Document Number' placement='top' arrow followCursor>
          <IconButton onClick={handleOpenModal}>
            <ModeEditIcon />
          </IconButton>
        </Tooltip>
      )}

      <Modal open={isModalOpen} onClose={handleCloseModal} aria-labelledby='edit-document-modal' aria-describedby='edit-document-modal-description'>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
          }}
        >
          <TextField
            autoFocus
            label='Document Number'
            variant='outlined'
            fullWidth
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            error={!isValid}
            helperText={isValid ? '' : '*Document number is already in use, please choose a different one.'}
          />
          <Box display='flex' justifyContent='space-between' mt={2}>
            <Button variant='contained' color='error' onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant='contained' onClick={handleSave}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
