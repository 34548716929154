import React, { useEffect } from 'react';
import { Row, Col, Label } from 'reactstrap';
import { Chip, Paper, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CustomModal } from '../../../../../common/Dialog/CustomModal';
import { useUpdateDisablePosting, useCreateDisablePosting } from '../apiHooks';
import { CustomInput } from '../../../../../common/CustomInput';
import { DatePickerComponent } from '../../../../../common/DatePicker/DatePicker';
import { CustomCheckbox } from '../../../../../common/Inputs/CustomCheckbox';
import { notificationDanger } from '../../../../../utils/toastify';

export const AddEditComponent = ({ action, id, open, toggleCustomModal, disablePostingData }) => {
  const [data, setData] = React.useState({
    emails: [],
    email: '',
    name: '',
    date_from: null,
    date_to: null,
    repeatable: false,
  });

  const cleanUp = React.useCallback(() => {
    setData({
      emails: [],
      email: '',
      name: '',
      date_from: null,
      date_to: null,
      repeatable: false,
    });
    toggleCustomModal();
  }, [toggleCustomModal]);

  const { mutate: createMutation } = useCreateDisablePosting(cleanUp);
  const { mutate: updateMutation } = useUpdateDisablePosting(cleanUp);

  useEffect(() => {
    if (open) {
      if (disablePostingData && Object.keys(disablePostingData).length && action !== 'create') {
        setData(disablePostingData);
      } else {
        setData({
          emails: [],
          email: '',
          name: '',
          date_from: null,
          date_to: null,
          repeatable: false,
        });
      }
    }
  }, [open]);

  const handleChangeInput = (e) => {
    const { value, name } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const addToEmails = (e, name) => {
    if (e.charCode === 13) {
      if (name) {
        if (!data.email) {
          notificationDanger('Email is required');
        }
      }
      const email = name ? data.email : e.target.value;
      const isValidEmail = String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

      if (isValidEmail && isValidEmail.length) {
        if (data.emails.includes(email)) {
          notificationDanger('Email already added to the list!');
          return;
        }
        setData((prev) => ({ ...prev, emails: [...prev.emails, { email, name: data.name }], email: '', name: '' }));
      } else {
        notificationDanger('Invalid Email!');
      }
    }
  };

  const onDeleteEmail = (email) => {
    const filteredEmails = data.emails.filter((item) => item.email !== email);
    setData((prev) => ({ ...prev, emails: filteredEmails }));
  };

  const handleChangeCheckbox = (e) => {
    const { checked } = e.target;
    setData((prev) => ({ ...prev, repeatable: checked }));
  };

  const handleDateChange = (date, name) => {
    setData((oldData) => ({ ...oldData, [name]: date }));
  };

  const onSave = () => {
    if (action === 'create') {
      const { email, name, ...rest } = data;
      createMutation(rest);
    } else {
      const { email, name, ...rest } = data;
      updateMutation({ id, ...rest });
    }
  };

  return (
    <CustomModal
      modalTitle={action === 'create' ? 'Create Disable Posting entry' : 'Update Disable Posting entry'}
      isOpen={open}
      toggleCustomModal={() => {
        toggleCustomModal();
      }}
      onSave={onSave}
      modalSize={'md'}
    >
      <Row className='d-flex align-items-center'>
        <Col md='4'>
          <Row>
            <Col>
              <CustomInput
                name='name'
                value={data.name}
                onChange={handleChangeInput}
                label='User Name (Press enter to add to list)'
                placeholder={'name'}
                autoComplete={'off'}
                onKeyPress={(e) => addToEmails(e, 'name')}
                size={'sm'}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CustomInput
                name='email'
                value={data.email}
                onChange={handleChangeInput}
                label='Email (Press enter to add to list) *'
                placeholder={'email'}
                autoComplete={'off'}
                onKeyPress={addToEmails}
                size={'sm'}
              />
            </Col>
          </Row>
        </Col>
        <Col md='8'>
          <Label>Email List</Label>
          <EmailContainer component='ul'>
            {data.emails && data.emails.length ? (
              data.emails.map((item) => {
                return (
                  <ListItem key={item.email}>
                    <Tooltip title={item.name || 'test'} className='test' placement='top' arrow followCursor>
                      <Chip label={item.email} onDelete={() => onDeleteEmail(item.email)} size='small' color='primary' />
                    </Tooltip>
                  </ListItem>
                );
              })
            ) : (
              <EmptyList>Empty List</EmptyList>
            )}
          </EmailContainer>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col md='4'>
          <DatePickerComponent
            onChange={(date) => handleDateChange(date, 'date_from')}
            isClearable
            name='date_from'
            selected={data.date_from}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Date From'
          />
        </Col>
        <Col md='4'>
          <DatePickerComponent
            onChange={(date) => handleDateChange(date, 'date_to')}
            isClearable
            name='date_to'
            selected={data.date_to}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Date To'
          />
        </Col>
        <Col md='4'>
          <Label>Repeatable</Label>
          <CustomCheckbox checked={data.repeatable} onChange={handleChangeCheckbox} name='repeatable' color='primary' />
        </Col>
      </Row>
    </CustomModal>
  );
};

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const EmailContainer = styled(Paper)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  listStyle: 'none',
  padding: '10px',
  margin: 0,
  backgroundColor: '#fff',
  minHeight: '80px',
}));

const EmptyList = styled('div')(({ theme }) => ({
  display: 'grid',
  placeItems: 'center',
  width: '100%',
  color: theme.palette.grey[500],
}));
