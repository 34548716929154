import DoneIcon from '@mui/icons-material/Done';
import RemoveIcon from '@mui/icons-material/Remove';
import React from 'react';
import { formatAmount } from '../../../../../utils/formatAmout';
import { formatDateForTable } from '../../../../../utils/formatDataForTable';

export const columns = ({ Actions, SelectCheck }) => {
  const boolColumn = (value) => {
    return <div>{value === true ? <DoneIcon color='success' /> : <RemoveIcon />}</div>;
  };

  return [
    {
      id: 'selectedChecks',
      Header: (row) =>
        SelectCheck && (
          <div className='d-flex justify-content-center align-items-center'>{React.cloneElement(SelectCheck, { row, selectMultiple: true })}</div>
        ),
      Cell: (row) =>
        SelectCheck && <div className='d-flex justify-content-center align-items-center'>{React.cloneElement(SelectCheck, { row })}</div>,
      sortable: false,
    },
    {
      id: 'billing_payment_checks.client_id',
      Header: <div>Name</div>,
      Cell: (row) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const clientId = row?.original?.account?.id;
            const clientType = row?.original?.account_type?.name?.toLowerCase().includes('vendor') ? 'vendors' : 'customers';

            if (clientId) {
              window.open(`/client/${clientType}/360/${clientId}`, '_blank');
            }
          }}
        >
          {row.value}
        </div>
      ),
      accessor: (d) => {
        const val = `${d.account?.number ?? ''} - ${d.account?.name ?? ''}`;
        return val;
      },
      sortable: true,
    },
    {
      id: 'billing_payment_checks.balance_account.name',
      Header: <div>Bank Account </div>,
      Cell: (row) => <div>{row.value}</div>,
      accessor: (d) => {
        const val = `${d.balance_account?.number ?? ''} - ${d.balance_account?.name ?? ''}`;
        return val;
      },
      sortable: false,
    },
    {
      id: 'billing_payment_checks.check_number',
      Header: <div>Check Number</div>,
      Cell: (row) => <div>{row.value}</div>,
      accessor: (d) => d['billing_payment_checks.check_number'],
      sortable: true,
    },
    {
      id: 'cleared_amount',
      Header: <div>Amount</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      accessor: (d) => d['billing_payment_checks.amount'],
      sortable: true,
    },
    {
      id: 'billing_payment_checks.check_date',
      Header: <div>Check Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      accessor: (d) => d['billing_payment_checks.check_date'],
      sortable: true,
    },
    {
      id: 'billing_payment_checks.printed',
      Header: <div>Printed</div>,
      Cell: (row) => boolColumn(row.value),
      accessor: (d) => d['billing_payment_checks.printed'],
      sortable: true,
    },
    {
      id: 'billing_payment_checks.print_date',
      Header: <div>Date Printed</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      accessor: (d) => d['billing_payment_checks.print_date'],
      sortable: true,
    },
    {
      id: 'billing_payment_checks.voided',
      Header: <div>Voided</div>,
      Cell: (row) => boolColumn(row.value),
      accessor: (d) => d['billing_payment_checks.voided'],
      sortable: true,
    },
    {
      id: 'billing_payment_checks.void_date',
      Header: <div>Date Voided</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      accessor: (d) => d['billing_payment_checks.void_date'],
      sortable: true,
    },
    {
      id: 'billing_payment_checks.posted',
      Header: <div>Posted</div>,
      Cell: (row) => boolColumn(row.value),
      accessor: (d) => d['billing_payment_checks.posted'],
      sortable: true,
    },
    {
      id: 'billing_payment_checks.posting_date',
      Header: <div>Date Posted</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      accessor: (d) => d['billing_payment_checks.posting_date'],
      sortable: true,
    },
    {
      id: 'actions',
      sortable: false,
      Header: 'Actions',
      accessor: (row) => {
        return <div className='d-flex justify-content-center'>{Actions && React.cloneElement(Actions, { row })}</div>;
      },
    },
  ];
};

export const excelHeaders = [
  { header: 'ID', key: 'billing_payment_checks.id' },
  { header: 'NAME', key: 'account_details' },
  { header: 'CHECK NUMBER', key: 'billing_payment_checks.check_number' },
  { header: 'CHECK DATE', key: 'billing_payment_checks.check_date' },
  { header: 'AMOUNT', key: 'billing_payment_checks.amount', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
  { header: 'PRINTED', key: 'billing_payment_checks.printed' },
  { header: 'DATE PRINTED', key: 'billing_payment_checks.print_date' },
  { header: 'VOIDED', key: 'billing_payment_checks.voided' },
  { header: 'DATE VOIDED', key: 'billing_payment_checks.void_date' },
  { header: 'POSTED', key: 'billing_payment_checks.posted' },
  { header: 'DATE POSTED', key: 'billing_payment_checks.posting_date' },
];
