import DoneIcon from '@mui/icons-material/Done';
import RemoveIcon from '@mui/icons-material/Remove';
import React from 'react';
import { formatAmount } from '../../../../../utils/formatAmout';

export const columns = ({ Actions }) => {
  const defaultColumns = [
    {
      id: 'client.external_id',
      Header: <div>ID</div>,
      Cell: (row) => <div>{row.value}</div>,
      accessor: (d) => d['client.external_id'],
      sortable: true,
    },
    {
      id: 'client.name',
      Header: <div>Name</div>,
      Cell: (row) => <div>{row.value}</div>,
      accessor: (d) => d['client.name'],
      sortable: true,
    },
    {
      id: 'address',
      Header: <div>Address</div>,
      Cell: (row) => <div>{row.value}</div>,
      accessor: (d) => d['address'],
      sortable: true,
    },
    {
      id: 'client.cfs',
      Header: <div>CFS</div>,
      Cell: (row) => <div>{row.value === true ? <DoneIcon color='success' /> : <RemoveIcon />}</div>,
      accessor: (d) => d['client.cfs'],
      sortable: true,
    },
    {
      id: 'client.ach',
      Header: <div>ACH</div>,
      Cell: (row) => <div>{row.value === true ? <DoneIcon color='success' /> : <RemoveIcon />}</div>,
      accessor: (d) => d['client.ach'],
      sortable: true,
    },
    {
      id: 'balance_due',
      Header: <div>Balance Due</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      accessor: (d) => d['balance_due'],
      sortable: true,
    },
    {
      id: 'actions',
      sortable: false,
      Header: 'Actions',
      accessor: (row) => {
        return <div className='d-flex justify-content-center'>{Actions && React.cloneElement(Actions, { row })}</div>;
      },
    },
  ];

  return defaultColumns;
};
