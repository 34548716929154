import React from 'react';
import { formatAmount } from '../../../../../utils/formatAmout';
import { formatDateForTable } from '../../../../../utils/formatDataForTable';

export const columns = ({ Actions }) => {
  const isCheckVoided = (row) => {
    return row.original.check?.is_check && row.original.check?.voided;
  };

  const clearedAmountValue = (row) => {
    if (isCheckVoided(row)) {
      return formatAmount(0);
    }

    return formatAmount(row.value);
  };

  const paymentInfoColumn = (row) => {
    if (row.original.check) {
      return (
        <div>
          {row.original.check?.voided && 'Voided'}
          {row.original.check?.void_date && ` on ${formatDateForTable(row.original.check.void_date)}`}
          {row.original.check.voided_by?.name && ` by ${row.original.check.voided_by?.name}`}
        </div>
      );
    }

    return <div></div>;
  };

  const defaultColumns = [
    {
      id: 'account_type.name',
      Header: <div>Account Type</div>,
      Cell: (row) => <div>{row.value}</div>,
      accessor: (d) => d['account_type.name'],
      sortable: true,
    },
    {
      id: 'billing_payment_items.account_number',
      Header: <div>Account Number</div>,
      Cell: (row) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const clientId = row?.original?.account?.id;
            const clientType = row?.original['account_type.name']?.toLowerCase().includes('vendor') ? 'vendors' : 'customers';
            if (clientId) {
              window.open(`/client/${clientType}/360/${clientId}`, '_blank');
            }
          }}
        >
          {row.value}
        </div>
      ),
      accessor: (d) => {
        const val = `${d.account?.number ?? ''} - ${d.account?.name ?? ''}`;
        return val;
      },
      sortable: true,
    },
    {
      id: 'billing_payment_items.pay_to_account_number',
      Header: <div>Pay To Account Number</div>,
      Cell: (row) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const clientId = row?.original?.pay_to_account?.id;
            const clientType = row?.original['account_type.name']?.toLowerCase().includes('vendor') ? 'vendors' : 'customers';
            if (clientId) {
              window.open(`/client/${clientType}/360/${clientId}`, '_blank');
            }
          }}
        >
          {row.value}
        </div>
      ),
      accessor: (d) => {
        const val = `${d.pay_to_account?.number ?? ''} - ${d.pay_to_account?.name ?? ''}`;
        return val;
      },
      sortable: true,
    },
    {
      id: 'transaction_type.name',
      Header: <div>Transaction Type</div>,
      Cell: (row) => <div>{row.value}</div>,
      accessor: (d) => d['transaction_type.name'],
      sortable: true,
    },
    {
      id: 'billing_payment_items.document_number',
      Header: <div>Document Number</div>,
      Cell: (row) => <div>{row.value}</div>,
      accessor: (d) => d['billing_payment_items.document_number'],
      sortable: true,
    },
    {
      id: 'billing_payment_items.posting_date',
      Header: <div>Posting Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      accessor: (d) => d['billing_payment_items.posting_date'],
      sortable: true,
    },
    {
      id: 'billing_payment_items.amount',
      Header: <div>Amount</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      accessor: (d) => d['billing_payment_items.amount'],
      sortable: true,
    },
    {
      accessor: 'cleared_amount',
      Header: 'Applied Amount',
      Cell: (row) => <div>{clearedAmountValue(row)}</div>,
    },
    {
      id: 'payment_source.name',
      Header: <div>Payment Method</div>,
      Cell: (row) => <div>{row.value}</div>,
      accessor: (d) => d['payment_source.name'],
      sortable: true,
    },
    {
      id: 'payment_info',
      Header: <div>Payment Info</div>,
      Cell: (row) => paymentInfoColumn(row),
      sortable: false,
    },
    {
      id: 'bank_payment_source.name',
      Header: <div>Bank Payment Method</div>,
      Cell: (row) => <div>{row.value}</div>,
      accessor: (d) => d['bank_payment_source.name'],
      sortable: true,
    },
    {
      id: 'balance_account_type.name',
      Header: <div>Bal. Account Type</div>,
      Cell: (row) => <div>{row.value}</div>,
      accessor: (d) => d['balance_account_type.name'],
      sortable: true,
    },
    {
      id: 'billing_payment_items.balance_account_number',
      Header: <div>Bal. Account Number</div>,
      Cell: (row) => <div>{row.value}</div>,
      accessor: (d) => {
        const val = `${d.balance_account?.number ?? ''} - ${d.balance_account?.name ?? ''}`;
        return val;
      },
      sortable: true,
    },
    {
      id: 'branch.name',
      Header: <div>Branch</div>,
      Cell: (row) => <div>{row.value}</div>,
      accessor: (d) => d['branch.name'],
      sortable: true,
    },
    {
      id: 'billing_payment_items.description',
      Header: <div>Description</div>,
      Cell: (row) => <div>{row.value}</div>,
      accessor: (d) => d['billing_payment_items.description'],
      sortable: true,
    },
    {
      id: 'actions',
      sortable: false,
      Header: 'Actions',
      accessor: (row) => {
        return <div className='d-flex justify-content-center'>{Actions && React.cloneElement(Actions, { row })}</div>;
      },
    },
  ];

  return defaultColumns;
};

export const excelHeaders = [
  { header: 'ACCOUNT TYPE', key: 'account_type.name' },
  { header: 'ACCOUNT NUMBER', key: 'account_details' },
  { header: 'TRANSACTION TYPE', key: 'transaction_type.name' },
  { header: 'DOCUMENT NUMBER', key: 'billing_payment_items.document_number' },
  { header: 'POSTING DATE', key: 'billing_payment_items.posting_date' },
  { header: 'AMOUNT', key: 'billing_payment_amount', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
  { header: 'PAYMENT METHOD', key: 'payment_source.name' },
  { header: 'BANK PAYMENT METHOD', key: 'bank_payment_source.name' },
  { header: 'BAL. ACCOUNT TYPE', key: 'balance_account_type.name' },
  { header: 'BAL. ACCOUNT NUMBER', key: 'balance_account_details' },
  { header: 'BRANCH', key: 'branch.name' },
  { header: 'DESCRIPTION', key: 'billing_payment_items.description' },
  { header: 'CREDIT AMOUNT', key: 'billing_payment_items.amount', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
  { header: 'APPLIED AMOUNT', key: 'cleared_amount', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
  { header: 'APPLICATION APPLIED AMOUNT', key: 'billing_payment_items.amount', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
];
