import axios from 'axios';
import jwt_decode from 'jwt-decode';
import {setAuthToken} from "../../../config/auth/auth";

export const AUTHENTICATION_SET_USER = 'AUTHENTICATION_SET_USER';

export const authenticationLogin = (userData) => dispatch => {
    return axios
        .post('/login/', userData)
        .then(res => {
            const { token } = res.data;
            localStorage.setItem('legalToken', token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(authenticationSetUser(decoded));
        })
        .catch(err => {

        });
};
// Set loged in user
export const authenticationSetUser = decoded => {
    return {
        type: AUTHENTICATION_SET_USER,
        payload: decoded
    };
};
// Logout user
export const authenticationLogoutAction = () => dispatch => {
    localStorage.removeItem('legalToken');
    setAuthToken(false);
    dispatch(authenticationSetUser({}));
    window.location.href = '/login';
};
