import { Button } from '@mui/material';
import { Col, Label, Row } from 'reactstrap';
import { CustomCurrencyInput } from '../../../../../common/Inputs/CurrencyInput';
import { CustomPageableAsyncSelect } from '../../../../../common/Inputs/CustomPageableAsyncSelect';
import DateRangeSelectBox from '../../../../../component/SelectBox/DataRangeSelectBox';
import { useAsyncPageable } from '../../../../../hooks/useAsyncPageable';

export const Filters = ({ filter, blankFilter, onChange, onCurrencyChange, onSelectChange, onSearch }) => {
  const { fetchOptions } = useAsyncPageable();

  const loadOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/account/options/accounts', { q: inputValue, ...options });
    return data;
  };

  return (
    <>
      <Row>
        <Col xs={12} md={4} lg={3}>
          <Label>Date Range</Label>
          <DateRangeSelectBox name='name' field={{ start: filter['start'], end: filter['end'] }} onChange={onChange} isDateRange />
        </Col>

        <Col xs={12} md={4} lg={3}>
          <CustomPageableAsyncSelect
            label='Account (From)'
            isClearable
            onChange={(val, e) => onSelectChange(val, e, 'from')}
            loadOptions={loadOptions}
            name='account.number'
            defaultValue={filter['account.number.from']}
            disabled={!!blankFilter['account.number.from']}
          />
        </Col>

        <Col xs={12} md={4} lg={3}>
          <CustomPageableAsyncSelect
            label='Account (To)'
            isClearable
            onChange={(val, e) => onSelectChange(val, e, 'to')}
            loadOptions={loadOptions}
            name='account.number'
            defaultValue={filter['account.number.to']}
            disabled={!!blankFilter['account.number.to']}
          />
        </Col>

        <Col xs={12} md={4} lg={3}>
          <CustomCurrencyInput
            label='Credit Amount (From)'
            key={'general_ledger.credit_amount'}
            formClassName='m-0'
            name={'general_ledger.credit_amount'}
            value={filter['general_ledger.credit_amount.from']}
            onChange={(value, name) => onCurrencyChange(value, name, 'from')}
            prefix={'$ '}
          />
        </Col>

        <Col xs={12} md={4} lg={3}>
          <CustomCurrencyInput
            label='Credit Amount (To)'
            key={'general_ledger.credit_amount'}
            formClassName='m-0'
            name={'general_ledger.credit_amount'}
            value={filter['general_ledger.credit_amount.to']}
            onChange={(value, name) => onCurrencyChange(value, name, 'to')}
            prefix={'$ '}
          />
        </Col>

        <Col xs={12} md={4} lg={3}>
          <CustomCurrencyInput
            label='Debit Amount (From)'
            key={'general_ledger.debit_amount'}
            formClassName='m-0'
            name={'general_ledger.debit_amount'}
            value={filter['general_ledger.debit_amount.from']}
            onChange={(value, name) => onCurrencyChange(value, name, 'from')}
            prefix={'$ '}
          />
        </Col>

        <Col xs={12} md={4} lg={3}>
          <CustomCurrencyInput
            label='Debit Amount (To)'
            key={'general_ledger.debit_amount'}
            formClassName='m-0'
            name={'general_ledger.debit_amount'}
            value={filter['general_ledger.debit_amount.to']}
            onChange={(value, name) => onCurrencyChange(value, name, 'to')}
            prefix={'$ '}
          />
        </Col>
      </Row>
      <Row>
        <Col className='d-flex justify-content-end'>
          <Button onClick={onSearch}>Search</Button>
        </Col>
      </Row>
    </>
  );
};
