import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import { lineInfoTableColumns } from '../tableColumns/lineInfoTableColumns';
import { useCustomModalToggle } from '../../../../common/Dialog/CustomModal';
import DataGridComponent from '../../../../component/DataTable/component/DataTableComponent';
import { CollapsableCard } from '../../../../common/Card/CollapsableCard';
import AddDepositItem from './AddDepositItem';
import { useDeleteDepositItemMutation, useGetDeposit, useGetDepositItemTableData } from '../depositApiRoutes';
import UploadItems from './UploadItems';
import { ExpendGeneralLedgerDataTableComponent } from '../../../../component/DataTable/ExpendComponent/ExpendGeneralLedgerDataTableComponent';
import UploadApplications from './UploadApplications';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DialogActions, DialogContent, IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Box } from '@mui/system';
import { CustomPopup } from '../../../../common/Popup/CustomPopup';
import LineInfoFilter from './LineInfoFilter';
import { hasPermissionHook } from '../../../../hooks/hasPermission';
import { DEPOSIT, EDIT } from '../../../../constants/permissions.constants';

const LineInfo = ({ isFinished }) => {
  const { hasPermission } = hasPermissionHook();
  const params = useParams();
  const history = useHistory();
  const { toggleCustomModal: toggleCreateUpdateModal, open: isModalOpen } = useCustomModalToggle();

  const [filters, setFilters] = React.useState({
    deposit_id: params.depositId,
    account_type: null,
    client_id: '',
    page: 0,
    pageSize: 10,
    order_by: 'asc',
    sort_by: 'account.number',
    unapliedFirst: 'OFF',
  });
  const [depositItemData, setDepositItemData] = React.useState({});
  const [deleteData, setDeleteData] = React.useState({
    deleteAnchor: null,
  });

  const { data: tableData, refetch } = useGetDepositItemTableData(filters);
  const { mutate: deleteDepositItemMutation } = useDeleteDepositItemMutation();

  const onFilterChange = (e) => {
    setFilters({ ...filters, ...e });
  };

  const onClearingClick = (rowData) => {
    history.push(`/deposit/${params.depositId}/clearing/${rowData.id}`);
  };

  const onEditClick = (rowData) => {
    setDepositItemData({
      depositItemId: rowData.id,
      account_name: rowData.account.name,
      account_number: rowData.account.number,
      external_id: rowData.account.external_id,
      account_type: rowData.account_type_id,
      amount: rowData.amount,
      claim_dep: rowData.claim_dep,
      deposit_category: rowData.deposit_categories.id,
      document_date: rowData.document_date,
      document_number: rowData.document_number,
      down_payment_dep: rowData.down_payment_dep,
      fixed_asset_dep: rowData.fixed_asset_dep,
      legal_dep: rowData.legal_dep,
      security_dep: rowData.security_dep,
      transaction_type: rowData.transaction_types.id,
      payment_type: rowData.payment_types.id,
      description: rowData.description,
    });
    toggleCreateUpdateModal();
  };

  const onDeleteClick = (e, rowData) => {
    setDeleteData({
      deleteAnchor: e.currentTarget,
      openDeletePopup: true,
      depositItemId: rowData.id,
    });
  };

  const toggleModal = () => {
    setDepositItemData({});
    toggleCreateUpdateModal();
  };

  const columns = React.useMemo(
    () => lineInfoTableColumns({ onEditClick, onClearingClick, isFinished, onDeleteClick, disabled: !hasPermission(DEPOSIT, EDIT) }),
    [isFinished]
  );
  const cleanDataSource = React.useMemo(() => ({ data: [], page: 0, pageSize: 10, pageTotal: 0 }));

  const handleConfirmDeleteAccountClick = () => {
    deleteDepositItemMutation(deleteData.depositItemId);
    setDeleteData({
      deleteAnchor: null,
    });
  };

  const SubComponentGl = ({ original }) => {
    return <ExpendGeneralLedgerDataTableComponent gls={original?.gls || []} />;
  };

  const highlightTableRow = React.useCallback((index, rowInfo) => {
    const { cleared_amount, amount } = rowInfo?.original ?? {};

    if (cleared_amount > 0 && cleared_amount === amount) {
      return {
        className: 'rt-tr-group bg-highlight-green',
      };
    }

    return null;
  }, []);

  const Table = React.useMemo(() => {
    if (isFinished) {
      return (
        <DataGridComponent
          columns={columns}
          loading={false}
          dataSource={tableData?.data || cleanDataSource}
          onFilterChange={onFilterChange}
          SubComponent={SubComponentGl}
          getTrGroupProps={highlightTableRow}
        />
      );
    }

    return (
      <DataGridComponent
        columns={columns}
        loading={false}
        dataSource={tableData?.data || cleanDataSource}
        onFilterChange={onFilterChange}
        getTrGroupProps={highlightTableRow}
      />
    );
  }, [isFinished, tableData?.data]);

  return (
    <CollapsableCard
      isExpanded={!!params.depositId}
      allowOpen={!params.depositId}
      headerTitle='Line Info'
      titleTypographyProps={{ fontSize: '16px' }}
      sxCardHeader={{ padding: '12px' }}
    >
      <Row className='mb-2 align-items-center'>
        <Col md='12' className='d-flex justify-content-end mb-2'>
          <UploadItems tableFilters={filters} disabled={isFinished || !hasPermission(DEPOSIT, EDIT)} />
          <UploadApplications tableFilters={filters} disabled={isFinished || !hasPermission(DEPOSIT, EDIT)} />
          <AddDepositItem
            tableFilters={filters}
            depositItemData={depositItemData}
            toggleCreateUpdateModal={toggleModal}
            isModalOpen={isModalOpen}
            disabled={isFinished || !hasPermission(DEPOSIT, EDIT)}
          />
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col md={12}>
          <CollapsableCard
            headerTitle='Filters'
            isExpanded={false}
            titleTypographyProps={{ fontSize: '18px' }}
            sxCardContent={{ padding: '16px' }}
            sxCardHeader={{ padding: '16px' }}
          >
            <LineInfoFilter setFilters={setFilters} filters={filters} onSearchClick={refetch} />
          </CollapsableCard>
        </Col>
      </Row>
      <Row className='mt-1'>
        <Col>{Table}</Col>
      </Row>
      <CustomPopup
        anchor={deleteData.deleteAnchor}
        content={
          <DeleteAccountPopupContent
            onConfirm={handleConfirmDeleteAccountClick}
            onCancel={() => setDeleteData((prev) => ({ deleteAnchor: null }))}
            message={
              <>
                <div>Are you sure you want to delete this deposit item?</div>
                <div className='font-italic'>This action will delete all applications</div>
              </>
            }
          />
        }
        arrow
        placement='bottom'
        onClose={() => setDeleteData((prev) => ({ deleteAnchor: null }))}
        open={deleteData.openDeletePopup}
      />
    </CollapsableCard>
  );
};

const DeleteAccountPopupContent = ({ onConfirm, onCancel, message = `Are you sure you want to delete this account?` }) => {
  return (
    <>
      <Box>
        <DialogContent sx={{ padding: '5px' }}>{message}</DialogContent>
        <DialogActions sx={{ padding: '5px' }}>
          <IconButton color='error' onClick={onConfirm}>
            <DeleteForeverIcon />
          </IconButton>
          <IconButton onClick={onCancel}>
            <ClearIcon />
          </IconButton>
        </DialogActions>
      </Box>
    </>
  );
};

export default LineInfo;
