import DataTablePagination from './DataTablePagination';
import { DataTableShadowComponent } from './DataTableShadowComponent';
import React from 'react';
import ReactTable from 'react-table';

const NoPagination = () => {
  return <></>;
};

export default function DataGridComponent({
  dataSource = { data: [], page: 1, pageSize: 10, pageTotal: 0 },
  onFilterChange = () => {},
  columns = [],
  loading = false,
  pagination = true,
  className = '',
  defaultPageSize,
  noPagination,
  ...props
}) {
  const onFilterPageChange = (e) => {
    const f = { page: e };
    onFilterChange(f);
  };

  const onFilterPageSizeChange = (e) => {
    const f = { pageSize: e };
    onFilterChange(f);
  };
  const onSortChange = (e) => {
    const column = e[0].id;
    const method = e[0].desc ? 'DESC' : 'ASC';
    const f = { sort_by: column, order_by: method };
    onFilterChange(f);
  };

  return (
    <ReactTable
      showPaginationBottom={pagination}
      PaginationComponent={noPagination ? NoPagination : DataTablePagination}
      TableComponent={DataTableShadowComponent}
      className={`-striped -highlight ${className}`}
      getFooterProps={() => ({ style: { display: 'block' } })}
      defaultSortDesc={true}
      data={dataSource.data}
      columns={columns}
      page={dataSource.page}
      pageSize={dataSource.pageSize}
      pages={dataSource.pageTotal}
      minRows={dataSource.data && dataSource.data.length > 5 ? 5 : dataSource.data ? dataSource.data.length : 1}
      defaultPageSize={defaultPageSize || 10}
      showPageSizeOptions={true}
      NoDataComponent={() => null}
      manual
      onPageSizeChange={onFilterPageSizeChange}
      onPageChange={onFilterPageChange}
      onSortedChange={onSortChange}
      loading={loading}
      {...props}
    />
  );
}
