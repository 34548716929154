import { IconButton, Tooltip } from '@mui/material';
import { PENDING } from '../../../../../constants/pendingTransactionsStatus.constants';
import { Check, DoDisturb } from '@mui/icons-material';

export const PendingTransactionsTableActions = ({ row, setSelectedRows, onApprove, onDecline }) => {
  const handleTransactionApproval = (row) => {
    setSelectedRows([row.original['pending_transactions.id']]);
    onApprove();
  };

  const handleTransactionDeclination = () => {
    setSelectedRows([row.original['pending_transactions.id']]);
    onDecline();
  };

  const disabledButton = row?.original?.pending_status_id !== PENDING;
  return (
    <>
      <IconButton
        disabled={disabledButton}
        onClick={() => {
          handleTransactionApproval(row);
        }}
      >
        <Tooltip title='Approve Transaction' placement='top' arrow followCursor>
          <Check color={disabledButton ? 'grey' : 'success'} />
        </Tooltip>
      </IconButton>
      <IconButton
        disabled={disabledButton}
        onClick={() => {
          handleTransactionDeclination();
        }}
      >
        <Tooltip title='Decline Transaction' placement='top' arrow followCursor>
          <DoDisturb color={disabledButton ? 'grey' : 'error'} />
        </Tooltip>
      </IconButton>
    </>
  );
};
