export const ASSET_TYPE = 1;
export const YEAR = 2;
export const MAKE = 3;
export const MODEL = 4;
export const EQUIPMENT_CLASS = 5;
export const TRUCK_TYPE = 6;
export const LIEN_HOLDER = 7;
export const DEPRECIATION_METHOD = 8;
export const ACC_TRAILER_TYPE = 9;
export const STATUS = 10;
export const CONDITION = 11;
export const RENTAL_STATUS = 12;
export const MECHANICAL_STATUS = 13;
export const TIRE_STATUS = 14;
export const ACC_TRUCK_TYPE = 15;
