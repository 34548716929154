import React from 'react';
import { formatAmount } from '../../../../../../utils/formatAmout';
import { formatDateForTable } from '../../../../../../utils/formatDataForTable';
import {
  isVendorParentTransactionColumn,
  isVendorPaymentVoidedColumn,
  paymentMethodColumn,
  shouldHidePaymentActionsAndDescription,
  statusColumn,
  vendorPaymentInfoColumn,
  vendorRemainingBalanceColumn,
} from './clientTransactionsTableColumnsUtils';
import { EditDocumentNumberAction } from './EditDocumentNumberAction';

// transactions
export const CustomerTransactionsDataTableColumns = (Actions, Actions2, refetchClientTransactions, filter) => {
  return [
    {
      accessor: (d) => d['transactions.posting_date'],
      id: 'transactions.posting_date',
      Header: <div>Posting Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['document_type.name'],
      id: 'document_type.name',
      Header: <div>Type</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['transactions.document_number'],
      id: 'transactions.document_number',
      Header: (props) => <div>Document Number</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: false,
    },

    {
      accessor: (d) => d['transactions.document_date'],
      id: 'transactions.document_date',
      Header: (props) => <div>Invoice Date</div>,
      sortable: true,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },

    {
      accessor: (d) => d['transactions.amount'],
      id: 'transactions.amount',
      Header: (props) => <div>Amount</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['transactions_balances.balance'],
      id: 'transactions_balances.balance',
      Header: (props) => <div>Remaining Balance</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['transactions_due_date.due_date'],
      id: 'transactions_due_date.due_date',
      Header: (props) => <div>Invoice Due Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      sortable: true,
    },
    {
      id: 'transactions.status',
      Header: <div>Status</div>,
      sortable: false,
      Cell: statusColumn,
    },
    {
      accessor: (d) => d['transactions.create_at'],
      id: 'transactions.create_at',
      Header: <div>Created On</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['users.name'],
      id: 'users.name',
      Header: <div>Created By</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: true,
    },

    {
      id: 'actions',
      Header: (props) => <div>Actions</div>,
      Cell: (row) => {
        const documentNumber = row.original['transactions.document_number'];
        const clientId = row.original['client.id'];
        const transactionsId = row.original['transactions.id'];
        const documentTypeName = row?.original['document_type.name'];

        if (documentTypeName === 'Customer Credit Memo') {
          return (
            <div className='d-flex'>
              {Actions2 && React.cloneElement(Actions2, { row })}
              {Actions && React.cloneElement(Actions, { row })}
            </div>
          );
        }

        if (documentTypeName === 'Customer Refund' && Actions && Actions2) {
          return (
            <div>
              {React.cloneElement(Actions2, { row })}
              {React.cloneElement(Actions, { row })}
              <EditDocumentNumberAction
                documentNumber={documentNumber}
                clientId={clientId}
                transactionsId={transactionsId}
                refetch={refetchClientTransactions}
                filter={filter}
              />
            </div>
          );
        }

        if (documentTypeName === 'Customer Payment' && Actions2) {
          return (
            <div>
              {React.cloneElement(Actions2, { row })}
              <EditDocumentNumberAction
                documentNumber={documentNumber}
                clientId={clientId}
                transactionsId={transactionsId}
                refetch={refetchClientTransactions}
                filter={filter}
              />
            </div>
          );
        } else if (documentTypeName !== 'Customer Payment' && Actions) {
          return (
            <div>
              {React.cloneElement(Actions, { row })}
              <EditDocumentNumberAction
                documentNumber={documentNumber}
                clientId={clientId}
                transactionsId={transactionsId}
                refetch={refetchClientTransactions}
                filter={filter}
              />
            </div>
          );
        } else {
          return <></>;
        }
      },
      sortable: false,
      minWidth: 160,
      maxWidth: 160,
    },
  ];
};

export const VendorTransactionsDataTableColumns = (Actions, Actions2, refetchClientTransactions, filter) => {
  const paymentActions = (row) => {
    const documentNumber = row.original['transactions.document_number'];
    const clientId = row.original['client.id'];
    const transactionsId = row.original['transactions.id'];

    if (['Vendor Credit Memo', 'Vendor Refund'].includes(row?.original['document_type.name']) && Actions && Actions2) {
      return (
        <>
          {React.cloneElement(Actions, { row })}
          {React.cloneElement(Actions2, { row })}
          <EditDocumentNumberAction
            documentNumber={documentNumber}
            clientId={clientId}
            transactionsId={transactionsId}
            refetch={refetchClientTransactions}
            filter={filter}
          />
        </>
      );
    }

    if (row?.original['document_type.name']?.includes('Vendor Payment') && Actions2 && !shouldHidePaymentActionsAndDescription(row)) {
      return (
        <div>
          {React.cloneElement(Actions2, { row })}
          <EditDocumentNumberAction
            documentNumber={documentNumber}
            clientId={clientId}
            transactionsId={transactionsId}
            refetch={refetchClientTransactions}
            filter={filter}
          />
        </div>
      );
    } else if (!row?.original['document_type.name']?.includes('Vendor Payment') && Actions && Actions2) {
      return (
        <div>
          {React.cloneElement(Actions, { row })}
          <EditDocumentNumberAction
            documentNumber={documentNumber}
            clientId={clientId}
            transactionsId={transactionsId}
            refetch={refetchClientTransactions}
            filter={filter}
          />
        </div>
      );
    } else {
      return <></>;
    }
  };

  return [
    {
      accessor: (d) => d['transactions.posting_date'],
      id: 'transactions.posting_date',
      Header: <div>Posting Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },
    {
      id: 'is_voided',
      Header: () => <div>Voided</div>,
      Cell: isVendorPaymentVoidedColumn,
      sortable: false,
    },
    {
      accessor: (d) => d['document_type.name'],
      id: 'document_type.name',
      Header: <div>Type</div>,
      Cell: (row) => <div>{row.value}</div>,
    },
    {
      accessor: (d) => d['transactions.document_number'],
      id: 'transactions.document_number',
      Header: (props) => <div>Document Number</div>,
      Cell: (row) => <div>{row.value}</div>,
    },
    {
      id: 'payment_source',
      Header: (props) => <div>Payment Method</div>,
      Cell: paymentMethodColumn,
      sortable: false,
    },
    {
      accessor: (d) => d['transactions.document_date'],
      id: 'transactions.document_date',
      Header: (props) => <div>Document Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },
    {
      accessor: (d) => d['subaccount_payment_amount'],
      id: 'subaccount_payment_amount',
      Header: (props) => <div>Payment Amount</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
    },
    {
      accessor: (d) => d['transactions.amount'],
      id: 'transactions.amount',
      Header: (props) => <div>Amount</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
    },
    {
      accessor: (d) => d['transactions_balances.balance'],
      id: 'transactions_balances.balance',
      Header: (props) => <div>Remaining Balance</div>,
      Cell: vendorRemainingBalanceColumn,
    },
    {
      accessor: (d) => d['transactions.memo'],
      id: 'transactions.memo',
      Header: (props) => <div>Description</div>,
      Cell: (row) => <div>{shouldHidePaymentActionsAndDescription(row) ? '' : row.value}</div>,
    },
    {
      accessor: (d) => d['transactions_due_date.due_date'],
      id: 'transactions_due_date.due_date',
      Header: (props) => <div>Document Due Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },
    {
      id: 'transactions.status',
      Header: <div>Status</div>,
      sortable: false,
      Cell: statusColumn,
    },
    {
      accessor: (d) => d['transactions.create_at'],
      id: 'transactions.create_at',
      Header: <div>Created On</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },
    {
      accessor: (d) => d['users.name'],
      id: 'users.name',
      Header: <div>Created By</div>,
      Cell: (row) => <div>{row.value}</div>,
    },
    {
      accessor: (d) => d['is_parent_transaction'],
      id: 'is_parent_transaction',
      Header: <div>Parent Transaction</div>,
      Cell: (row) => isVendorParentTransactionColumn(row),
      sortable: true,
    },
    {
      id: 'actions',
      Header: (props) => <div>Actions</div>,
      Cell: (row) => paymentActions(row),
      sortable: false,
      minWidth: 150,
    },
  ];
};

// invoices
export const CustomerInvoiceTransactionsDataTableColumns = (Actions) => {
  return [
    {
      accessor: (d) => d['transactions.posting_date'],
      id: 'transactions.posting_date',
      Header: <div>Posting Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['transactions.document_number'],
      id: 'transactions.document_number',
      Header: (props) => <div>Document Number</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: false,
    },

    {
      accessor: (d) => d['transactions.document_date'],
      id: 'transactions.document_date',
      Header: (props) => <div>Invoice Date</div>,
      sortable: true,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },

    {
      accessor: (d) => d['transactions.amount'],
      id: 'transactions.amount',
      Header: (props) => <div>Amount</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['transactions_balances.balance'],
      id: 'transactions_balances.balance',
      Header: (props) => <div>Remaining Balance</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['transactions_due_date.due_date'],
      id: 'transactions_due_date.due_date',
      Header: (props) => <div>Invoice Due Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      sortable: true,
    },
    {
      id: 'transactions.status',
      Header: <div>Status</div>,
      sortable: false,
      Cell: statusColumn,
    },
    {
      accessor: (d) => d['transactions.create_at'],
      id: 'transactions.create_at',
      Header: <div>Created On</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['users.name'],
      id: 'users.name',
      Header: <div>Created By</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: true,
    },

    {
      id: 'actions',
      Header: (props) => <div>Actions</div>,
      Cell: (row) => <div>{Actions && React.cloneElement(Actions, { row })}</div>,
      sortable: false,
    },
  ];
};

export const VendorInvoiceTransactionsDataTableColumns = (Actions) => {
  return [
    {
      accessor: (d) => d['transactions.posting_date'],
      id: 'transactions.posting_date',
      Header: <div>Posting Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },
    {
      accessor: (d) => d['transactions.document_number'],
      id: 'transactions.document_number',
      Header: <div>Document Number</div>,
      Cell: (row) => <div>{row.value}</div>,
    },
    {
      accessor: (d) => d['transactions.document_date'],
      id: 'transactions.document_date',
      Header: <div>Document Date</div>,

      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },
    {
      accessor: (d) => d['transactions.amount'],
      id: 'transactions.amount',
      Header: <div>Amount</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
    },
    {
      accessor: (d) => d['transactions_balances.balance'],
      id: 'transactions_balances.balance',
      Header: <div>Remaining Balance</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
    },
    {
      accessor: (d) => d['transactions.memo'],
      id: 'transactions.memo',
      Header: <div>Description</div>,
      Cell: (row) => <div>{row.value}</div>,
    },
    {
      accessor: (d) => d['transactions_due_date.due_date'],
      id: 'transactions_due_date.due_date',
      Header: <div>Document Due Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },
    {
      id: 'transactions.status',
      Header: <div>Status</div>,
      sortable: false,
      Cell: statusColumn,
    },
    {
      accessor: (d) => d['transactions.create_at'],
      id: 'transactions.create_at',
      Header: <div>Created On</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },
    {
      accessor: (d) => d['users.name'],
      id: 'users.name',
      Header: <div>Created By</div>,
      Cell: (row) => <div>{row.value}</div>,
    },

    {
      id: 'actions',
      Header: <div>Actions</div>,
      Cell: (row) => <div>{Actions && React.cloneElement(Actions, { row })}</div>,
      sortable: false,
    },
  ];
};

export const CustomerPaymentTransactionsDataTableColumns = (Actions, Actions2) => [
  {
    accessor: (d) => d['transactions.posting_date'],
    id: 'transactions.posting_date',
    Header: <div>Posting Date</div>,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['transactions.document_number'],
    id: 'transactions.document_number',
    Header: (props) => <div>Document Number</div>,
    Cell: (row) => <div>{row.value}</div>,
    sortable: false,
  },

  {
    accessor: (d) => d['transactions.document_date'],
    id: 'transactions.document_date',
    Header: (props) => <div>Document Date</div>,
    sortable: true,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
  },

  {
    accessor: (d) => d['transactions.amount'],
    id: 'transactions.amount',
    Header: (props) => <div>Amount</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['applied_amount'],
    id: 'applied_amount',
    Header: 'Applied Amount',
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['transactions_balances.balance'],
    id: 'transactions_balances.balance',
    Header: (props) => <div>Unapplied Amount</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['transactions.memo'],
    id: 'transactions.memo',
    Header: (props) => <div>Description</div>,
    Cell: (row) => <div>{row.value}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['transactions.create_at'],
    id: 'transactions.create_at',
    Header: <div>Created On</div>,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['users.name'],
    id: 'users.name',
    Header: <div>Created By</div>,
    Cell: (row) => <div>{row.value}</div>,
    sortable: true,
  },
  // {
  //   id: 'actions',
  //   Header: () => <div>Actions</div>,
  //   Cell: (row) => {
  //     if (Actions2) {
  //       return <div>{React.cloneElement(Actions2, { row })}</div>;
  //     } else {
  //       return <></>;
  //     }
  //   },
  //   sortable: false,
  //   minWidth: 160,
  //   maxWidth: 160,
  // },
];

export const VendorPaymentTransactionsDataTableColumns = (Actions, Actions2) => {
  return [
    {
      accessor: (d) => d['transactions.posting_date'],
      id: 'transactions.posting_date',
      Header: <div>Posting Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      sortable: true,
    },
    {
      id: 'is_voided',
      Header: () => <div>Voided</div>,
      Cell: isVendorPaymentVoidedColumn,
      sortable: false,
    },
    {
      accessor: (d) => d['transactions.document_number'],
      id: 'transactions.document_number',
      Header: (props) => <div>Document Number</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: false,
    },
    {
      id: 'payment_source',
      Header: (props) => <div>Payment Method</div>,
      Cell: paymentMethodColumn,
      sortable: false,
    },
    {
      accessor: (d) => d['transactions.document_date'],
      id: 'transactions.document_date',
      Header: (props) => <div>Document Date</div>,
      sortable: true,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },
    {
      accessor: (d) => d['subaccount_payment_amount'],
      id: 'subaccount_payment_amount',
      Header: (props) => <div>Payment Amount</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
    },
    {
      accessor: (d) => d['transactions.amount'],
      id: 'transactions.amount',
      Header: (props) => <div>Amount</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sort: true,
    },
    {
      accessor: (d) => d['applied_amount'],
      id: 'applied_amount',
      Header: 'Applied Amount',
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['transactions_balances.balance'],
      id: 'transactions_balances.balance',
      Header: (props) => <div>Unapplied Amount</div>,
      Cell: vendorRemainingBalanceColumn,
      sortable: true,
    },
    {
      id: 'vendor_payment_check',
      Header: (props) => <div>Payment Info</div>,
      Cell: vendorPaymentInfoColumn,
      sortable: false,
    },
    {
      accessor: (d) => d['transactions.memo'],
      id: 'transactions.memo',
      Header: (props) => <div>Description</div>,
      Cell: (row) => <div>{row.value}</div>,
      sort: true,
    },
    {
      accessor: (d) => d['transactions.create_at'],
      id: 'transactions.create_at',
      Header: <div>Created On</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['users.name'],
      id: 'users.name',
      Header: <div>Created By</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['is_parent_transaction'],
      id: 'is_parent_transaction',
      Header: <div>Parent Transaction</div>,
      Cell: (row) => isVendorParentTransactionColumn(row),
      sortable: true,
    },
    // {
    //   id: 'actions',
    //   Header: () => <div>Actions</div>,
    //   Cell: (row) => {
    //     if (Actions2) {
    //       return <div>{React.cloneElement(Actions2, { row })}</div>;
    //     } else {
    //       return <></>;
    //     }
    //   },
    //   sortable: false,
    //   minWidth: 160,
    //   maxWidth: 160,
    // },
  ];
};

// credit memo
export const CustomerCreditMemoTransactionsDataTableColumns = (Actions) => [
  {
    accessor: (d) => d['transactions.posting_date'],
    id: 'transactions.posting_date',
    Header: <div>Posting Date</div>,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['transactions.document_number'],
    id: 'transactions.document_number',
    Header: (props) => <div>Document Number</div>,
    Cell: (row) => <div>{row.value}</div>,
    sortable: false,
  },

  {
    accessor: (d) => d['transactions.document_date'],
    id: 'transactions.document_date',
    Header: (props) => <div>Date</div>,
    sortable: true,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
  },

  {
    accessor: (d) => d['transactions.amount'],
    id: 'transactions.amount',
    Header: (props) => <div>Amount</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['transactions_balances.balance'],
    id: 'transactions_balances.balance',
    Header: (props) => <div>Remaining Balance</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['transactions.memo'],
    id: 'transactions.memo',
    Header: (props) => <div>Description</div>,
    Cell: (row) => <div>{row.value}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['transactions.create_at'],
    id: 'transactions.create_at',
    Header: <div>Created On</div>,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['users.name'],
    id: 'users.name',
    Header: <div>Created By</div>,
    Cell: (row) => <div>{row.value}</div>,
    sortable: true,
  },

  {
    id: 'actions',
    Header: (props) => <div>Actions</div>,
    Cell: (row) => <div>{Actions && React.cloneElement(Actions, { row })}</div>,
    sortable: false,
  },
];

export const VendorCreditMemoTransactionsDataTableColumns = (Actions) => {
  return [
    {
      accessor: (d) => d['transactions.posting_date'],
      id: 'transactions.posting_date',
      Header: <div>Posting Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },
    {
      accessor: (d) => d['transactions.document_number'],
      id: 'transactions.document_number',
      Header: <div>Document Number</div>,
      Cell: (row) => <div>{row.value}</div>,
    },

    {
      accessor: (d) => d['transactions.document_date'],
      id: 'transactions.document_date',
      Header: <div>Document Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },

    {
      accessor: (d) => d['transactions.amount'],
      id: 'transactions.amount',
      Header: <div>Amount</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
    },
    {
      accessor: (d) => d['transactions_balances.balance'],
      id: 'transactions_balances.balance',
      Header: <div>Remaining Balance</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
    },
    {
      accessor: (d) => d['transactions.memo'],
      id: 'transactions.memo',
      Header: <div>Description</div>,
      Cell: (row) => <div>{row.value}</div>,
    },
    {
      accessor: (d) => d['transactions.create_at'],
      id: 'transactions.create_at',
      Header: <div>Created On</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },
    {
      accessor: (d) => d['users.name'],
      id: 'users.name',
      Header: <div>Created By</div>,
      Cell: (row) => <div>{row.value}</div>,
    },

    {
      id: 'actions',
      Header: <div>Actions</div>,
      Cell: (row) => <div>{Actions && React.cloneElement(Actions, { row })}</div>,
      sortable: false,
    },
  ];
};

// debit memo
export const CustomerDebitMemoTransactionsDataTableColumns = (Actions) => [
  {
    accessor: (d) => d['transactions.posting_date'],
    id: 'transactions.posting_date',
    Header: <div>Posting Date</div>,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['transactions.document_number'],
    id: 'transactions.document_number',
    Header: (props) => <div>Document Number</div>,
    Cell: (row) => <div>{row.value}</div>,
    sortable: false,
  },

  {
    accessor: (d) => d['transactions.document_date'],
    id: 'transactions.document_date',
    Header: (props) => <div>Date</div>,
    sortable: true,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
  },

  {
    accessor: (d) => d['transactions.amount'],
    id: 'transactions.amount',
    Header: (props) => <div>Amount</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['transactions_balances.balance'],
    id: 'transactions_balances.balance',
    Header: (props) => <div>Remaining Balance</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['transactions.memo'],
    id: 'transactions.memo',
    Header: (props) => <div>Description</div>,
    Cell: (row) => <div>{row.value}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['transactions.create_at'],
    id: 'transactions.create_at',
    Header: <div>Created On</div>,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['users.name'],
    id: 'users.name',
    Header: <div>Created By</div>,
    Cell: (row) => <div>{row.value}</div>,
    sortable: true,
  },

  {
    id: 'actions',
    Header: (props) => <div>Actions</div>,
    Cell: (row) => <div>{Actions && React.cloneElement(Actions, { row })}</div>,
    sortable: false,
  },
];

export const VendorDebitMemoTransactionsDataTableColumns = (Actions) => {
  return [
    {
      accessor: (d) => d['transactions.posting_date'],
      id: 'transactions.posting_date',
      Header: <div>Posting Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },
    {
      accessor: (d) => d['transactions.document_number'],
      id: 'transactions.document_number',
      Header: <div>Document Number</div>,
      Cell: (row) => <div>{row.value}</div>,
    },
    {
      accessor: (d) => d['transactions.document_date'],
      id: 'transactions.document_date',
      Header: <div>Document Date</div>,

      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },
    {
      accessor: (d) => d['transactions.amount'],
      id: 'transactions.amount',
      Header: <div>Amount</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
    },
    {
      accessor: (d) => d['transactions_balances.balance'],
      id: 'transactions_balances.balance',
      Header: <div>Remaining Balance</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
    },
    {
      accessor: (d) => d['transactions.memo'],
      id: 'transactions.memo',
      Header: <div>Description</div>,
      Cell: (row) => <div>{row.value}</div>,
    },
    {
      accessor: (d) => d['transactions.create_at'],
      id: 'transactions.create_at',
      Header: <div>Created On</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },
    {
      accessor: (d) => d['users.name'],
      id: 'users.name',
      Header: <div>Created By</div>,
      Cell: (row) => <div>{row.value}</div>,
    },

    {
      id: 'actions',
      Header: <div>Actions</div>,
      Cell: (row) => <div>{Actions && React.cloneElement(Actions, { row })}</div>,
      sortable: false,
    },
  ];
};

// refund
export const CustomerRefundTransactionsDataTableColumns = (Actions) => [
  {
    accessor: (d) => d['transactions.posting_date'],
    id: 'transactions.posting_date',
    Header: <div>Posting Date</div>,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['transactions.document_number'],
    id: 'transactions.document_number',
    Header: (props) => <div>Document Number</div>,
    Cell: (row) => <div>{row.value}</div>,
    sortable: false,
  },

  {
    accessor: (d) => d['transactions.document_date'],
    id: 'transactions.document_date',
    Header: (props) => <div>Date</div>,
    sortable: true,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
  },

  {
    accessor: (d) => d['transactions.amount'],
    id: 'transactions.amount',
    Header: (props) => <div>Amount</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['transactions_balances.balance'],
    id: 'transactions_balances.balance',
    Header: (props) => <div>Remaining Balance</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['transactions.memo'],
    id: 'transactions.memo',
    Header: (props) => <div>Description</div>,
    Cell: (row) => <div>{row.value}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['transactions.create_at'],
    id: 'transactions.create_at',
    Header: <div>Created On</div>,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['users.name'],
    id: 'users.name',
    Header: <div>Created By</div>,
    Cell: (row) => <div>{row.value}</div>,
    sortable: true,
  },

  {
    id: 'actions',
    Header: (props) => <div>Actions</div>,
    Cell: (row) => <div>{React.cloneElement(Actions, { row })}</div>,
    sortable: false,
  },
];

export const VendorRefundTransactionsDataTableColumns = [
  {
    accessor: (d) => d['transactions.document_number'],
    id: 'transactions.document_number',
    Header: (props) => <div>Document Number</div>,
    Cell: (row) => <div>{row.value}</div>,
    sortable: false,
  },

  {
    accessor: (d) => d['transactions.document_date'],
    id: 'transactions.document_date',
    Header: (props) => <div>Date</div>,
    sortable: true,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
  },

  {
    accessor: (d) => d['transactions.amount'],
    id: 'transactions.amount',
    Header: (props) => <div>Amount</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['transactions_balances.balance'],
    id: 'transactions_balances.balance',
    Header: (props) => <div>Balance</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['transactions.memo'],
    id: 'transactions.memo',
    Header: (props) => <div>Description</div>,
    Cell: (row) => <div>{row.value}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['transactions.create_at'],
    id: 'transactions.create_at',
    Header: <div>Created On</div>,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    sortable: true,
  },
  {
    accessor: (d) => d['users.name'],
    id: 'users.name',
    Header: <div>Created By</div>,
    Cell: (row) => <div>{row.value}</div>,
    sortable: true,
  },

  {
    id: 'actions',
    Header: (props) => <div>Actions</div>,
    Cell: (row) => <div>{''}</div>,
    sortable: false,
  },
];
