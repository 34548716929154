import React, { useEffect, useState } from 'react';
import { Col, Label, Row } from 'reactstrap';
import { CustomButton } from '../../../../../common/Button/CustomButton';
import { DatePickerComponent } from '../../../../../common/DatePicker/DatePicker';
import { CustomPageableAsyncSelect } from '../../../../../common/Inputs/CustomPageableAsyncSelect';
import TransactionsDocumentTypeSelectBox from '../../../../../component/SelectBox/TransactionsDocumentTypeSelectBox';
import { useAsyncPageable } from '../../../../../hooks/useAsyncPageable';
import { clientTypeNameSingular } from '../../../Client/utils';
import { Button, Divider } from '@mui/material';

export const TransactionFilter = ({ filter, blankFilter, onDateChange, onSelectChange, onFilterActionClick, onFilterChangeHandler, clientType }) => {
  const [init, setInit] = useState(false);

  const { fetchOptions } = useAsyncPageable();

  const loadClientExternalIdOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/client/customer/options-number-for-select', { q: inputValue, ...options });
    return data;
  };

  const loadClientRmiIdOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/client/customer/options-rmi-id-for-select', { q: inputValue, ...options });
    return data;
  };

  const loadDocumentNumberOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/transaction/options-for-select-dn', { q: inputValue, ...options });
    return data;
  };

  useEffect(() => {
    setInit(false);

    //force rerendering of async inputs
    setTimeout(() => {
      setInit(true);
    }, 1);
  }, [clientType]);

  const handleResetClick = (e) => {
    e.preventDefault();
    e.target.blur();
    onFilterActionClick('reset');

    setInit(false);
    //force rerendering of async inputs
    setTimeout(() => {
      setInit(true);
    }, 1);
  };

  return (
    <>
      <Row>
        <Col xs={12} md={4} lg={3}>
          {init && (
            <CustomPageableAsyncSelect
              label={clientTypeNameSingular(clientType) + ' No.'}
              isClearable
              onChange={onSelectChange}
              loadOptions={loadClientExternalIdOptions}
              name='client.external_id'
              defaultValue={filter['client.external_id'] || null}
            />
          )}
        </Col>
        <Col xs={12} md={4} lg={3}>
          {init && (
            <CustomPageableAsyncSelect
              label={'RMI No.'}
              isClearable
              onChange={onSelectChange}
              loadOptions={loadClientRmiIdOptions}
              name='client.rmi_number'
              defaultValue={filter['client.rmi_number'] || null}
            />
          )}
        </Col>
        <Col xs={12} md={4} lg={3}>
          <Label>Transactions:</Label>
          <TransactionsDocumentTypeSelectBox
            domain={clientType}
            name='account.number'
            field={{
              'transactions.document_type_journal_entry_id': filter['transactions.document_type_journal_entry_id'],
              'transactions.document_type_domain_id': filter['transactions.document_type_domain_id'],
            }}
            onChange={onFilterChangeHandler}
          />
        </Col>
        <Col xs={12} md={4} lg={3}>
          {init && (
            <CustomPageableAsyncSelect
              label='Document Number'
              isClearable
              onChange={onSelectChange}
              loadOptions={loadDocumentNumberOptions}
              name='transactions.document_number'
              defaultValue={filter['transactions.document_number']}
              disabled={!!blankFilter['transactions.document_number']}
            />
          )}
        </Col>
        <Col lg={3} md={4} sm={12}>
          <DatePickerComponent
            showYearDropdown
            name='posting_date_from'
            selected={filter['posting_date_from']}
            onChange={(date) => onDateChange(date, 'posting_date_from')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Posting Date From'
            isClearable
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <DatePickerComponent
            showYearDropdown
            name='posting_date_to'
            selected={filter['posting_date_to']}
            onChange={(date) => onDateChange(date, 'posting_date_to')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Posting Date To'
            isClearable
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <DatePickerComponent
            showYearDropdown
            name='invoice_date_from'
            selected={filter['invoice_date_from']}
            onChange={(date) => onDateChange(date, 'invoice_date_from')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label={`Invoice (Trx) Date From`}
            isClearable
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <DatePickerComponent
            showYearDropdown
            name='invoice_date_to'
            selected={filter['invoice_date_to']}
            onChange={(date) => onDateChange(date, 'invoice_date_to')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Invoice (Trx) Date To'
            isClearable
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <DatePickerComponent
            showYearDropdown
            name='invoice_due_date_from'
            selected={filter['invoice_due_date_from']}
            onChange={(date) => onDateChange(date, 'invoice_due_date_from')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Invoice Due Date From'
            isClearable
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <DatePickerComponent
            showYearDropdown
            name='invoice_due_date_to'
            selected={filter['invoice_due_date_to']}
            onChange={(date) => onDateChange(date, 'invoice_due_date_to')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Invoice Due Date To'
            isClearable
          />
        </Col>
      </Row>{' '}
      <Row className='mt-2'>
        <Col>
          <Divider />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col className='d-flex justify-content-end'>
          <Button sx={{ marginRight: '1rem' }} color='success' icon={'fas fa-sync-alt'} onClick={handleResetClick}>
            Reset
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.target.blur();
              onFilterActionClick('search');
            }}
          >
            Search
          </Button>
        </Col>
      </Row>
    </>
  );
};
