import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import TransactionsDataTable from '../../../../../../component/DataTable/TransactionsDataTable';
import { useSendTransactionEmail, useTransactionPdfExporter } from '../../../Client360/component/transaction/clientTransactionsHooks';
import { PaymentsTableActions } from '../../../Client360/component/transaction/payment/PaymentsTableActions';
import { TransactionTableActions } from '../../../Client360/component/transaction/TransactionTableActions';
import {
  emailSendErrorCallback,
  emailSendSuccessCallback,
  pdfExportErrorCallback,
} from '../../../Client360/component/transaction/utils/pdfAndEmailCallbackUtils';
import { useClientTableColumns } from '../../../useClientTableColumns';
import { transactionTableTypes } from './../../../constants';
import { useListAgingTransactions } from '../../hooks';

const ClientAgingTransactions = ({ clientId, clientType, initFilters }) => {
  const blankData = useMemo(() => {
    return { data: [], page: 0, pageSize: 10, pageTotal: 0 };
  }, []);

  const blankFilter = useMemo(() => {
    return {
      start: '',
      end: '',
      page: 0,
      pageSize: 10,
      age: initFilters?.age || null,
      aged_as_of: initFilters?.aged_as_of || null,
      aged_by: initFilters?.aged_by || null,
      balance: 1,
      // sort_by: 'client.name',
      // order_by: 'DESC',
    };
  }, [initFilters]);

  const { enqueueSnackbar } = useSnackbar();

  const transactionType = useMemo(() => 'Transaction', []);

  const [tableData, setTableData] = useState(blankData);
  const [filter, setFilter] = useState(blankFilter);

  const { data: clientAgingTransactionsData } = useListAgingTransactions(clientId, clientType, filter);

  const { mutate: sendTransactionEmail } = useSendTransactionEmail(clientId, clientType, transactionType, {
    successCallback: () => emailSendSuccessCallback({ enqueueSnackbar }),
    errorCallback: () => emailSendErrorCallback({ enqueueSnackbar }),
  });

  const { mutate: exportTransactionPdf } = useTransactionPdfExporter(clientId, clientType, transactionType, {
    errorCallback: () => pdfExportErrorCallback({ enqueueSnackbar }),
  });

  const { columns } = useClientTableColumns(
    clientType,
    transactionTableTypes.transactions,
    <TransactionTableActions
      overlayPopup
      overlayModal
      exportPdf={exportTransactionPdf}
      sendEmail={sendTransactionEmail}
      clientId={clientId}
      clientType={clientType}
      transactionType={transactionType}
    />,
    <PaymentsTableActions clientId={clientId} />
  );

  const onFilterChange = (input) => {
    setFilter((p) => ({ ...p, ...input }));
  };

  useEffect(() => {
    if (clientAgingTransactionsData?.data) {
      if (clientAgingTransactionsData?.status === 200) {
        setTableData(clientAgingTransactionsData.data);
      }
    }
  }, [clientAgingTransactionsData?.data]);

  return (
    <>
      <Row>
        <Col>
          <TransactionsDataTable dataSource={tableData} columns={columns} onFilterChange={onFilterChange} />
        </Col>
      </Row>
    </>
  );
};

export { ClientAgingTransactions };
