// transactions
export const clientTransactionsExcelHeaders = [
  { header: 'ID', key: 'transactions.id' },
  { header: 'NUMBER', key: 'transactions.document_number' },
  { header: 'SOURCE', key: 'transaction_import_method.name' },
  { header: 'TYPE', key: 'document_type.name' },
  { header: 'DATE', key: 'transactions.document_date', style: { numFmt: 'MM/DD/YYYY' } },
  { header: 'AMOUNT', key: 'transactions.amount', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
  {
    header: 'BALANCE',
    key: 'transactions_balances.balance',
    style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' },
  },
  { header: 'DESCRIPTION', key: 'transactions.memo' },
  { header: 'CREATE AT', key: 'transactions.create_at', style: { numFmt: 'MM/DD/YYYY' } },
  { header: 'CREATE BY', key: 'users.name' },
];

// invoices
export const clientInvoiceTransactionsExcelHeaders = [
  { header: 'ID', key: 'transactions.id' },
  { header: 'NUMBER', key: 'transactions.document_number' },
  { header: 'SOURCE', key: 'transaction_import_method.name' },
  { header: 'TYPE', key: 'document_type.name' },
  { header: 'DATE', key: 'transactions.document_date', style: { numFmt: 'MM/DD/YYYY' } },
  { header: 'AMOUNT', key: 'transactions.amount', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
  {
    header: 'BALANCE',
    key: 'transactions_balances.balance',
    style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' },
  },
  { header: 'DESCRIPTION', key: 'transactions.memo' },
  { header: 'CREATE AT', key: 'transactions.create_at', style: { numFmt: 'MM/DD/YYYY' } },
  { header: 'CREATE BY', key: 'users.name' },
];

// payment
export const clientPaymentTransactionsExcelHeaders = [
  { header: 'ID', key: 'transactions.id' },
  { header: 'NUMBER', key: 'transactions.document_number' },
  { header: 'SOURCE', key: 'transaction_import_method.name' },
  { header: 'TYPE', key: 'document_type.name' },
  { header: 'DATE', key: 'transactions.document_date', style: { numFmt: 'MM/DD/YYYY' } },
  { header: 'AMOUNT', key: 'transactions.amount', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
  {
    header: 'BALANCE',
    key: 'transactions_balances.balance',
    style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' },
  },
  { header: 'DESCRIPTION', key: 'transactions.memo' },
  { header: 'CREATE AT', key: 'transactions.create_at', style: { numFmt: 'MM/DD/YYYY' } },
  { header: 'CREATE BY', key: 'users.name' },
];

// credit memo
export const clientCreditMemoTransactionsExcelHeaders = [
  { header: 'ID', key: 'transactions.id' },
  { header: 'NUMBER', key: 'transactions.document_number' },
  { header: 'SOURCE', key: 'transaction_import_method.name' },
  { header: 'TYPE', key: 'document_type.name' },
  { header: 'DATE', key: 'transactions.document_date', style: { numFmt: 'MM/DD/YYYY' } },
  { header: 'AMOUNT', key: 'transactions.amount', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
  {
    header: 'BALANCE',
    key: 'transactions_balances.balance',
    style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' },
  },
  { header: 'DESCRIPTION', key: 'transactions.memo' },
  { header: 'CREATE AT', key: 'transactions.create_at', style: { numFmt: 'MM/DD/YYYY' } },
  { header: 'CREATE BY', key: 'users.name' },
];

// debit memo
export const clientDebitMemoTransactionsExcelHeader = [
  { header: 'ID', key: 'transactions.id' },
  { header: 'NUMBER', key: 'transactions.document_number' },
  { header: 'SOURCE', key: 'transaction_import_method.name' },
  { header: 'TYPE', key: 'document_type.name' },
  { header: 'DATE', key: 'transactions.document_date', style: { numFmt: 'MM/DD/YYYY' } },
  { header: 'AMOUNT', key: 'transactions.amount', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
  {
    header: 'BALANCE',
    key: 'transactions_balances.balance',
    style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' },
  },
  { header: 'DESCRIPTION', key: 'transactions.memo' },
  { header: 'CREATE AT', key: 'transactions.create_at', style: { numFmt: 'MM/DD/YYYY' } },
  { header: 'CREATE BY', key: 'users.name' },
];

// refund
export const clientRefundTransactionsExcelHeader = [
  { header: 'ID', key: 'transactions.id' },
  { header: 'NUMBER', key: 'transactions.document_number' },
  { header: 'SOURCE', key: 'transaction_import_method.name' },
  { header: 'TYPE', key: 'document_type.name' },
  { header: 'DATE', key: 'transactions.document_date', style: { numFmt: 'MM/DD/YYYY' } },
  { header: 'AMOUNT', key: 'transactions.amount', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
  {
    header: 'BALANCE',
    key: 'transactions_balances.balance',
    style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' },
  },
  { header: 'DESCRIPTION', key: 'transactions.memo' },
  { header: 'CREATE AT', key: 'transactions.create_at', style: { numFmt: 'MM/DD/YYYY' } },
  { header: 'CREATE BY', key: 'users.name' },
];

// invoice tracker
export const invoiceTrackerExcelHeader = [
  { header: 'DOCUMENT DATE', key: 'transactions.document_date' },
  { header: 'DESCRIPTION', key: 'general_ledger.description' },
  { header: 'CLIENT', key: 'client.name' },
  {
    header: 'DEBIT AMOUNT',
    key: 'general_ledger.debit_amount',
    style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' },
  },
  {
    header: 'CREDIT AMOUNT',
    key: 'general_ledger.credit_amount',
    style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' },
  },
  {
    header: 'TOTAL AMOUNT',
    key: 'general_ledger.total_amount',
    style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' },
  },
  { header: 'CREATE AT', key: 'transactions.create_at', style: { numFmt: 'MM/DD/YYYY' } },
  { header: 'CREATE BY', key: 'users.name' },
];
