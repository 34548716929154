import { IconButton, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { CollapsableCard } from '../../../../../common/Card/CollapsableCard';
import { CustomCard } from '../../../../../common/Card/CustomCard';
import { CustomDialog } from '../../../../../common/Dialog/CustomDialog';
import ExportButton from '../../../../../component/Buttons/ExportButton';
import { CustomSwitch } from '../../../../../common/Inputs/CustomSwitch';
import { downloaderFiles } from '../../../../../utils/downloaderFiles';
import { exportExcel } from '../../../../../utils/exportExcel';
import { exportPdf } from '../../../../../utils/exportPdf';
import { composeClientTransactionsUrl } from '../../Client360/component/transaction/clientTransactionsHooks';
import { clientTypeName } from '../../utils';
import { clientAgingBaseUrl, useClientAgingReport, useClientAgingTotal } from '../hooks';
import { agingExcelSummaryHeader, agingExcelDetailedHeader } from './component/agingColumns';
import AgingDataTable from './component/AgingDataTable';
import { AgingFilters } from './component/AgingFilters';
import AgingTotalHeaderComponent from './component/AgingTotalHeaderComponent';
import { ClientAgingTransactions } from './component/ClientAgingTransactions';
import { tableColumns } from './component/agingColumns';
import { clientTypeNameSingular } from '../../utils';
import { getDocumentType } from '../../../../../constants/documentType.constants';
import { Loader } from '../../../../../common/Loader';

export default function AgingView({ clientType = null }) {
  const blankFilter = useMemo(() => {
    return {
      'client.id': '',
      start: '',
      end: '',
      page: 0,
      pageSize: 10,
      aged_as_of: moment().format('YYYY/MM/DD'),
      aged_by: 'invoice_date',
      detailed: 'OFF',
    };
  }, []);

  const blankData = useMemo(() => {
    return { data: [], page: 0, pageSize: 10, pageTotal: 0 };
  }, []);

  const [transactionsData, setTransactionsData] = useState({
    clientId: null,
    filters: {
      age: null,
      aged_as_of: moment().format('YYYY/MM/DD'),
      aged_by: blankFilter.aged_by,
      filter_paid_transactions: true,
    },
    exportType: 'EXCEL',
  });

  const [modalTitleData, setModalTitleData] = useState({
    title: '',
    clientName: '',
  });

  const [downloading, setDownloading] = useState(false);
  const [container, setContainer] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filter, setFilter] = useState(blankFilter);
  const [total, setTotal] = useState({
    regular_debit: 0,
    debit_upto15: 0,
    debit_upto30: 0,
    debit_upto60: 0,
    debit_upto90: 0,
    debit_over90: 0,
    total_balance: 0,
  });

  const url = useMemo(() => {
    const baseUrl = clientAgingBaseUrl(clientType);
    return `${baseUrl}/list`;
  }, [clientType]);

  const { data: totalData, refetch: fetchTotal, isLoading: isTotalDataLoading } = useClientAgingTotal(clientType, filter);
  const { data: tableData, isLoading, isFetching, refetch: fetchReport } = useClientAgingReport(clientType, filter);

  useEffect(() => {
    if (totalData?.data) {
      if (totalData?.status === 200) {
        setTotal(totalData.data);
      }
    }
  }, [totalData]);

  useEffect(() => {
    setTransactionsData((p) => ({ ...p, filters: { ...p.filters, aged_as_of: filter.aged_as_of, aged_by: filter.aged_by } }));
  }, [filter.aged_as_of, filter.aged_by]);

  useEffect(() => {
    // reset filters on client type change
    setFilter(blankFilter);
  }, [clientType]);

  useEffect(() => {
    if (filter == blankFilter) {
      // refetch on filters reset
      refetch();
    }
  }, [filter]);

  const refetch = () => {
    fetchTotal();
    fetchReport();
  };

  const onTableFilterChange = (input) => {
    const f = { ...filter, ...input };
    setFilter(f);
  };

  const onChange = (e) => {
    try {
      if (e.target.value.constructor === {}.constructor) {
        setFilter((prev) => ({ ...prev, ...e.target.value }));
      } else {
        setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      }
    } catch (error) {
      setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const onSwitchChange = (e) => {
    const { name, checked } = e.target;
    setFilter((prev) => ({ ...prev, [name]: checked }));
  };

  const onSelectChange = (val, e) => {
    if (e.action === 'select-option') {
      let o = { target: { name: e.name, value: val.value } };
      onChange(o);
      return;
    }
    if (e.action === 'clear') {
      let o = { target: { name: e.name, value: null } };
      onChange(o);
    }
  };

  const onSelectTransactionTypeChange = (val, e) => {
    if (val) {
      const docType = getDocumentType(val.value);
      setFilter((prev) => ({ ...prev, ...docType, transactions_type: val.value }));
    } else {
      const docType = getDocumentType(null);
      setFilter((prev) => ({ ...prev, ...docType, transactions_type: null }));
    }
  };

  const onDateChange = (date, name) => {
    setFilter((prev) => ({
      ...prev,
      [name]: date,
    }));
  };

  const onButtonGroupChange = (e) => {
    const { name, value } = e.target;
    const newFilters = { ...filter };
    if (!value) {
      newFilters[name] = undefined;
    } else if (value === 'true') {
      newFilters[name] = true;
    } else if (value === 'false') {
      newFilters[name] = false;
    }

    setFilter((prev) => ({ ...prev, ...newFilters }));
  };

  const onColumnClick = (row, column) => {
    // id => column id
    const { age, header: headerTitle, id: columnId, isClientNameColumn, isAmountColumn } = column;
    if (isClientNameColumn) {
      if (clientType && row?.original['client.id']) {
        const clientUrl = `/client/${clientType}/360/${row.original['client.id']}/`;
        window.open(clientUrl, '_blank');
      }
    } else if (isAmountColumn) {
      if (columnId && row) {
        setTransactionsData((p) => ({ ...p, clientId: row.original['client.id'], filters: { ...p.filters, age } }));
        setModalTitleData({ title: headerTitle, clientName: row.original['client.name'] });
        setIsModalOpen(true);
      }
    }
  };

  const onFilterActionBtn = (e) => {
    if (e.toString() === 'PDF' || e.toString() === 'EXCEL') {
      downloaderFiles({
        filter,
        type: e,
        url,
        cbLoad: setDownloading,
        name: `${clientTypeName(clientType)} List Aging Aged As Of ${filter.aged_as_of ? moment(filter.aged_as_of).format('MM-DD-YYYY') : '/'}`,
      });
    }
    if (e.toString() === 'reset') {
      setFilter(blankFilter);
    }
    if (e.toString() === 'search') refetch();
  };

  const exportClientTransactionsToExcel = () => {
    downloaderFiles({
      filter: transactionsData.filters,
      type: transactionsData.exportType,
      url: `/me/client/${clientTypeNameSingular(clientType)}/aging/list-transactions/${transactionsData.clientId}`,
      cbLoad: () => {},
      name: `${modalTitleData.clientName} - ${modalTitleData.title} Aged As Of ${
        filter.aged_as_of ? moment(filter.aged_as_of).format('MM-DD-YYYY') : '/'
      }`,
    });
  };

  const handleChangeButtonGroup = (e) => {
    const { value } = e.target;

    setFilter((prev) => {
      let sort = { sort_by: 'client.external_id', order_by: 'DESC' };

      if (value == 'OFF') {
        // sorting is killing query performances
        sort = { sort_by: null, order_by: null };
      }
      return { ...prev, detailed: value, ...sort };
    });
  };

  return (
    <>
      {(isLoading || isFetching) && <Loader isFullScreen />}
      <CustomDialog
        isOpen={isModalOpen}
        actionButtonDisabled
        disableEscapeKeyDown
        disableBackdropClick
        hideActions
        hideTitleButton
        modalSize={'xl'}
        modalTitle={<ModalTitle title={modalTitleData.title} clientName={modalTitleData.clientName} excelExport={exportClientTransactionsToExcel} />}
        onClose={() => setIsModalOpen(false)}
      >
        <ClientAgingTransactions clientId={transactionsData.clientId} initFilters={transactionsData.filters} clientType={clientType} />
      </CustomDialog>
      <div className='content-header'>
        <div className='container-fluid'>
          <Row>
            <Col className='d-flex align-items-center jusitfy-content-between'>
              <h1 className='mr-auto text-dark'>Aging Report </h1>
              <div>
                Show Detailed Report
                <ToggleButtonGroup color='primary' value={filter.detailed} exclusive onChange={handleChangeButtonGroup} size='small' sx={{ ml: 2 }}>
                  <ToggleButton value={'OFF'} sx={{ fontWeight: 'bold' }}>
                    OFF
                  </ToggleButton>
                  <ToggleButton value={'ON'} sx={{ fontWeight: 'bold' }}>
                    ON
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className='container-fluid '>
        <Row>
          <Col xs={12}>
            <Card className='box-shadow'>
              <AgingTotalHeaderComponent total={total} isLoading={isTotalDataLoading} />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <CustomCard
              headerTitle={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>{clientTypeName(clientType)} List Aging</span>
                  <span>
                    <ExportButton
                      disabled={downloading}
                      exportTablePDF={() => {
                        onFilterActionBtn('PDF');
                      }}
                      exportTableEXCEL={() => {
                        onFilterActionBtn('EXCEL');
                      }}
                      exportPagePDF={() => exportPdf(container, `${clientTypeName(clientType)} List Aging`)}
                      exportPageEXCEL={() =>
                        exportExcel(
                          tableData.data?.data,
                          filter.detailed === 'OFF'
                            ? agingExcelSummaryHeader({ clientType: clientTypeNameSingular(clientType) })
                            : agingExcelDetailedHeader({ clientType: clientTypeNameSingular(clientType) }),
                          `${clientTypeName(clientType)} List Aging`
                        )
                      }
                    />
                  </span>
                </div>
              }
            >
              <Row className='mb-3'>
                <Col md={12}>
                  <CollapsableCard
                    headerTitle='Filters'
                    isExpanded={false}
                    titleTypographyProps={{ fontSize: '18px' }}
                    sxCardContent={{ padding: '16px' }}
                    sxCardHeader={{ padding: '16px' }}
                  >
                    <AgingFilters
                      isDetailed={filter.detailed === 'ON'}
                      filter={filter}
                      clientType={clientType}
                      blankFilter={blankFilter}
                      onFilterChange={onChange}
                      onSelectChange={onSelectChange}
                      onSelectTransactionTypeChange={onSelectTransactionTypeChange}
                      onSwitchChange={onSwitchChange}
                      onButtonGroupChange={onButtonGroupChange}
                      onChange={onChange}
                      onDateChange={onDateChange}
                      onFilterActionBtn={onFilterActionBtn}
                    />
                  </CollapsableCard>
                </Col>
              </Row>
              <div
                className='row'
                ref={(instance) => {
                  setContainer(instance);
                }}
              >
                <Col>
                  <AgingDataTable
                    columns={tableColumns({ clientType: clientTypeNameSingular(clientType), isDetailed: filter.detailed === 'ON' })}
                    dataSource={tableData?.data || blankData}
                    loader={isLoading}
                    onFilterChange={onTableFilterChange}
                    onColumnClick={onColumnClick}
                  />
                </Col>
              </div>
            </CustomCard>
          </Col>
        </Row>
      </div>
    </>
  );
}

const ModalTitle = ({ title, clientName, excelExport }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: '3rem' }}>
      <span>{title ?? ''}</span>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <span>{clientName}</span>
        <span style={{ marginLeft: '1rem' }}>
          <Tooltip arrow title={`Export ${clientName} transactions to excel`}>
            <IconButton onClick={excelExport}>
              <i className='fas fa-file-excel' style={{ color: '#686A6B' }} />
            </IconButton>
          </Tooltip>
        </span>
      </span>
    </div>
  );
};
