import React, { useState, useEffect } from 'react';
import NumberInput from 'react-number-format';
import PropTypes from 'prop-types';
import { CustomInput } from '../CustomInput';

const telFormat = '(###) ###-####';

const CustomInputMask = ({
  type,
  format,
  mask,
  name,
  value,
  label,
  onChange,
  prefix,
  min,
  thousandSeparator,
  displayType,
  allowEmptyFormatting,
  allowNegative,
  decimalScale,
  errorMess,
  error,
  infoText,
}) => {
  const [inputFormat, setInputFormat] = useState(null);

  useEffect(() => {
    if (format) {
      setInputFormat(format);
    } else {
      if (type) {
        switch (type) {
          case 'tel':
            setInputFormat(telFormat);
            break;
          default:
            setInputFormat(null);
        }
      }
    }
  }, [type, format]);

  return (
    <>
      <NumberInput
        min={min}
        type={type}
        allowEmptyFormatting={allowEmptyFormatting}
        decimalScale={decimalScale}
        format={inputFormat}
        mask={mask}
        name={name}
        label={label}
        value={value}
        customInput={CustomInput}
        thousandSeparator={thousandSeparator}
        displayType={displayType}
        prefix={prefix}
        placeholder=''
        allowNegative={allowNegative}
        onValueChange={(values, _) => {
          const normalizedEvent = { target: { name, value: values.value } };
          onChange(normalizedEvent);
        }}
      />
      <small className={infoText ? 'text-muted' : 'text-danger'}>
        {errorMess || (error && error[name] && error[name].errorMessage)}
        {!errorMess && infoText ? infoText : ''}
      </small>
    </>
  );
};

CustomInputMask.propTypes = {
  type: PropTypes.oneOf(['tel', 'text', 'password']),
  format: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  mask: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  prefix: PropTypes.string,
  thousandSeparator: PropTypes.bool,
  displayType: PropTypes.oneOf(['text', 'input']),
};

CustomInputMask.defaultProps = {
  displayType: 'input',
  mask: '_',
  allowEmptyFormatting: false,
  infoText: '',
  errorMess: '',
};

export { CustomInputMask };
