import { combineReducers } from 'redux';
import AuthorizationReducer from '../../app/redax/Authorization/AuthorizationReducer';
import SysReducer from '../../app/redax/Sys/SysReducer';
import PaginationReducer from '../../app/redax/Pagination/PaginationReducer';

const rootReducer = combineReducers({
  auth: AuthorizationReducer,
  sys: SysReducer,
  pagination: PaginationReducer,
});
export default rootReducer;
