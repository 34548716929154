import { Button } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { DatePickerComponent } from '../../../../../common/DatePicker/DatePicker';
import { CustomModal } from '../../../../../common/Dialog/CustomModal';
import { notificationDanger } from '../../../../../utils/toastify';
import { useNachaDownload } from '../hooks';

export const NachaModal = ({ toggleModal, isOpen = {} }) => {
  const { billingId, clientType } = useParams();
  const [form, setForm] = useState({
    effective_date: moment().format('YYYY-MM-DD'),
    fileCreationDate: moment().format('YYYY-MM-DD HH:mm'),
  });

  const { mutate: downloadNachaFile } = useNachaDownload({
    billingId,
    clientType,
    filters: {},
    callbacks: {
      onSuccess: toggleModal,
    },
  });

  const handleDateChange = (date, name) => {
    setForm((prevValue) => ({ ...prevValue, [name]: date }));
  };

  const downloadFile = () => {
    if (!form.effective_date) {
      notificationDanger('Please Select Effective Date');
      return;
    }

    downloadNachaFile({ ...form, effective_date: moment(form.effective_date).format('YYYY-MM-DD') });
  };

  return (
    <CustomModal
      modalTitle='Download NACHA File'
      modalSize='xs'
      toggleCustomModal={toggleModal}
      isOpen={isOpen}
      actionButtons={
        <div className='d-flex justify-content-between w-100'>
          <Button onClick={toggleModal}>Cancel</Button>
          <Button color='success' onClick={downloadFile}>
            Download
          </Button>
        </div>
      }
    >
      <Row>
        <Col>
          <DatePickerComponent
            onChange={(date) => handleDateChange(date, 'effective_date')}
            showYearDropdown
            name='effective_date'
            selected={form.effective_date}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Effective Date'
          />
        </Col>
      </Row>
    </CustomModal>
  );
};
