import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import CurrencyInput from 'react-currency-input-field';
import classNames from 'classnames';

export const CustomCurrencyInput = ({
  name,
  placeholder,
  value,
  onChange,
  prefix,
  suffix,
  disabled,
  errorMess,
  error,
  infoText,
  formClassName,
  label,
  decimalsLimit,
  allowNegativeValue = true,
  ...rest
}) => {
  const classes = classNames('form-control', { [rest.className]: !!rest.className });
  return (
    <FormGroup className={formClassName}>
      {label ? <Label>{label}</Label> : null}
      <CurrencyInput
        // className={`${errorMess && 'red-border-datepicker'}  ${rest.className ? `${rest.className} form-control` : 'form-control'}`}
        className={classes}
        name={name}
        placeholder={placeholder}
        value={value}
        decimalsLimit={decimalsLimit}
        onValueChange={onChange}
        allowNegativeValue={allowNegativeValue}
        allowDecimals
        prefix={prefix}
        suffix={suffix}
        disabled={disabled}
        {...rest}
      />
      <small className={infoText ? 'text-muted' : 'text-danger'}>
        {errorMess || (error && error[name] && error[name].errorMessage)}
        {!errorMess && infoText ? infoText : ''}
      </small>
    </FormGroup>
  );
};

CustomCurrencyInput.defaultProps = {
  decimalsLimit: 4,
};
