import React, { Fragment } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { JournalEntryTable } from '../JournalEntryTable';

export default function JournalEntryModal({ open = false, account = null, onToggle, accountName = null, displayFullLedgers, initialFilters = {} }) {
  return (
    <Fragment>
      <Modal style={{ maxWidth: 1600 }} isOpen={open} toggle={onToggle}>
        <ModalHeader toggle={onToggle}>
          Journal Entry For Account: {account} {accountName && ` - ${accountName}`}
        </ModalHeader>
        <ModalBody>
          {account ? <JournalEntryTable account={account} modal displayFullLedgers={displayFullLedgers} initialFilters={initialFilters} /> : null}
        </ModalBody>
      </Modal>
    </Fragment>
  );
}
