import React, { Fragment, useMemo, useState } from 'react';
import queryString from 'query-string';
import { Button } from '@mui/material';
import { Col, Row } from 'reactstrap';
import { exportPdf } from '../../../utils/exportPdf';
import { exportExcel } from '../../../utils/exportExcel';
import JournalEntryFilter from './component/JournalEntryFilter';
import { downloaderFiles } from '../../../utils/downloaderFiles';
import { JournalEntryDataGridColumns, excelHeaderColumns } from './tableColumns/JournalEntryTableColumns';
import { CollapsableCard } from '../../../common/Card/CollapsableCard';
import { CustomCard } from '../../../common/Card/CustomCard';
import ExportButton from '../../../component/Buttons/ExportButton';
import DataTableComponent from '../../../component/DataTable/component/DataTableComponent';
import { useGetJournalEntryTableData } from './journalEntryApiHooks';
import { AddJournalEntryModal } from './component/AddJournalEntry';
import { useCustomModalToggle } from '../../../common/Dialog/CustomModal';
import { mapDataToExcel } from '../../../utils/journalEntries/excelDataMapper';
import { notificationDangerCustomTime } from '../../../utils/toastify';
import { UploadJournalEntries } from './component/UploadJournalEntries';
import { EDIT, JOURNAL_ENTRY } from '../../../constants/permissions.constants';
import { hasPermissionHook } from '../../../hooks/hasPermission';
import { CustomSwitch } from '../../../common/Inputs/CustomSwitch';
import { ExpendGeneralLedgerDataTableComponent } from '../../../component/DataTable/ExpendComponent/ExpendGeneralLedgerDataTableComponent';

function JournalEntryTable({ account = null, client_id, modal = false, initialFilters, displayFullLedgers = false, ...props }) {
  const { hasPermission, isPostingDisabled } = hasPermissionHook();
  const { toggleCustomModal: toggleCreateModal, open: isCreateModalOpen } = useCustomModalToggle();
  const blankFilter = useMemo(() => {
    return {
      'account.number': account,
      'client_transactions.client_id': client_id,
      'transactions.id': '',
      'general_ledger.description': '',
      'general_ledger.branch_search': '',
      'general_ledger.created_by_user': '',
      'client_transactions.client_id': '',
      'transactions.document_type_journal_entry_id': '',
      'transactions.document_type_domain_id': '',
      'posting_date.from': initialFilters?.postingDate?.from ?? '',
      'posting_date.to': initialFilters?.postingDate?.to ?? '',
      'general_ledger.branch_id': initialFilters && initialFilters['general_ledger.branch_id'] ? initialFilters['general_ledger.branch_id'] : null,
      'fixed_assets.unit_number': initialFilters && initialFilters['fixed_assets.unit_number'] ? initialFilters['fixed_assets.unit_number'] : null,
      'fixed_assets.year': initialFilters && initialFilters['fixed_assets.year'] ? initialFilters['fixed_assets.year'] : null,
      'fixed_assets.truck_type': initialFilters && initialFilters['fixed_assets.truck_type'] ? initialFilters['fixed_assets.truck_type'] : null,
      'fixed_assets.make': initialFilters && initialFilters['fixed_assets.make'] ? initialFilters['fixed_assets.make'] : null,
      vendor_parent: null,
      child_vendor: null,

      full_ledgers: false,
      document_type: null,
      start: '',
      end: '',
      page: 0,
      pageSize: 10,
    };
  }, [account, client_id, initialFilters]);

  const blankData = useMemo(() => {
    return { data: [], page: 0, pageSize: 10, pageTotal: 0 };
  }, []);

  const [filter, setFilter] = useState(blankFilter);
  const [loader, setLoader] = useState(false);
  const [container, setContainer] = useState();

  const { data: tableData, refetch } = useGetJournalEntryTableData({ ...filter });

  const openClientTransactionPage = (row, options) => {
    const { isClient, isDeposit } = options;

    if (row?.original['client.id'] || row?.original['bank_payment_wizard.id']) {
      if (row.original['bank_payment_wizard.id'] && isDeposit) {
        window.open(`/deposit/${row.original['bank_payment_wizard.id']}`, '_blank');
      } else if (row.column.id === 'transactions.document_number' && !isDeposit) {
        const query = queryString.stringify({ 'transactions.id': row.original['transactions_id'] });
        window.open(`/client/${row.original['client.client_type']}/360/${row.original['client.id']}?${query}`, '_blank');
      } else if (isClient) {
        window.open(`/client/${row.original['client.client_type']}/360/${row.original['client.id']}`, '_blank');
      }
    } else {
      notificationDangerCustomTime('No Client for this JL Item!', 5000);
    }
  };

  const columns = React.useMemo(() => JournalEntryDataGridColumns({ openClientTransactionPage }), []);

  const onFilterHandler = (e) => {
    try {
      if (e.target.value.constructor === {}.constructor) {
        const f = { ...filter, ...e.target.value };
        setFilter(f);
      } else {
        const f = { ...filter, [e.target.name]: e.target.value };
        setFilter(f);
      }
    } catch (error) {
      const f = { ...filter, [e.target.name]: e.target.value };
      setFilter(f);
    }
  };
  const onFilterChange = (input) => {
    const f = { ...filter, ...input };
    setFilter(f);
  };

  const onSwitchChange = (e) => {
    const { name, checked } = e.target;
    setFilter((prev) => ({ ...prev, [name]: checked }));
  };

  const onFilterActionBtn = (e) => {
    if (e.toString() === 'PDF' || e.toString() === 'EXCEL') {
      downloaderFiles({ filter, type: e, url: '/me/journal-entry', cbLoad: setLoader, name: 'Journal-Entry' });
    }
    if (e.toString() === 'reset') setFilter(blankFilter);
  };

  const openAddNewModal = () => {
    toggleCreateModal();
  };

  const SubComponentGl = ({ original }) => {
    return <ExpendGeneralLedgerDataTableComponent displayOnlyDetailedLedgers gls={[]} detailedGls={original?.detailed_gls || []} />;
  };

  return (
    <Fragment>
      <Row className='mt-2'>
        <Col md={12}>
          <CustomCard
            headerTitle={
              <div className='d-flex align-items-center justify-content-between'>
                <span>Journal Entry - Account</span>
                {displayFullLedgers && (
                  <span style={{ minWidth: '235px' }}>
                    <CustomSwitch
                      label={filter.full_ledgers ? 'Full ledgers' : 'One sided ledgers'}
                      name={'full_ledgers'}
                      checked={filter.full_ledgers}
                      onChange={onSwitchChange}
                    />
                  </span>
                )}
                <ExportButton
                  disabled={loader}
                  exportTablePDF={() => {
                    onFilterActionBtn('PDF');
                  }}
                  exportTableEXCEL={() => {
                    onFilterActionBtn('EXCEL');
                  }}
                  exportPagePDF={() => exportPdf(container, 'Journal Entry')}
                  exportPageEXCEL={() => exportExcel(mapDataToExcel(tableData?.data?.data), excelHeaderColumns, 'Journal Entry')}
                />
              </div>
            }
            sxCardContainer={{ mt: 2 }}
          >
            <Row className='mb-3'>
              <Col md={12}>
                <CollapsableCard
                  headerTitle='Filters'
                  isExpanded={false}
                  titleTypographyProps={{ fontSize: '18px' }}
                  sxCardContent={{ padding: '16px' }}
                  sxCardHeader={{ padding: '16px' }}
                >
                  <JournalEntryFilter
                    modal={modal}
                    loader={loader}
                    filter={filter}
                    blankFilter={blankFilter}
                    onFilterActionClick={onFilterActionBtn}
                    onFilterChangeHandler={onFilterHandler}
                    setFilter={setFilter}
                    onSearchClick={refetch}
                  />
                </CollapsableCard>
              </Col>
            </Row>
            {!modal && (
              <Row className='mb-2'>
                <Col className='d-flex justify-content-end'>
                  <UploadJournalEntries />
                  <Button onClick={openAddNewModal} color='success' disabled={!hasPermission(JOURNAL_ENTRY, EDIT)}>
                    Add New Journal Entry
                  </Button>
                </Col>
              </Row>
            )}
            <div
              ref={(instance) => {
                setContainer(instance);
              }}
            >
              <DataTableComponent
                dataSource={tableData?.data || blankData}
                onFilterChange={onFilterChange}
                columns={columns}
                loading={loader}
                SubComponent={SubComponentGl}
              />
            </div>
          </CustomCard>
        </Col>
      </Row>
      <AddJournalEntryModal tableFilters={filter} toggleModal={toggleCreateModal} isModalOpen={isCreateModalOpen} saveAsDraft />
    </Fragment>
  );
}

export { JournalEntryTable };
