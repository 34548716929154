import React, { useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import Client360View from './Client360/Client360View';
import { ApplicationOfPaymentsComponent } from './Client360/component/transaction/ApplicationOfPaymentsComponents/ApplicationOfPaymentsComponent';
import { NewApplicationOfPaymentsComponent } from './Client360/component/transaction/ApplicationOfPaymentsComponents/NewApplicationOfPaymentsComponent';
import { PaymentInfoComponent } from './Client360/component/transaction/PaymentInfoComponent/PaymentInfoComponent';
import ClientList from './ClientList/ClientListView';
import { BillingPayment } from './ClientPayment/components/BillingPayment';
import { BillingPaymentChecks } from './ClientPayment/components/checks/BillingPaymentChecks';
import { CreateBillingPayment } from './ClientPayment/components/CreateBillingPayment';
import { BillingPaymentItemClearing } from './ClientPayment/components/items/BillingPaymentItemClearing';
import { BillingPayments } from './ClientPayment/components/BillingPayments';
import AgingView from './Report/Aging/AgingView';
import InvoiceTrackerView from './Report/InvoiceTracker/InvoiceTrackerView';
import ClientBoxTransactionsComponent from './Transactions/ClientBoxTransactionsComponent';
import { RestrictedRoute } from '../../../config/router/RestrictedRoute';
import {
  CUSTOMERS,
  CUSTOMER_AGING,
  CUSTOMER_INVOICE_TRACKER,
  PAY_BILLS,
  VENDORS,
  VENDOR_AGING,
  VENDOR_INVOICE_TRACKER,
} from '../../../constants/permissions.constants';

export default function ClientView() {
  let { clientType } = useParams();
  useEffect(() => {}, []);

  return (
    <Switch>
      <Route exact path='/client/:clientType(customers|vendors)/'>
        <RestrictedRoute functionalities={clientType === 'customers' ? [CUSTOMERS] : [VENDORS]}>
          <ClientList />
        </RestrictedRoute>
      </Route>
      <Route exact path='/client/:clientType(customers|vendors)/payment'>
        <RestrictedRoute functionalities={[PAY_BILLS]}>
          <BillingPayments />
        </RestrictedRoute>
      </Route>
      <Route exact path='/client/:clientType(customers|vendors)/payment/:billingId([0-9]+)'>
        <RestrictedRoute functionalities={[PAY_BILLS]}>
          <BillingPayment />
        </RestrictedRoute>
      </Route>
      <Route exact path='/client/:clientType(customers|vendors)/payment/checks'>
        <RestrictedRoute functionalities={[PAY_BILLS]}>
          <BillingPaymentChecks />
        </RestrictedRoute>
      </Route>
      <Route exact path='/client/:clientType(customers|vendors)/payment/new'>
        <RestrictedRoute functionalities={[PAY_BILLS]}>
          <CreateBillingPayment />
        </RestrictedRoute>
      </Route>
      <Route exact path='/client/:clientType(customers|vendors)/payment/:billingId([0-9]+)/items/:billingItemId([0-9]+)/clearing'>
        <RestrictedRoute functionalities={[PAY_BILLS]}>
          <BillingPaymentItemClearing />
        </RestrictedRoute>
      </Route>
      <Route exact path='/client/:clientType(customers|vendors)/aging'>
        <RestrictedRoute functionalities={clientType === 'customers' ? [CUSTOMER_AGING] : [VENDOR_AGING]}>
          <AgingView clientType={clientType} />
        </RestrictedRoute>
      </Route>
      {/* <Route exact={true} path='/client/:clientType(customers|vendors)/invoices-tracker'>
        <RestrictedRoute functionalities={clientType === 'customers' ? [CUSTOMER_INVOICE_TRACKER] : [VENDOR_INVOICE_TRACKER]}>
          <InvoiceTrackerView clientType={clientType} />
        </RestrictedRoute>
      </Route> */}
      <Route path='/client/:clientType(customers|vendors)/:screenType/:cid?'>
        <div className='container-fluid mt-2'>
          <Row>
            <Col md={12}>
              <Switch>
                <Route
                  exact={true}
                  path='/client/:clientType(customers|vendors)/360/:clientId/payment/application-of-payments/:paymentId'
                  component={NewApplicationOfPaymentsComponent}
                  // component={ApplicationOfPaymentsComponent}
                />
                <Route
                  exact={true}
                  path='/client/:clientType(customers|vendors)/360/:clientId/invoice/payment-info/:invoiceId'
                  component={PaymentInfoComponent}
                />
                <Route path='/client/:clientType(customers|vendors)/360/:clientId/' component={Client360View} />
                <Route path='/client/:clientType(customers|vendors)/transactions/:transactionsType?' component={ClientBoxTransactionsComponent} />
                <Route path='/client/:clientType(customers|vendors)/edit/:clientId' />
              </Switch>
            </Col>
          </Row>
        </div>
      </Route>
    </Switch>
  );
}
