import React from 'react';
import { Row, Col } from 'reactstrap';
import { IconButton, Button, DialogActions, DialogContent, Box } from '@mui/material';
import { AddCircleOutline, DeleteForeverOutlined, ClearOutlined } from '@mui/icons-material';
import { CustomPopup } from '../../../../common/Popup/CustomPopup';

import DataTableComponent from '../../../../component/DataTable/component/DataTableComponent';
import { CustomCard } from '../../../../common/Card/CustomCard';
import { useGetDisablePostingTableData, useDeleteDisablePosting } from './apiHooks';
import { AddEditComponent } from './components/AddEditDisablePosting';
import { useCustomModalToggle } from '../../../../common/Dialog/CustomModal';
import { hasPermissionHook } from '../../../../hooks/hasPermission';
import { DISABLE_POSTING, EDIT } from '../../../../constants/permissions.constants';
import { DisablePostingTableColumns } from './columns';

export const DisablePostingTable = () => {
  const { hasPermission } = hasPermissionHook();
  const { open, toggleCustomModal } = useCustomModalToggle();
  const [modalData, setModalData] = React.useState({
    id: null,
    action: null,
  });
  const blankData = React.useMemo(() => {
    return { data: [], page: 0, pageSize: 100, pageTotal: 0 };
  }, []);
  const [filter, setFilter] = React.useState({
    sort_by: 'date_from',
    order_by: 'ASC',
    page: 0,
    pageSize: 100,
  });
  const [deleteData, setDeleteData] = React.useState({
    deleteAnchor: null,
  });

  const { data: tableData, isLoading } = useGetDisablePostingTableData(filter);
  const { mutate: deleteMutation } = useDeleteDisablePosting(filter);

  const onFilterChange = (input) => {
    setFilter((oldFilter) => ({ ...oldFilter, ...input }));
  };

  const onDeleteItem = (e, rowData) => {
    setDeleteData({
      deleteAnchor: e.currentTarget,
      openDeletePopup: true,
      id: rowData.id,
    });
  };

  const toggleModal = ({ action, id, data }) => {
    setModalData({ action, id, data });
    toggleCustomModal();
  };

  const columns = React.useMemo(() => DisablePostingTableColumns({ disabled: !hasPermission(DISABLE_POSTING, EDIT), toggleModal, onDeleteItem }), []);

  const handleConfirmDeleteDisablePostingClick = () => {
    deleteMutation(deleteData.id);
    setDeleteData({ deleteAnchor: null });
  };

  return (
    <>
      <CustomCard headerTitle='Disable Posting' sxCardContainer={{ mt: 2 }}>
        <Row className='mb-3'>
          <Col md='12' className='d-flex align-items-end justify-content-end'>
            <Button
              variant='contained'
              endIcon={<AddCircleOutline />}
              color='success'
              size='small'
              onClick={() => toggleModal({ action: 'create', id: null })}
              disabled={!hasPermission(DISABLE_POSTING, EDIT)}
            >
              Add new Disable Posting entry
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <DataTableComponent dataSource={tableData?.data || blankData} onFilterChange={onFilterChange} columns={columns} loading={isLoading} />
          </Col>
        </Row>
      </CustomCard>
      <CustomPopup
        anchor={deleteData.deleteAnchor}
        content={
          <DeleteUserPopupContent
            onConfirm={handleConfirmDeleteDisablePostingClick}
            onCancel={() => setDeleteData((prev) => ({ deleteAnchor: null }))}
          />
        }
        arrow
        placement='bottom'
        onClose={() => setDeleteData((prev) => ({ deleteAnchor: null }))}
        open={deleteData.openDeletePopup}
      />
      <AddEditComponent
        open={open}
        action={modalData.action}
        id={modalData.id}
        disablePostingData={modalData.data}
        toggleCustomModal={toggleCustomModal}
      />
    </>
  );
};

const DeleteUserPopupContent = ({ onConfirm, onCancel, message = `Are you sure you want to delete this role?` }) => {
  return (
    <>
      <Box>
        <DialogContent sx={{ padding: '5px' }}>{message}</DialogContent>
        <DialogActions sx={{ padding: '5px' }}>
          <IconButton color='error' onClick={onConfirm}>
            <DeleteForeverOutlined />
          </IconButton>
          <IconButton onClick={onCancel}>
            <ClearOutlined />
          </IconButton>
        </DialogActions>
      </Box>
    </>
  );
};
