import React, { useEffect, useMemo, useState } from 'react';
import ReactTable from 'react-table';
import { CustomSwitch } from '../../../common/Inputs/CustomSwitch';
import { GeneralLedgerSubColumns } from '../Columns/GeneralLedgerSubColumns';

export function ExpendGeneralLedgerDataTableComponent({ displayOnlyDetailedLedgers, gls = [], detailedGls = [] }) {
  const [detailed, setDetailed] = useState();
  const pageSize = useMemo(() => {
    return (detailed ? detailedGls.length : gls.length) ?? 2;
  }, [detailed, detailedGls, gls]);

  const sortBy = useMemo(() => {
    if (detailed) {
      return [
        { desc: false, id: 'client_name' },
        { desc: true, id: 'debit_amount' },
      ];
    }

    return [{ desc: true, id: 'debit_amount' }];
  }, [detailed, detailedGls, gls]);

  useEffect(() => {
    if (displayOnlyDetailedLedgers) {
      setDetailed(true);
    }
  }, [displayOnlyDetailedLedgers]);

  const data = useMemo(() => {
    if (displayOnlyDetailedLedgers) {
      return detailedGls;
    }

    return detailed ? detailedGls : gls;
  }, [detailed, gls, detailedGls, displayOnlyDetailedLedgers]);

  const toggleDetailedPreview = () => {
    if (!displayOnlyDetailedLedgers) {
      setDetailed((previous) => !previous);
    }
  };

  if (!gls?.length && !detailedGls.length) {
    return <></>;
  }

  return (
    <div className='m-3 p-2 box-shadow'>
      <h6 style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <span>General Ledger</span>
        {!!detailedGls?.length && !displayOnlyDetailedLedgers && (
          <span className='ml-5'>
            <CustomSwitch name='toggler' label='Detailed Preview' onChange={toggleDetailedPreview} checked={detailed} />
          </span>
        )}
      </h6>
      <ReactTable
        data={data}
        columns={GeneralLedgerSubColumns}
        filterable={false}
        pageSize={pageSize}
        showPagination={false}
        defaultSorted={sortBy}
      />
    </div>
  );
}
