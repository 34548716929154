import { ChartOfAccountTable } from '../app/view/ChartOfAccount/ChartOfAccountTable';
import { JournalEntryTable } from '../app/view/JournalEntry/JournalEntryTable';
import TransactionsView from '../app/view/Transactions/All/TransactionView';
import ClientView from '../app/view/Client/ClientView';
import ProfitLossView from '../app/view/Report/ProfitLoss/ProfitLossView';
import BalanceSheetView from '../app/view/Report/BalanceSheet/BalanceSheetView';
import TrialBalanceView from '../app/view/Report/TrialBalance/TiralBalanceView';
import DepositTable from '../app/view/Deposit/DepositTableView';
import DepositComponent from '../app/view/Deposit/DepositComponent';
import SupportTableView from '../app/view/Settings/SupportTables/SupportTablesView';
import DepositClearingComponent from '../app/view/Deposit/components/DepositClearingComponent';
import BankReconciliationTable from '../app/view/BankReconciliation/BankReconciliationTable';
import BankReconciliationComponent from '../app/view/BankReconciliation/BankReconciliationComponent';
import FixedAssetsTable from '../app/view/FixedAssets/FixedAssetsTable';
import FixedAssetsMain from '../app/view/FixedAssets/FixedAssetsMain';
import AddEditComponentRecurrinEntry from '../app/view/RecurringJournalEntry/AddEditComponent';
import RecurringEntryTable from '../app/view/RecurringJournalEntry/TableView';
import { DepreciationItemsPreview } from '../app/view/FixedAssets/depreciationItemsPreview/DepreciationItemsPreview';
import { RoleTable } from '../app/view/Settings/Roles/RoleTable';
import { UserTable } from '../app/view/Settings/User/UserTable';
import { PendingTransactionsView } from '../app/view/Transactions/Pending/PendingTransactionsView';
import { DisablePostingTable } from '../app/view/Settings/DisablePosting/DisablePostingTable';
import * as functionalities from '../constants/permissions.constants';
import DepreciationReport from '../app/view/FixedAssets/DepreciationReport/DepreciationReport';
import { JournalEntryDrafts } from '../app/view/JournalEntryDraft/JournalEntryDraft';

export const routes = [
  {
    path: '/chart-of-account',
    name: 'Chart Of Account',
    component: ChartOfAccountTable,
    functionality: [functionalities.CHART_OF_ACCOUNTS],
  },
  {
    path: '/journal-entry',
    name: 'Journal Entry',
    component: JournalEntryTable,
    functionality: [functionalities.JOURNAL_ENTRY],
  },
  {
    path: '/journal-entry-drafts',
    name: 'Journal Entry Drafts',
    component: JournalEntryDrafts,
    functionality: [functionalities.JOURNAL_ENTRY],
  },
  {
    path: '/transactions/all',
    name: 'All',
    component: TransactionsView,
    functionality: [functionalities.ALL_TRANSACTIONS],
  },
  {
    path: '/transactions/pending',
    name: 'Pending',
    component: PendingTransactionsView,
    functionality: [functionalities.PENDING_TRANSACTIONS],
  },
  {
    path: '/client/:clientType(customers|vendors)',
    name: 'Client',
    component: ClientView,
    functionality: [functionalities.CUSTOMERS, functionalities.VENDORS],
  },
  {
    path: '/fixed-assets',
    exact: true,
    name: 'fixed-assets',
    component: FixedAssetsTable,
    functionality: [functionalities.FIXED_ASSETS_TABLE],
  },
  {
    path: '/fixed-assets/:id',
    exact: false,
    name: 'fixed-assets-component',
    component: FixedAssetsMain,
    functionality: [functionalities.FIXED_ASSETS_TABLE],
  },
  {
    path: '/depreciation-items-preview',
    exact: true,
    name: 'depreciation-items-preview',
    component: DepreciationItemsPreview,
    functionality: [functionalities.DEPRECIATION_PREVIEW],
  },
  {
    path: '/depreciation-report',
    exact: true,
    name: 'depreciation-report',
    component: DepreciationReport,
    functionality: [functionalities.DEPRECIATION_REPORT],
  },
  {
    path: '/report/profit-loss',
    name: 'Profit Loss',
    component: ProfitLossView,
    functionality: [functionalities.PROFIT_AND_LOSS],
  },
  {
    path: '/report/balance-sheet',
    name: 'Balance Sheet',
    component: BalanceSheetView,
    functionality: [functionalities.BALANCE_SHEET],
  },
  {
    path: '/report/trial-balance',
    name: 'Trial balance',
    component: TrialBalanceView,
    functionality: [functionalities.TRIAL_BALANCE],
  },
  {
    path: '/settings/support-table',
    name: 'Support Table',
    component: SupportTableView,
    functionality: [functionalities.SUPPORT_TABLES],
  },
  {
    path: '/settings/roles',
    name: 'Roles Table',
    component: RoleTable,
    functionality: [functionalities.ROLES],
  },
  {
    path: '/settings/disable-posting',
    name: 'Disable Posting table',
    component: DisablePostingTable,
    functionality: [functionalities.DISABLE_POSTING],
  },
  {
    path: '/settings/users',
    name: 'User Table',
    component: UserTable,
    functionality: [functionalities.USERS],
  },
  {
    path: '/recurring',
    exact: true,
    name: '/recurring',
    component: RecurringEntryTable,
    functionality: [functionalities.RECURRING_JOURNAL_ENTRY],
  },
  {
    path: '/recurring/add/new',
    name: '/recurring/add/new',
    exact: true,
    component: AddEditComponentRecurrinEntry,
    functionality: [functionalities.RECURRING_JOURNAL_ENTRY],
  },
  {
    path: '/recurring/:recurringId',
    name: '/recurring/:recurringId',
    exact: true,
    component: AddEditComponentRecurrinEntry,
    functionality: [functionalities.RECURRING_JOURNAL_ENTRY],
  },
  {
    path: '/deposit',
    exact: true,
    name: '/deposit',
    component: DepositTable,
    functionality: [functionalities.DEPOSIT],
  },
  {
    path: '/deposit/add/new',
    name: '/deposit/add/new',
    exact: true,
    component: DepositComponent,
    functionality: [functionalities.DEPOSIT],
  },
  {
    path: '/deposit/:depositId',
    name: '/deposit/:depositId',
    exact: true,
    component: DepositComponent,
    functionality: [functionalities.DEPOSIT],
  },
  {
    path: '/deposit/:depositId/clearing/:depositItemId',
    name: '/deposit/:depositId/clearing/:depositItemId',
    exact: true,
    component: DepositClearingComponent,
    functionality: [functionalities.DEPOSIT],
  },
  {
    path: '/bank-reconciliation',
    exact: true,
    name: '/bank-reconciliation',
    component: BankReconciliationTable,
    functionality: [functionalities.BANK_RECONCILIATION],
  },
  {
    path: '/bank-reconciliation/reconciliation',
    exact: true,
    name: '/bank-reconciliation/reconciliation',
    component: BankReconciliationComponent,
    functionality: [functionalities.BANK_RECONCILIATION],
  },
  {
    path: '/bank-reconciliation/reconciliation/:recoId',
    exact: true,
    name: '/bank-reconciliation/reconciliation/:recoId',
    component: BankReconciliationComponent,
    functionality: [functionalities.BANK_RECONCILIATION],
  },
  {
    path: '/bank-reconciliation/report/:reconciliationId',
    exact: true,
    name: '/bank-reconciliation/report/:reconciliationId',
    component: BankReconciliationComponent,
    functionality: [functionalities.BANK_RECONCILIATION],
  },
];
