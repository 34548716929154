import React, { useEffect, useState } from 'react';
import Select from 'react-select';

export default function TransactionsDocumentTypeSelectBox({ name = null, onChange = () => {}, field = 'PRAZAN', domain = null }) {
  const [filter, setFilter] = useState(null);

  useEffect(() => {
    if (domain) {
      if (domain.toString() === 'customers' || domain.toString() === '1') setFilter(1);
      if (domain.toString() === 'vendors' || domain.toString() === '2') setFilter(2);
      if (domain.toString() === 'general' || domain.toString() === '3') setFilter(3);
      if (domain.toString() === 'fa' || domain.toString() === '4') setFilter(4);
      if (domain.toString() === 'loan' || domain.toString() === '5') setFilter(5);
    } else {
      setFilter(null);
    }
  }, [domain]);

  const transactionDocumentTypeRepository = [
    {
      'transactions.document_type_journal_entry_id': 1,
      'transactions.document_type_domain_id': 1,
      name: 'Customer Invoice',
    },
    {
      'transactions.document_type_journal_entry_id': 2,
      'transactions.document_type_domain_id': 1,
      name: 'Customer Payment',
    },
    {
      'transactions.document_type_journal_entry_id': 3,
      'transactions.document_type_domain_id': 1,
      name: 'Customer Credit Memo',
    },
    {
      'transactions.document_type_journal_entry_id': 4,
      'transactions.document_type_domain_id': 1,
      name: 'Customer Debit Memo',
    },
    {
      'transactions.document_type_journal_entry_id': 5,
      'transactions.document_type_domain_id': 1,
      name: 'Customer Refund',
    },

    {
      'transactions.document_type_journal_entry_id': 1,
      'transactions.document_type_domain_id': 2,
      name: 'Vendor Invoice',
    },
    {
      'transactions.document_type_journal_entry_id': 2,
      'transactions.document_type_domain_id': 2,
      name: 'Vendor Payment',
    },
    {
      'transactions.document_type_journal_entry_id': 3,
      'transactions.document_type_domain_id': 2,
      name: 'Vendor Credit Memo',
    },
    {
      'transactions.document_type_journal_entry_id': 4,
      'transactions.document_type_domain_id': 2,
      name: 'Vendor Debit Memo',
    },
    {
      'transactions.document_type_journal_entry_id': 5,
      'transactions.document_type_domain_id': 2,
      name: 'Vendor Refund',
    },

    {
      'transactions.document_type_journal_entry_id': 6,
      'transactions.document_type_domain_id': 3,
      name: 'Other Income',
    },
    {
      'transactions.document_type_journal_entry_id': 7,
      'transactions.document_type_domain_id': 3,
      name: 'Other Expense',
    },
    {
      'transactions.document_type_journal_entry_id': 8,
      'transactions.document_type_domain_id': 3,
      name: 'Transfer',
    },
    {
      'transactions.document_type_journal_entry_id': 9,
      'transactions.document_type_domain_id': 3,
      name: 'Close Entry',
    },
    {
      'transactions.document_type_journal_entry_id': 10,
      'transactions.document_type_domain_id': 3,
      name: 'Opening Entry',
    },

    {
      'transactions.document_type_journal_entry_id': 11,
      'transactions.document_type_domain_id': 4,
      name: 'FA Purchase',
    },
    {
      'transactions.document_type_journal_entry_id': 12,
      'transactions.document_type_domain_id': 4,
      name: 'FA Depreciation',
    },
    {
      'transactions.document_type_journal_entry_id': 13,
      'transactions.document_type_domain_id': 4,
      name: 'FA Appreciation',
    },
    {
      'transactions.document_type_journal_entry_id': 14,
      'transactions.document_type_domain_id': 4,
      name: 'FA DISPOSAL',
    },
    {
      'transactions.document_type_journal_entry_id': 15,
      'transactions.document_type_domain_id': 5,
      name: 'LOAN NEW',
    },
    {
      'transactions.document_type_journal_entry_id': 16,
      'transactions.document_type_domain_id': 5,
      name: 'LOAN INTEREST',
    },
    {
      'transactions.document_type_journal_entry_id': 17,
      'transactions.document_type_domain_id': 5,
      name: 'LOAN PAYMENT',
    },
  ];

  const domainFilter = (x) => {
    if (filter !== null) {
      return x['transactions.document_type_domain_id'] === filter;
    } else {
      return true;
    }
  };

  const clearObject = {
    'transactions.document_type_journal_entry_id': null,
    'transactions.document_type_domain_id': null,
  };

  /** build data for selectBox **/
  const options = Array.isArray(transactionDocumentTypeRepository)
    ? transactionDocumentTypeRepository.filter(domainFilter).map((key) => {
        return {
          value: {
            'transactions.document_type_journal_entry_id': key['transactions.document_type_journal_entry_id'],
            'transactions.document_type_domain_id': key['transactions.document_type_domain_id'],
          },
          label: key.name,
        };
      })
    : [];

  /** onChange remodeling normalization **/
  const selectHandler = (val, e) => {
    if (e.action === 'select-option') {
      let o = { target: { name: e.name, value: val.value } };
      onChange(o);
      return;
    }
    if (e.action === 'clear') {
      let o = { target: { name: e.name, value: clearObject } };
      onChange(o);
    }
  };

  const valueFilter = (x) => {
    try {
      return (
        x.value['transactions.document_type_journal_entry_id'] === field['transactions.document_type_journal_entry_id'] &&
        x.value['transactions.document_type_domain_id'] === field['transactions.document_type_domain_id']
      );
    } catch (e) {
      return false;
    }
  };

  return (
    <Select
      name={name}
      isClearable={true}
      options={options}
      value={options.filter(valueFilter)}
      noOptionsMessage={() => 'First Create Transaction Document Type'}
      placeholder={'Select'}
      onChange={selectHandler}
      isSearchable={true}
    />
  );
}
