import { Box, Button, DialogActions, DialogContent, Divider, Grid } from '@mui/material';
import moment from 'moment';
import queryString from 'query-string';
import React, { useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { CollapsableCard } from '../../../../../../../common/Card/CollapsableCard';
import { CustomInput } from '../../../../../../../common/CustomInput';
import { CustomSelect } from '../../../../../../../common/CustomSelect';
import { DatePickerComponent } from '../../../../../../../common/DatePicker/DatePicker';
import { CustomModal, useCustomModalToggle } from '../../../../../../../common/Dialog/CustomModal';
import { CustomCurrencyInput } from '../../../../../../../common/Inputs/CurrencyInput';
import { Loader } from '../../../../../../../common/Loader';
import { CustomPopup } from '../../../../../../../common/Popup/CustomPopup';
import DataGridComponent from '../../../../../../../component/DataTable/component/DataTableComponent';
import { formatDateForTable } from '../../../../../../../utils/formatDataForTable';
import { notificationDangerCustomTime } from '../../../../../../../utils/toastify';
import { useClient, useGetTransaction } from '../../../../clientHooks';
import { useTransactionsBillingPayment } from '../../../../ClientPayment/hooks';
import { paymentInfoColumns } from '../PaymentInfoComponent/paymentInfoColumns';
import { useDeleteAppliedAmount, useGetApplicationOfPayments, useGetPaymentInfo, useUpdateAppliedAmount } from '../transactionApiHooks';
import { applicationOfPaymentColumns } from './columns/applicationOfPaymentColumns';

const NewApplicationOfPaymentsComponent = () => {
  const params = useParams();
  const location = useLocation();
  const { isCreditMemo, isRefund, isPayment } = queryString.parse(location.search);

  const { open: isEditOpen, toggleCustomModal: toggleEditModal } = useCustomModalToggle();
  const [popupData, setPopupData] = React.useState({
    deleteAnchor: null,
    openDeletePopup: false,
  });
  const [editAmountData, setEditAmountData] = React.useState({
    clientId: params.clientId,
    dateApplied: null,
    paymentBalance: null,
    paymentAmount: null,
    appliedAmount: null,
    oldAppliedAmount: null,
    transactionClearingID: null,
    maxAppliedAmount: null,
    documentTypeJournalEntryId: null,
    isRefundOnCreditMemo: false,
  });
  const [cancelReason, setCancelReason] = useState(null);
  const [loader, setLoader] = React.useState(false);
  const [filters, setFilters] = React.useState({
    paymentId: params.paymentId,
    domainId: params.clientType === 'customers' ? 1 : 2,
    // page: 0,
    // pageSize: 10,
    clientId: params.clientId,
  });

  const { data: { data: clientData } = {} } = useClient(params.clientId, params.clientType);
  const { data: { data: paymentData } = {} } = useGetTransaction(params.clientId, params.paymentId);
  const { data: { data: tableData } = {} } = useGetApplicationOfPayments(filters);
  const { data: { data: paymentTableData } = {} } = useGetPaymentInfo({
    invoiceId: params.paymentId,
    domainId: params.clientType === 'customers' ? 1 : 2,
    page: 0,
    pageSize: 100,
    clientId: params.clientId,
  });
  const { data: { data: billingPaymentItem } = {} } = useTransactionsBillingPayment({
    clientType: params.clientType,
    transactionId: params.paymentId,
    enabled: params.clientType.toLowerCase() === 'vendors',
  });
  const { mutate: deleteAppliedAmountMutation } = useDeleteAppliedAmount(filters, params);
  const { mutate: updateAppliedAmountMutation } = useUpdateAppliedAmount(filters, params, () => setLoader(false));

  const appliedAmount = React.useMemo(() => {
    const normalizedAmount = Number(
      (
        Number((+paymentData?.amount - +paymentData?.balance).toFixed(2)) ||
        0 + Number(tableData?.data.reduce((acc, curr) => acc + +curr.applied_amount || 0, 0)?.toFixed(2)) ||
        0
      ).toFixed(2)
    );

    return normalizedAmount;
  }, [tableData, paymentData]);
  const cleanDataSource = React.useMemo(() => ({ data: [], page: 0, pageSize: 100, pageTotal: 0 }));
  const columnsPaymentData = React.useMemo(() => paymentInfoColumns({}), []);

  const transactionTypeLabel = useMemo(() => {
    if (isCreditMemo) {
      return 'Credit Memo';
    } else if (isPayment) {
      return 'Payment';
    } else if (isRefund) {
      return 'Refund';
    } else {
      ('Transaction');
    }
  }, [location.search]);

  const handleCancelReasonChange = (e) => {
    setCancelReason(e.value);
  };

  const onDeleteClick = (e, row) => {
    setPopupData({
      openDeletePopup: true,
      deleteAnchor: e.currentTarget,
      transactionClearingID: row.transactions_clearing_id,
    });
  };

  const onChangeAmountClick = (row) => {
    const appliedPaymentAmount =
      +paymentData?.amount - +appliedAmount > row.invoice_balance
        ? Number(row.invoice_balance).toFixed(2)
        : Number(+paymentData?.amount - +appliedAmount).toFixed(2);

    const maxAppliedAmount = parseFloat((+paymentData?.amount - +appliedAmount).toFixed(2));

    setEditAmountData((oldData) => ({
      ...oldData,
      invoiceBalance: row.invoice_balance,
      paymentBalance: (+paymentData?.amount - +appliedAmount).toFixed(2),
      paymentAmount: +paymentData?.amount,
      dateApplied: moment(paymentData.posting_date).format('YYYY-MM-DD'),
      appliedAmount: appliedPaymentAmount,
      maxAppliedAmount,
      oldAppliedAmount: +row.applied_amount,
      transactionClearingID: row.transactions_clearing_id,
      invoiceId: row['transactions.id'],
      transactionType: row.transaction_type,
      documentTypeJournalEntryId: row['transactions.document_type_journal_entry_id'],
    }));
    toggleEditModal();
  };

  const columns = React.useMemo(
    () => applicationOfPaymentColumns({ onChangeAmountClick, onDeleteClick, payingTransactionType: paymentData?.document_type_journal_entry_id }),
    [paymentData, appliedAmount]
  );

  const cancelDeleteClick = () => {
    setPopupData({ deleteAnchor: null, openDeletePopup: false, transactionClearingID: null });
    setCancelReason(null);
  };

  const confirmDeleteClick = () => {
    deleteAppliedAmountMutation({
      clientId: params.clientId,
      transactionClearingID: popupData.transactionClearingID,
      clientType: params.clientType,
      isCreditMemo,
      isPayment,
      isRefund,
      reason: cancelReason,
    });
    setPopupData({ deleteAnchor: null, openDeletePopup: false, transactionClearingID: null });
    setCancelReason(null);
  };

  const changeAppliedPaymentAmount = (value) => {
    let realValue = +value;

    if (editAmountData.isRefundOnCreditMemo) {
      if (+value > +editAmountData.maxAppliedAmount) {
        realValue = +editAmountData.maxAppliedAmount;
      }
    } else if (+value > +editAmountData.maxAppliedAmount) {
      // if payment trx allow application of max amount or lower
      if (['Payment'].includes(editAmountData.transactionType) && paymentData.amount > 0) {
        // Positive payment
        if (+value > +editAmountData.invoiceBalance) {
          realValue = +editAmountData.invoiceBalance;
        } else {
          realValue = +value;
        }
      } else {
        if (paymentData.amount < 0) {
          // If source transaction.amount is negative
          realValue = +value;
        } else {
          realValue = editAmountData.maxAppliedAmount;
        }
      }
    }

    setEditAmountData((oldData) => ({ ...oldData, appliedAmount: realValue }));
  };

  const handleDateChange = (date, name) => {
    setEditAmountData((oldData) => ({ ...oldData, [name]: date }));
  };

  const onFilterChange = (e) => {
    setFilters({ ...filters, ...e });
  };

  const onCancelApplyAmount = () => {
    setEditAmountData({
      clientId: params.clientId,
      dateApplied: null,
      paymentBalance: null,
      paymentAmount: null,
      appliedAmount: null,
      maxAppliedAmount: null,
      oldAppliedAmount: null,
      transactionClearingID: null,
      invoiceId: null,
      invoiceBalance: null,
      transactionType: null,
      documentTypeJournalEntryId: null,
    });
    toggleEditModal();
  };

  const onUpdateApplyAmount = () => {
    setLoader(true);
    if (!editAmountData.appliedAmount) {
      notificationDangerCustomTime('Applied Payment Amount must be greater than 0!', 5000);
      setLoader(false);
      return;
    }
    // if (+editAmountData.invoiceBalance - +editAmountData.appliedAmount < 0) {
    //   notificationDangerCustomTime(`Applied Payment Amount too high, please reduce it! Invoice Balance: $ ${+editAmountData.invoiceBalance}`, 5000);
    //   setLoader(false);
    //   return;
    // }

    const updateData = {
      invoiceId: editAmountData.invoiceId,
      dateApplied: editAmountData.dateApplied,
      clientId: editAmountData.clientId,
      clientType: params.clientType,
      appliedAmount: editAmountData.appliedAmount,
      // ['Customer Payment', 'Vendor Payment'].includes(editAmountData.transactionType) || editAmountData.isRefundOnCreditMemo
      //   ? -1 * editAmountData.appliedAmount
      //   : editAmountData.appliedAmount,
      oldAppliedAmount: editAmountData.oldAppliedAmount,
      transactionClearingID: editAmountData.transactionClearingID,
      release_date: moment().format('YYYY/MM/DD'),
      paymentId: params.paymentId,
      isCreditMemo,
      isPayment,
      isRefund,
    };

    updateAppliedAmountMutation(updateData);

    setEditAmountData({
      clientId: params.clientId,
      dateApplied: null,
      paymentBalance: null,
      paymentAmount: null,
      appliedAmount: null,
      maxAppliedAmount: null,
      oldAppliedAmount: null,
      transactionClearingID: null,
      invoiceId: null,
      invoiceBalance: null,
      transactionType: null,
      documentTypeJournalEntryId: null,
      isRefundOnCreditMemo: false,
    });
    toggleEditModal();
  };

  return (
    <>
      {loader && <Loader isFullScreen />}
      <Row className='mb-3 mt-2'>
        <Col>
          <CollapsableCard
            isExpanded
            headerTitle={`${transactionTypeLabel} Info`}
            titleTypographyProps={{ fontSize: '16px' }}
            sxCardHeader={{ padding: '12px' }}
            // addStyles={{ overflowY: 'visible !important' }}
          >
            <Row>
              <Col md='3'>
                <CustomInput
                  label={params.clientType.toLowerCase() === 'customers' ? 'Customer' : 'Vendor'}
                  value={clientData?.external_id + ' - ' + clientData?.name}
                  disabled
                  onChange={() => {}}
                />
              </Col>
              <Col md='3'>
                <CustomCurrencyInput label={'Amount'} value={paymentData?.amount} prefix={'$ '} disabled onChange={() => {}} />
              </Col>
              <Col md='3'>
                <CustomCurrencyInput label={'Applied Amount'} value={Number(appliedAmount).toFixed(2)} prefix={'$ '} disabled onChange={() => {}} />
              </Col>
              <Col md='3'>
                <CustomCurrencyInput
                  label={'Unapplied Amount'}
                  value={Number(paymentData?.amount - appliedAmount).toFixed(2)}
                  prefix={'$ '}
                  disabled
                  onChange={() => {}}
                />
              </Col>
            </Row>
            <Row>
              <Col md='3'>
                <CustomInput label='Document Number' value={paymentData?.document_number} disabled onChange={() => {}} />
              </Col>
              <Col md='3'>
                <DatePickerComponent
                  label='Posting Date'
                  selected={paymentData?.posting_date}
                  disabled
                  onChange={() => {}}
                  dateFormat={'MM/dd/yyyy'}
                />
              </Col>
            </Row>
            {params.clientType.toLowerCase() === 'vendors' && (
              <>
                {!isCreditMemo && (
                  <>
                    <Divider className='mt-3 mb-3' />
                    <Row className='mb-1'>
                      <Col>Additional Payment</Col>
                    </Row>
                    <Row>
                      <Col md='3'>
                        <CustomInput label={'Document Number'} value={billingPaymentItem?.at_document_number} disabled onChange={() => {}} />
                      </Col>
                      <Col md='3'>
                        <CustomInput
                          label={'Document Date'}
                          value={billingPaymentItem?.at_document_date ? formatDateForTable(billingPaymentItem.at_document_date) : null}
                          disabled
                          onChange={() => {}}
                        />
                      </Col>
                      <Col md='3'>
                        <CustomCurrencyInput label={'Amount'} prefix='$ ' value={billingPaymentItem?.at_amount} disabled onChange={() => {}} />
                      </Col>
                      <Col md='3'>
                        <CustomInput label={'Description'} value={billingPaymentItem?.at_description} disabled onChange={() => {}} />
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
            {paymentTableData?.data?.length ? (
              <>
                <Divider className='mt-3 mb-3' />
                <Row>
                  <Col>
                    <DataGridComponent
                      columns={columnsPaymentData}
                      loading={false}
                      dataSource={paymentTableData || cleanDataSource}
                      onFilterChange={onFilterChange}
                    />
                  </Col>
                </Row>
              </>
            ) : null}
          </CollapsableCard>
        </Col>
      </Row>
      <Row>
        <Col>
          <CollapsableCard
            isExpanded={true}
            headerTitle='Application of Payments'
            titleTypographyProps={{ fontSize: '16px' }}
            sxCardHeader={{ padding: '12px' }}
            // addStyles={{ overflowY: 'visible !important' }}
          >
            {/* <Row className='mb-2'>
          <Col className='d-flex justify-content-end'>
            <AddApplicationOfPayment filters={filters} unappliedPaymentAmount={paymentData?.amount - appliedAmount} />
          </Col>
        </Row> */}
            <DataGridComponent
              defaultPageSize={100}
              columns={columns}
              loading={false}
              dataSource={tableData || cleanDataSource}
              onFilterChange={onFilterChange}
            />
          </CollapsableCard>
        </Col>
      </Row>
      <CustomModal
        modalTitle='Apply Amount'
        modalSize='md'
        toggleCustomModal={onCancelApplyAmount}
        isOpen={isEditOpen}
        actionButtons={
          <div className='d-flex justify-content-between w-100'>
            <Button color='error' onClick={onCancelApplyAmount}>
              Cancel
            </Button>
            <Button color='success' onClick={onUpdateApplyAmount}>
              Save
            </Button>
          </div>
        }
      >
        <Row>
          <Col md='6'>
            <CustomCurrencyInput
              label={`Applied ${transactionTypeLabel} Amount`}
              value={editAmountData.appliedAmount}
              prefix={'$ '}
              onChange={changeAppliedPaymentAmount}
            />
          </Col>
          <Col md='6'>
            <DatePickerComponent
              onChange={(date) => handleDateChange(date, 'dateApplied')}
              showYearDropdown
              minDate={paymentData?.posting_date}
              name='date_applied'
              selected={editAmountData.dateApplied}
              placeholder='mm/dd/yyyy'
              dateFormat={'MM/dd/yyyy'}
              label='Date Applied'
            />
          </Col>
        </Row>
        <Row>
          <Col md='6'>
            <CustomCurrencyInput
              label={`${transactionTypeLabel} Balance`}
              value={Number(paymentData?.amount - appliedAmount).toFixed(2)}
              prefix={'$ '}
              disabled
              onChange={() => {}}
            />
          </Col>
          <Col md='6'>
            <CustomCurrencyInput label={`${transactionTypeLabel} Amount`} value={paymentData?.amount} prefix={'$ '} disabled onChange={() => {}} />
          </Col>
        </Row>
      </CustomModal>
      <CustomPopup
        anchor={popupData.deleteAnchor}
        content={
          <UnapplyPopupContent
            onConfirm={confirmDeleteClick}
            onCancel={cancelDeleteClick}
            onSelect={handleCancelReasonChange}
            cancelReason={cancelReason}
          />
        }
        arrow
        placement='bottom'
        onClose={cancelDeleteClick}
        open={popupData.openDeletePopup}
      />
    </>
  );
};

const UnapplyPopupContent = ({ onConfirm, onCancel, onSelect = () => {}, cancelReason }) => {
  const cancelPaymentApplicationReasons = useMemo(() => {
    return [
      { name: 'User Error', value: 1 },
      { name: 'Bounced Payment', value: 2 },
    ];
  }, []);

  return (
    <Box>
      <DialogContent sx={{ padding: '5px' }}>Are you sure you wish to unapply payment amount?</DialogContent>
      <DialogActions sx={{ padding: '5px' }}>
        <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          <Grid item xs={12} sm={6} md={6}>
            <CustomSelect
              // label='Reason'
              borderBottomOnly
              onChange={onSelect}
              name='cancelReason'
              defaultValue={cancelReason}
              options={cancelPaymentApplicationReasons || []}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Button color='success' disabled={!cancelReason} onClick={onConfirm}>
              unapply
            </Button>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Button onClick={onCancel} color='error'>
              cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Box>
  );
};

export { NewApplicationOfPaymentsComponent };
