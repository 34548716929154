import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { notificationSuccess } from '../../../utils/toastify.js';
import { normalizeClientType } from './utils.js';
import { saveAs } from 'file-saver';

const getClients = (clientType, filters) => {
  const normalizedClientType = normalizeClientType(clientType);
  const url = `/me/client/${normalizedClientType}/with-balance`;
  return axios.get(url, { params: { ...filters } });
};

export const useGetClients = (clientType, filters) => {
  const { page, pageSize, sort_by, order_by } = filters;
  return useQuery(['clientList.table', page, pageSize, sort_by, order_by], () => getClients(clientType, filters), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

// todo remove it
const createClient = (clientType, data) => {
  const normalizedClientType = normalizeClientType(clientType);
  const url = `/me/client/${normalizedClientType}/`;
  return axios.post(url, data);
};

export const useCreateClient = (clientType, filter) => {
  const queryClient = useQueryClient();
  const { page, pageSize, sort_by, order_by } = filter;

  return useMutation((data) => createClient(clientType, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['clientList.table', page, pageSize, sort_by, order_by, clientType]);
    },
  });
};

const updateClient = (clientType, clientId, data) => {
  const normalizedClientType = normalizeClientType(clientType);
  const url = `/me/client/${normalizedClientType}/${clientId}`;

  return axios.patch(url, data);
};

export const useUpdateClient = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(({ clientType, clientId, data }) => updateClient(clientType, clientId, data), {
    onSuccess: () => {
      if (!!callbacks?.successCallback && typeof callbacks?.successCallback === 'function') {
        callbacks.successCallback();
      }
      queryClient.invalidateQueries(['client.additionalInfo']);
      notificationSuccess('Update Successful');
    },
    onError: () => {
      if (!!callbacks?.errorCallback && typeof callbacks?.errorCallback === 'function') {
        callbacks.errorCallback();
      }
    },
  });
};

const getClient = (clientId, clientType) => {
  if (!clientId) {
    return null;
  }

  const normalizedClientType = normalizeClientType(clientType);
  const url = `/me/client/${normalizedClientType}/${clientId}`;
  return axios.get(url);
};

export const useClient = (clientId, clientType) => {
  return useQuery(['client', clientId, clientType], () => getClient(clientId, clientType), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const getTransaction = (clientId, transactionId) => {
  return axios.get(`/me/client/customer/${clientId}/transactions/${transactionId}`);
};

export const useGetTransaction = (clientId, transactionId) => {
  return useQuery(['get-one-transaction', clientId, transactionId], () => getTransaction(clientId, transactionId), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const getExportOptions = (clientType) => {
  const normalizedClientType = normalizeClientType(clientType);
  const url = `/me/client/${normalizedClientType}/export-options`;
  return axios.get(url);
};

export const useExportOptions = (clientType) => {
  return useQuery(['client.export-options', clientType], () => getExportOptions(clientType), {
    keepPreviousData: true,
    refetchOnMount: false,
  });
};

const exportClients = (clientType, options, filters) => {
  const normalizedClientType = normalizeClientType(clientType);
  const url = `/me/client/${normalizedClientType}/custom-export`;

  return axios.post(url, { options }, { responseType: 'blob', params: { ...filters } });
};

export const useCustomClientExport = (clientType, filters) => {
  return useMutation((data) => exportClients(clientType, data, filters), {
    onSuccess: (res) => {
      let filename = 'Clients';
      if (res.status === 200) {
        if (res.headers['content-disposition']) {
          const [_, originalFileName] = res.headers['content-disposition'].split('filename=');
          filename = originalFileName;
        }

        saveAs(new Blob([res.data]), filename);
      }
    },
  });
};
