import React, { memo, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Button, FormControlLabel, Checkbox, Divider, TextField, Modal } from '@mui/material';
import { DatePickerComponent } from '../../../../common/DatePicker/DatePicker';
import { CustomInput } from '../../../../common/CustomInput';
import { CustomCurrencyInput } from '../../../../common/Inputs/CurrencyInput';
import { CustomSelect } from '../../../../common/CustomSelect';
import { CustomPageableAsyncSelect } from '../../../../common/Inputs/CustomPageableAsyncSelect';
import { CustomPageableAsyncSelect as AsyncSelect } from '../../../../common/Inputs/v2/AsyncSelect2';
import { CustomModal } from '../../../../common/Dialog/CustomModal';
import { useAsyncPageable } from '../../../../hooks/useAsyncPageable';
import { accountTypeOptions, ACC_TYPE_CUSTOMER, ACC_TYPE_GL_ACCOUNT, ACC_TYPE_VENDOR } from '../../../../constants/accountType.constants';
import {
  recurringTransactionTypesOptions,
  getRecurringTransactionTypes,
  CUSTOMER_INVOICE,
  CUSTOMER_CREDIT_MEMO,
  CUSTOMER_PAYMENT,
  VENDOR_CREDIT_MEMO,
  VENDOR_INVOICE,
  VENDOR_PAYMENT,
  CUSTOMER_REVERSING_PAYMENT,
  VENDOR_REVERSING_PAYMENT,
  JOURNAL_ENTRY,
} from '../../../../constants/transactionTypesRecurring.constants';
import { useGetSupportTablesForSelect } from '../../../../hooks/commonApiHooks';
import { useGetTransactionsByClientId, useCreateJournalEntry, useSaveAsDraft } from '../journalEntryApiHooks';
import DataTableComponent from '../../../../component/DataTable/component/DataTableComponent';
import { BRANCH_TABLE, DEPOSIT_CATEGORIES_TABLE, PAYMENT_SOURCE, TRANSACTION_TYPES } from '../../../../constants/supportTables.constants';
import { Loader } from '../../../../common/Loader';
import { validateJournalEntryQueueItem } from '../validators';
import { QueueTableColumns } from '../tableColumns/queueTableColumns';
import { CollapsableCard } from '../../../../common/Card/CollapsableCard';
import { notificationDanger, notificationDangerCustomTime, notificationSuccess } from '../../../../utils/toastify';
import { useGetBankAccount } from '../../RecurringJournalEntry/apiHooks';
import { Box } from '@mui/system';
import { hasPermissionHook } from '../../../../hooks/hasPermission';

const blankData = {
  transaction_type: null,
  client_id: {
    value: null,
    label: '',
  },
  account_type: null,
  account_number: {
    value: null,
    label: '',
  },
  amount: null,
  description: '',
  posting_date: moment().format('YYYY/MM/DD'),
  branch_id: null,
};

const AddJournalEntryModal = ({
  tableFilters,
  toggleModal,
  isModalOpen,
  saveAsDraft = false,
  queueData,
  draftId,
  deleteDraft,
  baseDraftId,
  saveDraftChanges = false,
  handleUpdateDraft,
  modalTitle = 'Add New Journal Entry',
}) => {
  const { fetchOptions } = useAsyncPageable();
  const params = useParams();

  const [insertData, setInsertData] = React.useState([]);
  const [queue, setQueue] = React.useState({ data: [], page: 0, pageSize: 100, pageTotal: 0 });
  const [total, setTotal] = React.useState({ total_credit_amount: 0, total_debit_amount: 0 });
  const [data, setData] = React.useState(blankData);
  const [errors, setErrors] = React.useState({});
  const [asyncKey, setAsyncKey] = React.useState(Date.now());
  const [draftsName, setDraftsName] = React.useState('');
  const [isDraftModalOpen, setDraftModalOpen] = React.useState(false);

  const [commonData, setCommonData] = React.useState({
    document_number: '',
    client_id: null,
    is_reversing: false,
    reverse_date: null,
    transaction_type: null,
    account_type: null,
  });
  const [editMode, setEditMode] = React.useState({
    isEdit: false,
    inputClicked: false,
    clientIdInputClicked: false,
    id: null,
    initialData: {},
  });

  const filteredAccountTypeOptions = React.useMemo(() => {
    if ([CUSTOMER_INVOICE, CUSTOMER_CREDIT_MEMO, CUSTOMER_PAYMENT, CUSTOMER_REVERSING_PAYMENT].includes(data.transaction_type)) {
      return accountTypeOptions.filter((item) => item.value !== ACC_TYPE_VENDOR);
    } else if ([VENDOR_CREDIT_MEMO, VENDOR_INVOICE, VENDOR_PAYMENT, VENDOR_REVERSING_PAYMENT].includes(data.transaction_type)) {
      return accountTypeOptions.filter((item) => item.value !== ACC_TYPE_CUSTOMER);
    } else {
      return accountTypeOptions.filter((item) => item.value === ACC_TYPE_GL_ACCOUNT);
    }
  }, [data.transaction_type]);

  const cleanup = () => {
    setQueue({ data: [], page: 0, pageSize: 100, pageTotal: 0 });
    setInsertData([]);
    setTotal({ total_credit_amount: 0, total_debit_amount: 0 });
    setData(blankData);
    setErrors({});
    setCommonData({
      document_number: '',
      client_id: null,
      is_reversing: false,
      reverse_date: null,
      account_number: '',
    });
  };

  const createJournalEntrySuccessCb = () => {
    cleanup();
    notificationSuccess('Successfully created Journal Entries');
    toggleModal();

    if (deleteDraft) deleteDraft(baseDraftId);
  };

  const { mutate: createJournalEntryMutation } = useCreateJournalEntry(createJournalEntrySuccessCb);

  const { data: bankAccounts } = useGetBankAccount();
  const { data: { data: supportTableOptions = {} } = {} } = useGetSupportTablesForSelect({
    table_names: [TRANSACTION_TYPES, PAYMENT_SOURCE, BRANCH_TABLE],
  });

  const { hasPermission, isPostingDisabled } = hasPermissionHook();

  const onDeleteClick = (row) => {
    setTotal((oldData) => ({
      total_credit_amount: +(oldData.total_credit_amount - row.credit_amount).toFixed(2),
      total_debit_amount: +(oldData.total_debit_amount - row.debit_amount).toFixed(2),
    }));
    setQueue((oldData) => ({ ...oldData, data: oldData.data.filter((item) => item.id !== row.id) }));
    setInsertData((oldData) => oldData.filter((item) => item.id !== row.id));
  };

  const onEditClick = (row) => {
    const selectedRow = insertData.find((item) => item.id === row.id);
    setEditMode((oldData) => ({
      ...oldData,
      isEdit: true,
      id: row.id,
      account_number: row.account_number,
      client_id: row.client_id,
      initialData: {
        transaction_type: selectedRow.transaction_type,
        account_type: selectedRow.account_type,
        client_id: {
          value: selectedRow.client_id,
          label: row.client_id,
        },
        account_number: {
          value: selectedRow.account_number,
          label: row.account_number,
        },
        debit_amount: selectedRow.debit_amount,
        credit_amount: selectedRow.credit_amount,
        description: selectedRow.description,
        posting_date: selectedRow.posting_date,
        branch_id: selectedRow.branch_id,
      },
    }));
    setData((oldData) => ({
      ...oldData,
      transaction_type: selectedRow.transaction_type,
      account_type: selectedRow.account_type,
      client_id: {
        value: selectedRow.client_id,
        label: row.client_id,
      },
      account_number: {
        value: selectedRow.account_number,
        label: row.account_number,
      },
      amount: +selectedRow.debit_amount > 0 ? selectedRow.debit_amount : +selectedRow.credit_amount * -1,
      description: selectedRow.description,
      posting_date: selectedRow.posting_date,
      branch_id: selectedRow.branch_id,
    }));
  };

  const onDuplicateClick = (row) => {
    const id = Date.now();
    setQueue((oldData) => ({ ...oldData, data: [...oldData.data, { ...row, id }] }));

    const accNumber = +row.account_number.split('-')[0].trim();

    let clientId = null;
    if (row.client_id) {
      clientId = insertData.find((item) => item.id === row.id)?.client_id;
    }
    setInsertData((oldData) => [...oldData, { ...row, account_number: accNumber, client_id: clientId, id }]);

    setTotal((oldData) => ({
      total_credit_amount: +(oldData.total_credit_amount + +row.credit_amount || 0).toFixed(2),
      total_debit_amount: +(oldData.total_debit_amount + +row.debit_amount || 0).toFixed(2),
    }));
  };

  const columns = React.useMemo(() => QueueTableColumns({ onDeleteClick, onEditClick, onDuplicateClick }), [insertData, queue.data, queue]);

  const VendorOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/client/vendor/options-number-for-select', { q: inputValue, ...options, client_type_id: 2 });
    return data;
  };
  const CustomerOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/client/customer/options-number-for-select', { q: inputValue, ...options, client_type_id: 1 });
    return data;
  };
  const GlAccountOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/account/options/accounts', { q: inputValue, ...options });
    return data;
  };

  const handleChangeSelect = (val, e) => {
    if (e.name === 'transaction_id') {
      return setData((oldData) => ({
        ...oldData,
        [e.name]: {
          value: val ? val.value : null,
          label: val ? val.label : '',
        },
        document_number: val ? val.document_number : null,
      }));
    }
    setData((oldData) => ({
      ...oldData,
      [e.name]: {
        value: val ? val.value : null,
        label: val ? val.label : '',
      },
    }));
  };

  const handleDateChange = (date, name) => {
    setData((oldData) => ({ ...oldData, [name]: date }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((oldData) => ({ ...oldData, [name]: value }));
  };

  const handleSelectChange = (val, e) => {
    if (e.action === 'select-option') {
      if (e.name === 'account_type') {
        setData((oldData) => ({ ...oldData, [e.name]: val.value, client_id: { value: null, label: null } }));
        setEditMode((oldData) => ({ ...oldData, client_id: '' }));
      } else {
        setData((oldData) => ({ ...oldData, [e.name]: val.value }));
      }
    } else if (e.action === 'clear') {
      if (e.name === 'account_type') {
        setData((oldData) => ({ ...oldData, [e.name]: null, client_id: { value: null, label: null } }));
        setEditMode((oldData) => ({ ...oldData, client_id: '' }));
      } else {
        setData((oldData) => ({ ...oldData, [e.name]: null }));
      }
    }
  };

  const handleCurrencyInputChange = (value, name) => {
    setData((oldData) => ({ ...oldData, [name]: value }));
  };

  const handleChangeCommonData = (e, name) => {
    let value;
    if (name === 'document_number') {
      // handle document_date
      setCommonData((oldData) => ({ ...oldData, [name]: e.target.value }));
      value = e.target.value;
    } else if (name === 'is_reversing') {
      // handle is_reversing
      setQueue((oldData) => ({
        ...oldData,
        data: oldData.data.map((item) => {
          (item[name] = e.target.checked), (item['reverse_date'] = e.target.checked ? commonData.reverse_date : null);
          return item;
        }),
      }));
      setInsertData((oldData) =>
        oldData.map((item) => {
          (item[name] = e.target.checked), (item['reverse_date'] = e.target.checked ? commonData.reverse_date : null);
          return item;
        })
      );
      setCommonData((oldData) => ({ ...oldData, [name]: e.target.checked }));
      return;
    } else if (name === 'client_id') {
      setCommonData((prev) => ({ ...prev, [name]: e }));
    } else {
      // handle reverse_date
      setCommonData((oldData) => ({ ...oldData, [name]: e }));
      value = e;
    }

    setQueue((oldData) => ({
      ...oldData,
      data: oldData.data.map((item) => {
        if (name === 'client_id') {
          item[name] = e.label;
        } else {
          item[name] = value;
        }
        return item;
      }),
    }));
    setInsertData((oldData) =>
      oldData.map((item) => {
        if (name === 'client_id') {
          item[name] = e.value;
        } else {
          item[name] = value;
        }
        return item;
      })
    );
  };

  const validateTransactionRules = () => {
    const errors = {};
    const transactionType = insertData.find((el) => el.transaction_type !== JOURNAL_ENTRY)?.transaction_type;

    if (transactionType === VENDOR_INVOICE) {
      const data = insertData.find((el) => el.account_number === 21010 && el.amount < 0);
      if (!data) {
        const message = (
          <div>
            Record with <strong className='text-danger'>Document Number {insertData[0].document_number}</strong> must contains at least one G/L entry
            posting a credit to account 21010 that is less than 0.`
          </div>
        );

        errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
      }
    }

    if (transactionType === CUSTOMER_INVOICE) {
      const data = insertData.find((el) => el.account_number === 11110 && el.amount > 0);
      if (!data) {
        const message = (
          <div>
            Record with <strong className='text-danger'>Document Number {insertData[0].document_number}</strong> must contains at least one G/L entry
            posting a debit to account 11110 that is greater than 0.`
          </div>
        );

        errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
      }
    }

    if (transactionType === CUSTOMER_CREDIT_MEMO) {
      const data = insertData.find((el) => el.account_number === 11110 && el.amount < 0);
      if (!data) {
        const message = (
          <div>
            Record with <strong className='text-danger'>Document Number {insertData[0].document_number}</strong> must contains at least one G/L entry
            posting a credit to account 11110 that is less than 0.`
          </div>
        );

        errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
      }
    }

    if (transactionType === VENDOR_CREDIT_MEMO) {
      const data = insertData.find((el) => el.account_number === 21010 && el.amount > 0);
      if (!data) {
        const message = (
          <div>
            Record with <strong className='text-danger'>Document Number {insertData[0].document_number}</strong> must contains at least one G/L entry
            posting a debit to account 21010 that is greater than 0.`
          </div>
        );

        errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
      }
    }

    if (transactionType === CUSTOMER_PAYMENT) {
      let isExistDebitCashAccount = false;
      let isExistCreditAccount = false;
      for (const el of insertData) {
        if (bankAccounts?.data.includes(el.account_number) && el.amount > 0) {
          isExistDebitCashAccount = true;
        }
        if (el.account_number === 11110 && el.amount < 0) {
          isExistCreditAccount = true;
        }

        if (isExistCreditAccount && isExistDebitCashAccount) {
          break;
        }
      }

      if (!isExistDebitCashAccount) {
        const message = (
          <div>
            Record with <strong className='text-danger'>Document Number {insertData[0].document_number}</strong> must contains at least one G/L entry
            posting a debit to a cash account that is greater than 0.
          </div>
        );

        errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
      }
      if (!isExistCreditAccount) {
        const message = (
          <div>
            Record with <strong className='text-danger'>Document Number {insertData[0].document_number}</strong> must contains at least one G/L entry
            posting a credit to account 11110 that is less than 0.
          </div>
        );

        errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
      }
    }

    if (transactionType === VENDOR_PAYMENT) {
      let isExistCreditCashAccount = false;
      let isExistDebitAccount = false;
      for (const el of insertData) {
        if (bankAccounts?.data.includes(el.account_number) && el.amount < 0) {
          isExistCreditCashAccount = true;
        }
        if (el.account_number === 21010 && el.amount > 0) {
          isExistDebitAccount = true;
        }

        if (isExistDebitAccount && isExistCreditCashAccount) {
          break;
        }
      }

      if (!isExistCreditCashAccount) {
        const message = (
          <div>
            Record with <strong className='text-danger'>Document Number {insertData[0].document_number}</strong> must contains at least one G/L entry
            posting a credit to a cash account that is less than 0.
          </div>
        );

        errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
      }
      if (!isExistDebitAccount) {
        const message = (
          <div>
            Record with <strong className='text-danger'>Document Number {insertData[0].document_number}</strong> must contains at least one G/L entry
            posting a debit to account 21010 that is greater than 0.
          </div>
        );

        errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
      }
    }

    if (transactionType === CUSTOMER_REVERSING_PAYMENT) {
      let isExistCreditCashAccount = false;
      let isExistDebitAccount = false;
      for (const el of insertData) {
        if (el.account_number === 10103 && el.amount < 0) {
          isExistCreditCashAccount = true;
        }
        if (el.account_number === 11110 && el.amount > 0) {
          isExistDebitAccount = true;
        }

        if (isExistDebitAccount && isExistCreditCashAccount) {
          break;
        }
      }

      if (!isExistCreditCashAccount) {
        const message = (
          <div>
            Record with <strong className='text-danger'>Document Number {insertData[0].document_number}</strong> must contains at least one G/L entry
            posting a credit to cash account 10103 that is less than 0.
          </div>
        );

        errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
      }

      if (!isExistDebitAccount) {
        const message = (
          <div>
            Record with <strong className='text-danger'>Document Number {insertData[0].document_number}</strong> must contains at least one G/L entry
            posting a debit to account 11110 that is greater than 0.
          </div>
        );

        errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
      }
    }

    if (transactionType === VENDOR_REVERSING_PAYMENT) {
      let isExistDebitCashAccount = false;
      let isExistCreditAccount = false;
      for (const el of insertData) {
        if (bankAccounts.data.includes(el.account_number) && el.amount > 0) {
          isExistDebitCashAccount = true;
        }
        if (el.account_number === 21010 && el.amount < 0) {
          isExistCreditAccount = true;
        }

        if (isExistCreditAccount && isExistDebitCashAccount) {
          break;
        }
      }

      if (!isExistDebitCashAccount) {
        const message = (
          <div>
            Record with <strong className='text-danger'>Document Number {insertData[0].document_number}</strong> must contains at least one G/L entry
            posting a debit to a cash account that is greater than 0.
          </div>
        );

        errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
      }

      if (!isExistCreditAccount) {
        const message = (
          <div>
            Record with <strong className='text-danger'>Document Number {insertData[0].document_number}</strong> must contains at least one G/L entry
            posting a credit to account 21010 that is less than 0.
          </div>
        );

        errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
      }
    }

    const isClientTransactionType = [
      CUSTOMER_INVOICE,
      CUSTOMER_CREDIT_MEMO,
      CUSTOMER_PAYMENT,
      VENDOR_CREDIT_MEMO,
      VENDOR_INVOICE,
      VENDOR_PAYMENT,
      CUSTOMER_REVERSING_PAYMENT,
      VENDOR_REVERSING_PAYMENT,
    ].includes(transactionType);

    if (isClientTransactionType && !insertData[0].client_id) {
      const message = <div>Please select client</div>;
      errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
    }

    if (errors.transactionRules) {
      let notification = () => (
        <div>
          {errors.transactionRules.map((item, id) => (
            <React.Fragment key={item}>{item}</React.Fragment>
          ))}

          <strong>Bad Data</strong>
        </div>
      );
      notificationDangerCustomTime(notification, 15000);
      return false;
    }

    return true;
  };

  const onSave = () => {
    const datesArray = insertData.map((item) => item.posting_date);

    if (isPostingDisabled(datesArray)) {
      notificationDangerCustomTime('You are not allowed to post in this period. Please contact your superior for more info!', 10000);
      return;
    }

    if (total.total_credit_amount === total.total_debit_amount) {
      createJournalEntryMutation({
        insertData,
        document_number: commonData.document_number,
        total_credit_amount: total.total_credit_amount,
        // create_at: moment().format('YYYY'),
      });
    } else {
      notificationDangerCustomTime('Total Credit Amount and Total Debit Amount must be equal!', 5000);
    }
  };

  const onAddToQueue = () => {
    const validateData = {
      ...data,
      account_type: data.account_type,
      client_id: commonData.client_id?.value,
      transaction_type: data.transaction_type,
      debit_amount: +data.amount > 0 ? +data.amount : 0,
      credit_amount: +data.amount < 0 ? Math.abs(+data.amount) : 0,
      account_number: data.account_number.value,
      branch_id: data.branch_id,
      document_number: commonData.document_number,
      is_reversing: commonData.is_reversing,
      reverse_date: commonData.reverse_date,
    };

    const errorsValidation = validateJournalEntryQueueItem(validateData);
    if (errorsValidation) {
      setErrors(errorsValidation);
      return;
    }

    const queueData = {
      ...data,
      account_type: data.account_type,
      client_id: commonData.client_id?.label,
      transaction_type: data.transaction_type,
      debit_amount: +data.amount > 0 ? +data.amount : 0,
      credit_amount: +data.amount < 0 ? Math.abs(+data.amount) : 0,
      account_number: data.account_number.label,
      document_number: commonData.document_number,
      is_reversing: commonData.is_reversing,
      reverse_date: commonData.reverse_date,
      branch_id: data.branch_id,
      branch_name: data.branch_id ? supportTableOptions[BRANCH_TABLE].find((option) => option.value == data.branch_id)?.label : null,
    };

    const id = Date.now();
    if (!editMode.isEdit) {
      setQueue((oldData) => ({ ...oldData, data: [...oldData.data, { id, ...queueData }] }));
      setInsertData((oldData) => [...oldData, { id, ...validateData }]);
      setTotal((oldData) => ({
        total_credit_amount: +(oldData.total_credit_amount + (+data.amount < 0 ? Math.abs(+data.amount) : 0)).toFixed(2),
        total_debit_amount: +(oldData.total_debit_amount + (+data.amount > 0 ? +data.amount : 0)).toFixed(2),
      }));
      setData(() => ({ ...blankData }));
      setErrors(() => ({}));
      setAsyncKey(Date.now());
    } else {
      // setQueue((oldData) => ({ ...oldData, data: [...oldData.data, { id, ...queueData }] }));
      setQueue((oldData) => {
        const idx = oldData.data.findIndex((item) => item.id === editMode.id);
        const newData = [...oldData.data];
        newData[idx] = { id: id, ...queueData };
        return { ...oldData, data: newData };
      });
      // setInsertData((oldData) => [...oldData, { id, ...validateData }]);
      setInsertData((oldData) => {
        const idx = oldData.findIndex((item) => item.id === editMode.id);
        const newData = [...oldData];
        newData[idx] = { id: id, ...validateData };
        return [...newData];
      });
      setTotal((oldData) => ({
        total_credit_amount: +(
          oldData.total_credit_amount -
          editMode.initialData.credit_amount +
          (+data.amount < 0 ? Math.abs(+data.amount) : 0)
        ).toFixed(2),
        total_debit_amount: +(oldData.total_debit_amount - editMode.initialData.debit_amount + (+data.amount > 0 ? +data.amount : 0)).toFixed(2),
      }));
      setData(() => ({ ...blankData }));
      setErrors(() => ({}));
      setEditMode((oldData) => ({ isEdit: false, id: null, inputClicked: false, clientIdInputClicked: false, initialData: {} }));
    }
  };

  const toggleCustomModal = () => {
    cleanup();
    toggleModal();
    onCancelEdit();
  };

  const onCancelEdit = () => {
    setData({ ...blankData });
    setErrors({});
    setEditMode((oldData) => ({ isEdit: false, id: null, inputClicked: false, clientIdInputClicked: false, initialData: {} }));
  };

  useEffect(() => {
    if (queueData?.data?.[draftId]?.data) {
      const draftData = queueData.data[draftId].data;

      setQueue({
        data: draftData.data,
        page: 0,
        pageSize: 100,
        pageTotal: 0,
      });

      setInsertData(draftData.insertData);
      setCommonData(draftData.data[0]);

      const total = draftData.data.reduce(
        (acc, obj) => {
          const amount = parseInt(obj.amount);
          if (amount >= 0) {
            acc.total_debit_amount += amount;
          } else {
            acc.total_credit_amount += Math.abs(amount);
          }
          return acc;
        },
        { total_credit_amount: 0, total_debit_amount: 0 }
      );

      setTotal(total);
    } else {
      setTotal({ total_credit_amount: 0, total_debit_amount: 0 });
    }
  }, [queueData, toggleModal]);

  const renderSubbaccount = () => {
    if (editMode.isEdit && !editMode.clientIdInputClicked) {
      return (
        <CustomInput
          value={editMode.client_id}
          label='Subaccount'
          onChange={() => {}}
          onClick={() => {
            setEditMode((oldData) => ({ ...oldData, clientIdInputClicked: true }));
          }}
        />
      );
    }
    return (
      <>
        {data.account_type === ACC_TYPE_VENDOR && (
          <CustomPageableAsyncSelect
            label={'Subaccount'}
            initValue={commonData.client_id?.value || ''}
            defKey={`${commonData.client_id?.value}`}
            isClearable={false}
            onChange={(val) => handleChangeCommonData(val, 'client_id')}
            loadOptions={VendorOptions}
            name='client_id'
            // classNamePrefix='react-select-prefix'
            errorMess={errors?.client_id}
          />
        )}
        {data.account_type === ACC_TYPE_CUSTOMER && (
          <CustomPageableAsyncSelect
            label={'Subaccount'}
            initValue={commonData.client_id?.value || ''}
            defKey={`${commonData.client_id?.value}`}
            isClearable={false}
            onChange={(val) => handleChangeCommonData(val, 'client_id')}
            loadOptions={CustomerOptions}
            name='client_id'
            // classNamePrefix='react-select-prefix'
            errorMess={errors?.client_id}
          />
        )}
        {(!data.account_type || data.account_type === ACC_TYPE_GL_ACCOUNT) && (
          <CustomSelect label={'Subaccount'} onChange={() => {}} options={[]} defaultValue={null} />
        )}
      </>
    );
  };

  const { mutate: saveDrafts } = useSaveAsDraft();

  const handleSaveAsDraft = () => {
    saveDrafts({ draftsData: { data: queue.data, insertData }, draftsName });
    handleCloseDraftModal();
    setDraftsName('');
  };

  const handleOpenDraftModal = () => {
    setDraftModalOpen(true);
  };

  const handleCloseDraftModal = () => {
    setDraftModalOpen(false);
    setDraftsName('');
  };

  return (
    <>
      <CustomModal
        modalTitle={modalTitle}
        modalSize='xl'
        buttonClassName='btn-success'
        saveButtonTitle='Save'
        onSave={onSave}
        toggleCustomModal={toggleCustomModal}
        isOpen={isModalOpen}
        addStyles={{ PaperStyle: { overflowY: 'auto' }, ContainerStyle: { overflowY: 'scroll' } }}
        actionButtons={
          <>
            {editMode.isEdit ? (
              <div>
                <Button className='mr-2' color='error' onClick={onCancelEdit}>
                  Cancel Edit
                </Button>
                <Button color='success' onClick={onAddToQueue}>
                  Save edit
                </Button>
              </div>
            ) : (
              <div className='d-flex justify-content-between w-100'>
                <div>
                  <Button className='mr-2' color='error' onClick={toggleCustomModal}>
                    Cancel
                  </Button>
                </div>
                <div>
                  {saveAsDraft && (
                    <Button disabled={!insertData.length} color='info' className='mr-2' size='small' onClick={handleOpenDraftModal}>
                      Create Draft
                    </Button>
                  )}
                  {saveDraftChanges && (
                    <Button
                      color='info'
                      className='mr-2'
                      size='small'
                      onClick={() =>
                        handleUpdateDraft({
                          draftsData: { data: queue.data, insertData },
                          draftsName: queueData?.data[draftId]?.name,
                          draftId: baseDraftId,
                        })
                      }
                    >
                      Save Changes
                    </Button>
                  )}
                  <Button className='mr-2' size='small' onClick={onAddToQueue}>
                    Add to Queue
                  </Button>
                  <Button onClick={onSave} color={'success'} size='small' disabled={!insertData.length}>
                    post to gl
                  </Button>
                </div>
              </div>
            )}
          </>
        }
      >
        <Row className='mb-3'>
          <Col>
            <CollapsableCard
              headerTitle='Queue Data'
              isExpanded={true}
              titleTypographyProps={{ fontSize: '16px' }}
              sxCardContent={{ padding: '10px', maxHeight: '500px' }}
              sxCardHeader={{ padding: '14px' }}
              addStyles={{ overflowY: 'scroll !important' }}
            >
              <Row className='d-flex align-items-center'>
                <Col></Col>
                <Col md='3'>
                  <CustomCurrencyInput
                    className='currency-input-small'
                    name=''
                    value={total.total_debit_amount}
                    onChange={handleCurrencyInputChange}
                    prefix={'$ '}
                    label='Total Debit Amount'
                    placeholder='Total Debit Amount'
                    disabled
                  />
                </Col>
                <Col md='3'>
                  <CustomCurrencyInput
                    className='currency-input-small'
                    name=''
                    value={total.total_credit_amount}
                    onChange={handleCurrencyInputChange}
                    prefix={'$ '}
                    label='Total Credit Amount'
                    placeholder='Total Credit Amount'
                    disabled
                  />
                </Col>
              </Row>
              <DataTableComponent css={{ minWidth: '2000px' }} dataSource={queue} columns={columns} />
            </CollapsableCard>
          </Col>
        </Row>
        <Row className='mt-2 mb-2'>
          <Col>
            <Divider />
          </Col>
        </Row>
        <Row>
          <Col md='2'>
            <CustomSelect
              name='transaction_type'
              options={recurringTransactionTypesOptions}
              defaultValue={data.transaction_type}
              onChange={handleSelectChange}
              label='Transaction Type'
              isClearable
              errorMess={errors?.transaction_type}
            />
          </Col>
          <Col md='2'>
            <CustomSelect
              name='account_type'
              options={filteredAccountTypeOptions}
              defaultValue={data.account_type}
              onChange={handleSelectChange}
              label='Account Type'
              isClearable
              errorMess={errors?.account_type}
            />
          </Col>
          <Col md='3'>
            {editMode.isEdit && !editMode.inputClicked ? (
              <CustomInput
                value={editMode.account_number}
                label='Account Number'
                onChange={() => {}}
                onClick={() => {
                  setEditMode((oldData) => ({ ...oldData, inputClicked: true }));
                }}
              />
            ) : (
              <AsyncSelect
                key={asyncKey}
                label='Account Number'
                isClearable
                onChange={handleChangeSelect}
                loadOptions={GlAccountOptions}
                name='account_number'
                errorMess={errors?.account_number}
              />
            )}
          </Col>
          <Col md='3'>{renderSubbaccount()}</Col>
          <Col md='2'>
            <CustomCurrencyInput
              name='amount'
              value={data.amount || ''}
              onChange={handleCurrencyInputChange}
              prefix={'$ '}
              label='Amount'
              placeholder='Amount'
              errorMess={errors?.amount}
            />
          </Col>
        </Row>
        <Row className='d-flex align-items-center'>
          <Col md='2'>
            <CustomInput
              name='document_number'
              value={commonData.document_number}
              onChange={(e) => handleChangeCommonData(e, 'document_number')}
              placeholder='Document Number'
              label='Document Number'
              errorMess={errors?.document_number}
            />
          </Col>
          <Col md='2'>
            <DatePickerComponent
              showYearDropdown
              isClearable
              name='posting_date'
              selected={data.posting_date}
              onChange={(date) => handleDateChange(date, 'posting_date')}
              placeholder='mm/dd/yyyy'
              dateFormat={'MM/dd/yyyy'}
              label='Posting Date'
              errorMess={errors?.posting_date}
            />
          </Col>
          <Col md='2'>
            <CustomSelect
              name='branch_id'
              options={supportTableOptions[BRANCH_TABLE]}
              defaultValue={data.branch_id}
              onChange={handleSelectChange}
              label='Branch'
              isClearable
              errorMess={errors?.branch_id}
            />
          </Col>
          <Col md='2'>
            <CustomInput
              name='description'
              value={data.description}
              type={'textarea'}
              rows={3}
              onChange={handleInputChange}
              placeholder='Description'
              label='Description'
              errorMess={errors?.description}
            />
          </Col>
          <Col md='2'>
            <FormControlLabel
              sx={{ marginBottom: '7px' }}
              control={
                <Checkbox
                  onChange={(e) => handleChangeCommonData(e, 'is_reversing')}
                  name='is_reversing'
                  checked={commonData.is_reversing}
                  sx={{ paddingBottom: '10px' }}
                  color='warning'
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label='Is Reversing'
            />
          </Col>
          <Col md='2'>
            {commonData.is_reversing && (
              <DatePickerComponent
                showYearDropdown
                isClearable
                name='reverse_date'
                selected={commonData.reverse_date}
                onChange={(e) => handleChangeCommonData(e, 'reverse_date')}
                placeholder='mm/dd/yyyy'
                dateFormat={'MM/dd/yyyy'}
                label='Reverse Date'
                errorMess={errors?.reverse_date}
              />
            )}
          </Col>
        </Row>
      </CustomModal>
      {isDraftModalOpen && (
        <>
          <CustomModal
            modalTitle='Drafts Name'
            modalSize='xs'
            toggleCustomModal={handleCloseDraftModal}
            isOpen={isModalOpen}
            actionButtons={
              <Box className='d-flex justify-content-between w-100'>
                <Button variant='contained' color='error' onClick={handleCloseDraftModal}>
                  Close
                </Button>
                <Button disabled={draftsName === ''} variant='contained' onClick={handleSaveAsDraft}>
                  Save
                </Button>
              </Box>
            }
          >
            <TextField
              autoFocus
              label='Drafts Name'
              variant='outlined'
              fullWidth
              value={draftsName}
              onChange={(e) => setDraftsName(e.target.value)}
              helperText={draftsName ? '' : '*Drafts name is required.'}
            />
          </CustomModal>
        </>
      )}
    </>
  );
};

export { AddJournalEntryModal };
