import {
  AccountBalanceOutlined,
  ArticleOutlined,
  CreditScoreOutlined,
  PaymentOutlined,
  PeopleOutline,
  PersonSearchOutlined,
  ReportOutlined,
  SettingsOutlined,
  TableChartOutlined,
  WarehouseOutlined,
  PivotTableChartOutlined,
  Assessment as AssessmentIcon,
} from '@mui/icons-material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Col, Row } from 'reactstrap';
import { Warning } from '../common/Warning';
import * as permissions from '../constants/permissions.constants';
import BackupTableIcon from '@mui/icons-material/BackupTable';

const navigator = [
  // {
  //   id: 100,
  //   name: 'Snapshots',
  //   url: '/dashboard',
  //   icon: <Dashboard />,
  // },
  {
    functionality: permissions.CHART_OF_ACCOUNTS,
    name: 'Chart Of Accounts',
    url: '/chart-of-account',
    icon: <CreditScoreOutlined />,
  },
  {
    functionality: permissions.JOURNAL_ENTRY,
    name: 'Journal Entry',
    icon: <TableChartOutlined />,
    children: [
      {
        functionality: permissions.JOURNAL_ENTRY,
        name: 'Journal Entry',
        url: '/journal-entry',
        icon: <TableChartOutlined />,
      },
      {
        functionality: permissions.RECURRING_JOURNAL_ENTRY,
        name: 'Recurring Journal Entry',
        url: '/recurring',
        icon: <PivotTableChartOutlined />,
      },
      {
        functionality: permissions.JOURNAL_ENTRY,
        name: 'Journal Entry Drafts',
        url: '/journal-entry-drafts',
        icon: <BackupTableIcon />,
      },
    ],
  },

  {
    functionality: permissions.ACCOUNT_RECEIVABLES,
    name: 'Accounts Receivables',
    icon: <PeopleOutline />,
    children: [
      {
        functionality: permissions.CUSTOMERS,
        name: 'Customers',
        url: '/client/customers/',
        icon: <PeopleOutline />,
      },
      {
        functionality: permissions.CUSTOMER_AGING,
        name: 'Customer Aging',
        url: '/client/customers/aging',
        icon: <PeopleOutline />,
      },
      // {
      //   functionality: permissions.CUSTOMER_INVOICE_TRACKER,
      //   name: (
      //     <Row>
      //       <Col>
      //         <Warning /> Customer Invoice Tracker
      //       </Col>
      //     </Row>
      //   ),
      //   url: '/client/customers/invoices-tracker',
      //   icon: <PeopleOutline />,
      // },
    ],
  },
  {
    functionality: permissions.ACCOUNT_PAYABLE,
    name: 'Accounts Payable',
    icon: <PersonSearchOutlined />,
    children: [
      {
        functionality: permissions.VENDORS,
        name: 'Vendors',
        url: '/client/vendors/',
        icon: <PersonSearchOutlined />,
      },
      {
        functionality: permissions.PAY_BILLS,
        name: 'Pay Bills',
        icon: <PaymentOutlined />,
        url: '/client/vendors/payment',
      },
      {
        functionality: permissions.VENDOR_AGING,
        name: 'Vendor Aging',
        url: '/client/vendors/aging',
        icon: <PersonSearchOutlined />,
      },
      // {
      //   functionality: permissions.VENDOR_INVOICE_TRACKER,
      //   name: (
      //     <Row>
      //       <Col>
      //         <Warning /> Vendor Invoice Tracker
      //       </Col>
      //     </Row>
      //   ),
      //   url: '/client/vendors/invoices-tracker',
      //   icon: <PersonSearchOutlined />,
      // },
    ],
  },
  {
    functionality: permissions.BANKING,
    name: 'Banking',
    icon: <AccountBalanceOutlined />,
    children: [
      {
        functionality: permissions.DEPOSIT,
        name: 'Deposit',
        url: '/deposit',
        icon: <AccountBalanceOutlined />,
      },
      {
        functionality: permissions.BANK_RECONCILIATION,
        name: 'Bank Reconciliation',
        url: '/bank-reconciliation',
        icon: <AccountBalanceOutlined />,
      },
    ],
  },
  {
    functionality: permissions.TRANSACTIONS,
    name: (
      <Row>
        <Col>Transactions</Col>
      </Row>
    ),
    icon: <ArticleOutlined />,
    children: [
      {
        functionality: permissions.ALL_TRANSACTIONS,
        name: 'All',
        url: '/transactions/all',
        icon: <ArticleOutlined />,
      },
      {
        functionality: permissions.PENDING_TRANSACTIONS,
        name: 'Pending',
        url: '/transactions/pending',
        icon: <ArticleOutlined />,
      },
    ],
  },
  {
    functionality: permissions.FIXED_ASSETS,
    name: 'Fixed Assets',
    icon: <WarehouseOutlined />,
    children: [
      {
        functionality: permissions.FIXED_ASSETS_TABLE,
        name: 'Fixed Assets',
        url: '/fixed-assets',
        icon: <WarehouseOutlined />,
      },
      {
        functionality: permissions.DEPRECIATION_PREVIEW,
        name: 'Depreciation Preview',
        url: '/depreciation-items-preview',
        icon: <RemoveRedEyeIcon />,
      },
      {
        functionality: permissions.DEPRECIATION_REPORT,
        name: 'Depreciation Report',
        url: '/depreciation-report',
        icon: <AssessmentIcon />,
      },
    ],
  },
  {
    functionality: permissions.REPORTS,
    name: 'Reports',
    icon: <ReportOutlined />,
    children: [
      {
        functionality: permissions.BALANCE_SHEET,
        name: 'Balance Sheet',
        url: '/report/balance-sheet',
        icon: <ReportOutlined />,
      },
      {
        functionality: permissions.PROFIT_AND_LOSS,
        name: 'Profit & Loss',
        url: '/report/profit-loss',
        icon: <ReportOutlined />,
      },
      {
        functionality: permissions.TRIAL_BALANCE,
        name: 'Trial Balance',
        url: '/report/trial-balance',
        icon: <ReportOutlined />,
      },
    ],
  },
  {
    functionality: permissions.SETTINGS,
    name: 'Setting',
    icon: <SettingsOutlined />,
    children: [
      {
        functionality: permissions.ROLES,
        name: 'Roles',
        url: '/settings/roles',
        icon: <SettingsOutlined />,
      },
      {
        functionality: permissions.USERS,
        name: 'Users',
        url: '/settings/users',
        icon: <SettingsOutlined />,
      },
      {
        functionality: permissions.SUPPORT_TABLES,
        name: 'Support Table',
        url: '/settings/support-table',
        icon: <SettingsOutlined />,
      },
      {
        functionality: permissions.DISABLE_POSTING,
        name: 'Disable Posting',
        url: '/settings/disable-posting',
        icon: <SettingsOutlined />,
      },
    ],
  },
];
export { navigator };
