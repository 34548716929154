// incomes
const INCOME = 1;
const OTHER_INCOME = 5;

// expenses
const NONE = 0;
const COST_OF_GOODS_SOLD = 2;
const EXPENSE = 3;
const OTHER_EXPENSE = 4;
const ADMIN_EXPENSES = 6;

export const getTypeNameById = (id) => {
  const parsedId = id?.toString();

  switch (parsedId) {
    case '1':
      return 'Income';
    case '2':
      return 'Cost Of Goods Sold';
    case '0':
      return 'None';
    case '3':
      return 'Expense';
    case '6':
      return 'Admin Expenses';
    case '5':
      return 'Other Income';
    case '4':
      return 'Other Expense';
    default:
      return '';
  }
};

export const accountProfitLossType = { INCOME, COST_OF_GOODS_SOLD, NONE, EXPENSE, ADMIN_EXPENSES, OTHER_INCOME, OTHER_EXPENSE };
export const incomeAccountTypes = [INCOME, OTHER_INCOME];
export const expenseAccountTypes = [EXPENSE, OTHER_EXPENSE, ADMIN_EXPENSES, COST_OF_GOODS_SOLD];
