export const shouldApplyToTableOptions = [
  {
    label: 'Checks & Deposits',
    value: 1,
  },
  {
    label: 'Deposits',
    value: 2,
  },
  {
    label: 'Checks',
    value: 3,
  },
];

export const amountOptions = [
  {
    label: '=',
    value: 1,
  },
  {
    label: '>=',
    value: 2,
  },
  {
    label: '<=',
    value: 3,
  },
  {
    label: 'From & To',
    value: 4,
  },
];

const currencyInputMapper = new Map();
currencyInputMapper.set(1, ['amountEqual']);
currencyInputMapper.set(2, ['amountGreater']);
currencyInputMapper.set(3, ['amountLess']);
currencyInputMapper.set(4, ['amountFrom', 'amountTo']);

export const getCurrencyInputName = (id) => {
  return currencyInputMapper.get(id);
};
