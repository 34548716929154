import { formatAmount } from '../../../../../utils/formatAmout';
import { formatDateForTable } from '../../../../../utils/formatDataForTable';

export const columnsForCustomers = [
  {
    accessor: 'external_id',
    Header: (props) => <div>ID</div>,
  },
  {
    Header: <div>Name</div>,
    accessor: 'name',
  },
  { accessor: 'address', Header: <div>Address</div> },
  { accessor: 'branch', Header: <div>Branch</div> },
  { accessor: 'cfs', Header: <div>CFS</div>, Cell: (props) => <div>{props.value ? 'Yes' : 'No'}</div> },
  { accessor: 'ach', Header: <div>ACH</div>, Cell: (props) => <div>{props.value ? 'Yes' : 'No'}</div> },
  {
    accessor: 'balance',
    Header: (props) => <div>Balance</div>,
    Cell: (props) => <div>{formatAmount(props.value)}</div>,
  },
  // credit limit
  { accessor: 'blocked', Header: <div>Blocked</div>, Cell: (props) => <div>{props.value ? 'Yes' : 'No'}</div> },
  { accessor: 'legal_date', Header: <div>Sent To Legal Date</div>, Cell: (props) => <div>{formatDateForTable(props.value)}</div> },
  { accessor: 'last_modified_date', Header: <div>Last Modified Date</div>, Cell: (props) => <div>{formatDateForTable(props.value)}</div> },
];

export const columnsForVendors = [
  {
    accessor: 'external_id',
    Header: <div>ID</div>,
  },
  {
    accessor: 'rmi_number',
    Header: <div>RMI ID</div>,
  },
  {
    accessor: 'name',
    Header: <div>Name</div>,
  },
  {
    id: 'vendor_type.name',
    accessor: (d) => d?.vendor?.vendor_type,
    Header: <div>Vendor Type</div>,
  },
  {
    accessor: 'address',
    Header: <div>Address</div>,
  },
  {
    accessor: 'branch',
    Header: <div>Branch</div>,
    Cell: (props) => {
      let value = '';
      if (props.value && props?.original?.vendor?.vendor_branch) {
        value = `${props.value} (${props.original.vendor.vendor_branch})`;
      } else if (props.value && !props?.original?.vendor?.vendor_branch) {
        value = props.value;
      } else if (!props.value && props?.original?.vendor?.vendor_branch) {
        value = `${props.original.vendor.vendor_branch}`;
      }

      return <div>{value}</div>;
    },
  },
  {
    accessor: 'balance',
    Header: <div>Balance</div>,
    Cell: (props) => <div>{formatAmount(props.value)}</div>,
  },
  {
    accessor: 'blocked',
    Header: <div>Blocked</div>,
    Cell: (props) => <div>{props.value ? 'Yes' : 'No'}</div>,
  },
  {
    accessor: 'last_modified_date',
    Header: <div>Last Modified Date</div>,
    Cell: (props) => <div>{formatDateForTable(props.value)}</div>,
  },
];
