import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Tooltip } from '@mui/material';
import React from 'react';
import { formatAmount } from '../../../../../../../utils/formatAmout';
import { formatDateForTable } from '../../../../../../../utils/formatDataForTable';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const ClientTransactionsHistoryTableColumns = (Actions) => {
  const appliedFromCell = (row) => {
    return (
      <div>
        {row.original.from_payment_reversed && (
          <Tooltip title={`${row.original.from_type} Reversed`} placement='top' arrow followCursor style={{ zIndex: 10 }}>
            <AutorenewIcon color='warning' />
          </Tooltip>
        )}
        {row.original.from_document_number} - {row.original.from_type}
      </div>
    );
  };

  const appliedToCell = (row) => {
    return (
      <div>
        {row.original.to_payment_reversed && (
          <Tooltip title={`${row.original.from_type} Reversed`} placement='top' arrow followCursor style={{ zIndex: 10 }}>
            <AutorenewIcon color='warning' />
          </Tooltip>
        )}
        {row.original.to_document_number} - {row.original.to_type}
      </div>
    );
  };

  return [
    {
      accessor: (d) => d['from_document_number'],
      id: 'from_document_number',
      Header: <div>Applied From</div>,
      Cell: appliedFromCell,
      sortable: true,
    },
    {
      accessor: (d) => d['amount'],
      id: 'amount',
      Header: <div>Amount</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['to_document_number'],
      id: 'to_document_number',
      Header: () => <div>Applied To</div>,
      Cell: appliedToCell,
      sortable: true,
    },
    {
      accessor: (d) => d['create_at'],
      id: 'create_at',
      Header: () => <div>Created On</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['date_applied'],
      id: 'date_applied',
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      Header: () => <div>Date Applied</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['row_status'],
      id: 'row_status',
      Header: () => <div>Status</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: true,
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            background: rowInfo?.original?.row_status == 'Deleted' ? 'red' : 'green',
            color: 'white',
            opacity: 0.7,
            textAlign: 'center',
            borderRadius: '5px',
          },
        };
      },
      width: 90,
    },
    {
      accessor: (d) => d['delete_at'],
      id: 'delete_at',
      Header: () => <div>Deleted On</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['delete_reason'],
      id: 'delete_reason',
      Header: () => (
        <div>
          <Tooltip title='User Errors will be ignored in Aging Reports' arrow followCursor>
            <InfoOutlinedIcon color='info' />
          </Tooltip>
          Deleted Because
        </div>
      ),
      Cell: (row) => <div>{row.value}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['delete_by'],
      id: 'delete_by',
      Header: () => <div>Deleted By</div>,
      Cell: (row) => <div>{row?.original?.reason ? row.value : ''}</div>,
      sortable: true,
    },
    {
      id: 'actions',
      Header: () => <div>Actions</div>,
      Cell: (row) => {
        if (row.original.row_status == 'Deleted' && Actions) {
          return <div>{React.cloneElement(Actions, { row })}</div>;
        } else {
          return <></>;
        }
      },
      sortable: false,
      minWidth: 160,
      maxWidth: 160,
    },
  ];
};
