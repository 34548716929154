import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import { Divider, Box, DialogContent, DialogActions } from '@mui/material';

import DataGridComponent from '../../../../component/DataTable/component/DataTableComponent';
import { CustomCard } from '../../../../common/Card/CustomCard';
import { CustomModal, useCustomModalToggle } from '../../../../common/Dialog/CustomModal';
import { CustomCurrencyInput } from '../../../../common/Inputs/CurrencyInput';
import { DatePickerComponent } from '../../../../common/DatePicker/DatePicker';
import { CustomInput } from '../../../../common/CustomInput';
import { CustomButton } from '../../../../common/Button/CustomButton';
import {
  useGetDepositItemTransactionsTableData,
  useGetDepositItem,
  useClearingTransactionMutation,
  useCancelClearingTransactionMutation,
  useGetDeposit,
} from '../depositApiRoutes';
import { useTransactionPdfExporter, useSendTransactionEmail } from '../../Client/Client360/component/transaction/clientTransactionsHooks';
import { depositClearingTableColumns } from '../tableColumns/depositClearingTableColumns';
import { CustomPopup } from '../../../../common/Popup/CustomPopup';
import { TransactionTableActions } from '../../Client/Client360/component/transaction/TransactionTableActions';
import { notificationDanger } from '../../../../utils/toastify';
import { Loader } from '../../../../common/Loader';

const DEPOSIT_REFUND = 2;

const DepositClearingComponent = () => {
  const { open, toggleCustomModal } = useCustomModalToggle();
  const { depositId, depositItemId } = useParams();
  const history = useHistory();
  const [filters, setFilters] = React.useState({});
  const [clearingData, setClearingData] = React.useState({
    amount: null,
    documentNumber: null,
    transactions_due_date_id: null,
    transaction_id: null,
    transactions_due_date_balance: null,
    realAmount: null,
    transactionType: null,
    isRefundOnCreditMemo: false,
  });
  const [cancelData, setCancelData] = React.useState({
    isModalOpen: false,
    modalAnchor: null,
  });

  const hideRemainingBalanceField = useMemo(() => {
    return true;
  }, []);

  const { data: tableData } = useGetDepositItemTransactionsTableData({ depositId, depositItemId, ...filters });
  const { data: { data: depositItemData = {} } = {} } = useGetDepositItem({ depositId, depositItemId });
  const { mutate: clearingTransactionMutation, isLoading } = useClearingTransactionMutation(filters, { depositId, depositItemId }, toggleCustomModal);
  const { mutate: cancelClearingTransactionMutation } = useCancelClearingTransactionMutation(filters, { depositId, depositItemId });
  const { mutate: sendTransactionEmail } = useSendTransactionEmail(
    depositItemData?.account_info?.id,
    depositItemData?.account_type_info?.name === 'Customer' ? 'customers' : 'vendors',
    'Transaction'
  );
  const { mutate: exportTransactionPdf } = useTransactionPdfExporter(
    depositItemData?.account_info?.id,
    depositItemData?.account_type_info?.name === 'Customer' ? 'customers' : 'vendors',
    'Transaction'
  );

  const modalRemainingBalanceValue = useMemo(() => {
    let value = 0;

    if (
      clearingData.isVendorRefundOnInvoice ||
      clearingData.isVendorNegativeRefundOnInvoice ||
      clearingData.isVendorNegativeRefundOnNegativePayment ||
      clearingData.isVendorNegativeRefundOnCreditMemo ||
      clearingData.isVendorNegativeRefundOnPayment ||
      clearingData.isVendorNegativeRefundOnRefund
    ) {
      value = +depositItemData.amount - +depositItemData.balance + (+clearingData.realAmount || 0) + +clearingData.alreadyAppliedAmount;
    } else {
      value = +depositItemData.amount - +depositItemData.balance - (+clearingData.realAmount || 0) + +clearingData.alreadyAppliedAmount;
    }

    return value.toFixed(2);
  }, [depositItemData, clearingData]);

  const onFilterChange = (e) => {
    setFilters({ ...filters, ...e });
  };

  const onClearClick = (row) => {
    const isRefundOnCreditMemo = row?.transaction_type?.includes('Credit Memo') && depositItemData.transaction_type_id == DEPOSIT_REFUND;

    let amount = 0;
    if (row['bank_payment_wizard_clearing_invoice.amount']) {
      amount = row['bank_payment_wizard_clearing_invoice.amount'];
    } else {
      if (isRefundOnCreditMemo) {
        amount = Math.min(
          Math.abs(row['transactions_due_date.balance'] - row['total_clear_balance']),
          +(depositItemData.amount - depositItemData.balance).toFixed(2)
        );
      } else {
        amount = Math.min(
          row['transactions_due_date.balance'] - row['total_clear_balance'],
          +(depositItemData.amount - depositItemData.balance).toFixed(2)
        );
      }
    }

    setClearingData((oldData) => ({
      ...oldData,
      maxAmount: amount,
      amount: amount,
      realAmount: amount,
      documentNumber: row['transactions.document_number'],
      date_applied: depositItemData.deposit_document_date,
      transactions_due_date_id: row['transactions_due_date.id'],
      transaction_id: row['transactions.id'],
      transactions_due_date_balance: row['transactions_due_date.balance'],
      alreadyAppliedAmount: row['bank_payment_wizard_clearing_invoice.amount'],
      transactionType: row.transaction_type,
      isRefundOnCreditMemo,
      isVendorNegativeRefundOnInvoice: row.is_negative_refund_on_invoice && row.account_type_id == 2,
      isVendorRefundOnInvoice: row.is_refund_on_invoice && row.account_type_id == 2,
      isVendorNegativeRefundOnNegativePayment: row.is_negative_refund_on_negative_payment && row.account_type_id == 2,
      isVendorNegativeRefundOnNegativeRefund: row.is_negative_refund_on_negative_refund && row.account_type_id == 2,
      isVendorRefundOnCreditMemo: row.is_refund_on_credit_memo && row.account_type_id == 2,
      isVendorNegativeRefundOnCreditMemo: row.is_negative_refund_on_credit_memo && row.account_type_id == 2,
      isVendorNegativeRefundOnPayment: row.is_negative_refund_on_payment && row.account_type_id == 2,
      isVendorNegativeRefundOnRefund: row.is_negative_refund_on_refund && row.account_type_id == 2,
    }));
    toggleCustomModal();
  };

  const toggleCancelModal = (e, row) => {
    if (!e) {
      setCancelData({ isModalOpen: false, modalAnchor: null, transaction_due_date_id: null });
    } else {
      setCancelData({ isModalOpen: true, modalAnchor: e.currentTarget, transactions_due_date_id: row['transactions_due_date.id'] });
    }
  };

  const handleCurrencyInputChange = (value) => {
    let realValue = value;
    if (Math.abs(+value) > Math.abs(+clearingData.maxAmount)) {
      realValue = +clearingData.maxAmount;
    }
    setClearingData((oldData) => ({ ...oldData, amount: realValue, realAmount: realValue }));
  };

  const handleDateChange = (date, name) => {
    setClearingData((oldData) => ({ ...oldData, [name]: date }));
  };

  const onSaveBalance = () => {
    if (+clearingData.amount === 0) return notificationDanger('Applying Amount cannot be 0!');
    if (Math.abs(+clearingData.amount) > Math.abs(+clearingData.maxAmount)) {
      return notificationDanger(
        `Applying Amount is to high, please reduce it! Remaining Balance for deposit item is ${+clearingData.maxAmount.toFixed(2)}`
      );
    }

    const data = {
      depositId,
      depositItemId,
      date_applied: clearingData.date_applied,
      amount: clearingData.realAmount,
      transactions_due_date_id: clearingData.transactions_due_date_id,
      transaction_id: clearingData.transaction_id,
      transactions_due_date_balance: clearingData.transactions_due_date_balance,
    };
    clearingTransactionMutation(data);
  };

  const onCancelClearing = () => {
    cancelClearingTransactionMutation({ depositId, depositItemId, transactions_due_date_id: cancelData.transactions_due_date_id });
    toggleCancelModal(false);
  };

  const columns = React.useMemo(
    () =>
      depositClearingTableColumns(
        {
          onClearClick,
          onCancelClearClick: toggleCancelModal,
          isClearingDisabled: false,
          accountTypeId: depositItemData?.account_type_id,
          isRefund: depositItemData.transaction_type_id == 2,
          clientType: depositItemData?.account_type_info?.name === 'Customer' ? 'customers' : 'vendors',
        },
        <TransactionTableActions
          exportPdf={exportTransactionPdf}
          sendEmail={sendTransactionEmail}
          clientId={depositItemData?.account_info?.id}
          clientType={depositItemData?.account_type_info?.name === 'Customer' ? 'customers' : 'vendors'}
          transactionType={'Transaction'}
        />
      ),
    [depositItemData]
  );
  const cleanDataSource = React.useMemo(() => ({ data: [], page: 0, pageSize: 10, pageTotal: 0 }));

  const backHandler = () => {
    history.push(`/deposit/${depositId}`);
  };

  return (
    <>
      <Button className='btn btn-sm' onClick={backHandler}>
        <i className='fas fa-angle-left mr-2'></i>Go Back To Deposit View
      </Button>
      <CustomCard headerTitle={'Deposit Info'} sxCardContainer={{ my: 2 }}>
        <Row>
          <Col md='3'>
            <CustomInput
              name='account_type'
              value={depositItemData?.account_type_info?.name}
              onChange={() => {}}
              label='Account Type'
              placeholder='Account Type'
            />
          </Col>
          <Col md='6'>
            <CustomInput
              name='account_info'
              value={depositItemData?.account_info?.id + ' - ' + depositItemData?.account_info?.name}
              onChange={() => {}}
              label='Account'
              placeholder='Account'
            />
          </Col>
        </Row>
        <Divider sx={{ my: 2 }} />
        <Row>
          <Col md='3'>
            <CustomCurrencyInput
              name='amount'
              value={depositItemData.amount}
              onChange={() => {}}
              prefix={'$ '}
              label='Credit Amount'
              placeholder='Credit Amount'
            />
          </Col>
          <Col md='3'>
            <CustomCurrencyInput
              name='charge'
              value={depositItemData.balance}
              onChange={() => {}}
              prefix={'$ '}
              label='Applied Amount'
              placeholder='Charge'
            />
          </Col>
          <Col md='3'>
            <CustomCurrencyInput
              name='remaining_balance'
              value={+(depositItemData.amount - depositItemData.balance).toFixed(2)}
              onChange={() => {}}
              prefix={'$ '}
              label='Remaining Balance'
              placeholder='Remaining Balance'
            />
          </Col>
        </Row>
      </CustomCard>
      <CustomCard headerTitle={'Unpaid Transactions'}>
        <Row>
          <Col>
            <DataGridComponent
              columns={columns}
              loading={false}
              dataSource={tableData?.data || cleanDataSource}
              onFilterChange={onFilterChange}
              noPagination
            />
          </Col>
        </Row>
      </CustomCard>
      <CustomPopup
        anchor={cancelData.modalAnchor}
        content={
          <>
            <CancelClearingpopupContent onConfirm={onCancelClearing} onCancel={toggleCancelModal} />
          </>
        }
        arrow
        placement='bottom'
        onClose={() => toggleCancelModal(false)}
        open={cancelData.isModalOpen}
      />
      <CustomModal
        modalTitle='Add Balance'
        modalSize='md'
        buttonTitle='Add Balance'
        buttonClassName='btn-success'
        saveButtonTitle='Save'
        onSave={onSaveBalance}
        toggleCustomModal={toggleCustomModal}
        isOpen={open}
      >
        <Row>
          {isLoading && <Loader isFullScreen />}
          <Col md='6'>
            <CustomCurrencyInput
              name='amount'
              value={clearingData.amount}
              onChange={handleCurrencyInputChange}
              prefix={'$ '}
              label='Applying Amount'
              placeholder='Applying Amount'
            />
          </Col>
          <Col md='6'>
            <DatePickerComponent
              onChange={(date) => handleDateChange(date, 'date_applied')}
              showYearDropdown
              name='date_applied'
              selected={clearingData.date_applied}
              placeholder='mm/dd/yyyy'
              dateFormat={'MM/dd/yyyy'}
              label='Date Applied'
            />
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col md='4'>
            <CustomInput
              name='document_number'
              value={clearingData.documentNumber}
              onChange={() => {}}
              label='Document Number'
              placeholder='Document Number'
              disabled
            />
          </Col>
          <Col md='4'>
            <CustomCurrencyInput
              name='Credit Amount'
              value={depositItemData.amount}
              onChange={() => {}}
              prefix={'$ '}
              label='Credit Amount'
              placeholder='Credit Amount'
              disabled
            />
          </Col>
          {!hideRemainingBalanceField && (
            <Col md='4'>
              <CustomCurrencyInput
                name='Remaining Balance'
                value={modalRemainingBalanceValue}
                onChange={() => {}}
                prefix={'$ '}
                label='Remaining Balance'
                placeholder='Remaining Balance'
                disabled
              />
            </Col>
          )}
        </Row>
      </CustomModal>
    </>
  );
};

const CancelClearingpopupContent = ({ onConfirm, onCancel, message = `Are you sure you wish to cancel application for this transaction?` }) => {
  return (
    <>
      <Box>
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          <CustomButton color='danger' onClick={onConfirm}>
            Ok
          </CustomButton>
          <CustomButton color='secondary' onClick={() => onCancel(false)}>
            Cancel
          </CustomButton>
        </DialogActions>
      </Box>
    </>
  );
};

export default DepositClearingComponent;
