import { Row, Col } from 'reactstrap';
import { CustomModal } from '../../../../../common/Dialog/CustomModal';
import { CustomInput } from '../../../../../common/CustomInput';
import { useState, useEffect } from 'react';
import { useUpdateUserPassword } from '../apiHooks';
import Joi from 'joi';

const userRequestPasswordSchema = Joi.object({
  oldPassword: Joi.string().required().messages({
    'any.required': 'Old password is required',
    'string.empty': 'Old password can not be empty',
  }),
  password: Joi.string().min(6).required().messages({
    'any.required': 'Password is required',
    'string.min': 'Password must be at least 6 characters long',
  }),
  repeatPassword: Joi.string().valid(Joi.ref('password')).required().messages({
    'any.only': 'Repeat Password must match',
  }),
});

const adminRequestPasswordSchema = Joi.object({
  password: Joi.string().min(6).required().messages({
    'any.required': 'Password is required',
    'string.min': 'Password must be at least 6 characters long',
  }),
  repeatPassword: Joi.string().valid(Joi.ref('password')).required().messages({
    'any.only': 'Repeat Password must match',
  }),
});

export const ChangePassword = ({ open, toggleCustomModal, isAdmin, userId }) => {
  const [passwordObj, setPasswordObj] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (open) setErrors({});
    else setPasswordObj({});
  }, [open]);

  const handleChangePassword = (event) => {
    const { name, value } = event.target;
    setPasswordObj((prevState) => ({ ...prevState, [name]: value }));
  };

  const { mutate: updatePassword } = useUpdateUserPassword();

  const onSave = () => {
    setErrors({});
    const { error } = isAdmin
      ? adminRequestPasswordSchema.validate(passwordObj, { abortEarly: false })
      : userRequestPasswordSchema.validate(passwordObj, { abortEarly: false });
    if (error && error?.details) {
      const errorsObj = {};
      for (const detail of error.details) {
        errorsObj[detail.context.key] = detail.message;
      }
      setErrors((prevState) => ({ ...prevState, ...errorsObj }));
      return;
    }
    updatePassword({ ...passwordObj, isAdmin, userId });
  };

  return (
    <CustomModal modalTitle='Change Password' isOpen={open} onSave={onSave} toggleCustomModal={toggleCustomModal}>
      <Row>
        {!isAdmin ? (
          <Col md='12'>
            <CustomInput
              name='oldPassword'
              label='Old Password'
              placeholder='Old Password'
              onChange={handleChangePassword.bind(null)}
              errorMess={errors.oldPassword}
            />
          </Col>
        ) : null}
        <Col md='12'>
          <CustomInput
            name='password'
            label='New Password'
            placeholder='New Password'
            onChange={handleChangePassword.bind(null)}
            errorMess={errors.password}
          />
        </Col>
        <Col md='12'>
          <CustomInput
            onChange={handleChangePassword.bind(null)}
            name='repeatPassword'
            label='Repeat New Password'
            placeholder='Repeat New Password'
            errorMess={errors.repeatPassword}
          />
        </Col>
      </Row>
    </CustomModal>
  );
};
