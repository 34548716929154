import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { CustomCard } from '../../../../../common/Card/CustomCard';
import { BillingPaymentForm } from './BillingPaymentForm';
import { useSelector } from 'react-redux';
import { PaginationKeys } from '../../../../redax/Pagination/PaginationActions';

export const CreateBillingPayment = () => {
  const { clientType } = useParams();
  const history = useHistory();
  const { billingPaymentsLocation } = useSelector((state) => ({
    billingPaymentsLocation: state.pagination[PaginationKeys.BillingPayments],
  }));

  const handleBackClick = () => {
    let url = `/client/${clientType}/payment`;
    if (billingPaymentsLocation) {
      const { pathname, search } = billingPaymentsLocation;
      url = `${pathname}${search}`;
    }
    history.push(url);
  };

  return (
    <>
      <Button className='btn btn-sm mb-2' onClick={handleBackClick}>
        <i className='fas fa-angle-left mr-2'></i>Go Back To Payments Table
      </Button>
      <CustomCard
        headerTitle={
          <div>
            <span>Payment</span>
          </div>
        }
      >
        <BillingPaymentForm />
      </CustomCard>
    </>
  );
};
