import { formatAmount } from '../../../../utils/formatAmout';
import { formatDateForTable } from '../../../../utils/formatDataForTable';

export const extractValidationErrorMessage = (sources = [], field) => {
  const filtered = sources.filter(Boolean);
  if (!filtered.length || !field || sources?.response?.status === 500) return '';

  const messages = sources.map((source) => {
    if (source?.response?.status === 422) {
      if (Object.keys(source.response?.data?.error).length && Object.keys(source.response?.data?.error)[0].includes('[0]')) {
        return source?.response?.data?.error[`[0].${field}`] ?? '';
      }

      return source?.response?.data?.error[field] ?? '';
    }
  });

  return messages.filter(Boolean).join();
};

export const mapBillingPaymentItemsExcelData = (data) => {
  if (!Array.isArray(data)) {
    return [];
  }

  const normalizedData = data.map((row) => {
    const normalized = {
      ...row,
      account_details: `${row.account.number} - ${row.account.name}`,
      balance_account_details: `${row.balance_account.number} - ${row.balance_account.name}`,
      'billing_payment_items.posting_date': formatDateForTable(row['billing_payment_items.posting_date']),
      'billing_payment_items.amount': formatAmount(row['billing_payment_items.amount']),
      cleared_amount: formatAmount(row.cleared_amount),
    };

    return normalized;
  });

  return normalizedData;
};

export const mapBillingPaymentExcelData = (data) => {
  if (!Array.isArray(data)) {
    return [];
  }

  const normalizedData = data.map((row) => {
    const normalized = {
      ...row,
      document_date: formatDateForTable(row.document_date),
      posting_date: formatDateForTable(row.posting_date),
      billing_date: formatDateForTable(row.billing_date),
      create_at: formatDateForTable(row.create_at),
      account_details: `${row.bank_account.number} - ${row.bank_account.name}`,
      finished: row.finished ? 'Yes' : 'No',
    };

    return normalized;
  });

  return normalizedData;
};
