import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { formatAmount } from '../../../../../utils/formatAmout';
import { useProfitLossByAccountType } from '../hooks';
import { ReportTable } from './ReportTable';

export const ProfitLossSection = ({ accountType, filters = {}, shouldRefetch }) => {
  const { data: { data: reportData = {} } = {}, refetch } = useProfitLossByAccountType(accountType, filters, { enabled: !!Object.keys(filters) });

  useEffect(() => {
    refetch();
  }, [shouldRefetch]);

  if (!reportData) {
    return (
      <>
        <Row>
          <Col>
            <CircularProgress />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <Row className='mb-3 mt-3'>
        <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ fontSize: '1.5rem', margin: 0 }}>
              {reportData['account_profit_loss.name']}{' '}
              <small style={{ fontSize: '0.75rem' }}>({(reportData.group_percentage || 0).toFixed(2)}%)</small>
            </p>
          </span>
        </Col>
      </Row>
      {reportData?.accounts?.length >= 0 && (
        <ReportTable
          shouldRefetch={shouldRefetch}
          data={reportData.accounts}
          series={reportData?.series}
          accountType={accountType}
          filter={filters}
          onFilterChange={() => {}}
        />
      )}
      <div className='d-flex  justify-content-end' style={{ fontSize: '1.5rem', margin: 0 }}>
        {formatAmount(reportData.total)}
      </div>
    </>
  );
};
