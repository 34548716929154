import PropTypes from 'prop-types';
import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';

const defaultPaginationOptions = {
  page: 0,
  pageSize: 50,
};

const baseInputStyles = {
  container: (provided) => {
    return {
      ...provided,
      flex: 1,
    };
  },
};

/**
 * Async Select With Infinite Scrolling Component
 * @param {function} loadOptions - @description loadOptions(search:string, pagination:{ page: number, pageSize: number })  => loadOptions returns { options: { value: any, label: string }[], hasMore: bool }
 */
const CustomPageableAsyncSelect = ({
  initFilters,
  label,
  loadOptions,
  isMulti,
  onInputChange,
  onChange,
  name,
  formClassName,
  styles,
  className,
  placeholder,
  loadingMessage,
  noOptionsMessage,
  paginationOptions,
  prependAddon,
  defaultValue,
  onFocusOut,
  errorMess,
  classNamePrefix,
  isClearable,
}) => {
  const loadPageOptions = async (searchBy, prevOptions, { page }) => {
    const { options, hasMore } = await loadOptions(searchBy, { page, pageSize: paginationOptions.pageSize, ...initFilters });

    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <>
      <FormGroup className={formClassName}>
        {label && <Label>{label}</Label>}
        <InputGroup style={{ display: 'flex', flexDirection: 'row' }}>
          {prependAddon && (
            <InputGroupAddon addonType='prepend'>
              <InputGroupText style={{ padding: '0 5px' }}>{prependAddon}</InputGroupText>
            </InputGroupAddon>
          )}
          <AsyncPaginate
            key={loadOptions}
            menuPosition={'fixed'}
            cacheOptions
            defaultOptions
            loadOptionsOnMenuOpen
            isClearable={isClearable}
            additional={defaultPaginationOptions}
            name={name}
            // value={
            //   isMulti
            //     ? options.filter((option) => {
            //         return defaultValue.includes(option.id) || defaultValue.includes(option.value);
            //       })
            //     : options.find((option) => option.value === defaultValue || option.id === defaultValue) || 0
            // }
            loadOptions={loadPageOptions}
            onChange={onChange}
            onBlur={onFocusOut}
            onInputChange={onInputChange}
            loadingMessage={() => loadingMessage}
            noOptionsMessage={() => noOptionsMessage}
            placeholder={placeholder}
            styles={{ ...styles, ...baseInputStyles }}
            className={className}
            classNamePrefix={classNamePrefix || ''}
          />
        </InputGroup>
        <small className='text-danger'>{errorMess}</small>
      </FormGroup>
    </>
  );
};

CustomPageableAsyncSelect.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  loadOptions: PropTypes.func.isRequired,
  name: PropTypes.string,
  formClassName: PropTypes.string,
  styles: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  loadingMessage: PropTypes.string,
  errorMess: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  paginationOptions: PropTypes.shape({ page: PropTypes.number, pageSize: PropTypes.number }),
  prependAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Element)]),
  initFilters: PropTypes.object,
  onFocusOut: PropTypes.func,
  isClearable: PropTypes.bool,
};

CustomPageableAsyncSelect.defaultProps = {
  paginationOptions: defaultPaginationOptions,
  loadingMessage: 'Loading...',
  noOptionsMessage: 'No results found',
  prependAddon: '',
  errorMess: '',
  initFilters: {},
  onFocusOut: () => {},
  isClearable: true,
};

export { CustomPageableAsyncSelect };
