import { formatAmount, formatNumber } from '../../../../utils/formatAmout';
import './styles.scss';

export const columns = ({ series, options = {} }) => {
  const composeAdditionalColumns = () => {
    if (!series?.length) {
      return [];
    }

    const findSeriesValue = (series, accountNumber) => {
      const element = series.series_accounts.find((el) => el?.account_data?.account === accountNumber);
      if (!element) {
        return '$0';
      }

      return formatAmount(element?.account_data?.amount || 0);
    };

    const findSeriesPercentage = (series, accountNumber) => {
      const element = series.series_accounts.find((el) => el?.account_data?.account === accountNumber);
      if (!element) {
        return '0%';
      }

      return `${formatNumber(element?.account_data?.percentage || 0)}%`;
    };

    const columns = series.map((col) => {
      const columnAmount = {
        id: col.period,
        accessor: 'period',
        Header: <div>{col.period}</div>,
        sortable: false,
        Cell: (row) => <div>{findSeriesValue(col, row?.original?.number)}</div>,
      };

      const columnSet = [columnAmount];

      if (options.percentageIncluded) {
        const columnPercentage = {
          id: `${col.period}-${Math.random()}`,
          accessor: 'period_percentage',
          Header: <div>[{col.period}] %</div>,
          sortable: false,
          Cell: (row) => <div>{findSeriesPercentage(col, row?.original?.number)}</div>,
        };

        columnSet.push(columnPercentage);
      }

      return columnSet;
    });

    return columns;
  };

  const defaultColumns = [
    {
      id: 'number',
      accessor: 'number',
      Header: <div>Account</div>,
      getProps: () => {
        return {
          className: 'account-link',
        };
      },
    },
    {
      id: 'name',
      accessor: 'name',
      Header: <div>Account Name</div>,
    },
  ];

  const nonSeriesColumns = [
    {
      id: 'current_month_sum',
      accessor: 'current_month_sum',
      Header: () => <div className='rightHeader'>Current Month</div>,
      Cell: (row) => <div style={{ textAlign: 'right' }}>{formatAmount(row.value)}</div>,
    },
    {
      id: 'current_month_percentage',
      accessor: 'current_month_percentage',
      Header: () => <div className='rightHeader'>Current Month %</div>,
      Cell: (row) => <div style={{ textAlign: 'right' }}>{`${formatNumber(row.value || 0)} %`}</div>,
      sortable: false,
    },
    {
      id: 'ytd_sum',
      accessor: 'ytd_sum',
      Header: () => <div className='rightHeader'>Year To Date</div>,
      Cell: (row) => <div style={{ textAlign: 'right' }}>{formatAmount(row.value)}</div>,
    },
    {
      id: 'ytd_percentage',
      accessor: 'ytd_percentage',
      Header: () => <div className='rightHeader'>Year To Date %</div>,
      Cell: (row) => <div style={{ textAlign: 'right' }}>{`${formatNumber(row.value || 0)} %`}</div>,
      sortable: false,
    },
  ];

  const mappedAdditionalColumns = composeAdditionalColumns();
  mappedAdditionalColumns?.length ? defaultColumns.splice(defaultColumns.length, 0, ...mappedAdditionalColumns.flat()) : defaultColumns;

  if (!series.length) {
    defaultColumns.push(...nonSeriesColumns);
  }

  if (!options.percentageIncluded) {
    return defaultColumns.filter((column) => !column.id.includes('percentage'));
  }

  return defaultColumns;
};
