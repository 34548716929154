import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { List, ListItem, Checkbox } from '@mui/material';
import { darken } from '@mui/material/styles';

import {
  FUNCTIONALITIES,
  GROUPED_FUNCTIONALITIES,
  PERMISSIONS,
  PERMISSIONS_FOR_SELECT,
  getFunctionality,
} from '../../../../../constants/permissions.constants';
import { CustomModal } from '../../../../../common/Dialog/CustomModal';
import { useUpdateRole, useCreateRole, useGetRole } from '../apiHooks';
import { CustomInput } from '../../../../../common/CustomInput';
import { CustomSelect } from '../../../../../common/CustomSelect';
import { CustomCheckbox } from '../../../../../common/Inputs/CustomCheckbox';
import { notificationDanger } from '../../../../../utils/toastify';

export const AddEditComponent = ({ action, id, open, toggleCustomModal }) => {
  const [data, setData] = React.useState({
    name: '',
    perm_and_func: {},
  });

  const cleanUp = React.useCallback(() => {
    setData({ name: '', perm_and_func: {} });
    toggleCustomModal();
  }, [toggleCustomModal]);

  const { mutate: createMutation } = useCreateRole(cleanUp);
  const { mutate: updateMutation } = useUpdateRole(cleanUp);
  const { data: roleData } = useGetRole(id);

  useEffect(() => {
    if (roleData?.data && action !== 'create') {
      setData({
        name: roleData.data.name,
        perm_and_func: roleData.data.perm_and_func,
      });
    } else {
      setData({ name: '', perm_and_func: {} });
    }
  }, [open, roleData?.data]);

  const handleChangeInput = (e) => {
    const { value, name } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeCheckbox = (e, item, nestedFunc) => {
    const { checked } = e.target;
    const setValues = {};
    for (const func of nestedFunc) {
      setValues[func] = { perm: checked ? PERMISSIONS : [], active: checked };
    }
    setData((prev) => {
      return { ...prev, perm_and_func: { ...prev.perm_and_func, [item]: { perm: checked ? PERMISSIONS : [], active: checked }, ...setValues } };
    });
  };

  const handleChangeSelect = (value, item, nestedFunc) => {
    setData((prev) => {
      const setValues = {};
      for (const func of nestedFunc) {
        setValues[func] = { active: value ? true : false, perm: value ? value.map((v) => v.value) : [] };
      }

      return {
        ...prev,
        perm_and_func: {
          ...prev.perm_and_func,
          [item]: { active: value ? true : false, perm: value ? value.map((v) => v.value) : [] },
          ...setValues,
        },
      };
    });
  };

  const onSave = () => {
    if (!data.name) {
      notificationDanger('Role name is required');
      return;
    }
    if (action === 'create') {
      createMutation(data);
    } else {
      updateMutation({ id, ...data });
    }
  };

  const findNestedFunctionalities = (arr, matchFunc = null) => {
    let nestedFunc = [];

    function searchNestedArrays(array) {
      for (let item of array) {
        if (Array.isArray(item)) {
          for (let i of item) {
            if (matchFunc) {
              if (!Array.isArray(i) && i.parent === matchFunc) {
                nestedFunc.push(i.func);
              }
            } else if (!Array.isArray(i)) {
              nestedFunc.push(i.func);
            }
          }
          searchNestedArrays(item);
        }
      }
    }

    searchNestedArrays(arr);
    return nestedFunc;
  };

  const renderListItem = (item, depth, nestedFunc = []) => {
    return (
      <ListItem key={JSON.stringify(item)} sx={{ display: 'grid', gridTemplateColumns: '4fr 2fr 1fr', alignItems: 'center' }}>
        <div style={{ marginLeft: depth * 25 }}>
          <item.icon sx={{ color: darken('#ceac02', 0.2) }} /> {getFunctionality(item.func)}
        </div>
        <CustomSelect
          options={PERMISSIONS_FOR_SELECT}
          name='permissions'
          onChange={(value) => handleChangeSelect(value, item.func, nestedFunc)}
          placeholder={'Select Permission'}
          classNamePrefix='react-select-prefix'
          isMulti
          customValueStyle
          defaultValue={data.perm_and_func[item.func]?.perm || []}
        />
        <div style={{ justifySelf: 'end' }}>
          <CustomCheckbox
            checked={data.perm_and_func[item.func]?.active}
            onChange={(e) => handleChangeCheckbox(e, item.func, nestedFunc)}
            name='active'
            color='primary'
          />
        </div>
      </ListItem>
    );
  };

  const renderFunctionalities = () => {
    return GROUPED_FUNCTIONALITIES.map((item) => {
      if (Array.isArray(item)) {
        return (
          <div
            style={{
              boxShadow: `rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em`,
              marginTop: '5px',
            }}
          >
            {item.map((innerItem) => {
              if (Array.isArray(innerItem)) {
                return innerItem.map((inner2Item) => {
                  if (Array.isArray(inner2Item)) {
                    return inner2Item.map((inner3Item) => {
                      return renderListItem(inner3Item, 2);
                    });
                  } else {
                    const nestedFunc = findNestedFunctionalities(innerItem, inner2Item.func);
                    return renderListItem(inner2Item, 1, nestedFunc);
                  }
                });
              } else {
                const nestedFunc = findNestedFunctionalities(item);
                return renderListItem(innerItem, 0, nestedFunc);
              }
            })}
          </div>
        );
      }
    });
  };

  return (
    <CustomModal
      modalTitle={action === 'create' ? 'Create Role' : 'Update Role'}
      isOpen={open}
      toggleCustomModal={() => {
        setData({
          name: '',
          perm_and_func: {},
        });
        toggleCustomModal();
      }}
      onSave={onSave}
      modalSize={'md'}
    >
      <Row>
        <Col md='4'>
          <CustomInput value={data.name} onChange={handleChangeInput} name='name' label='Role Name' placeholder='Role Name' />
        </Col>
      </Row>
      <List dense sx={{ width: '100%', borderRadius: '5px' }}>
        {renderFunctionalities(GROUPED_FUNCTIONALITIES, -1)}
      </List>
    </CustomModal>
  );
};
