import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { notificationSuccess } from '../../../../utils/toastify';

const updateDisablePosting = ({ id, ...rest }) => {
  return axios.patch(`/me/repository/disable-posting/${id}`, rest);
};

export const useUpdateDisablePosting = (callback) => {
  const queryClient = useQueryClient();
  return useMutation(updateDisablePosting, {
    onSuccess: (data) => {
      notificationSuccess('Successfully updated Disable Posting entry!');
      queryClient.invalidateQueries(['disable-posting-table']);
      callback && callback(data);
    },
  });
};

const getDisablePostingTableData = (filter) => {
  return axios.get('/me/repository/disable-posting', { params: filter });
};

export const useGetDisablePostingTableData = (filter) => {
  return useQuery(
    ['disable-posting-table', filter.page, filter.pageSize, filter.sort_by, filter.order_by],
    () => getDisablePostingTableData(filter),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const createDisablePosting = (data) => {
  return axios.post('/me/repository/disable-posting', data);
};

export const useCreateDisablePosting = (callback) => {
  const queryClient = useQueryClient();
  return useMutation(createDisablePosting, {
    onSuccess: (data) => {
      notificationSuccess('Successfully created Disable Posting entry!');
      queryClient.invalidateQueries(['disable-posting-table']);
      callback && callback(data);
    },
  });
};

const deleteDisablePosting = (id) => {
  return axios.delete(`/me/repository/disable-posting/${id}`);
};

export const useDeleteDisablePosting = (callback) => {
  const queryClient = useQueryClient();
  return useMutation(deleteDisablePosting, {
    onSuccess: (data) => {
      notificationSuccess('Successfully deleted Disable Posting entry!');
      queryClient.invalidateQueries(['disable-posting-table']);
      callback && callback(data);
    },
  });
};

const checkIfPostingIsDisabled = (data) => {
  return axios.get('/me/repository/disable-posting/check-if-disable-posting', { params: data });
};

export const useCheckIfPostingIsDisabled = (data) => {
  return useQuery(['check-if-posting-is-disabled'], () => checkIfPostingIsDisabled(data), {
    refetchOnWindowFocus: true,
  });
};
