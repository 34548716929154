import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Box, Button, Divider } from '@mui/material';

import { DatePickerComponent } from '../../../../common/DatePicker/DatePicker';
import { CustomCurrencyInput } from '../../../../common/Inputs/CurrencyInput';
import { CustomSelect } from '../../../../common/CustomSelect';
import { useGetBankAccount } from '../../../../hooks/commonApiHooks';
import { validateBankRecoData } from '../validators';
import { useUpdateBancReco, useCreateBankReco } from '../BankRecoApiHooks';

export const BeginReconciliation = ({
  setStep,
  bankRecoData,
  removeFetchedBancRecoData,
  setAccount,
  refetchGetBancRecoData,
  lastBancRecoData,
  removeLastBancRecoData,
}) => {
  const history = useHistory();
  const [data, setData] = React.useState({
    account: null,
    statement_date: null,
    begining_balance: 0,
    ending_balance: '',
  });
  const [errors, setErrors] = React.useState({});
  const { data: bankAccountOptions } = useGetBankAccount();

  const successCbCreate = (successData) => {
    setStep(1);
    refetchGetBancRecoData();
  };
  const { mutate: createBancRecoMutation } = useCreateBankReco(successCbCreate);
  const { mutate: updateBancRecoMutation } = useUpdateBancReco(() => setStep(1), bankRecoData?.account);

  React.useEffect(() => {
    if (bankRecoData && Object.keys(bankRecoData).length) {
      setData({
        account: bankRecoData.account,
        statement_date: bankRecoData.statment_date,
        begining_balance: bankRecoData.begining_balance,
        ending_balance: bankRecoData.ending_balance,
      });
    }

    if (lastBancRecoData && Object.keys(lastBancRecoData).length && lastBancRecoData.data !== 'no data') {
      setData((oldData) => ({
        ...oldData,
        last_reconsiled_on: lastBancRecoData.statment_date,
        begining_balance: lastBancRecoData.ending_balance,
        statement_date: lastBancRecoData.statment_date
          ? moment(lastBancRecoData.statment_date).add(1, 'month').endOf('month').format('YYYY-MM-DD')
          : moment().endOf('month').format('YYYY-MM-DD'),
      }));
    } else if (lastBancRecoData && Object.keys(lastBancRecoData).length && lastBancRecoData.data === 'no data') {
      setData((oldData) => ({
        ...oldData,
        statement_date: moment().endOf('month').format('YYYY-MM-DD'),
      }));
    }
  }, [bankRecoData, lastBancRecoData]);

  const onContinueClick = () => {
    const recoData = {
      account: data.account,
      statment_date: data.statement_date,
      begining_balance: data.begining_balance,
      ending_balance: data.ending_balance,
    };

    const validationResult = validateBankRecoData(recoData);
    if (validationResult) {
      setErrors(validationResult);
      return;
    }

    if (bankRecoData && bankRecoData.id) {
      updateBancRecoMutation({ ...recoData, id: bankRecoData.id, version: bankRecoData.version });
      setErrors({});
    } else {
      createBancRecoMutation(recoData);
      setErrors({});
    }
  };

  const handleChangeSelect = (val, e) => {
    if (val) {
      setData((oldData) => ({ ...oldData, [e.name]: val.value }));
      setAccount(val.value);
      setErrors({});
      if (bankRecoData) {
        setData({
          account: bankRecoData.account,
          statement_date: bankRecoData.statment_date,
          begining_balance: bankRecoData.begining_balance,
          ending_balance: bankRecoData.ending_balance,
        });
      }
    } else {
      setAccount(null);
      setData({
        account: null,
      });
      removeFetchedBancRecoData();
      removeLastBancRecoData();
      setErrors({});
    }
  };

  const handleDateChange = (date, name) => {
    setData((oldData) => ({ ...oldData, [name]: date }));
  };

  const handleCurrencyInputChange = (value, name) => {
    setData((oldData) => ({ ...oldData, [name]: value }));
  };

  const onCancel = () => {
    history.push('/bank-reconciliation');
    removeFetchedBancRecoData();
    removeLastBancRecoData();
    setErrors({});
  };

  return (
    <Box sx={{ boxShadow: 3, paddingY: 2, paddingX: 10 }}>
      <Row>
        <Col md='4'>
          <CustomSelect
            isClearable
            options={bankAccountOptions?.data || []}
            defaultValue={data.account}
            onChange={handleChangeSelect}
            label='Account'
            name={'account'}
            errorMess={errors?.account}
          />
        </Col>
        <Col md='4'>
          <DatePickerComponent
            onChange={(date) => handleDateChange(date, 'statement_date')}
            showYearDropdown
            isClearable
            name='statement_date'
            selected={data.statement_date}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Statement Date'
            errorMess={errors?.statement_date}
          />
        </Col>
        {/* <Col md='3'></Col> */}
        <Col md='4'>
          <DatePickerComponent
            onChange={() => {}}
            showYearDropdown
            isClearable
            name='last_reconsiled_on'
            selected={data.last_reconsiled_on}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Last Reconciled On'
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col md='4'>
          <CustomCurrencyInput
            name='ending_balance'
            value={data.ending_balance}
            onChange={handleCurrencyInputChange}
            label='Ending Balance'
            prefix={'$ '}
            placeholder='Ending Balance'
            errorMess={errors?.ending_balance}
          />
        </Col>
        <Col></Col>
        <Col md='4'>
          <CustomCurrencyInput
            name='begining_balance'
            value={data.begining_balance}
            onChange={() => {}}
            label='Beginning Balance'
            prefix={'$ '}
            placeholder='Beginning Balance'
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Divider sx={{ my: 1 }} />
        </Col>
      </Row>
      <Row>
        <Col className='d-flex justify-content-between'>
          <Button color='error' onClick={onCancel}>
            Cancel
          </Button>
          <Button color='success' onClick={onContinueClick}>
            Continue
          </Button>
        </Col>
      </Row>
    </Box>
  );
};
