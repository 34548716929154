import React from 'react';
import { toast } from 'react-toastify';

export const notificationSuccess = (text) => {
  return toast.success(text, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  });
};

export const notificationDanger = (text) => {
  return toast.error(text, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  });
};

export const notificationDangerCustomTime = (text, time) => {
  return toast.error(text, {
    position: 'top-right',
    autoClose: time,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  });
};

export const notificationDangerMultipleRows = (array) => {
  return toast.error(
    <div>
      {array &&
        array.map((i) => {
          return <div key={i}>{i}</div>;
        })}
    </div>,
    { position: toast.POSITION.UPPER_RIGHT }
  );
};

export const notificationWarning = (text) => {
  return toast.warn(text, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  });
};

export const notificationInfo = (text) => {
  return toast.info(text, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  });
};
