import React from 'react';
import { formatAmount } from '../../../../../../../utils/formatAmout';
import { formatDateForTable } from '../../../../../../../utils/formatDataForTable';

export const columns = ({ SelectRow }) => [
  {
    id: 'selectedTransactions',
    Header: (row) =>
      SelectRow && (
        <div className='d-flex justify-content-center align-items-center'>{React.cloneElement(SelectRow, { row, selectMultiple: true })}</div>
      ),
    Cell: (row) => SelectRow && <div className='d-flex justify-content-center align-items-center'>{React.cloneElement(SelectRow, { row })}</div>,
    sortable: false,
  },
  {
    id: 'client.name',
    Header: <div>Vendor</div>,
    Cell: (row) => <div>{row.value}</div>,
    accessor: (d) => d['client.name'],
    sortable: true,
  },
  {
    id: 'transactions.document_number',
    Header: <div>Document Number</div>,
    Cell: (row) => <div>{row.value}</div>,
    accessor: (d) => d['transactions.document_number'],
    sortable: true,
  },
  {
    id: 'transactions.document_date',
    accessor: (d) => d['transactions.document_date'],
    Header: () => <div className='leftHeader'>Date</div>,
    sort: true,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
  },
  {
    id: 'transactions_due_date.due_date',
    Header: <div>Due Date</div>,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    accessor: (d) => d['transactions_due_date.due_date'],
    sortable: true,
  },
  {
    id: 'transactions_due_date.balance',
    accessor: (d) => d['transactions_due_date.balance'],
    Header: () => <div className='leftHeader'>Balance</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sort: true,
  },
];
