import axios from "axios";
import {
    SYS_SET_ACCOUNT,
    SYS_SET_BRANCH_CODE,
    SYS_SET_INVOICE_TYPE, SYS_SET_PAYMENT_TERM,
    SYS_SET_PAYMENT_TYPE,
    SYS_SET_TAX_CODE, SYS_SET_TAX_ITEM
} from "./SysReducer";

export const systemAccountLoad = (input) => dispatch => {
    return axios
        .get('/me/account/' )
        .then(res => {
            if(res.status===200) {
                dispatch({
                    type: SYS_SET_ACCOUNT,
                    payload: res.data['data']
                });
            }
        });
};
export const systemPaymentTypeLoad = (input) => dispatch => {
    return axios
        .get('/me/repository/payment-type/' )
        .then(res => {
            if(res.status===200) dispatch({
                type: SYS_SET_PAYMENT_TYPE,
                payload: res.data
            });
        });
};
export const systemInvoiceTypeLoad = (input) => dispatch => {
    return axios
        .get('/me/repository/invoice-type/' )
        .then(res => {
            if(res.status===200) dispatch({
                type: SYS_SET_INVOICE_TYPE,
                payload: res.data
            });
        })
};
export const systemBranchLoad = (input) => dispatch => {
    return axios
        .get('/me/repository/branch/' )
        .then(res => {
            if(res.status===200) dispatch({
                type: SYS_SET_BRANCH_CODE,
                payload: res.data
            });
        })
};
export const systemTaxCodeLoad = (input) => dispatch => {
    return axios
        .get('/me/repository/tax-code/' )
        .then(res => {
            if(res.status===200) dispatch({
                type: SYS_SET_TAX_CODE,
                payload: res.data
            });
        })
};
export const systemTaxItemLoad = (input) => dispatch => {
    return axios
        .get('/me/repository/tax-item/' )
        .then(res => {
            if(res.status===200) dispatch({
                type: SYS_SET_TAX_ITEM,
                payload: res.data
            });
        })
};
export const systemPaymentTermLoad = (input) => dispatch => {
    return axios
        .get('/me/repository/payment-term/' )
        .then(res => {
            if(res.status===200) dispatch({
                type: SYS_SET_PAYMENT_TERM,
                payload: res.data
            });
        })
};