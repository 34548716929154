import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './AppReduxIndex';

const initialState = {};

const middleware = [thunk];

let ReduxStore;

if (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'test') {
  ReduxStore = createStore(rootReducer, initialState, compose(applyMiddleware(...middleware)));
} else {
  ReduxStore = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
  );
}
export default ReduxStore;
