import axios from 'axios';
import { authenticationLogoutAction } from '../../app/redax/Authorization/AuthorizationAction';
import { notificationDanger, notificationDangerCustomTime, notificationSuccess } from '../../utils/toastify';

export const httpApiConfig = (store) => {
  if (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'test') {
    axios.defaults.baseURL = '/api/v1';
  } else {
    //axios.defaults.baseURL = 'https://compassaccounting.net/api/v1';
    axios.defaults.baseURL = 'http://localhost:2522/api/v1/';
  }
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        store.dispatch(authenticationLogoutAction());
      }

      if (error.response.data?.isMiddleware) {
        notificationDangerCustomTime(error.response.data.error, 10000);
        return Promise.reject(error);
      }

      if (!error.response.data?.isCustom) {
        if (error.response.data.msg) {
          notificationDanger(error.response.data.msg);
        } else notificationDanger('Server error!');
      }
      // react-query will never enter onError without reject
      return Promise.reject(error);
      // return error;
    }
  );
  axios.defaults.timeout = 10000000;
};
