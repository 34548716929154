import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';

const CustomButton = ({ onClick, style, className, color, icon, children, disabled, hidden }) => {
  return (
    <>
      <Button color={color} className={`btn ${className}`} onClick={onClick} style={style} disabled={disabled} hidden={hidden}>
        <div className='d-flex flex-row align-items-center'>
          {icon && <i className={icon} style={{ paddingRight: '5px' }} />} {children}
        </div>
      </Button>
    </>
  );
};

CustomButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Element)]).isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string, // font awesome classes
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
};

CustomButton.defaultProps = {
  onClick: () => {},
  children: 'Button',
  color: 'primary',
  className: '',
  style: {},
  icon: '',
  disabled: false,
  hidden: false,
};

export { CustomButton };
