import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import DataTablePagination from '../../../../../component/DataTable/component/DataTablePagination';
import { DataTableShadowComponent } from '../../../../../component/DataTable/component/DataTableShadowComponent';
import { ExpendPendingGeneralLedgerDataTable } from './ExpendPendingGeneralLedgerDataTable';

export const PendingTransactionsDataTable = ({
  dataSource = { data: [], page: 1, pageSize: 10, pageTotal: 0 },
  onFilterChange = () => {},
  columns = [],
  loading = false,
  pagination = true,
  className = '',
  defaultPageSize,
  expandedAll,
  ...props
}) => {
  const [openRow, setOpenRows] = useState([]);

  useEffect(() => {
    setOpenRows([]);
  }, [dataSource]);

  useEffect(() => {
    if (expandedAll === true) {
      const after = dataSource.data.map(() => {
        return true;
      });
      setOpenRows(after);
    } else {
      setOpenRows([]);
    }
  }, [expandedAll]);

  const onExpandedChange = (rows) => {
    setOpenRows(rows);
  };

  function SubComponentPendingGl({
    row: {
      _original: { gls },
    },
  }) {
    return <ExpendPendingGeneralLedgerDataTable gls={gls} />;
  }

  const onFilterPageChange = (e) => {
    const f = { page: e };
    onFilterChange(f);
  };

  const onFilterPageSizeChange = (e) => {
    const f = { pageSize: e };
    onFilterChange(f);
  };

  const onSortChange = (e) => {
    const column = e[0].id;
    const method = e[0].desc ? 'DESC' : 'ASC';
    const f = { sort_by: column, order_by: method };
    onFilterChange(f);
  };

  return (
    <ReactTable
      showPaginationBottom={pagination}
      PaginationComponent={DataTablePagination}
      TableComponent={DataTableShadowComponent}
      className={`-striped -highlight ${className}`}
      getFooterProps={() => ({ style: { display: 'block' } })}
      defaultSortDesc
      data={dataSource.data}
      columns={columns}
      page={dataSource.page}
      pageSize={dataSource.pageSize}
      pages={dataSource.pageTotal}
      minRows={dataSource.data && dataSource.data.length > 5 ? 5 : dataSource.data ? dataSource.data.length : 1}
      defaultPageSize={defaultPageSize || 10}
      showPageSizeOptions
      NoDataComponent={() => null}
      manual
      onPageSizeChange={onFilterPageSizeChange}
      onPageChange={onFilterPageChange}
      onSortedChange={onSortChange}
      onExpandedChange={onExpandedChange}
      SubComponent={SubComponentPendingGl}
      expanded={openRow}
      {...props}
    />
  );
};
