import React, { Fragment, useState } from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { clientString2Int } from '../../utils/fastTools';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = (data) => {
  return (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
};

export default function ClientSelectBox({
  onChange = null,
  clientType = null,
  name = 'client',
  field,
  onSelectSearch = null,
  client_id = null,
  defaults = {},
  isHeader = false,
}) {
  const [loading, setLoading] = useState(false);
  const selectHandler = (val, e) => {
    if (e.action === 'select-option') {
      if (onSelectSearch) onSelectSearch(val);
      let o = { target: { name: e.name, value: val.value } };
      if (onChange) onChange(o);
    }
    if (e.action === 'clear') {
      let o = { target: { name: e.name, value: null } };
      if (onChange) onChange(o);
    }
  };

  const dataFilter = (data) => {
    if (!Array.isArray(data)) return [];
    if (data.length < 1) return [];
    return data.reduce((option, i) => {
      if (!Array.isArray(option)) option = [];
      const type = i.client_type_id === 1 ? 'CUSTOMERS' : 'VENDORS';
      let f = option.findIndex((x) => x.label === type);
      if (f < 0) {
        option.push({ label: type, options: [{ value: i.id, label: i.name, type: i.client_type_id }] });
      } else {
        option[f].options.push({ value: i.id, label: i.name, type: i.client_type_id });
      }
      return option;
    }, {});
  };
  const getData = (inputValue, callback) => {
    // return [
    //     {
    //       label: "Group 1",
    //       options: [
    //         { label: "Group 1, option 1", value: "value_1" },
    //         { label: "Group 1, option 2", value: "value_2" },
    //       ],
    //     },
    //     { label: "A root option", value: "value_3" },
    //     { label: "Another root option", value: "value_4" },
    //   ];
    const input = { q: inputValue };
    if (clientType) {
      input.client_type_id = clientString2Int(clientType);
    }
    if (client_id) {
      input.id = client_id;
    }
    axios
      .get('/me/client/s/', { params: { ...input } })
      .then((res) => {
        if (res.status === 200) {
          callback(dataFilter(res.data));
        } else {
          callback([]);
        }
      })
      .catch((e) => {
        callback([]);
      });
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '1.5rem',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
  };

  return (
    <Fragment>
      <AsyncSelect
        name={name}
        isClearable={true}
        defaultOptions={defaults}
        loadOptions={getData}
        onChange={selectHandler}
        placeholder={'Select Customer / Vendor'}
        value={field}
        formatGroupLabel={formatGroupLabel}
        disabled={client_id}
        styles={
          isHeader
            ? customStyles
            : {
                indicatorSeparator: () => ({
                  display: 'none',
                }),
              }
        }
      />
    </Fragment>
  );
}
