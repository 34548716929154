import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Box, Button, DialogActions, DialogContent, IconButton } from '@mui/material';
import { ClearOutlined, DeleteForeverOutlined } from '@mui/icons-material';

import DataGridComponent from '../../../component/DataTable/component/DataTableComponent';
import ExportButton from '../../../component/Buttons/ExportButton';
import { CustomCard } from '../../../common/Card/CustomCard';
import { BankReconciliationTableColumns } from './columns/BankReconciliationTableColumns';
import { useDeleteBancReco, useGetBankRecoTableData, useReopenReco } from './BankRecoApiHooks';

import { exportExcel } from '../../../utils/exportExcel';
import { exportPdf } from '../../../utils/exportPdf';
import { downloaderFiles } from '../../../utils/downloaderFiles';
import { hasPermissionHook } from '../../../hooks/hasPermission';
import { BANK_RECONCILIATION, EDIT } from '../../../constants/permissions.constants';
import { CustomPopup } from '../../../common/Popup/CustomPopup';

const BankReconciliationTable = () => {
  const { hasPermission } = hasPermissionHook();
  const history = useHistory();
  const [filters, setFilters] = React.useState({
    page: 0,
    pageSize: 10,
    sort_by: 'create_at',
    order_by: 'DESC',
  });
  const [container, setContainer] = React.useState();
  const [deleteData, setDeleteData] = React.useState({
    deleteAnchor: null,
  });

  const { mutate: deleteBankReco } = useDeleteBancReco();
  const { mutate: reopenReconciliation } = useReopenReco();

  const onOpenReport = (row) => {
    history.push(`/bank-reconciliation/report/${row.original.id}`);
  };

  const onUpdate = (row) => {
    history.push(`/bank-reconciliation/reconciliation/${row.original.id}`);
  };

  const onDelete = (e, rowData) => {
    setDeleteData({
      deleteAnchor: e.currentTarget,
      openDeletePopup: true,
      id: rowData.original.id,
    });
  };

  const onReopen = (row) => {
    reopenReconciliation(row.original);
  };

  const columns = React.useMemo(() => BankReconciliationTableColumns({ onOpenReport, onUpdate, onDelete, onReopen }), []);
  const cleanDataSource = React.useMemo(() => ({ data: [], page: 0, pageSize: 10, pageTotal: 0 }));

  const { data: tableData } = useGetBankRecoTableData(filters);

  const onFilterChange = (e) => {
    setFilters({ ...filters, ...e });
  };

  const excelHeader = React.useMemo(
    () => [
      { header: 'Statement Date', key: 'statment_date' },
      { header: 'Ending Balance', key: 'ending_balance', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
      { header: 'Begining Balance', key: 'begining_balance', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
      { header: 'Account', key: 'account' },
    ],
    []
  );

  const onFilterActionBtn = (e) => {
    if (e.toString() === 'PDF' || e.toString() === 'EXCEL') {
      downloaderFiles({ filter: filters, type: e, url: '/me/bank/bank-reconciliation', cbLoad: () => {}, name: 'Bank Reconciliation' });
    }
  };

  const handleConfirmDeleteClick = () => {
    deleteBankReco(deleteData.id);
    setDeleteData({ deleteAnchor: null });
  };

  return (
    <CustomCard
      headerTitle={
        <div className='d-flex justify-content-between align-items-center'>
          <span>Bank Reconciliation Table</span>
          <ExportButton
            disabled={false}
            exportTablePDF={() => {
              onFilterActionBtn('PDF');
            }}
            exportTableEXCEL={() => {
              onFilterActionBtn('EXCEL');
            }}
            exportPagePDF={() => exportPdf(container, 'Bank Reconciliation')}
            exportPageEXCEL={() => exportExcel(tableData?.data?.data, excelHeader, 'Bank Reconciliation')}
          />
        </div>
      }
      sxCardContainer={{ marginTop: '15px' }}
    >
      <Row className='mb-3'>
        <Col className=''>
          <Button
            color={'success'}
            onClick={() => history.push('/bank-reconciliation/reconciliation')}
            disabled={!hasPermission(BANK_RECONCILIATION, EDIT)}
          >
            Start New Reconciliation
          </Button>
        </Col>
      </Row>
      <Row>
        <Col
          ref={(instance) => {
            setContainer(instance);
          }}
        >
          <DataGridComponent columns={columns} loading={false} dataSource={tableData?.data || cleanDataSource} onFilterChange={onFilterChange} />
        </Col>
      </Row>
      <CustomPopup
        anchor={deleteData.deleteAnchor}
        content={<DeletePopupContent onConfirm={handleConfirmDeleteClick} onCancel={() => setDeleteData((prev) => ({ deleteAnchor: null }))} />}
        arrow
        placement='bottom'
        onClose={() => setDeleteData((prev) => ({ deleteAnchor: null }))}
        open={deleteData.openDeletePopup}
      />
    </CustomCard>
  );
};

const DeletePopupContent = ({ onConfirm, onCancel, message = `Are you sure you want to delete this reconciliation entry?` }) => {
  return (
    <>
      <Box>
        <DialogContent sx={{ padding: '5px' }}>{message}</DialogContent>
        <DialogActions sx={{ padding: '5px' }}>
          <IconButton color='error' onClick={onConfirm}>
            <DeleteForeverOutlined />
          </IconButton>
          <IconButton onClick={onCancel}>
            <ClearOutlined />
          </IconButton>
        </DialogActions>
      </Box>
    </>
  );
};

export default BankReconciliationTable;
