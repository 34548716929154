import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Check, Remove } from '@mui/icons-material';
import { formatDateForTable } from '../../../../utils/formatDataForTable';
import { formatAmount } from '../../../../utils/formatAmout';

export const DepreciationItemsTableColumns = () => {
  return [
    {
      accessor: 'asset_name',
      Header: 'Asset Name',
      Cell: (row) => {
        return row?.value;
      },
    },
    {
      accessor: 'from_date',
      Header: 'From Date',
      Cell: (row) => formatDateForTable(row?.value),
    },
    {
      accessor: 'to_date',
      Header: 'To Date',
      Cell: (row) => formatDateForTable(row?.value),
    },
    {
      accessor: 'depreciation_amount',
      Header: 'Depreciation Amount',
      Cell: (row) => formatAmount(row?.value),
    },
    {
      accessor: 'net_book_value',
      Header: 'Net Book Value',
      Cell: (row) => formatAmount(row?.value),
    },
  ];
};
