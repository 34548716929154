import { Button } from '@mui/material';
import { useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { CustomInput } from '../../../../../../common/CustomInput';
import { CustomSelect } from '../../../../../../common/CustomSelect';
import { DatePickerComponent } from '../../../../../../common/DatePicker/DatePicker';
import { CustomCheckbox } from '../../../../../../common/Inputs/CustomCheckbox';
import { CustomPageableAsyncSelect } from '../../../../../../common/Inputs/CustomPageableAsyncSelect';
import { CustomToggleButtonGroup } from '../../../../../../common/Inputs/CustomToggleButtonGroup';
import { agedByOptions } from '../../../../../../constants/agedByOptions.constants';
import { CUSTOMER_TYPE_STRING } from '../../../../../../constants/clientType.constants';
import { BRANCH_TABLE } from '../../../../../../constants/supportTables.constants';
import { useGetSupportTablesForSelect } from '../../../../../../hooks/commonApiHooks';
import { useAsyncPageable } from '../../../../../../hooks/useAsyncPageable';
import { clientString2Int } from '../../../../../../utils/fastTools';
import { clientTypeNameSingular } from '../../../utils';
import { customerTransactioTypeOptions, vendorTransactioTypeOptions } from '../../../../../../constants/documentType.constants';

export const AgingFilters = ({
  filter,
  onChange,
  onSelectChange,
  onSelectTransactionTypeChange,
  onSwitchChange,
  onDateChange,
  onFilterActionBtn,
  isDetailed,
  onButtonGroupChange,
}) => {
  const { clientType } = useParams();

  const [init, setInit] = useState(false);

  const { data: { data: supportTableOptions = {} } = {} } = useGetSupportTablesForSelect({
    table_names: [BRANCH_TABLE],
  });

  useEffect(() => {
    setInit(false);

    //force rerendering of async inputs
    setTimeout(() => {
      setInit(true);
    }, 1);
  }, [clientType]);

  const initClientFilters = useMemo(() => {
    return { client_type_id: clientString2Int(clientType) };
  }, [clientType]);

  const { fetchOptions } = useAsyncPageable();

  const loadClientExternalIdOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/client/customer/options-number-for-select', { q: inputValue, ...options });
    return data;
  };

  const loadClientRmiIdOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/client/customer/options-rmi-id-for-select', { q: inputValue, ...options });
    return data;
  };

  const loadVendorParentOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/client/vendor/options-vendor-parent-for-select', { q: inputValue, ...options });
    return data;
  };

  return (
    <>
      <Row>
        <Col xs={12} md={4} lg={3}>
          <DatePickerComponent
            showYearDropdown
            name='aged_as_of'
            selected={filter['aged_as_of']}
            onChange={(date) => onDateChange(date, 'aged_as_of')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Aged As Of'
          />
        </Col>
        <Col xs={12} md={4} lg={3}>
          <CustomSelect label='Aged By' onChange={onSelectChange} name='aged_by' defaultValue={filter['aged_by'] || null} options={agedByOptions} />
        </Col>
        {/* <Col xs={12} md={4} lg={3}>
          <CustomInput type='number' name='aging_period' value={filter['aging_period']} onChange={onChange} label='Length of Aging Period (days)' />
        </Col> */}
        <Col xs={12} md={4} lg={3}>
          {init && (
            <CustomPageableAsyncSelect
              label={clientTypeNameSingular(clientType) + ' No.'}
              isClearable
              onChange={onSelectChange}
              loadOptions={loadClientExternalIdOptions}
              name='client.id'
              defaultValue={filter['client.id']}
              initFilters={initClientFilters}
            />
          )}
        </Col>
        <Col xs={12} md={4} lg={3}>
          {init && (
            <CustomPageableAsyncSelect
              label={'RMI No.'}
              isClearable
              onChange={onSelectChange}
              loadOptions={loadClientRmiIdOptions}
              name='client.rmi_number'
              defaultValue={filter['client.rmi_number']}
              initFilters={initClientFilters}
            />
          )}
        </Col>
        <Col xs={12} md={4} lg={3}>
          <CustomSelect
            label='Branch'
            isClearable
            onChange={onSelectChange}
            name='general_ledger.branch_id'
            defaultValue={filter['general_ledger.branch_id'] || null}
            options={supportTableOptions[BRANCH_TABLE] || []}
          />
        </Col>
        <Col xs={12} md={4} lg={3}>
          <CustomInput name='client.salesperson' value={filter['client.salesperson'] || ''} onChange={onChange} label='Salesperson' />
        </Col>
        <Col xs={12} md={4} lg={3}>
          <CustomToggleButtonGroup size='small' label='ACH' name='client.ach' value={filter['client.ach'] ?? null} onChange={onButtonGroupChange} />
        </Col>
        {clientType === CUSTOMER_TYPE_STRING && (
          <Col xs={12} md={4} lg={3}>
            <CustomToggleButtonGroup size='small' label='CFS' name='client.cfs' value={filter['client.cfs'] ?? null} onChange={onButtonGroupChange} />
          </Col>
        )}
      </Row>
      {isDetailed && (
        <>
          <Row>
            <Col lg={3} md={4} sm={12}>
              <DatePickerComponent
                showYearDropdown
                name='posting_date_from'
                selected={filter['posting_date_from']}
                onChange={(date) => onDateChange(date, 'posting_date_from')}
                placeholder='mm/dd/yyyy'
                dateFormat={'MM/dd/yyyy'}
                label='Posting Date From'
                isClearable
              />
            </Col>
            <Col lg={3} md={4} sm={12}>
              <DatePickerComponent
                showYearDropdown
                name='posting_date_to'
                selected={filter['posting_date_to']}
                onChange={(date) => onDateChange(date, 'posting_date_to')}
                placeholder='mm/dd/yyyy'
                dateFormat={'MM/dd/yyyy'}
                label='Posting Date To'
                isClearable
              />
            </Col>
            <Col lg={3} md={4} sm={12}>
              <DatePickerComponent
                showYearDropdown
                name='invoice_date_from'
                selected={filter['invoice_date_from']}
                onChange={(date) => onDateChange(date, 'invoice_date_from')}
                placeholder='mm/dd/yyyy'
                dateFormat={'MM/dd/yyyy'}
                label='Aging Date (Trx Date) From'
                isClearable
              />
            </Col>
            <Col lg={3} md={4} sm={12}>
              <DatePickerComponent
                showYearDropdown
                name='invoice_date_to'
                selected={filter['invoice_date_to']}
                onChange={(date) => onDateChange(date, 'invoice_date_to')}
                placeholder='mm/dd/yyyy'
                dateFormat={'MM/dd/yyyy'}
                label='Aging Date (Trx Date) To'
                isClearable
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3} md={4} sm={12}>
              <CustomSelect
                label='Transaction Type'
                isClearable
                onChange={onSelectTransactionTypeChange}
                name='transactions_type'
                defaultValue={filter['transactions_type'] || null}
                options={clientString2Int(clientType) === 1 ? customerTransactioTypeOptions : vendorTransactioTypeOptions}
              />
            </Col>
            {clientString2Int(clientType) === 2 && (
              <Col lg={3} md={4} sm={12}>
                <CustomPageableAsyncSelect
                  label={'Vendor Parent'}
                  isClearable
                  onChange={onSelectChange}
                  loadOptions={loadVendorParentOptions}
                  name='client.vendor_parent'
                  defaultValue={filter['client.vendor_parent']}
                />
              </Col>
            )}
          </Row>
        </>
      )}
      <Row>
        <Col className='d-flex justify-content-end'>
          <Button
            onClick={() => {
              onFilterActionBtn('search');
            }}
          >
            Search
          </Button>
        </Col>
      </Row>
    </>
  );
};
