import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { BILLING_PAYMENT_DOCUMENT_NUMBER_CODE } from '../../../../constants/tracker.constants';
import { notificationDanger, notificationSuccess } from '../../../../utils/toastify';
import { normalizeClientType } from '../utils';

const downloadFileFromBlob = (res, options = { name: 'file' }) => {
  if (res?.status === 200) {
    let filename = options.name;
    if (res.headers['content-disposition']) {
      const [_, originalFileName] = res.headers['content-disposition'].split('filename=');
      filename = originalFileName;
    }

    saveAs(new Blob([res.data]), filename);
  }
};

export const composeBaseUrl = (clientType) => {
  const normalizedClientType = normalizeClientType(clientType);

  const url = `/me/client/${normalizedClientType}/billing-payments`;
  return url;
};

const getClient = (clientType, id) => {
  if (!id) return null;
  const baseUrl = composeBaseUrl(clientType);
  const url = `${baseUrl}/clients/${id}`;
  return axios.get(url);
};

export const useBillingPaymentClient = (clientType, id) => {
  return useQuery(['billing-payments.client', id], () => getClient(clientType, id), { keepPreviousData: false });
};

const getBillingPayment = (clientType, id) => {
  if (!id) {
    return null;
  }

  const baseUrl = composeBaseUrl(clientType);
  const url = `${baseUrl}/billings/${id}`;

  return axios.get(url);
};

export const useBillingPayment = (clientType, id) => {
  return useQuery(['billing-payment', id], () => getBillingPayment(clientType, id), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const getBillingPayments = (clientType, filters) => {
  const baseUrl = composeBaseUrl(clientType);

  return axios.get(baseUrl, { params: { ...filters } });
};

export const useBillingPayments = (clientType, filters, { enabled }) => {
  const { page, pageSize, sort_by, order_by } = filters;

  return useQuery(['billing-payments', page, pageSize, sort_by, order_by, clientType], () => getBillingPayments(clientType, filters), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled,
  });
};

const getNachaFile = ({ billingId, clientType, filters, data }) => {
  const baseUrl = composeBaseUrl(clientType);
  return axios.get(`${baseUrl}/billings/${billingId}/ach`, { params: { ...filters, ...data } });
};

export const useNachaDownload = ({ billingId, clientType, filters, callbacks: { onError = () => {}, onSuccess = () => {} } = {} }) => {
  return useMutation(
    ({ fileCreationDate, effective_date }) => getNachaFile({ billingId, clientType, filters, data: { fileCreationDate, effective_date } }),
    {
      onSuccess: (res) => {
        onSuccess();
        downloadFileFromBlob(res, { name: 'NACHA' });
      },
      onError: (error) => {
        notificationDanger(error.response.data?.error);
        onError();
      },
    }
  );
};

const createPayment = ({ clientType, data }) => {
  const baseUrl = composeBaseUrl(clientType);

  return axios.post(`${baseUrl}`, data);
};

export const useCreateBillingPayment = (clientType, callbacks) => {
  const queryClient = useQueryClient();
  return useMutation((data) => createPayment({ clientType, data }), {
    onSuccess: (data) => {
      if (callbacks?.successCallback && typeof callbacks?.successCallback === 'function') {
        const billingId = data?.data?.id;
        // redirect
        callbacks.successCallback(billingId);
      }
      queryClient.invalidateQueries(['billing-payments']);
    },
    onError: () => {
      if (callbacks?.errorCallback && typeof callbacks?.errorCallback === 'function') {
        callbacks.errorCallback();
      }
    },
  });
};

const updateBillingPayment = ({ id, data, clientType }) => {
  if (!id) return null;

  const baseUrl = composeBaseUrl(clientType);
  return axios.patch(`${baseUrl}/billings/${id}`, data);
};

export const useUpdateBillingPayment = (id, clientType) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateBillingPayment({ id, clientType, data }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['billing-payments']);
      queryClient.invalidateQueries(['billing-payment']);
      queryClient.invalidateQueries(['billing-payments.items']);
    },
  });
};

const deleteBillingPayment = ({ id, clientType }) => {
  if (!id) {
    return null;
  }

  const baseUrl = composeBaseUrl(clientType);
  const url = `${baseUrl}/billings/${id}`;
  return axios.delete(url);
};

export const useDeleteBillingPayment = ({ clientType, callbacks = {} }) => {
  const queryClient = useQueryClient();
  return useMutation((id) => deleteBillingPayment({ id, clientType }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['billing-payments']);
      queryClient.invalidateQueries(['billing-payments.items']);
      queryClient.invalidateQueries(['billing-payments.checks']);
      queryClient.invalidateQueries(['billing-payments.client-unpaid-transactions.all']);

      if (callbacks?.successCallback && typeof callbacks?.successCallback === 'function') {
        callbacks.successCallback();
      }
    },
    onError: () => {
      if (callbacks?.errorCallback && typeof callbacks?.errorCallback === 'function') {
        callbacks.errorCallback();
      }
    },
  });
};

const deleteNotPostedBillingPayments = (clientType) => {
  const baseUrl = composeBaseUrl(clientType);
  const url = `${baseUrl}/billings`;

  return axios.delete(url);
};

export const useDeleteNotPostedBillingPayments = ({ clientType, successCallback }) => {
  const queryClient = useQueryClient();
  return useMutation(() => deleteNotPostedBillingPayments(clientType), {
    onSuccess: () => {
      queryClient.invalidateQueries(['billing-payments']);
      successCallback && successCallback();
    },
  });
};

const getClientUnpaidTransactionsAsOptions = ({ billingId, clientId, clientType, dueDate }) => {
  if (!billingId || !clientId) {
    return null;
  }

  const baseUrl = composeBaseUrl(clientType);
  const url = `${baseUrl}/billings/${billingId}/${clientId}/transactions/options`;
  return axios.get(url, { params: { dueDate } });
};

export const useClientUnpaidTransactionsOptions = ({ clientType, clientId, billingId, dueDate }) => {
  return useQuery(
    ['billing-payments.client-unpaid-transactions.options', clientType, clientId, billingId, dueDate],
    () => getClientUnpaidTransactionsAsOptions({ clientType, clientId, billingId, dueDate }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: true,
      enabled: !!clientId && !!billingId,
    }
  );
};

const getAllClientsWithUnpaidTransactions = ({ billingId, clientType, filters }) => {
  if (!billingId) {
    return null;
  }

  const baseUrl = composeBaseUrl(clientType);
  const url = `${baseUrl}/billings/${billingId}/transactions`;
  return axios.get(url, { params: { ...filters } });
};

export const useAllClientsWithUnpaidTransactions = ({ billingId, clientType, filters }) => {
  const { page, pageSize, sort_by, order_by } = filters;
  return useQuery(
    ['billing-payments.client-unpaid-transactions.all', page, pageSize, sort_by, order_by],
    () => getAllClientsWithUnpaidTransactions({ billingId, clientType, filters }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!billingId,
    }
  );
};

const getClientUnpaidTransactions = ({ billingId, billingItemId, clientId, clientType, filters }) => {
  if (!billingId || !billingItemId) {
    return null;
  }

  const baseUrl = composeBaseUrl(clientType);
  const url = `${baseUrl}/billings/${billingId}/items/${billingItemId}/transactions`;
  return axios.get(url, { params: { ...filters } });
};

export const useClientUnpaidTransactions = ({ billingId, billingItemId, clientId, clientType, filters }) => {
  const { page, pageSize, sort_by, order_by } = filters;
  return useQuery(
    ['billing-payments.client-unpaid-transactions', page, pageSize, sort_by, order_by, clientId],
    () => getClientUnpaidTransactions({ billingId, billingItemId, clientId, clientType, filters }),
    { keepPreviousData: true }
  );
};

const getClientsWithUnpaidTransactions = (clientType, filters) => {
  const baseUrl = composeBaseUrl(clientType);
  const url = `${baseUrl}/clients`;
  return axios.get(url, { params: { ...filters } });
};

export const useClientsWithUnpaidTransactions = (clientType, filters) => {
  const { page, pageSize, sort_by, order_by } = filters;
  return useQuery(
    ['billing-payments.clients', page, pageSize, sort_by, order_by, clientType],
    () => getClientsWithUnpaidTransactions(clientType, filters),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: 1,
    }
  );
};

const getBalanceAccount = (clientType, billingId) => {
  const baseUrl = composeBaseUrl(clientType);
  return axios.get(`${baseUrl}/billings/${billingId}/balance-account`);
};

export const useSelectedBalanceAccount = (clientType, billingId) => {
  return useQuery(['billing-payments.balance-account', clientType, billingId], () => getBalanceAccount(clientType, billingId), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!billingId,
  });
};

const getDocumentNumber = (clientType, code) => {
  const baseUrl = composeBaseUrl(clientType);
  return axios.get(`${baseUrl}/number`, { params: { code } });
};

export const useBillingPaymentDocumentNumber = (clientType, enabled) => {
  return useQuery(['billing-payments.doc-number'], () => getDocumentNumber(clientType, BILLING_PAYMENT_DOCUMENT_NUMBER_CODE), {
    enabled,
    keepPreviousData: false,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });
};

const postBillingPayment = ({ clientType, id, checkData = null, options }) => {
  if (!id) return null;

  const baseUrl = composeBaseUrl(clientType);
  const url = `${baseUrl}/billings/${id}/post`;

  return axios.post(url, { create_at: new Date(), checkData, options });
};

export const useBillingPaymentPosting = ({ clientType, options = {}, callbacks = {} }) => {
  const queryClient = useQueryClient();

  let printData = { printed: true, print_date: moment().format('MM/DD/YYYY') };
  return useMutation(
    ({ id, checkData }) => {
      if (checkData) {
        printData = {
          ...printData,
          print_date: moment(checkData.posting_date).format('MM/DD/YYYY'),
        };
      }

      return postBillingPayment({ clientType, id, checkData, options });
    },
    {
      onSuccess: (res) => {
        if (res?.data?.checks?.length) {
          // print checks if post is successful
          const { checks } = res.data;
          printChecks({ clientType, data: { ...printData, ids: checks, isPrint: true } }).then((res) => {
            downloadFileFromBlob(res, { name: 'Checks' });
          });
        }

        if (callbacks?.successCallback && typeof callbacks?.successCallback === 'function') {
          callbacks.successCallback();
        }

        queryClient.invalidateQueries(['billing-payment']);
        queryClient.invalidateQueries(['billing-payments']);
        queryClient.invalidateQueries(['billing-payments.clients']);
        queryClient.invalidateQueries(['client-unpaid-transactions']);
        queryClient.invalidateQueries(['billing-payments.items']);
        queryClient.invalidateQueries(['billing-payments.checks']);

        notificationSuccess('Vendor Pay Bills successfully posted!');
      },
      onError: (error) => {
        if (callbacks?.errorCallback && typeof callbacks?.errorCallback === 'function') {
          if (error.response.status === 422) {
            callbacks.errorCallback(error?.response?.data?.error);
          }
        }
      },
    }
  );
};

//! items
const createPaymentItems = ({ clientType, data = [], billingId, multiple = false }) => {
  const baseUrl = composeBaseUrl(clientType);

  return axios.post(`${baseUrl}/billings/${billingId}/items/${multiple}`, data);
};

export const useCreateBillingPaymentItems = ({ clientType, billingId, multiple, callbacks = {} }) => {
  const queryClient = useQueryClient();
  return useMutation((data) => createPaymentItems({ clientType, data, billingId, multiple }), {
    onSuccess: () => {
      if (callbacks?.successCallback && typeof callbacks?.successCallback === 'function') {
        callbacks.successCallback();
      }

      queryClient.invalidateQueries(['billing-payments.items']);
      queryClient.invalidateQueries(['billing-payments.checks']);
      queryClient.invalidateQueries(['billing-payments']);
      queryClient.invalidateQueries(['billing-payments.has-printed-checks']);
      queryClient.invalidateQueries(['billing-payment']);
      queryClient.invalidateQueries(['billing-payments.client-unpaid-transactions.all']);
    },
    onError: () => {
      notificationDanger('Unable to Create Payment Items');
    },
  });
};

const updateBillingPaymentItem = ({ id, billingId, data, clientType }) => {
  if (!id || !billingId) return null;

  const baseUrl = composeBaseUrl(clientType);
  return axios.patch(`${baseUrl}/billings/${billingId}/items/${id}`, data);
};

export const useUpdateBillingPaymentItem = (clientType, id, billingId, callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateBillingPaymentItem({ id, billingId, clientType, data }), {
    onSuccess: () => {
      if (callbacks?.successCallback && typeof callbacks?.successCallback === 'function') {
        callbacks.successCallback();
      }

      queryClient.invalidateQueries(['billing-payments.items']);
      queryClient.invalidateQueries(['billing-payments.checks']);
      queryClient.invalidateQueries(['billing-payments']);
      queryClient.invalidateQueries(['billing-payment']);
      queryClient.invalidateQueries(['billing-payments.client-unpaid-transactions.all']);
    },
  });
};

const getBillingPaymentItem = (clientType, id, billingId) => {
  if (!id || !billingId) return null;
  const baseUrl = composeBaseUrl(clientType);

  return axios.get(`${baseUrl}/billings/${billingId}/items/${id}`);
};

export const useBillingPaymentItem = (clientType, id, billingId) => {
  return useQuery(['billing-payments.item'], () => getBillingPaymentItem(clientType, id, billingId), {
    // keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const getBillingPaymentItemByTransactionId = ({ clientType, transactionId }) => {
  const baseUrl = composeBaseUrl(clientType);

  return axios.get(`${baseUrl}/items/transactions/${transactionId}`);
};

export const useTransactionsBillingPayment = ({ clientType, transactionId, enabled }) => {
  return useQuery(
    ['billing-payments.transactions.billing-payment-item', transactionId, clientType],
    () => getBillingPaymentItemByTransactionId({ clientType, transactionId }),
    {
      enabled,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const getBillingPaymentItems = ({ clientType, billingId, filters }) => {
  if (!billingId) return null;

  const baseUrl = composeBaseUrl(clientType);
  return axios.get(`${baseUrl}/billings/${billingId}/items`, { params: { ...filters } });
};

export const useBillingPaymentItems = (clientType, billingId, filters) => {
  const { page, pageSize, sort_by, order_by } = filters;

  return useQuery(['billing-payments.items', page, pageSize, sort_by, order_by], () => getBillingPaymentItems({ clientType, billingId, filters }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const getItemDocumentNumber = (clientType, billingId) => {
  const baseUrl = composeBaseUrl(clientType);

  return axios.get(`${baseUrl}/billings/${billingId}/items/document-number`);
};

export const useBillingPaymentItemDocumentNumber = (clientType, billingId) => {
  return useQuery(['billing-payments.items.doc-number'], () => getItemDocumentNumber(clientType, billingId), {
    enabled: !!billingId,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
};

const deleteBillingPaymentItem = ({ id, billingId, clientType }) => {
  if (!id || !billingId) return null;

  const baseUrl = composeBaseUrl(clientType);
  const url = `${baseUrl}/billings/${billingId}/items/${id}`;
  return axios.delete(url);
};

export const useDeleteBillingPaymentItem = (clientType) => {
  const queryClient = useQueryClient();
  return useMutation(({ id, billingId }) => deleteBillingPaymentItem({ id, billingId, clientType }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['billing-payment']);
      queryClient.invalidateQueries(['billing-payments']);
      queryClient.invalidateQueries(['billing-payments.has-printed-checks']);
      queryClient.invalidateQueries(['billing-payments.items']);
      queryClient.invalidateQueries(['billing-payments.checks']);
      queryClient.invalidateQueries(['billing-payments.client-unpaid-transactions.all']);
    },
  });
};

const clearTransaction = ({ clientType, billingId, billingItemId, ...rest }) => {
  const baseUrl = composeBaseUrl(clientType);

  const url = `${baseUrl}/billings/${billingId}/items/${billingItemId}/clearing`;
  return axios.patch(url, rest);
};

export const useBillingTransactionClearing = ({ clientType, successCallback }) => {
  const queryClient = useQueryClient();
  return useMutation((data) => clearTransaction({ ...data, clientType }), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['billing-payments']);
      queryClient.invalidateQueries(['billing-payments.item']);
      queryClient.invalidateQueries(['billing-payments.items']);
      queryClient.invalidateQueries(['billing-payments.client-unpaid-transactions']);
      queryClient.invalidateQueries(['billing-payments.client-unpaid-transactions.all']);

      successCallback && successCallback(data);
    },
  });
};

const cancelTransactionClearing = ({ clientType, billingId, billingItemId, ...rest }) => {
  const baseUrl = composeBaseUrl(clientType);

  const url = `${baseUrl}/billings/${billingId}/items/${billingItemId}/cancel-clearing`;
  return axios.patch(url, rest);
};

export const useCancelBillingTransactionClearing = ({ clientType, successCallback }) => {
  const queryClient = useQueryClient();
  return useMutation((data) => cancelTransactionClearing({ ...data, clientType }), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['billing-payments']);
      queryClient.invalidateQueries(['billing-payments.item']);
      queryClient.invalidateQueries(['billing-payments.items']);
      queryClient.invalidateQueries(['billing-payments.client-unpaid-transactions']);
      queryClient.invalidateQueries(['billing-payments.client-unpaid-transactions.all']);

      successCallback && successCallback(data);
    },
  });
};

//! checks
const hasPrintedChecks = (clientType, billingId) => {
  const baseUrl = composeBaseUrl(clientType);

  return axios.get(`${baseUrl}/billings/${billingId}/has-printed-checks`);
};

export const useHasPrintedChecks = (clientType, billingId) => {
  return useQuery(['billing-payments.has-printed-checks'], () => hasPrintedChecks(clientType, billingId), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const getCheck = (clientType, checkId) => {
  const baseUrl = composeBaseUrl(clientType);

  return axios.get(`${baseUrl}/checks/${checkId}`);
};

export const useBillingPaymentCheck = (clientType, checkId) => {
  return useQuery(['billing-payments.check'], () => getCheck(clientType, checkId), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!checkId,
  });
};

const getNextCheckNumber = (clientType) => {
  const baseUrl = composeBaseUrl(clientType);
  return axios.get(`${baseUrl}/checks/check-number`);
};

export const useBillingPaymentCheckNumber = (clientType, enabled) => {
  return useQuery(['billing-payments.checks.check-number'], () => getNextCheckNumber(clientType), {
    keepPreviousData: false,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    enabled,
  });
};

const getChecks = (clientType, filters) => {
  const baseUrl = composeBaseUrl(clientType);

  return axios.get(`${baseUrl}/checks`, { params: { ...filters } });
};

export const useBillingPaymentChecks = (clientType, filters) => {
  const { page, pageSize, sort_by, order_by } = filters;
  return useQuery(['billing-payments.checks', page, pageSize, sort_by, order_by], () => getChecks(clientType, filters), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

export const useBankBillingPaymentChecks = (clientType, filters) => {
  const { page, pageSize, sort_by, order_by } = filters;
  return useQuery(['billing-payments.checks.bank', page, pageSize, sort_by, order_by], () => getChecks(clientType, filters), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const getBankChecksPreview = ({ clientType, filters }) => {
  const baseUrl = composeBaseUrl(clientType);
  return axios.post(`${baseUrl}/checks/bank/preview`, { ...filters });
};

/**
 * @description Bank Checks Preview
 */
export const useBankChecksPreview = ({ clientType, filters }) => {
  const { page, pageSize, sort_by, order_by } = filters;
  return useQuery(['billing-payments.checks.bank-preview', page, pageSize, sort_by, order_by], () => getBankChecksPreview({ clientType, filters }), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const getBankChecksFile = ({ clientType, filters }) => {
  const baseUrl = composeBaseUrl(clientType);
  return axios.post(`${baseUrl}/checks/bank/download`, { ...filters }, { responseType: 'blob' });
};

/**
 * @description Bank Checks Downloader
 */
export const useBankChecksDownloader = ({ clientType, filters }) => {
  return useMutation(() => getBankChecksFile({ clientType, filters }), {
    onSuccess: (res) => {
      downloadFileFromBlob(res, { name: 'Checks' });
    },
  });
};

const getCheckPdfPreview = (clientType, checkId) => {
  const baseUrl = composeBaseUrl(clientType);

  return axios.post(`${baseUrl}/checks/${checkId}/pdf/preview`);
};

export const useCheckPdfPreview = (clientType, checkId) => {
  return useQuery(['billing-payments.checks.pdf-preview'], () => getCheckPdfPreview(clientType, checkId), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!checkId,
  });
};

const printChecks = async ({ clientType, data }) => {
  const baseUrl = composeBaseUrl(clientType);

  // updates and return blob
  return axios.patch(`${baseUrl}/checks/print`, data, { responseType: 'blob' });
};

export const useBillingPaymentChecksPrinting = ({ clientType, callbacks = {} }) => {
  const queryClient = useQueryClient();

  return useMutation(({ data }) => printChecks({ clientType, data }), {
    onSuccess: (res) => {
      const checkUrl = URL.createObjectURL(res.data);
      window.open(checkUrl, '_blank');
      URL.revokeObjectURL(checkUrl);

      if (callbacks?.success && typeof callbacks?.success === 'function') {
        callbacks.success();
      }

      queryClient.invalidateQueries(['billing-payments.checks']);
      queryClient.invalidateQueries(['billing-payments.items']);
      queryClient.invalidateQueries(['billing-payments.has-printed-checks']);
    },
    onError: () => {
      if (callbacks?.error && typeof callbacks?.error === 'function') {
        callbacks.error();
      }
    },
  });
};

const voidCheck = (clientType, checkId, data) => {
  const baseUrl = composeBaseUrl(clientType);

  return axios.patch(`${baseUrl}/checks/${checkId}/void`, data);
};

export const useBillingPaymentCheckVoiding = ({ clientType, callbacks = {} }) => {
  const queryClient = useQueryClient();
  return useMutation(({ checkId, data }) => voidCheck(clientType, checkId, data), {
    onSuccess: () => {
      if (callbacks?.successCallback && typeof callbacks?.successCallback === 'function') {
        callbacks.successCallback();
      }
      queryClient.invalidateQueries(['billing-payment']);
      queryClient.invalidateQueries(['billing-payments']);
      queryClient.invalidateQueries(['billing-payments.checks']);
      queryClient.invalidateQueries(['billing-payments.items']);
      queryClient.invalidateQueries(['billing-payments.client-unpaid-transactions.all']);
    },
    onError: () => {
      if (callbacks?.errorCallback && typeof callbacks?.errorCallback === 'function') {
        callbacks.errorCallback();
      }
    },
  });
};
