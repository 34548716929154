import { Link } from 'react-router-dom';
import moment from 'moment';
import { Checkbox, IconButton, Tooltip } from '@mui/material';
import { DeleteForeverOutlined } from '@mui/icons-material';
import { formatDateForTable } from '../../../../utils/formatDataForTable';
import { formatAmount } from '../../../../utils/formatAmout';

export const recurringJournalEntryColumns = ({ onDeleteClick, disabled }) => {
  return [
    {
      accessor: 'transaction_name',
      Header: 'Transaction Name',
      Cell: (row) => {
        return <Link to={`/recurring/${row.original?.id}`}>{row.value}</Link>;
      },
    },
    {
      accessor: 'description',
      Header: 'Description',
      Cell: (row) => row.value,
    },
    {
      accessor: 'frequency',
      Header: 'Frequency',
      Cell: (row) => row.value,
    },
    {
      accessor: 'start_date',
      Header: 'Start Date',
      Cell: (row) => formatDateForTable(row.value),
    },
    {
      accessor: 'end_date',
      Header: 'End Date',
      Cell: (row) => formatDateForTable(row.value),
    },
    {
      accessor: 'actions',
      Header: 'Actions',
      maxWidth: 100,
      minWidth: 100,
      Cell: (row) => (
        <>
          <Tooltip title='Delete' placement='top' arrow followCursor>
            <IconButton onClick={(e) => onDeleteClick(e, row)} color='error' disabled={disabled}>
              <DeleteForeverOutlined />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];
};
