import moment from 'moment';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { notificationSuccess } from '../../../utils/toastify';
import { customDownloader } from '../../../utils/downloaderFiles';

const updateFixedAssets = (data) => {
  return axios.patch(`/me/fixed-assets/${data.id}`, { ...data });
};

export const useUpdateFixedAssets = (id, callback) => {
  const queryClient = useQueryClient();
  return useMutation(updateFixedAssets, {
    onSuccess: (data) => {
      notificationSuccess('Successfully updated Fixed Asset!');
      queryClient.invalidateQueries(['fixed-asset', id]);
      callback && callback(data);
    },
  });
};

const getFixedAssetsTableData = (filter) => {
  return axios.get('/me/fixed-assets', { params: filter });
};

export const useGetFixedAssetsTableData = (filter) => {
  return useQuery(['fixed-assets-table', filter.page, filter.pageSize, filter.sort_by, filter.order_by], () => getFixedAssetsTableData(filter), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const getFixedAssetsCodebooks = (types) => {
  return axios.get('/me/fixed-assets/codebooks', { params: { types } });
};

export const useGetFixedAssetsCodebooks = (types) => {
  return useQuery(['fixed-assets-codebooks', types], () => getFixedAssetsCodebooks(types), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const getFixedAsset = (id) => {
  return axios.get(`/me/fixed-assets/${id}`);
};

export const useGetFixedAsset = (id) => {
  return useQuery(['fixed-asset', id], () => getFixedAsset(id), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const getDepreciationItemsTableData = (filters) => {
  return axios.get(`/me/fixed-assets/depreciation-items`, { params: filters });
};

export const useGetDepreciationItemsTableData = (filters) => {
  return useQuery(
    ['depreciation-items', filters.page, filters.pageSize, filters.sort_by, filters.order_by, filters.fixed_asset_depreciation_id],
    () => getDepreciationItemsTableData(filters),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!filters.fixed_asset_depreciation_id,
    }
  );
};

const getDepreciationItemsPreviewTableData = (filters) => {
  return axios.get(`/me/fixed-assets/depreciation-items-preview`, { params: filters });
};

export const useGetDepreciationItemsPreviewTableData = (filters) => {
  return useQuery(
    ['depreciation-items-preview', filters.page, filters.pageSize, filters.sort_by, filters.order_by],
    () => getDepreciationItemsPreviewTableData(filters),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const postDepreciationItemsData = (data) => {
  return axios.post(`/me/fixed-assets/depreciation-items-post`, data);
};

export const usePostDepreciationItemsData = (callback) => {
  const queryClient = useQueryClient();

  return useMutation(postDepreciationItemsData, {
    onSuccess: () => {
      callback && callback();
      queryClient.invalidateQueries(['depreciation-items-preview']);
      notificationSuccess('Successfully Posted Depreciation Items');
    },
  });
};

const getAllItems = () => {
  return axios.get('/me/fixed-assets/depreciation-items-select-all');
};

export const useGetSelectAllItems = (successCb) => {
  return useMutation(getAllItems, {
    onSuccess: (data) => {
      successCb && successCb(data);
      notificationSuccess('Successfully Selected All Depreciation Items');
    },
  });
};

const exportExcel = (filters) => {
  return customDownloader({ url: `/me/fixed-assets/export-excel`, fileType: 'EXCEL', params: filters });
};

export const useExportExcel = () => {
  return useMutation(exportExcel, {});
};

const getDepreciationReport = (filters) => {
  return axios.get(`/me/fixed-assets/depreciation-report`, { params: filters });
};

export const useDepreciationReport = (filters = {}) => {
  return useQuery(['depreciation-report', filters.page, filters.pageSize, filters.sort_by, filters.order_by], () => getDepreciationReport(filters), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const getFixedAssetDocuments = ({ unitNumber, filters }) => {
  return axios.get(`/me/fixed-assets/get-documents/${unitNumber}`, { params: filters });
};

export const useGetFixedAssetDocuments = ({ unitNumber, filters }) => {
  return useQuery(
    ['fixed-asset-documents', filters.page, filters.pageSize, filters.sort_by, filters.order_by],
    () => getFixedAssetDocuments({ unitNumber, filters }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const uploadFileToS3 = (data) => {
  return axios.post(`/me/fixed-assets/upload-attached-file`, data);
};

export const useUploadFileToS3 = (callback) => {
  const queryClient = useQueryClient();

  return useMutation(uploadFileToS3, {
    onSuccess: async () => {
      callback && callback();
      await queryClient.invalidateQueries(['fixed-asset-documents']);
      notificationSuccess('Document Successfully Uploaded');
    },
  });
};

const deleteFileFromS3 = (key) => {
  return axios.post(`/me/fixed-assets/delete-file/${key}`);
};

export const useDeleteFileFromS3 = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteFileFromS3, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['fixed-asset-documents']);
      notificationSuccess('Document Deleted Successfully');
    },
  });
};

const editDocumentInfo = (data) => {
  return axios.patch(`/me/fixed-assets/save-edit-info`, data);
};

export const useEditDocumentInfo = (handleCloseModal) => {
  const queryClient = useQueryClient();

  return useMutation(editDocumentInfo, {
    onSuccess: async () => {
      handleCloseModal();
      await queryClient.invalidateQueries(['fixed-asset-documents']);
      notificationSuccess('Changes Saved');
    },
  });
};
