export default function csv2Json(csv){
    let lines=csv.split("\n");
    let result = [];
    let headers=lines[0].split(",");
     headers.forEach((a,i)=>{
         headers[i]=a.replaceAll(/['"]+/g, '');
    });
       for(let i=1;i<lines.length;i++){
        let obj = {};
        let currentline=lines[i].split(",");
        for(let j=0;j<headers.length;j++){
            obj[headers[j]] = currentline[j];
        }
        result.push(obj);
    }
    return result;
}