import React from 'react';
import { formatDateForTable } from '../../../../../utils/formatDataForTable';
import { formatAmount } from '../../../../../utils/formatAmout';

export const PendingTransactionsDataTableColumns = ({ Actions, SelectRow, callbacks }) => {
  return [
    {
      id: 'selectedTransactions',
      Header: (row) =>
        SelectRow && (
          <div className='d-flex justify-content-center align-items-center'>{React.cloneElement(SelectRow, { row, selectMultiple: true })}</div>
        ),
      Cell: (row) => SelectRow && <div className='d-flex justify-content-center align-items-center'>{React.cloneElement(SelectRow, { row })}</div>,
      sortable: false,
    },
    {
      accessor: (d) => d['client.external_id'],
      id: 'client.external_id',
      Header: <div>Client Number</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['client.rmi_number'],
      id: 'client.rmi_number',
      Header: <div>RMI No.</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['client.name'],
      id: 'client.name',
      Header: <div>Client</div>,
      Cell: (row) => (
        <div style={{ cursor: 'pointer' }} onClick={() => callbacks?.onClientClick({ row })}>
          {row.value}
        </div>
      ),
      sortable: true,
    },
    {
      accessor: (d) => d['pending_transactions.posting_date'],
      id: 'pending_transactions.posting_date',
      Header: <div>Posting Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['document_type.name'],
      id: 'document_type.name',
      Header: <div>Type</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['pending_transactions.document_number'],
      id: 'pending_transactions.document_number',
      Header: <div>Document Number</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: false,
    },

    {
      accessor: (d) => d['pending_transactions.document_date'],
      id: 'pending_transactions.document_date',
      Header: <div>Invoice Date</div>,
      sortable: true,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },

    {
      accessor: (d) => d['pending_transactions.amount'],
      id: 'pending_transactions.amount',
      Header: <div>Amount</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sortable: true,
    },

    {
      accessor: (d) => d['due_date'],
      id: 'due_date',
      Header: <div>Invoice Due Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['pending_transactions.created_at'],
      id: 'pending_transactions.created_at',
      Header: <div>Created On</div>,
      Cell: (row) => <div>{formatDateForTable(row.value, 'MM/DD/YYYY HH:mm:ss')}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['users.name'],
      id: 'users.name',
      Header: <div>Created By</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['transaction_source.name'],
      id: 'transaction_source.name',
      Header: <div>Source</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['pending_transactions_status.name'],
      id: 'pending_transactions_status.name',
      Header: <div>Pending Status</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: true,
    },
    {
      id: 'actions',
      Header: <div>Actions</div>,
      Cell: (row) => {
        if (Actions) {
          return <div>{React.cloneElement(Actions, { row })}</div>;
        } else {
          return <></>;
        }
      },
      sortable: false,
      minWidth: 160,
      maxWidth: 160,
    },
    {
      accessor: (d) => d['pending_transactions.notes'],
      id: 'pending_transactions.notes',
      Header: <div>Notes</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['pending_transactions.transactions_id'],
      id: 'pending_transactions.transactions_id',
      Header: <div>Transaction ID</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['resolver.name'],
      id: 'resolver.name',
      Header: <div>Uploaded By</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['pending_transactions.resolved_at'],
      id: 'pending_transactions.resolved_at',
      Header: <div>Upload Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value, 'MM/DD/YYYY HH:mm')}</div>,
      sortable: true,
    },
  ];
};

export const PendingTransactionHeaders = [];
