import { LayersClearOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { CollapsableCard } from '../../../../../../common/Card/CollapsableCard';
import { CustomDialog } from '../../../../../../common/Dialog/CustomDialog';
import DataGridComponent from '../../../../../../component/DataTable/component/DataTableComponent';
import { columns } from '../../columns/clientColumns';
import { useBillingPayment, useClientsWithUnpaidTransactions } from '../../hooks';
import { BillingPaymentItemForm } from '../items/BillingPaymentItemForm';
import { AllUnpaidTransactions } from './components/AllUnpaidTransactions';
import { Filters } from './components/Filters';

export const BillingPaymentClients = ({}) => {
  const { clientType, billingId } = useParams();

  const blankData = useMemo(() => {
    return { data: [], page: 0, pageSize: 10, pageTotal: 0 };
  }, []);

  const blankFilter = useMemo(() => {
    return {
      'transactions_due_date.due_date': moment().format('YYYY/MM/DD'),
      client_id: null,
      preferred_payment_method: null,
      payment_priority: null,
      start: '',
      end: '',
      page: 0,
      pageSize: 10,
    };
  }, []);

  const [filter, setFilter] = useState(blankFilter);
  const [modalOptions, setModalOptions] = useState({
    itemForm: false,
    unpaidInvoices: false,
  });
  const [selectedClientId, setSelectedClientId] = useState(null);

  const { data: clientData, refetch } = useClientsWithUnpaidTransactions(clientType, filter);

  const { data: { data: billingPayment = {} } = {} } = useBillingPayment(clientType, billingId);

  const disabledActions = useMemo(() => !!billingPayment?.finished);

  const handleChange = (e) => {
    try {
      if (e.target.value.constructor === {}.constructor) {
        setFilter((prev) => ({ ...prev, ...e.target.value }));
      } else {
        setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      }
    } catch (error) {
      setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const handleTableFilterChange = (input) => {
    setFilter((prev) => ({ ...prev, ...input }));
  };

  const handleDateChange = (date, name) => {
    setFilter((prev) => ({
      ...prev,
      [name]: date,
    }));
  };

  const handleButtonGroupChange = (e) => {
    const { name, value } = e.target;
    const newFilters = { ...filter };
    if (!value) {
      newFilters[name] = null;
    } else if (value === 'true') {
      newFilters[name] = true;
    } else if (value === 'false') {
      newFilters[name] = false;
    }

    setFilter((prev) => ({ ...prev, ...newFilters }));
  };

  const handleSelectChange = (val, e) => {
    if (e.action === 'select-option') {
      let o = { target: { name: e.name, value: val.value } };

      handleChange(o);
      return;
    }
    if (e.action === 'clear') {
      let o = { target: { name: e.name, value: null } };
      handleChange(o);
    }
  };

  const handleSearch = () => {
    refetch();
  };

  const handlePaymentButtonClick = (row) => {
    const clientId = row['client.id'];

    if (!clientId) return;

    setSelectedClientId(clientId);
    setModalOptions((prevValue) => ({
      ...prevValue,
      itemForm: true,
    }));
  };

  const handleUnpaidInvoicesButtonClick = () => {
    setModalOptions((prevValue) => ({
      ...prevValue,
      unpaidInvoices: true,
    }));
  };

  const handleModalClose = (name) => {
    setModalOptions((prevValue) => ({
      ...prevValue,
      [name]: false,
    }));
  };

  return (
    <>
      <CustomDialog
        fullWidth
        isOpen={modalOptions.unpaidInvoices}
        actionButtonDisabled
        disableEscapeKeyDown
        disableBackdropClick
        hideActions
        hideTitleButton
        modalSize={'xl'}
        modalTitle={'Unpaid Invoices'}
        onClose={() => handleModalClose('unpaidInvoices')}
      >
        <AllUnpaidTransactions
          initFilters={{
            'transactions_due_date.due_date': filter['transactions_due_date.due_date'],
            client_id: filter.client_id,
            preferred_payment_method: filter.preferred_payment_method,
            payment_priority: filter.payment_priority,
          }}
        />
      </CustomDialog>
      <CustomDialog
        fullWidth
        isOpen={modalOptions.itemForm}
        actionButtonDisabled
        disableEscapeKeyDown
        disableBackdropClick
        hideActions
        hideTitleButton
        modalSize={'xl'}
        modalTitle={'Payment Item'}
        onClose={() => handleModalClose('itemForm')}
      >
        <BillingPaymentItemForm
          transactionDueDate={filter['transactions_due_date.due_date']}
          clientId={selectedClientId}
          closeModal={() => handleModalClose('itemForm')}
          linkToPayment
          billingId={billingId}
          disabled={disabledActions}
          defaultPostingDate={billingPayment.posting_date}
        />
      </CustomDialog>
      <Row>
        <Col>
          <CollapsableCard
            headerTitle={
              <div className='d-flex justify-content-between'>
                <div>Suggested Vendors</div>
                <Tooltip placement='bottom' arrow followCursor title={`All Vendors Invoices`}>
                  <IconButton disabled={disabledActions} className='mr-2 d-flex align-items-center'>
                    <i
                      class='fas fa-file-invoice-dollar'
                      onClick={handleUnpaidInvoicesButtonClick}
                      style={{ width: '20px', height: '20px', color: '#ceac02', fill: 'red', cursor: 'pointer' }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            }
            titleTypographyProps={{ fontSize: '18px' }}
            sxCardContent={{ padding: '16px' }}
            sxCardHeader={{ padding: '16px' }}
            isExpanded={false}
          >
            <Row className='mb-3'>
              <Col>
                <CollapsableCard
                  headerTitle='Filters'
                  isExpanded={false}
                  titleTypographyProps={{ fontSize: '16px' }}
                  sxCardContent={{ padding: '14px' }}
                  sxCardHeader={{ padding: '14px' }}
                >
                  <Filters
                    filters={filter}
                    onSelectChange={handleSelectChange}
                    onDateChange={handleDateChange}
                    onButtonGroupChange={handleButtonGroupChange}
                    onSearch={handleSearch}
                  />
                </CollapsableCard>
              </Col>
            </Row>

            <DataGridComponent
              dataSource={clientData?.data || blankData}
              onFilterChange={handleTableFilterChange}
              columns={columns({
                Actions: <SuggestedVendorsActions onApply={handlePaymentButtonClick} disabled={disabledActions} />,
              })}
            />
          </CollapsableCard>
        </Col>
      </Row>
    </>
  );
};

const SuggestedVendorsActions = ({ row, onApply, disabled }) => {
  return (
    <>
      <Tooltip arrow title='Payment'>
        <span>
          <IconButton onClick={() => onApply(row)} disabled={disabled} color='success'>
            <LayersClearOutlined fontSize='small' />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};
