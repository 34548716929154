import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { CollapsableCard } from '../../../../common/Card/CollapsableCard';
import { VENDOR_TYPE_STRING } from '../../../../constants/clientType.constants';
import { formatAmount } from '../../../../utils/formatAmout';
import { useClient } from '../clientHooks';
import { CustomerForm } from './CustomerForm';
import { VendorForm } from './VendorForm';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IconButton, Tooltip } from '@mui/material';

export const ClientCard = () => {
  let { clientType, clientId } = useParams();

  const { data: { data: clientData = {} } = {}, refetch: refetchClient } = useClient(clientId, clientType);

  const ClientHeader = useMemo(() => {
    if (clientType.toLowerCase() === VENDOR_TYPE_STRING) {
      const handleVendorParentClick = () => {
        if (!clientData?.parent?.id) return;

        window.open(`/client/${clientType}/360/${clientData.parent.id}`, '_blank');
      };

      return (
        <>
          <Row>
            <Col>
              <h5>
                {clientData?.name}
                {clientData?.parent?.is_parent && (
                  <Tooltip title='Parent Vendor' placement='top' followCursor sx={{ mb: 0.5 }}>
                    <IconButton color='primary'>
                      <CheckCircleIcon color='primary' />
                    </IconButton>
                  </Tooltip>
                )}
              </h5>
              {clientData?.parent?.id && (
                <small>
                  Parent:{' '}
                  <strong style={{ cursor: 'pointer' }} onClick={handleVendorParentClick}>
                    {clientData?.parent?.name}
                  </strong>
                </small>
              )}
            </Col>
            <Col style={{ textAlign: 'end' }}>
              <h5>
                Balance Due: <strong>{formatAmount(clientData?.balance || 0)}</strong>
              </h5>
              <h6>Budget Amount: {formatAmount(clientData?.budget || 0)}</h6>
            </Col>
          </Row>
        </>
      );
    } else {
      return (
        <>
          <Row>
            <Col>
              <h5>{clientData?.name}</h5>
              <h6>DBA: {clientData?.dba}</h6>
            </Col>
            <Col style={{ textAlign: 'end' }}>
              <h5>
                Credit Limit: <strong>{formatAmount(clientData?.credit_limit || 0)}</strong>
              </h5>
              <h6>Balance: {formatAmount(clientData?.balance || 0)}</h6>
            </Col>
          </Row>
        </>
      );
    }
  }, [clientType, clientData]);

  const formComponent = useMemo(() => {
    if (clientType.toLowerCase() === 'customers') {
      return <CustomerForm client={clientData} fetchClient={refetchClient} />;
    } else {
      return <VendorForm client={clientData} fetchClient={refetchClient} />;
    }
  }, [clientType, clientData]);

  return (
    <>
      <CollapsableCard isExpanded={false} headerTitle={ClientHeader}>
        {formComponent}
      </CollapsableCard>
    </>
  );
};
