import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const RestrictedRoute = ({ children: Component, functionalities }) => {
  const { user } = useSelector((state) => ({ user: state.auth.user }));

  let hasFunctionality = false;
  for (const func of functionalities) {
    if (user.functionalities[func] && user.functionalities[func].active) {
      hasFunctionality = true;
      break;
    }
  }

  if (functionalities && hasFunctionality) {
    return Component;
  }

  return <Redirect to='/' />;
};
