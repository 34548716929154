import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import { Button as MuiButton, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { useHistory, useParams } from 'react-router-dom';

import { CustomCard } from '../../../common/Card/CustomCard';
import HeaderInfo from './components/HeaderInfo';
import LineInfo from './components/LineInfo';
import { useGetDeposit, usePostDepositMutation, useExportExcel } from './depositApiRoutes';
import { notificationDanger, notificationDangerCustomTime } from '../../../utils/toastify';
import { hasPermissionHook } from '../../../hooks/hasPermission';
import { DEPOSIT, EDIT } from '../../../constants/permissions.constants';

const StyledButton = styled(MuiButton)(() => ({
  '&.Mui-disabled': {
    backgroundColor: '#9b8632',
    cursor: 'pointer',
  },
}));

const StyledExcelButton = styled(MuiButton)(() => ({
  marginRight: '15px',
  backgroundColor: '#2f353a',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#58626a',
  },
}));

const DepositComponent = () => {
  const { hasPermission, isPostingDisabled } = hasPermissionHook();
  const history = useHistory();
  const params = useParams();

  const { data: { data: depositData } = {} } = useGetDeposit({ id: params.depositId });
  const { mutate: postDepositMutation, isLoading } = usePostDepositMutation(params.depositId);
  const { mutate: exportExcelMutation } = useExportExcel();

  const backHandler = () => {
    history.push('/deposit');
  };

  const onPost = () => {
    if (isPostingDisabled([depositData?.posting_date])) {
      notificationDangerCustomTime('You are not allowed to post in this period. Please contact your superior for more info!', 10000);
      return;
    }

    if (+depositData?.total_deposit_amount !== +depositData?.deposit_items_amount) {
      return notificationDanger('Not Allowed!');
    }

    postDepositMutation({ depositId: params.depositId, create_at: new Date() });
  };

  const exportExcel = () => {
    if (!params.depositId) {
      return;
    }

    exportExcelMutation(params.depositId);
  };

  const headerTitle = React.useMemo(() => {
    const isAllowed = +depositData?.total_deposit_amount === +depositData?.deposit_items_amount;
    return (
      <Row>
        <Col className='d-flex align-items-center justify-content-between'>
          <h5 className='m-0'></h5>
          <div>
            <StyledExcelButton onClick={exportExcel}>Export Excel</StyledExcelButton>
            <Tooltip
              title={isAllowed ? '' : 'Not Allowed! Total Deposit Lines must be equal to Total Deposit Amount'}
              placement='top'
              arrow
              followCursor
            >
              <StyledButton onClick={onPost} disabled={isLoading || depositData?.finish || !hasPermission(DEPOSIT, EDIT)}>
                Post Deposit
              </StyledButton>
            </Tooltip>
          </div>
        </Col>
      </Row>
    );
  }, [depositData, isLoading]);

  return (
    <>
      <Button className='btn btn-sm' onClick={backHandler}>
        <i className='fas fa-angle-left mr-2'></i>Go Back To Deposit Table
      </Button>
      <CustomCard headerTitle={headerTitle} sxCardContainer={{ mt: 1, mb: 2 }}>
        <HeaderInfo disabled={depositData?.finish || !hasPermission(DEPOSIT, EDIT)} />
      </CustomCard>
      {params.depositId && <LineInfo isFinished={depositData?.finish} />}
    </>
  );
};

export default DepositComponent;
