import moment from 'moment';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { notificationSuccess } from '../../../utils/toastify';
import { customDownloader } from '../../../utils/downloaderFiles';

const createDeposit = (data) => {
  return axios.post('/me/bank/payment-wizard', data);
};

const updateDeposit = (data) => {
  return axios.patch(`/me/bank/payment-wizard/${data.id}`, data);
};

const getDepositTableData = (filter) => {
  delete filter.document_type;
  return axios.get(`/me/bank/payment-wizard/`, { params: { ...filter } });
};

const getDeposit = (filter) => {
  return axios.get(`/me/bank/payment-wizard/${filter.id}`);
};

const createDepositItem = (data) => {
  return axios.post(`/me/bank/payment-wizard/${data.deposit_id}`, data);
};

const getDepositItemTableData = (filter) => {
  return axios.get(`/me/bank/payment-wizard/${filter.deposit_id}/items`, { params: { ...filter } });
};

const getDepositItemTransactionsTableData = ({ depositId, depositItemId, ...filter }) => {
  return axios.get(`/me/bank/payment-wizard/${depositId}/${depositItemId}/transactions`, { params: { ...filter } });
};

const getDepositItem = ({ depositId, depositItemId }) => {
  return axios.get(`/me/bank/payment-wizard/${depositId}/${depositItemId}`);
};

const clearingTransaction = ({ depositId, depositItemId, ...rest }) => {
  return axios.put(`/me/bank/payment-wizard/${depositId}/${depositItemId}`, rest);
};

const cancelClearingTransaction = ({ depositId, depositItemId, ...rest }) => {
  return axios.put(`/me/bank/payment-wizard/${depositId}/${depositItemId}/cancel-clearing`, rest);
};

const updateDepositItem = ({ depositId, depositItemId, ...rest }) => {
  return axios.patch(`/me/bank/payment-wizard/${depositId}/${depositItemId}`, { ...rest });
};

const importDepositItems = (data) => {
  return axios.post(`/me/bank/payment-wizard/${data.depositId}/upload-new-items`, data.formData);
};

const uploadDepositItemsApplications = (data) => {
  return axios.post(`/me/bank/payment-wizard/${data.depositId}/upload-applications`, data.formData);
};

const postDeposit = (data) => {
  return axios.post(`/me/bank/payment-wizard/post-deposit`, data);
};

const deleteDepositItem = (id) => {
  return axios.delete(`/me/bank/payment-wizard/deposit-item/${id}`);
};

const exportExcel = (depositId) => {
  return customDownloader({ url: `/me/bank/payment-wizard/export-excel/${depositId}`, fileType: 'EXCEL' });
};

const deleteDeposit = (depositId) => {
  return axios.delete(`/me/bank/payment-wizard/${depositId}`);
};

const exampleImportNewItemsExcelDownload = () => {
  return customDownloader({ url: '/me/bank/payment-wizard/example-file-import-new-items', fileType: 'EXCEL' });
};

const exampleUploadApplicationDepositExcelDownload = () => {
  return customDownloader({ url: '/me/bank/payment-wizard/example-file-upload-application-deposit', fileType: 'EXCEL' });
};

export const useExampleImportNewItemsExcelDownload = () => {
  return useMutation(exampleImportNewItemsExcelDownload);
};

export const useExampleUploadApplicationDepositExcelDownload = () => {
  return useMutation(exampleUploadApplicationDepositExcelDownload);
};

export const useGetDepositTableData = (filter) => {
  return useQuery(['deposit-table', filter.page, filter.pageSize, filter.sort_by, filter.order_by], () => getDepositTableData(filter), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

export const useCreateDepositMutation = (callback) => {
  return useMutation(createDeposit, {
    onSuccess: (data) => {
      callback && callback(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useUpdateDepositMutation = (callback, depositId) => {
  const queryClient = useQueryClient();
  return useMutation(updateDeposit, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['deposit-component', depositId]);
      queryClient.invalidateQueries(['deposit-item-table']);
      callback && callback(data);
    },
  });
};

export const useGetDeposit = (filter, callback) => {
  return useQuery(['deposit-component', filter.id], () => getDeposit(filter), {
    refetchOnWindowFocus: false,
    enabled: !!filter.id,
    onSuccess: (data) => {
      callback && callback(data);
    },
  });
};

export const useCreateDepositItemMutation = (tableFilters, depositId, callback) => {
  const queryClient = useQueryClient();
  return useMutation(createDepositItem, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['deposit-component', depositId]);
      queryClient.invalidateQueries(['deposit-item-table', tableFilters.page, tableFilters.pageSize, tableFilters.sort_by, tableFilters.order_by]);
      callback && callback(data);
    },
  });
};

export const useGetDepositItemTableData = (filter) => {
  return useQuery(['deposit-item-table', filter.page, filter.pageSize, filter.sort_by, filter.order_by], () => getDepositItemTableData(filter), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

export const useGetDepositItemTransactionsTableData = (filter) => {
  return useQuery(['deposit-item-transactions-table', filter.sort_by, filter.order_by], () => getDepositItemTransactionsTableData(filter), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

export const useGetDepositItem = (data) => {
  return useQuery(['deposit-item', data], () => getDepositItem(data), {
    refetchOnWindowFocus: false,
  });
};

export const useUpdateDepositItemMutation = (tableFilters, depositId, callback) => {
  const queryClient = useQueryClient();
  return useMutation(updateDepositItem, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['deposit-component', depositId]);
      queryClient.invalidateQueries(['deposit-item-table', tableFilters.page, tableFilters.pageSize, tableFilters.sort_by, tableFilters.order_by]);
      callback && callback();
    },
  });
};

export const useDeleteDepositItemMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteDepositItem, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['deposit-component']);
      queryClient.invalidateQueries(['deposit-item-table']);
    },
  });
};

export const useClearingTransactionMutation = (filter, depItemData, callback) => {
  const queryClient = useQueryClient();
  return useMutation(clearingTransaction, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['deposit-item-transactions-table', filter.sort_by, filter.order_by]);
      queryClient.invalidateQueries(['deposit-item', depItemData]);
      callback && callback(data);
    },
  });
};

export const useCancelClearingTransactionMutation = (filter, depItemData, callback) => {
  const queryClient = useQueryClient();
  return useMutation(cancelClearingTransaction, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['deposit-item-transactions-table', filter.sort_by, filter.order_by]);
      queryClient.invalidateQueries(['deposit-item', depItemData]);
      callback && callback(data);
    },
  });
};

export const useImportDepositItemsMutation = (filter, depositId, errorCb, successCb) => {
  const queryClient = useQueryClient();
  return useMutation(importDepositItems, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['deposit-component', depositId]);
      queryClient.invalidateQueries(['deposit-item-table', filter.page, filter.pageSize, filter.sort_by, filter.order_by]);
      successCb(data.data.msg);
    },
    onError: (error) => {
      if (error.response.status === 422) {
        errorCb(error?.response?.data?.error);
      }
    },
  });
};

export const useUploadApplicationsMutation = (filter, depositId, errorCb, successCb) => {
  const queryClient = useQueryClient();
  return useMutation(uploadDepositItemsApplications, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['deposit-component', depositId]);
      queryClient.invalidateQueries(['deposit-item-table', filter.page, filter.pageSize, filter.sort_by, filter.order_by]);
      successCb(data.data);
    },
    onError: (error) => {
      if (error.response.status === 422) {
        errorCb(error?.response?.data?.error);
      }
    },
  });
};

export const usePostDepositMutation = (depositId) => {
  const filterDepositItems = {
    page: 0,
    pageSize: 10,
    order_by: 'asc',
    sort_by: 'account.number',
  };
  const queryClient = useQueryClient();
  return useMutation(postDeposit, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['deposit-component', depositId]);
      queryClient.invalidateQueries([
        'deposit-item-table',
        filterDepositItems.page,
        filterDepositItems.pageSize,
        filterDepositItems.sort_by,
        filterDepositItems.order_by,
      ]);
      notificationSuccess('Deposit successfully posted!');
    },
  });
};

export const useExportExcel = () => {
  return useMutation(exportExcel, {});
};

export const useDeleteDeposit = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteDeposit, {
    onSuccess: () => {
      notificationSuccess('Successfully deleted deposit');
      queryClient.invalidateQueries(['deposit-table']);
    },
  });
};
