import InfoIcon from '@mui/icons-material/Info';
import { Checkbox, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { formatAmount } from '../../../../utils/formatAmout';
import { formatDateForTable } from '../../../../utils/formatDataForTable';

export const PaymentsTableColumns = ({ checkPaymentTableRow, checkedPayments, report }) => {
  const DocumentNumber = (row) => {
    return (
      <div className='d-flex flex-row w-100'>
        <div className='w-100'> {row.value || ''}</div>
        {row?.original?.void && row?.original?.gl_type === 'checks' && (
          <div className='d-flex align-items-center'>
            <Tooltip title='Void Payment' placement='top'>
              <InfoIcon fontSize='small' color='warning' sx={{ ':hover': { cursor: 'help' }, fontSize: '15px' }} />
            </Tooltip>
          </div>
        )}
      </div>
    );
  };
  const columns = [
    {
      accessor: 'posting_date',
      Header: 'Date',
      Cell: (row) => formatDateForTable(row?.value) || '',
    },
    {
      accessor: 'document_number',
      Header: 'Document #',
      Cell: DocumentNumber,
    },
    {
      accessor: 'client_name',
      Header: 'Payee',
      Cell: (row) => {
        let value = '';
        if (row?.original?.transactions_data) {
          for (const [idx, transaction] of Object.entries(row.original.transactions_data)) {
            if (+idx === 2) {
              value += `/ ${transaction.client_name}...`;
              break;
            } else if (+idx === 0) {
              value += `${transaction.client_name ?? ''} `;
            } else {
              value += `/ ${transaction.client_name} `;
            }
          }
        }
        return value;
      },
      sortable: false,
    },
    {
      accessor: 'amount',
      Header: 'Amount',
      Cell: (row) => {
        let finalAmount = 0;
        if (row?.original?.transactions_data) {
          for (const transaction of row.original.transactions_data) {
            finalAmount += transaction.amount;
          }
        }
        return formatAmount(finalAmount) || '';
      },
      sortable: false,
    },
  ];

  if (!report) {
    columns.unshift({
      id: 'actionBtn',
      sortable: false,
      Header: (props) => <div className='leftHeader'></div>,
      accessor: (row) => {
        const allIds = row?.transactions_data?.map((tr) => +tr.id) || [];
        const isChecked = checkedPayments.find((payment) => allIds.includes(+payment.id));
        return (
          <StyledCheckbox
            checked={!!isChecked}
            onChange={() => checkPaymentTableRow(row, !!isChecked, allIds)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        );
      },
      maxWidth: 50,
    });
  }
  return columns;
};

const StyledCheckbox = styled((props) => {
  return <Checkbox {...props} />;
})(() => {
  return {
    '&.MuiCheckbox-root': {
      padding: '0',
    },
  };
});
