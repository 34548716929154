import {
  AccountBalanceOutlined,
  ArticleOutlined,
  CreditScoreOutlined,
  PaymentOutlined,
  PeopleOutline,
  PersonSearchOutlined,
  ReportOutlined,
  SettingsOutlined,
  TableChartOutlined,
  WarehouseOutlined,
  PivotTableChartOutlined,
  ManageHistoryOutlined,
} from '@mui/icons-material';

export const CHART_OF_ACCOUNTS = 200;
export const JOURNAL_ENTRY = 300;
export const RECURRING_JOURNAL_ENTRY = 350;
export const ACCOUNT_RECEIVABLES = 400;
export const CUSTOMERS = 401;
export const C_TRANSACTIONS = 420;
export const C_INVOICES = 421;
export const C_PAYMENT = 422;
export const C_CREDIT_MEMO = 423;
export const C_DEBIT_MEMO = 424;
export const C_REFUND = 425;
export const C_HISTORY = 426;
export const CUSTOMER_AGING = 402;
// export const CUSTOMER_INVOICE_TRACKER = 403;
export const ACCOUNT_PAYABLE = 500;
export const VENDORS = 501;
export const V_TRANSACTIONS = 520;
export const V_INVOICES = 521;
export const V_PAYMENT = 522;
export const V_CREDIT_MEMO = 523;
export const V_DEBIT_MEMO = 524;
export const V_REFUND = 525;
export const V_HISTORY = 526;
export const PAY_BILLS = 502;
export const VENDOR_AGING = 503;
// export const VENDOR_INVOICE_TRACKER = 504;
export const BANKING = 600;
export const DEPOSIT = 601;
export const BANK_RECONCILIATION = 602;
export const TRANSACTIONS = 700;
export const ALL_TRANSACTIONS = 701;
export const PENDING_TRANSACTIONS = 702;
export const FIXED_ASSETS = 800;
export const FIXED_ASSETS_TABLE = 801;
export const DEPRECIATION_PREVIEW = 802;
export const DEPRECIATION_REPORT = 803;
export const REPORTS = 900;
export const BALANCE_SHEET = 901;
export const PROFIT_AND_LOSS = 902;
export const TRIAL_BALANCE = 903;
export const SETTINGS = 1000;
export const SUPPORT_TABLES = 1001;
export const USERS = 1002;
export const ROLES = 1003;
export const DISABLE_POSTING = 1004;

export const GROUPED_FUNCTIONALITIES = [
  [{ icon: CreditScoreOutlined, func: CHART_OF_ACCOUNTS }],
  [{ icon: TableChartOutlined, func: JOURNAL_ENTRY }],
  [{ icon: PivotTableChartOutlined, func: RECURRING_JOURNAL_ENTRY }],
  [
    { icon: PeopleOutline, func: ACCOUNT_RECEIVABLES },
    [
      { icon: PeopleOutline, func: CUSTOMERS },
      [
        { icon: PeopleOutline, func: C_TRANSACTIONS, parent: CUSTOMERS },
        { icon: PeopleOutline, func: C_INVOICES, parent: CUSTOMERS },
        { icon: PeopleOutline, func: C_CREDIT_MEMO, parent: CUSTOMERS },
        { icon: PeopleOutline, func: C_DEBIT_MEMO, parent: CUSTOMERS },
        { icon: PeopleOutline, func: C_PAYMENT, parent: CUSTOMERS },
        { icon: PeopleOutline, func: C_REFUND, parent: CUSTOMERS },
        { icon: ManageHistoryOutlined, func: C_HISTORY, parent: CUSTOMERS },
      ],
      { icon: PeopleOutline, func: CUSTOMER_AGING },
      // { icon: PeopleOutline, func: CUSTOMER_INVOICE_TRACKER },
    ],
  ],
  [
    { icon: PersonSearchOutlined, func: ACCOUNT_PAYABLE },
    [
      { icon: PersonSearchOutlined, func: VENDORS },
      [
        { icon: PersonSearchOutlined, func: V_TRANSACTIONS, parent: VENDORS },
        { icon: PersonSearchOutlined, func: V_INVOICES, parent: VENDORS },
        { icon: PersonSearchOutlined, func: V_CREDIT_MEMO, parent: VENDORS },
        { icon: PersonSearchOutlined, func: V_DEBIT_MEMO, parent: VENDORS },
        { icon: PersonSearchOutlined, func: V_PAYMENT, parent: VENDORS },
        { icon: PersonSearchOutlined, func: V_REFUND, parent: VENDORS },
        { icon: ManageHistoryOutlined, func: V_HISTORY, parent: VENDORS },
      ],
      { icon: PersonSearchOutlined, func: VENDOR_AGING },
      { icon: PaymentOutlined, func: PAY_BILLS },
      // { icon: PersonSearchOutlined, func: VENDOR_INVOICE_TRACKER },
    ],
  ],
  [
    { icon: AccountBalanceOutlined, func: BANKING },
    [
      { icon: AccountBalanceOutlined, func: DEPOSIT },
      { icon: AccountBalanceOutlined, func: BANK_RECONCILIATION },
    ],
  ],
  [
    { icon: ArticleOutlined, func: TRANSACTIONS },
    [
      { icon: ArticleOutlined, func: ALL_TRANSACTIONS },
      { icon: ArticleOutlined, func: PENDING_TRANSACTIONS },
    ],
  ],
  [
    { icon: WarehouseOutlined, func: FIXED_ASSETS },
    [
      { icon: WarehouseOutlined, func: FIXED_ASSETS_TABLE },
      { icon: WarehouseOutlined, func: DEPRECIATION_PREVIEW },
      { icon: WarehouseOutlined, func: DEPRECIATION_REPORT },
    ],
  ],
  [
    { icon: ReportOutlined, func: REPORTS },
    [
      { icon: ReportOutlined, func: BALANCE_SHEET },
      { icon: ReportOutlined, func: PROFIT_AND_LOSS },
      { icon: ReportOutlined, func: TRIAL_BALANCE },
    ],
  ],
  [
    { icon: SettingsOutlined, func: SETTINGS },
    [
      { icon: SettingsOutlined, func: SUPPORT_TABLES },
      { icon: SettingsOutlined, func: USERS },
      { icon: SettingsOutlined, func: ROLES },
      { icon: SettingsOutlined, func: DISABLE_POSTING },
    ],
  ],
];

export const FUNCTIONALITIES = [
  CHART_OF_ACCOUNTS,
  JOURNAL_ENTRY,
  RECURRING_JOURNAL_ENTRY,
  ACCOUNT_RECEIVABLES,
  CUSTOMERS,
  C_TRANSACTIONS,
  C_INVOICES,
  C_PAYMENT,
  C_CREDIT_MEMO,
  C_DEBIT_MEMO,
  C_REFUND,
  C_HISTORY,
  CUSTOMER_AGING,
  // CUSTOMER_INVOICE_TRACKER,
  VENDORS,
  V_TRANSACTIONS,
  V_INVOICES,
  V_PAYMENT,
  V_CREDIT_MEMO,
  V_DEBIT_MEMO,
  V_REFUND,
  V_HISTORY,
  VENDOR_AGING,
  PAY_BILLS,
  // VENDOR_INVOICE_TRACKER,
  BANKING,
  DEPOSIT,
  BANK_RECONCILIATION,
  TRANSACTIONS,
  ALL_TRANSACTIONS,
  PENDING_TRANSACTIONS,
  FIXED_ASSETS,
  FIXED_ASSETS_TABLE,
  DEPRECIATION_PREVIEW,
  DEPRECIATION_REPORT,
  REPORTS,
  BALANCE_SHEET,
  PROFIT_AND_LOSS,
  TRIAL_BALANCE,
  SETTINGS,
  SUPPORT_TABLES,
  USERS,
  ROLES,
  DISABLE_POSTING,
];

export const FUNCTIONALITIES_FOR_SELECT = [
  { value: CHART_OF_ACCOUNTS, label: 'Chart of Accounts' },
  { value: JOURNAL_ENTRY, label: 'Journal Entry' },
  { value: RECURRING_JOURNAL_ENTRY, label: 'Recurring Journal Entry' },
  { value: ACCOUNT_RECEIVABLES, label: 'Account Receivables' },
  { value: CUSTOMERS, label: 'Customers' },
  { value: C_TRANSACTIONS, label: 'Customer Transactions' },
  { value: C_INVOICES, label: 'Customer Invoices' },
  { value: C_PAYMENT, label: 'Customer Payment' },
  { value: C_CREDIT_MEMO, label: 'Customer Credit Memo' },
  { value: C_DEBIT_MEMO, label: 'Customer Debit Memo' },
  { value: C_REFUND, label: 'Customer Refund' },
  { value: C_HISTORY, label: 'Customer History' },
  { value: CUSTOMER_AGING, label: 'Customer Aging' },
  // { value: CUSTOMER_INVOICE_TRACKER, label: 'Customer Invoice Tracker' },
  { value: VENDORS, label: 'Vendors' },
  { value: V_TRANSACTIONS, label: 'Vendor Transactions' },
  { value: V_INVOICES, label: 'Vendor Invoices' },
  { value: V_PAYMENT, label: 'Vendor Payment' },
  { value: V_CREDIT_MEMO, label: 'Vendor Credit Memo' },
  { value: V_DEBIT_MEMO, label: 'Vendor Debit Memo' },
  { value: V_REFUND, label: 'Vendor Refund' },
  { value: V_HISTORY, label: 'Vendor History' },
  { value: VENDOR_AGING, label: 'Vendor Transactions' },
  { value: PAY_BILLS, label: 'Pay Bills' },
  // { value: VENDOR_INVOICE_TRACKER, label: 'Vendor Invoice Tracker' },
  { value: BANKING, label: 'Banking' },
  { value: DEPOSIT, label: 'Deposit' },
  { value: BANK_RECONCILIATION, label: 'Bank Reconciliation' },
  { value: TRANSACTIONS, label: 'Transactions' },
  { value: ALL_TRANSACTIONS, label: 'All Transactions' },
  { value: PENDING_TRANSACTIONS, label: 'Pending Transactions' },
  { value: FIXED_ASSETS, label: 'Fixed Assets' },
  { value: FIXED_ASSETS_TABLE, label: 'Fixed Assets All' },
  { value: DEPRECIATION_PREVIEW, label: 'Depreciation Preview' },
  { value: DEPRECIATION_REPORT, label: 'Depreciation Report' },
  { value: REPORTS, label: 'Reports' },
  { value: BALANCE_SHEET, label: 'Balance Sheet' },
  { value: PROFIT_AND_LOSS, label: 'Profit & Loss' },
  { value: TRIAL_BALANCE, label: 'Trial Balance' },
  { value: SETTINGS, label: 'Settings' },
  { value: SUPPORT_TABLES, label: 'Support Tables' },
  { value: USERS, label: 'Users' },
  { value: ROLES, label: 'Roles' },
  { value: DISABLE_POSTING, label: 'Disable Posting' },
];

const functionalityMapper = new Map();
functionalityMapper.set(CHART_OF_ACCOUNTS, 'Chart of Accounts');
functionalityMapper.set(JOURNAL_ENTRY, 'Journal Entry');
functionalityMapper.set(RECURRING_JOURNAL_ENTRY, 'Recurring Journal Entry');
functionalityMapper.set(ACCOUNT_RECEIVABLES, 'Account Receivables');
functionalityMapper.set(CUSTOMERS, 'Customers');
functionalityMapper.set(C_TRANSACTIONS, 'Customer Transactions');
functionalityMapper.set(C_INVOICES, 'Customer Invoices');
functionalityMapper.set(C_PAYMENT, 'Customer Payment');
functionalityMapper.set(C_CREDIT_MEMO, 'Customer Credit Memo');
functionalityMapper.set(C_DEBIT_MEMO, 'Customer Debit Memo');
functionalityMapper.set(C_REFUND, 'Customer Refund');
functionalityMapper.set(C_HISTORY, 'Customer History');
functionalityMapper.set(CUSTOMER_AGING, 'Customer Aging');
// functionalityMapper.set(CUSTOMER_INVOICE_TRACKER, 'Customer Invoice Tracker');
functionalityMapper.set(ACCOUNT_PAYABLE, 'Account Payable');
functionalityMapper.set(VENDORS, 'Vendors');
functionalityMapper.set(V_TRANSACTIONS, 'Vendor Transactions');
functionalityMapper.set(V_INVOICES, 'Vendor Invoices');
functionalityMapper.set(V_PAYMENT, 'Vendor Payment');
functionalityMapper.set(V_CREDIT_MEMO, 'Vendor Credit Memo');
functionalityMapper.set(V_DEBIT_MEMO, 'Vendor Debit Memo');
functionalityMapper.set(V_REFUND, 'Vendor Refund');
functionalityMapper.set(V_HISTORY, 'Vendor History');
functionalityMapper.set(VENDOR_AGING, 'Vendor Transactions');
functionalityMapper.set(PAY_BILLS, 'Pay Bills');
// functionalityMapper.set(VENDOR_INVOICE_TRACKER, 'Vendor Invoice Tracker');
functionalityMapper.set(BANKING, 'Banking');
functionalityMapper.set(DEPOSIT, 'Deposit');
functionalityMapper.set(BANK_RECONCILIATION, 'Bank Reconciliation');
functionalityMapper.set(TRANSACTIONS, 'Transactions');
functionalityMapper.set(ALL_TRANSACTIONS, 'All Transactions');
functionalityMapper.set(PENDING_TRANSACTIONS, 'Pending Transactions');
functionalityMapper.set(FIXED_ASSETS, 'Fixed Assets');
functionalityMapper.set(FIXED_ASSETS_TABLE, 'Fixed Assets All');
functionalityMapper.set(DEPRECIATION_PREVIEW, 'Depreciation Preview');
functionalityMapper.set(DEPRECIATION_REPORT, 'Depreciation Report');
functionalityMapper.set(REPORTS, 'Reports');
functionalityMapper.set(BALANCE_SHEET, 'Balance Sheet');
functionalityMapper.set(PROFIT_AND_LOSS, 'Profit & Loss');
functionalityMapper.set(TRIAL_BALANCE, 'Trial Balance');
functionalityMapper.set(SETTINGS, 'Settings');
functionalityMapper.set(SUPPORT_TABLES, 'Support Tables');
functionalityMapper.set(USERS, 'Users');
functionalityMapper.set(ROLES, 'Roles');
functionalityMapper.set(DISABLE_POSTING, 'Disable Posting');

export const getFunctionality = (id) => {
  return functionalityMapper.get(id);
};

//PERMISSIONS
export const EDIT = 'edit';
export const VIEW = 'view';

export const PERMISSIONS = [EDIT, VIEW];

export const PERMISSIONS_FOR_SELECT = [
  { value: EDIT, label: 'Edit' },
  { value: VIEW, label: 'View Only' },
];

const permissionMapper = new Map();
permissionMapper.set(EDIT, 'Edit');
permissionMapper.set(VIEW, 'View Only');

export const getPermission = (id) => {
  return permissionMapper.get(id);
};
