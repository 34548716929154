import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import ClientBoxTransactionsFilters from './ClientBoxTransactionsFilters';
import TransactionsDataTable from '../../../../component/DataTable/TransactionsDataTable';
import { formatDateForTable } from '../../../../utils/formatDataForTable';
import { formatAmount } from '../../../../utils/formatAmout';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { downloaderFiles } from '../../../../utils/downloaderFiles';

const transactionsStringToId = (string) => {
  if (string === 'INVOICE') return 1;
  if (string === 'PAYMENT') return 2;
  if (string === 'CREDIT-MEMO') return 3;
  if (string === 'DEBIT-MEMO') return 4;
  if (string === 'REFUND') return 5;
  return null;
};

export default function ClientBoxTransactionsComponent() {
  const { clientType, transactionsType } = useParams();
  const blankFilter = useMemo(() => {
    return { 'transactions.document_type_domain_id': '', 'client_transactions.client_id': '', start: '', end: '', page: 0, pageSize: 10 };
  }, []);
  const blankData = useMemo(() => {
    return { data: [], page: 0, pageSize: 25, pageTotal: 0 };
  }, []);
  const [filter, setFilter] = useState(blankFilter);
  const [tableData, setTableData] = useState(blankData);
  const [loader, setLoader] = useState(false);
  const url = useMemo(() => '/me/client/' + clientType.toLowerCase().slice(0, -1) + '/transactions', [clientType]);

  useEffect(() => {
    const loadData = () => {
      setLoader(true);
      axios
        .get(url, { params: { ...filter } })
        .then((res) => {
          if (res.status === 200) {
            setTableData(res.data);
          }
          setLoader(false);
        })
        .catch((e) => {
          setTableData(blankData);
          setLoader(false);
        });
    };
    loadData();
  }, [filter, url, blankData]);
  useEffect(() => {
    const setDomain = () => {
      const transactionTypeId = transactionsType ? transactionsStringToId(transactionsType) : '';
      const f = { ...blankFilter, 'transactions.document_type_journal_entry_id': transactionTypeId };
      setFilter(f);
    };
    setDomain();
  }, [transactionsType, blankFilter]);

  const onFilterHandler = (e) => {
    try {
      if (e.target.value.constructor === {}.constructor) {
        const f = { ...filter, ...e.target.value };
        setFilter(f);
      } else {
        const f = { ...filter, [e.target.name]: e.target.value };
        setFilter(f);
      }
    } catch (error) {
      const f = { ...filter, [e.target.name]: e.target.value };
      setFilter(f);
    }
  };
  const onFilterChange = (input) => {
    const f = { ...filter, ...input };
    setFilter(f);
  };

  const onFilterActionBtn = (e) => {
    if (e.toString() === 'PDF' || e.toString() === 'EXCEL') {
      downloaderFiles({ filter, type: e, url, cbLoad: setLoader, name: 'Client Transactions' });
    }
    if (e.toString() === 'reset') setFilter(blankFilter);
  };

  return (
    <Fragment>
      <div className='container-fluid '>
        <Row>
          <Col md={12}>
            <Card className='mt-3'>
              <CardBody className='shadow'>
                <ClientBoxTransactionsFilters
                  loader={loader}
                  clientType={clientType}
                  filter={filter}
                  blankFilter={blankFilter}
                  onFilterActionClick={onFilterActionBtn}
                  onFilterChangeHandler={onFilterHandler}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md={12}>
            <Card className='card-info'>
              <CardHeader>
                <CardTitle>
                  {''} Transactions For {''}
                </CardTitle>
              </CardHeader>
              <CardBody className='m-0 p-0'>
                <TransactionsDataTable loader={loader} dataSource={tableData} onFilterChange={onFilterChange} columns={ClientColumns} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}
export const ClientColumns = [
  {
    accessor: (d) => d['document_type.name'],

    Header: <div className='leftHeader'>Type</div>,
    id: 'transaction_document_type_id',
    Cell: (row) => <div style={{ textAlign: 'left' }}>{row.value}</div>,
    sort: true,
  },
  {
    accessor: (d) => d['client.name'],
    id: 'client.name',
    Header: (props) => <div className='leftHeader'>Client</div>,
    Cell: (row) => <div style={{ textAlign: 'left' }}>{row.value}</div>,
    sort: true,
    // show: clientShow
  },
  {
    id: 'transactions.document_number',
    accessor: (d) => d['transactions.document_number'],
    Header: (props) => <div className='leftHeader'>Document Number</div>,
    Cell: (row) => <div style={{ textAlign: 'right' }}>{row.value}</div>,
    sort: false,
  },

  {
    accessor: (d) => d['transactions.document_date'],
    id: 'transactions.document_date',
    Header: (props) => <div className='leftHeader'>Date</div>,
    sort: true,
    Cell: (row) => <div style={{ textAlign: 'right' }}>{formatDateForTable(row.value)}</div>,
  },

  {
    accessor: (d) => d['transactions.amount'],
    id: 'transactions.amount',
    Header: (props) => <div className='leftHeader'>Amount</div>,
    Cell: (row) => <div style={{ textAlign: 'right' }}>{formatAmount(row.value)}</div>,
    sort: true,
  },
  {
    accessor: (d) => d['transactions_balances.balance'],
    id: 'transactions_balances.balance',
    Header: (props) => <div className='leftHeader'>Balance</div>,
    Cell: (row) => <div style={{ textAlign: 'right' }}>{formatAmount(row.value)}</div>,
    sort: true,
  },
  {
    accessor: (d) => d['transactions.memo'],
    id: 'transactions_balances.memo',
    Header: (props) => <div className='leftHeader'>Description</div>,
    Cell: (row) => <div style={{ textAlign: 'right' }}>{row.value}</div>,
    sort: true,
  },
];
