import React from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Button, FormControlLabel, Checkbox } from '@mui/material';

import { DatePickerComponent } from '../../../../common/DatePicker/DatePicker';
import { CustomInput } from '../../../../common/CustomInput';
import { CustomCurrencyInput } from '../../../../common/Inputs/CurrencyInput';
import { CustomSelect } from '../../../../common/CustomSelect';
import { AsyncSelect } from '../../../../common/Inputs/AsyncSelect';
import { CustomPageableAsyncSelect } from '../../../../common/Inputs/CustomPageableAsyncSelect';
import { CustomModal } from '../../../../common/Dialog/CustomModal';
import { useAsyncPageable } from '../../../../hooks/useAsyncPageable';
import { accountTypeOptions, ACC_TYPE_CUSTOMER, ACC_TYPE_GL_ACCOUNT, ACC_TYPE_VENDOR } from '../../../../constants/accountType.constants';
import { useGetSupportTablesForSelect } from '../../../../hooks/commonApiHooks';
import { useCreateDepositItemMutation, useUpdateDepositItemMutation, useGetDeposit } from '../depositApiRoutes';
import { DEPOSIT_CATEGORIES_TABLE, PAYMENT_SOURCE, TRANSACTION_TYPES, BRANCH_TABLE } from '../../../../constants/supportTables.constants';
import { Loader } from '../../../../common/Loader';
import { notificationDangerCustomTime } from '../../../../utils/toastify';

const blankData = {
  account_type: null,
  account_number: null,
  external_id: null,
  deposit_category: null,
  transaction_type: null,
  payment_type: null,
  document_number: '',
  amount: 0,
  claim_dep: false,
  down_payment_dep: false,
  fixed_asset_dep: false,
  legal_dep: false,
  security_dep: false,
  branch_id: null,
  description: null,
};

const AddDepositItem = ({ tableFilters, depositItemData, toggleCreateUpdateModal, isModalOpen, disabled }) => {
  const { fetchOptions } = useAsyncPageable();
  const params = useParams();
  const [data, setData] = React.useState(blankData);
  const [inputClicked, setInputClicked] = React.useState(false);

  const createUpdateDepositSuccessCallback = () => {
    toggleCreateUpdateModal();
    setData(blankData);
  };

  const { data: { data: depositData = {} } = {} } = useGetDeposit({ id: params.depositId });
  const { data: { data: supportTableOptions = {} } = {} } = useGetSupportTablesForSelect({
    table_names: [DEPOSIT_CATEGORIES_TABLE, TRANSACTION_TYPES, PAYMENT_SOURCE, BRANCH_TABLE],
  });
  const {
    mutate: createDepositItemMutation,
    error: errorCreate,
    reset: resetCreate,
    isLoading: isLoadingCreate,
  } = useCreateDepositItemMutation(tableFilters, params.depositId, createUpdateDepositSuccessCallback);
  const {
    mutate: updateDepositItemMutation,
    error: errorUpdate,
    reset: resetUpdate,
    isLoading: isLoadingUpdate,
  } = useUpdateDepositItemMutation(tableFilters, params.depositId, createUpdateDepositSuccessCallback);

  React.useEffect(() => {
    let realData;
    if (Object.keys(depositItemData).length) {
      realData = depositItemData;
    } else if (Object.keys(depositData).length && !Object.keys(depositItemData).length) {
      realData = blankData;
      realData.payment_type = depositData.payment_type_id;
    } else {
      realData = blankData;
    }
    setData(realData);
  }, [depositItemData, depositData]);

  const calculatedDifference = React.useMemo(() => {
    let startingDifference = 0;
    if (Object.keys(depositItemData).length) {
      startingDifference = +depositData?.total_deposit_amount - depositData?.deposit_items_amount + +depositItemData?.amount;
    } else {
      startingDifference = +depositData?.total_deposit_amount - +depositData?.deposit_items_amount;
    }

    if (data.amount) {
      return startingDifference - data.amount;
    } else {
      return startingDifference || 0;
    }
  }, [data.amount, depositData]);

  const calculatedTotalDepositLines = React.useMemo(() => {
    let startingTotalDepositLines = +depositData?.deposit_items_amount;
    if (Object.keys(depositItemData).length) {
      startingTotalDepositLines = +depositData?.deposit_items_amount - +depositItemData?.amount;
    }

    if (data.amount) {
      return startingTotalDepositLines + +data.amount;
    } else {
      return startingTotalDepositLines || 0;
    }
  }, [data.amount, depositData]);

  React.useEffect(() => {
    resetCreate();
    resetUpdate();
  }, [depositItemData]);

  const handleChangeSelect = (val, e) => {
    if (e.name === 'account_number') {
      setData((oldData) => ({
        ...oldData,
        [e.name]: val ? val.value : null,
        account_name: val ? val.client_name : null,
        description: val ? val.client_name : null,
      }));
    } else if (e.name === 'account_type') {
      setData((oldData) => ({
        ...oldData,
        [e.name]: val ? val.value : null,
        transaction_type: val && val.value === 1 ? 1 : val && val.value === 2 ? 2 : null,
      }));
    } else {
      setData((oldData) => ({ ...oldData, [e.name]: val ? val.value : null }));
    }
  };

  const handleDateChange = (date, name) => {
    setData((oldData) => ({ ...oldData, [name]: date }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((oldData) => ({ ...oldData, [name]: value }));
  };

  const handleCurrencyInputChange = (value, name) => {
    setData((oldData) => ({ ...oldData, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setData((oldData) => ({ ...oldData, [name]: checked }));
  };

  const VendorOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/client/vendor/options-number-for-select', { q: inputValue, ...options, client_type_id: 2 });
    return data;
  };
  const CustomerOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/client/customer/options-number-for-select', { q: inputValue, ...options, client_type_id: 1 });
    return data;
  };
  const GlAccountOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/account/options/accounts', { q: inputValue, ...options });
    return data;
  };

  const onSave = () => {
    if (
      data.account_type === ACC_TYPE_GL_ACCOUNT &&
      data.account_number &&
      ['4', '5', '6', '7', '8', '9'].includes((data.account_number + '')[0]) &&
      !data.branch_id
    ) {
      notificationDangerCustomTime(`Branch is required for ${data.account_number} Account Number`);
      return;
    }
    if (Object.keys(depositItemData).length) {
      updateDepositItemMutation({
        ...data,
        document_date: depositData.document_date,
        depositId: params.depositId,
        depositItemId: depositItemData.depositItemId,
      });
    } else {
      createDepositItemMutation({ ...data, document_date: depositData.document_date, deposit_id: params.depositId });
    }
  };

  const accountNumberRender = React.useMemo(() => {
    if (!Object.keys(depositItemData).length) {
      return (
        <>
          {' '}
          {data.account_type === ACC_TYPE_CUSTOMER && (
            <CustomPageableAsyncSelect
              label='Account Number'
              defaultValue={data.account_number}
              isClearable
              onChange={handleChangeSelect}
              loadOptions={CustomerOptions}
              name='account_number'
              errorMess={errorCreate?.response?.data?.error?.account_number || errorUpdate?.response?.data?.error?.account_number}
            />
          )}
          {data.account_type === ACC_TYPE_VENDOR && (
            <CustomPageableAsyncSelect
              label='Account Number'
              defaultValue={data.account_number}
              isClearable
              onChange={handleChangeSelect}
              loadOptions={VendorOptions}
              name='account_number'
              errorMess={errorCreate?.response?.data?.error?.account_number || errorUpdate?.response?.data?.error?.account_number}
            />
          )}
          {data.account_type === ACC_TYPE_GL_ACCOUNT && (
            <CustomPageableAsyncSelect
              label='Account Number'
              defaultValue={data.account_number}
              isClearable
              onChange={handleChangeSelect}
              loadOptions={GlAccountOptions}
              name='account_number'
              errorMess={errorCreate?.response?.data?.error?.account_number || errorUpdate?.response?.data?.error?.account_number}
            />
          )}
          {!data.account_type && (
            <CustomPageableAsyncSelect
              label='Account Number'
              defaultValue={data.account_number}
              isClearable
              onChange={handleChangeSelect}
              loadOptions={() => []}
              name='account_number'
              errorMess={errorCreate?.response?.data?.error?.account_number || errorUpdate?.response?.data?.error?.account_number}
            />
          )}
        </>
      );
    }

    if (data.account_type === ACC_TYPE_CUSTOMER && inputClicked) {
      return (
        <CustomPageableAsyncSelect
          label='Account Number'
          defaultValue={data.account_number}
          isClearable
          onChange={handleChangeSelect}
          loadOptions={CustomerOptions}
          name='account_number'
          onFocusOut={() => setInputClicked(false)}
          errorMess={errorCreate?.response?.data?.error?.account_number || errorUpdate?.response?.data?.error?.account_number}
        />
      );
    }

    if (data.account_type === ACC_TYPE_VENDOR && inputClicked) {
      return (
        <CustomPageableAsyncSelect
          label='Account Number'
          defaultValue={data.account_number}
          isClearable
          onChange={handleChangeSelect}
          loadOptions={VendorOptions}
          name='account_number'
          onFocusOut={() => setInputClicked(false)}
          errorMess={errorCreate?.response?.data?.error?.account_number || errorUpdate?.response?.data?.error?.account_number}
        />
      );
    }

    if (data.account_type === ACC_TYPE_GL_ACCOUNT && inputClicked) {
      return (
        <CustomPageableAsyncSelect
          label='Account Number'
          defaultValue={data.account_number}
          isClearable
          onChange={handleChangeSelect}
          loadOptions={GlAccountOptions}
          name='account_number'
          onFocusOut={() => setInputClicked(false)}
          errorMess={errorCreate?.response?.data?.error?.account_number || errorUpdate?.response?.data?.error?.account_number}
        />
      );
    }

    return (
      <CustomInput
        label='Account Number'
        value={(data?.external_id ? data.external_id : data.account_number) + ' - ' + data?.account_name}
        isClearable
        onClick={() => setInputClicked(true)}
        onChange={() => {}}
        name='account_number'
        errorMess={errorCreate?.response?.data?.error?.account_number || errorUpdate?.response?.data?.error?.account_number}
      />
    );
  }, [data, depositItemData, inputClicked]);

  return (
    <>
      <Button onClick={toggleCreateUpdateModal} color='success' sx={{ ml: 1 }} disabled={disabled}>
        Add New Item
      </Button>
      <CustomModal
        modalTitle='Add New Deposit Item'
        modalSize='lg'
        buttonTitle='Add New Item'
        buttonClassName='btn-success'
        saveButtonTitle='Save Item'
        onSave={onSave}
        toggleCustomModal={toggleCreateUpdateModal}
        isOpen={isModalOpen}
        addStyles={{ PaperStyle: { overflowY: 'visible' } }}
      >
        <Row>
          {(isLoadingUpdate || isLoadingCreate) && <Loader isFullScreen />}
          <Col md='9'>
            <Row>
              <Col md='4'>
                <CustomSelect
                  name='account_type'
                  defaultValue={data.account_type}
                  label='Account Type'
                  onChange={handleChangeSelect}
                  options={accountTypeOptions}
                  isClearable
                  errorMess={errorCreate?.response?.data?.error?.account_type || errorUpdate?.response?.data?.error?.account_type}
                />
              </Col>
              <Col md='8'>{accountNumberRender}</Col>
            </Row>
            <Row>
              <Col md='4'>
                <CustomSelect
                  name='deposit_category'
                  defaultValue={data.deposit_category}
                  label='Deposit Category'
                  onChange={handleChangeSelect}
                  options={supportTableOptions[DEPOSIT_CATEGORIES_TABLE] || []}
                  isClearable
                  errorMess={errorCreate?.response?.data?.error?.deposit_category || errorUpdate?.response?.data?.error?.deposit_category}
                />
              </Col>
              <Col md='4'>
                <DatePickerComponent
                  showYearDropdown
                  name='document_date'
                  selected={depositData?.document_date}
                  onChange={(date) => handleDateChange(date, 'document_date')}
                  placeholder='mm/dd/yyyy'
                  dateFormat={'MM/dd/yyyy'}
                  label='Document Date'
                  disabled
                  errorMess={errorCreate?.response?.data?.error?.document_date || errorUpdate?.response?.data?.error?.document_date}
                />
              </Col>
              <Col md='4'>
                <CustomSelect
                  name='transaction_type'
                  defaultValue={data.transaction_type}
                  label='Transaction Type'
                  onChange={handleChangeSelect}
                  options={supportTableOptions[TRANSACTION_TYPES] || []}
                  isClearable
                  errorMess={errorCreate?.response?.data?.error?.transaction_type || errorUpdate?.response?.data?.error?.transaction_type}
                  disabled={[1, 2].includes(data.account_type)}
                />
              </Col>
            </Row>
            <Row>
              <Col md='4'>
                <CustomInput
                  name='document_number'
                  value={data.document_number}
                  onChange={handleInputChange}
                  placeholder='Document Number'
                  label='Document Number'
                  errorMess={errorCreate?.response?.data?.error?.document_number || errorUpdate?.response?.data?.error?.document_number}
                />
              </Col>
              <Col md='4'>
                <CustomCurrencyInput
                  name='amount'
                  value={data.amount}
                  onChange={handleCurrencyInputChange}
                  allowNegativeValue={false}
                  prefix={'$ '}
                  label='Payment Amount'
                  placeholder='Payment Amount'
                  errorMess={errorCreate?.response?.data?.error?.amount || errorUpdate?.response?.data?.error?.amount}
                />
              </Col>
              <Col md='4'>
                <CustomSelect
                  name='payment_type'
                  defaultValue={data.payment_type}
                  label='Payment Type'
                  onChange={handleChangeSelect}
                  disabled
                  options={supportTableOptions[PAYMENT_SOURCE]}
                  isClearable
                  errorMess={errorCreate?.response?.data?.error?.payment_type || errorUpdate?.response?.data?.error?.payment_type}
                />
              </Col>
            </Row>
            <Row>
              {data.account_type === ACC_TYPE_GL_ACCOUNT && (
                <Col md='4'>
                  <CustomSelect
                    name='branch_id'
                    defaultValue={data.branch_id}
                    label='Branch'
                    onChange={handleChangeSelect}
                    options={supportTableOptions[BRANCH_TABLE]}
                    isClearable
                  />
                </Col>
              )}
              <Col md='8'>
                <CustomInput
                  name='description'
                  value={data.description}
                  onChange={handleInputChange}
                  placeholder='Description'
                  label='Description'
                  rows={2}
                  type='textarea'
                />
              </Col>
            </Row>
            <Row>
              <Col md='4'>
                <CustomCurrencyInput
                  name='amount'
                  value={+depositData?.total_deposit_amount?.toFixed(2)}
                  onChange={() => {}}
                  prefix={'$ '}
                  label='Total Deposit Amount'
                  placeholder='Total Deposit Amount'
                  disabled
                />
              </Col>
              <Col md='4'>
                <CustomCurrencyInput
                  name='amount'
                  value={(calculatedTotalDepositLines || 0).toFixed(2)}
                  onChange={() => {}}
                  prefix={'$ '}
                  label='Total Deposit Lines'
                  placeholder='Total Deposit Lines'
                  disabled
                />
              </Col>
              <Col md='4'>
                <CustomCurrencyInput
                  name='amount'
                  value={(calculatedDifference || 0).toFixed(2)}
                  onChange={() => {}}
                  prefix={'$ '}
                  label='Difference'
                  placeholder='Difference'
                  disabled
                />
              </Col>
            </Row>
          </Col>
          <Col md='3'>
            <Row>
              <Col className='d-flex flex-column align-items-start'>
                <FormControlLabel
                  sx={{ marginBottom: '7px' }}
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      name='claim_dep'
                      checked={data.claim_dep}
                      sx={{ paddingBottom: '10px' }}
                      color='warning'
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label='Claim Dep'
                />
                <FormControlLabel
                  sx={{ marginBottom: '7px' }}
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      name='down_payment_dep'
                      checked={data.down_payment_dep}
                      sx={{ paddingBottom: '10px' }}
                      color='warning'
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label='Down Payment Dep'
                />
                <FormControlLabel
                  sx={{ marginBottom: '7px' }}
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      name='fixed_asset_dep'
                      checked={data.fixed_asset_dep}
                      sx={{ paddingBottom: '10px' }}
                      color='warning'
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label='Fixed Asset Dep'
                />
                <FormControlLabel
                  sx={{ marginBottom: '7px' }}
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      name='legal_dep'
                      checked={data.legal_dep}
                      sx={{ paddingBottom: '10px' }}
                      color='warning'
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label='Legal Dep'
                />
                <FormControlLabel
                  sx={{ marginBottom: '7px' }}
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      name='security_dep'
                      checked={data.security_dep}
                      sx={{ paddingBottom: '10px' }}
                      color='warning'
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label='Security Dep'
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </CustomModal>
    </>
  );
};

export default AddDepositItem;
