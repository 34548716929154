import moment from 'moment';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { notificationDanger, notificationDangerCustomTime, notificationSuccess } from '../../../../../../utils/toastify';

const getApplicationOfPayments = (data) => {
  return axios.get(`/me/client/customer/${data.clientId}/transactions/payment/application-of-payments`, { params: data });
};

export const useGetApplicationOfPayments = (filter) => {
  return useQuery(
    ['application-of-payments', filter.page, filter.pageSize, filter.sort_by, filter.order_by],
    () => getApplicationOfPayments(filter),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const getPaymentInfo = (data) => {
  return axios.get(`/me/client/customer/${data.clientId}/transactions/payment/payment-info`, { params: data });
};

export const useGetPaymentInfo = (filter) => {
  return useQuery(['payment-info', filter.page, filter.pageSize, filter.sort_by, filter.order_by], () => getPaymentInfo(filter), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const createApplicationOfPayments = (data) => {
  return axios.post(`/me/client/customer/${data.clientId}/transactions/payment/application-of-payments`, data);
};

export const useCreateApplicationOfPayments = (filter, params, callback) => {
  const queryClient = useQueryClient();
  return useMutation(createApplicationOfPayments, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-one-transaction', params.clientId, params.invoiceId]);
      queryClient.invalidateQueries(['application-of-payments', filter.page, filter.pageSize, filter.sort_by, filter.order_by]);
      notificationSuccess('Successfully created application of payment!');
      callback();
    },
    onError: () => {
      callback();
    },
  });
};

const updateAppliedAmount = (data) => {
  return axios.patch(`/me/client/customer/${data.clientId}/transactions/payment/update-applied-amount`, data);
};

export const useUpdateAppliedAmount = (filter, params, callback) => {
  const queryClient = useQueryClient();
  return useMutation(updateAppliedAmount, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-one-transaction']);
      queryClient.invalidateQueries(['application-of-payments', filter.page, filter.pageSize, filter.sort_by, filter.order_by]);
      notificationSuccess('Successfully Changed Amount');

      callback();
    },
    onError: (err) => {
      if (err?.response?.status === 422) {
        notificationDanger(err.response.data.error);
      }

      callback();
    },
  });
};

/**
 * @param {{clientId: number, transactionClearingID: number}} data
 */
const deleteAppliedAmount = (data) => {
  return axios.delete(`/me/client/customer/${data.clientId}/transactions/payment/delete-applied-amount`, { params: data });
};

export const useDeleteAppliedAmount = (filter, params) => {
  const queryClient = useQueryClient();
  return useMutation(deleteAppliedAmount, {
    onSuccess: () => {
      queryClient.invalidateQueries(['payment-info']);
      queryClient.invalidateQueries(['get-one-transaction']);
      queryClient.invalidateQueries(['get-one-transaction']);
      queryClient.invalidateQueries(['application-of-payments', filter.page, filter.pageSize, filter.sort_by, filter.order_by]);
      notificationSuccess('Successfully deleted application of payment');
    },
  });
};

const reversePayment = (data) => {
  return axios.put(`/me/client/customer/${data.clientId}/transactions/payment/reverse-payment`, data);
};

export const useReversePayment = () => {
  const queryClient = useQueryClient();
  return useMutation(reversePayment, {
    onSuccess: () => {
      queryClient.invalidateQueries(['client.transactions']);
      queryClient.invalidateQueries(['client']);
      queryClient.invalidateQueries(['transactions.all']);

      notificationSuccess('Successfully reversed payment!');
    },
    onError: (error) => {
      if (error.response.status === 422) {
        notificationDangerCustomTime(error?.response?.data?.error, 10000);
      }
    },
  });
};
