import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { LayersClearOutlined, DoDisturbOnOutlined } from '@mui/icons-material';
import { formatDateForTable } from '../../../../../../../../utils/formatDataForTable';
import { formatAmount } from '../../../../../../../../utils/formatAmout';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { CUSTOMER_REFUND } from '../../../../../../../../constants/documentType.constants';

const transactionTypeColumn = (row) => {
  return (
    <div>
      <span>{row.value}</span>
      {!row?.original?.is_client_transaction && (
        <Tooltip title='Child Transaction' followCursor placement='top' arrow>
          <InfoIcon color='info' sx={{ fontSize: '16px', '&:hover': { cursor: 'help' } }} />
        </Tooltip>
      )}
    </div>
  );
};

export const applicationOfPaymentColumns = ({ onChangeAmountClick, onDeleteClick, payingTransactionType }) => {
  return [
    {
      accessor: (d) => d['transactions.posting_date'],
      id: 'transactions.posting_date',
      Header: 'Posting Date',
      Cell: (row) => {
        return <div>{formatDateForTable(row.value)}</div>;
      },
    },
    {
      accessor: 'entry_type',
      Header: 'Entry Type',
      Cell: (row) => {
        return <div>{row.value}</div>;
      },
    },
    {
      accessor: 'transaction_type',
      Header: 'Transaction Type',
      Cell: transactionTypeColumn,
    },
    {
      accessor: (d) => d['transactions.document_number'],
      id: 'transactions.document_number',
      Header: 'Invoice Number',
      Cell: (row) => <div>{row.value}</div>,
    },
    {
      accessor: 'initial_entry_due_date',
      Header: 'Invoice Due Date',
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },
    {
      accessor: 'invoice_amount',
      Header: 'Invoice Amount',
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
    },
    {
      accessor: 'invoice_balance',
      Header: 'Remaining Balance',
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
    },
    {
      accessor: 'applied_amount',
      Header: 'Applied Amount',
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
    },
    {
      accessor: (d) => d['date_applied'],
      id: 'date_applied',
      Header: 'Date Applied',
      Cell: (row) => {
        return <div>{formatDateForTable(row.value)}</div>;
      },
    },
    {
      filterable: false,
      sortable: false,
      accessor: (row) => {
        const isCustomerRefundOnInvoice = row.transaction_type.includes('Customer Invoice') && payingTransactionType === CUSTOMER_REFUND;

        return (
          <div className='d-flex justify-content-center'>
            {!isCustomerRefundOnInvoice && (
              <>
                <Tooltip title='Apply Amount' placement='top' arrow followCursor>
                  <IconButton onClick={() => onChangeAmountClick(row)} color='success'>
                    <LayersClearOutlined />
                  </IconButton>
                </Tooltip>
                {+row.applied_amount ? (
                  <Tooltip title='Cancel Application' placement='top' arrow followCursor>
                    <IconButton onClick={(e) => onDeleteClick(e, row)} color='error'>
                      <DoDisturbOnOutlined />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </>
            )}
          </div>
        );
      },
      id: 'actionBtn',
      Header: (props) => <div className='d-flex justify-content-center'>Actions</div>,
    },
  ];
};
