import { Button, Divider } from '@mui/material';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { CollapsableCard } from '../../../../common/Card/CollapsableCard';
import { CustomToggleButtonGroup } from '../../../../common/Inputs/CustomToggleButtonGroup';
import { CustomDateRange } from '../../../../common/Inputs/CustomDateRange';
import { CustomPageableAsyncSelect } from '../../../../common/Inputs/CustomPageableAsyncSelect';
import { CustomInput } from '../../../../common/CustomInput';
import { useGetBankAccount, useGetPaymentTypes } from '../../../../hooks/commonApiHooks';
import { CustomSelect } from '../../../../common/CustomSelect';
import { useAsyncPageable } from '../../../../hooks/useAsyncPageable';

export const DepositTableFilters = ({ filters, setFilters, search }) => {
  const { fetchOptions } = useAsyncPageable();
  const { data: { data: bankAccountOptions } = {} } = useGetBankAccount();
  const { data: { data: paymentTypesOptions } = [] } = useGetPaymentTypes();

  const loadDocumentNumberOptions = async (inputValue, options) => {
    const data = await fetchOptions(`/me/bank/payment-wizard/document-number-select`, {
      q: inputValue,
      ...options,
    });

    return data;
  };

  const handleButtonGroupChange = (e) => {
    const { name, value } = e.target;
    const newFilters = { ...filters };

    if (!value) {
      newFilters[name] = undefined;
    } else if (value === 'true') {
      newFilters[name] = true;
    } else if (value === 'false') {
      newFilters[name] = false;
    }

    setFilters((prev) => ({ ...prev, ...newFilters }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters((p) => ({ ...p, [name]: value }));
  };

  const onInputChange = (e) => {
    setFilters((oldFilter) => ({
      ...oldFilter,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectChange = (val, e) => {
    setFilters((oldFilter) => ({
      ...oldFilter,
      [e.name]: val?.value || null,
    }));
  };

  const changeMultiSelect = (val, e) => {
    setFilters((prev) => ({ ...prev, [e.name]: val ? val.map((v) => v.value) : [] }));
  };

  return (
    <CollapsableCard
      headerTitle='Filters'
      isExpanded={false}
      titleTypographyProps={{ fontSize: '16px' }}
      sxCardContent={{ padding: '14px' }}
      sxCardHeader={{ padding: '14px' }}
    >
      <Row>
        <Col md='3'>
          <CustomPageableAsyncSelect
            label='Document Number'
            isClearable
            onChange={handleSelectChange}
            loadOptions={loadDocumentNumberOptions}
            name='document_number'
            defaultValue={filters['document_number'] || null}
          />
        </Col>
        <Col md='3'>
          <CustomDateRange label='Posting Date' name='posting_date' onChange={handleChange} value={filters.posting_date || null} isDateRange />
        </Col>
        <Col md='3'>
          <CustomInput
            name='amount_from'
            value={filters['amount_from']}
            onChange={onInputChange}
            placeholder='Amount From'
            label='Amount From'
            type='number'
          />
        </Col>
        <Col md='3'>
          <CustomInput
            name='amount_to'
            value={filters['amount_to']}
            onChange={onInputChange}
            placeholder='Amount To'
            label='Amount To'
            type='number'
          />
        </Col>
      </Row>
      <Row>
        <Col md='3'>
          <CustomSelect
            label='Bank Account'
            isClearable
            onChange={handleSelectChange}
            name='account_number'
            defaultValue={filters['account_number'] || null}
            options={bankAccountOptions || []}
          />
        </Col>
        <Col md='3'>
          <CustomSelect
            label='Payment Type'
            isClearable
            onChange={changeMultiSelect}
            name='payment_type'
            defaultValue={filters['payment_type'] || []}
            options={paymentTypesOptions || []}
            isMulti
          />
        </Col>
        <Col md='3'>
          <CustomToggleButtonGroup size='small' label='Finished' name='finish' value={filters.finish ?? null} onChange={handleButtonGroupChange} />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <Divider />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col className='d-flex justify-content-end'>
          <Button onClick={search}>Search</Button>
        </Col>
      </Row>
    </CollapsableCard>
  );
};
