import { formatAmount } from '../../../../../../utils/formatAmout';
import { formatDateForTable } from '../../../../../../utils/formatDataForTable';

/**
 * @param {{clientType : "Vendor" | "Customer", isDetailed: boolean}} props
 * @returns []
 */
export const tableColumns = ({ clientType, isDetailed }) => {
  const columns = [
    {
      id: 'client.external_id',
      accessor: (d) => d['client.external_id'],
      filterName: 'client.external_id',
      Cell: (row) => {
        return <div>{row.value}</div>;
      },
      Header: <div>{clientType} No.</div>,
      filterable: false,
      sort: true,
    },
    {
      id: 'client.rmi_number',
      accessor: (d) => d['client.rmi_number'],
      filterName: 'client.rmi_number',
      Cell: (row) => {
        return <div>{row.value}</div>;
      },
      Header: <div>RMI No.</div>,
      filterable: false,
      sort: true,
    },
    {
      id: 'client.name',
      accessor: (d) => d['client.name'],
      filterName: 'client.name',
      Cell: (row) => {
        return <div>{row.value}</div>;
      },
      Header: <div>{clientType}</div>,
      filterable: false,
      sort: false,
      width: 250,
    },
    {
      id: 'debit_upto30',
      filterName: 'debit_upto30',
      accessor: 'debit_upto30',
      Header: <div>0 to 30 days</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sortMethod: (a, b) => Number(a) - Number(b),
      filterable: false,
      sort: false,
    },
    {
      id: 'debit_upto60',
      accessor: 'debit_upto60',
      filterName: 'debit_upto60',
      Header: <div>31 - 60 days</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sortMethod: (a, b) => Number(a) - Number(b),
      filterable: false,
      sort: false,
    },
    {
      id: 'debit_upto90',
      accessor: 'debit_upto90',
      filterName: 'debit_upto90',
      Header: <div>61 - 90 days</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sortMethod: (a, b) => Number(a) - Number(b),
      filterable: false,
      sort: false,
    },
    {
      id: 'debit_over90',
      accessor: 'debit_over90',
      filterName: 'debit_over90',
      Header: <div>90+ days</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sortMethod: (a, b) => Number(a) - Number(b),
      filterable: false,
      sort: false,
    },
    {
      id: 'total_balance',
      accessor: 'total_balance',
      filterName: 'total_balance',
      Header: <div>Total Balance</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sortMethod: (a, b) => Number(a) - Number(b),
      filterable: false,
      sort: false,
    },
  ];

  if (clientType.toLowerCase().includes('vendor')) {
    columns.splice(1, 0, {
      id: 'client.parent_external_id',
      accessor: (d) => d['client.parent_external_id'],
      filterName: 'client.parent_external_id',
      Cell: (row) => {
        return <div>{row.value}</div>;
      },
      Header: <div>Parent {clientType} No.</div>,
      filterable: false,
      sortable: false,
    });
  }

  const columnsDetailed = [
    {
      id: 'client.external_id',
      accessor: (d) => d['client.external_id'],
      filterName: 'client.external_id',
      Cell: (row) => {
        return <div>{row.value}</div>;
      },
      Header: <div>{clientType} No.</div>,
      filterable: false,
      sort: true,
    },
    {
      id: 'client.rmi_number',
      accessor: (d) => d['client.rmi_number'],
      filterName: 'client.rmi_number',
      Cell: (row) => {
        return <div>{row.value}</div>;
      },
      Header: <div>RMI No.</div>,
      filterable: false,
      sort: true,
    },
    {
      id: 'client.name',
      accessor: (d) => d['client.name'],
      filterName: 'client.name',
      Cell: (row) => {
        return <div>{row.value}</div>;
      },
      Header: <div>{clientType}</div>,
      filterable: false,
      sort: false,
      width: 250,
    },
    {
      id: 'transactions.document_date',
      accessor: (d) => d['transactions.document_date'],
      filterName: 'transactions.document_date',
      Cell: (row) => {
        return <div>{formatDateForTable(row.value)}</div>;
      },
      Header: <div>Aging Date (Trx Date)</div>,
      filterable: false,
      sort: true,
    },
    {
      id: 'transactions.posting_date',
      accessor: (d) => d['transactions.posting_date'],
      filterName: 'transactions.posting_date',
      Cell: (row) => {
        return <div>{formatDateForTable(row.value)}</div>;
      },
      Header: <div>Posting Date</div>,
      filterable: false,
      sort: true,
    },
    {
      id: 'document_type.name',
      accessor: (d) => d['document_type.name'],
      filterName: 'document_type.name',
      Cell: (row) => {
        return <div>{row.value}</div>;
      },
      Header: <div>Document Type</div>,
      filterable: false,
      sort: true,
    },
    {
      id: 'transactions.document_number',
      accessor: (d) => d['transactions.document_number'],
      filterName: 'transactions.document_number',
      Cell: (row) => {
        return <div>{row.value}</div>;
      },
      Header: <div>Document No.</div>,
      filterable: false,
      sort: true,
    },
    {
      id: 'total_balance',
      accessor: 'total_balance',
      filterName: 'total_balance',
      Header: <div>Balance Due</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sortMethod: (a, b) => Number(a) - Number(b),
      filterable: false,
      sort: false,
    },
    {
      id: 'debit_upto30',
      filterName: 'debit_upto30',
      accessor: 'debit_upto30',
      Header: <div>Current</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sortMethod: (a, b) => Number(a) - Number(b),
      filterable: false,
      sort: false,
    },
    {
      id: 'debit_upto60',
      accessor: 'debit_upto60',
      filterName: 'debit_upto60',
      Header: <div>31 - 60 days</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sortMethod: (a, b) => Number(a) - Number(b),
      filterable: false,
      sort: false,
    },
    {
      id: 'debit_upto90',
      accessor: 'debit_upto90',
      filterName: 'debit_upto90',
      Header: <div>61 - 90 days</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sortMethod: (a, b) => Number(a) - Number(b),
      filterable: false,
      sort: false,
    },
    {
      id: 'debit_over90',
      accessor: 'debit_over90',
      filterName: 'debit_over90',
      Header: <div>90+ days</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sortMethod: (a, b) => Number(a) - Number(b),
      filterable: false,
      sort: false,
    },
  ];

  if (clientType.toLowerCase().includes('vendor')) {
    columnsDetailed.splice(1, 0, {
      id: 'client.parent_external_id',
      accessor: (d) => d['client.parent_external_id'],
      filterName: 'client.parent_external_id',
      Cell: (row) => {
        return <div>{row.value}</div>;
      },
      Header: <div>Parent {clientType} No.</div>,
      filterable: false,
      sortable: false,
    });
  }

  if (isDetailed) {
    return columnsDetailed;
  }

  return columns;
};

/**
 * @param {{clientType : "Vendor" | "Customer", isDetailed: boolean}} props
 * @returns []
 */
export const agingExcelSummaryHeader = ({ clientType }) => {
  const headers = [
    { header: `${clientType} No.`, key: 'client.external_id' },
    { header: 'RMI No.', key: 'client.rmi_number' },
    { header: 'Name', key: 'client.name_only' },
    { header: 'TOTAL', key: 'total_balance', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
    { header: '1-30', key: 'debit_upto30', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
    { header: '31-60', key: 'debit_upto60', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
    { header: '61-90', key: 'debit_upto90', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
    { header: '90+', key: 'debit_over90', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
  ];

  if (clientType.toLowerCase().includes('vendor')) {
    headers.splice(1, 0, { header: `Parent ${clientType} No.`, key: 'client.parent_external_id' });
  }

  return headers;
};

/**
 * @param {{clientType : "Vendor" | "Customer", isDetailed: boolean}} props
 * @returns []
 */
export const agingExcelDetailedHeader = ({ clientType }) => {
  const headers = [
    { header: `${clientType} No.`, key: 'client.external_id' },
    { header: 'RMI No.', key: 'client.rmi_number' },
    { header: 'Name', key: 'client.name_only' },
    { header: 'Aging Date (Trx Date)', key: 'transactions.document_date' },
    { header: 'Posting Date', key: 'transactions.posting_date' },
    { header: 'Document Type', key: 'document_type.name' },
    { header: 'Document No.', key: 'transactions.document_number' },
    { header: 'Balance Due', key: 'total_balance', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
    { header: 'Current', key: 'debit_upto30', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
    { header: '31-60', key: 'debit_upto60', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
    { header: '61-90', key: 'debit_upto90', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
    { header: '91+', key: 'debit_over90', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
  ];

  if (clientType.toLowerCase().includes('vendor')) {
    headers.splice(1, 0, { header: `Parent ${clientType} No.`, key: 'client.parent_external_id' });
  }

  return headers;
};
