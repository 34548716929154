export const CUSTOMER_INVOICE = 1;
export const VENDOR_INVOICE = 2;
export const JOURNAL_ENTRY = 3;
export const CUSTOMER_CREDIT_MEMO = 4;
export const VENDOR_CREDIT_MEMO = 5;
export const CUSTOMER_PAYMENT = 6;
export const VENDOR_PAYMENT = 7;
export const CUSTOMER_REVERSING_PAYMENT = 8;
export const VENDOR_REVERSING_PAYMENT = 9;

export const recurringTransactionTypesOptions = [
  { label: 'Journal Entry', value: JOURNAL_ENTRY },
  { label: 'Customer Invoice', value: CUSTOMER_INVOICE },
  { label: 'Vendor Invoice', value: VENDOR_INVOICE },
  { label: 'Customer Credit Memo', value: CUSTOMER_CREDIT_MEMO },
  { label: 'Vendor Credit Memo', value: VENDOR_CREDIT_MEMO },
  { label: 'Customer Deposit', value: CUSTOMER_PAYMENT },
  { label: 'Vendor Payment', value: VENDOR_PAYMENT },
  { label: 'Customer Reversing Payment', value: CUSTOMER_REVERSING_PAYMENT },
  { label: 'Vendor Reversing Payment', value: VENDOR_REVERSING_PAYMENT },
];

const transactionTypesMapper = new Map();
transactionTypesMapper.set(CUSTOMER_INVOICE, 'Customer Invoice');
transactionTypesMapper.set(VENDOR_INVOICE, 'Vendor Invoice');
transactionTypesMapper.set(JOURNAL_ENTRY, 'Journal Entry');
transactionTypesMapper.set(CUSTOMER_CREDIT_MEMO, 'Customer Credit Memo');
transactionTypesMapper.set(VENDOR_CREDIT_MEMO, 'Vendor Credit Memo');
transactionTypesMapper.set(CUSTOMER_PAYMENT, 'Customer Deposit');
transactionTypesMapper.set(VENDOR_PAYMENT, 'Vendor Payment');
transactionTypesMapper.set(CUSTOMER_REVERSING_PAYMENT, 'Customer Reversing Payment');
transactionTypesMapper.set(VENDOR_REVERSING_PAYMENT, 'Vendor Reversing Payment');

export const getRecurringTransactionTypes = (id) => {
  return transactionTypesMapper.get(id);
};
