const documentTypeMapper = new Map();

documentTypeMapper.set(null, {
  'transactions.document_type_journal_entry_id': null,
  'transactions.document_type_domain_id': null,
});
documentTypeMapper.set(1, {
  'transactions.document_type_journal_entry_id': 1,
  'transactions.document_type_domain_id': 1,
});
documentTypeMapper.set(2, {
  'transactions.document_type_journal_entry_id': 2,
  'transactions.document_type_domain_id': 1,
});
documentTypeMapper.set(3, {
  'transactions.document_type_journal_entry_id': 3,
  'transactions.document_type_domain_id': 1,
});
documentTypeMapper.set(4, {
  'transactions.document_type_journal_entry_id': 4,
  'transactions.document_type_domain_id': 1,
});
documentTypeMapper.set(5, {
  'transactions.document_type_journal_entry_id': 5,
  'transactions.document_type_domain_id': 1,
});
documentTypeMapper.set(6, {
  'transactions.document_type_journal_entry_id': 1,
  'transactions.document_type_domain_id': 2,
});
documentTypeMapper.set(7, {
  'transactions.document_type_journal_entry_id': 2,
  'transactions.document_type_domain_id': 2,
});
documentTypeMapper.set(8, {
  'transactions.document_type_journal_entry_id': 3,
  'transactions.document_type_domain_id': 2,
});
documentTypeMapper.set(9, {
  'transactions.document_type_journal_entry_id': 4,
  'transactions.document_type_domain_id': 2,
});
documentTypeMapper.set(10, {
  'transactions.document_type_journal_entry_id': 5,
  'transactions.document_type_domain_id': 2,
});
documentTypeMapper.set(11, {
  'transactions.document_type_journal_entry_id': 6,
  'transactions.document_type_domain_id': 3,
});
documentTypeMapper.set(12, {
  'transactions.document_type_journal_entry_id': 7,
  'transactions.document_type_domain_id': 3,
});
documentTypeMapper.set(13, {
  'transactions.document_type_journal_entry_id': 8,
  'transactions.document_type_domain_id': 3,
});
documentTypeMapper.set(14, {
  'transactions.document_type_journal_entry_id': 9,
  'transactions.document_type_domain_id': 3,
});
documentTypeMapper.set(15, {
  'transactions.document_type_journal_entry_id': 10,
  'transactions.document_type_domain_id': 3,
});
documentTypeMapper.set(16, {
  'transactions.document_type_journal_entry_id': 11,
  'transactions.document_type_domain_id': 4,
});
documentTypeMapper.set(17, {
  'transactions.document_type_journal_entry_id': 12,
  'transactions.document_type_domain_id': 4,
});
documentTypeMapper.set(18, {
  'transactions.document_type_journal_entry_id': 13,
  'transactions.document_type_domain_id': 4,
});
documentTypeMapper.set(19, {
  'transactions.document_type_journal_entry_id': 14,
  'transactions.document_type_domain_id': 4,
});
documentTypeMapper.set(20, {
  'transactions.document_type_journal_entry_id': 15,
  'transactions.document_type_domain_id': 5,
});
documentTypeMapper.set(21, {
  'transactions.document_type_journal_entry_id': 16,
  'transactions.document_type_domain_id': 5,
});
documentTypeMapper.set(22, {
  'transactions.document_type_journal_entry_id': 17,
  'transactions.document_type_domain_id': 5,
});
documentTypeMapper.set(23, {
  'transactions.document_type_journal_entry_id': 18,
  'transactions.document_type_domain_id': 3,
});

export const getDocumentType = (id) => {
  return documentTypeMapper.get(id);
};

export const documentTypeOptions = [
  {
    label: 'Customer Invoice',
    value: 1,
  },
  {
    label: 'Customer Payment',
    value: 2,
  },
  {
    label: 'Customer Credit Memo',
    value: 3,
  },
  {
    label: 'Customer Debit Memo',
    value: 4,
  },
  {
    label: 'Customer Refund',
    value: 5,
  },

  {
    label: 'Vendor Invoice',
    value: 6,
  },
  {
    label: 'Vendor Payment',
    value: 7,
  },
  {
    label: 'Vendor Credit Memo',
    value: 8,
  },
  {
    label: 'Vendor Debit Memo',
    value: 9,
  },
  {
    label: 'Vendor Refund',
    value: 10,
  },

  {
    label: 'Other Income',
    value: 11,
  },
  {
    label: 'Other Expense',
    value: 12,
  },
  {
    label: 'Transfer',
    value: 13,
  },
  {
    label: 'Close Entry',
    value: 14,
  },
  {
    label: 'Opening Entry',
    value: 15,
  },

  {
    label: 'FA Purchase',
    value: 16,
  },
  {
    label: 'FA Depreciation',
    value: 17,
  },
  {
    label: 'FA Appreciation',
    value: 18,
  },
  {
    label: 'FA DISPOSAL',
    value: 19,
  },
  {
    label: 'LOAN NEW',
    value: 20,
  },
  {
    label: 'LOAN INTEREST',
    value: 21,
  },
  {
    label: 'LOAN PAYMENT',
    value: 22,
  },
  {
    label: 'Journal Entry',
    value: 23,
  },
];

export const customerTransactioTypeOptions = [
  {
    label: 'Customer Invoice',
    value: 1,
  },
  {
    label: 'Customer Payment',
    value: 2,
  },
  {
    label: 'Customer Credit Memo',
    value: 3,
  },
  {
    label: 'Customer Debit Memo',
    value: 4,
  },
  {
    label: 'Customer Refund',
    value: 5,
  },
];

export const vendorTransactioTypeOptions = [
  {
    label: 'Vendor Invoice',
    value: 6,
  },
  {
    label: 'Vendor Payment',
    value: 7,
  },
  {
    label: 'Vendor Credit Memo',
    value: 8,
  },
  {
    label: 'Vendor Debit Memo',
    value: 9,
  },
  {
    label: 'Vendor Refund',
    value: 10,
  },
];

// journal entry ids
export const CUSTOMER_INVOICE = 1;
export const VENDOR_INVOICE = 1;
export const CUSTOMER_PAYMENT = 2;
export const VENDOR_PAYMENT = 2;
export const CUSTOMER_CREDIT_MEMO = 3;
export const VENDOR_CREDIT_MEMO = 3;
export const CUSTOMER_DEBIT_MEMO = 4;
export const VENDOR_DEBIT_MEMO = 4;
export const CUSTOMER_REFUND = 5;
export const VENDOR_REFUND = 5;
export const OTHER_INCOME = 6;
export const OTHER_ESPENSE = 7;
export const TRANSFER = 8;
export const CLOSE_ENTRY = 9;
export const OPENING_ENTRY = 10;
export const FA_PURCHASE = 11;
export const FA_DEPRECIATION = 12;
export const FA_APRECIATION = 13;
export const FA_DISPOSAL = 14;
export const LOAN_NEW = 15;
export const LOAN_INTEREST = 16;
export const LOAN_PAYMENT = 17;
