import React from 'react';
import { formatDateForTable } from '../../../../utils/formatDataForTable';
import { formatAmount } from '../../../../utils/formatAmout';
import { Checkbox } from '@mui/material';

export const DepreciationItemsPreviewTableColumns = (isChecked, handleChange, handleChangeAll, checkedAll, setCheckedAll) => {
  return [
    {
      accessor: 'asset_name',
      Header: 'Asset Name',
    },
    {
      accessor: 'from_date',
      Header: 'From Date',
      Cell: (row) => formatDateForTable(row?.value),
    },
    {
      accessor: 'to_date',
      Header: 'To Date',
      Cell: (row) => formatDateForTable(row?.value),
    },
    {
      accessor: 'depreciation_amount',
      Header: 'Depreciation Amount',
      Cell: (row) => formatAmount(row?.value),
    },
    {
      accessor: 'net_book_value',
      Header: 'Net Book Value',
      Cell: (row) => formatAmount(row?.value),
    },
    {
      accessor: 'unit_number',
      Header: 'Fixed Asset',
    },
    {
      Header: (row) => <Checkbox color='success' checked={checkedAll} onChange={(e) => handleChangeAll(e, setCheckedAll(e.target.checked))} />,
      Cell: (row) => (
        <Checkbox checked={isChecked[row?.original?.id] || false} color='success' onChange={(e) => handleChange(e, row?.original?.id)} />
      ),
    },
  ];
};
