import moment from 'moment';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { notificationSuccess } from '../../../utils/toastify';
import { customDownloader } from '../../../utils/downloaderFiles';

const getRecurringJLTableData = (filter) => {
  return axios.get(`/me/recurring-jl`, { params: { ...filter } });
};

export const useGetRecurringJLTableData = (filter) => {
  return useQuery(['recurring-jl-table', filter.page, filter.pageSize, filter.sort_by, filter.order_by], () => getRecurringJLTableData(filter), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const getRecurringJL = (id) => {
  return axios.get(`/me/recurring-jl/${id}`);
};

export const useGetRecurringJL = (id, callback) => {
  return useQuery(['recurring-jl-component', id], () => getRecurringJL(id), {
    refetchOnWindowFocus: false,
    enabled: !!id,
    onSuccess: (data) => {
      const result = data.data;
      if (!result.items) result.items = [];
      callback && callback(result);

      return result;
    },
  });
};

const deleteRecurringJL = (id) => {
  return axios.delete(`/me/recurring-jl/${id}`);
};

export const useDeleteRecurringJLMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteRecurringJL, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['recurring-jl-table']);
    },
  });
};

const createRecurringJL = (data) => {
  return axios.post(`/me/recurring-jl`, data);
};

export const useCreateRecurringJLMutation = (callback) => {
  const queryClient = useQueryClient();
  return useMutation(createRecurringJL, {
    onMutate: (variables) => {
      return variables;
    },
    onSuccess: (successResponse) => {
      // queryClient.invalidateQueries(['recurring-jl-component', successResponse.data.id]);
      callback && callback(successResponse.data.id);
    },
    onError: (err, variables) => {
      console.log(err);
    },
  });
};

const updateRecurringJL = ({ data, id }) => {
  return axios.patch(`/me/recurring-jl/${id}`, data);
};

export const useUpdateRecurringJLMutation = (callback) => {
  const queryClient = useQueryClient();
  return useMutation(updateRecurringJL, {
    onMutate: (variables) => {
      return variables;
    },
    onSuccess: (successResponse) => {
      queryClient.invalidateQueries(['recurring-jl-component']);
      callback && callback();
    },
    onError: (err, variables) => {
      console.log(err);
    },
  });
};

const deleteRecurringItem = (itemId) => {
  return axios.delete(`/me/recurring-jl/items/${itemId}`);
};

export const useDeleteRecurringItem = () => {
  return useMutation(deleteRecurringItem);
};

const postRecurring = (data) => {
  return axios.post('/me/recurring-jl/post-recurring', data);
};

export const usePostRecurring = (callback, errorCb) => {
  const queryClient = useQueryClient();
  return useMutation(postRecurring, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['recurring-jl-component']);
      callback && callback('Successfully posted recurring joural entry!');
    },
    onError: (error) => {
      if (error.response.status === 422) {
        errorCb(error?.response?.data?.error);
      }
    },
  });
};

const exportExcel = (recurringId) => {
  return customDownloader({ url: `/me/recurring-jl/excel/${recurringId}`, fileType: 'EXCEL' });
};

export const useExportExcel = () => {
  return useMutation(exportExcel, {});
};

const uploadEntries = (data) => {
  return axios.post(`/me/recurring-jl/items/upload`, data.formData);
};

export const useUploadEntriesMutation = ({ errorCb, successCb, recurringId }) => {
  const queryClient = useQueryClient();
  return useMutation(uploadEntries, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['recurring-jl-component', recurringId]);
      successCb(data.data);
    },
    onError: (error) => {
      if (error.response.status === 422) {
        errorCb(error?.response?.data?.error);
      }
    },
  });
};

const exampleUploadExcelDownload = () => {
  return customDownloader({ url: '/me/recurring-jl/items/upload-template', fileType: 'EXCEL' });
};

export const useExampleUploadExcelDownload = () => {
  return useMutation(exampleUploadExcelDownload);
};

const getBankAccounts = () => {
  return axios.get('/me/account/bank-account');
};

export const useGetBankAccount = () => {
  return useQuery(['account/bank-account'], () => getBankAccounts(), {
    refetchOnWindowFocus: false,
  });
};

const getInvoiceForSubAccount = (client) => {
  return axios.get(`/me/recurring-jl/subaccount-transactions`, { params: { ...client } });
};

export const useInvoiceForSubAccount = (client) => {
  return useQuery(['subaccount/invoices', { ...client }], () => getInvoiceForSubAccount(client), {
    refetchOnWindowFocus: false,
    enabled: false,
  });
};

const applyToDocument = (data) => {
  return axios.post(`/me/recurring-jl/apply-to-document`, data);
};

export const useApplyToDocument = (onSuccessCb) => {
  return useMutation(applyToDocument, {
    onSuccess: (data) => {
      onSuccessCb();
      notificationSuccess('Successfully apply to document.');
    },
    onError: (error) => {
      notificationDanger('Unable to apply to document.');
    },
  });
};

const getClearingTransactions = (recurring_jl_id) => {
  return axios.get(`/me/recurring-jl/clearing-transactions/${recurring_jl_id}`);
};

export const useGetClearingTransactions = (recurring_jl_id) => {
  if (!recurring_jl_id) {
    return { data: null };
  }
  return useQuery(['clearing_transactions', recurring_jl_id], () => getClearingTransactions(recurring_jl_id), {
    refetchOnWindowFocus: false,
    enabled: false,
  });
};
