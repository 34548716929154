import React from 'react';
import { formatDateForTable } from '../../../utils/formatDataForTable';
import { formatAmount } from '../../../utils/formatAmout';

export const GeneralLedgerSubColumns = [
  {
    Header: <div>Document Number</div>,
    accessor: 'document_number',
    sort: false,
  },
  {
    Header: <div>Document Type</div>,
    accessor: 'document_type',
    sort: false,
  },
  {
    Header: <div>Account</div>,
    accessor: 'number',
    Cell: ({ value, row }) => {
      return (
        <>
          <div className='mb-2'>{value}</div>
          <div>{row?._original['name']}</div>
        </>
      );
    },
    sort: false,
  },
  {
    Header: <div>Description</div>,
    accessor: 'description',
    sort: false,
  },
  {
    Header: <div>Subaccount Name</div>,
    accessor: 'client_name',
    sort: false,
  },
  {
    Header: <div>Rental Order Number</div>,
    accessor: 'order_number',
    sort: false,
  },
  {
    Header: <div>Stock Number</div>,
    accessor: 'stock_no',
    sort: false,
  },
  {
    Header: <div>Posting Date</div>,
    accessor: 'posting_date',
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    sort: false,
  },
  {
    accessor: 'debit_amount',
    Header: () => <div>Debit Amount</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sort: false,
  },
  {
    accessor: 'credit_amount',
    Header: () => <div>Credit Amount</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sort: false,
  },
  {
    id: 'total',
    accessor: 'total_amount',
    Header: () => <div>Total</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sort: false,
  },
  {
    id: 'source',
    accessor: 'source',
    Header: () => <div>Source</div>,
    sort: true,
  },
  {
    id: 'branch',
    accessor: 'branch',
    Header: () => <div>Branch</div>,
    sort: true,
  },
  {
    id: 'create_at',
    accessor: 'create_at',
    Header: 'Created At',
    Cell: (row) => formatDateForTable(row.value),
    sort: true,
  },
  {
    accessor: 'create_by',
    id: 'create_by',
    Header: 'Created By',
    Cell: (row) => row.value,
    sort: true,
  },
  {
    accessor: 'transactions_id',
    id: 'transactions_id',
    Header: 'Transaction ID',
    Cell: (row) => row.value,
    sort: true,
  },
  {
    accessor: 'line_id',
    id: 'line_id',
    Header: 'Line ID',
    Cell: (row) => row.value,
    sort: true,
  },
];
