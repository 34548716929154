import React, { useEffect, useMemo, useState } from 'react';
import { Col, Label, Row } from 'reactstrap';
import { CustomButton } from '../../../../../../common/Button/CustomButton';
import { DatePickerComponent } from '../../../../../../common/DatePicker/DatePicker';
import { CustomPageableAsyncSelect } from '../../../../../../common/Inputs/CustomPageableAsyncSelect';
import TransactionsDocumentTypeSelectBox from '../../../../../../component/SelectBox/TransactionsDocumentTypeSelectBox';
import { useAsyncPageable } from '../../../../../../hooks/useAsyncPageable';
import { transactionTableTypes } from '../../../constants';
import { CustomSelect } from '../../../../../../common/CustomSelect';

export const ClientTransactionsFilter = ({
  filter,
  blankFilter,
  onChange,
  onDateChange,
  onSelectChange,
  onFilterActionClick,
  onFilterChangeHandler,
  clientType,
  transactionType,
}) => {
  const invoiceLabel = useMemo(() => {
    if (clientType === 'customers') {
      return 'Invoice';
    } else {
      return 'Document';
    }
  }, [clientType]);

  const invoiceDueDateVisible = useMemo(() => {
    const visible = [transactionTableTypes.transactions, transactionTableTypes.invoices].includes(transactionType);
    if (visible) {
      return true;
    }
    return false;
  }, [transactionType]);

  const [init, setInit] = useState(false);

  const { fetchOptions } = useAsyncPageable();

  const loadDocumentNumberOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/transaction/options-for-select-dn', { q: inputValue, ...options });
    return data;
  };

  const loadRentalOrderNumberOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/journal-entry/rental-order-number', { q: inputValue, ...options });
    return data;
  };

  const loadStockNumberOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/transaction//options-for-select-stock-no', { q: inputValue, ...options });
    return data;
  };

  useEffect(() => {
    setInit(false);

    //force rerendering of async inputs
    setTimeout(() => {
      setInit(true);
    }, 1);
  }, [clientType]);

  const handleResetClick = (e) => {
    e.preventDefault();
    e.target.blur();
    onFilterActionClick('reset');

    setInit(false);
    //force rerendering of async inputs
    setTimeout(() => {
      setInit(true);
    }, 1);
  };

  return (
    <>
      <Row>
        {transactionType === transactionTableTypes.transactions && (
          <Col xs={12} md={4} lg={3}>
            <Label>Transactions:</Label>
            <TransactionsDocumentTypeSelectBox
              domain={clientType}
              name='account.number'
              field={{
                'transactions.document_type_journal_entry_id': filter['transactions.document_type_journal_entry_id'],
                'transactions.document_type_domain_id': filter['transactions.document_type_domain_id'],
              }}
              onChange={onFilterChangeHandler}
            />
          </Col>
        )}
        <Col xs={12} md={4} lg={3}>
          {init && (
            <CustomPageableAsyncSelect
              label='Document Number'
              isClearable
              onChange={onSelectChange}
              loadOptions={loadDocumentNumberOptions}
              name='transactions.document_number'
              defaultValue={filter['transactions.document_number']}
              disabled={!!blankFilter['transactions.document_number']}
            />
          )}
        </Col>
        <Col lg={3} md={4} sm={12}>
          <DatePickerComponent
            showYearDropdown
            name='posting_date_from'
            selected={filter['posting_date_from']}
            onChange={(date) => onDateChange(date, 'posting_date_from')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Posting Date From'
            isClearable
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <DatePickerComponent
            showYearDropdown
            name='posting_date_to'
            selected={filter['posting_date_to']}
            onChange={(date) => onDateChange(date, 'posting_date_to')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Posting Date To'
            isClearable
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <DatePickerComponent
            showYearDropdown
            name='invoice_date_from'
            selected={filter['invoice_date_from']}
            onChange={(date) => onDateChange(date, 'invoice_date_from')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label={`${invoiceLabel} Date From`}
            isClearable
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <DatePickerComponent
            showYearDropdown
            name='invoice_date_to'
            selected={filter['invoice_date_to']}
            onChange={(date) => onDateChange(date, 'invoice_date_to')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label={`${invoiceLabel} Date To`}
            isClearable
          />
        </Col>
        {invoiceDueDateVisible && (
          <Col lg={3} md={4} sm={12}>
            <DatePickerComponent
              showYearDropdown
              name='invoice_due_date_from'
              selected={filter['invoice_due_date_from']}
              onChange={(date) => onDateChange(date, 'invoice_due_date_from')}
              placeholder='mm/dd/yyyy'
              dateFormat={'MM/dd/yyyy'}
              label={`${invoiceLabel} Due Date From`}
              isClearable
            />
          </Col>
        )}
        {invoiceDueDateVisible && (
          <Col lg={3} md={4} sm={12}>
            <DatePickerComponent
              showYearDropdown
              name='invoice_due_date_to'
              selected={filter['invoice_due_date_to']}
              onChange={(date) => onDateChange(date, 'invoice_due_date_to')}
              placeholder='mm/dd/yyyy'
              dateFormat={'MM/dd/yyyy'}
              label={`${invoiceLabel} Due Date To`}
              isClearable
            />
          </Col>
        )}
        <Col lg={3} md={4} sm={12}>
          <CustomSelect
            label='Status'
            name='transactions.status'
            isClearable
            options={[
              { name: 'All', value: '' },
              { value: 'paid', label: 'Paid' },
              { value: 'unpaid', label: 'Unpaid' },
            ]}
            onChange={onSelectChange}
            defaultValue={filter['transactions.status']}
            disabled={!!blankFilter['transactions.status']}
          />
        </Col>
        {clientType === 'customers' && init && (
          <>
            <Col md={3}>
              <CustomPageableAsyncSelect
                label='Rental Order Number'
                isClearable
                onChange={onSelectChange}
                loadOptions={loadRentalOrderNumberOptions}
                name='order_number'
                defaultValue={filter['order_number']}
                disabled={!!blankFilter['order_number']}
              />
            </Col>
            <Col md={3}>
              <CustomPageableAsyncSelect
                label='Fixed Asset Number'
                isClearable
                onChange={onSelectChange}
                loadOptions={loadStockNumberOptions}
                name='stock_no'
                defaultValue={filter['stock_no']}
                disabled={!!blankFilter['stock_no']}
              />
            </Col>
          </>
        )}
      </Row>
      <Row className='mt-3 justify-content-end'>
        <Col className='d-flex justify-content-end'>
          <CustomButton color='success' icon={'fas fa-sync-alt'} onClick={handleResetClick}>
            Reset
          </CustomButton>
          <CustomButton
            className={'ml-3'}
            color='secondary'
            onClick={(e) => {
              e.preventDefault();
              e.target.blur();
              onFilterActionClick('search');
            }}
          >
            Search
          </CustomButton>
        </Col>
      </Row>
    </>
  );
};
