import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { notificationSuccess } from '../../../../utils/toastify';

const getUsersFroSelect = () => {
  return axios.get(`/me/repository/users/for-select`);
};

export const useGetUsersFroSelect = () => {
  return useQuery(['users-for-select'], () => getUsersFroSelect(), {
    refetchOnWindowFocus: false,
  });
};

const updateUser = (data) => {
  return axios.patch(`/me/repository/users`, data);
};

export const useUpdateUser = (callback) => {
  const queryClient = useQueryClient();
  return useMutation(updateUser, {
    onSuccess: (data) => {
      notificationSuccess('Successfully updated User!');
      queryClient.invalidateQueries(['user-table']);
      callback && callback(data);
    },
  });
};

const deleteUser = (data) => {
  return axios.patch(`/me/repository/users/delete-user`, data);
};

export const useDeleteUser = (callback) => {
  const queryClient = useQueryClient();
  return useMutation(deleteUser, {
    onSuccess: (data) => {
      notificationSuccess('Successfully deleted User!');
      queryClient.invalidateQueries(['user-table']);
      callback && callback(data);
    },
  });
};

const getUsersTableData = (filter) => {
  return axios.get('/me/repository/users', { params: filter });
};

export const useGetUsersTableData = (filter) => {
  return useQuery(['user-table', filter.page, filter.pageSize, filter.sort_by, filter.order_by], () => getUsersTableData(filter), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const getUser = (id) => {
  return axios.get(`/me/repository/users/${id}`);
};

export const useGetUser = (id) => {
  return useQuery(['user', id], () => getUser(id), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
};

const createUser = (data) => {
  return axios.post('/me/repository/users', data);
};

export const useCreateUser = (callback) => {
  const queryClient = useQueryClient();
  return useMutation(createUser, {
    onSuccess: (data) => {
      notificationSuccess('Successfully created User!');
      queryClient.invalidateQueries(['user-table']);
      callback && callback(null, data);
    },
    onError: (err) => {
      if (err.response && err.response.data && err.response.data.error) {
        callback(err.response.data.error);
      }
    },
  });
};

const updateUserPassword = (data) => {
  return axios.patch('/me/repository/users/change-password', data);
};

export const useUpdateUserPassword = () => {
  return useMutation(updateUserPassword, {
    onSuccess: () => {
      notificationSuccess('Successfully updated password!');
    },
    onError: (err) => {
      if (err.response && err.response.data && err.response.data.error) {
        callback(err.response.data.error);
      }
    },
  });
};
