import React from 'react';
import { useCheckPdfPreview } from '../../hooks';

//* display SSR-ed pdf template
const CheckPdfPreview = ({ clientType, checkId }) => {
  const { data } = useCheckPdfPreview(clientType, checkId);

  if (!data?.data) return <>No Preview Available</>;

  return <div dangerouslySetInnerHTML={{ __html: data.data }} style={{ margin: '2rem' }} />;
};

export { CheckPdfPreview };
