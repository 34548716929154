import axios from 'axios';
import { notificationDanger } from './toastify';

export const previewFile = async (endpoint, method, data, name) => {
  try {
    let response;

    switch (method) {
      case 'get':
        response = await axios.get(endpoint, { responseType: 'blob' });
        break;
      case 'post':
        response = await axios.post(endpoint, data, { responseType: 'blob' });
        break;
      default:
        break;
    }

    const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] ?? response.headers['Content-Type'] }));

    if (!name) {
      const pdfWindow = window.open();
      pdfWindow.location.href = url;
    } else {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${name}`);
      link.click();
    }
  } catch (error) {
    notificationDanger('File does not exist.');
  }
};
