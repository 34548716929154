import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { Box, Button, DialogActions, DialogContent, Grid } from '@mui/material';
import { CollapsableCard } from '../../../../../../../common/Card/CollapsableCard';
import { CustomInput } from '../../../../../../../common/CustomInput';
import { CustomCurrencyInput } from '../../../../../../../common/Inputs/CurrencyInput';
import { CustomPopup } from '../../../../../../../common/Popup/CustomPopup';
import DataGridComponent from '../../../../../../../component/DataTable/component/DataTableComponent';
import { useClient, useGetTransaction } from '../../../../clientHooks';
import { useDeleteAppliedAmount, useGetPaymentInfo } from '../transactionApiHooks';
import { PaymentInfoActions } from './PaymentInfoActions';
import { paymentInfoColumns } from './paymentInfoColumns';
import { CustomSelect } from '../../../../../../../common/CustomSelect';

const PaymentInfoComponent = () => {
  const params = useParams();

  const [cancelReason, setCancelReason] = useState(null);
  const [filters, setFilters] = React.useState({
    invoiceId: params.invoiceId,
    domainId: params.clientType === 'customers' ? 1 : 2,
    page: 0,
    pageSize: 10,
    clientId: params.clientId,
  });

  const [popupData, setPopupData] = React.useState({
    deleteAnchor: null,
    openDeletePopup: false,
  });

  const { data: { data: clientData } = {} } = useClient(params.clientId, params.clientType);
  const { data: { data: invoiceData } = {} } = useGetTransaction(params.clientId, params.invoiceId);
  const { data: { data: tableData } = {} } = useGetPaymentInfo(filters);
  const { mutate: deleteAppliedAmountMutation } = useDeleteAppliedAmount(filters, params);

  const onFilterChange = (e) => {
    setFilters({ ...filters, ...e });
  };

  const handleCancelReasonChange = (e) => {
    setCancelReason(e.value);
  };

  const handleUnApplyClick = (e, row) => {
    setPopupData({
      openDeletePopup: true,
      deleteAnchor: e.currentTarget,
      transactionClearingID: row?.original['tc.id'],
    });
  };

  const cancelDeleteClick = () => {
    setPopupData({ deleteAnchor: null, openDeletePopup: false, transactionClearingID: null });
    setCancelReason(null);
  };

  const confirmDeleteClick = () => {
    deleteAppliedAmountMutation({ clientId: params.clientId, transactionClearingID: popupData.transactionClearingID, reason: cancelReason });
    setPopupData({ deleteAnchor: null, openDeletePopup: false, transactionClearingID: null });
    setCancelReason(null);
  };

  const cleanDataSource = React.useMemo(() => ({ data: [], page: 0, pageSize: 10, pageTotal: 0 }));
  const columns = React.useMemo(
    () =>
      paymentInfoColumns({
        transactionType: invoiceData?.document_type_journal_entry_id,
        Actions: <PaymentInfoActions onDelete={handleUnApplyClick} />,
      }),
    [invoiceData]
  );
  return (
    <>
      <Row className='mb-3 mt-2'>
        <Col>
          <CollapsableCard
            isExpanded
            headerTitle='Invoice Info'
            titleTypographyProps={{ fontSize: '16px' }}
            sxCardHeader={{ padding: '12px' }}
            // addStyles={{ overflowY: 'visible !important' }}
          >
            <Row>
              <Col md='3'>
                <CustomInput
                  label={params.clientType.toLowerCase() === 'customers' ? 'Customer' : 'Vendor'}
                  value={clientData?.external_id + ' - ' + clientData?.name}
                  disabled
                  onChange={() => {}}
                />
              </Col>
              <Col md='3'>
                <CustomInput label='Document Number' value={invoiceData?.document_number} disabled onChange={() => {}} />
              </Col>
              <Col md='3'>
                <CustomCurrencyInput label={'Invoice Amount'} value={invoiceData?.amount} prefix={'$ '} disabled onChange={() => {}} />
              </Col>
              <Col md='3'>
                <CustomCurrencyInput label={'Remaining Balance'} value={invoiceData?.balance} prefix={'$ '} disabled onChange={() => {}} />
              </Col>
            </Row>
          </CollapsableCard>
        </Col>
      </Row>
      <Row>
        <Col>
          <CollapsableCard
            isExpanded={true}
            headerTitle='Payment Info'
            titleTypographyProps={{ fontSize: '16px' }}
            sxCardHeader={{ padding: '12px' }}
            // addStyles={{ overflowY: 'visible !important' }}
          >
            <DataGridComponent columns={columns} loading={false} dataSource={tableData || cleanDataSource} onFilterChange={onFilterChange} />
          </CollapsableCard>
        </Col>
      </Row>

      <CustomPopup
        anchor={popupData.deleteAnchor}
        content={
          <UnapplyPopupContent
            onConfirm={confirmDeleteClick}
            onCancel={cancelDeleteClick}
            onSelect={handleCancelReasonChange}
            cancelReason={cancelReason}
          />
        }
        arrow
        placement='bottom'
        onClose={cancelDeleteClick}
        open={popupData.openDeletePopup}
      />
    </>
  );
};

const UnapplyPopupContent = ({ onConfirm, onCancel, onSelect = () => {}, cancelReason }) => {
  const cancelPaymentApplicationReasons = React.useMemo(() => {
    return [
      { name: 'User Error', value: 1 },
      { name: 'Bounced Payment', value: 2 },
    ];
  }, []);

  return (
    <Box>
      <DialogContent sx={{ padding: '5px' }}>Are you sure you wish to unapply payment amount?</DialogContent>
      <DialogActions sx={{ padding: '5px' }}>
        <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          <Grid item xs={12} sm={6} md={6}>
            <CustomSelect
              borderBottomOnly
              onChange={onSelect}
              name='cancelReason'
              defaultValue={cancelReason}
              options={cancelPaymentApplicationReasons || []}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Button color='success' disabled={!cancelReason} onClick={onConfirm}>
              unapply
            </Button>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Button onClick={onCancel} color='error'>
              cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Box>
  );
};

export { PaymentInfoComponent };
