import { FormLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import { FormGroup } from 'reactstrap';
import { CustomInput } from '../CustomInput';

const StyledIcon = styled('i')((props) => {
  return {
    '&:hover': {
      cursor: 'pointer',
      color: 'black',
    },
  };
});

const CUSTOM = 1;
const RANGE = 2;

export const CustomDateRange = ({
  label,
  name,
  onChange = () => {},
  value = { start: null, end: null },
  isDateRange = false,
  standardOptions = true,
  dateRangeOptions,
}) => {
  const [selectType, setSelectType] = useState(RANGE);

  const { options } = useDateRangeOptions({
    config: {
      options: dateRangeOptions,
      standardOptions,
    },
  });

  const selectHandler = (val, e) => {
    if (e.action === 'select-option') {
      if (val.value !== 'c') {
        onChange({ target: { name, value: val.range } });
      } else {
        setSelectType(CUSTOM);
        onChange({ target: { name, value: { start: null, end: null } } });
      }
    }
    if (e.action === 'clear') {
      onChange({ target: { name, value: { start: null, end: null } } });
    }
  };

  const onCustomHandler = (rangePart, e) => {
    onChange({ target: { name: [e.target.name], value: { ...value, [rangePart]: e.target.value } } });
  };

  if (selectType === CUSTOM) {
    return (
      <>
        <div className='mb-0' style={{}}>
          <div className='d-flex justify-content-between'>
            {label && <FormLabel>{label}</FormLabel>}
            <span>
              <StyledIcon
                className='fas fa-times-circle'
                onClick={(e) => {
                  e.preventDefault();
                  setSelectType(RANGE);
                  onChange({ target: { name, value: { start: null, end: null } } });
                }}
              />
            </span>
          </div>
          <div>
            <CustomInput
              style={{ marginRight: '2px' }}
              key={'start'}
              type='date'
              value={value.start}
              name={name}
              onChange={(e) => onCustomHandler('start', e)}
            />
            {isDateRange && <CustomInput key={'end'} type='date' value={value.end} name={name} onChange={(e) => onCustomHandler('end', e)} />}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <FormGroup>
        {label && <FormLabel>{label}</FormLabel>}
        <Select
          name={name}
          isClearable
          options={options}
          value={options.filter((x) => {
            try {
              return x.range.start === value.start && x.range.end === value.end;
            } catch (e) {
              return false;
            }
          })}
          placeholder={'Select'}
          onChange={selectHandler}
          className='input-sm'
        />
      </FormGroup>
    );
  }
};

export const getDateRageOptions = () => {
  return {
    custom: 'Custom',
    today: 'Today',
    yesterday: 'Yesterday',
    thisWeek: 'This Week',
    lastWeek: 'Last Week',
    nextWeek: 'Next Week',
    nextFourWeeks: 'Next 4 Weeks',
    thisWeekToDate: 'This Week-to-date',
    lastWeekToDate: 'Last Week-to-date',
    thisMonth: 'This Month',
    lastMonth: 'Last Month',
    nextMonth: 'Next Month',
    thisMonthToDate: 'This Month-to-date',
    lastMonthToDate: 'Last Month-to-date',
    thisQuarter: 'This Quarter',
    lastQuarter: 'Last Quarter',
    nextQuarter: 'Next Quarter',
    thisQuarterToDate: 'This Quarter-to-date',
    lastQuarterToDate: 'Last Quarter-to-date',
    thisYear: 'This Year',
    lastYear: 'Last Year',
    nextYear: 'Next Year',
    thisYearToLastMonth: 'This Year-to Last Month',
    thisYearToDate: 'This Year-to-date',
    lastYearToDate: 'Last Year-to-date',
  };
};

export const useDateRangeOptions = ({
  all,
  config = {
    standardOptions: false,
    options: [],
  },
}) => {
  const standardDateRangeOptions = useMemo(() => {
    const { custom, today, yesterday, thisWeek, lastWeek, thisMonth, lastMonth, thisQuarter, lastQuarter, thisYear, lastYear } = getDateRageOptions();

    return [custom, today, yesterday, thisWeek, lastWeek, thisMonth, lastMonth, thisQuarter, lastQuarter, thisYear, lastYear];
  }, []);

  const options = useMemo(() => {
    return [
      {
        label: 'Custom',
        value: 'c',
        range: {
          start: null,
          end: null,
        },
      },
      {
        label: 'Today',
        value: '1',
        range: {
          start: moment().format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD'),
        },
      },
      {
        label: 'This Week',
        value: '2',
        range: {
          start: moment().startOf('isoWeek').format('YYYY-MM-DD'),
          end: moment().endOf('isoWeek').format('YYYY-MM-DD'),
        },
      },
      {
        label: 'This Week-to-date',
        value: '3',
        range: {
          start: moment().startOf('isoWeek').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD'),
        },
      },
      {
        label: 'This Month',
        value: '4',
        range: {
          start: moment().startOf('month').format('YYYY-MM-DD'),
          end: moment().endOf('month').format('YYYY-MM-DD'),
        },
      },
      {
        label: 'This Month-to-date',
        value: '5',
        range: {
          start: moment().startOf('month').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD'),
        },
      },
      {
        label: 'This Quarter',
        value: '6',
        range: {
          start: moment().startOf('quarter').format('YYYY-MM-DD'),
          end: moment().endOf('quarter').format('YYYY-MM-DD'),
        },
      },
      {
        label: 'This Quarter-to-date',
        value: '7',
        range: {
          start: moment().startOf('quarter').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD'),
        },
      },
      {
        label: 'This Year',
        value: '8',
        range: {
          start: moment().startOf('year').format('YYYY-MM-DD'),
          end: moment().endOf('year').format('YYYY-MM-DD'),
        },
      },
      {
        label: 'This Year-to Last Month',
        value: '9',
        range: {
          start: moment().startOf('year').format('YYYY-MM-DD'),
          end: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
        },
      },
      {
        label: 'This Year-to-date',
        value: '10',
        range: {
          start: moment().startOf('year').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD'),
        },
      },
      {
        label: 'Yesterday',
        value: '11',
        range: {
          start: moment().subtract(1, 'days').format('YYYY-MM-DD'),
          end: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        },
      },
      {
        label: 'Last Week',
        value: '12',
        range: {
          start: moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD'),
          end: moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD'),
        },
      },
      {
        label: 'Last Week-to-date',
        value: '13',
        range: {
          start: moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD'),
          end: moment().subtract(1, 'week').format('YYYY-MM-DD'),
        },
      },
      {
        label: 'Last Month',
        value: '14',
        range: {
          start: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
          end: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
        },
      },
      {
        label: 'Last Month-to-date',
        value: '15',
        range: {
          start: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
          end: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        },
      },
      {
        label: 'Last Quarter',
        value: '16',
        range: {
          start: moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'),
          end: moment().subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD'),
        },
      },
      {
        label: 'Last Quarter-to-date',
        value: '17',
        range: {
          start: moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'),
          end: moment().subtract(1, 'quarter').format('YYYY-MM-DD'),
        },
      },
      {
        label: 'Last Year',
        value: '18',
        range: {
          start: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
          end: moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
        },
      },
      {
        label: 'Last Year-to-date',
        value: '19',
        range: {
          start: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
          end: moment().subtract(1, 'year').format('YYYY-MM-DD'),
        },
      },
      {
        label: 'Next Week',
        value: '20',
        range: {
          start: moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
          end: moment().add(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD'),
        },
      },
      {
        label: 'Next 4 Weeks',
        value: '21',
        range: {
          start: moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
          end: moment().add(4, 'weeks').endOf('isoWeek').format('YYYY-MM-DD'),
        },
      },
      {
        label: 'Next Month',
        value: '22',
        range: {
          start: moment().add(1, 'month').startOf('month').format('YYYY-MM-DD'),
          end: moment().add(1, 'month').endOf('month').format('YYYY-MM-DD'),
        },
      },
      {
        label: 'Next Quarter',
        value: '23',
        range: {
          start: moment().add(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'),
          end: moment().add(1, 'quarter').endOf('quarter').format('YYYY-MM-DD'),
        },
      },
      {
        label: 'Next Year',
        value: '24',
        range: {
          start: moment().add(1, 'year').startOf('year').format('YYYY-MM-DD'),
          end: moment().add(1, 'year').endOf('year').format('YYYY-MM-DD'),
        },
      },
    ];
  }, []);

  const getOptions = () => {
    if (!config.standardOptions) {
      return options;
    } else if (config.standardOptions) {
      return standardDateRangeOptions.map((optionLabel) => options.find((opt) => opt.label === optionLabel));
    } else if (config.options && config.options.length) {
      return config.options.map((optionLabel) => options.find((opt) => opt.label === optionLabel));
    } else {
      return [];
    }
  };
  return { options: getOptions() };
};
