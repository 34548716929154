import React from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Typography, IconButton, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import { notificationDangerCustomTime, notificationSuccess } from '../../../../utils/toastify';

import { CustomModal, useCustomModalToggle } from '../../../../common/Dialog/CustomModal';
import { useExampleUploadApplicationDepositExcelDownload, useUploadApplicationsMutation } from '../depositApiRoutes';
import moment from 'moment';

const StyledBox = styled(Box)(({ theme }) => ({
  minWidth: '100%',
  border: '1px dotted red',
  padding: '10px',
}));

const UploadApplications = ({ tableFilters, disabled }) => {
  const params = useParams();
  const [files, setFiles] = React.useState([]);
  const [errors, setErrors] = React.useState([]);
  const { toggleCustomModal, open } = useCustomModalToggle();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles([acceptedFiles[0]]);
    },
  });

  const onErrorResponseCallback = (responseErrors) => {
    setErrors(responseErrors);
  };

  const onSuccessResponseCallback = (data) => {
    if (data.errorsFromUpload.length) {
      setErrors(data.errorsFromUpload);
    } else {
      toggleCustomModal();
    }
    notificationSuccess('Uploaded successfully!');
  };

  const { mutate: downloadExampleFileMutation } = useExampleUploadApplicationDepositExcelDownload();

  const { mutate: uploadApplicationMutation, data: { data: mutationResponse = {} } = {} } = useUploadApplicationsMutation(
    tableFilters,
    params.depositId,
    onErrorResponseCallback,
    onSuccessResponseCallback
  );

  const preparedFilesDisplay = React.useMemo(() => {
    return files.map((file) => (
      <>
        <Grid
          item
          key={file.lastModified}
          xs={12}
          md={12}
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '2px 10px', backgroundColor: 'rgba(46,125,50, .2)' }}
        >
          <Typography sx={{ wordBreak: 'break-word' }}>{file.name}</Typography>
          <IconButton
            onClick={() => setFiles([])}
            sx={{
              color: (theme) => theme.palette.error,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </>
    ));
  }, [files]);

  const preparedErrorsDisplay = React.useMemo(() => {
    return errors.map((error) => (
      <Grid item xs={12} md={12} key={error} sx={{ mb: 2 }}>
        <Alert severity='error'>{error}</Alert>
      </Grid>
    ));
  }, [errors.length]);

  const onUpload = () => {
    setErrors([]);
    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('creation_date', moment().format('YYYY-MM-DD'));

    if (files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      notificationDangerCustomTime('Please provide Excel file!', 5000);
      return;
    }

    uploadApplicationMutation({ formData, depositId: params.depositId });
  };

  const renderActionButtons = (
    <div className='d-flex align-items-center justify-content-between w-100'>
      <Button onClick={downloadExampleFileMutation} size='small' color='info'>
        Download Example File
      </Button>
      <Button autoFocus onClick={onUpload} color={'success'} size='small'>
        Upload
      </Button>
    </div>
  );

  return (
    <>
      <Button
        color='warning'
        size='small'
        onClick={() => {
          toggleCustomModal();
          setErrors([]);
          setFiles([]);
        }}
        disabled={disabled}
        sx={{ ml: 1 }}
      >
        Upload Applications (Excel file)
      </Button>
      <CustomModal
        modalTitle='Upload Applications'
        modalSize='sm'
        toggleCustomModal={toggleCustomModal}
        isOpen={open}
        addStyles={{ PaperStyle: { overflowY: 'scroll' } }}
        actionButtons={renderActionButtons}
      >
        <Grid container>
          {preparedErrorsDisplay}
          <Grid item sm={12} md={12} sx={{ mb: 2 }}>
            <StyledBox {...getRootProps()}>
              <Typography>{isDragActive ? 'Drop me here!' : `Drag 'n' drop or click to select files`}</Typography>
            </StyledBox>
          </Grid>
          {preparedFilesDisplay}
        </Grid>
      </CustomModal>
    </>
  );
};

export default UploadApplications;
