import { Fragment, useMemo } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { CustomSelect } from '../../../../common/CustomSelect';
import { CustomPageableAsyncSelect } from '../../../../common/Inputs/CustomPageableAsyncSelect';
import { useAsyncPageable } from '../../../../hooks/useAsyncPageable';
import { accountTypeOptions, ACC_TYPE_CUSTOMER, ACC_TYPE_GL_ACCOUNT, ACC_TYPE_VENDOR } from '../../../../constants/accountType.constants';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

export default function LineInfoFilter({ setFilters, filters, onSearchClick }) {
  const { fetchOptions } = useAsyncPageable();

  const selectHandler = (val, e) => {
    setFilters((prev) => ({ ...prev, [e.name]: val ? val.value : null }));
  };

  const loadAccountNameOptions = async (inputValue, options) => {
    const data = await fetchOptions(`/me/bank/payment-wizard/deposit-items-accounts-for-select/${filters.account_type}`, {
      q: inputValue,
      ...options,
      account_type: filters.account_type,
      deposit_id: filters.deposit_id,
    });

    return data;
  };

  const handleChangeButtonGroup = (e) => {
    const { value } = e.target;
    if (value === 'OFF') {
      setFilters((prev) => ({ ...prev, unapliedFirst: value, sort_by: 'account.number', unapliedFirst: 'OFF' }));
    } else {
      setFilters((prev) => ({ ...prev, unapliedFirst: value, sort_by: 'SUM(bpwci.amount)', order_by: 'DESC' }));
    }
  };

  const accountNameRender = useMemo(() => {
    return (
      <>
        {filters.account_type === ACC_TYPE_CUSTOMER && (
          <CustomPageableAsyncSelect
            label='Account Name'
            isClearable
            onChange={selectHandler}
            loadOptions={loadAccountNameOptions}
            name='client_id'
            defaultValue={filters['client_id'] || null}
          />
        )}
        {filters.account_type === ACC_TYPE_VENDOR && (
          <CustomPageableAsyncSelect
            label='Account Name'
            isClearable
            onChange={selectHandler}
            loadOptions={loadAccountNameOptions}
            name='client_id'
            defaultValue={filters['client_id'] || null}
          />
        )}
        {filters.account_type === ACC_TYPE_GL_ACCOUNT && (
          <CustomPageableAsyncSelect
            label='Account Name'
            isClearable
            onChange={selectHandler}
            loadOptions={loadAccountNameOptions}
            name='account_number'
            defaultValue={filters['account_number'] || null}
          />
        )}
      </>
    );
  }, [filters.account_type]);

  return (
    <Fragment>
      <Row>
        <Col md={4}>
          <CustomSelect
            onChange={(val, e) => {
              selectHandler(val, e);
              refetch;
            }}
            defaultValue={filters.account_type}
            name='account_type'
            isClearable
            label='Account Type'
            options={accountTypeOptions}
          />
        </Col>
        <Col md={4}>{accountNameRender}</Col>
        <Col md={4}>
          <h6> Show Unapplied First</h6>
          <ToggleButtonGroup color='primary' value={filters.unapliedFirst} exclusive onChange={handleChangeButtonGroup} size='small' sx={{ ml: 2 }}>
            <ToggleButton value={'OFF'} sx={{ fontWeight: 'bold' }}>
              OFF
            </ToggleButton>
            <ToggleButton value={'ON'} sx={{ fontWeight: 'bold' }}>
              ON
            </ToggleButton>
          </ToggleButtonGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12} className='d-flex justify-content-end'>
          <Button onClick={onSearchClick}>Search</Button>
        </Col>
      </Row>
    </Fragment>
  );
}
