import React from 'react';
import { Row, Col } from 'reactstrap';
import { IconButton, Button, Checkbox, DialogActions, DialogContent, Box } from '@mui/material';
import {
  ModeEditOutline,
  DeleteOutline,
  AddCircleOutline,
  CheckCircle,
  CheckCircleOutline,
  DeleteForeverOutlined,
  ClearOutlined,
} from '@mui/icons-material';
import { CustomPopup } from '../../../../common/Popup/CustomPopup';

import DataTableComponent from '../../../../component/DataTable/component/DataTableComponent';
import { CustomCard } from '../../../../common/Card/CustomCard';
import { CustomSelect } from '../../../../common/CustomSelect';
import { useGetRolesTableData, useDeleteRole } from './apiHooks';
import { AddEditComponent } from './components/AddEditComponent';
import { useCustomModalToggle } from '../../../../common/Dialog/CustomModal';
import { hasPermissionHook } from '../../../../hooks/hasPermission';
import { EDIT, ROLES } from '../../../../constants/permissions.constants';

export const RoleTable = () => {
  const { hasPermission } = hasPermissionHook();
  const { open, toggleCustomModal } = useCustomModalToggle();
  const [modalData, setModalData] = React.useState({
    id: null,
    action: null,
  });
  const blankData = React.useMemo(() => {
    return { data: [], page: 0, pageSize: 100, pageTotal: 0 };
  }, []);
  const [filter, setFilter] = React.useState({
    sort_by: 'name',
    order_by: 'ASC',
    page: 0,
    pageSize: 100,
  });
  const [deleteData, setDeleteData] = React.useState({
    deleteAnchor: null,
  });

  const { data: tableData, isLoading } = useGetRolesTableData(filter);
  const { mutate: deleteMutation } = useDeleteRole(filter);

  const onFilterChange = (input) => {
    setFilter((oldFilter) => ({ ...oldFilter, ...input }));
  };

  const onDeleteItem = (e, rowData) => {
    setDeleteData({
      deleteAnchor: e.currentTarget,
      openDeletePopup: true,
      roleId: rowData.id,
    });
  };

  const toggleModal = ({ action, id }) => {
    setModalData({ action, id });
    toggleCustomModal();
  };

  const columns = React.useMemo(() => {
    return [
      {
        accessor: 'name',
        id: 'name',
        Header: 'Name',
        Cell: (row) => row.value,
        sort: true,
      },
      {
        filterable: false,
        sortable: false,
        accessor: (row) => {
          return (
            <>
              <IconButton color='primary' onClick={() => toggleModal({ action: 'update', id: row.id })} disabled={!hasPermission(ROLES, EDIT)}>
                <ModeEditOutline />
              </IconButton>
              <IconButton color='error' onClick={(e) => onDeleteItem(e, row)} disabled={!hasPermission(ROLES, EDIT)}>
                <DeleteOutline />
              </IconButton>
            </>
          );
        },
        id: 'btnAction',
        Header: 'Actions',
        minWidth: 150,
        maxWidth: 150,
      },
    ];
  }, [tableData?.data]);

  const handleConfirmDeleteRoleClick = () => {
    deleteMutation(deleteData.roleId);
    setDeleteData({ deleteAnchor: null });
  };

  return (
    <>
      <CustomCard headerTitle='Roles' sxCardContainer={{ mt: 2 }}>
        <Row className='mb-3'>
          <Col md='12' className='d-flex align-items-end justify-content-end'>
            <Button
              variant='contained'
              endIcon={<AddCircleOutline />}
              color='success'
              size='small'
              onClick={() => toggleModal({ action: 'create', id: null })}
              disabled={!hasPermission(ROLES, EDIT)}
            >
              Add New Role
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <DataTableComponent dataSource={tableData?.data || blankData} onFilterChange={onFilterChange} columns={columns} loading={isLoading} />
          </Col>
        </Row>
      </CustomCard>
      <CustomPopup
        anchor={deleteData.deleteAnchor}
        content={
          <DeleteUserPopupContent onConfirm={handleConfirmDeleteRoleClick} onCancel={() => setDeleteData((prev) => ({ deleteAnchor: null }))} />
        }
        arrow
        placement='bottom'
        onClose={() => setDeleteData((prev) => ({ deleteAnchor: null }))}
        open={deleteData.openDeletePopup}
      />
      <AddEditComponent open={open} action={modalData.action} id={modalData.id} toggleCustomModal={toggleCustomModal} />
    </>
  );
};

const DeleteUserPopupContent = ({ onConfirm, onCancel, message = `Are you sure you want to delete this role?` }) => {
  return (
    <>
      <Box>
        <DialogContent sx={{ padding: '5px' }}>{message}</DialogContent>
        <DialogActions sx={{ padding: '5px' }}>
          <IconButton color='error' onClick={onConfirm}>
            <DeleteForeverOutlined />
          </IconButton>
          <IconButton onClick={onCancel}>
            <ClearOutlined />
          </IconButton>
        </DialogActions>
      </Box>
    </>
  );
};
