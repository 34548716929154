import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Check, Remove } from '@mui/icons-material';
import { formatDateForTable } from '../../../../utils/formatDataForTable';
import { formatAmount } from '../../../../utils/formatAmout';

export const FixedAssetsTableColumns = () => {
  return [
    // {
    //   accessor: 'id',
    //   Header: 'ID',
    //   maxWidth: 70,
    //   minWidth: 70,
    //   Cell: (row) => <Link to={`fixed-assets/${row?.value}`}>{row?.value}</Link>,
    // },
    {
      accessor: 'asset_type',
      Header: 'Asset Type',
      Cell: (row) => {
        return row?.value;
      },
    },
    {
      accessor: 'unit_number',
      Header: 'Unit #',
      Cell: (row) => {
        return <Link to={`fixed-assets/${row?.original?.id}/transactions`}>{row?.value}</Link>;
      },
    },
    {
      accessor: 'description',
      Header: 'Description',
      Cell: (row) => row?.value,
    },
    {
      accessor: 'branch_name',
      Header: 'Branch',
      Cell: (row) => row?.value,
    },
    {
      accessor: 'active',
      Header: 'Active',
      Cell: (row) => <BooleanBody row={row} />,
    },
    {
      accessor: 'on_rent',
      Header: 'On Rent',
      Cell: (row) => <BooleanBody row={row} />,
    },
    {
      accessor: 'disposed',
      Header: 'Disposed',
      Cell: (row) => <BooleanBody row={row} />,
    },
    {
      accessor: 'purchase_date',
      Header: 'Purchase Date',
      Cell: (row) => row?.value,
    },
    {
      accessor: 'book_value',
      Header: 'Book Value',
      Cell: (row) => row?.value,
    },
    {
      accessor: (d) => d['client.name'],
      id: 'client.name',
      Header: 'Vendor',
      Cell: (row) => row?.value,
    },
    {
      accessor: 'lien_holder',
      Header: 'Lien Holder',
      Cell: (row) => row?.value,
    },
  ];
};

const BooleanBody = ({ row }) => {
  if (!row) {
    return '';
  }

  if (row.value) {
    return <Check color='success' />;
  } else {
    return <Remove color='error' />;
  }
};
