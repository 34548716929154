import moment from 'moment';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { notificationSuccess } from '../../../../utils/toastify';
import { customDownloader } from '../../../../utils/downloaderFiles';

const getRolesFroSelect = () => {
  return axios.get(`/me/repository/roles/for-select`);
};

export const useGetRolesFroSelect = () => {
  return useQuery(['roles-for-select'], () => getRolesFroSelect(), {
    refetchOnWindowFocus: false,
  });
};

const updateRole = (data) => {
  return axios.patch(`/me/repository/roles`, data);
};

export const useUpdateRole = (callback) => {
  const queryClient = useQueryClient();
  return useMutation(updateRole, {
    onSuccess: (data) => {
      notificationSuccess('Successfully updated Role!');
      queryClient.invalidateQueries(['role-table']);
      callback && callback(data);
    },
  });
};

const getRolesTableData = (filter) => {
  return axios.get('/me/repository/roles', { params: filter });
};

export const useGetRolesTableData = (filter) => {
  return useQuery(['role-table', filter.page, filter.pageSize, filter.sort_by, filter.order_by], () => getRolesTableData(filter), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const getRole = (id) => {
  return axios.get(`/me/repository/roles/${id}`);
};

export const useGetRole = (id) => {
  return useQuery(['role', id], () => getRole(id), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
};

const createRole = (data) => {
  return axios.post('/me/repository/roles', data);
};

export const useCreateRole = (callback) => {
  const queryClient = useQueryClient();
  return useMutation(createRole, {
    onSuccess: (data) => {
      notificationSuccess('Successfully created Role!');
      queryClient.invalidateQueries(['role-table']);
      callback && callback(data);
    },
  });
};

const deleteRole = (roleId) => {
  return axios.delete(`/me/repository/roles/${roleId}`);
};

export const useDeleteRole = (callback) => {
  const queryClient = useQueryClient();
  return useMutation(deleteRole, {
    onSuccess: (data) => {
      notificationSuccess('Successfully deleted Role!');
      queryClient.invalidateQueries(['role-table']);
      callback && callback(data);
    },
  });
};
