import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

import { FixedAssetsInfo } from './components/FixedAssetsInfo';
import { Navbar } from './components/Navbar';
import { TransactionsFixedAssets } from './transactionTables/TransactionsFixedAssets';
import { CreditMemoFixedAssets } from './transactionTables/CreditMemoFixedAssets';
import { DebitMemoFixedAssets } from './transactionTables/DebitMemoFixedAssets';
import { InvoicesFixedAssets } from './transactionTables/InvoicesFixedAssets';
import { PaymentsFixedAssets } from './transactionTables/PaymentsFixedAssets';
import { Button } from '@mui/material';
import { grey } from '@mui/material/colors';

const FixedAssetsMain = ({ match }) => {
  const history = useHistory();
  return (
    <div className='mt-2'>
      <Button
        size='small'
        sx={{ mb: 1, backgroundColor: grey[800], '&:hover': { backgroundColor: grey[700] } }}
        onClick={() => {
          history.push('/fixed-assets');
        }}
      >
        <i className='fas fa-angle-left mr-2'></i>Go Back To Fixed Assets Table
      </Button>
      <FixedAssetsInfo />
      {/* <Navbar /> */}
      {/* <Switch> */}
      {/* <Route path={`${match.url}/transactions`} render={(props) => <TransactionsFixedAssets {...props} />} /> */}
      {/* <Route path={`${match.url}/invoice`} render={(props) => <InvoicesFixedAssets {...props} />} />
        <Route path={`${match.url}/payment`} render={(props) => <PaymentsFixedAssets {...props} />} />
        <Route path={`${match.url}/credit-memo`} render={(props) => <CreditMemoFixedAssets {...props} />} />
        <Route path={`${match.url}/debit-memo`} render={(props) => <DebitMemoFixedAssets {...props} />} /> */}
      {/* </Switch> */}
    </div>
  );
};

export default FixedAssetsMain;
