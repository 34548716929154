import { Button, Divider, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { SortableMultiSelect } from '../../../../../common/Inputs/SortableMultiSelect';
import { notificationDanger } from '../../../../../utils/toastify';
import { useCustomClientExport, useExportOptions } from '../../clientHooks';

export const ClientExportForm = ({ filters = {}, closeModal }) => {
  const { clientType } = useParams();
  const { data: { data: exportOptions = [] } = {} } = useExportOptions(clientType);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const { mutate: exportClients, isSuccess } = useCustomClientExport(clientType, filters);

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  const handleExcelExport = () => {
    if (!selectedOptions.length) {
      notificationDanger('No Columns Selected');
      return;
    }

    const selected = selectedOptions.map((option) => option.value);
    exportClients(selected);
  };

  return (
    <>
      <Row style={{ height: '450px' }}>
        <Col>
          <SortableMultiSelect
            overlayModal
            label={
              <>
                Columns For Export
                <Tooltip title='Please select columns to be exported. Reorder selected columns if needed.' placement='top' arrow followCursor>
                  <i className='fas fa-info-circle ml-2' />
                </Tooltip>
              </>
            }
            name='columns'
            options={exportOptions || []}
            onChange={handleSelectChange}
            selectedOptions={selectedOptions}
          />
        </Col>
      </Row>
      <Divider className='mt-3 mb-3' />
      <Row>
        <Col className='d-flex justify-content-end'>
          <Button onClick={handleExcelExport}>Export Excel</Button>
        </Col>
      </Row>
    </>
  );
};
