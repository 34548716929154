import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { CustomButton } from '../../../../common/Button/CustomButton';
import { CollapsableCard } from '../../../../common/Card/CollapsableCard';
import { CustomCard } from '../../../../common/Card/CustomCard';
import { CustomDialog } from '../../../../common/Dialog/CustomDialog';
import ExportButton from '../../../../component/Buttons/ExportButton';
import UploadButton from '../../../../component/Buttons/UploadButton';
import { downloaderFiles } from '../../../../utils/downloaderFiles';
import { useGetClients } from '../clientHooks';
import ClientCreateForm from '../component/ClientCreateForm';
import { clientTypeName } from '../utils';
import { ClientExportForm } from './component/ClientExportForm';
import ClientListDataTable from './component/ClientListDataTable';
import { Filters } from './component/ClientListFilter';
import { onCsvFileRead, onExcelRead } from './readFileUtils';
import { CUSTOMERS, EDIT } from '../../../../constants/permissions.constants';
import { hasPermissionHook } from '../../../../hooks/hasPermission';

export default function ClientList() {
  const { hasPermission } = hasPermissionHook();
  const { clientType } = useParams();
  const history = useHistory();

  const blankFilter = useMemo(() => {
    return {
      start: '',
      end: '',
      page: 0,
      pageSize: 10,
    };
  }, []);

  const blankData = useMemo(() => {
    return { data: [], page: 0, pageSize: 10, pageTotal: 0 };
  }, []);

  const [filter, setFilter] = useState(blankFilter);
  const [loader, setLoader] = useState(false);
  const [createForm, setCreateForm] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);

  const inputFile = useRef(null);

  const { data: clientsTableData, isLoading, refetch: fetchClients } = useGetClients(clientType, filter);

  const url = useMemo(() => '/me/client/' + clientType.toLowerCase().slice(0, -1) + '/with-balance', [clientType]);

  useEffect(() => {
    setFilter(blankFilter);
  }, [clientType]);

  useEffect(() => {
    // refetch on client type change after filter reset
    if (JSON.stringify(blankFilter) === JSON.stringify(filter)) {
      fetchClients();
    }
  }, [clientType, filter]);

  useEffect(() => {
    setCreateForm(false);
    setLoader(isLoading);
  }, [clientType, filter, url, blankData, isLoading]);

  const onFilterChange = (input) => {
    const f = { ...filter, ...input };
    setFilter(f);
  };

  const handleChange = (e) => {
    try {
      if (e.target.value.constructor === {}.constructor) {
        setFilter((prev) => ({ ...prev, ...e.target.value }));
      } else {
        setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      }
    } catch (error) {
      setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const handleSelectChange = (val, e) => {
    if (e.action === 'select-option') {
      let o = { target: { name: e.name, value: val.value || val.id } };
      handleChange(o);
      return;
    }
    if (e.action === 'clear') {
      let o = { target: { name: e.name, value: null } };
      handleChange(o);
    }
  };

  const handleButtonGroupChange = (e) => {
    const { name, value } = e.target;
    const newFilters = { ...filter };
    if (!value) {
      newFilters[name] = undefined;
    } else if (value === 'true') {
      newFilters[name] = true;
    } else if (value === 'false') {
      newFilters[name] = false;
    }

    setFilter((prev) => ({ ...prev, ...newFilters }));
  };

  const onFilterActionBtn = (e) => {
    if (e.toString() === 'PDF' || e.toString() === 'EXCEL') {
      downloaderFiles({ filter, type: e, url, cbLoad: setLoader, name: 'Client-List' });
    }
    if (e.toString() === 'reset') setFilter(blankFilter);
    if (e.toString() === 'add') setCreateForm(!createForm);
    if (e.toString() === 'addExcel') openUploadExcelDialog();
  };

  const openUploadExcelDialog = () => {
    inputFile.current.click();
  };

  const onSelectFile = (e) => {
    if (!e.target.files[0]) return 0;
    let fileType = e.target.files[0].type;
    const fileReader = new FileReader();
    if (fileType.toString().toUpperCase() === 'TEXT/CSV') {
      fileReader.onload = onCsvFileRead;
      fileReader.readAsText(e.target.files[0]);
    } else {
      fileReader.onload = onExcelRead;
      fileReader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const handleExportModalOpen = () => {
    setExportModalOpen(true);
  };
  const handleExportModalClose = () => {
    setExportModalOpen(false);
  };

  return (
    <>
      <CustomDialog
        fullWidth
        isOpen={exportModalOpen}
        hideActions
        hideTitleButton
        disableBackdropClick
        disableEscapeKeyDown
        buttonSx={{ backgroundColor: 'transparent' }}
        modalSize={'md'}
        modalTitle={`Export ${clientTypeName(clientType)}`}
        contentStyle={{ overflowY: 'visible' }}
        onClose={handleExportModalClose}
      >
        <Row>
          <Col>
            <ClientExportForm closeModal={handleExportModalClose} filters={filter} />
          </Col>
        </Row>
      </CustomDialog>

      <input
        onChange={onSelectFile}
        type='file'
        id='file'
        accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
        ref={inputFile}
        style={{ display: 'none' }}
      />
      <Row className='mt-2'>
        <Col md={12}>
          <CustomCard
            sxCardContainer={{ mt: 2 }}
            headerTitle={
              <Row className='d-flex align-items-center justify-content-between'>
                <Col>
                  <span>{clientType.toUpperCase()}</span>
                </Col>
                <Col className='d-flex align-items-center justify-content-end'>
                  <ClientCreateForm
                    filter={filter}
                    clientType={clientType}
                    open={createForm}
                    onToggle={(e) => {
                      e.preventDefault();
                      setCreateForm(!createForm);
                    }}
                  />

                  <UploadButton
                    style={{ marginRight: '10px' }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.target.blur();
                      onFilterActionBtn('addExcel');
                    }}
                    disabled={!hasPermission(CUSTOMERS, EDIT)}
                  />
                  <Row>
                    <Col xs={12}>
                      <Button onClick={handleExportModalOpen}>
                        <span>
                          <i className='fas fa-file-export' />
                          Export
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            }
          >
            <Row className='mb-3'>
              <Col>
                <CollapsableCard
                  className='mb-3'
                  headerTitle='Filters'
                  isExpanded={false}
                  titleTypographyProps={{ fontSize: '18px' }}
                  sxCardContent={{ padding: '16px' }}
                  sxCardHeader={{ padding: '16px' }}
                >
                  <Filters
                    filters={filter}
                    onChange={handleChange}
                    onSelectChange={handleSelectChange}
                    onButtonGroupChange={handleButtonGroupChange}
                    onSearch={fetchClients}
                  />
                </CollapsableCard>
              </Col>
            </Row>

            <div className='mb-3'>
              <ClientListDataTable
                dataSource={clientsTableData?.data || blankData}
                loader={loader}
                onFilterChange={onFilterChange}
                getTrGroupProps={(_, rowInfo) => {
                  if (rowInfo)
                    return {
                      onClick: (e) => {
                        e.preventDefault();
                        history.push(`/client/${clientType}/360/${rowInfo.original.id}/`);
                      },
                    };
                }}
              />
            </div>
          </CustomCard>
        </Col>
      </Row>
    </>
  );
}
