import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactTable from 'react-table';
import DataTablePagination from '../../../../../../component/DataTable/component/DataTablePagination';
import { DataTableShadowComponent } from '../../../../../../component/DataTable/component/DataTableShadowComponent';
import { clientTypeNameSingular } from '../../../utils';
import { tableColumns } from './agingColumns';

export default function AgingDataTable({ dataSource, loader = false, onFilterChange, onColumnClick, columns }) {
  const { clientType } = useParams();
  const [selectedRow, setSelectedRow] = useState(-1);

  const balanceColumns = useMemo(() => {
    return ['debit_upto30', 'debit_upto60', 'debit_upto90', 'debit_over90', 'total_balance'];
  }, []);

  const onFilterPageChange = (e) => {
    const f = { page: e };
    onFilterChange(f);
  };

  const onFilterPageSizeChange = (e) => {
    const f = { pageSize: e };
    onFilterChange(f);
  };

  const onSortChange = (e) => {
    const column = e[0].id;
    const method = e[0].desc ? 'DESC' : 'ASC';
    const f = { sort_by: column, order_by: method };
    onFilterChange(f);
  };

  return (
    <>
      <ReactTable
        PaginationComponent={DataTablePagination}
        TableComponent={DataTableShadowComponent}
        className='-striped -highlight '
        getFooterProps={() => ({ style: { display: 'block' } })}
        defaultSortDesc={true}
        data={dataSource.data}
        columns={columns}
        page={dataSource.page}
        pageSize={dataSource.pageSize}
        pages={dataSource.pageTotal}
        minRows={5}
        defaultPageSize={10}
        showPageSizeOptions={true}
        NoDataComponent={() => null}
        manual
        onPageSizeChange={onFilterPageSizeChange}
        onPageChange={onFilterPageChange}
        onSortedChange={onSortChange}
        getTdProps={(_, rowInfo, column) => {
          return {
            onClick: (e) => {
              const columnHeader = column.Header?.props?.children || '';
              const filterName = column.filterName;
              const isAmountColumn = balanceColumns.includes(filterName);

              const id = column.id !== 'client.name' ? column.id : null;
              const isClientNameColumn = column.id === 'client.name';
              onColumnClick(rowInfo, {
                age: isAmountColumn ? filterName : null,
                header: columnHeader,
                id,
                isClientNameColumn,
                isAmountColumn,
              });
            },
          };
        }}
        getTrGroupProps={(_, rowInfo) => {
          if (rowInfo !== undefined) {
            return {
              onClick: (e) => {
                e.preventDefault();
                setSelectedRow(rowInfo.original['client.id']);
              },
              className: rowInfo.original['client.id'] === selectedRow ? 'rt-tr-group bg-info' : 'rt-tr-group',

              style: {
                cursor: 'pointer',
              },
            };
          }
        }}
      />
    </>
  );
}
