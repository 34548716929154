import { Button, Divider } from '@mui/material';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { CustomSelect } from '../../../../../../../common/CustomSelect';
import { DatePickerComponent } from '../../../../../../../common/DatePicker/DatePicker';
import { CustomPageableAsyncSelect } from '../../../../../../../common/Inputs/CustomPageableAsyncSelect';
import { CustomToggleButtonGroup } from '../../../../../../../common/Inputs/CustomToggleButtonGroup';
import { PAYMENT_SOURCE } from '../../../../../../../constants/supportTables.constants';
import { useGetSupportTablesForSelect } from '../../../../../../../hooks/commonApiHooks';
import { useAsyncPageable } from '../../../../../../../hooks/useAsyncPageable';

export const Filters = ({ filters, onSelectChange, onDateChange, onButtonGroupChange, onSearch }) => {
  const { fetchOptions } = useAsyncPageable();

  const { data: { data: supportTableOptions = {} } = {} } = useGetSupportTablesForSelect({
    table_names: [PAYMENT_SOURCE],
  });
  const VendorOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/client/vendor/options-number-for-select', { q: inputValue, ...options, client_type_id: 2 });
    return data;
  };

  return (
    <>
      <Row>
        <Col md={4}>
          <CustomPageableAsyncSelect
            label='Vendor'
            defaultValue={filters.client_id}
            isClearable
            onChange={onSelectChange}
            loadOptions={VendorOptions}
            name='client_id'
          />
        </Col>
        <Col md={4}>
          <CustomSelect
            isClearable
            label='Preferred Payment Method'
            onChange={onSelectChange}
            name='preferred_payment_method'
            defaultValue={filters.preferred_payment_method || null}
            options={supportTableOptions[PAYMENT_SOURCE] || []}
          />
        </Col>
        <Col md={4}>
          <DatePickerComponent
            showYearDropdown
            isClearable
            name='transactions_due_date.due_date'
            selected={filters['transactions_due_date.due_date']}
            onChange={(date) => onDateChange(date, 'transactions_due_date.due_date')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Invoice Due Date'
          />
        </Col>
        <Col md={4}>
          <CustomToggleButtonGroup
            size='small'
            label='Payment Priority'
            name='payment_priority'
            value={filters?.payment_priority}
            onChange={onButtonGroupChange}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <Divider />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col className='d-flex justify-content-end'>
          <Button onClick={onSearch}>Search</Button>
        </Col>
      </Row>
    </>
  );
};
