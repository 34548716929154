import React, { useEffect } from 'react';

import DataGridComponent from '../../../../component/DataTable/component/DataTableComponent';
import { DepreciationItemsTableColumns } from '../tableColumns/DepreciationItemsTableColumns';
import { useGetDepreciationItemsTableData } from '../FixedAsettesApiRoutes';

export const DepreciationItemsTable = ({ fixed_asset_depreciation_id }) => {
  const [filters, setFilters] = React.useState({
    page: 0,
    pageSize: 5,
    fixed_asset_depreciation_id,
  });

  useEffect(() => {
    if (fixed_asset_depreciation_id) {
      setFilters((oldFilters) => ({ ...oldFilters, fixed_asset_depreciation_id }));
    }
  }, [fixed_asset_depreciation_id]);

  const { data: { data: tableData } = {} } = useGetDepreciationItemsTableData(filters);

  const columns = React.useMemo(() => DepreciationItemsTableColumns(), []);
  const cleanDataSource = React.useMemo(() => ({ data: [], page: 0, pageSize: 5, pageTotal: 0 }));

  const onFilterChange = (e) => {
    setFilters({ ...filters, ...e });
  };

  return <DataGridComponent columns={columns} loading={false} dataSource={tableData || cleanDataSource} onFilterChange={onFilterChange} />;
};
