import React from 'react';
import { CUSTOMER_INVOICE } from '../../../../../../../constants/documentType.constants';
import { formatAmount } from '../../../../../../../utils/formatAmout';
import { formatDateForTable } from '../../../../../../../utils/formatDataForTable';

export const paymentInfoColumns = ({ transactionType, Actions }) => {
  const columns = [
    {
      accessor: (d) => d['transactions.document_number'],
      id: 'transactions.document_number',
      Header: 'Check#',
      Cell: (row) => row.value,
    },
    {
      accessor: (d) => d['transactions.document_date'],
      id: 'transactions.document_date',
      Header: 'Document Date',
      Cell: (row) => formatDateForTable(row.value),
    },
    {
      accessor: (d) => d['tc.amount'],
      id: 'tc.amount',
      Header: 'Amount',
      Cell: (row) => formatAmount(row.value),
    },
    {
      accessor: (d) => d['payment_source.payment_type'],
      id: 'payment_source.payment_type',
      Header: 'Payment Type',
      Cell: (row) => row.value,
    },
    {
      accessor: (d) => d['tc.date_applied'],
      id: 'tc.date_applied',
      Header: 'Date Applied',
      Cell: (row) => formatDateForTable(row.value),
    },
  ];

  if (transactionType === CUSTOMER_INVOICE) {
    columns.push({
      id: 'actions',
      width: 80,
      Header: () => <div>Actions</div>,
      Cell: (row) => {
        if (Actions) {
          return <div style={{ display: 'flex', justifyContent: 'center' }}>{React.cloneElement(Actions, { row })}</div>;
        }

        return <></>;
      },
    });
  }

  return columns;
};
