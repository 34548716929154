import { formatDateForTable } from '../../../../utils/formatDataForTable';
import { formatAmount } from '../../../../utils/formatAmout';

import { PaymentsTableActions } from '../../Client/Client360/component/transaction/payment/PaymentsTableActions';
import { TransactionTableActions } from '../../Client/Client360/component/transaction/TransactionTableActions';

export const TransactionsTableColumns = () => {
  return [
    {
      accessor: (d) => d['transactions.posting_date'],
      id: 'transactions.posting_date',
      Header: <div>Posting Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      sort: true,
    },
    {
      accessor: (d) => d['document_type.name'],
      id: 'document_type.name',
      Header: <div>Type</div>,
      Cell: (row) => <div>{row.value}</div>,
      sort: true,
    },
    {
      accessor: (d) => d['client.name'],
      id: 'client.name',
      Header: <div>Client Name</div>,
      Cell: (row) => {
        const value = row.original['client.external_id'] + ' - ' + row.original['client.name'];

        let openClient = () => {};
        if (row.original['client.client_type_id'] === 1) {
          openClient = () => {
            window.open(`/client/customers/360/${row.original['client.id']}`, '_blank');
          };
        } else if (row.original['client.client_type_id'] === 2) {
          openClient = () => {
            window.open(`/client/vendors/360/${row.original['client.id']}`, '_blank');
          };
        }

        return (
          <div onClick={openClient} style={{ cursor: 'pointer' }}>
            {value}
          </div>
        );
      },
      sort: true,
    },
    {
      accessor: (d) => d['transactions.document_number'],
      id: 'transactions.document_number',
      Header: (props) => <div>Document Number</div>,
      Cell: (row) => <div>{row.value}</div>,
      sort: false,
    },

    {
      accessor: (d) => d['transactions.document_date'],
      id: 'transactions.document_date',
      Header: (props) => <div>Invoice Date</div>,
      sort: true,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },

    {
      accessor: (d) => d['transactions.amount'],
      id: 'transactions.amount',
      Header: (props) => <div>Amount</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sort: true,
    },
    {
      accessor: (d) => d['transactions_balances.balance'],
      id: 'transactions_balances.balance',
      Header: (props) => <div>Remaining Balance</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sort: true,
    },
    {
      accessor: (d) => d['transactions_due_date.due_date'],
      id: 'transactions_due_date.due_date',
      Header: (props) => <div>Invoice Due Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      sort: true,
    },
    {
      accessor: (d) => d['branch.name'],
      id: 'branch.name',
      Header: (props) => <div>Branch</div>,
      Cell: (row) => <div>{row.value}</div>,
      sort: true,
    },
    {
      accessor: (d) => d['transactions.create_at'],
      id: 'transactions.create_at',
      Header: <div>Created</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      sort: true,
    },
    {
      accessor: (d) => d['users.name'],
      id: 'users.name',
      Header: <div>Created By</div>,
      Cell: (row) => <div>{row.value}</div>,
      sort: true,
    },
    {
      id: 'actions',
      Header: (props) => <div>Actions</div>,
      Cell: (row) => {
        if (row?.original['transactions.document_type_journal_entry_id'] === 2) {
          return (
            <div>
              <PaymentsTableActions row={row} />
            </div>
          );
        } else if (row?.original['transactions.document_type_journal_entry_id'] === 1) {
          return (
            <div>
              <TransactionTableActions row={row} />
            </div>
          );
        } else {
          return <></>;
        }
      },
      sortable: false,
    },
  ];
};

export const InvoicesTableColumns = () => {
  return [
    {
      accessor: (d) => d['transactions.posting_date'],
      id: 'transactions.posting_date',
      Header: <div>Posting Date</div>,
      Cell: (row) => <div>{row.value}</div>,
      sort: true,
    },
    {
      accessor: (d) => d['transactions.document_number'],
      id: 'transactions.document_number',
      Header: (props) => <div>Document Number</div>,
      Cell: (row) => <div>{row.value}</div>,
      sort: false,
    },

    {
      accessor: (d) => d['transactions.document_date'],
      id: 'transactions.document_date',
      Header: (props) => <div>Invoice Date</div>,
      sort: true,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },

    {
      accessor: (d) => d['transactions.amount'],
      id: 'transactions.amount',
      Header: (props) => <div>Amount</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sort: true,
    },
    {
      accessor: (d) => d['transactions_balances.balance'],
      id: 'transactions_balances.balance',
      Header: (props) => <div>Remaining Balance</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sort: true,
    },
    {
      accessor: (d) => d['transactions_due_date.due_date'],
      id: 'transactions_due_date.due_date',
      Header: (props) => <div>Invoice Due Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      sort: true,
    },
    {
      accessor: (d) => d['branch.name'],
      id: 'branch.name',
      Header: (props) => <div>Branch</div>,
      Cell: (row) => <div>{row.value}</div>,
      sort: true,
    },
    {
      accessor: (d) => d['transactions.create_at'],
      id: 'transactions.create_at',
      Header: <div>Created</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      sort: true,
    },
    {
      accessor: (d) => d['users.name'],
      id: 'users.name',
      Header: <div>Created By</div>,
      Cell: (row) => <div>{row.value}</div>,
      sort: true,
    },

    // {
    //   id: 'actions',
    //   Header: (props) => <div>Actions</div>,
    //   Cell: (row) => <div>{React.cloneElement(Actions, { row })}</div>,
    //   sortable: false,
    // },
  ];
};

export const PaymentsTableColumns = () => [
  {
    accessor: (d) => d['transactions.posting_date'],
    id: 'transactions.posting_date',
    Header: <div>Posting Date</div>,
    Cell: (row) => <div>{row.value}</div>,
    sort: true,
  },
  {
    accessor: (d) => d['transactions.document_number'],
    id: 'transactions.document_number',
    Header: (props) => <div>Document Number</div>,
    Cell: (row) => <div>{row.value}</div>,
    sort: false,
  },

  {
    accessor: (d) => d['transactions.document_date'],
    id: 'transactions.document_date',
    Header: (props) => <div>Document Date</div>,
    sort: true,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
  },

  {
    accessor: (d) => d['transactions.amount'],
    id: 'transactions.amount',
    Header: (props) => <div>Amount</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sort: true,
  },
  {
    accessor: (d) => d['applied_amount'],
    id: 'applied_amount',
    Header: 'Applied Amount',
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sort: true,
  },
  {
    accessor: (d) => d['transactions_balances.balance'],
    id: 'transactions_balances.balance',
    Header: (props) => <div>Unapplied Amount</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sort: true,
  },
  {
    accessor: (d) => d['transactions.memo'],
    id: 'transactions.memo',
    Header: (props) => <div>Description</div>,
    Cell: (row) => <div>{row.value}</div>,
    sort: true,
  },
  {
    accessor: (d) => d['branch.name'],
    id: 'branch.name',
    Header: (props) => <div>Branch</div>,
    Cell: (row) => <div>{row.value}</div>,
    sort: true,
  },
  {
    accessor: (d) => d['transactions.create_at'],
    id: 'transactions.create_at',
    Header: <div>Created</div>,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    sort: true,
  },
  {
    accessor: (d) => d['users.name'],
    id: 'users.name',
    Header: <div>Created By</div>,
    Cell: (row) => <div>{row.value}</div>,
    sort: true,
  },
  // {
  //   id: 'actions',
  //   Header: (props) => <div className='d-flex justify-content-center'>Actions</div>,
  //   Cell: (row) => <div>{React.cloneElement(Actions, { row })}</div>,
  //   sortable: false,
  // },
];

export const CreditMemoTableColuns = () => [
  {
    accessor: (d) => d['transactions.posting_date'],
    id: 'transactions.posting_date',
    Header: <div>Posting Date</div>,
    Cell: (row) => <div>{row.value}</div>,
    sort: true,
  },
  {
    accessor: (d) => d['transactions.document_number'],
    id: 'transactions.document_number',
    Header: (props) => <div>Document Number</div>,
    Cell: (row) => <div>{row.value}</div>,
    sort: false,
  },

  {
    accessor: (d) => d['transactions.document_date'],
    id: 'transactions.document_date',
    Header: (props) => <div>Date</div>,
    sort: true,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
  },

  {
    accessor: (d) => d['transactions.amount'],
    id: 'transactions.amount',
    Header: (props) => <div>Amount</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sort: true,
  },
  {
    accessor: (d) => d['transactions_balances.balance'],
    id: 'transactions_balances.balance',
    Header: (props) => <div>Remaining Balance</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sort: true,
  },
  {
    accessor: (d) => d['transactions.memo'],
    id: 'transactions.memo',
    Header: (props) => <div>Description</div>,
    Cell: (row) => <div>{row.value}</div>,
    sort: true,
  },
  {
    accessor: (d) => d['branch.name'],
    id: 'branch.name',
    Header: (props) => <div>Branch</div>,
    Cell: (row) => <div>{row.value}</div>,
    sort: true,
  },
  {
    accessor: (d) => d['transactions.create_at'],
    id: 'transactions.create_at',
    Header: <div>Created</div>,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    sort: true,
  },
  {
    accessor: (d) => d['users.name'],
    id: 'users.name',
    Header: <div>Created By</div>,
    Cell: (row) => <div>{row.value}</div>,
    sort: true,
  },

  // {
  //   id: 'actions',
  //   Header: (props) => <div>Actions</div>,
  //   Cell: (row) => <div>{React.cloneElement(Actions, { row })}</div>,
  //   sortable: false,
  // },
];

export const DebitMemoTableColumns = () => [
  {
    accessor: (d) => d['transactions.document_number'],
    id: 'transactions.document_number',
    Header: (props) => <div>Document Number</div>,
    Cell: (row) => <div>{row.value}</div>,
    sort: false,
  },

  {
    accessor: (d) => d['transactions.document_date'],
    id: 'transactions.document_date',
    Header: (props) => <div>Date</div>,
    sort: true,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
  },

  {
    accessor: (d) => d['transactions.amount'],
    id: 'transactions.amount',
    Header: (props) => <div>Amount</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sort: true,
  },
  {
    accessor: (d) => d['transactions_balances.balance'],
    id: 'transactions_balances.balance',
    Header: (props) => <div>Balance</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sort: true,
  },
  {
    accessor: (d) => d['transactions.memo'],
    id: 'transactions.memo',
    Header: (props) => <div>Description</div>,
    Cell: (row) => <div>{row.value}</div>,
    sort: true,
  },
  {
    accessor: (d) => d['transactions.create_at'],
    id: 'transactions.create_at',
    Header: <div>Created</div>,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    sort: true,
  },
  {
    accessor: (d) => d['users.name'],
    id: 'users.name',
    Header: <div>Created By</div>,
    Cell: (row) => <div>{row.value}</div>,
    sort: true,
  },

  // {
  //   id: 'actions',
  //   Header: (props) => <div>Actions</div>,
  //   Cell: (row) => <div>{''}</div>,
  //   sortable: false,
  // },
];
