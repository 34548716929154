import { formatAmount } from '../../../utils/formatAmout';
import { formatDateForTable } from '../../../utils/formatDataForTable';
import React from 'react';

export const TransactionDataGridColumns = ({
  actionHandler = function (e) {
    console.log(e);
  },
  clientShow = true,
  dueDataShow = false,
  actionShow = false,
}) => {
  return [
    {
      accessor: 'customer.name',
      Header: (props) => <div className='leftHeader'>Client</div>,
      Cell: (row) => <div style={{ textAlign: 'left' }}>{row.value}</div>,
      sort: true,
      show: clientShow,
    },
    {
      accessor: 'document_type.name',
      Header: <div className='leftHeader'>Type</div>,
      id: 'transaction_document_type_id',
      Cell: (row) => <div style={{ textAlign: 'left' }}>{row.value}</div>,
      sort: true,
    },
    {
      accessor: 'transaction.document_number',
      Header: (props) => <div className='leftHeader'>Document Number</div>,
      Cell: (row) => <div style={{ textAlign: 'right' }}>{row.value}</div>,
      sort: false,
    },

    {
      accessor: 'transaction.document_date',
      Header: (props) => <div className='leftHeader'>Date</div>,
      sort: true,
      Cell: (row) => <div style={{ textAlign: 'right' }}>{formatDateForTable(row.value)}</div>,
    },

    {
      accessor: 'transactions.amount',
      Header: (props) => <div className='leftHeader'>Amount</div>,
      Cell: (row) => <div style={{ textAlign: 'right' }}>{formatAmount(row.value)}</div>,
      sort: true,
    },
    {
      accessor: 'transaction_balance.balance',
      Header: (props) => <div className='leftHeader'>Balance</div>,
      Cell: (row) => <div style={{ textAlign: 'right' }}>{formatAmount(row.value)}</div>,
      sort: true,
    },
    {
      accessor: 'transactions.memo',
      Header: (props) => <div className='leftHeader'>Description</div>,
      Cell: (row) => <div style={{ textAlign: 'right' }}>{row.value}</div>,
      sort: true,
    },
    {
      id: 'actions',
      Header: (props) => <div className='leftHeader'>Actions</div>,
      Cell: (row) => <div style={{ textAlign: 'right' }}>{''}</div>,
      sort: true,
      show: actionShow,
    },
  ];
};
