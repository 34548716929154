import { ClearOutlined, DeleteForeverOutlined } from '@mui/icons-material';
import { Box, Button, DialogActions, DialogContent, IconButton } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { CustomCard } from '../../../common/Card/CustomCard';
import { CustomPopup } from '../../../common/Popup/CustomPopup';
import ExportButton from '../../../component/Buttons/ExportButton';
import DataGridComponent from '../../../component/DataTable/component/DataTableComponent';
import { downloaderFiles } from '../../../utils/downloaderFiles';
import { exportExcel } from '../../../utils/exportExcel';
import { exportPdf } from '../../../utils/exportPdf';
import { formatDateForTable } from '../../../utils/formatDataForTable';
import { DepositTableFilters } from './components/DepositTableFilters';
import { useDeleteDeposit, useGetDepositTableData } from './depositApiRoutes';
import { depositExcelHeaders, depositTableColumns } from './tableColumns/depositTableColumns';
import { hasPermissionHook } from '../../../hooks/hasPermission';
import { DEPOSIT, EDIT } from '../../../constants/permissions.constants';

const DepositTableView = (props) => {
  const { hasPermission } = hasPermissionHook();
  const history = useHistory();
  const [filters, setFilters] = React.useState({
    finish: undefined,
    posting_date: { start: null, end: null },
    sort_by: 'id',
    order_by: 'DESC',
    pageSize: 100,
  });

  const [container, setContainer] = React.useState();
  const [deleteData, setDeleteData] = React.useState({
    deleteAnchor: null,
  });
  const { data: tableData, refetch } = useGetDepositTableData(filters);
  const { mutate: deleteDepositMutation } = useDeleteDeposit();

  const onFilterChange = (e) => {
    setFilters({ ...filters, ...e });
  };

  const onDeleteClick = (e, rowData) => {
    setDeleteData({
      deleteAnchor: e.currentTarget,
      openDeletePopup: true,
      depositId: rowData.original.id,
    });
  };

  const onFilterActionBtn = (e) => {
    if (e.toString() === 'PDF' || e.toString() === 'EXCEL') {
      downloaderFiles({ filter: filters, type: e, url: '/me/bank/payment-wizard', cbLoad: () => {}, name: 'Deposits' });
    }
  };

  const columns = React.useMemo(() => depositTableColumns({ onDeleteClick, disabled: !hasPermission(DEPOSIT, EDIT) }), []);
  const cleanDataSource = React.useMemo(() => ({ data: [], page: 0, pageSize: 10, pageTotal: 0 }));

  const handleConfirmDeleteDepositClick = () => {
    deleteDepositMutation(deleteData.depositId);
    setDeleteData({ deleteAnchor: null });
  };

  return (
    <>
      <CustomCard
        headerTitle={
          <div className='d-flex justify-content-between align-items-center'>
            Deposit
            <ExportButton
              disabled={false}
              exportTablePDF={() => {
                onFilterActionBtn('PDF');
              }}
              exportTableEXCEL={() => {
                onFilterActionBtn('EXCEL');
              }}
              exportPagePDF={() => exportPdf(container, 'Deposit')}
              exportPageEXCEL={() => {
                const normalizedData = tableData?.data?.data.map((row) => {
                  const normalized = {
                    ...row,
                    document_date: formatDateForTable(row.document_date),
                    posting_date: formatDateForTable(row.posting_date),
                    deposit_date: formatDateForTable(row.deposit_date),
                    create_at: formatDateForTable(row.create_at),
                    finish: row.finish ? 'Yes' : 'No',
                  };

                  return normalized;
                });
                exportExcel(normalizedData ?? [], depositExcelHeaders, 'Deposit');
              }}
            />
          </div>
        }
        sxCardContainer={{ mt: 2 }}
      >
        <Row className='mb-3'>
          <Col>
            <DepositTableFilters setFilters={setFilters} filters={filters} search={refetch} />
          </Col>
        </Row>
        <Row className='mb-2'>
          <Col className='d-flex justify-content-end'>
            <Button color='success' onClick={() => history.push('/deposit/add/new')} disabled={!hasPermission(DEPOSIT, EDIT)}>
              Add New Deposit
            </Button>
          </Col>
        </Row>
        <Row>
          <div
            className='col'
            ref={(instance) => {
              setContainer(instance);
            }}
          >
            <DataGridComponent columns={columns} loading={false} dataSource={tableData?.data || cleanDataSource} onFilterChange={onFilterChange} />
          </div>
        </Row>
      </CustomCard>
      <CustomPopup
        anchor={deleteData.deleteAnchor}
        content={
          <DeleteDepositPopupContent onConfirm={handleConfirmDeleteDepositClick} onCancel={() => setDeleteData((prev) => ({ deleteAnchor: null }))} />
        }
        arrow
        placement='bottom'
        onClose={() => setDeleteData((prev) => ({ deleteAnchor: null }))}
        open={deleteData.openDeletePopup}
      />
    </>
  );
};

const DeleteDepositPopupContent = ({ onConfirm, onCancel, message = `Are you sure you want to delete this deposit?` }) => {
  return (
    <>
      <Box>
        <DialogContent sx={{ padding: '5px' }}>{message}</DialogContent>
        <DialogActions sx={{ padding: '5px' }}>
          <IconButton color='error' onClick={onConfirm}>
            <DeleteForeverOutlined />
          </IconButton>
          <IconButton onClick={onCancel}>
            <ClearOutlined />
          </IconButton>
        </DialogActions>
      </Box>
    </>
  );
};

export default DepositTableView;
