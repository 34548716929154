const defaultDuration = 5000;
const success = 'success';
const error = 'error';

// email
export const emailSendSuccessCallback = ({ enqueueSnackbar, message, duration }) => {
  enqueueSnackbar(message ?? 'Email Sent', { variant: success, autoHideDuration: duration ?? defaultDuration });
};

export const emailSendErrorCallback = ({ enqueueSnackbar, message, duration }) => {
  enqueueSnackbar(message ?? 'Email sending failed!', { variant: error, autoHideDuration: duration ?? defaultDuration });
};

// pdf download
export const pdfExportErrorCallback = ({ enqueueSnackbar, message, duration }) => {
  enqueueSnackbar(message ?? 'PDF export failed!', { variant: error, autoHideDuration: duration ?? defaultDuration });
};
