import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';

export function PrivateRouter({ component: Component, ...rest }) {
  const currentLocation = rest.location.pathname;
  const auth = useSelector((state) => state.auth);
  useEffect(() => {}, []);

  if (!auth.isAuthenticated) return <Redirect to={{ pathname: '/login', state: { fromPathname: currentLocation } }} />;
  return (
    <Route
      {...rest}
      render={(props) => {
        return auth.isAuthenticated === true ? <Component {...props} /> : <Redirect to='/dashboard' />;
      }}
    />
  );
}
