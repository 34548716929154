import { Info as InfoIcon } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import PreviewIcon from '@mui/icons-material/Preview';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { Box, Button, DialogActions, DialogContent, Divider, FormControlLabel, IconButton, Radio, RadioGroup, Tooltip } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { CollapsableCard } from '../../../../../../common/Card/CollapsableCard';
import { DatePickerComponent } from '../../../../../../common/DatePicker/DatePicker';
import { CustomDialog } from '../../../../../../common/Dialog/CustomDialog';
import { CustomCheckbox } from '../../../../../../common/Inputs/CustomCheckbox';
import { CustomPageableAsyncSelect } from '../../../../../../common/Inputs/CustomPageableAsyncSelect';
import { CustomToggleButtonGroup } from '../../../../../../common/Inputs/CustomToggleButtonGroup';
import { CustomPopup } from '../../../../../../common/Popup/CustomPopup';
import DataGridComponent from '../../../../../../component/DataTable/component/DataTableComponent';
import { useAsyncPageable } from '../../../../../../hooks/useAsyncPageable';
import { downloaderFiles } from '../../../../../../utils/downloaderFiles';
import { columns } from '../../columns/checkColumns';
import { composeBaseUrl, useBankChecksDownloader, useBillingPayment, useBillingPaymentChecks } from '../../hooks';
import { BankChecksPreview } from './BankChecksPreview';
import { CheckPdfPreview } from './CheckPdfPreview';
import { PrintCheckForm } from './PrintCheckForm';
import { VoidCheckForm } from './VoidCheckForm';
import { notificationDangerCustomTime } from '../../../../../../utils/toastify';
import { hasPermissionHook } from '../../../../../../hooks/hasPermission';

const PRINT = 'print';
const REPRINT = 'reprint';
const selectChecksType = [
  { name: 'Print', value: PRINT },
  { name: 'Reprint', value: REPRINT },
];

export const BillingPaymentChecks = ({ billingChecks = false }) => {
  const { clientType, billingId } = useParams();
  const { isPostingDisabled } = hasPermissionHook();

  const blankData = useMemo(() => {
    return { data: [], page: 0, pageSize: 10, pageTotal: 0 };
  }, []);

  //
  const blankFilter = useMemo(() => {
    return {
      printed: null,
      voided: null,
      posted: null,
      start: '',
      end: '',
      sort_by: 'billing_payment_checks.id',
      order_by: 'DESC',
      page: 0,
      pageSize: 10,
      billingChecks,
      billingId,
      posting_date: null,
    };
  }, [billingId, billingChecks]);

  const blankPopupOptions = useMemo(() => {
    return { anchor: null, open: false };
  }, []);

  const blankModalOptions = useMemo(() => {
    return { open: false };
  }, []);

  const url = useMemo(() => `${composeBaseUrl(clientType)}/checks`, [clientType]);

  const [filter, setFilter] = useState(blankFilter);
  const [container, setContainer] = useState(null);
  const [popupOptions, setPopupOptions] = useState({ bankChecks: blankPopupOptions });
  const [modalOptions, setModalOptions] = useState({
    preview: blankModalOptions,
    printOne: blankModalOptions,
    void: blankModalOptions,
    printMany: blankModalOptions,
  });
  const [selectedCheckId, setSelectedCheckId] = useState(null);
  const [selectedChecks, setSelectedChecks] = useState([]);
  const [selectChecksFor, setSelectChecksFor] = useState(PRINT);
  const [voidCheckTitle, setVoidCheckTitle] = useState('Void Check');
  const [previewCheckTitle, setPreviewCheckTitle] = useState('Preview Check');

  const { data: checksData, refetch } = useBillingPaymentChecks(clientType, filter);
  const { data: { data: billingPayment = {} } = {} } = useBillingPayment(clientType, billingId);

  const { mutate: downloadBankChecks } = useBankChecksDownloader({
    clientType,
    filters: { ...filter, posted: true },
  });

  useEffect(() => {
    setSelectedChecks([]);
  }, [selectChecksFor]);

  const { fetchOptions } = useAsyncPageable();

  const loadOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/client/customer/options-for-select/', { q: inputValue, ...options });
    return data;
  };

  const handleTableFilterChange = (input) => {
    setFilter((prev) => ({ ...prev, ...input }));
  };

  const onFilterActionBtn = (e) => {
    if (e.toString() === 'PDF' || e.toString() === 'EXCEL') {
      downloaderFiles({ filter, type: e, url, cbLoad: () => {}, name: 'Payment Checks' });
    }
  };

  const handleChange = (e) => {
    try {
      if (e.target.value.constructor === {}.constructor) {
        setFilter((prev) => ({ ...prev, ...e.target.value }));
      } else {
        setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      }
    } catch (error) {
      setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const handleSelectChange = (val, e) => {
    if (e.action === 'select-option') {
      let o = { target: { name: e.name, value: val.value } };
      handleChange(o);
      return;
    }
    if (e.action === 'clear') {
      let o = { target: { name: e.name, value: null } };
      handleChange(o);
    }
  };

  const handleChangeButtonGroup = (e) => {
    const { name, value } = e.target;
    const newFilters = { ...filter };
    if (!value) {
      newFilters[name] = undefined;
    } else if (value === 'true') {
      newFilters[name] = true;
    } else if (value === 'false') {
      newFilters[name] = false;
    }

    setFilter((prev) => ({ ...prev, ...newFilters }));
  };

  const handleDateChange = (date, name) => {
    setFilter((oldData) => ({ ...oldData, [name]: date }));
  };

  const handleSearch = () => {
    refetch();
  };

  const handleModalOptionsChange = (modal, options = {}) => {
    setModalOptions((p) => ({
      ...p,
      [modal]: {
        ...p[modal],
        ...options,
      },
    }));
  };

  const handleModalClose = (modal) => {
    handleModalOptionsChange(modal, { open: false });
  };

  const handlePreviewCheckClick = (row) => {
    const checkId = row['billing_payment_checks.id'];
    const checkNumber = row['billing_payment_checks.check_number'];

    setSelectedCheckId(checkId);
    setPreviewCheckTitle(`Preview Check  ${checkNumber ? `#${checkNumber}` : ''}`);
    handleModalOptionsChange('preview', { open: true });
  };

  const handlePrintCheckClick = (row) => {
    if (isPostingDisabled([row['billing_payment_checks.check_date']])) {
      notificationDangerCustomTime('You are not allowed to post in this period. Please contact your superior for more info!', 10000);
      return;
    }

    const checkId = row['billing_payment_checks.id'];
    setSelectedCheckId(checkId);
    handleModalOptionsChange('printOne', { open: true });
  };

  const handlePrintManyChecksClick = () => {
    const datesArray = checksData?.data?.data.map((item) => item['billing_payment_checks.check_date']);

    if (isPostingDisabled(datesArray)) {
      notificationDangerCustomTime('You are not allowed to post in this period. Please contact your superior for more info!', 10000);
      return;
    }
    handleModalOptionsChange('printMany', { open: true });
  };

  const handlePrintManyChecksModalClose = () => {
    handleModalOptionsChange('printMany', { open: false });
    setSelectedChecks([]);
  };

  const handleVoidCheckClick = (row) => {
    const checkId = row['billing_payment_checks.id'];
    setVoidCheckTitle(`Void Check #${row['billing_payment_checks.check_number']}`);

    setSelectedCheckId(checkId);
    handleModalOptionsChange('void', { open: true });
  };

  const handleCheckSelect = (row, selectMultiple) => {
    if (!selectMultiple) {
      const checkId = row.original['billing_payment_checks.id'];
      const uniqueSelectedChecks = new Set(selectedChecks);

      const isDisabled = isRowDisabled(row.original, selectChecksFor === PRINT);

      if (checkId) {
        if (!isDisabled) {
          if (uniqueSelectedChecks.has(checkId)) {
            uniqueSelectedChecks.delete(checkId);
          } else {
            uniqueSelectedChecks.add(checkId);
          }

          setSelectedChecks(Array.from(uniqueSelectedChecks));
        }
      }
    } else {
      if (checksData?.data?.data) {
        const selectableFromPage = checksData.data.data.reduce((count, check) => {
          const isDisabled = isRowDisabled(check, selectChecksFor === PRINT);

          if (!isDisabled) {
            count += 1;
          }
          return count;
        }, 0);

        const selectedFromPage = checksData.data.data.reduce((count, check) => {
          const checkId = check['billing_payment_checks.id'];
          if (selectedChecks.includes(checkId)) {
            count += 1;
          }

          return count;
        }, 0);

        const uniqueSelectedChecks = new Set(selectedChecks);

        checksData.data.data.map((check) => {
          const isDisabled = isRowDisabled(check, selectChecksFor === PRINT);

          if (!isDisabled) {
            const checkId = check['billing_payment_checks.id'];
            // select unselected from page => select whole page
            if (selectableFromPage !== selectedFromPage) {
              if (!uniqueSelectedChecks.has(checkId)) {
                uniqueSelectedChecks.add(checkId);
              }
            } else {
              // select/remove all
              if (uniqueSelectedChecks.has(checkId)) {
                uniqueSelectedChecks.delete(checkId);
              } else {
                uniqueSelectedChecks.add(checkId);
              }
            }
          }
        });

        setSelectedChecks(Array.from(uniqueSelectedChecks));
      }
    }
  };

  const triggerBankCheckPopup = (e, open) => {
    setPopupOptions((p) => ({
      ...p,
      bankChecks: {
        ...p.bankChecks,
        open,
        anchor: e?.currentTarget,
      },
    }));
  };

  const handleBankCheckPopupOpen = (e) => {
    triggerBankCheckPopup(e, true);
  };

  const handleBankCheckPopupClose = (e) => {
    triggerBankCheckPopup(e, false);
  };

  const handleBankChecksDownloadClick = () => {
    downloadBankChecks();
  };

  const isRowDisabled = (check, isForPrint) => {
    let isDisabled = false;

    if (isForPrint) {
      isDisabled = check['billing_payment_checks.posted'] || check['billing_payment_checks.printed'];
    } else {
      isDisabled = check['billing_payment_checks.posted'] || !check['billing_payment_checks.printed'];
    }

    return isDisabled;
  };

  const checksHeaderTitle = useMemo(() => {
    return (
      <div className='d-flex justify-content-between align-items-center'>
        <span style={{ fontSize: '18px' }}>Checks</span>
        <RadioGroup
          sx={{ display: 'flex', flexDirection: 'row' }}
          aria-labelledby={`controlled-radio-buttons-group-checks-select`}
          name={'selectChecksFor'}
          value={selectChecksFor}
          selected={selectChecksFor}
          onChange={(e) => {
            setSelectChecksFor(e.target.value);
          }}
        >
          {selectChecksType.map((option) => (
            <FormControlLabel key={`${option.name}`} control={<Radio />} label={option.name} value={option.value} />
          ))}
        </RadioGroup>

        <span className='d-flex'>
          <Button sx={{ minWidth: '100px' }} disabled={selectedChecks.length === 0} className='mr-2' onClick={handlePrintManyChecksClick}>
            {selectChecksFor === PRINT ? 'Print' : 'Reprint'}
          </Button>
        </span>
        <span>
          <Tooltip title='Export Table To EXCEL' arrow>
            <IconButton onClick={() => onFilterActionBtn('EXCEL')}>
              <i className='fas fa-file-excel' style={{ color: '#ceac02', fontSize: '1.2rem', width: '20px', height: '20px' }} />
            </IconButton>
          </Tooltip>
          {billingPayment.finished && (
            <IconButton color='primary' onClick={handleBankCheckPopupOpen}>
              <RequestQuoteIcon />
            </IconButton>
          )}
        </span>
      </div>
    );
  }, [checksData?.data?.data, selectedChecks, selectChecksFor]);

  return (
    <>
      <CustomPopup
        overlayModal
        anchor={popupOptions.bankChecks.anchor}
        content={
          <MemoizedBankCheckPopupContent
            filters={{ ...filter, posted: true }}
            overlayModal
            onCancel={handleBankCheckPopupClose}
            onDownload={handleBankChecksDownloadClick}
          />
        }
        arrow
        placement='bottom'
        onClose={handleBankCheckPopupClose}
        open={popupOptions.bankChecks.open}
      />

      <CustomDialog
        fullWidth
        isOpen={modalOptions.printMany.open}
        hideActions
        hideTitleButton
        disableBackdropClick
        disableEscapeKeyDown
        buttonSx={{ backgroundColor: 'transparent' }}
        modalSize={'sm'}
        modalTitle={selectChecksFor === PRINT ? 'Print Checks' : 'Reprint Checks'}
        onClose={handlePrintManyChecksModalClose}
      >
        <Row>
          <Col>
            <PrintCheckForm
              clientType={clientType}
              multiplePrint
              selectedCheckIds={selectedChecks}
              closeModal={handlePrintManyChecksModalClose}
              isPrint={selectChecksFor === PRINT}
            />
          </Col>
        </Row>
      </CustomDialog>

      <CustomDialog
        fullWidth
        isOpen={modalOptions.preview.open}
        hideActions
        hideTitleButton
        disableBackdropClick
        disableEscapeKeyDown
        buttonSx={{ backgroundColor: 'transparent' }}
        customTitleButton={
          <Tooltip arrow title={previewCheckTitle}>
            <IconButton>
              <PreviewIcon color='primary' />
            </IconButton>
          </Tooltip>
        }
        modalSize={'xl'}
        modalTitle={previewCheckTitle}
        onClose={() => handleModalClose('preview')}
      >
        <CheckPdfPreview clientType={clientType} checkId={selectedCheckId} />
      </CustomDialog>

      <CustomDialog
        fullWidth
        isOpen={modalOptions.printOne.open}
        hideActions
        hideTitleButton
        disableBackdropClick
        disableEscapeKeyDown
        buttonSx={{ backgroundColor: 'transparent' }}
        modalSize={'sm'}
        modalTitle={'Print Check'}
        onClose={() => handleModalClose('printOne')}
      >
        <Row>
          <Col>
            <PrintCheckForm clientType={clientType} checkId={selectedCheckId} closeModal={() => handleModalClose('printOne')} />
          </Col>
        </Row>
      </CustomDialog>

      <CustomDialog
        fullWidth
        isOpen={modalOptions.void.open}
        hideActions
        hideTitleButton
        disableBackdropClick
        disableEscapeKeyDown
        buttonSx={{ backgroundColor: 'transparent' }}
        modalSize={'sm'}
        modalTitle={voidCheckTitle}
        onClose={() => handleModalClose('void')}
      >
        <Row>
          <Col>
            <VoidCheckForm clientType={clientType} checkId={selectedCheckId} closeModal={() => handleModalClose('void')} />
          </Col>
        </Row>
      </CustomDialog>

      <Row>
        <Col>
          <CollapsableCard headerTitle={checksHeaderTitle}>
            <Row className='mb-3'>
              <Col>
                <CollapsableCard
                  headerTitle='Filters'
                  isExpanded={false}
                  titleTypographyProps={{ fontSize: '16px' }}
                  sxCardContent={{ padding: '14px' }}
                  sxCardHeader={{ padding: '14px' }}
                >
                  <Row>
                    <Col md={4}>
                      <CustomPageableAsyncSelect
                        label={'Client'}
                        isClearable
                        onChange={handleSelectChange}
                        loadOptions={loadOptions}
                        name='billing_payment_checks.client_id'
                        defaultValue={filter['billing_payment_checks.client_id']}
                      />
                    </Col>
                    <Col md={4}>
                      <DatePickerComponent
                        onChange={(date) => handleDateChange(date, 'billing_payment_checks.posting_date')}
                        showYearDropdown
                        isClearable
                        name='billing_payment_checks.posting_date'
                        selected={filter['billing_payment_checks.posting_date']}
                        placeholder='mm/dd/yyyy'
                        dateFormat={'MM/dd/yyyy'}
                        label='Posting Date'
                      />
                    </Col>
                    <Col md={4}>
                      <CustomToggleButtonGroup
                        size='small'
                        label='Printed'
                        name='printed'
                        value={filter.printed}
                        onChange={handleChangeButtonGroup}
                      />
                    </Col>
                    <Col md={4}>
                      <CustomToggleButtonGroup size='small' label='Voided' name='voided' value={filter.voided} onChange={handleChangeButtonGroup} />
                    </Col>
                    <Col md={4}>
                      <CustomToggleButtonGroup size='small' label='Posted' name='posted' value={filter.posted} onChange={handleChangeButtonGroup} />
                    </Col>
                  </Row>
                  <Row className='mt-2'>
                    <Col>
                      <Divider />
                    </Col>
                  </Row>
                  <Row className='mt-2'>
                    <Col className='d-flex justify-content-end'>
                      <Button onClick={handleSearch}>Search</Button>
                    </Col>
                  </Row>
                </CollapsableCard>
              </Col>
            </Row>
            <div
              ref={(instance) => {
                setContainer(instance);
              }}
            >
              <DataGridComponent
                dataSource={checksData?.data || blankData}
                onFilterChange={handleTableFilterChange}
                columns={columns({
                  Actions: (
                    <MemoizedCheckActions
                      disabled={false}
                      onPreview={handlePreviewCheckClick}
                      onPrint={handlePrintCheckClick}
                      onVoid={handleVoidCheckClick}
                    />
                  ),
                  SelectCheck: (
                    <MemoizedSelectCheck
                      name='selectedChecks'
                      onChange={handleCheckSelect}
                      selectedChecks={selectedChecks}
                      selectForPrint={selectChecksFor === PRINT}
                    />
                  ),
                })}
              />
            </div>
          </CollapsableCard>
        </Col>
      </Row>
    </>
  );
};

const CheckActions = ({ row, onPrint, onVoid, onPreview }) => {
  const isTransactionReconciliated = useMemo(() => Boolean(row?.reconciliated), [row]);

  return (
    <>
      <Row>
        <Tooltip title='Preview Check'>
          <span>
            <IconButton onClick={() => onPreview(row)} color='success'>
              <PreviewIcon fontSize='small' />
            </IconButton>
          </span>
        </Tooltip>

        {isTransactionReconciliated && (
          <>
            <div className='d-flex justify-content-center align-items-center'>
              <Tooltip placement='top' title='This Transaction Has Been Reconciliated'>
                <InfoIcon fontSize='small' color='info' sx={{ ':hover': { cursor: 'help' } }} />
              </Tooltip>
            </div>
          </>
        )}

        {!isTransactionReconciliated && (
          <>
            <Tooltip arrow title='Print Check'>
              <span>
                <IconButton
                  onClick={() => onPrint(row)}
                  disabled={row['billing_payment_checks.posted'] || row['billing_payments.finished'] || row['billing_payment_checks.printed']}
                  color='success'
                >
                  <DownloadIcon fontSize='small' />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip arrow title='Void Check'>
              <span>
                <IconButton
                  onClick={() => onVoid(row)}
                  disabled={!row['billing_payment_checks.posted'] || row['billing_payment_checks.voided']}
                  color='warning'
                >
                  <RemoveCircleOutlineIcon fontSize='small' />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
      </Row>
    </>
  );
};

const MemoizedCheckActions = React.memo(CheckActions);

const SelectCheck = ({ row, name, onChange, selectedChecks = [], selectMultiple, selectForPrint = true }) => {
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [multipleDisabled, setMultipleDisabled] = useState(false);

  useEffect(() => {
    if (!selectMultiple) {
      setChecked(!!selectedChecks.includes(row.original['billing_payment_checks.id']));
    } else {
      setChecked(!!selectedChecks.length);
    }
  }, [selectMultiple, selectedChecks, row]);

  useEffect(() => {
    if (selectMultiple) {
      setMultipleDisabled(row.data[0]?._original['billing_payments.finished']);
    }

    if (selectForPrint) {
      setDisabled(
        !selectMultiple &&
          (row.original['billing_payment_checks.posted'] ||
            row.original['billing_payments.finished'] ||
            row.original['billing_payment_checks.printed'])
      );
    } else {
      setDisabled(
        !selectMultiple &&
          (row.original['billing_payment_checks.posted'] ||
            !row.original['billing_payment_checks.printed'] ||
            row.original['billing_payments.finished'])
      );
    }
  }, [selectMultiple, selectForPrint, row]);

  if (multipleDisabled) {
    return <></>;
  }

  return <CustomCheckbox name={name} checked={checked} disabled={disabled} onChange={() => onChange(row, selectMultiple)} />;
};

const MemoizedSelectCheck = React.memo(SelectCheck);

const BankCheckPopupContent = ({ message, onDownload, onCancel, overlayPopup, filters }) => {
  return (
    <>
      <Box>
        {message && <DialogContent>{message}</DialogContent>}
        <DialogActions sx={{ padding: '2px' }}>
          <CustomDialog
            overlayPopup={overlayPopup}
            hideActions
            disableBackdropClick
            disableEscapeKeyDown
            buttonSx={{ backgroundColor: 'transparent' }}
            customTitleButton={
              <Tooltip arrow title={`Preview Bank Checks`}>
                <IconButton>
                  <PreviewIcon color='primary' />
                </IconButton>
              </Tooltip>
            }
            modalSize={'lg'}
            modalTitle={`Preview`}
          >
            <BankChecksPreview initFilters={filters} />
          </CustomDialog>

          <Tooltip arrow title={`Export Checks for Bank`}>
            <IconButton onClick={onDownload}>
              <DownloadIcon color='primary' />
            </IconButton>
          </Tooltip>
          <IconButton onClick={onCancel}>
            <ClearIcon />
          </IconButton>
        </DialogActions>
      </Box>
    </>
  );
};

const MemoizedBankCheckPopupContent = React.memo(BankCheckPopupContent);
