import React from 'react';
import { useParams } from 'react-router-dom';
import DataTableComponent from '../../../../../component/DataTable/component/DataTableComponent';
import { columnsForCustomers, columnsForVendors } from './ClientTableColumns';

export default function ClientListDataTable({
  dataSource = { data: [], page: 1, pageSize: 10, pageTotal: 0 },
  onFilterChange,
  loader = true,
  ...props
}) {
  const { clientType } = useParams();

  return (
    <>
      <DataTableComponent
        dataSource={dataSource}
        onFilterChange={onFilterChange}
        columns={clientType === 'customers' ? columnsForCustomers : columnsForVendors}
        loading={loader}
        {...props}
      />
    </>
  );
}
