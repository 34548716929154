import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { CUSTOMER_CLIENT, VENDOR_CLIENT } from '../../../../../constants/clientType.constants';
import { useSendTransactionEmail, useTransactionPdfExporter } from '../../../Client/Client360/component/transaction/clientTransactionsHooks';
import { PaymentsTableActions } from '../../../Client/Client360/component/transaction/payment/PaymentsTableActions';
import { TransactionTableActions } from '../../../Client/Client360/component/transaction/TransactionTableActions';
import {
  emailSendErrorCallback,
  emailSendSuccessCallback,
  pdfExportErrorCallback,
} from '../../../Client/Client360/component/transaction/utils/pdfAndEmailCallbackUtils';
import { CUSTOMER_CREDIT_MEMO, CUSTOMER_PAYMENT, CUSTOMER_REFUND } from '../../../../../constants/documentType.constants';

export const AllTransactionsTableActions = ({ row, overlayModal, overlayPopup }) => {
  const transactionType = useMemo(() => 'Transaction', []);

  const clientType = useMemo(() => {
    if (+row?.original['client.client_type_id'] === CUSTOMER_CLIENT) {
      return 'customers';
    } else {
      if (+row?.original['client.client_type_id'] === VENDOR_CLIENT) {
        return 'vendors';
      }
    }
    return '';
  }, [row]);

  const clientId = useMemo(() => {
    if (row?.original['client.id']) {
      return row.original['client.id'];
    }

    return null;
  }, [row]);

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: sendTransactionEmail } = useSendTransactionEmail(clientId, clientType, transactionType, {
    successCallback: () => emailSendSuccessCallback({ enqueueSnackbar }),
    errorCallback: () => emailSendErrorCallback({ enqueueSnackbar }),
  });
  const { mutate: exportTransactionPdf } = useTransactionPdfExporter(clientId, clientType, transactionType, {
    errorCallback: () => pdfExportErrorCallback({ enqueueSnackbar }),
  });

  return (
    <>
      {row?.original['transactions.document_type_journal_entry_id'] !== CUSTOMER_PAYMENT && ( //payment = 2
        <TransactionTableActions
          row={row}
          exportPdf={exportTransactionPdf}
          sendEmail={sendTransactionEmail}
          clientId={clientId}
          clientType={clientType}
          transactionType={transactionType}
          overlayModal={overlayModal}
          overlayPopup={overlayPopup}
          fromAllTransactionTable
        />
      )}
      {[CUSTOMER_PAYMENT, CUSTOMER_REFUND, CUSTOMER_CREDIT_MEMO].includes(+row?.original['transactions.document_type_journal_entry_id']) && (
        <PaymentsTableActions row={row} clientType={clientType} clientId={clientId} fromAllTransactionTable />
      )}
    </>
  );
};
