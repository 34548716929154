import React from 'react';
import { Col, Label, Row } from 'reactstrap';
import { CustomButton } from '../../../../common/Button/CustomButton';
import { CustomSelect } from '../../../../common/CustomSelect';
import { CustomPageableAsyncSelect } from '../../../../common/Inputs/CustomPageableAsyncSelect';
import { CustomSwitch } from '../../../../common/Inputs/CustomSwitch';
import DateRangeSelectBox from '../../../../component/SelectBox/DataRangeSelectBox';
import { useGroupedAccountTypeOptions, useNormalBalanceOptions } from '../../../../hooks/commonApiHooks';
import { useAsyncPageable } from '../../../../hooks/useAsyncPageable';
import { CustomDateRange } from '../../../../common/Inputs/CustomDateRange';

const ChartOfAccountFilter = ({ filter, blankFilter, onFilterActionClick, onFilterChangeHandler, onFilterDateRangeChange, onSwitchChange }) => {
  const { data: accountTypeOptionsData } = useGroupedAccountTypeOptions();
  const { data: balanceOptions } = useNormalBalanceOptions();

  const { fetchOptions } = useAsyncPageable();

  const selectHandler = (val, e) => {
    if (e.action === 'select-option') {
      let o = { target: { name: e.name, value: val.value } };
      onFilterChangeHandler(o);
      return;
    }
    if (e.action === 'clear') {
      let o = { target: { name: e.name, value: null } };
      onFilterChangeHandler(o);
    }
  };

  const loadOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/account/options/accounts', { q: inputValue, ...options });
    return data;
  };

  return (
    <>
      <Row className='mb-3'>
        <Col xs={12} md={4} lg={3}>
          <CustomPageableAsyncSelect
            label='Account'
            isClearable
            onChange={selectHandler}
            loadOptions={loadOptions}
            name='account.number'
            defaultValue={filter['account.number']}
            disabled={!!blankFilter['account.number']}
          />
        </Col>
        <Col xs={12} md={4} lg={3}>
          <CustomSelect
            label='Normal Balance'
            isClearable
            onChange={selectHandler}
            name='account.account_normal_balance_id'
            defaultValue={filter['account.account_normal_balance_id']}
            options={balanceOptions?.data || []}
            disabled={!!blankFilter['account.account_normal_balance_id']}
          />
        </Col>
        <Col xs={12} md={4} lg={3}>
          <CustomSelect
            label='Account Type'
            isClearable
            isGrouped
            onChange={selectHandler}
            name='account.type'
            defaultValue={filter['account.type']}
            options={accountTypeOptionsData?.data || []}
            disabled={!!blankFilter['account.type']}
          />
        </Col>
        <Col xs={12} md={5} lg={3}>
          <Label>Date Range (Posting Date):</Label>
          <CustomDateRange
            name='posting_date'
            onChange={onFilterDateRangeChange}
            value={{ start: filter['start'], end: filter['end'] }}
            isDateRange
            standardOptions={false}
          />
        </Col>
        <Col xs={12} md={4} lg={3}>
          <CustomSwitch
            label='Accounts Without Activity'
            name='account.with_no_activity'
            checked={filter['account.with_no_activity']}
            onChange={onSwitchChange}
          />
        </Col>
      </Row>
      <Row className='p-0 justify-content-end'>
        <Col md={3} className='d-flex justify-content-end'>
          <CustomButton
            color='success'
            icon={'fas fa-sync-alt'}
            onClick={(e) => {
              e.preventDefault();
              e.target.blur();
              onFilterActionClick('reset');
            }}
          >
            Reset
          </CustomButton>
          <CustomButton
            className={'ml-3'}
            color='secondary'
            onClick={(e) => {
              e.preventDefault();
              e.target.blur();
              onFilterActionClick('search');
            }}
          >
            Search
          </CustomButton>
        </Col>
      </Row>
    </>
  );
};

export { ChartOfAccountFilter };
