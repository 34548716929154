import React from 'react';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import { Button } from '@mui/material';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { useHistory, useParams } from 'react-router-dom';

import { DatePickerComponent } from '../../../../common/DatePicker/DatePicker';
import { CustomInput } from '../../../../common/CustomInput';
import { CustomCurrencyInput } from '../../../../common/Inputs/CurrencyInput';
import { CustomSelect } from '../../../../common/CustomSelect';
import { useGetBankAccount, useGetSupportTablesForSelect } from '../../../../hooks/commonApiHooks';
import { useCreateDepositMutation, useGetDeposit, useUpdateDepositMutation } from '../depositApiRoutes';
import { notificationSuccess } from '../../../../utils/toastify';
import { PAYMENT_SOURCE } from '../../../../constants/supportTables.constants';

const StyledBox = styled(Box)(() => ({
  padding: '5px 10px',
  border: '1px solid #cdad2b',
  borderRadius: '5px',
}));

const HeaderInfo = ({ disabled }) => {
  const { data: bankAccountOptions } = useGetBankAccount();
  const history = useHistory();
  const params = useParams();
  const [headerInfoData, setHeaderInfoData] = React.useState({
    deposit_number: '',
    bank_account_number: null,
    total_deposit_amount: null,
    payment_type_id: null,
    total_deposit_lines: 0,
    total_cleared_amount: 0,
    difference: 0,
    posting_date: '',
    document_date: '',
    deposit_date: '',
  });

  const createDepositSuccessCallback = (data) => {
    const {
      data: { id },
    } = data;
    history.push(`/deposit/${id}`);
  };

  const getDepositSuccessCallback = (data) => {
    const { data: depositData } = data;

    const newHeaderInfoData = {
      deposit_number: depositData.deposit_number,
      bank_account_number: depositData.bank_account_number,
      total_deposit_amount: depositData.total_deposit_amount,
      total_deposit_lines: +depositData.deposit_items_amount,
      difference: +depositData.total_deposit_amount - +depositData.deposit_items_amount,
      posting_date: depositData.posting_date,
      document_date: depositData.document_date,
      deposit_date: depositData.deposit_date,
      total_cleared_amount: depositData.total_cleared_amount,
      payment_type_id: depositData.payment_type_id,
    };

    setHeaderInfoData(newHeaderInfoData);
  };

  const updateDepositSuccessCallback = (data) => {
    const { data: depositData } = data;
    setHeaderInfoData((oldData) => ({ ...oldData, difference: +depositData.total_deposit_amount - +depositData.deposit_items_amount }));
    notificationSuccess('Updated successfully');
  };

  const { mutate: createDepositMutation, error: errorCreate } = useCreateDepositMutation(createDepositSuccessCallback);
  const { mutate: updateDepositMutation, error: errorUpdate } = useUpdateDepositMutation(updateDepositSuccessCallback, params.depositId);
  const { data: { data: supportTableOptions = {} } = {} } = useGetSupportTablesForSelect({
    table_names: [PAYMENT_SOURCE],
  });
  useGetDeposit({ id: params.depositId }, getDepositSuccessCallback);

  const onSave = () => {
    if (!params.depositId) {
      createDepositMutation(headerInfoData);
    } else {
      updateDepositMutation({ ...headerInfoData, id: params.depositId });
    }
  };

  const handleChangeSelect = (val, e) => {
    setHeaderInfoData((oldData) => ({ ...oldData, [e.name]: val?.value ? val.value : null }));
  };

  const handleDateChange = (date, name) => {
    if (name === 'deposit_date' || name === 'posting_date' || name === 'document_date') {
      setHeaderInfoData((oldData) => ({ ...oldData, posting_date: date, deposit_date: date, document_date: date }));
    } else {
      setHeaderInfoData((oldData) => ({ ...oldData, [name]: date }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHeaderInfoData((oldData) => ({ ...oldData, [name]: value }));
  };

  const handleCurrencyInputChange = (value, name) => {
    setHeaderInfoData((oldData) => ({ ...oldData, [name]: value }));
  };

  return (
    <>
      <Row>
        <Col md='4'>
          <StyledBox>
            <CustomInput
              name='deposit_number'
              value={headerInfoData.deposit_number}
              onChange={() => {}}
              disabled
              label='Deposit Number'
              placeholder='Deposit Number'
            />
            <CustomSelect
              isClearable
              options={bankAccountOptions?.data || []}
              defaultValue={headerInfoData.bank_account_number}
              onChange={handleChangeSelect}
              label='Bank Account'
              name={'bank_account_number'}
              errorMess={errorCreate?.response?.data?.error?.bank_account_number || errorUpdate?.response?.data?.error?.bank_account_number}
            />
            <CustomSelect
              isClearable
              options={supportTableOptions[PAYMENT_SOURCE] || []}
              defaultValue={headerInfoData.payment_type_id}
              onChange={handleChangeSelect}
              label='Payment Type'
              name={'payment_type_id'}
              errorMess={errorCreate?.response?.data?.error?.payment_type_id || errorUpdate?.response?.data?.error?.payment_type_id}
            />
            <CustomCurrencyInput
              name='total_cleared_amount'
              value={headerInfoData.total_cleared_amount}
              onChange={() => {}}
              prefix={'$ '}
              label='Total Applied Amount'
              placeholder='Total Cleared Amount'
              disabled
            />
            <CustomCurrencyInput
              name='total_uncleared_amount'
              value={+(headerInfoData.total_deposit_amount - headerInfoData.total_cleared_amount).toFixed(2)}
              onChange={() => {}}
              prefix={'$ '}
              label='Total Unapplied Amount'
              placeholder='Total Uncleared Amount'
              disabled
            />
          </StyledBox>
        </Col>
        <Col md='4'>
          <StyledBox>
            <CustomCurrencyInput
              name='total_deposit_amount'
              value={headerInfoData.total_deposit_amount}
              allowNegativeValue={false}
              onChange={handleCurrencyInputChange}
              prefix={'$ '}
              label='Total Deposit Amount'
              placeholder='Total Deposit Amount'
              errorMess={errorCreate?.response?.data?.error?.total_deposit_amount || errorUpdate?.response?.data?.error?.total_deposit_amount}
            />
            <CustomCurrencyInput
              name='total_deposit_lines'
              value={headerInfoData.total_deposit_lines}
              onChange={() => {}}
              prefix={'$ '}
              label='Total Deposit Lines'
              placeholder='Total Deposit Lines'
              disabled
            />
            <CustomCurrencyInput
              name='difference'
              value={headerInfoData.difference.toFixed(2)}
              onChange={() => {}}
              label='Difference'
              prefix={'$ '}
              placeholder='Difference'
              disabled
            />
          </StyledBox>
        </Col>
        <Col md='4'>
          <StyledBox>
            <DatePickerComponent
              onChange={(date) => handleDateChange(date, 'posting_date')}
              showYearDropdown
              isClearable
              name='posting_date'
              selected={headerInfoData.posting_date}
              placeholder='mm/dd/yyyy'
              dateFormat={'MM/dd/yyyy'}
              label='Posting Date'
              errorMess={errorCreate?.response?.data?.error?.posting_date || errorUpdate?.response?.data?.error?.posting_date}
            />
            <DatePickerComponent
              onChange={(date) => handleDateChange(date, 'document_date')}
              showYearDropdown
              isClearable
              name='document_date'
              selected={headerInfoData.document_date}
              placeholder='mm/dd/yyyy'
              dateFormat={'MM/dd/yyyy'}
              label='Document Date'
              errorMess={errorCreate?.response?.data?.error?.document_date || errorUpdate?.response?.data?.error?.document_date}
            />
            <DatePickerComponent
              onChange={(date) => handleDateChange(date, 'deposit_date')}
              showYearDropdown
              isClearable
              name='deposit_date'
              selected={headerInfoData.deposit_date}
              placeholder='mm/dd/yyyy'
              dateFormat={'MM/dd/yyyy'}
              label='Deposit Date'
              errorMess={errorCreate?.response?.data?.error?.deposit_date || errorUpdate?.response?.data?.error?.deposit_date}
            />
          </StyledBox>
        </Col>
      </Row>
      <Row>
        <Col className='mt-3 d-flex justify-content-end'>
          <Button color='success' onClick={onSave} disabled={disabled}>
            Save
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default HeaderInfo;
