import { Button } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { CollapsableCard } from '../../../../../../../common/Card/CollapsableCard';
import { CustomCard } from '../../../../../../../common/Card/CustomCard';
import { DatePickerComponent } from '../../../../../../../common/DatePicker/DatePicker';
import DataGridComponent from '../../../../../../../component/DataTable/component/DataTableComponent';
import { useSendTransactionEmail, useTransactionPdfExporter } from '../../../../Client360/component/transaction/clientTransactionsHooks';
import { TransactionTableActions } from '../../../../Client360/component/transaction/TransactionTableActions';
import { columns as transactionColumns } from '../../../columns/transactionColumns';
import { useClientUnpaidTransactions } from '../../../hooks';
import ExportButton from '../../../../../../../component/Buttons/ExportButton';
import { downloaderFiles } from '../../../../../../../utils/downloaderFiles';
import { exportExcel } from '../../../../../../../utils/exportExcel';
import { formatAmount } from '../../../../../../../utils/formatAmout';
import { formatDateForTable } from '../../../../../../../utils/formatDataForTable';

export const UnpaidClientTransactions = ({ clientId = null, billingPaymentItem = {}, onCancelClearClick, onClearClick }) => {
  const { clientType, billingId, billingItemId } = useParams();
  const transactionType = useMemo(() => 'Transaction', []);

  const blankData = useMemo(() => {
    return { data: [], page: 0, pageSize: 10, pageTotal: 0 };
  }, []);

  const blankFilter = useMemo(() => {
    return {
      'transactions_due_date.due_date': moment().add(1, 'year').format('YYYY/MM/DD'),
      start: '',
      end: '',
      page: 0,
      pageSize: 10,
      sort_by: 'client_transactions.client_id',
      order_by: 'ASC',
    };
  }, []);

  const [filter, setFilter] = useState(blankFilter);

  const { data: unpaidTransactionsData, refetch } = useClientUnpaidTransactions({
    billingId,
    billingItemId,
    clientId,
    clientType,
    filters: filter,
  });

  const { mutate: sendTransactionEmail } = useSendTransactionEmail(
    billingPaymentItem.account?.number,
    billingPaymentItem.account_type_info?.name === 'Customer' ? 'customers' : 'vendors',
    transactionType
  );
  const { mutate: exportTransactionPdf } = useTransactionPdfExporter(
    billingPaymentItem.account?.number,
    billingPaymentItem.account_type_info?.name === 'Customer' ? 'customers' : 'vendors',
    transactionType
  );

  useEffect(() => {
    setFilter((prev) => ({ ...prev, 'client_transactions.client_id': null }));
  }, [clientType]);

  const handleTableFilterChange = (input) => {
    setFilter((prev) => ({ ...prev, ...input }));
  };

  const handleDateChange = (date, name) => {
    setFilter((prev) => ({
      ...prev,
      [name]: date,
    }));
  };

  const columns = React.useMemo(
    () =>
      transactionColumns({
        onClearClick,
        onCancelClearClick,
        isClearingDisabled: +billingPaymentItem.balance === +billingPaymentItem.amount,
        accountTypeId: billingPaymentItem.account_type_id,
        actionsStyle: { scaleDown: '0.9' },
        isRefund: billingPaymentItem.transaction_type == 2,
        Actions: (
          <TransactionTableActions
            exportPdf={exportTransactionPdf}
            sendEmail={sendTransactionEmail}
            clientId={billingPaymentItem.account?.number}
            clientType={billingPaymentItem.account_type_info?.name === 'Customer' ? 'customers' : 'vendors'}
            transactionType={transactionType}
          />
        ),
      }),
    [billingPaymentItem]
  );

  const handleSearch = () => {
    refetch();
  };

  const onFilterActionBtn = (e) => {
    if (e.toString() === 'EXCEL') {
      downloaderFiles({
        filter: filter,
        type: e,
        url: `/me/client/vendor/billing-payments/billings/${billingId}/items/${billingItemId}/transactions`,
        cbLoad: () => {},
        name: 'Unpaid Transactions All',
      });
    }
  };

  const excelHeader = React.useMemo(
    () => [
      { header: 'Document Number', key: 'transactions.document_number' },
      { header: 'Document Date', key: 'transactions.document_date' },
      { header: 'Due Date', key: 'transactions_due_date.due_date' },
      { header: 'Amount', key: 'transactions_due_date.amount' },
      { header: 'Document Type', key: 'document_type.name' },
      { header: 'Remaining Balance', key: 'transactions_due_date.balance' },
      { header: 'Applied Amount', key: 'billing_payment_clearing_invoice.amount' },
      { header: 'Ending Balance', key: 'ending_balance' },
      { header: 'Total Ending Balance', key: 'total_ending_balance' },
    ],
    []
  );

  const mappedData = unpaidTransactionsData?.data?.data.map((item) => ({
    ...item,
    'transactions.document_date': formatDateForTable(item['transactions.document_date']),
    'transactions_due_date.due_date': formatDateForTable(item['transactions_due_date.due_date']),
    'transactions_due_date.amount': formatAmount(item['transactions_due_date.amount'] || 0),
    'transactions_due_date.balance': formatAmount(item['transactions_due_date.balance']),
    'billing_payment_clearing_invoice.amount': formatAmount(item['billing_payment_clearing_invoice.amount']),
    ending_balance: formatAmount(item['transactions_due_date.balance'] - item['billing_payment_clearing_invoice.amount']),
    total_ending_balance: formatAmount(item['transactions_due_date.balance'] - item.total_clear_balance),
  }));

  return (
    <>
      <Row className='mb-3'>
        <Col>
          <CollapsableCard
            headerTitle='Filters'
            isExpanded
            titleTypographyProps={{ fontSize: '16px' }}
            sxCardContent={{ padding: '14px' }}
            sxCardHeader={{ padding: '14px' }}
          >
            <Row>
              <Col md={4}>
                <DatePickerComponent
                  showYearDropdown
                  isClearable
                  name='transactions_due_date.due_date'
                  selected={filter['transactions_due_date.due_date']}
                  onChange={(date) => handleDateChange(date, 'transactions_due_date.due_date')}
                  placeholder='mm/dd/yyyy'
                  dateFormat={'MM/dd/yyyy'}
                  label='Invoice Due Date'
                />
              </Col>
            </Row>
            <Row>
              <Col className='d-flex justify-content-end'>
                <Button onClick={handleSearch}>Search</Button>
              </Col>
            </Row>
          </CollapsableCard>
        </Col>
      </Row>
      <Row>
        <Col>
          <CustomCard
            headerTitle={
              <div className='d-flex justify-content-between align-items-center'>
                <span>Unpaid Transactions</span>
                <ExportButton
                  disabled={false}
                  showOnly='EXCEL'
                  exportTableEXCEL={() => {
                    onFilterActionBtn('EXCEL');
                  }}
                  exportPageEXCEL={() => exportExcel(mappedData, excelHeader, 'Unpaid Transactions')}
                />
              </div>
            }
            sxCardContainer={{ marginTop: '15px' }}
          >
            <DataGridComponent dataSource={unpaidTransactionsData?.data || blankData} onFilterChange={handleTableFilterChange} columns={columns} />
          </CustomCard>
        </Col>
      </Row>
    </>
  );
};
