import CloseIcon from '@mui/icons-material/Close';
import { colors, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect } from 'react';
import { Button } from 'reactstrap';

const BootstrapDialog = styled(Dialog)(({ theme, overlayPopup }) => {
  return {
    zIndex: overlayPopup ? theme.options.modal.overlayPopperZIndex : theme.options.modal.zIndex,
    '& .MuiPaper-root': {
      zIndex: overlayPopup ? theme.options.modal.overlayPopperZIndex : theme.options.modal.zIndex,
      overflowY: overlayPopup ? 'visible' : 'auto',
    },
    '& .MuiDialogContent-root': {
      zIndex: overlayPopup ? theme.options.modal.overlayPopperZIndex : theme.options.modal.zIndex,
      overflowY: overlayPopup ? 'visible' : 'auto',
      padding: theme.spacing(2),
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  };
});

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.error[500],
          }}
        >
          <CloseIcon sx={{ color: colors.pink[500], fontSize: '30px' }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const useCustomModalToggle = () => {
  const [open, setOpen] = React.useState(false);

  const toggleCustomModal = () => {
    setOpen(!open);
  };

  return { open, setOpen, toggleCustomModal };
};

/**hideTitleButton hides button that opens dialog, allowing control of the dialog from the outside in combination with isOpen flag  */
const CustomDialog = ({
  modalSize,
  fullWidth,
  buttonTitle,
  saveButtonTitle,
  modalTitle,
  onSave,
  children,
  buttonSx,
  actionButtonDisabled,
  disableEscapeKeyDown,
  disableBackdropClick,
  hideActions,
  onClose,
  customTitleButton,
  isOpen,
  hideTitleButton,
  overlayPopup,
}) => {
  const { open, toggleCustomModal, setOpen } = useCustomModalToggle();

  const handleClose = (e, reason) => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return false;
    }

    if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
      return false;
    }
    toggleCustomModal();

    if (typeof onClose === 'function') {
      onClose();
    }
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      {!hideTitleButton && customTitleButton && React.cloneElement(customTitleButton, { onClick: handleClose, sx: buttonSx })}
      {!hideTitleButton && !customTitleButton && (
        <Button onClick={handleClose} sx={buttonSx}>
          {buttonTitle}
        </Button>
      )}
      <BootstrapDialog
        overlayPopup={overlayPopup}
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
        maxWidth={modalSize}
        fullWidth={fullWidth}
      >
        <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
          {modalTitle}
        </BootstrapDialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        {!hideActions && (
          <DialogActions>
            <Button autoFocus onClick={onSave} disabled={actionButtonDisabled}>
              {saveButtonTitle}
            </Button>
          </DialogActions>
        )}
      </BootstrapDialog>
    </>
  );
};

CustomDialog.propTypes = {
  modalSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  buttonTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  saveButtonTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  disableEscapeKeyDown: PropTypes.bool,
  buttonSx: PropTypes.object,
  actionButtonDisabled: PropTypes.bool,
  hideActions: PropTypes.bool,
  onSave: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  disableBackdropClick: PropTypes.bool,
  onClose: PropTypes.func,
  customTitleButton: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  hideTitleButton: PropTypes.bool,
  fullWidth: PropTypes.bool,
  customButtonTitleValidation: (props) => {
    if (!props.buttonTitle && !props.customTitleButton && !props.hideTitleButton) {
      return new Error(`buttonTitle is required`);
    }
  },
  customSaveButtonTitleValidation: (props) => {
    if (!props.saveButtonTitle && !props.hideActions) {
      return new Error(`saveButtonTitle is required`);
    }
  },
  isOpen: PropTypes.bool,
  hideTitleButton: PropTypes.bool,
  overlayPopup: PropTypes.bool,
};

CustomDialog.defaultProp = {
  modalSize: 'sm',
  onSave: () => {},
  onClose: () => {},
  hideActions: false,
  disableEscapeKeyDown: false,
  disableBackdropClick: false,
  isOpen: false,
  hideTitleButton: false,
  overlayPopup: false,
  fullWidth: false,
};

export { CustomDialog, useCustomModalToggle };
