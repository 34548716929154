import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Box, Button, Paper, Avatar, IconButton, Tooltip } from '@mui/material';
import { EditOutlined, DoneOutlineOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { grey, teal, cyan, lightBlue } from '@mui/material/colors';

import DataGridComponent from '../../../../component/DataTable/component/DataTableComponent';
import { CollapsableCard } from '../../../../common/Card/CollapsableCard';
import { CustomCurrencyInput } from '../../../../common/Inputs/CurrencyInput';
import { Loader } from '../../../../common/Loader';

import { PaymentsTableColumns } from '../columns/PaymentsTableColumns';
import { useUpdateBancReco, useGetGlTable, useGetMarkAll, useReconciliateMutation, useDownloadRecoItems } from '../BankRecoApiHooks';
import { formatAmount } from '../../../../utils/formatAmout';
import { notificationDangerCustomTime, notificationSuccess } from '../../../../utils/toastify';
import { hasPermissionHook } from '../../../../hooks/hasPermission';
import { BANK_RECONCILIATION, EDIT } from '../../../../constants/permissions.constants';

export const Reconsile = ({ setStep, bankRecoData, accountNumber, refetchGetBancRecoData }) => {
  const { hasPermission } = hasPermissionHook();
  const params = useParams();
  const history = useHistory();
  const [editEndingBalance, setEditEndingBalance] = React.useState({
    is_edit: false,
    amount: bankRecoData?.ending_balance,
  });

  const [tableFilters, setTableFilters] = React.useState({
    checksAndPayments: {
      page: 0,
      pageSize: 10,
      sort_by: 'posting_date',
      order_by: 'ASC',
      tableType: 'checks',
      account: accountNumber || null,
      statment_date: null,
    },
    depositAndOtherCredits: {
      page: 0,
      pageSize: 10,
      sort_by: 'posting_date',
      order_by: 'ASC',
      tableType: 'deposits',
      account: accountNumber || null,
      statment_date: null,
    },
  });
  const [checkedPayments, setCheckedPayments] = React.useState([]);

  const balances = React.useMemo(() => {
    if (bankRecoData && Object.keys(bankRecoData).length) {
      let depositAndOtherCreditsBalance = +bankRecoData?.deposits_total_amount;
      let countDeposit = 0;
      let checksAndPaymentsBalance = +bankRecoData?.checks_total_amount;
      let countChecks = 0;
      let differenceBalance = +bankRecoData.ending_gl_balance;
      let adjustedBankStatementBalance = +bankRecoData?.ending_balance;

      for (const payment of checkedPayments) {
        if (payment.gl_type === 'deposits') {
          depositAndOtherCreditsBalance -= +payment.amount;
          countDeposit++;
        } else if (payment.gl_type === 'checks') {
          checksAndPaymentsBalance -= +payment.amount;
          countChecks++;
        }
      }

      adjustedBankStatementBalance += +depositAndOtherCreditsBalance - Math.abs(+checksAndPaymentsBalance);
      differenceBalance -= +adjustedBankStatementBalance;

      return {
        depositAndOtherCreditsBalance: Number(depositAndOtherCreditsBalance?.toFixed(2) || 0),
        countDeposit,
        checksAndPaymentsBalance: Number(checksAndPaymentsBalance?.toFixed(2) || 0),
        countChecks,
        adjustedBankStatementBalance: Number(adjustedBankStatementBalance?.toFixed(2) || 0),
        differenceBalance: Number(differenceBalance?.toFixed(2) || 0),
      };
    }

    return {
      depositAndOtherCreditsBalance: 0,
      countDeposit: 0,
      checksAndPaymentsBalance: 0,
      countChecks: 0,
      differenceBalance: 0,
    };
  }, [bankRecoData, checkedPayments]);

  React.useEffect(() => {
    if (bankRecoData && Object.keys(bankRecoData).length) {
      setEditEndingBalance((oldData) => ({ ...oldData, amount: bankRecoData?.ending_balance }));
      setCheckedPayments(bankRecoData.gl_info);
    }
    let account = accountNumber;

    // Case when recoId is set in url
    if (!accountNumber && bankRecoData && Object.keys(bankRecoData).length) {
      account = bankRecoData?.account;
    }

    setTableFilters((oldFilters) => ({
      checksAndPayments: { ...oldFilters.checksAndPayments, account, statment_date: bankRecoData?.statment_date },
      depositAndOtherCredits: { ...oldFilters.depositAndOtherCredits, account, statment_date: bankRecoData?.statment_date },
    }));
  }, [bankRecoData]);

  const onSuccessGetMarkAll = (data) => {
    const newCheckedPayments = checkedPayments.filter((payment) => payment.gl_type !== data.tableType);
    setCheckedPayments(() => [...newCheckedPayments, ...data.gls]);
  };

  const onSuccessReconciliate = () => {
    history.push(`/bank-reconciliation/report/${bankRecoData.id}`);
    notificationSuccess('Successfully Reconciled!');
  };

  const onSuccessUpdateEndingBalance = () => {
    if (params.recoId) {
      refetchGetBancRecoData({ id: params.recoId });
    }
    notificationSuccess('Successfully updated Ending Balance!');
  };

  const { data: { data: checksAndPaymentsTableData = {} } = {} } = useGetGlTable(tableFilters.checksAndPayments);
  const { data: { data: depositAndOtherCreditsTableData = {} } = {} } = useGetGlTable(tableFilters.depositAndOtherCredits);
  const { mutate: updateBancRecoMutation } = useUpdateBancReco(onSuccessUpdateEndingBalance, bankRecoData?.account);
  const { mutate: updateBancRecoMutation2, isLoading } = useUpdateBancReco(() => history.push('/bank-reconciliation'), bankRecoData?.account);
  const { mutate: getMarkAll } = useGetMarkAll(onSuccessGetMarkAll);
  const { mutate: reconciliateMutation } = useReconciliateMutation(onSuccessReconciliate);
  const { mutate: downloadRecoItems } = useDownloadRecoItems(params.recoId);

  const downloadExcel = (tableType) => {
    downloadRecoItems({ tableType });
  };

  const checkPaymentTableRow = (row, isChecked, currentCheckedIds) => {
    const allCheckedPayments = row.transactions_data.map((trans) => ({ id: trans.id, gl_type: row.gl_type, amount: trans.amount }));
    if (isChecked) {
      setCheckedPayments((oldData) => oldData.filter((payment) => !currentCheckedIds.includes(+payment.id)));
    } else {
      setCheckedPayments((oldData) => [...oldData, ...allCheckedPayments]);
    }
  };

  const emptyGlTableData = React.useMemo(() => ({ data: [], page: 0, pageSize: 10, pageTotal: 0 }), []);
  const tableColumns = React.useMemo(() => PaymentsTableColumns({ checkPaymentTableRow, checkedPayments }), [checkedPayments]);

  const onTableFilterChange = (input, name) => {
    if (name === 'checks') {
      setTableFilters((oldFilters) => ({ ...oldFilters, checksAndPayments: { ...oldFilters.checksAndPayments, ...input } }));
    } else {
      setTableFilters((oldFilters) => ({ ...oldFilters, depositAndOtherCredits: { ...oldFilters.depositAndOtherCredits, ...input } }));
    }
  };

  const onBackClick = () => {
    setStep(0);
  };

  const onEditingEndingBalance = (action) => {
    if (action === 'save') {
      updateBancRecoMutation({ ending_balance: editEndingBalance.amount, id: bankRecoData.id, version: bankRecoData.version });
    }

    setEditEndingBalance((oldData) => ({ ...oldData, is_edit: !oldData.is_edit }));
  };

  const handleCurrencyInputChange = (value, name) => {
    setEditEndingBalance((oldData) => ({ ...oldData, [name]: value }));
  };

  const onSaveAndLeaveClick = () => {
    updateBancRecoMutation2({ gl_info: JSON.stringify(checkedPayments), id: bankRecoData.id, version: bankRecoData.version });
  };

  const onReconsileClick = () => {
    if (balances.differenceBalance !== 0) {
      notificationDangerCustomTime('Difference must be $0.00 to be able finish Reconciliation!', 10000);
      return;
    }

    const account = accountNumber || bankRecoData?.account;

    reconciliateMutation({
      checkedPayments: JSON.stringify(checkedPayments),
      account,
      id: bankRecoData.id,
      version: bankRecoData.version,
    });
  };

  const onMarkAllClick = (tableType) => {
    getMarkAll({ tableType, account: accountNumber || bankRecoData?.account, posting_date: bankRecoData?.statment_date });
  };

  const onUnmarkAllClick = (tableType) => {
    setCheckedPayments((oldData) => oldData.filter((payment) => payment.gl_type !== tableType));
  };

  const highlightTableRow = React.useCallback(
    (_, rowInfo) => {
      if (rowInfo !== undefined) {
        if (checkedPayments.findIndex((payment) => payment.id === rowInfo.original.id) !== -1) {
          return {
            className: 'rt-tr-group bg-highlight',
          };
        }
      }
    },
    [checkedPayments]
  );

  return (
    <Box sx={{ boxShadow: 2, padding: 2 }}>
      {isLoading && <Loader isFullScreen />}
      <Row>
        <Col>
          <CollapsableCard
            isExpanded
            headerTitle={
              <div className='d-flex align-items-center'>
                <span className='mr-3'>Checks and Payments</span>
                <div className='mr-3 flex-grow-1'>
                  {+checksAndPaymentsTableData?.total !== +balances.countChecks ? (
                    <Button variant='outlined' onClick={() => onMarkAllClick('checks')}>
                      Mark All
                    </Button>
                  ) : (
                    <Button variant='outlined' onClick={() => onUnmarkAllClick('checks')}>
                      Unmark All
                    </Button>
                  )}
                </div>
                <Tooltip title='Export To EXCEL' arrow>
                  <IconButton onClick={() => downloadExcel('checks')}>
                    <i className='fas fa-file-excel' style={{ color: '#ceac02', fontSize: '1.2rem', width: '20px', height: '20px' }} />
                  </IconButton>
                </Tooltip>
              </div>
            }
          >
            <DataGridComponent
              dataSource={checksAndPaymentsTableData || emptyGlTableData}
              onFilterChange={(e) => onTableFilterChange(e, 'checks')}
              columns={tableColumns}
              loading={false}
              getTrGroupProps={highlightTableRow}
            />
          </CollapsableCard>
        </Col>
        <Col>
          <CollapsableCard
            isExpanded
            headerTitle={
              <div className='d-flex align-items-center'>
                <span className='mr-3'>Deposits and Other Credits</span>
                <div className='mr-3 flex-grow-1'>
                  {+depositAndOtherCreditsTableData?.total !== +balances.countDeposit ? (
                    <Button variant='outlined' onClick={() => onMarkAllClick('deposits')}>
                      Mark All
                    </Button>
                  ) : (
                    <Button variant='outlined' onClick={() => onUnmarkAllClick('deposits')}>
                      Unmark All
                    </Button>
                  )}
                </div>
                <Tooltip title='Export To EXCEL' arrow>
                  <IconButton onClick={() => downloadExcel('deposits')}>
                    <i className='fas fa-file-excel' style={{ color: '#ceac02', fontSize: '1.2rem', width: '20px', height: '20px' }} />
                  </IconButton>
                </Tooltip>
              </div>
            }
          >
            <DataGridComponent
              dataSource={depositAndOtherCreditsTableData || emptyGlTableData}
              onFilterChange={(e) => onTableFilterChange(e, 'deposits')}
              columns={tableColumns}
              loading={false}
              getTrGroupProps={highlightTableRow}
            />
          </CollapsableCard>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col>
          <Paper elevation={2} sx={{ p: 1 }}>
            <Row className='d-flex align-items-end'>
              <Col md='4'>
                <div className='h5'>Items you have marked cleared:</div>
                <CalloutComponent className='d-flex flex-column' color={teal[800]}>
                  <span className='text-muted d-flex align-items-center'>
                    Deposits and Other Credits{' '}
                    <Avatar sx={{ bgcolor: '#ceac02', width: 26, height: 26, fontSize: 15, ml: 2 }}>
                      <strong>{balances.countDeposit}</strong>
                    </Avatar>
                  </span>
                  <span className='h5'>{formatAmount(balances.depositAndOtherCreditsBalance)}</span>
                </CalloutComponent>
                <CalloutComponent className='mt-2 d-flex flex-column' color={teal[800]}>
                  <span className='text-muted d-flex align-items-center'>
                    Checks and Payments{' '}
                    <Avatar sx={{ bgcolor: '#ceac02', width: 26, height: 26, fontSize: 15, ml: 2 }}>
                      <strong>{balances.countChecks}</strong>
                    </Avatar>
                  </span>
                  <span className='h5'>{formatAmount(balances.checksAndPaymentsBalance)}</span>
                </CalloutComponent>
              </Col>
              <Col md='4'>
                <CalloutComponent className='d-flex flex-column' color={cyan[800]}>
                  <span className='text-muted'>Ending GL Balance</span>
                  <span className='h5'>{formatAmount(bankRecoData?.ending_gl_balance)}</span>
                </CalloutComponent>
                <CalloutComponent className='mt-2 d-flex flex-column' color={cyan[800]}>
                  <span className='text-muted d-flex align-items-center'>
                    Ending Bank Statement Balance{' '}
                    {!editEndingBalance.is_edit ? (
                      <Tooltip title='Edit Ending Balance' placement='top' arrow followCursor>
                        <IconButton color='primary' sx={{ ml: 2 }} onClick={() => onEditingEndingBalance('edit')}>
                          <EditOutlined />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title='Save Ending Balance' placement='top' arrow followCursor>
                        <IconButton color='success' sx={{ ml: 2 }} onClick={() => onEditingEndingBalance('save')}>
                          <DoneOutlineOutlined />
                        </IconButton>
                      </Tooltip>
                    )}
                  </span>
                  <span className='h5'>
                    {!editEndingBalance.is_edit ? (
                      formatAmount(bankRecoData?.ending_balance)
                    ) : (
                      <CustomCurrencyInput
                        formClassName='m-0'
                        name='amount'
                        value={editEndingBalance.amount}
                        onChange={handleCurrencyInputChange}
                        prefix={'$ '}
                        placeholder='Ending Balance'
                      />
                    )}
                  </span>
                </CalloutComponent>
              </Col>
              <Col md='4'>
                <CalloutComponent className='d-flex flex-column' color={lightBlue[800]}>
                  <span className='text-muted'>Adjusted Bank Statement Balance</span>
                  <span className='h5'>{formatAmount(balances.adjustedBankStatementBalance)}</span>
                </CalloutComponent>
                <CalloutComponent className='mt-2 d-flex flex-column' color={lightBlue[800]}>
                  <span className='text-muted'>Difference</span>
                  <span className='h5'>{formatAmount(balances.differenceBalance)}</span>
                </CalloutComponent>
              </Col>
            </Row>
          </Paper>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col className='d-flex justify-content-between'>
          <Box>
            {!params.recoId && (
              <Button sx={{ backgroundColor: grey[800], '&:hover': { backgroundColor: grey[700] } }} onClick={onBackClick}>
                <i className='fas fa-angle-left mr-2'></i>Back
              </Button>
            )}
          </Box>
          <Box>
            <Button color='primary' sx={{ mr: 2 }} onClick={onSaveAndLeaveClick} disabled={!hasPermission(BANK_RECONCILIATION, EDIT)}>
              Save & Leave
            </Button>
            <Button color='success' onClick={onReconsileClick} disabled={!hasPermission(BANK_RECONCILIATION, EDIT)}>
              Reconcile Now
            </Button>
          </Box>
        </Col>
      </Row>
    </Box>
  );
};

const CalloutComponent = styled((props) => {
  return <div {...props} />;
})(({ theme, color }) => {
  const styles = {
    backgroundColor: 'inherit',
    borderLeft: `5px solid ${color}`,
    borderRadius: '6px',
    padding: '8px',
  };
  return styles;
});
