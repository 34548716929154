import React from 'react';
import { Row, Col } from 'reactstrap';
import { Button, IconButton } from '@mui/material';
import { ModeEditOutline, DeleteOutline, AddCircleOutline } from '@mui/icons-material';

import { CustomModal, useCustomModalToggle } from '../../../../../common/Dialog/CustomModal';
import { useUpdateSupportTableMutation, useCreateSupportTableMutation } from '../supportTableApiHooks';
import { CustomInput } from '../../../../../common/CustomInput';

const SupportTableForm = ({ action, filter, id, nameProps, disabled }) => {
  const [name, setName] = React.useState('');
  const { open, toggleCustomModal } = useCustomModalToggle();

  const cleanUp = React.useCallback(() => {
    toggleCustomModal();
    setName('');
  }, [toggleCustomModal]);

  const { mutate: createMutation } = useCreateSupportTableMutation(filter, cleanUp);
  const { mutate: updateMutation } = useUpdateSupportTableMutation(filter, cleanUp);

  React.useEffect(() => {
    setName(nameProps);
  }, [nameProps]);

  const handleChange = (e) => {
    const { value } = e.target;
    setName(value);
  };

  const onSave = () => {
    if (action === 'create') {
      createMutation({ name, table_name: filter.table_name });
    } else {
      updateMutation({ id, name, table_name: filter.table_name });
    }
  };

  return (
    <>
      {action === 'create' ? (
        <Button
          variant='contained'
          endIcon={<AddCircleOutline />}
          color='success'
          size='small'
          disabled={!filter.table_name || disabled}
          onClick={toggleCustomModal}
        >
          Add New Item
        </Button>
      ) : (
        <IconButton color='primary' onClick={toggleCustomModal} disabled={disabled}>
          <ModeEditOutline />
        </IconButton>
      )}
      <CustomModal
        modalTitle={action === 'create' ? 'Create Item' : 'Update Item'}
        isOpen={open}
        toggleCustomModal={toggleCustomModal}
        onSave={onSave}
      >
        <Row>
          <Col>
            <CustomInput value={name} onChange={handleChange} name='name' label='Enter Name' placeholder='Name' />
          </Col>
        </Row>
      </CustomModal>
    </>
  );
};

export default SupportTableForm;
