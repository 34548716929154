import { FormLabel, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export const CustomToggleButtonGroup = ({ label, name, value, onChange, size, disabled }) => {
  return (
    <>
      <FormLabel sx={{ display: 'block' }}>{label}</FormLabel>
      <ToggleButtonGroup color='primary' value={value} exclusive onChange={onChange} size={size} disabled={disabled}>
        <ToggleButton name={name} selected={value == undefined}>
          Both
        </ToggleButton>
        <ToggleButton name={name} value={true}>
          Yes
        </ToggleButton>
        <ToggleButton name={name} value={false}>
          No
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};

CustomToggleButtonGroup.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.objectOf([PropTypes.bool, null, undefined]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

CustomToggleButtonGroup.defaultProps = {
  disabled: false,
  checked: false,
  size: 'small',
};
