import axios from 'axios';
import { saveAs } from 'file-saver';

export const downloaderFiles = async ({ url, type, filter, cbLoad, name = 'CompassAccounting', page }) => {
  if (type.toString() === 'PDF' || type.toString() === 'EXCEL') {
    let filename = name;
    filename += type.toString() === 'EXCEL' ? '.xlsx' : '.pdf';

    cbLoad && cbLoad(true);
    const f = { ...filter, exportType: type };
    if (page) {
      f.exportPage = page;
    }

    await axios
      .get(url, { params: { ...f }, responseType: 'blob' })
      .then((r) => {
        if (r.status === 200) {
          saveAs(new Blob([r.data]), filename);
        }
      })
      .finally((r) => {
        cbLoad && cbLoad(false);
      });
  }
};

/** for react-query use */
export const downloader = async ({ url, transactionType, fileType, name = 'CompassAccounting' }) => {
  if (fileType.toString() === 'PDF' || fileType.toString() === 'EXCEL') {
    let filename = name;
    filename += fileType.toString() === 'EXCEL' ? '.xlsx' : '.pdf';

    return axios.get(url, { responseType: 'blob', params: { type: transactionType } }).then((res) => {
      if (res.status === 200) {
        if (res.headers['content-disposition']) {
          const [_, originalFileName] = res.headers['content-disposition'].split('filename=');
          filename = originalFileName;
        }

        saveAs(new Blob([res.data]), filename);
      }
    });
  }
};

export const customDownloader = async ({ url, params = {}, fileType, name = 'CompassAccounting' }) => {
  if (fileType.toString() === 'PDF' || fileType.toString() === 'EXCEL') {
    let filename = name;
    filename += fileType.toString() === 'EXCEL' ? '.xlsx' : '.pdf';

    return axios.get(url, { responseType: 'blob', params }).then((res) => {
      if (res.status === 200) {
        if (res.headers['content-disposition']) {
          const [_, originalFileName] = res.headers['content-disposition'].split('filename=');
          filename = originalFileName;
        }

        saveAs(new Blob([res.data]), filename);
      }
    });
  }
};
