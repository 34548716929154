import { Divider } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { formatAmount } from '../../../../../../../utils/formatAmout';
import { formatDateForTable } from '../../../../../../../utils/formatDataForTable';

const StyledContainer = styled('div')({
  '&::-webkit-scrollbar': {
    width: '5px',
    backgroundColor: 'rgba(206, 172, 2, 1)',
    borderRadius: '1ex',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'gold',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: 'goldenrod',
  },
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  maxHeight: '400px',
  overflow: 'auto',
});
const StyledTable = styled('table')({ border: '1px solid white', marginTop: '1rem', fontSize: '12px', marginLeft: '5px', marginRight: '5px' });
const StyledTableCaption = styled('caption')({ captionSide: 'top', fontStyle: 'italic', fontSize: '14px' });
const StyledTableRow = styled('tr')({ border: '1px solid white' });
const StyledTableColumn = styled('td')({ border: '1px solid white' });
const StyledTh = styled('td')({ border: '1px solid white' });
const StyledTableHeader = styled('thead')({
  backgroundColor: 'rgba(206, 172, 2, 1)',
  color: 'white',
  fontWeight: 'bolder',
});

export const UnpaidTransactionsInfo = ({ data = {} }) => {
  if (!data.data.length) return <></>;

  return (
    <>
      <Row>
        <Col>
          <div>
            <strong>No. of Invoices</strong>: {data.totalInvoices}
          </div>
          <div>
            <strong>Total Amount</strong>: {formatAmount(data.totalAmount)}
          </div>
        </Col>
      </Row>
      <Divider />
      <StyledContainer>
        {Object.keys(data.data).map((vendorId) => (
          <>
            <Row className='m-0 p-0'>
              <Col className='m-0 p-0'>
                <StyledTable>
                  <StyledTableCaption>
                    <span style={{ fontWeight: 'bolder' }}>{data.data[vendorId][['client.name']]}</span> ({data.data[vendorId].count} unit
                    {data.data[vendorId].invoices.length > 1 ? 's' : ''}) {formatAmount(data.data[vendorId].total)}
                  </StyledTableCaption>
                  <StyledTableHeader>
                    <StyledTableRow>
                      <StyledTh>Document Number</StyledTh>
                      <StyledTh>Date</StyledTh>
                      <StyledTh>Balance Due</StyledTh>
                      <StyledTh>Due Date</StyledTh>
                    </StyledTableRow>
                  </StyledTableHeader>
                  <tbody>
                    {data.data[vendorId].invoices.map((invoice) => (
                      <StyledTableRow>
                        <StyledTableColumn>
                          <span>{invoice['transactions.document_number']}</span>
                        </StyledTableColumn>
                        <StyledTableColumn>
                          <span>{invoice['transactions.document_date'] ? formatDateForTable(invoice['transactions.document_date']) : ''}</span>
                        </StyledTableColumn>
                        <StyledTableColumn>
                          <span>{formatAmount(invoice['transactions_due_date.balance'])}</span>
                        </StyledTableColumn>
                        <StyledTableColumn>
                          <span>
                            {invoice['transactions_due_date.due_date'] ? formatDateForTable(invoice['transactions_due_date.due_date']) : ''}
                          </span>
                        </StyledTableColumn>
                      </StyledTableRow>
                    ))}
                  </tbody>
                </StyledTable>
              </Col>
            </Row>
          </>
        ))}
      </StyledContainer>
    </>
  );
};
