import axios from 'axios';

const useAsyncPageable = () => {
  const fetchOptions = async (url, options) => {
    const data = await axios.get(url, { params: { ...options } });
    if (data.status === 200) {
      const { options: selectOptions, hasMore } = data.data;
      return { options: selectOptions || [], hasMore: hasMore || false };
    } else {
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  return { fetchOptions };
};

export { useAsyncPageable };
