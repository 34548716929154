import React from 'react';
import { formatDateForTable } from '../../../../../utils/formatDataForTable';
import { formatAmount } from '../../../../../utils/formatAmout';
import { EditDocumentNumberAction } from '../../../Client/Client360/component/transaction/EditDocumentNumberAction';

// utils
const isVoidVendorPayment = (row) => {
  return row.original['transactions.void'];
};

const isCheckVoided = (row) => {
  return row.original.vendor_payment_check?.is_check && row.original.vendor_payment_check?.voided;
};

const shouldHidePaymentActionsAndDescription = (row) => isVoidVendorPayment(row) || isCheckVoided(row);

// columns
export const TransactionsDataTableColumns = ({ Actions, refetch, filter }) => {
  return [
    {
      accessor: (d) => d['client.external_id'],
      id: 'client.external_id',
      Header: <div>Client Number</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['client.rmi_number'],
      id: 'client.rmi_number',
      Header: <div>RMI No.</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['client.name'],
      id: 'client.name',
      Header: <div>Client</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['transactions.posting_date'],
      id: 'transactions.posting_date',
      Header: <div>Posting Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['document_type.name'],
      id: 'document_type.name',
      Header: <div>Type</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['transactions.document_number'],
      id: 'transactions.document_number',
      Header: <div>Document Number</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: false,
    },

    {
      accessor: (d) => d['transactions.document_date'],
      id: 'transactions.document_date',
      Header: <div>Invoice Date</div>,
      sortable: true,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },

    {
      accessor: (d) => d['transactions.amount'],
      id: 'transactions.amount',
      Header: <div>Amount</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['transactions_balances.balance'],
      id: 'transactions_balances.balance',
      Header: <div>Remaining Balance</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['transactions_due_date.due_date'],
      id: 'transactions_due_date.due_date',
      Header: <div>Invoice Due Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['transactions.create_at'],
      id: 'transactions.create_at',
      Header: <div>Created On</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['users.name'],
      id: 'users.name',
      Header: <div>Created By</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: true,
    },

    {
      id: 'actions',
      Header: <div>Actions</div>,
      Cell: (row) => {
        const documentNumber = row.original['transactions.document_number'];
        const clientId = row.original['client.id'];
        const transactionsId = row.original['transactions.id'];

        if (!Actions || (row?.original['document_type.name']?.includes('Vendor Payment') && shouldHidePaymentActionsAndDescription(row))) {
          return <></>;
        } else if (Actions) {
          return (
            <div>
              {React.cloneElement(Actions, { row })}{' '}
              <EditDocumentNumberAction
                documentNumber={documentNumber}
                clientId={clientId}
                transactionsId={transactionsId}
                refetch={refetch}
                filter={filter}
              />
            </div>
          );
        }
      },
      sortable: false,
      minWidth: 160,
      maxWidth: 160,
    },
  ];
};

export const transactionExcelHeader = [
  { header: 'CLIENT NO.', key: 'client.external_id' },
  { header: 'RMI NO.', key: 'client.rmi_number' },
  { header: 'CLIENT', key: 'client.name' },
  { header: 'POSTING DATE', key: 'transactions.posting_date', style: { numFmt: 'MM/DD/YYYY' } },
  { header: 'TYPE', key: 'document_type.name' },
  { header: 'DOCUMENT NUMBER', key: 'transactions.document_number' },
  { header: 'INVOICE DATE', key: 'transactions.document_date', style: { numFmt: 'MM/DD/YYYY' } },
  { header: 'AMOUNT', key: 'transactions.amount', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
  {
    header: 'BALANCE',
    key: 'transactions_balances.balance',
    style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' },
  },
  { header: 'INVOICE DUE DATE', key: 'transactions_due_date.due_date', style: { numFmt: 'MM/DD/YYYY' } },
  { header: 'CREATE ON', key: 'transactions.create_at', style: { numFmt: 'MM/DD/YYYY' } },
  { header: 'CREATE BY', key: 'users.name' },
];
