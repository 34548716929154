import React, { useEffect, useState } from 'react';
import { CustomCheckbox } from '../../../../../../common/Inputs/CustomCheckbox';
import DataTable from '../../../../../../component/DataTable/component/DataTableComponent';
import { formatAmount } from '../../../../../../utils/formatAmout';
import { formatDateForTable } from '../../../../../../utils/formatDataForTable';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';

export const DetailedTransactionsTable = ({ dataSource, onSelectChange = () => {}, selectedChildClients }) => {
  return (
    <>
      <DataTable
        noPagination
        dataSource={dataSource}
        onFilterChange={() => {}}
        columns={detailedTransactionsTableColumns({
          SelectChildClient: (
            <MemoizedSelectChildClient name='selectChildClient' onChange={onSelectChange} selectedChildClients={selectedChildClients} />
          ),
        })}
      />
    </>
  );
};

const SelectChildClient = ({ row, name, onChange, selectedChildClients = [] }) => {
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (row?.original) {
      setChecked(!!selectedChildClients.includes(row?.original['client.id']));
      const isReversed = row.original?.detailed_gls?.length && row.original.detailed_gls[0]?.is_reversed;

      setDisabled(isReversed);
    }
  }, [selectedChildClients, row]);

  return <CustomCheckbox name={name} checked={checked} disabled={disabled} onChange={() => onChange(row)} />;
};

const MemoizedSelectChildClient = React.memo(SelectChildClient);

const detailedTransactionsTableColumns = ({ SelectChildClient }) => {
  const reversedColumn = (row) => {
    return (
      <div className='d-flex justify-content-center'>
        {row?.original?.detailed_gls?.length && row.original.detailed_gls[0].is_reversed ? (
          <CheckIcon color='success' />
        ) : (
          <RemoveIcon color='error' />
        )}
      </div>
    );
  };

  return [
    {
      id: 'selectedChildClient',
      Cell: (row) =>
        SelectChildClient && <div className='d-flex justify-content-center align-items-center'>{React.cloneElement(SelectChildClient, { row })}</div>,
      sortable: false,
      width: 50,
    },
    {
      accessor: (d) => d?.detailed_gls[0]?.is_reversed,
      id: 'is_reversed',
      Header: <div>Reversed</div>,
      Cell: reversedColumn,
      sortable: true,
    },
    {
      accessor: (d) => d['transactions.posting_date'],
      id: 'transactions.posting_date',
      Header: <div>Posting Date</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['client.name'],
      id: 'client.name',
      Header: () => <div>Client</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: false,
    },
    {
      accessor: (d) => d['transactions.document_date'],
      id: 'transactions.document_date',
      Header: () => <div>Document Date</div>,
      sortable: true,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },

    {
      accessor: (d) => d['transactions.amount'],
      id: 'transactions.amount',
      Header: () => <div>Amount</div>,
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
      sort: true,
    },
    {
      accessor: (d) => d['transactions.create_at'],
      id: 'transactions.create_at',
      Header: <div>Created On</div>,
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
      sortable: true,
    },
    {
      accessor: (d) => d['users.name'],
      id: 'users.name',
      Header: <div>Created By</div>,
      Cell: (row) => <div>{row.value}</div>,
      sortable: true,
    },
  ];
};
