import React, { Fragment } from 'react';
import { Col, Row, Button } from 'reactstrap';
import { CustomSelect } from '../../../../common/CustomSelect';
import { documentTypeOptions, getDocumentType } from '../../../../constants/documentType.constants';
import { CustomPageableAsyncSelect } from '../../../../common/Inputs/CustomPageableAsyncSelect';
import { useAsyncPageable } from '../../../../hooks/useAsyncPageable';
import { CustomInput } from '../../../../common/CustomInput';
import { DatePickerComponent } from '../../../../common/DatePicker/DatePicker';
import { useGetSupportTableForSelect } from '../../Settings/SupportTables/supportTableApiHooks';
import { useSelectCreatedBy } from '../journalEntryApiHooks';

export default function JournalEntryFilter({ filter, blankFilter, onFilterChangeHandler, onSearchClick, setFilter }) {
  const { fetchOptions } = useAsyncPageable();

  const selectHandlerDocumentType = (val, e) => {
    const docTypeValue = getDocumentType(val?.value || null);
    setFilter((oldFilter) => ({
      ...oldFilter,
      [e.name]: val?.value || null,
      ...docTypeValue,
    }));
  };

  const selectHandler = (val, e) => {
    let o;
    if (e.name === 'transactions.document_number') {
      o = { target: { name: e.name, value: val ? val.label : null } };
    } else {
      o = { target: { name: e.name, value: val ? val.value : null } };
    }
    onFilterChangeHandler(o);
  };

  const onInputChange = (e) => {
    setFilter((oldFilter) => ({
      ...oldFilter,
      [e.target.name]: e.target.value,
    }));
  };

  const onDateChange = (date, name) => {
    setFilter((oldFilter) => ({
      ...oldFilter,
      [name]: date,
    }));
  };

  const loadAccountOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/account/options/accounts', { q: inputValue, ...options });
    return data;
  };

  const loadDocumentNumberOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/transaction/options-for-select-dn', { q: inputValue, ...options });
    return data;
  };

  const loadSubaccountOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/client/customer/options-number-for-select', { q: inputValue, ...options });
    return data;
  };

  const loadVendorParentOptions = async (inputValue, options) => {
    return fetchOptions('/me/client/vendor/options-vendor-parent-for-select', { q: inputValue, ...options });
  };

  const loadVendorChildrenOptions = async (inputValue, options) => {
    return fetchOptions('/me/client/vendor/options/children', { q: inputValue, ...options });
  };

  const loadFixedAssetOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/journal-entry/fixed-assets-select', { q: inputValue, ...options });
    return data;
  };

  const loadRentalOrderNumberOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/journal-entry/rental-order-number', { q: inputValue, ...options });
    return data;
  };

  const { data: { data: supportTables = {} } = {} } = useGetSupportTableForSelect(['branch']);
  const { data: { data: optionsCreatedBy } = {} } = useSelectCreatedBy();

  return (
    <Fragment>
      <Row>
        <Col md={3}>
          <CustomPageableAsyncSelect
            label='Document Number'
            isClearable
            onChange={selectHandler}
            loadOptions={loadDocumentNumberOptions}
            name='transactions.document_number'
            defaultValue={filter['transactions.document_number']}
            disabled={!!blankFilter['transactions.document_number']}
          />
        </Col>
        <Col md={3}>
          <CustomPageableAsyncSelect
            label='Subaccount'
            isClearable
            onChange={selectHandler}
            loadOptions={loadSubaccountOptions}
            name='client_transactions.client_id'
            defaultValue={filter['client_transactions.client_id']}
            disabled={!!blankFilter['client_transactions.client_id']}
          />
        </Col>

        <Col md={3}>
          <CustomPageableAsyncSelect
            label='Account (From)'
            isClearable
            placeholder='From'
            onChange={selectHandler}
            loadOptions={loadAccountOptions}
            name='account.number_from'
            defaultValue={filter['account.number_from']}
            disabled={!!blankFilter['account.number_from']}
          />
        </Col>
        <Col md={3}>
          <CustomPageableAsyncSelect
            label='Account (To)'
            placeholder='To'
            isClearable
            onChange={selectHandler}
            loadOptions={loadAccountOptions}
            name='account.number_to'
            defaultValue={filter['account.number_to']}
            disabled={!!blankFilter['account.number_to']}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <CustomSelect
            label='Transactions'
            isClearable
            onChange={selectHandlerDocumentType}
            name='document_type'
            defaultValue={filter['document_type']}
            options={documentTypeOptions || []}
            disabled={!!blankFilter['document_type']}
          />
        </Col>
        <Col md={3}>
          <CustomPageableAsyncSelect
            label='Fixed Asset'
            isClearable
            onChange={selectHandler}
            loadOptions={loadFixedAssetOptions}
            name='stock_no'
            defaultValue={filter['stock_no']}
            disabled={!!blankFilter['stock_no']}
          />
        </Col>
        <Col md={3}>
          <DatePickerComponent
            showYearDropdown
            isClearable
            name='posting_date.from'
            selected={filter['posting_date.from']}
            onChange={(date) => onDateChange(date, 'posting_date.from')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Posting Date ( From )'
          />
        </Col>
        <Col md={3}>
          <DatePickerComponent
            showYearDropdown
            isClearable
            name='posting_date.to'
            selected={filter['posting_date.to']}
            onChange={(date) => onDateChange(date, 'posting_date.to')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Posting Date ( To )'
          />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <CustomPageableAsyncSelect
            label='Rental Order Number'
            isClearable
            onChange={selectHandler}
            loadOptions={loadRentalOrderNumberOptions}
            name='order_number'
            defaultValue={filter['order_number']}
            disabled={!!blankFilter['order_number']}
          />
        </Col>
        <Col md={3}>
          <CustomInput
            name='general_ledger.debit_amount'
            value={filter['general_ledger.debit_amount']}
            onChange={onInputChange}
            placeholder='Debit Amount'
            label='Debit Amount'
          />
        </Col>
        <Col md={3}>
          <CustomPageableAsyncSelect
            placeholder='From'
            label='Document Number Range ( From )'
            isClearable
            onChange={selectHandler}
            loadOptions={loadDocumentNumberOptions}
            name='transactions.document_number_from'
            defaultValue={filter['transactions.document_number_from']}
            disabled={!!blankFilter['transactions.document_number_from']}
          />
        </Col>
        <Col md={3}>
          <CustomPageableAsyncSelect
            placeholder='To'
            label='Document Number Range ( To )'
            isClearable
            onChange={selectHandler}
            loadOptions={loadDocumentNumberOptions}
            name='transactions.document_number_to'
            defaultValue={filter['transactions.document_number_to']}
            disabled={!!blankFilter['transactions.document_number_to']}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <CustomInput
            name='general_ledger.credit_amount'
            value={filter['general_ledger.credit_amount']}
            onChange={onInputChange}
            placeholder='Credit Amount'
            label='Credit Amount'
          />
        </Col>
        <Col md={3}>
          <CustomInput
            name='general_ledger.description'
            value={filter['general_ledger.description']}
            onChange={onInputChange}
            placeholder='Enter Partial Description'
            label='Description Search'
          />
        </Col>
        <Col md={3}>
          <DatePickerComponent
            showYearDropdown
            isClearable
            name='created_at.from'
            selected={filter['created_at.from']}
            onChange={(date) => onDateChange(date, 'created_at.from')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Created At ( From )'
          />
        </Col>
        <Col md={3}>
          <DatePickerComponent
            showYearDropdown
            isClearable
            name='created_at.to'
            selected={filter['created_at.to']}
            onChange={(date) => onDateChange(date, 'created_at.to')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Created At ( To )'
          />
        </Col>
      </Row>
      <Row>
        <Col md='3'>
          <CustomSelect
            isClearable
            label='Branch'
            defaultValue={filter['general_ledger.branch_search']}
            onChange={selectHandler}
            name='general_ledger.branch_search'
            options={supportTables['branch'] || []}
          />
        </Col>
        <Col md={3}>
          <CustomSelect
            label='Created By'
            isClearable
            onChange={selectHandler}
            options={optionsCreatedBy || []}
            name='general_ledger.created_by_user'
            defaultValue={filter['general_ledger.created_by_user']}
            disabled={!!blankFilter['general_ledger.created_by_user']}
          />
        </Col>
        <Col md={3}>
          <CustomPageableAsyncSelect
            label='Vendor Parent'
            isClearable
            placeholder='Parent'
            onChange={selectHandler}
            loadOptions={loadVendorParentOptions}
            name='vendor_parent'
            defaultValue={filter.vendor_parent}
            disabled={!!blankFilter.vendor_parent}
          />
        </Col>
        <Col md={3}>
          <CustomPageableAsyncSelect
            label='Child Vendor'
            isClearable
            placeholder='Child'
            onChange={selectHandler}
            loadOptions={loadVendorChildrenOptions}
            name='child_vendor'
            defaultValue={filter.child_vendor}
            disabled={!!blankFilter.child_vendor}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} className='d-flex justify-content-end'>
          <Button onClick={onSearchClick}>Search</Button>
        </Col>
      </Row>
    </Fragment>
  );
}
