export const CASH = 1;
export const CHECK = 2;
export const CREDIT_CARD = 3;
export const ACH = 4;
export const E_PAYMENT = 5;
export const WIRE = 7;
export const ACH_NOTES = 8;
export const AUTOPAY = 9;

export const BOUNCED_ACH = 10;
export const BOUNCED_CHECK = 11;
export const BOUNCED_WIRE = 12;

export const NEGATIVE_PAYMENT_TYPE_SOURCES = [BOUNCED_ACH, BOUNCED_CHECK, BOUNCED_WIRE];
