import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { CollapsableCard } from '../../../../../../common/Card/CollapsableCard';
import { CustomCard } from '../../../../../../common/Card/CustomCard';
import ExportButton from '../../../../../../component/Buttons/ExportButton';
import TransactionsDataTable from '../../../../../../component/DataTable/TransactionsDataTable';
import { downloaderFiles } from '../../../../../../utils/downloaderFiles';
import { exportExcel } from '../../../../../../utils/exportExcel';
import { exportPdf } from '../../../../../../utils/exportPdf';
import { transactionTableTypes } from '../../../constants';
import { useClientTableColumns } from '../../../useClientTableColumns';
import { ClientTransactionsFilter } from './ClientTransactionsFilter';
import { useClientTransactions } from './clientTransactionsHooks';
import { clientTransactionsExcelHeaders, vendorTransactionsExcelHeaders } from './excelHeaders';
import { TransactionTableActions } from './TransactionTableActions';
import { PaymentsTableActions } from './payment/PaymentsTableActions';

export default function ClientTransactionComponent() {
  const { clientType, clientId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const blankFilter = useMemo(() => {
    return {
      'transactions.document_type_journal_entry_id': '',
      'transactions.document_type_domain_id': '',
      'client.external_id': null,
      'client.rmi_number': null,
      'transactions.document_number': null,
      'transactions.status': '',
      posting_date_from: null,
      posting_date_to: null,
      invoice_date_from: null,
      invoice_date_to: null,
      invoice_due_date_from: null,
      invoice_due_date_to: null,
      start: '',
      end: '',
      page: 0,
      pageSize: 10,
      sort_by: 'transactions.id',
      order_by: 'DESC',
      order_number: null,
      stock_no: null,
    };
  }, []);
  const blankData = useMemo(() => {
    return { data: [], page: 0, pageSize: 10, pageTotal: 0 };
  }, []);

  const [filter, setFilter] = useState(blankFilter);
  const [tableData, setTableData] = useState(blankData);
  const [loader, setLoader] = useState(false);
  const [container, setContainer] = useState();

  const url = useMemo(() => '/me/client/' + clientType.toLowerCase().slice(0, -1) + '/' + clientId + '/transactions', [clientType, clientId]);

  const {
    data: transactionsTableData,
    isLoading,
    refetch: refetchClientTransactions,
  } = useClientTransactions(clientId, clientType, filter, location);

  const { columns: tableColumns } = useClientTableColumns(
    clientType,
    transactionTableTypes.transactions,
    <TransactionTableActions />,
    <PaymentsTableActions />,
    refetchClientTransactions,
    filter
  );

  useEffect(() => {
    if (transactionsTableData?.data) {
      if (transactionsTableData?.status === 200) {
        setTableData(transactionsTableData.data);
      }
    }
  }, [transactionsTableData?.data]);

  useEffect(() => {
    setLoader(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setFilter(blankFilter);
  }, [clientId]);

  const onFilterHandler = (e) => {
    try {
      if (e.target.value.constructor === {}.constructor) {
        const f = { ...filter, ...e.target.value };
        setFilter(f);
      } else {
        const f = { ...filter, [e.target.name]: e.target.value };
        setFilter(f);
      }
    } catch (error) {
      const f = { ...filter, [e.target.name]: e.target.value };
      setFilter(f);
    }
  };

  const onFilterChange = (input) => {
    const f = { ...filter, ...input };
    setFilter(f);
  };

  const handleChange = (e) => {
    try {
      if (e.target.value.constructor === {}.constructor) {
        setFilter((prev) => ({ ...prev, ...e.target.value }));
      } else {
        setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      }
    } catch (error) {
      setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const handleSelectChange = (val, e) => {
    if (e.action === 'select-option') {
      let o = { target: { name: e.name, value: val.value || val.id } };
      handleChange(o);
      return;
    }
    if (e.action === 'clear') {
      let o = { target: { name: e.name, value: null } };
      handleChange(o);
    }
  };

  const handleDateChange = (date, name) => {
    setFilter((prev) => ({
      ...prev,
      [name]: date,
    }));
  };

  const onFilterActionBtn = (e) => {
    if (e.toString() === 'PDF' || e.toString() === 'EXCEL') {
      downloaderFiles({ filter, type: e, url, cbLoad: setLoader, name: 'Client Transactions' });
    }
    if (e.toString() === 'reset') {
      history.push(location.pathname);
      setFilter(blankFilter);
    }
    if (e.toString() === 'search') refetchClientTransactions();
  };

  return (
    <>
      <Row>
        <Col>
          <CustomCard
            headerTitle={
              <div className='d-flex align-items-center justify-content-between'>
                <span>Transactions</span>
                <ExportButton
                  disabled={loader}
                  exportTablePDF={() => {
                    onFilterActionBtn('PDF');
                  }}
                  exportTableEXCEL={() => {
                    onFilterActionBtn('EXCEL');
                  }}
                  exportPagePDF={(e) => exportPdf(container, 'Client-' + clientId + '-Transactions')}
                  exportPageEXCEL={(e) =>
                    exportExcel(
                      tableData.data,
                      clientType === 'vendors'
                        ? [
                            { header: 'VENDOR', key: 'client.name' },
                            { header: 'VENDOR ID', key: 'client.external_id' },
                            { header: 'RMI ID', key: 'client.rmi_number' },
                            { header: 'VENDOR PARENT', key: 'parent_number' },
                            ...clientTransactionsExcelHeaders.slice(1).map((item) => item),
                          ]
                        : clientTransactionsExcelHeaders,
                      'Client-' + clientId + '-Transactions'
                    )
                  }
                />
              </div>
            }
            sxCardContainer={{ mt: 2 }}
          >
            <Row className='mb-3'>
              <Col md={12}>
                <CollapsableCard
                  headerTitle='Filters'
                  isExpanded={false}
                  titleTypographyProps={{ fontSize: '18px' }}
                  sxCardContent={{ padding: '16px' }}
                  sxCardHeader={{ padding: '16px' }}
                >
                  <ClientTransactionsFilter
                    loader={loader}
                    clientType={clientType}
                    filter={filter}
                    blankFilter={blankFilter}
                    onFilterActionClick={onFilterActionBtn}
                    onFilterChangeHandler={onFilterHandler}
                    onChange={handleChange}
                    onSelectChange={handleSelectChange}
                    onDateChange={handleDateChange}
                    transactionType={transactionTableTypes.transactions}
                  />
                </CollapsableCard>
              </Col>
            </Row>
            <div
              className='row'
              ref={(instance) => {
                setContainer(instance);
              }}
            >
              <Col>
                <TransactionsDataTable loader={loader} dataSource={tableData} onFilterChange={onFilterChange} columns={tableColumns} />
              </Col>
            </div>
          </CustomCard>
        </Col>
      </Row>
    </>
  );
}
