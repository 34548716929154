import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const getTableData = (filter) => {
  return axios.get(`me/account/chart-of-account/`, { params: { ...filter } });
};

const deleteAccount = (id) => {
  if (id) {
    return axios.delete(`me/account/${id}`);
  }
};

const canDeleteAccount = (id) => {
  if (id) {
    return axios.get(`me/account/can-delete/${id}`);
  }
};

export const useChartOfCAccountTableData = (filter) => {
  const { page, pageSize, sort_by, order_by } = filter;
  return useQuery(['accounts-table', page, pageSize, sort_by, order_by], () => getTableData(filter), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

export const useCanDeleteAccount = (id) => {
  return useQuery(['account.canDelete'], () => canDeleteAccount(id), {
    enabled: false,
    keepPreviousData: false,
  });
};

export const useDeleteAccount = (filter) => {
  const { page, pageSize, sort_by, order_by } = filter;
  const queryClient = useQueryClient();

  return useMutation(deleteAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries(['accounts-table', page, pageSize, sort_by, order_by]);
    },
  });
};

const getAccount = (id) => {
  if (id || id === 0) return axios.get(`/me/account/${id}`);
};

export const useAccount = (id) => {
  return useQuery(['account', id], () => getAccount(id), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const createOrUpdateAccount = (id, data) => {
  if (id || id === 0) {
    return axios.patch(`/me/account/${id}`, data);
  } else {
    return axios.post('/me/account', data);
  }
};

export const useCreateOrUpdateAccount = ({ successCallback, errorCallback }) => {
  const queryClient = useQueryClient();

  return useMutation(({ id, data }) => createOrUpdateAccount(id, data), {
    onSuccess: () => {
      successCallback();
      queryClient.invalidateQueries(['account']);
      queryClient.invalidateQueries(['accounts-table']);
    },
    onError: () => {
      errorCallback();
    },
  });
};
