import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  padding: '2px',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const StyledCard = styled((props) => <Card {...props} />)(({ addStyles }) => {
  let styles = {
    maxWidth: '100%',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: '8px',
    boxShadow: 'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px',
  };

  if (addStyles) {
    styles = { ...styles, ...addStyles };
  }

  return styles;
});

const StyledCardHeader = styled(CardHeader)(() => ({
  backgroundColor: '#2f353a',
  color: '#fff',
  padding: '10px',
}));

const StyledCardContent = styled(CardContent)(() => ({
  padding: '10px',
}));

const CollapsableCard = ({
  headerTitle,
  children,
  isExpanded,
  titleTypographyProps,
  sxCardContent,
  sxCardHeader,
  disableOpen,
  hideActions,
  addStyles,
}) => {
  const [expanded, setExpanded] = useState(isExpanded);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <StyledCard addStyles={addStyles}>
      <StyledCardHeader
        sx={sxCardHeader}
        title={headerTitle}
        titleTypographyProps={{ fontSize: '13px', ...titleTypographyProps }}
        action={
          !hideActions && (
            <ExpandMore expand={expanded} onClick={handleExpandClick} disabled={disableOpen}>
              <ExpandMoreIcon sx={{ color: '#fff' }} />{' '}
            </ExpandMore>
          )
        }
      >
        cap
      </StyledCardHeader>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <StyledCardContent sx={sxCardContent}>{children}</StyledCardContent>
      </Collapse>
    </StyledCard>
  );
};

CollapsableCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  headerTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  isExpanded: PropTypes.bool,
  titleTypographyProps: PropTypes.object,
  sxCardContent: PropTypes.object,
  sxCardHeader: PropTypes.object,
  disableOpen: PropTypes.bool,
  hideActions: PropTypes.bool,
};

CollapsableCard.defaultProps = {
  isExpanded: true,
  titleTypographyProps: {},
  sxCardContent: {},
  sxCardHeader: {},
  disableOpen: false,
  hideActions: false,
};

export { CollapsableCard };
