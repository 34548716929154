import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import { Button as MuiButton, Tooltip, Box, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { useHistory, useParams } from 'react-router-dom';

import { CollapsableCard } from '../../../common/Card/CollapsableCard';
import { useGetRecurringJL, useCreateRecurringJLMutation, useUpdateRecurringJLMutation, usePostRecurring, useGetBankAccount } from './apiHooks';
import { notificationDanger, notificationDangerCustomTime, notificationSuccess } from '../../../utils/toastify';
import { CustomSelect } from '../../../common/CustomSelect';
import { CustomInput } from '../../../common/CustomInput';
import { DatePickerComponent } from '../../../common/DatePicker/DatePicker';
import {
  recurringTransactionTypesOptions,
  getRecurringTransactionTypes,
  VENDOR_INVOICE,
  CUSTOMER_INVOICE,
  CUSTOMER_CREDIT_MEMO,
  VENDOR_CREDIT_MEMO,
  CUSTOMER_PAYMENT,
  VENDOR_PAYMENT,
  CUSTOMER_REVERSING_PAYMENT,
  VENDOR_REVERSING_PAYMENT,
} from '../../../constants/transactionTypesRecurring.constants';
import { frequencyRecurringOptions, reminderRecurringOptions } from '../../../constants/dateRange.constants';
import { useGetUsersFroSelect } from '../Settings/User/apiHooks';
import { RecurringJournalEntries } from './components/RecurringJournalEntries';
import { Loader } from '../../../common/Loader';
import { getAccountType } from '../../../constants/accountType.constants';
import { RM_FIXED, RM_REVERSING_FIXED, RM_REVERSING_VARIABLE, RM_VARIABLE, getRecurringMethod } from '../../../constants/recurringMethods.constants';
import { hasPermissionHook } from '../../../hooks/hasPermission';
import { EDIT, RECURRING_JOURNAL_ENTRY } from '../../../constants/permissions.constants';
import { PAYMENT_SOURCE } from '../../../constants/supportTables.constants';
import { useGetSupportTablesForSelect } from '../../../hooks/commonApiHooks';

const AddEditComponentRecurrinEntry = () => {
  const { hasPermission, isPostingDisabled } = hasPermissionHook();
  const history = useHistory();
  const { recurringId } = useParams();
  const [recurringData, setRecurringData] = React.useState({});
  const [tableData, setTableData] = React.useState([]);
  const [validationErrors, setValidationErrors] = React.useState({});

  const successCreateCallback = (id) => {
    history.push(`/recurring/${id}`);
    notificationSuccess('Succesfully created Recurring JL!');
  };

  const successUpdateCallback = (id) => {
    notificationSuccess('Succesfully updated!');
  };

  const onErrorResponseCallback = (responseErrors) => {
    notificationDangerCustomTime(responseErrors, 10000);
  };

  const { data: bankAccounts } = useGetBankAccount();
  const { data: { data: supportTableOptions = {} } = {} } = useGetSupportTablesForSelect({
    table_names: [PAYMENT_SOURCE],
  });
  const { data: { data: usersForSelectOptions } = {} } = useGetUsersFroSelect();
  const { mutate: createRecurringJLMutation, isLoading: createIsLoading } = useCreateRecurringJLMutation(successCreateCallback);
  const { mutate: updateRecurringJLMutation, isLoading: updateIsLoading } = useUpdateRecurringJLMutation(successUpdateCallback);
  const { data: { data: recurringDataDB } = {} } = useGetRecurringJL(recurringId);
  const { mutate: postRecurringMutation, isLoading: postIsLoading } = usePostRecurring(notificationSuccess, onErrorResponseCallback);

  React.useEffect(() => {
    if (recurringDataDB) {
      const { selected_items, nonselected_items, ...rest } = recurringDataDB;
      setRecurringData(rest);
      let calcTableData = [];
      if (selected_items) calcTableData = selected_items;
      if (nonselected_items) calcTableData = [...calcTableData, ...nonselected_items];
      setTableData(calcTableData);
    }
  }, [recurringDataDB]);

  const changeInput = (e) => {
    setRecurringData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const changeSelect = (val, e) => {
    setRecurringData((prev) => ({ ...prev, [e.name]: val?.value ? val.value : null }));
  };

  const handleDateChange = (date, name) => {
    setRecurringData((prev) => ({ ...prev, [name]: date }));
  };

  const changeMultiSelect = (val, e) => {
    setRecurringData((prev) => ({ ...prev, [e.name]: val ? val.map((v) => v.value) : [] }));
  };

  const backHandler = () => {
    history.push('/recurring');
  };

  const totalData = React.useMemo(() => {
    let totalDebit = 0;
    let totalCredit = 0;

    for (const tableRow of tableData) {
      if (+tableRow.amount > 0 && tableRow.selected) totalDebit = (+totalDebit + +tableRow.amount).toFixed(2);
      if (+tableRow.amount < 0 && tableRow.selected) totalCredit = (+totalCredit + +tableRow.amount).toFixed(2);
    }

    return {
      totalCredit: (+totalCredit).toFixed(2),
      totalDebit: (+totalDebit).toFixed(2),
      difference: (+totalDebit + +totalCredit).toFixed(2),
    };
  }, [tableData]);

  const formatDataForUpdate = () => {
    const dataToSave = { ...recurringData, items: [] };

    if (!dataToSave.transaction_name) {
      notificationDanger('Transaction Name is required!');
      return;
    }
    if (!dataToSave.recurring_transaction_type) {
      notificationDanger('Transaction Type is required');
      return;
    }

    for (const row of tableData) {
      dataToSave.items.push({
        account_number: row.account_number || null,
        amount: +row.amount || null,
        branch_id: row.branch_id || null,
        client_id: row.client_id || null,
        client_type: row.client_type || null,
        description: row.description || null,
        document_number: row.document_number || null,
        posting_date: row.posting_date || null,
        recurring_method: row.recurring_method || null,
        selected: row.selected || false,
        id: row.id || null,
      });
    }

    return dataToSave;
  };

  const saveChangesToDB = () => {
    const dataToSave = formatDataForUpdate();
    if (!dataToSave) return;

    if (recurringId) updateRecurringJLMutation({ data: dataToSave, id: recurringId });
    else createRecurringJLMutation(dataToSave);
  };

  const validatePostRecurring = () => {
    const resultObj = {};
    let firstRecurringMethod = null;
    const errors = { isError: false };
    const otherErrors = { isError: false };
    for (const [idx, row] of Object.entries(tableData)) {
      if (row.selected) {
        errors[idx] = {};
        // Validate Fields
        if ([1, 2].includes(row.client_type) && !row.client_id) errors[idx].client_id = 'Subbaccount is required';
        if (!row.amount) errors[idx].amount = 'Amount is required';
        if (!row.posting_date) errors[idx].posting_date = 'Posting Date is required';
        if (!row.document_number) errors[idx].document_number = 'Document Number is required';
        if (!row.recurring_method) errors[idx].recurring_method = 'Recurring Method is required';
        if (!row.account_number) errors[idx].account_number = 'Account Number is required';
        if ([1, 2].includes(row.client_type) && !row.client_id) errors[idx].client_id = 'Subbaccount is required';
        if (+`${row.account_number}`[0] > 3 && !row.branch_id) errors[idx].branch_id = 'Branch is required';
        if (recurringData.end_date && new Date(recurringData.end_date).getTime() < new Date(row.posting_date).getTime()) {
          errors[idx].posting_date = 'Cannot post after End Date';
        }

        if (!firstRecurringMethod) {
          firstRecurringMethod = row.recurring_method;
        } else if (
          [RM_FIXED, RM_VARIABLE].includes(firstRecurringMethod) &&
          [RM_REVERSING_FIXED, RM_REVERSING_FIXED].includes(row.recurring_method)
        ) {
          otherErrors.isError = true;
          otherErrors.error = `${getRecurringMethod(RM_REVERSING_FIXED)} and ${getRecurringMethod(
            RM_REVERSING_VARIABLE
          )} recurring methods cannot be mixed with ${getRecurringMethod(RM_FIXED)} and ${getRecurringMethod(RM_VARIABLE)} methods`;
        } else if (
          [RM_REVERSING_FIXED, RM_REVERSING_FIXED].includes(firstRecurringMethod) &&
          [RM_FIXED, RM_VARIABLE].includes(row.recurring_method)
        ) {
          otherErrors.isError = true;
          otherErrors.error = `${getRecurringMethod(RM_FIXED)} and ${getRecurringMethod(
            RM_VARIABLE
          )} recurring methods cannot be mixed with ${getRecurringMethod(RM_REVERSING_FIXED)} and ${getRecurringMethod(RM_REVERSING_FIXED)} methods`;
        }

        if (Object.keys(errors[idx]).length) {
          errors.isError = true;
          continue;
        }

        if (otherErrors.isError) {
          continue;
        }

        // Add row to result obj if there are no validation errors
        let groupId = `${row.posting_date}//${row.document_number}`.replace(/\s/g, ''); // removes all white space
        resultObj[groupId] = resultObj[groupId] ? [...resultObj[groupId], row] : [row];
      }
    }

    if (errors.isError) {
      setValidationErrors(errors);
      notificationDangerCustomTime('Unable to post! Please make sure you filled out all required fields!', 10000);
      return { error: true };
    }

    if (otherErrors.isError) {
      notificationDangerCustomTime(otherErrors.error, 10000);
      return { error: true };
    }

    for (const [id, rows] of Object.entries(resultObj)) {
      const diff_acc_nums = {};
      const sum_by_group = {};
      const not_matching_acc = {};
      const has_client = {};
      const bad_tr_type = {};
      const client_id_check = { clientId: null, error: null };
      for (const row of rows) {
        // check if transaction type matching amounts.
        if (
          ([1, 4].includes(recurringData.recurring_transaction_type) && ![1, 3].includes(row.client_type)) ||
          ([2, 5].includes(recurringData.recurring_transaction_type) && ![2, 3].includes(row.client_type)) ||
          (recurringData.recurring_transaction_type === 3 && row.client_type !== 3)
        ) {
          bad_tr_type[row.document_number.trim()] =
            'Transaction Type and Account Type does not match. Document Number: ' + row.document_number.trim();
        }
        // Count if at least one row by document number has client_id
        if (!has_client[row.document_number.trim()]) has_client[row.document_number.trim()] = false;
        if (row.client_id) has_client[row.document_number.trim()] = true;

        // check if amount by group is 0
        if (sum_by_group[row.document_number.trim()])
          sum_by_group[row.document_number.trim()] = (+sum_by_group[row.document_number.trim()] + +row.amount).toFixed(2);
        else sum_by_group[row.document_number.trim()] = +row.amount;

        // check if transaction type and account type does not match
        if (
          (row.client_type === 1 && [2, 5].includes(recurringData.recurring_transaction_type)) ||
          (row.client_type === 2 && [1, 4].includes(recurringData.recurring_transaction_type))
        ) {
          not_matching_acc[row.document_number.trim()] = {
            client_type: row.client_type,
            transaction_type: recurringData.recurring_transaction_type,
          };
        }

        if (!client_id_check.clientId && row.client_id) {
          client_id_check.clientId = row.client_id;
        }
        if (client_id_check.clientId && row.client_id && client_id_check.clientId !== row.client_id) {
          client_id_check.error = `Different subaccounts not allowed for Document Number: ${row.document_number.trim()}`;
        }
      }

      const { recurring_transaction_type: type } = recurringData;
      if (type === VENDOR_INVOICE) {
        const data = rows.find((el) => el.account_number === 21010 && el.amount < 0);
        if (!data) {
          const message = (
            <div>
              Record with <strong className='text-danger'>Document Number {rows[0].document_number}</strong> must contains at least one G/L entry
              posting a credit to account 21010 that is less than 0.
            </div>
          );

          errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
        }
      }

      if (type === CUSTOMER_INVOICE) {
        const data = rows.find((el) => el.account_number === 11110 && el.amount > 0);
        if (!data) {
          const message = (
            <div>
              Record with <strong className='text-danger'>Document Number {rows[0].document_number}</strong> must contains at least one G/L entry
              posting a debit to account 11110 that is greater than 0.
            </div>
          );

          errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
        }
      }

      if (type === CUSTOMER_CREDIT_MEMO) {
        const data = rows.find((el) => el.account_number === 11110 && el.amount < 0);
        if (!data) {
          const message = (
            <div>
              Record with <strong className='text-danger'>Document Number {rows[0].document_number}</strong> must contains at least one G/L entry
              posting a credit to account 11110 that is less than 0.
            </div>
          );

          errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
        }
      }

      if (type === VENDOR_CREDIT_MEMO) {
        const data = rows.find((el) => el.account_number === 21010 && el.amount > 0);
        if (!data) {
          const message = (
            <div>
              Record with <strong className='text-danger'>Document Number {rows[0].document_number}</strong> must contains at least one G/L entry
              posting a debit to account 21010 that is greater than 0.
            </div>
          );

          errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
        }
      }

      if (type === CUSTOMER_PAYMENT) {
        let isExistDebitCashAccount = false;
        let isExistCreditAccount = false;
        for (const el of rows) {
          if (bankAccounts?.data.includes(el.account_number) && el.amount > 0) {
            isExistDebitCashAccount = true;
          }
          if (el.account_number === 11110 && el.amount < 0) {
            isExistCreditAccount = true;
          }

          if (isExistCreditAccount && isExistDebitCashAccount) {
            break;
          }
        }

        if (!isExistDebitCashAccount) {
          const message = (
            <div>
              Record with <strong className='text-danger'>Document Number {rows[0].document_number}</strong> must contains at least one G/L entry
              posting a debit to a cash account that is greater than 0.
            </div>
          );

          errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
        }
        if (!isExistCreditAccount) {
          const message = (
            <div>
              Record with <strong className='text-danger'>Document Number {rows[0].document_number}</strong> must contains at least one G/L entry
              posting a credit to account 11110 that is less than 0.
            </div>
          );

          errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
        }
      }

      if (type === VENDOR_PAYMENT) {
        let isExistCreditCashAccount = false;
        let isExistDebitAccount = false;
        for (const el of rows) {
          if (bankAccounts?.data.includes(el.account_number) && el.amount < 0) {
            isExistCreditCashAccount = true;
          }
          if (el.account_number === 21010 && el.amount > 0) {
            isExistDebitAccount = true;
          }

          if (isExistDebitAccount && isExistCreditCashAccount) {
            break;
          }
        }

        if (!isExistCreditCashAccount) {
          const message = (
            <div>
              Record with <strong className='text-danger'>Document Number {rows[0].document_number}</strong> must contains at least one G/L entry
              posting a credit to a cash account that is less than 0.
            </div>
          );

          errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
        }
        if (!isExistDebitAccount) {
          const message = (
            <div>
              Record with <strong className='text-danger'>Document Number {rows[0].document_number}</strong> must contains at least one G/L entry
              posting a debit to account 21010 that is greater than 0.
            </div>
          );

          errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
        }
      }

      if (type === CUSTOMER_REVERSING_PAYMENT) {
        let isExistCreditCashAccount = false;
        let isExistDebitAccount = false;
        for (const el of rows) {
          if (el.account_number === 10103 && el.amount < 0) {
            isExistCreditCashAccount = true;
          }
          if (el.account_number === 11110 && el.amount > 0) {
            isExistDebitAccount = true;
          }

          if (isExistDebitAccount && isExistCreditCashAccount) {
            break;
          }
        }

        if (!isExistCreditCashAccount) {
          const message = (
            <div>
              Record with <strong className='text-danger'>Document Number {rows[0].document_number}</strong> must contains at least one G/L entry
              posting a credit to cash account 10103 that is less than 0.
            </div>
          );

          errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
        }

        if (!isExistDebitAccount) {
          const message = (
            <div>
              Record with <strong className='text-danger'>Document Number {rows[0].document_number}</strong> must contains at least one G/L entry
              posting a debit to account 11110 that is greater than 0.
            </div>
          );

          errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
        }
      }

      if (type === VENDOR_REVERSING_PAYMENT) {
        let isExistDebitCashAccount = false;
        let isExistCreditAccount = false;
        for (const el of rows) {
          if (bankAccounts.data.includes(el.account_number) && el.amount > 0) {
            isExistDebitCashAccount = true;
          }
          if (el.account_number === 21010 && el.amount < 0) {
            isExistCreditAccount = true;
          }

          if (isExistCreditAccount && isExistDebitCashAccount) {
            break;
          }
        }

        if (!isExistDebitCashAccount) {
          const message = (
            <div>
              Record with <strong className='text-danger'>Document Number {rows[0].document_number}</strong> must contains at least one G/L entry
              posting a debit to a cash account that is greater than 0.
            </div>
          );

          errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
        }

        if (!isExistCreditAccount) {
          const message = (
            <div>
              Record with <strong className='text-danger'>Document Number {rows[0].document_number}</strong> must contains at least one G/L entry
              posting a credit to account 21010 that is less than 0.
            </div>
          );

          errors.transactionRules = errors.transactionRules ? [...errors.transactionRules, message] : [message];
        }
      }

      // Format error message if sum of all amounts by group is not zero
      for (const [document_num, num] of Object.entries(sum_by_group)) {
        if (+num !== 0) {
          const message = (
            <div>
              Document Number: <strong className='text-danger'>{document_num}</strong>. Sum Amounts:
              <strong className='text-danger'>
                {num.toLocaleString('us', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 2,
                })}
              </strong>
            </div>
          );
          errors.amountErrors = errors.amountErrors ? [...errors.amountErrors, message] : [message];
        }
      }

      // Format error message if transaction type and account type does not match
      for (const [document_num, obj_values] of Object.entries(not_matching_acc)) {
        const message = (
          <div>
            <div>
              Transaction type: <strong className='text-danger'>{getRecurringTransactionTypes(obj_values.transaction_type)}</strong>
            </div>
            <div>
              Account Type: <strong className='text-danger'>{getAccountType(obj_values.client_type)}</strong>
            </div>
            <div>
              for Document Number: <strong>{document_num}</strong> cannot be posted.
            </div>
          </div>
        );
        errors.dataMismatch = errors.dataMismatch ? [...errors.dataMismatch, message] : [message];
      }

      // Format error message if there is not client_id
      for (const [document_num, value] of Object.entries(has_client)) {
        if (recurringData.recurring_transaction_type !== 3 && !value) {
          const message = (
            <div>
              Group with document number <strong className='text-danger'>"{document_num}"</strong> must have at least one row with Subbacount entered.
            </div>
          );
          errors.hasClient = errors.hasClient ? [...errors.hasClient, message] : [message];
        }
      }

      // Format error message when transaction type is not matching amount
      for (const [document_num, obj_value] of Object.entries(bad_tr_type)) {
        const message = <div>{obj_value}</div>;
        errors.badTrType = errors.badTrType ? [...errors.badTrType, message] : [message];
      }

      // Format error when there is more than one client_id for one group
      if (client_id_check.error) {
        const message = <div>{client_id_check.error}</div>;
        errors.clientIdCheck = errors.clientIdCheck ? [...errors.clientIdCheck, message] : [message];
      }
    }

    // Throw formatted error if amount is different than 0
    if (errors.amountErrors) {
      // console.log(errors);
      let notification = () => (
        <div>
          {errors.amountErrors.map((item, idx) => (
            <React.Fragment key={idx}>{item}</React.Fragment>
          ))}
          <strong>Sum of amount must be $0.00 in grouped items by Posting Date and Document Number.</strong>
        </div>
      );

      notificationDangerCustomTime(notification, 15000);
      return { error: true };
    }

    // Throw formatted error if data is mismatched
    if (errors.dataMismatch) {
      let notification = () => (
        <div>
          {errors.dataMismatch.map((item) => (
            <React.Fragment key={item}>{item}</React.Fragment>
          ))}
          <strong>Data Mismatch</strong>
        </div>
      );
      notificationDangerCustomTime(notification, 15000);
      return { error: true };
    }

    // Throw formatted error when data does not have client_id
    if (errors.hasClient) {
      let notification = () => (
        <div>
          {errors.hasClient.map((item) => (
            <React.Fragment key={item}>{item}</React.Fragment>
          ))}
          <strong>Data Missing</strong>
        </div>
      );
      notificationDangerCustomTime(notification, 15000);
      return { error: true };
    }

    // Throw formatted error when transaction type is not matching amount
    if (errors.badTrType) {
      let notification = () => (
        <div>
          {errors.badTrType.map((item) => (
            <React.Fragment key={item}>{item}</React.Fragment>
          ))}
          <strong>Bad Data</strong>
        </div>
      );
      notificationDangerCustomTime(notification, 15000);
      return { error: true };
    }

    if (errors.clientIdCheck) {
      let notification = () => (
        <div>
          {errors.clientIdCheck.map((item) => (
            <React.Fragment key={item}>{item}</React.Fragment>
          ))}
          <strong>Bad Data</strong>
        </div>
      );
      notificationDangerCustomTime(notification, 15000);
      return { error: true };
    }

    if (errors.transactionRules) {
      let notification = () => (
        <div>
          {errors.transactionRules.map((item, id) => (
            <React.Fragment key={item}>{item}</React.Fragment>
          ))}

          <strong>Bad Data</strong>
        </div>
      );
      notificationDangerCustomTime(notification, 15000);
      return { error: true };
    }
    return { error: false, resultObj };
  };

  const postRecurringJournalEntries = () => {
    if (+totalData.difference !== 0) {
      notificationDangerCustomTime('Difference between Total Debit and Total Credit must be 0 to be able to post Recurring JL!', 10000);
    }
    if (+totalData.totalDebit === 0 && +totalData.totalCredit === 0) {
      notificationDangerCustomTime('Please setup recurring items to be able to post!', 10000);
      return;
    }
    if (
      (recurringData.recurring_transaction_type === VENDOR_PAYMENT || recurringData.recurring_transaction_type === CUSTOMER_PAYMENT) &&
      !recurringData.payment_method
    ) {
      notificationDangerCustomTime('You must select some payment method.', 10000);
      return;
    }

    const { error } = validatePostRecurring();

    if (error) return;
    setValidationErrors({});

    const updateData = formatDataForUpdate();
    const datesArray = updateData?.items?.map((item) => item.posting_date);

    if (isPostingDisabled(datesArray)) {
      notificationDangerCustomTime('You are not allowed to post in this period. Please contact your superior for more info!', 10000);
      return;
    }

    postRecurringMutation({ updateData, recurringId });
  };

  const headerTitle = (
    <Row>
      <Col className='d-flex align-items-center justify-content-between'>
        <h6 className='m-0'>General</h6>
        <div>
          <StyledButton color='success' onClick={saveChangesToDB} sx={{ mr: 2 }} disabled={!hasPermission(RECURRING_JOURNAL_ENTRY, EDIT)}>
            Save Changes To DB
          </StyledButton>
          {recurringId && (
            <Tooltip title={'Post Recurring Journal Entries'} placement='top' arrow followCursor>
              <StyledButton onClick={postRecurringJournalEntries} sx={{ mr: 2 }} disabled={!hasPermission(RECURRING_JOURNAL_ENTRY, EDIT)}>
                Post Recurring Journal Entries
              </StyledButton>
            </Tooltip>
          )}
        </div>
      </Col>
    </Row>
  );

  return (
    <>
      {(postIsLoading || updateIsLoading || createIsLoading) && <Loader isFullScreen />}
      <Button className='btn btn-sm my-2' onClick={backHandler}>
        <i className='fas fa-angle-left mr-2'></i>Go Back Without Save
      </Button>
      <CollapsableCard headerTitle={headerTitle}>
        <Row className='d-flex align-items-center'>
          <Col md='3'>
            <CustomInput
              label='Transaction Name'
              value={recurringData.transaction_name}
              onChange={changeInput}
              placeholder='Transaction Name'
              name='transaction_name'
            />
          </Col>
          <Col md='3'>
            <CustomSelect
              onChange={changeSelect}
              options={recurringTransactionTypesOptions}
              defaultValue={recurringData.recurring_transaction_type || null}
              label='Transaction Type'
              name='recurring_transaction_type'
            />
          </Col>
          <Col md='3'>
            <CustomInput
              label='Description'
              type='textarea'
              rows={2}
              value={recurringData.description}
              onChange={changeInput}
              placeholder='Description'
              name='description'
            />
          </Col>
          <Col md='3'>
            <CustomSelect
              isClearable
              options={
                supportTableOptions[PAYMENT_SOURCE] ? supportTableOptions[PAYMENT_SOURCE].filter((item) => !item.label.includes('Bounced')) : []
              }
              defaultValue={recurringData.payment_method || null}
              onChange={changeSelect}
              label='Payment Method'
              name={'payment_method'}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col md='3'>
            <CustomSelect
              onChange={changeSelect}
              options={frequencyRecurringOptions}
              defaultValue={recurringData.frequency || null}
              label='Recurring Frequency'
              name='frequency'
            />
          </Col>
          <Col md='3'>
            <DatePickerComponent
              onChange={(date) => handleDateChange(date, 'start_date')}
              showYearDropdown
              isClearable
              name='start_date'
              selected={recurringData.start_date}
              placeholder='mm/dd/yyyy'
              dateFormat={'MM/dd/yyyy'}
              label='Start Date'
            />
          </Col>
          <Col md='3'>
            <DatePickerComponent
              onChange={(date) => handleDateChange(date, 'end_date')}
              showYearDropdown
              isClearable
              name='end_date'
              selected={recurringData.end_date}
              placeholder='mm/dd/yyyy'
              dateFormat={'MM/dd/yyyy'}
              label='End Date'
            />
          </Col>
        </Row>
        <Row>
          <Col md='3'>
            <CustomSelect
              onChange={changeSelect}
              options={reminderRecurringOptions}
              defaultValue={recurringData.reminder || null}
              label='Reminder before posting'
              name='reminder'
            />
          </Col>
          <Col md='3'>
            <CustomSelect
              isMulti
              onChange={changeMultiSelect}
              options={usersForSelectOptions || []}
              defaultValue={recurringData.users_for_reminder || []}
              label='Users For Reminder'
              name='users_for_reminder'
            />
          </Col>
        </Row>
      </CollapsableCard>
      <CollapsableCard headerTitle='Journal Entries'>
        <RecurringJournalEntries
          recurringId={recurringId}
          tableData={tableData}
          setTableData={setTableData}
          totalData={totalData}
          validationErrors={validationErrors}
          recurringData={recurringData}
        />
      </CollapsableCard>
    </>
  );
};

const StyledButton = styled(MuiButton)(() => ({
  '&.Mui-disabled': {
    backgroundColor: '#9b8632',
    cursor: 'pointer',
  },
}));

const StyledBox = styled(Box)(() => ({
  padding: '5px 10px',
  border: '1px solid #cdad2b',
  borderRadius: '5px',
}));

export default AddEditComponentRecurrinEntry;
