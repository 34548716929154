import { Button } from '@mui/material';
import { useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import { CustomSelect } from '../../../../common/CustomSelect';
import { DatePickerComponent } from '../../../../common/DatePicker/DatePicker';
import { CustomPageableAsyncSelect } from '../../../../common/Inputs/CustomPageableAsyncSelect';
import { LIEN_HOLDER, MAKE, TRUCK_TYPE, YEAR } from '../../../../constants/fixedAssetsCodebookType.constants';
import { useAsyncPageable } from '../../../../hooks/useAsyncPageable';
import { useGetFixedAssetsCodebooks } from '../FixedAsettesApiRoutes';

export const Filters = ({ filters = {}, onDateChange, onSelectChange, onSearch }) => {
  const { fetchOptions } = useAsyncPageable();

  const yesNoOptions = useMemo(
    () => [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    []
  );

  const loadOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/account/options/accounts', { q: inputValue, ...options });
    return data;
  };

  const loadUnitNumberOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/fixed-assets/unit-num-for-select', {
      q: inputValue,
      ...options,
    });

    return data;
  };

  const { data: { data: codeBooks = {} } = {} } = useGetFixedAssetsCodebooks([MAKE, TRUCK_TYPE, YEAR, LIEN_HOLDER]);

  return (
    <>
      <Row>
        <Col lg={3} md={4} sm={12}>
          <DatePickerComponent
            showYearDropdown
            name='purchase_date_from'
            selected={filters['purchase_date_from']}
            onChange={(date) => onDateChange(date, 'purchase_date_from')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Purchase Date From'
            isClearable
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <DatePickerComponent
            showYearDropdown
            name='purchase_date_to'
            selected={filters['purchase_date_to']}
            onChange={(date) => onDateChange(date, 'purchase_date_to')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Purchase Date To'
            isClearable
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <DatePickerComponent
            showYearDropdown
            name='sales_date_from'
            selected={filters['sales_date_from']}
            onChange={(date) => onDateChange(date, 'sales_date_from')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Sales Date From'
            isClearable
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <DatePickerComponent
            showYearDropdown
            name='sales_date_to'
            selected={filters['sales_date_to']}
            onChange={(date) => onDateChange(date, 'sales_date_to')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Sales Date To'
            isClearable
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <DatePickerComponent
            showYearDropdown
            name='posting_date_from'
            selected={filters['posting_date_from']}
            onChange={(date) => onDateChange(date, 'posting_date_from')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Posting Date From'
            isClearable
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <DatePickerComponent
            showYearDropdown
            name='posting_date_to'
            selected={filters['posting_date_to']}
            onChange={(date) => onDateChange(date, 'posting_date_to')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Posting Date To'
            isClearable
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <CustomPageableAsyncSelect
            isClearable
            label='Unit #'
            onChange={onSelectChange}
            loadOptions={loadUnitNumberOptions}
            name='fixed_assets.unit_number'
            defaultValue={filters['fixed_assets.unit_number'] || null}
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <CustomSelect
            isClearable
            label='Truck Year'
            defaultValue={filters['fixed_assets.year'] || null}
            onChange={onSelectChange}
            name='fixed_assets.year'
            options={codeBooks[YEAR] || []}
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <CustomSelect
            isClearable
            label='Truck Type'
            name='fixed_assets.truck_type'
            defaultValue={filters['fixed_assets.truck_type'] || null}
            onChange={onSelectChange}
            options={codeBooks[TRUCK_TYPE] || []}
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <CustomSelect
            isClearable
            label='Lien Holder'
            name='fixed_assets.lien_holder'
            defaultValue={filters['fixed_assets.lien_holder'] || null}
            onChange={onSelectChange}
            options={codeBooks[LIEN_HOLDER] || []}
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <CustomSelect
            isClearable
            label='Make'
            name='fixed_assets.make'
            defaultValue={filters['fixed_assets.make'] || null}
            onChange={onSelectChange}
            options={codeBooks[MAKE] || []}
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <CustomSelect
            isClearable
            label='Active'
            defaultValue={filters.active || null}
            onChange={onSelectChange}
            name='active'
            options={yesNoOptions}
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <CustomSelect
            isClearable
            label='On Rent'
            defaultValue={filters.on_rent || null}
            onChange={onSelectChange}
            name='on_rent'
            options={yesNoOptions}
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <CustomSelect
            isClearable
            label='Disposed'
            defaultValue={filters.disposed || null}
            onChange={onSelectChange}
            name='disposed'
            options={yesNoOptions}
          />
        </Col>
      </Row>
      <Row>
        <Col className='d-flex justify-content-end'>
          <Button onClick={onSearch}>Search</Button>
        </Col>
      </Row>
    </>
  );
};
