import { Button, Divider, FormLabel, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { CollapsableCard } from '../../../../../common/Card/CollapsableCard';
import { CustomSelect } from '../../../../../common/CustomSelect';
import { DatePickerComponent } from '../../../../../common/DatePicker/DatePicker';
import { CustomPageableAsyncSelect } from '../../../../../common/Inputs/v2/AsyncSelect2';
import { PAYMENT_SOURCE } from '../../../../../constants/supportTables.constants';
import { useGetSupportTablesForSelect } from '../../../../../hooks/commonApiHooks';
import { useAsyncPageable } from '../../../../../hooks/useAsyncPageable';
import { normalizeClientType } from '../../utils';

export const BillingPaymentFilters = ({ onFilterChange, filters, onSearch }) => {
  const { clientType } = useParams();

  const { fetchOptions } = useAsyncPageable();

  const loadDocumentNumberOptions = (inputValue, options) => {
    return fetchOptions('/me/transaction/options-for-select-dn', { q: inputValue, ...options });
  };

  const loadBatchNumberOptions = (inputValue, options) => {
    return fetchOptions(`/me/client/${normalizeClientType(clientType)}/billing-payments/options`, { q: inputValue, ...options });
  };

  const loadAccountOptions = (inputValue, options) => {
    return fetchOptions('/me/account/options/accounts?active=true&bank=true', { q: inputValue, ...options });
  };

  const { data: { data: supportTableOptions = {} } = {} } = useGetSupportTablesForSelect({
    table_names: [PAYMENT_SOURCE],
  });

  const handleChange = (e) => {
    onFilterChange({ [e.target.name]: e.target.value });
  };

  const handleButtonGroupChange = (e) => {
    const { name, value: rawValue } = e.target;
    let value = undefined;

    if (!rawValue) {
      value = undefined;
    } else if (rawValue === 'true') {
      value = true;
    } else if (rawValue === 'false') {
      value = false;
    }

    handleChange({ target: { name, value } });
  };

  const handleDateChange = (date, name) => {
    handleChange({ target: { name, value: date } });
  };

  const handleSelectChange = (val, e) => {
    if (e.action === 'select-option') {
      handleChange({ target: { name: e.name, value: val.value } });
      return;
    }
    if (e.action === 'clear') {
      handleChange({ target: { name: e.name, value: null } });
    }
  };

  return (
    <>
      <CollapsableCard
        headerTitle='Filters'
        isExpanded={false}
        titleTypographyProps={{ fontSize: '16px' }}
        sxCardContent={{ padding: '14px' }}
        sxCardHeader={{ padding: '14px' }}
      >
        <Row>
          <Col md='3'>
            <FormLabel sx={{ display: 'block' }}>Posted</FormLabel>
            <ToggleButtonGroup color='primary' name='finished' value={filters.finished} exclusive onChange={handleButtonGroupChange} size='small'>
              <ToggleButton name='finished' selected={filters.finished === undefined}>
                Both
              </ToggleButton>
              <ToggleButton name='finished' value={true}>
                Yes
              </ToggleButton>
              <ToggleButton name='finished' value={false}>
                No
              </ToggleButton>
            </ToggleButtonGroup>
          </Col>
          <Col md={3}>
            <CustomPageableAsyncSelect
              label='Batch Number'
              isClearable
              onChange={handleSelectChange}
              loadOptions={loadBatchNumberOptions}
              name='batch_number'
              initValue={filters.batch_number}
            />
          </Col>
          <Col md={3}>
            <CustomPageableAsyncSelect
              label='Document Number'
              isClearable
              onChange={handleSelectChange}
              loadOptions={loadDocumentNumberOptions}
              name='document_number'
              initValue={filters.document_number}
            />
          </Col>
          <Col md={3}>
            <CustomSelect
              isClearable
              options={supportTableOptions[PAYMENT_SOURCE] || []}
              defaultValue={filters.payment_source}
              onChange={handleSelectChange}
              label='Payment Method'
              name={'payment_source'}
            />
          </Col>
          <Col md={3}>
            <CustomPageableAsyncSelect
              label='Bank Account'
              isClearable
              onChange={handleSelectChange}
              loadOptions={loadAccountOptions}
              name='bank_account_number'
              initValue={filters.bank_account_number}
            />
          </Col>
          <Col md={3}>
            <DatePickerComponent
              showYearDropdown
              isClearable
              name='posting_date_from'
              selected={filters.posting_date_from}
              onChange={(date) => handleDateChange(date, 'posting_date_from')}
              placeholder='mm/dd/yyyy'
              dateFormat={'MM/dd/yyyy'}
              label='Posting Date ( From )'
            />
          </Col>
          <Col md={3}>
            <DatePickerComponent
              showYearDropdown
              isClearable
              name='posting_date_to'
              selected={filters.posting_date_to}
              onChange={(date) => handleDateChange(date, 'posting_date_to')}
              placeholder='mm/dd/yyyy'
              dateFormat={'MM/dd/yyyy'}
              label='Posting Date ( To )'
            />
          </Col>
          <Col md={3}>
            <DatePickerComponent
              showYearDropdown
              isClearable
              name='create_at_from'
              selected={filters.create_at_from}
              onChange={(date) => handleDateChange(date, 'create_at_from')}
              placeholder='mm/dd/yyyy'
              dateFormat={'MM/dd/yyyy'}
              label='Created Date ( From )'
            />
          </Col>
          <Col md={3}>
            <DatePickerComponent
              showYearDropdown
              isClearable
              name='create_at_to'
              selected={filters.create_at_to}
              onChange={(date) => handleDateChange(date, 'create_at_to')}
              placeholder='mm/dd/yyyy'
              dateFormat={'MM/dd/yyyy'}
              label='Created Date ( To )'
            />
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col>
            <Divider />
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col className='d-flex justify-content-end'>
            <Button onClick={onSearch}>Search</Button>
          </Col>
        </Row>
      </CollapsableCard>
    </>
  );
};
