import Joi from 'joi';
import { ACC_TYPE_CUSTOMER, ACC_TYPE_VENDOR } from '../../../constants/accountType.constants';

export const validateJournalEntryQueueItem = (data) => {
  const journalEntryModel = Joi.object()
    .options({ abortEarly: false })
    .keys({
      account_type: Joi.number().required().messages({
        'number.base': 'Account Type is required',
      }),
      account_number: Joi.number().required().messages({
        'number.base': 'Account Number is required',
      }),
      client_id: Joi.custom((value, helper) => {
        const state = helper.state.ancestors[0];
        if (!state.client_id && [ACC_TYPE_CUSTOMER, ACC_TYPE_VENDOR].includes(state.account_type)) {
          return helper.message('Subaccount is required');
        }
        return value;
      }),
      transaction_type: Joi.number().required().messages({
        'number.base': 'Transaction Type is required',
      }),
      // transaction_id: Joi.number().required().messages({
      //   'number.base': 'Transaction ID is required',
      // }),
      // description: Joi.string().required().messages({
      //   'string.base': 'Description is required',
      //   'string.empty': 'Description is required',
      // }),
      document_number: Joi.string().required().messages({
        'string.empty': 'Document Number is required',
        'any.required': 'Document Number is required',
      }),
      branch_id: Joi.optional().custom((value, helper) => {
        const state = helper.state.ancestors[0];
        // account number starts with 4 or greater
        const is4PlusAccount = +`${state.account_number}`[0] >= 4;
        if (is4PlusAccount && !value) {
          return helper.message('Branch is required');
        }
        return value;
      }),
      posting_date: Joi.date().required().messages({
        'date.base': 'Posting Date is required',
      }),
      amount: Joi.number().required().messages({
        'number.base': 'Amount is required',
      }),
      // debit_amount: Joi.custom((value, helper) => {
      //   const state = helper.state.ancestors[0];
      //   if (!state.debit_amount && !state.credit_amount) {
      //     return helper.message('Debit or Credit Amount required');
      //   }
      //   return value;
      // }),
      // credit_amount: Joi.custom((value, helper) => {
      //   const state = helper.state.ancestors[0];
      //   if (!state.debit_amount && !state.credit_amount) {
      //     return helper.message('Debit or Credit Amount required');
      //   }
      //   return value;
      // }),
      reverse_date: Joi.optional().custom((value, helper) => {
        const state = helper.state.ancestors[0];
        if (state.is_reversing && !value) {
          return helper.message('Reverse Date required');
        }
        return value;
      }),
    })
    .unknown(true);

  const validationResult = journalEntryModel.validate(data);

  if (validationResult.error) {
    const errors = {};
    validationResult.error.details.forEach((error) => {
      errors[error.context.label] = error.message;
    });
    return errors;
  }

  return null;
};
