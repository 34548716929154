import { Button } from '@mui/material';
import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { CustomInput } from '../../../../common/CustomInput';
import { DatePickerComponent } from '../../../../common/DatePicker/DatePicker';
import { CustomModal } from '../../../../common/Dialog/CustomModal';
import { usePostDepreciationItemsData } from '../FixedAsettesApiRoutes';
import Joi from 'joi';

export const DepreciationItemsModal = ({
  postingData,
  setPostingData,
  isModalOpen,
  setIsModalOpen,
  selectedDataFromTable,
  setSelectedDataFromTable,
  defaultDocumentNumber,
}) => {
  const [errors, setErrors] = useState({});

  const closeModal = () => {
    setIsModalOpen(false);
    setPostingData({
      documentNumber: defaultDocumentNumber,
      postingDate: '',
      comment: '',
    });
    setErrors({});
  };

  const handleInputData = (e) => {
    const { name, value } = e.target;
    setPostingData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateData = (date) => {
    setPostingData((prevState) => ({
      ...prevState,
      postingDate: date,
    }));
  };

  const { mutate: createMutation } = usePostDepreciationItemsData(() => {
    closeModal();
    setSelectedDataFromTable({});
  });

  const onPostDepreciations = () => {
    if (!postingData.documentNumber || !postingData.postingDate || !postingData.comment) {
      const validateData = {
        documentNumber: postingData.documentNumber,
        postingDate: postingData.postingDate,
        comment: postingData.comment,
      };

      const errorsValidation = validateDataBeforePosting(validateData);
      if (errorsValidation) {
        setErrors(errorsValidation);
        return;
      }
      return;
    }

    createMutation({
      selectedDataFromTable,
      postingData,
    });
  };

  const validateDataBeforePosting = (data) => {
    const postingDataValidator = Joi.object()
      .options({ abortEarly: false })
      .keys({
        documentNumber: Joi.string().required().messages({
          'string.empty': '*Document Number Is Required',
        }),
        postingDate: Joi.date().required().messages({
          'date.base': '*Posting Date Is Required',
        }),
        comment: Joi.string().required().messages({
          'string.empty': '*Comment Is Required',
        }),
      })
      .unknown(true);

    const validationResult = postingDataValidator.validate(data);

    if (validationResult.error) {
      const errors = {};
      validationResult.error.details.forEach((error) => {
        errors[error.context.label] = error.message;
      });
      return errors;
    }

    return null;
  };

  return (
    <>
      <CustomModal
        modalTitle={'Post Selected Items'}
        modalSize='md'
        toggleCustomModal={closeModal}
        isOpen={isModalOpen}
        actionButtons={
          <div className='d-flex justify-content-between w-100'>
            <Button variant='contained' color='error' onClick={closeModal}>
              Cancel
            </Button>
            <Button variant='contained' onClick={onPostDepreciations}>
              <span>POST SELECTED DEPRECIATIONS</span>
            </Button>
          </div>
        }
      >
        <Row className='d-flex align-items-center'>
          <Col md='4'>
            <CustomInput
              label='Add Document Number'
              onChange={handleInputData}
              name='documentNumber'
              fullWidth
              variant='outlined'
              type='text'
              placeholder='Add Document Number'
              value={postingData.documentNumber || defaultDocumentNumber}
              errorMess={errors?.documentNumber}
            />
          </Col>
          <Col md='4'>
            <DatePickerComponent
              onChange={(date) => handleDateData(date)}
              showYearDropdown
              name='postingDate'
              selected={postingData.postingDate}
              placeholder='mm/dd/yyyy'
              dateFormat={'MM/dd/yyyy'}
              label='Select Posting Date'
              errorMess={errors?.postingDate}
            />
          </Col>
          <Col md='4'>
            <CustomInput
              label='Add Comment'
              onChange={handleInputData}
              name='comment'
              fullWidth
              variant='outlined'
              type='textarea'
              rows={2}
              placeholder='Add Comment For Selected Depreciations'
              errorMess={errors?.comment}
            />
          </Col>
        </Row>
      </CustomModal>
    </>
  );
};
