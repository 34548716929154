import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { CollapsableCard } from '../../../../common/Card/CollapsableCard';
import DataGridComponent from '../../../../component/DataTable/component/DataTableComponent';
import { downloaderFiles } from '../../../../utils/downloaderFiles';
import { useDepreciationReport } from '../FixedAsettesApiRoutes';
import { DepreciationReportTableColumns } from '../tableColumns/DepreciationReportTableColumns';
import { Filters } from './Filters';
import { useSelector } from 'react-redux';
import ExportButton from '../../../../component/Buttons/ExportButton';
import { exportExcel } from '../../../../utils/exportExcel';
import { CustomPopup } from '../../../../common/Popup/CustomPopup';
import { Box } from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';

export default function DepreciationReport() {
  const { tenantName } = useSelector((state) => ({
    tenantName: state.auth.user?.tenantName,
  }));

  const blankFilter = useMemo(
    () => ({
      page: 0,
      pageSize: 20,
      sort_by: 'unit_number',
      order_by: 'ASC',
      purchase_date_from: null,
      purchase_date_to: null,
      sales_date_from: null,
      sales_date_to: null,
      'fixed_assets.unit_number': null,
      'fixed_assets.year': null,
      'fixed_assets.truck_type': null,
      'fixed_assets.make': null,
      active: null,
      on_rent: null,
      disposed: null,
    }),
    []
  );

  const [filters, setFilters] = useState(blankFilter);
  const [chooseExport, setChooseExport] = useState('');
  const [popupData, setPopupData] = React.useState({
    anchor: null,
  });

  const columns = useMemo(() => DepreciationReportTableColumns({}), []);
  const cleanDataSource = useMemo(() => ({ data: [], page: 0, pageSize: 10, pageTotal: 0 }));

  const normalizeFilters = useCallback((filters) => {
    const normalizedFilters = {
      ...filters,
      purchase_date: {
        from: filters.purchase_date_from,
        to: filters.purchase_date_to,
      },
      sales_date: {
        from: filters.sales_date_from,
        to: filters.sales_date_to,
      },
      posting_date: {
        from: filters.posting_date_from,
        to: filters.posting_date_to,
      },
    };

    delete normalizedFilters.purchase_date_from;
    delete normalizedFilters.purchase_date_to;
    delete normalizedFilters.sales_date_from;
    delete normalizedFilters.sales_date_to;
    delete normalizedFilters.posting_date_from;
    delete normalizedFilters.posting_date_to;

    return normalizedFilters;
  }, []);

  const { data: { data: reportData = {} } = {}, refetch: getReport } = useDepreciationReport(normalizeFilters(filters));

  const handleFilterChange = (input) => {
    setFilters((prev) => ({ ...prev, ...input }));
  };

  const handleChange = (e) => {
    try {
      if (e.target.value.constructor === {}.constructor) {
        setFilters((prev) => ({ ...prev, ...e.target.value }));
      } else {
        setFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      }
    } catch (error) {
      setFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const handleSelectChange = (val, e) => {
    if (e.action === 'select-option') {
      handleChange({ target: { name: e.name, value: val.value || val.id } });
      return;
    }
    if (e.action === 'clear') {
      handleChange({ target: { name: e.name, value: null } });
    }
  };

  const handleDateChange = (date, name) => {
    setFilters((prev) => ({
      ...prev,
      [name]: date,
    }));
  };

  const excelHeader = React.useMemo(
    () => [
      { header: 'Unit #', key: 'unit_number' },
      { header: 'Year', key: 'unit_year' },
      { header: 'Make', key: 'unit_manufacturer' },
      { header: 'Model', key: 'unit_model' },
      { header: 'VIN #', key: 'vin' },
      { header: 'Status', key: 'status' },
      { header: 'Branch', key: 'branch_name' },
      { header: 'Lien Holder', key: 'lien_holder' },
      { header: 'Truck Type', key: 'unit_truck_type' },
      { header: 'Purchase Cost', key: 'acquisition_cost' },
      { header: 'Depreciation Start Date', key: 'begining_depreciation_date' },
      { header: 'Depreciation End Date', key: 'end_depreciation_date' },
      {
        header: 'Year To Date Depreciation Expense',
        key: 'year_to_date_depreciation_expense',
        style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' },
      },
      { header: 'Monthly Depreciation Expense', key: 'monthly_depreciation_expense', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
      { header: 'Salvage Value', key: 'salvage_value', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
      { header: 'Useful Life', key: 'depreciation_period' },
      { header: 'Net Book Value', key: 'net_book_value', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
      { header: 'Disposal Date', key: 'sales_date' },
      { header: 'Selling Price', key: 'sold_for' },
      { header: 'Gain/Loss on Sale', key: 'gain_loss_on_sale', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
    ],
    []
  );

  const handleExcelButtonClick = (currentPage) => {
    const commonParams = {
      filter: { ...normalizeFilters(filters), excel_creation_date: moment().format('YYYY-MM-DD') },
      type: 'EXCEL',
      cbLoad: () => {},
    };

    if (chooseExport !== 'deprecationReport' && currentPage) {
      downloaderFiles({
        ...commonParams,
        url: '/me/fixed-assets/depreciation-report-second',
        name: `Deprecation Report By Posting Date`,
        page: true,
      });
    } else if (chooseExport !== 'deprecationReport') {
      downloaderFiles({
        ...commonParams,
        url: '/me/fixed-assets/depreciation-report-second',
        name: `Deprecation Report By Posting Date`,
      });
    } else {
      downloaderFiles({
        ...commonParams,
        url: '/me/fixed-assets/depreciation-report',
        name: `Deprecation Report ${moment().format('dddd, MMMM DD, YYYY')}`,
      });
    }

    setChooseExport('');
  };

  const handleSearch = () => {
    getReport(normalizeFilters(filters));
  };

  const PopupContent = () => {
    return (
      <>
        <Box sx={{ borderRadius: '25px' }}>
          <DialogContent sx={{ padding: '5px' }}>What do you want to export?</DialogContent>
          <DialogActions sx={{ padding: '5px' }}>
            <Button
              onClick={() => {
                setChooseExport('deprecationReport');
                setPopupData((prev) => ({ anchor: null }));
              }}
            >
              Deprecation Report
            </Button>
            <Button
              onClick={() => {
                setChooseExport('deprecationReportByPostingDate');
                setPopupData((prev) => ({ anchor: null }));
              }}
            >
              Deprecation Report By Posting Date
            </Button>
          </DialogActions>
        </Box>
      </>
    );
  };

  return (
    <>
      <Row className='mt-5 mb-3'>
        <Col>
          <Row className='mb-3'>
            <Col>
              <CollapsableCard
                headerTitle={
                  <>
                    <div className='d-flex align-items-center justify-content-between'>
                      <span>Filters</span>
                      {chooseExport !== '' ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span>
                            <Paper
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                pr: '8px',
                              }}
                            >
                              <IconButton onClick={() => setChooseExport('')}>
                                <ClearIcon color='error' />
                              </IconButton>
                              {chooseExport === 'deprecationReport' ? 'Deprecation Report  ' : 'Deprecation Report By Posting Date  '}
                            </Paper>
                          </span>
                          <ExportButton
                            showOnly='EXCEL'
                            exportTableEXCEL={handleExcelButtonClick}
                            exportPageEXCEL={() => {
                              if (chooseExport === 'deprecationReport') {
                                exportExcel(reportData?.data, excelHeader, `Deprecation Report ${moment().format('dddd, MMMM DD')}`);
                                setChooseExport('');
                              } else {
                                handleExcelButtonClick(true);
                              }
                            }}
                          />
                        </div>
                      ) : (
                        <>
                          <Button
                            variant='outlined'
                            onClick={(e) =>
                              setPopupData({
                                anchor: e.currentTarget,
                                openPopup: true,
                              })
                            }
                          >
                            Export
                          </Button>
                        </>
                      )}
                    </div>
                  </>
                }
                isExpanded={false}
                titleTypographyProps={{ fontSize: '18px' }}
                sxCardContent={{ padding: '16px' }}
                sxCardHeader={{ padding: '16px' }}
              >
                <Filters
                  filters={filters}
                  onDateChange={handleDateChange}
                  onSelectChange={handleSelectChange}
                  onDownload={handleExcelButtonClick}
                  onSearch={handleSearch}
                />
              </CollapsableCard>
            </Col>
          </Row>

          <div style={{ border: '0px solid black', backgroundColor: 'white' }}>
            <Row>
              <Col className='mb-4'>
                <Row>
                  <Col className='d-flex justify-content-between'>
                    <Col>
                      <h3 className='title' style={{ paddingTop: '15px', fontWeight: 'bold' }}>
                        Depreciation Report
                      </h3>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                      <h5
                        className='title'
                        style={{
                          paddingTop: '10px',
                          textAlign: 'center',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {moment().format('dddd, MMMM D, YYYY hh:mm A')}
                      </h5>
                    </Col>
                  </Col>
                </Row>
                <Col className='mt-3'>
                  <div className='mb-3'>{tenantName}</div>
                </Col>
                <Col>
                  <DataGridComponent
                    columns={columns}
                    loading={false}
                    dataSource={reportData || cleanDataSource}
                    onFilterChange={handleFilterChange}
                  />
                </Col>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <CustomPopup
        anchor={popupData.anchor}
        content={<PopupContent />}
        arrow
        placement='bottom'
        onClose={() => setPopupData((prev) => ({ anchor: null }))}
        open={popupData.openPopup}
      />
    </>
  );
}
