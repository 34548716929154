import React from 'react';
import { Button } from 'reactstrap';

const UploadButton = ({ onClick = () => {}, text = 'Upload Excel', style = {}, disabled = false }) => {
  return (
    <Button style={style} className='upload-button btn' onClick={onClick} disabled={disabled}>
      {' '}
      <i className='fas fa-file-upload'> </i> {text}
    </Button>
  );
};

export default UploadButton;
