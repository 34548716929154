import React, { useEffect, useMemo, useState } from 'react';
import DataGridComponent from '../../../../component/DataTable/component/DataTableComponent';
import { useGetSelectAllItems, useGetDepreciationItemsPreviewTableData } from '../FixedAsettesApiRoutes';
import { DepreciationItemsPreviewTableColumns } from '../tableColumns/DepreciationItemsPreviewTableColumns';

export const DepreciationItemsPreviewTable = (props) => {
  const [isChecked, setIsChecked] = useState({});
  const [postData, setPostData] = useState({});
  const [checkedAll, setCheckedAll] = useState(null);

  const [filters, setFilters] = useState({
    start: '',
    end: '',
    page: 0,
    pageSize: 10,
    sort_by: '',
    order_by: 'DESC',
  });

  const onFilterChange = (e) => {
    setFilters({ ...filters, ...e });
  };

  const depreciationItemsSelectAll = (data) => {
    const incomingData = data?.data?.data;
    const newAllSelectedToPost = {};
    const newAllChecked = {};

    for (let i = 0; i < incomingData.length; i++) {
      newAllSelectedToPost[i] = incomingData[i].id;
      newAllChecked[incomingData[i].id] = true;
    }

    setIsChecked(newAllChecked);
    setPostData(newAllSelectedToPost);
  };

  const { data: { data: tableData } = {} } = useGetDepreciationItemsPreviewTableData(filters);
  const { mutate: getMarkAll } = useGetSelectAllItems(depreciationItemsSelectAll);

  const handleChange = (e, id) => {
    const { checked } = e.target;

    setIsChecked((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
    if (checked) {
      // Add the id to postData
      setPostData((prevState) => ({
        ...prevState,
        [id]: id,
      }));
    } else {
      // Remove the id from postData
      setPostData((prevState) => {
        const { [id]: removedItem, ...rest } = prevState;
        setCheckedAll(false);
        return rest;
      });
    }
  };

  const handleChangeAll = (e) => {
    if (tableData.total > 0 && e.target.checked) {
      getMarkAll();
    } else {
      setPostData({});
      setIsChecked({});
    }
  };

  const highlightTableRow = (isChecked, index, rowIndex) => {
    const selectedKeys = Object.keys(isChecked).filter((key) => isChecked[key]);

    if (selectedKeys.length > 0 && selectedKeys.includes(String(rowIndex?.original?.id))) {
      return { className: 'rt-tr-group bg-highlight-green' };
    } else {
      return {};
    }
  };

  useEffect(() => {
    props.sendSelectedDataFromTable(postData);

    // Cleanup function
    return () => {};
  }, [postData]);

  const columns = useMemo(() => DepreciationItemsPreviewTableColumns(isChecked, handleChange, handleChangeAll, checkedAll, setCheckedAll));

  return (
    <DataGridComponent
      onFilterChange={onFilterChange}
      columns={columns}
      loading={false}
      dataSource={tableData}
      getTrGroupProps={(rowIndex, index) => highlightTableRow(isChecked, rowIndex, index)}
    />
  );
};
