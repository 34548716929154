import { formatDateForTable } from '../../../../utils/formatDataForTable';
import { formatAmount } from '../../../../utils/formatAmout';

export const BalanceSheetReportColumns = ({ toggleJournalEntryModal }) => [
  {
    accessor: (x) => x['account.number'],
    Header: <div className='leftHeader'>Account</div>,
    id: 1,
    Cell: (row) => (
      <div style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => toggleJournalEntryModal(row)}>
        {row.value}
      </div>
    ),
    sort: false,
  },
  {
    filterable: false,

    Header: <div className='leftHeader'>Account Name</div>,
    accessor: (x) => x['account.name'],
    id: 2,
    sort: false,
  },
  {
    accessor: 'total_sum',
    id: '3',
    Header: (props) => <div className='rightHeader'>Total</div>,
    Cell: (row) => <div style={{ textAlign: 'right' }}>{formatAmount(row.value)}</div>,
    sort: false,
  },
];
