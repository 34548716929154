import moment from 'moment';
import React, { useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { FormGroup, Label } from 'reactstrap';

export const DatePickerComponent = ({
  label,
  selected,
  isClearable,
  onChange,
  showTimeInput,
  showTimeSelect,
  dateFormat,
  disabled,
  placeholder,
  errorMess,
  borderBottomOnly,
  className,
  showYearDropdown,
  isTimestamp,
  minDate,
}) => {
  const onChangeHandler = (date) => {
    const value = date ? moment(date).format(isTimestamp ? 'MM/DD/YYYY HH:mm' : 'YYYY/MM/DD') : '';
    onChange(value);
  };

  const normalizedMinDate = useMemo(() => {
    if (!minDate) return null;

    // neutralize offset added by new Date
    const normalizedMinDate = new Date(minDate);

    const offsetInMinutes = normalizedMinDate.getTimezoneOffset();

    const offsetInMilliseconds = offsetInMinutes * 60 * 1000;

    return new Date(normalizedMinDate.getTime() + offsetInMilliseconds);
  }, [minDate]);

  return (
    <FormGroup>
      {label ? <Label className='text-dark'>{label}</Label> : null}
      <DatePicker
        popperProps={{
          strategy: 'fixed',
        }}
        minDate={normalizedMinDate}
        calendarClassName={showTimeSelect ? 'react-datepicker-custom-class' : ''}
        selected={selected ? (isTimestamp ? new Date(selected) : new Date(moment(selected))) : null}
        isClearable={isClearable}
        onChange={onChangeHandler}
        showYearDropdown={showYearDropdown}
        showTimeSelect={showTimeSelect}
        showTimeInput={showTimeInput}
        dateFormat={!dateFormat ? 'MM/dd/yyyy, hh:mm a' : dateFormat}
        disabled={disabled}
        placeholderText={placeholder}
        className={`${errorMess && 'red-border-datepicker'} ${!errorMess && borderBottomOnly && 'border-bottom-only-date-picker '}${className || ''}`}
      />

      <small className='text-danger'>{errorMess}</small>
    </FormGroup>
  );
};
