import React, {Fragment} from "react";
import {Button, ButtonGroup, Col, Label, Row} from "reactstrap";
import ClientSelectBox from "../../../../component/SelectBox/ClientSelectBox";
import DateRangeSelectBox from "../../../../component/SelectBox/DataRangeSelectBox";

export default function ClientBoxTransactionsFilters({filter,blankFilter,onFilterActionClick,onFilterChangeHandler,loader,clientType}){
    return(
        <Fragment>
            <div className="shadow-sm " style={{position:'absolute',top:'-15px',right:'10px'}}>
                <ButtonGroup vertical={false}>
                    <Button  color={'info'} className="btn btn-sm  " onClick={(e)=>{e.preventDefault(); e.target.blur(); onFilterActionClick('PDF')}} disabled={loader}>
                        {loader?<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span> : <i className="fas fa-download"> </i> }PDF
                    </Button>
                    <Button  color={'info'} className="btn btn-sm  " onClick={(e)=>{e.preventDefault(); e.target.blur(); onFilterActionClick('EXCEL')}} disabled={loader}>
                        {loader?<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span> : <i className="fas fa-download"> </i> }EXCEL
                    </Button>

                </ButtonGroup>
            </div>
            <Row className="p-0 " noGutters={true}>
                <Col md={12} className="pl-2 no-wrap ">

                    <div className="d-inline-block pr-2" style={{minWidth:'200px'}}>
                        <Label>Client:</Label>
                        <ClientSelectBox name='client_transactions.client_id'  clientType={clientType} field={filter['client_transactions.client_id']} onChange={onFilterChangeHandler}  />
                    </div>

                    <div className="d-inline-block pr-2" style={{minWidth:'200px'}}>
                        <Label>Date Range:</Label>
                        <DateRangeSelectBox name='name' field={{start:filter['start'],end:filter['end']}} onChange={onFilterChangeHandler} disabled={!!blankFilter['account.account_normal_balance_id']} />
                    </div>

                    <div className="d-inline-block pr-2 pl-2">
                        <Button color={'default'} className="btn btn-xs shadow " onClick={(e)=>{e.preventDefault(); e.target.blur(); onFilterActionClick('reset');}}> <i className="fas fa-sync-alt"> </i> RESET</Button>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}