import { Divider } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { CustomInput } from '../../../../common/CustomInput';
import { CustomSelect } from '../../../../common/CustomSelect';
import { CustomDialog } from '../../../../common/Dialog/CustomDialog';
import { CustomCheckbox } from '../../../../common/Inputs/CustomCheckbox';
import { CustomRadioGroup } from '../../../../common/Inputs/CustomRadioGroup';
import { CustomPageableAsyncSelect } from '../../../../common/Inputs/v2/AsyncSelect2';
import { useBalanceSheetOptions, useCashFlowOptions, useNormalBalanceOptions, useProfitLossOptions } from '../../../../hooks/commonApiHooks';
import { useAsyncPageable } from '../../../../hooks/useAsyncPageable';
import { notificationDanger } from '../../../../utils/toastify';
import { useAccount, useCreateOrUpdateAccount } from '../../ChartOfAccount/ChartOfAccountApi';

export default function AccountForm({ number = null, open = true, onToggle = () => {}, setRefresh }) {
  const blankForm = useMemo(() => {
    return {
      number: 0,
      name: '',
      account_normal_balance_id: 1,
      account_balance_sheet_id: 0,
      account_profit_loss_id: 0,
      account_cash_flow_id: 0,
      active: true,
      bank: false,
      master: false,
      parent: null,
      bank_code: null,
      bank_number: null,
    };
  }, []);

  const { fetchOptions } = useAsyncPageable();

  const loadOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/account/options/accounts', { q: inputValue, ...options });
    return data;
  };

  const { enqueueSnackbar } = useSnackbar();

  const accountingRepository = useSelector((state) => state.sys.account);
  const [isSubAccount, setIsSubAccount] = useState(false);
  const [form, setForm] = useState(blankForm);
  const [validatorNumber, setValidatorNumber] = useState(true);

  const { data: accountData } = useAccount(number);
  const { data: normalBalanceOptions } = useNormalBalanceOptions();
  const { data: balanceSheetOptions } = useBalanceSheetOptions();
  const { data: profitLossOptions } = useProfitLossOptions();
  const { data: cashFlowOptions } = useCashFlowOptions();

  const { mutate: createOrUpdate } = useCreateOrUpdateAccount({
    successCallback: () => {
      onToggle(false);
      if (!number) {
        //reset form on creation of new account
        resetForm();
      }
      enqueueSnackbar(number ? 'Account Updated!' : 'Account Created!', { variant: 'success', autoHideDuration: 5000 });
    },
    errorCallback: () => {
      enqueueSnackbar(number ? 'Account Update Failed!' : 'Account Creation Failed!', { variant: 'error', autoHideDuration: 5000 });
    },
  });

  const initAccountFilters = useMemo(() => {
    return { not_descendant_of: number, exclude_parent_of: number };
  }, [number]);

  useEffect(() => {
    if (accountData?.data) {
      setForm(accountData.data);
      setIsSubAccount(!!accountData.data.parent);
    }
  }, [accountData?.data]);

  useEffect(() => {
    if (!number) {
      //reset form if add new is clicked
      resetForm();
    }
  }, [number]);

  const resetForm = () => {
    setForm(blankForm);
    setIsSubAccount(false);
  };

  const handleChange = (e) => {
    const { type, name, value, checked } = e.target;
    const f = { ...form, [name]: type === 'radio' ? Number(value) : type === 'checkbox' ? checked : value };
    if (name === 'number' && !number) {
      setValidatorNumber(numberValidation(value));
    }

    setForm(f);
  };

  const handleSelect = (val, e) => {
    if (e.action === 'select-option') {
      let o = { target: { name: e.name, value: val.value } };
      handleChange(o);
      return;
    }
    if (e.action === 'clear') {
      let o = { target: { name: e.name, value: null } };
      handleChange(o);
    }
  };

  const numberValidation = (e) => {
    if (e.length < 4) return false;
    const a = accountingRepository.find((x) => x.number.toString() === e);
    if (a) return false;
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.bank) {
      const invalidCode = String(form.bank_code).length > 3;
      const invalidNumber = String(form.bank_number).length > 10;

      if (invalidCode || invalidNumber) {
        notificationDanger(invalidCode ? 'Bank Code must be up to 3 characters long' : 'Bank number must be up to 10 characters long');
        return;
      }
    }
    if (validatorNumber) {
      const { parent_account_name, ...data } = form;
      createOrUpdate({ id: number, data });
    }
  };

  const toggleSubAccount = () => {
    setIsSubAccount((p) => !p);
  };

  const onClose = (e) => {
    onToggle(false);
  };

  return (
    <>
      <CustomDialog
        isOpen={open}
        hideTitleButton
        modalTitle={number || number === 0 ? 'Edit existing account' : 'Create a new account '}
        modalSize={'md'}
        disableBackdropClick
        disableEscapeKeyDown
        buttonTitle={<i className='fas fa-edit' color='white' />}
        saveButtonTitle={number || number === 0 ? 'Update Account' : 'Create New Account'}
        actionButtonDisabled={!validatorNumber}
        onSave={handleSubmit}
        onClose={onClose}
      >
        <Row>
          <Col md={12}>
            <Row>
              <Col>
                <CustomInput label='Name' type='text' name='name' value={form['name']} placeholder='Name' onChange={handleChange} />
              </Col>
            </Row>
            <Row>
              <Col md={4} className='d-flex align-items-center'>
                <CustomCheckbox label='Subaccount' name='subAccount' checked={isSubAccount} onChange={toggleSubAccount} />
              </Col>
              {/* {form['parent_account_name'] && (
                <Col md={4}>
                  <CustomInput label='Sub Account Of' disabled onChange={() => {}} name='parent_account_name' value={form['parent_account_name']} />
                </Col>
              )} */}
              <Col md={4}>
                {isSubAccount && (
                  <CustomPageableAsyncSelect
                    defKey={Math.random()}
                    initFilters={initAccountFilters}
                    label={'Subaccount Of:'}
                    isClearable
                    onChange={handleSelect}
                    loadOptions={loadOptions}
                    name='parent'
                    initValue={accountData?.data?.parent}
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <CustomInput
              label='Number'
              type='number'
              name='number'
              value={form['number']}
              onChange={handleChange}
              disabled={number}
              invalid={!validatorNumber}
              errorMess={!validatorNumber && 'Account number already exists'}
            />
          </Col>
          <Col md={4}>
            <CustomSelect
              label='Normal Balance'
              onChange={handleSelect}
              name='account_normal_balance_id'
              defaultValue={form['account_normal_balance_id']}
              options={normalBalanceOptions?.data || []}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <CustomCheckbox label='Master Account' name='master' checked={form['master']} onChange={handleChange} />
          </Col>
          <Col md={4}>
            <CustomCheckbox id='bank' label='Bank Account' name='bank' checked={form['bank']} onChange={handleChange} />
          </Col>
          <Col md={4}>
            <CustomCheckbox id='active' label='Active' name='active' checked={form['active']} onChange={handleChange} />
          </Col>
        </Row>

        <Divider />
        {form.bank && (
          <Row>
            <Col md='4'>
              <CustomInput
                label='Bank Code'
                type='text'
                name='bank_code'
                value={form['bank_code']}
                placeholder='Code'
                onChange={handleChange}
                errorMess={form.bank && (String(form.bank_code).length > 3 || !form.bank_code) && 'Bank Code must be up to 3 characters long'}
              />
            </Col>
            <Col md='4'>
              <CustomInput
                label='Bank Number'
                type='number'
                name='bank_number'
                value={form.bank_number}
                placeholder='Bank Number'
                onChange={handleChange}
              />
            </Col>
            <Col md='4'>
              <CustomInput
                label='Bank Routing Number'
                type='number'
                name='bank_routing_number'
                value={form.bank_routing_number}
                placeholder='Bank Number'
                onChange={handleChange}
              />
            </Col>
            <Col md='4'>
              <CustomInput label='Name' name='bank_name' value={form.bank_name} placeholder='Bank Name' onChange={handleChange} />
            </Col>
          </Row>
        )}
        <Divider />

        <Row className='pt-3'>
          <Col md={4}>
            <CustomRadioGroup
              label='Balance Sheet'
              name={'account_balance_sheet_id'}
              onChange={handleChange}
              items={balanceSheetOptions?.data || []}
              selected={form['account_balance_sheet_id']}
            />
          </Col>
          <Col md={4}>
            <CustomRadioGroup
              label='Profit Loss'
              name={'account_profit_loss_id'}
              onChange={handleChange}
              items={profitLossOptions?.data || []}
              selected={form['account_profit_loss_id']}
            />
          </Col>
          <Col md={4}>
            <CustomRadioGroup
              label='Cash Flow'
              name={'account_cash_flow_id'}
              selected={form['account_cash_flow_id']}
              onChange={handleChange}
              items={cashFlowOptions?.data || []}
            />
          </Col>
        </Row>
      </CustomDialog>
    </>
  );
}
