import moment from 'moment';
import React, { Fragment, useState } from 'react';
import Select from 'react-select';
import { Button, FormGroup, Input, Row } from 'reactstrap';

const CUSTOM = 1;
const RANGE = 2;

export default function DateRangeSelectBox({ name, onChange = () => {}, field = { start: null, end: null }, isDateRange = false }) {
  const [selectType, setSelectType] = useState(RANGE);
  const options = [
    {
      label: 'Custom',
      value: 'c',
      range: {
        start: null,
        end: null,
      },
    },
    {
      label: 'Today',
      value: '1',
      range: {
        start: moment().format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      },
    },
    {
      label: 'This Week',
      value: '2',
      range: {
        start: moment().startOf('isoWeek').format('YYYY-MM-DD'),
        end: moment().endOf('isoWeek').format('YYYY-MM-DD'),
      },
    },
    {
      label: 'This Week-to-date',
      value: '3',
      range: {
        start: moment().startOf('isoWeek').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      },
    },
    {
      label: 'This Month',
      value: '4',
      range: {
        start: moment().startOf('month').format('YYYY-MM-DD'),
        end: moment().endOf('month').format('YYYY-MM-DD'),
      },
    },
    {
      label: 'This Month-to-date',
      value: '5',
      range: {
        start: moment().startOf('month').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      },
    },
    {
      label: 'This Fiscal Quarter',
      value: '6',
      range: {
        start: moment().startOf('quarter').format('YYYY-MM-DD'),
        end: moment().endOf('quarter').format('YYYY-MM-DD'),
      },
    },
    {
      label: 'This Fiscal Quarter-to-date',
      value: '7',
      range: {
        start: moment().startOf('quarter').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      },
    },
    {
      label: 'This Fiscal Year',
      value: '8',
      range: {
        start: moment().startOf('year').format('YYYY-MM-DD'),
        end: moment().endOf('year').format('YYYY-MM-DD'),
      },
    },
    {
      label: 'This Fiscal Year-to Last Month',
      value: '9',
      range: {
        start: moment().startOf('year').format('YYYY-MM-DD'),
        end: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
      },
    },
    {
      label: 'This Fiscal Year-to-date',
      value: '10',
      range: {
        start: moment().startOf('year').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Yesterday',
      value: '11',
      range: {
        start: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        end: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Last Week',
      value: '12',
      range: {
        start: moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD'),
        end: moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Last Week-to-date',
      value: '13',
      range: {
        start: moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD'),
        end: moment().subtract(1, 'week').format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Last Month',
      value: '14',
      range: {
        start: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        end: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Last Month-to-date',
      value: '15',
      range: {
        start: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        end: moment().subtract(1, 'month').format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Last Fiscal Quarter',
      value: '16',
      range: {
        start: moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'),
        end: moment().subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Last Fiscal Quarter-to-date',
      value: '17',
      range: {
        start: moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'),
        end: moment().subtract(1, 'quarter').format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Last Fiscal Year',
      value: '18',
      range: {
        start: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
        end: moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Last Fiscal Year-to-date',
      value: '19',
      range: {
        start: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
        end: moment().subtract(1, 'year').format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Next Week',
      value: '20',
      range: {
        start: moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
        end: moment().add(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Next 4 Weeks',
      value: '21',
      range: {
        start: moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
        end: moment().add(4, 'weeks').endOf('isoWeek').format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Next Month',
      value: '22',
      range: {
        start: moment().add(1, 'month').startOf('month').format('YYYY-MM-DD'),
        end: moment().add(1, 'month').endOf('month').format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Next Fiscal Quarter',
      value: '23',
      range: {
        start: moment().add(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'),
        end: moment().add(1, 'quarter').endOf('quarter').format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Next Fiscal Year',
      value: '24',
      range: {
        start: moment().add(1, 'year').startOf('year').format('YYYY-MM-DD'),
        end: moment().add(1, 'year').endOf('year').format('YYYY-MM-DD'),
      },
    },
  ];

  const selectHandler = (val, e) => {
    if (e.action === 'select-option') {
      if (val.value !== 'c') {
        onChange({ target: { name: 'name', value: val.range } });
      } else {
        setSelectType(CUSTOM);
        onChange({ target: { name: 'name', value: { start: null, end: null } } });
      }
    }
    if (e.action === 'clear') {
      onChange({ target: { name: 'name', value: { start: null, end: null } } });
    }
  };

  const onCustomHandler = (e) => {
    onChange({ target: { name: [e.target.name], value: e.target.value } });
  };

  if (selectType === CUSTOM) {
    return (
      <Fragment>
        <Row noGutters>
          <FormGroup className='col m-0'>
            <Input key={'start'} type='date' value={field.start} name='start' onChange={onCustomHandler} />
          </FormGroup>
          {isDateRange && (
            <FormGroup className='col m-0'>
              <Input key={'end'} type='date' value={field.end} name='end' onChange={onCustomHandler} />
            </FormGroup>
          )}
          <FormGroup className='col m-0'>
            <Button
              color='info'
              onClick={(e) => {
                e.preventDefault();
                setSelectType(RANGE);
              }}
            >
              <i className='fas fa-times-circle'> </i>{' '}
            </Button>
          </FormGroup>
        </Row>
      </Fragment>
    );
  } else {
    return (
      <Select
        name={name}
        isClearable={true}
        options={options}
        value={options.filter((x) => {
          try {
            return x.range.start === field.start && x.range.end === field.end;
          } catch (e) {
            return false;
          }
        })}
        placeholder={'Select'}
        onChange={selectHandler}
        className='input-sm'
        styles={{
          indicatorSeparator: () => ({
            display: 'none',
          }),
        }}
      />
    );
  }
}
