import { IconButton, Tooltip } from '@mui/material';
import { ContentCopy, Delete, Edit } from '@mui/icons-material';
import { formatDateForTable } from '../../../../utils/formatDataForTable';
import { formatAmount } from '../../../../utils/formatAmout';
import { getRecurringTransactionTypes } from '../../../../constants/transactionTypesRecurring.constants';
import { getAccountType } from '../../../../constants/accountType.constants';

export const QueueTableColumns = ({ onDeleteClick, onEditClick, onDuplicateClick }) => [
  {
    Header: 'Transaction Type',
    Cell: (row) => <div>{getRecurringTransactionTypes(+row.value)}</div>,
    accessor: (d) => d['transaction_type'],
    id: 'transaction_type',
    sort: true,
  },
  {
    accessor: (d) => d['account_type'],
    id: 'account_type',
    Header: 'Account Type',
    Cell: (row) => getAccountType(+row.value),
    sort: true,
  },
  {
    Header: 'Account Number',
    Cell: (row) => <div>{row.value}</div>,
    accessor: (d) => d['account_number'],
    id: 'account_number',
    sort: true,
  },
  {
    Header: 'Subaccount Number',
    Cell: (row) => <div>{row.value}</div>,
    accessor: (d) => d['client_id'],
    id: 'client_id',
    sort: true,
  },
  {
    accessor: (d) => d['debit_amount'],
    id: 'debit_amount',
    Header: (props) => 'Debit Amount',
    Cell: (row) => formatAmount(row.value),
    sort: true,
  },
  {
    accessor: (d) => d['credit_amount'],
    id: 'credit_amount',
    Header: (props) => 'Credit Amount',
    Cell: (row) => formatAmount(row.value),
    sort: true,
  },
  // {
  //   accessor: (d) => d['total_amount'],
  //   id: 'total_amount',
  //   Header: (props) => 'Total',
  //   Cell: (row) => formatAmount(row.value),
  //   sort: true,
  // },
  {
    accessor: (d) => d['document_number'],
    id: 'document_number',
    Header: (props) => 'Document Number',
    Cell: (row) => row.value,
    sort: true,
  },
  {
    Header: 'Description',
    Cell: (row) => row.value,
    accessor: (d) => d['description'],
    id: 'description',
    sort: true,
  },
  {
    Header: 'Branch',
    Cell: (row) => row.value,
    accessor: (d) => d['branch_name'],
    id: 'branch_name',
    sort: true,
  },
  {
    accessor: (d) => d['posting_date'],
    id: 'posting_date',
    Header: 'Posting Date',
    Cell: (row) => formatDateForTable(row.value),
    sort: true,
  },
  {
    accessor: (d) => d['reverse_date'],
    id: 'reverse_date',
    Header: 'Reverse Date',
    Cell: (row) => formatDateForTable(row.value),
    sort: true,
  },
  {
    id: 'actions',
    sort: false,
    Header: 'Actions',
    accessor: (row) => {
      return (
        <div className='d-flex justify-content-center'>
          <Tooltip title='Edit Row' placement='top' arrow followCursor>
            <IconButton onClick={() => onEditClick(row)} color='primary'>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title='Delete Row' placement='top' arrow followCursor>
            <IconButton onClick={() => onDeleteClick(row)} color='error'>
              <Delete />
            </IconButton>
          </Tooltip>
          <Tooltip title='Duplicate Row' placement='top' arrow followCursor>
            <IconButton onClick={() => onDuplicateClick(row)} color='success'>
              <ContentCopy />
            </IconButton>
          </Tooltip>
        </div>
      );
    },
  },
];
