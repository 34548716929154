import React, { useState } from 'react';
import { Button, Col, Row, Collapse } from 'reactstrap';
import Select from 'react-select';

const ExportButton = ({ disabled, exportPagePDF, exportPageEXCEL, exportTablePDF, exportTableEXCEL, style, showOnly = 'ALL' }) => {
  const [showExport, setShowExport] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [selectedFileSize, setselectedFileSize] = useState({
    value: 'tabel',
    label: (
      <span>
        <i className='fas fa-table' /> Whole Table
      </span>
    ),
  });

  const handleChange = (selectedValue, e) => {
    if (e.name == 'selectedFileSize') {
      setselectedFileSize(selectedValue);
    } else {
      setSelectedFileType(selectedValue);
    }
  };

  const handleExport = () => {
    if (selectedFileSize.value === 'page') {
      if (selectedFileType.value === 'pdf') {
        exportPagePDF();
      } else {
        exportPageEXCEL();
      }
    } else {
      if (selectedFileType.value === 'pdf') {
        exportTablePDF();
      } else {
        exportTableEXCEL();
      }
    }
  };

  const toggleExport = () => setShowExport(!showExport);

  const options =
    showOnly === 'ALL'
      ? [
          {
            value: 'pdf',
            label: (
              <span>
                <i className='far fa-file-pdf' style={{ color: 'red' }} /> PDF
              </span>
            ),
          },
          {
            value: 'excel',
            label: (
              <span>
                <i className='far fa-file-excel' style={{ color: '#1d6f42' }} /> EXCEL
              </span>
            ),
          },
        ]
      : showOnly === 'PDF'
      ? [
          {
            value: 'pdf',
            label: (
              <span>
                <i className='far fa-file-pdf' style={{ color: 'red' }} /> PDF
              </span>
            ),
          },
        ]
      : showOnly === 'EXCEL'
      ? [
          {
            value: 'excel',
            label: (
              <span>
                <i className='far fa-file-excel' style={{ color: '#1d6f42' }} /> EXCEL
              </span>
            ),
          },
        ]
      : [];

  return (
    <div className='export-button' style={{ position: 'relative' }}>
      <Button style={{ display: 'block', ...style }} onClick={toggleExport}>
        {disabled ? (
          <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'>
            {' '}
          </span>
        ) : (
          <span>
            <i className='fas fa-file-export' /> {!showExport ? 'Export Table' : 'Close Export Table'}
          </span>
        )}
      </Button>
      <Collapse
        className='p-3 box-shadow'
        isOpen={showExport}
        style={{ position: 'absolute', right: -20, top: '165%', zIndex: 11, width: 250, background: 'white', color: 'black' }}
      >
        <Row className='mb-3'>
          <Col xs={12}>
            <Select
              options={
                exportPageEXCEL
                  ? [
                      {
                        value: 'page',
                        label: (
                          <span>
                            <i className='fas fa-columns' /> Current Page
                          </span>
                        ),
                      },
                      {
                        value: 'tabel',
                        label: (
                          <span>
                            <i className='fas fa-table' /> Whole Table
                          </span>
                        ),
                      },
                    ]
                  : [
                      {
                        value: 'tabel',
                        label: (
                          <span>
                            <i className='fas fa-table' /> Whole Table
                          </span>
                        ),
                      },
                    ]
              }
              isClearable
              placeholder='What to export...'
              value={selectedFileSize}
              name='selectedFileSize'
              onChange={handleChange}
              styles={{
                indicatorSeparator: () => ({
                  display: 'none',
                }),
              }}
              isDisabled={disabled}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs={12}>
            <Select
              options={options}
              isClearable
              placeholder='Select file type...'
              value={selectedFileType}
              name='selectedFileType'
              onChange={handleChange}
              styles={{
                indicatorSeparator: () => ({
                  display: 'none',
                }),
              }}
              isDisabled={disabled}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} style={{ textAlign: 'right' }}>
            <Button color='secondary' outline onClick={handleExport} disabled={disabled}>
              {' '}
              <i className='fas fa-download' /> Export
            </Button>
          </Col>
        </Row>
      </Collapse>
    </div>
  );
};

export default ExportButton;
