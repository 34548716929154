import { IconButton, Tooltip } from '@mui/material';
import { SummarizeOutlined, EditOutlined, DeleteOutline, FileOpenOutlined } from '@mui/icons-material';
import { formatDateForTable } from '../../../../utils/formatDataForTable';
import { formatAmount } from '../../../../utils/formatAmout';
import { Link } from 'react-router-dom';

export const BankReconciliationTableColumns = ({ onOpenReport, onUpdate, onDelete, onReopen }) => {
  return [
    {
      accessor: 'statment_date',
      Header: 'Statement Date',
      Cell: (row) => formatDateForTable(row.value),
    },
    {
      accessor: 'ending_balance',
      Header: 'Ending Balance',
      Cell: (row) => formatAmount(row.value),
    },
    {
      accessor: 'begining_balance',
      Header: 'Begining Balance',
      Cell: (row) => formatAmount(row.value),
    },
    {
      accessor: 'account',
      Header: 'Account',
      Cell: (row) =>
        row.original.finish ? (
          row.value
        ) : (
          <Tooltip title='Edit Reconciliation' placement='top' arrow followCursor>
            <Link to={'/bank-reconciliation/reconciliation/' + row.original.id}>{row.value}</Link>
          </Tooltip>
        ),
    },
    {
      accessor: 'finish',
      Header: 'Status',
      Cell: (row) => {
        return row.value ? 'Finished' : 'Open';
      },
    },
    {
      accessor: 'reports',
      Header: 'Actions',
      Cell: (row) => {
        return row.original.finish ? (
          <div className='d-flex align-items-center justify-content-center'>
            <Tooltip title='Open Reports' placement='top' arrow followCursor>
              <IconButton color='primary' onClick={() => onOpenReport(row)}>
                <SummarizeOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title='Reopen Reconciliation' placement='top' arrow followCursor>
              <IconButton disabled={!Boolean(row?.original?.reopenable)} color='warning' onClick={() => onReopen(row)}>
                <FileOpenOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title='Delete' placement='top' arrow followCursor>
              <IconButton color='error' onClick={(e) => onDelete(e, row)}>
                <DeleteOutline />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <div className='d-flex align-items-center justify-content-center'>
            <Tooltip title='Edit' placement='top' arrow followCursor>
              <IconButton color='primary' onClick={() => onUpdate(row)}>
                <EditOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title='Delete' placement='top' arrow followCursor>
              <IconButton color='error' onClick={(e) => onDelete(e, row)}>
                <DeleteOutline />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
      maxWidth: 100,
      minWidth: 100,
    },
  ];
};
