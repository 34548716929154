import { Box, ClickAwayListener, Fade, Paper, Popper, styled } from '@mui/material';
import React, { useState } from 'react';

import PropTypes from 'prop-types';

/** use children only as anchor element, or else provide one
 *  if anchor is used use null as default value instead of undefined
 */
const CustomPopup = ({ placement, arrow, open, onClose, content, children, anchor, overlayModal }) => {
  const [arrowRef, setArrowRef] = useState(null);
  const [childNode, setChildNode] = useState(null);

  const modifiers = [
    {
      name: 'preventOverflow',
      enabled: true,
      options: {
        boundariesElement: 'window',
      },
    },
    {
      name: 'arrow',
      enabled: arrow,
      options: {
        element: arrowRef,
      },
    },
  ];

  return (
    <div>
      {children && !anchor && (
        <div onClick={(e) => setChildNode(e.currentTarget)} style={{ width: 'max-content' }}>
          {children}
        </div>
      )}

      {open && (
        <StyledPopper
          open={open}
          anchorEl={anchor ? anchor : childNode}
          placement={placement}
          transition
          overlayModal={overlayModal}
          className={'popper'}
          modifiers={modifiers}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener
              onClickAway={() => {
                setTimeout(() => {
                  onClose();
                }, 250);
              }}
            >
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <>
                    {arrow ? <span className={'arrow'} ref={setArrowRef} /> : null}
                    <Paper className={'popover-root'}>
                      <Box className={'popper-content'}>{typeof content === 'function' ? content() : content}</Box>
                    </Paper>
                  </>
                </Paper>
              </Fade>
            </ClickAwayListener>
          )}
        </StyledPopper>
      )}
    </div>
  );
};

CustomPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  arrow: PropTypes.bool,
  placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  children: PropTypes.oneOfType([PropTypes.element]),
  onClose: PropTypes.func,
  anchor: PropTypes.instanceOf(Element),
  overlayModal: PropTypes.bool,
  customValidation: (props) => {
    if (!props.children && !props.anchor && props.anchor !== null) {
      return new Error(`One among children or anchor prop must be provided`);
    }
  },
};

CustomPopup.defaultProps = {
  arrow: false,
  placement: 'bottom',
  onClose: () => {},
  overlayModal: false,
};

const StyledPopper = styled(Popper)(({ theme, overlayModal }) => {
  return `&&{
  z-index: ${overlayModal ? theme.options.popper.overlayModalZIndex : theme.options.popper.zIndex};
  border-radius: ${theme.options.popper.borderRadius};

  &[data-popper-placement*="bottom"] .arrow{
    width: 0; 
    height: 0; 
    border-left: 1em solid transparent;
    border-right: 1em solid transparent;
    border-bottom: 1em solid ${theme.palette.neutral.main};
    margin-top: -0.9em;
    
    &:before {
      border-width: '0 1em 1em 1em';
      border-color: 'transparent transparent white transparent';
    }
  }

  &[data-popper-placement*="top"] .arrow{
    bottom: 0;
    width: 0; 
    height: 0; 
    border-left: 1em solid transparent;
    border-right: 1em solid transparent;
    border-top: 1em solid ${theme.palette.neutral.main};
    margin-bottom: -0.9em;

    &:before {
      border-width: 1em 1em 0 1em;
      border-color: white transparent transparent transparent;
    }
  }

  &[data-popper-placement*="right"] .arrow{
    left: 0;
    width: 0; 
    height: 0; 
    border-top: 1em solid transparent;
    border-bottom: 1em solid transparent;
    border-right: 1em solid ${theme.palette.neutral.main};
    margin-left: -0.9em;

    &:before {
      border-width: 1em 1em 1em 0;
      border-color: transparent white transparent transparent;
    }
  }

  &[data-popper-placement*="left"] .arrow{
    right: 0;
    width: 0; 
    height: 0; 
    border-top: 1em solid transparent;
    border-bottom: 1em solid transparent;
    border-left: 1em solid ${theme.palette.neutral.main};
    margin-right: -0.9em;

    &:before {
      border-width: 1em 0 1em 1em;
      border-color: transparent transparent transparent white;
    }
  }

  .arrow {
    position: absolute;
    font-size: 7px;
    width: 3em;
    height: 3em;

    &:before {
      content: '""',
      margin: auto;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
    }
  }

  .popover-root {
      max-width: 1000px;
  }
  
  .content {
      background-color: 'white';
      padding: 0.5rem;   
  }

  .popper-content {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.palette.neutral.main};
    color: ${theme.palette.neutral.contrastText};
    border-radius:5px;
  }
}`;
});

export { CustomPopup };
