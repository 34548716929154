import React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import PropTypes from 'prop-types';

const StyledCard = styled(Card)(() => ({
  maxWidth: '100%',
  border: '1px solid rgba(0,0,0,0.2)',
  borderRadius: '8px',
  boxShadow: 'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px',
}));

const StyledCardHeader = styled(CardHeader)(() => ({
  backgroundColor: '#2f353a',
  color: '#fff',
}));

const CustomCard = ({ headerTitle, children, sxCardContainer, sxCardHeader }) => {
  return (
    <StyledCard sx={sxCardContainer}>
      <StyledCardHeader sx={sxCardHeader} title={headerTitle} titleTypographyProps={{ fontSize: '18px' }} />
      <CardContent>{children}</CardContent>
    </StyledCard>
  );
};

CustomCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  headerTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  sxCardContainer: PropTypes.object,
};

CustomCard.defaultProps = {
  sxCardContainer: {},
};

export { CustomCard };
