import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const composeUrl = () => {
  const baseUrl = '/me/report/trial-balance';

  return baseUrl;
};

const getTrialBalance = (filters) => {
  const url = composeUrl();
  return axios.get(url, { params: { ...filters } });
};

export const useTrialBalance = (filters) => {
  return useQuery(['reports.trial-balance'], () => getTrialBalance(filters), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};
