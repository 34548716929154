import { CustomCard } from '../../../../common/Card/CustomCard';
import { DepreciationItemsPreviewTable } from './DepreciationItemsPreviewTable';
import { useState } from 'react';
import { DepreciationItemsModal } from './DepreciationItemsModal';
import { Button } from '@mui/material';
import moment from 'moment';

export const DepreciationItemsPreview = () => {
  const defaultDocumentNumber = moment().subtract(1, 'months').format('MMM YY') + ' Deprec. Adj';
  const [selectedDataFromTable, setSelectedDataFromTable] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [postingData, setPostingData] = useState({
    documentNumber: defaultDocumentNumber,
    postingDate: '',
    comment: '',
  });

  const handleSelectedDataFromTable = (data) => {
    setSelectedDataFromTable(data);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const disablePostButton = () => {
    if (Object.keys(selectedDataFromTable).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <CustomCard headerTitle='Depreciation Preview' sxCardContainer={{ mt: 2 }}>
      <div className='d-flex justify-content-end w-100 mb-2'>
        <Button disabled={disablePostButton()} onClick={handleOpenModal}>
          {disablePostButton() ? <span>SELECT DEPRICIATIONS TO PROCEED</span> : <span>Post</span>}
        </Button>
      </div>
      {isModalOpen && (
        <DepreciationItemsModal
          postingData={postingData}
          setPostingData={setPostingData}
          isModalOpen
          setIsModalOpen={setIsModalOpen}
          selectedDataFromTable={selectedDataFromTable}
          setSelectedDataFromTable={setSelectedDataFromTable}
          defaultDocumentNumber={defaultDocumentNumber}
        />
      )}
      <DepreciationItemsPreviewTable sendSelectedDataFromTable={handleSelectedDataFromTable} />
    </CustomCard>
  );
};
