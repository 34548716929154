import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import PropTypes from 'prop-types';

export const CustomCheckbox = ({ id, label, labelPosition, checked, onChange, name, disabled }) => {
  return (
    <FormGroup sx={{ userSelect: 'none' }}>
      <FormControlLabel
        label={label}
        labelPlacement={labelPosition}
        control={
          <Checkbox
            id={id}
            name={name}
            onChange={onChange}
            inputProps={{ 'aria-label': 'controlled' }}
            checked={checked}
            value={checked}
            disabled={disabled}
          />
        }
      />
    </FormGroup>
  );
};

CustomCheckbox.propTypes = {
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(['top', 'bottom', 'start', 'end']),
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

CustomCheckbox.defaultProps = {
  labelPosition: 'end',
  checked: false,
};
