import { LayersClearOutlined } from '@mui/icons-material';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { Button, IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { CustomCard } from '../../../../../../../common/Card/CustomCard';
import { CustomDialog } from '../../../../../../../common/Dialog/CustomDialog';
import { CustomCheckbox } from '../../../../../../../common/Inputs/CustomCheckbox';
import DataGridComponent from '../../../../../../../component/DataTable/component/DataTableComponent';
import { useAllClientsWithUnpaidTransactions, useBillingPayment } from '../../../hooks';
import { BillingPaymentItemForm } from '../../items/BillingPaymentItemForm';
import { columns as transactionColumns } from './unpaidTransactionsColumns';

export const AllUnpaidTransactions = ({ initFilters }) => {
  const { clientType, billingId } = useParams();

  const blankFilter = useMemo(() => {
    return {
      'transactions_due_date.due_date': initFilters['transactions_due_date.due_date'] || moment().format('YYYY/MM/DD'),
      client_id: initFilters.client_id || null,
      preferred_payment_method: initFilters.preferred_payment_method || null,
      payment_priority: initFilters.payment_priority || null,
      start: '',
      end: '',
      page: 0,
      pageSize: 10,
    };
  }, [initFilters]);

  const blankData = useMemo(() => {
    return { data: [], page: 0, pageSize: 10, pageTotal: 0 };
  }, []);

  const [filter, setFilter] = useState(blankFilter);
  const [modalOptions, setModalOptions] = useState({
    billingItem: false,
  });

  //  contains data | rows from BE
  const [selectedRows, setSelectedRows] = useState([]);

  const [insertData, setInsertData] = useState({});

  const { data: { data: transactions = {} } = {} } = useAllClientsWithUnpaidTransactions({ billingId, clientType, filters: filter });
  const { data: { data: billingPayment = {} } = {} } = useBillingPayment(clientType, billingId);

  const handleTableFilterChange = (input) => {
    setFilter((prev) => ({ ...prev, ...input }));
  };

  const handleCheckboxChange = (row, selectMultiple) => {
    if (!selectMultiple) {
      const transactionId = row?.original['transactions.id'];
      const hasElement = !!selectedRows.find((element) => element['transactions.id'] == transactionId);
      let uniqueSelectedInvoices = [...selectedRows];

      if (transactionId) {
        if (hasElement && !row?.original?.invoice_status?.used) {
          uniqueSelectedInvoices = [...uniqueSelectedInvoices.filter((element) => element['transactions.id'] != transactionId)];
        } else {
          uniqueSelectedInvoices.push(row.original);
        }

        setSelectedRows(uniqueSelectedInvoices);
      }
    } else {
      if (transactions?.data) {
        const selectableFromPage = transactions.data.reduce((count, transaction) => {
          const transactionId = transaction['transactions.id'];
          const hasElement = selectedRows.find((element) => element['transactions.id'] == transactionId);
          const isDisabled = transaction.invoice_status.used;

          if (!hasElement && !isDisabled) {
            count += 1;
          }

          return count;
        }, 0);

        let uniqueSelectedInvoices = [...selectedRows];

        transactions.data.map((transaction) => {
          const transactionId = transaction['transactions.id'];
          const hasElement = !!selectedRows.find((element) => element['transactions.id'] == transactionId);
          const isDisabled = transaction.invoice_status.used;

          if (!isDisabled) {
            // select unselected from page => select whole page
            if (selectableFromPage !== 0) {
              if (!hasElement) {
                const rowElement = row.data.find((tableRow) => tableRow._original['transactions.id'] == transactionId);
                if (rowElement) {
                  uniqueSelectedInvoices.push(transaction);
                }
              }
            } else {
              // select/remove all
              if (hasElement) {
                uniqueSelectedInvoices = [...uniqueSelectedInvoices.filter((element) => element['transactions.id'] != transactionId)];
              } else {
                const rowElement = row.data.find((tableRow) => tableRow._original['transactions.id'] == transactionId);
                if (rowElement) {
                  uniqueSelectedInvoices.push(transaction);
                }
              }
            }
          }
        });

        setSelectedRows(uniqueSelectedInvoices);
      }
    }
  };

  const getInsertData = useCallback(() => {
    let totalInvoices = 0;
    let totalAmount = 0.0;
    const mappedData = selectedRows.reduce((result, element) => {
      const key = element['client.id'];

      if (!result[key]) {
        result[key] = {
          invoices: [],
        };
      }

      const invoice = {
        'transactions.document_date': element['transactions.document_date'],
        'transactions.document_number': element['transactions.document_number'],
        'transactions.id': element['transactions.id'],
        'transactions_due_date.balance': element['transactions_due_date.balance'],
        'transactions_due_date.due_date': element['transactions_due_date.due_date'],
      };

      const prop = {
        'client.id': element['client.id'],
        'client.name': element['client.name'],
        'client.vendor_parent': element['client.vendor_parent'],
      };

      result[key] = { ...prop, invoices: [...result[key].invoices, invoice] };
      return result;
    }, {});

    const processedData = Object.keys(mappedData).map((invoiceId) => {
      const element = mappedData[invoiceId];
      const clientInvoiceCount = element.invoices.length;
      const clientTotalAmount = element.invoices.reduce((accumulator, invoice) => accumulator + invoice['transactions_due_date.balance'], 0);

      totalInvoices += clientInvoiceCount;
      totalAmount += clientTotalAmount;
      return { ...element, total: clientTotalAmount, count: clientInvoiceCount };
    });

    return { data: processedData, totalAmount, totalInvoices };
  }, [selectedRows]);

  const handleModalClose = (name) => {
    setModalOptions((prevValue) => ({
      ...prevValue,
      [name]: false,
    }));
  };

  const handlePayAllClick = () => {
    setModalOptions((prevValue) => ({
      ...prevValue,
      billingItem: true,
    }));

    const insertData = getInsertData();
    setInsertData(insertData);
  };

  const resetSelectedRows = () => {
    setSelectedRows([]);
  };

  const columns = useMemo(
    () =>
      transactionColumns({
        SelectRow: (
          <MemoizedSelectRow
            name='selectedTransactions'
            onChange={handleCheckboxChange}
            selectedRows={selectedRows}
            multiselectDisabled={!transactions?.data?.length}
          />
        ),
      }),
    [selectedRows, handleCheckboxChange]
  );

  return (
    <>
      <CustomDialog
        fullWidth
        isOpen={modalOptions.billingItem}
        actionButtonDisabled
        disableEscapeKeyDown
        disableBackdropClick
        hideActions
        hideTitleButton
        modalSize={'xl'}
        modalTitle={'Payment Item'}
        onClose={() => handleModalClose('billingItem')}
      >
        <BillingPaymentItemForm
          callbacks={{
            mandatory: resetSelectedRows,
          }}
          closeModal={() => handleModalClose('billingItem')}
          billingId={billingId}
          multiple
          multipleInsertData={insertData}
          defaultPostingDate={billingPayment.posting_date}
        />
      </CustomDialog>
      <Row>
        <Col>
          <CustomCard
            headerTitle={
              <div className='d-flex justify-content-end'>
                <Tooltip placement='bottom' arrow title={`Payment`}>
                  <Button color='success' disabled={!selectedRows.length} onClick={handlePayAllClick}>
                    <LayersClearOutlined />
                  </Button>
                </Tooltip>
              </div>
            }
            sxCardHeader={{ backgroundColor: 'transparent' }}
          >
            <DataGridComponent dataSource={transactions || blankData} onFilterChange={handleTableFilterChange} columns={columns} />
          </CustomCard>
        </Col>
      </Row>
    </>
  );
};

const SelectRow = ({ row, name, onChange, selectedRows = [], selectMultiple, multiselectDisabled }) => {
  const [checked, setChecked] = useState(false);
  const disabled = useMemo(() => {
    if ((selectMultiple && multiselectDisabled) || row?.original?.invoice_status.used) {
      return true;
    }
  }, [row, selectMultiple, multiselectDisabled]);

  useEffect(() => {
    if (!selectMultiple) {
      setChecked(!!selectedRows.find((selectedRow) => selectedRow['transactions.id'] == row?.original['transactions.id']));
    } else {
      setChecked(!!selectedRows.length);
    }
  }, [selectMultiple, selectedRows, row]);

  if (row?.original?.invoice_status.used) {
    return (
      <Tooltip title={`Invoice already added in payment item ${row?.original?.invoice_status.used_in}`}>
        <IconButton>
          <DoDisturbIcon color='warning' />
        </IconButton>
      </Tooltip>
    );
  }

  return <CustomCheckbox disabled={disabled} name={name} checked={checked} onChange={() => onChange(row, selectMultiple)} />;
};

const MemoizedSelectRow = React.memo(SelectRow);
