import moment from 'moment';

const getAccountFullName = (row) => {
  return `${row['account.number'] ? `${row['account.number']}` : ''} - ${row['account.name'] ? row['account.name'] : ''}`;
};

export const mapDataToExcel = (data) => {
  if (!data || !Array.isArray(data)) return [];

  const normalizedData = data.map((row) => {
    return {
      ...row,
      accountFullName: getAccountFullName(row),
      is_reversed: row.is_reversed ? 'Yes' : 'No',
      posting_date: row.posting_date ? moment(row.posting_date).format('MM/DD/YYYY') : null,
      'general_ledger.created_at': row['general_ledger.created_at'] ? moment(row['general_ledger.created_at']).format('MM/DD/YYYY') : null,
      'document_type.name': row['document_type.journal_entry_id'] === 19 ? 'Journal Entry' : row['document_type.name'],
    };
  });

  return normalizedData;
};
