import { Button } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Label, Row } from 'reactstrap';
import { CollapsableCard } from '../../../../common/Card/CollapsableCard';
import { CustomInput } from '../../../../common/CustomInput';
import { CustomSelect } from '../../../../common/CustomSelect';
import { DatePickerComponent } from '../../../../common/DatePicker/DatePicker';
import { CustomInputMask } from '../../../../common/Inputs/CustomInputMask';
import { CustomSwitch } from '../../../../common/Inputs/CustomSwitch';
import { PAYMENT_TERM_TABLE } from '../../../../constants/supportTables.constants';
import { useGetSupportTablesForSelect, useNationalityOptions, useStateOptions } from '../../../../hooks/commonApiHooks';
import { useUpdateClient } from '../clientHooks';

export const CustomerForm = ({ client, fetchClient }) => {
  const { clientType, clientId } = useParams();

  const [form, setForm] = useState({});

  const { data: stateOptionsData } = useStateOptions();
  const { data: nationalityOptions } = useNationalityOptions();

  const { data: { data: supportTableOptions = {} } = {} } = useGetSupportTablesForSelect({
    table_names: [PAYMENT_TERM_TABLE],
  });

  const { mutate: updateClient } = useUpdateClient({
    successCallback: () => {
      fetchClient();
    },
  });

  useEffect(() => {
    if (client) {
      setForm(client);
    }
  }, [client]);

  const onChange = (e) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onDateChange = (date, name) => {
    console.log(date, name);
    setForm((prev) => ({ ...prev, [name]: date }));
  };

  const onSelectChange = (val, e) => {
    if (e.action === 'select-option') {
      let o = { target: { name: e.name, value: val.value || val.id } };
      onChange(o);
      return;
    }
    if (e.action === 'clear') {
      let o = { target: { name: e.name, value: null } };
      onChange(o);
    }
  };

  const onSwitchChange = (e) => {
    const { name, checked } = e.target;
    setForm((prev) => ({ ...prev, [name]: checked }));
  };

  const onSave = () => {
    const { id, branch_name, balance, latest_modifier_name, parent, ...data } = form;

    updateClient({ clientId, clientType, data });
  };

  const formatDateForPreview = (date) => {
    if (!date) return '';
    return moment(date).utcOffset(0, false).format('MM/DD/YYYY h:mm a');
  };

  const labelStyle = useMemo(() => {
    return { display: 'flex', justifyContent: 'start', fontSize: '1.2rem' };
  }, []);

  if (!Object.keys(form).length) {
    return <>No data available</>;
  }

  return (
    <>
      <Row>
        {/* 1 */}
        <Col>
          <Col>
            <CustomInput disabled placeholder='' type='text' label='ID' name='id' value={form?.external_id || ''} onChange={() => {}} />
          </Col>
          <Col>
            <CustomInput placeholder='' type='text' label='RMI Number' name='rmi_number' value={form?.rmi_number || ''} onChange={onChange} />
          </Col>
          <Col>
            <CustomInput
              placeholder=''
              type='text'
              label='Customer Group Name'
              name='group_name'
              value={form?.group_name || ''}
              onChange={onChange}
            />
          </Col>
          <Col>
            <CustomInput
              placeholder=''
              type='text'
              label='Customer Group Number'
              name='group_number'
              value={form?.group_number || ''}
              onChange={onChange}
            />
          </Col>
          <Col>
            <CustomSelect
              label='Payment Terms'
              isClearable
              name='payment_terms'
              defaultValue={form['payment_terms']}
              options={supportTableOptions[PAYMENT_TERM_TABLE] || []}
              onChange={onSelectChange}
            />
          </Col>
        </Col>
        {/* 2 */}
        <Col>
          <Col>
            <CustomInput placeholder='' type='text' label='MC Number' name='mc_number' value={form?.mc_number || ''} onChange={onChange} />
          </Col>
          <Col>
            <CustomInput placeholder='' type='text' label='DOT Number' name='dot_number' value={form?.dot_number || ''} onChange={onChange} />
          </Col>
          <Col>
            <CustomInput placeholder='' type='text' label='FEIN' name='fein' value={form?.fein || ''} onChange={onChange} />
          </Col>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <CustomSwitch labelPosition='top' label='Taxable' name='taxable' checked={form?.taxable} onChange={onSwitchChange} />
          </Col>
          <Col>
            <CustomInput
              disabled
              type='text'
              name='last_modified_date'
              placeholder={'mm/dd/yyyy'}
              label='Last Modified Date'
              value={formatDateForPreview(form?.last_modified_date)}
              onChange={() => {}}
            />
          </Col>
        </Col>
        {/* 3 */}
        <Col>
          <Col>
            <CustomInput placeholder='' type='text' label='Salesperson' name='salesperson' value={form?.salesperson || ''} onChange={onChange} />
          </Col>
          <Col>
            <CustomInput placeholder='' disabled type='text' label='Branch' name='branch_name' value={form?.branch_name || ''} onChange={() => {}} />
          </Col>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <CustomSwitch labelPosition='top' label='CFS' name='cfs' checked={form?.cfs} onChange={onSwitchChange} />
          </Col>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <CustomSwitch labelPosition='top' label='ACH' name='ach' checked={form?.ach} onChange={onSwitchChange} />
          </Col>
          <Col>
            <CustomInput
              disabled
              placeholder=''
              type='text'
              name='last_modified_by'
              label='Last Modified By'
              value={form?.latest_modifier_name || ''}
              onChange={() => {}}
            />
          </Col>
        </Col>
        {/* 4 */}
        <Col>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <CustomSwitch labelPosition='top' label='Blocked' name='blocked' checked={form?.blocked} onChange={onSwitchChange} />
          </Col>
          <Col>
            <CustomInput
              placeholder=''
              type='textarea'
              rows={4}
              label='Blocked Notes'
              name='blocked_notes'
              value={form?.blocked_notes || ''}
              onChange={onChange}
            />
          </Col>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <CustomSwitch labelPosition='top' label='Legal' name='legal' checked={form?.legal} onChange={onSwitchChange} />
          </Col>
          <Col style={{ paddingTop: '2px' }}>
            <DatePickerComponent
              showYearDropdown
              isClearable
              name='legal_date'
              placeholder={'mm/dd/yyyy'}
              dateFormat={'MM/dd/yyyy'}
              label='Date Submitted to Legal'
              selected={form?.legal_date ?? null}
              onChange={(date) => onDateChange(date, 'legal_date')}
            />
          </Col>
        </Col>
      </Row>
      {/* Contact Persons */}
      <Row className='mt-3'>
        <Col style={{ padding: '4px' }}>
          <CollapsableCard
            isExpanded={false}
            headerTitle={
              <Row>
                <Col md={4} lg={4}>
                  <Label style={labelStyle}>Company Owner Information</Label>
                </Col>
                <Col md={4} lg={4}>
                  <Label style={labelStyle}>Primary Contact Information</Label>
                </Col>
                <Col md={4} lg={4}>
                  <Label style={labelStyle}>A/P Contact Information</Label>
                </Col>
              </Row>
            }
          >
            <Row>
              <Col md={4} lg={4}>
                {/* <Col>
                  <CustomInputMask
                    prefix={'$'}
                    thousandSeparator
                    label='Credit Limit'
                    name='credit_limit'
                    value={form?.credit_limit || ''}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <CustomInput type='text' label='DBA' name='dba' value={form?.dba || ''} onChange={onChange} />
                </Col> */}
                <Col>
                  <CustomInput
                    placeholder=''
                    type='text'
                    label='Company Owner Name'
                    name='owner_name'
                    value={form?.owner_name || ''}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <CustomSelect
                    label='Nationality'
                    isClearable
                    onChange={onSelectChange}
                    name='nationality_id'
                    defaultValue={form['nationality_id']}
                    options={nationalityOptions?.data || []}
                  />
                </Col>
                <Col>
                  <CustomInputMask
                    type='tel'
                    label='Company Owner Cell Phone'
                    name='mobile_phone'
                    value={form?.mobile_phone || ''}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <CustomInputMask type='tel' name='safer_phone' label='Safer Phone' value={form?.safer_phone} onChange={onChange} />
                </Col>
                <Col>
                  <CustomInput placeholder='' type='text' label='Email' name='email' value={form?.email || ''} onChange={onChange} />
                </Col>
              </Col>
              {/* 2nd col */}
              <Col md={4} lg={4}>
                <Col>
                  <CustomInput
                    placeholder=''
                    type='text'
                    label='Primary Contact Name'
                    name='primary_contact_name'
                    value={form?.primary_contact_name || ''}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <CustomInputMask
                    type='tel'
                    label='Primary Contact Cell Phone'
                    name='primary_contact_phone_number'
                    value={form?.primary_contact_phone_number || ''}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <CustomInputMask
                    type='tel'
                    label='Primary Contact Business Phone'
                    name='primary_contact_business_phone'
                    value={form?.primary_contact_business_phone || ''}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <CustomInputMask type='tel' label='Fax' name='primary_contact_fax' value={form?.primary_contact_fax || ''} onChange={onChange} />
                </Col>
                <Col>
                  <CustomInput
                    placeholder=''
                    type='text'
                    label='Email'
                    name='primary_contact_email'
                    value={form?.primary_contact_email || ''}
                    onChange={onChange}
                  />
                </Col>
              </Col>
              {/* 3rd col */}
              <Col md={4} lg={4}>
                <Col>
                  <CustomInput
                    placeholder=''
                    type='text'
                    label='A/P Contact Name'
                    name='ap_contact_person_name'
                    value={form?.ap_contact_person_name || ''}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <CustomInputMask
                    type='tel'
                    label='A/P Contact Cell Phone'
                    name='ap_contact_person_phone'
                    value={form?.ap_contact_person_phone || ''}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <CustomInputMask
                    type='tel'
                    label='A/P Contact Business Phone'
                    name='ap_contact_person_business_phone'
                    value={form?.ap_contact_person_business_phone || ''}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <CustomInputMask
                    type='tel'
                    label='Fax'
                    name='ap_contact_person_fax'
                    value={form?.ap_contact_person_fax || ''}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <CustomInput
                    placeholder=''
                    type='text'
                    label='Email'
                    name='ap_contact_person_email'
                    value={form?.ap_contact_person_email || ''}
                    onChange={onChange}
                  />
                </Col>
              </Col>
            </Row>
          </CollapsableCard>
        </Col>
      </Row>

      {/* Address */}
      <Row className='mt-3 mb-3'>
        <Col className='m-0' style={{ padding: '4px' }}>
          <CollapsableCard
            isExpanded={false}
            headerTitle={
              <Row>
                <Col>
                  <Label style={labelStyle}>Physical Address</Label>
                </Col>
                <Col>
                  <Label style={labelStyle}>Mailing Address</Label>
                </Col>
              </Row>
            }
          >
            <Row>
              {/* Physical */}
              <Col sm={12} lg={6} className='mb-1'>
                <Col>
                  <CustomInput
                    placeholder=''
                    type='text'
                    label='Street1'
                    name='address_street1'
                    value={form?.address_street1 || ''}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <CustomInput
                    placeholder=''
                    type='text'
                    label='Street2'
                    name='address_street2'
                    value={form?.address_street2 || ''}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <CustomInput placeholder='' type='text' label='City' name='address_city' value={form?.address_city || ''} onChange={onChange} />
                </Col>
                <Col>
                  <CustomSelect
                    label='State'
                    isClearable
                    onChange={onSelectChange}
                    name='address_state'
                    defaultValue={form['address_state']}
                    options={stateOptionsData?.data || []}
                  />
                </Col>
                <Col>
                  <CustomInput placeholder='' type='text' label='Zip' name='address_zip' value={form?.address_zip || ''} onChange={onChange} />
                </Col>
              </Col>
              {/* Mailing */}
              <Col sm={12} lg={6}>
                <Col>
                  <CustomInput
                    placeholder=''
                    type='text'
                    label='Street1'
                    name='address2_street1'
                    value={form?.address2_street1 || ''}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <CustomInput
                    placeholder=''
                    type='text'
                    label='Street2'
                    name='address2_street2'
                    value={form?.address2_street2 || ''}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <CustomInput placeholder='' type='text' label='City' name='address2_city' value={form?.address2_city || ''} onChange={onChange} />
                </Col>
                <Col>
                  <CustomSelect
                    label='State'
                    isClearable
                    onChange={onSelectChange}
                    name='address2_state'
                    defaultValue={form['address2_state']}
                    options={stateOptionsData?.data || []}
                  />
                </Col>
                <Col>
                  <CustomInput placeholder='' type='text' label='Zip' name='address2_zip' value={form?.address2_zip || ''} onChange={onChange} />
                </Col>
              </Col>
            </Row>
          </CollapsableCard>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex justify-content-end'>
          <Button onClick={onSave}>Save</Button>
        </Col>
      </Row>
    </>
  );
};
