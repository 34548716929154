import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';

export const ExportButtons = ({ loader, onFilterActionClick }) => {
  return (
    <div className='shadow-sm ' style={{ position: 'absolute', top: '-15px', right: '10px' }}>
      <ButtonGroup vertical={false}>
        <Button
          color={'info'}
          className='btn btn-sm  '
          onClick={(e) => {
            e.preventDefault();
            e.target.blur();
            onFilterActionClick('PDF');
          }}
          disabled={loader}
        >
          {loader ? <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' /> : <i className='fas fa-download'> </i>}
          PDF
        </Button>
        <Button
          color={'info'}
          className='btn btn-sm  '
          onClick={(e) => {
            e.preventDefault();
            e.target.blur();
            onFilterActionClick('EXCEL');
          }}
          disabled={loader}
        >
          {loader ? <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' /> : <i className='fas fa-download'> </i>}
          EXCEL
        </Button>
      </ButtonGroup>
    </div>
  );
};
