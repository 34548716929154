import { Link } from 'react-router-dom';
import moment from 'moment';
import { Checkbox, IconButton, Tooltip } from '@mui/material';
import { DeleteForeverOutlined } from '@mui/icons-material';
import { formatDateForTable } from '../../../../utils/formatDataForTable';
import { formatAmount } from '../../../../utils/formatAmout';

export const depositTableColumns = ({ onDeleteClick, disabled }) => {
  return [
    {
      accessor: 'document_number',
      Header: 'Document Number',
      Cell: (row) => {
        //
        return (
          <div style={{ cursor: 'pointer', color: 'blue' }} onClick={() => window.open(`/deposit/${row.original?.id}`, '_blank')}>
            {row.value}
          </div>
        );
      },
    },
    {
      accessor: 'amount',
      Header: 'Total Amount',
      Cell: (row) => formatAmount(row.value),
    },
    {
      accessor: 'account_number',
      Header: 'Bank Account',
      Cell: (row) => row.value,
    },
    {
      id: 'payment_source.name',
      accessor: (d) => d['payment_source.name'],
      Header: 'Payment Type',
      Cell: (row) => row.value,
    },
    {
      accessor: 'document_date',
      Header: 'Document Date',
      Cell: (row) => formatDateForTable(row.value),
    },
    {
      accessor: 'posting_date',
      Header: 'Posting Date',
      Cell: (row) => formatDateForTable(row.value),
    },
    {
      accessor: 'deposit_date',
      Header: 'Deposit Date',
      Cell: (row) => formatDateForTable(row.value),
    },
    {
      accessor: 'create_at',
      Header: 'Date Created',
      Cell: (row) => moment(row.value).format('MM/DD/YYYY HH:mm'),
    },
    {
      accessor: 'finish',
      Header: 'Posted',
      Cell: (row) => (
        <>
          <Checkbox checked={Boolean(row.value)} />
          {!Boolean(row.value) && (
            <Tooltip title='Delete' placement='top' arrow followCursor>
              <IconButton onClick={(e) => onDeleteClick(e, row)} color='error' disabled={disabled}>
                <DeleteForeverOutlined />
              </IconButton>
            </Tooltip>
          )}
        </>
      ),
    },
  ];
};

export const depositExcelHeaders = [
  { header: 'Document Number', key: 'document_number' },
  { header: 'Total Amount', key: 'amount', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
  { header: 'Bank Account', key: 'account_number', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
  { header: 'Payment Type', key: 'payment_source.name' },
  { header: 'Document Date', key: 'document_date' },
  { header: 'Posting Date', key: 'posting_date' },
  { header: 'Deposit Date', key: 'deposit_date' },
  { header: 'Date Created', key: 'create_at' },
  { header: 'Posted', key: 'finish' },
];
