import { AUTHENTICATION_SET_USER } from './AuthorizationAction';

const initialState = {
  isAuthenticated: false,
  user: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  //  console.log(action);
  switch (action.type) {
    case AUTHENTICATION_SET_USER:
      return {
        ...state,
        isAuthenticated: !!action.payload,
        user: action.payload || {},
      };
    case 'ERROR':
      return { ...state };
    default:
      return state;
  }
}
