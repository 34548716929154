import { Chip, IconButton, Tooltip } from '@mui/material';
import { ModeEditOutline, DeleteOutline } from '@mui/icons-material';
import { formatDateForTable } from '../../../../utils/formatDataForTable';
import { CustomCheckbox } from '../../../../common/Inputs/CustomCheckbox';

export const DisablePostingTableColumns = ({ disabled, onDeleteItem, toggleModal }) => {
  return [
    {
      accessor: 'emails',
      id: 'emails',
      Header: 'Emails',
      Cell: (row) => {
        if (row.value) {
          return row.value.map((item) => {
            return (
              <Tooltip key={item.email} title={item.name} placement='top' arrow followCursor>
                <Chip label={item.email} className={item.name} size='small' color='primary' sx={{ m: 0.5 }} />
              </Tooltip>
            );
          });
        }
        return null;
      },
      sort: false,
    },
    {
      accessor: 'date_from',
      id: 'date_from',
      Header: 'Date From',
      Cell: (row) => formatDateForTable(row.value),
      sort: true,
    },
    {
      accessor: 'date_to',
      id: 'date_to',
      Header: 'Date To',
      Cell: (row) => formatDateForTable(row.value),
      sort: true,
    },
    {
      accessor: 'repeatable',
      id: 'repeatable',
      Header: 'Repeatable',
      Cell: (row) => <CustomCheckbox disabled checked={row.value} color='primary' />,
      sort: true,
    },
    {
      filterable: false,
      sortable: false,
      accessor: (row) => {
        return (
          <>
            <IconButton color='primary' onClick={() => toggleModal({ action: 'update', id: row.id, data: row })} disabled={disabled}>
              <ModeEditOutline />
            </IconButton>
            <IconButton color='error' onClick={(e) => onDeleteItem(e, row)} disabled={disabled}>
              <DeleteOutline />
            </IconButton>
          </>
        );
      },
      id: 'btnAction',
      Header: 'Actions',
      minWidth: 150,
      maxWidth: 150,
    },
  ];
};
