import React, { useState } from 'react';
import { CustomCard } from '../../../common/Card/CustomCard';
import { Button, Col, Row } from 'reactstrap';
import DataGridComponent from '../../../component/DataTable/component/DataTableComponent';
import { AddJournalEntryModal } from '../JournalEntry/component/AddJournalEntry';
import { CustomModal, useCustomModalToggle } from '../../../common/Dialog/CustomModal';
import { journalEntryDraftsColumns } from './journalEntryDraftsColumns';
import { useDeleteDraft, useDuplicateDraft, useGetJournalEntriesDrafts, useUpdateDraft } from '../JournalEntry/journalEntryApiHooks';
import { Loader } from '../../../common/Loader';
import { Box } from '@mui/system';
import { TextField } from '@mui/material';

export const JournalEntryDrafts = () => {
  const [draftId, setDraftId] = useState();
  const [baseDraftId, setBaseDraftId] = useState();
  const [filters, setFilters] = React.useState({
    sort_by: 'id',
    order_by: 'DESC',
  });

  const { toggleCustomModal: toggleCreateModal, open: isCreateModalOpen } = useCustomModalToggle();

  const { data, isLoading: isLoadingGetDrafts } = useGetJournalEntriesDrafts(filters);
  const { mutate: deleteDraft, isLoading: isLoadingDelete } = useDeleteDraft();
  const { mutate: duplicateDraft, isLoading: isLoadingDuplicate } = useDuplicateDraft();
  const { mutate: updateDraft, isLoading: isLoadingUpdate } = useUpdateDraft();

  const onDuplicateClick = (id) => {
    duplicateDraft(id);
  };

  const onDeleteClick = (id) => {
    deleteDraft(id);
  };

  const onFilterChange = (e) => {
    setFilters({ ...filters, ...e });
  };

  const columns = React.useMemo(() =>
    journalEntryDraftsColumns({ toggleCreateModal, setDraftId, onDeleteClick, onDuplicateClick, setBaseDraftId }, [])
  );

  const handleUpdateDraft = (data) => {
    updateDraft(data);
  };

  return (
    <>
      {(isLoadingGetDrafts || isLoadingDelete || isLoadingDuplicate || isLoadingUpdate) && <Loader isFullScreen />}
      <CustomCard headerTitle='Journal Entries Drafts' sxCardContainer={{ mt: 2 }}>
        <Row>
          <Col>
            <DataGridComponent columns={columns} dataSource={data} onFilterChange={onFilterChange} />
          </Col>
        </Row>
      </CustomCard>

      <AddJournalEntryModal
        deleteDraft={deleteDraft}
        draftId={draftId}
        baseDraftId={baseDraftId}
        tableFilters={[]}
        toggleModal={toggleCreateModal}
        isModalOpen={isCreateModalOpen}
        queueData={data}
        saveDraftChanges
        handleUpdateDraft={handleUpdateDraft}
        modalTitle={`${data?.data[draftId]?.name}`}
      />
    </>
  );
};
