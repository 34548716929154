import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import DataGridComponent from '../../../component/DataTable/component/DataTableComponent';
import { CustomCard } from '../../../common/Card/CustomCard';
import { FixedAssetsTableColumns } from './tableColumns/FixedAssetsTableColumns';
import { FixedAssetsTableFilters } from './components/FixedAssetsTableFilters';
import { useGetFixedAssetsTableData, useExportExcel } from './FixedAsettesApiRoutes';

const FixedAssetsTable = (props) => {
  const history = useHistory();
  const [filters, setFilters] = React.useState({
    page: 0,
    pageSize: 10,
  });

  const { data: { data: fixedAssetsTableData } = {}, refetch } = useGetFixedAssetsTableData(filters);
  const { mutate: exportExcelMutation } = useExportExcel();

  const onFilterChange = (e) => {
    setFilters({ ...filters, ...e });
  };

  const columns = React.useMemo(() => FixedAssetsTableColumns({ onEditClick: () => {} }), []);
  const cleanDataSource = React.useMemo(() => ({ data: [], page: 0, pageSize: 10, pageTotal: 0 }));

  const exportToExcel = () => {
    exportExcelMutation(filters);
  };

  return (
    <CustomCard headerTitle='Fixed Assets' sxCardContainer={{ mt: 2 }}>
      <Row className='mb-3'>
        <Col>
          <FixedAssetsTableFilters setFilters={setFilters} filters={filters} refetch={refetch} exportToExcel={exportToExcel} />
        </Col>
      </Row>
      <Row>
        <Col>
          <DataGridComponent columns={columns} loading={false} dataSource={fixedAssetsTableData || cleanDataSource} onFilterChange={onFilterChange} />
        </Col>
      </Row>
    </CustomCard>
  );
};

export default FixedAssetsTable;
