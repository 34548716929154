import Joi from 'joi';
import React from 'react';
import { CustomModal } from '../../../../common/Dialog/CustomModal';
import { CustomSelect } from '../../../../common/CustomSelect';
import { Button, Divider, Paper, Typography, IconButton, Tooltip } from '@mui/material';
import Dropzone from 'react-dropzone';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { CustomInput } from '../../../../common/CustomInput';
import CloseIcon from '@mui/icons-material/Close';
import { notificationDangerCustomTime } from '../../../../utils/toastify';
import { Loader } from '../../../../common/Loader';
import { useEditDocumentInfo, useUploadFileToS3 } from '../FixedAsettesApiRoutes';

const useCustomUploadModalToggle = () => {
  const [open, setOpen] = React.useState(false);

  const toggleCustomUploadModal = () => {
    setOpen(!open);
  };

  return { open, toggleCustomUploadModal };
};

const UploadDocumentModal = ({
  isOpen,
  toggleCustomUploadModal,
  unitNumber = 0,
  selectOptions,
  isLoadingDeleteDocument = false,
  modalTitle = 'Upload Single Document',
  editInfoData,
  setEditInfoData,
}) => {
  const [uploadData, setUploadData] = React.useState({
    documentName: '',
    documentType: '',
    selectedFile: [],
  });
  const [errors, setErrors] = React.useState({
    noDocument: false,
  });

  const onSuccess = () => {
    setErrors({});
    setUploadData({ documentName: '', documentType: '', selectedFile: [] });
  };

  const handleCloseModal = () => {
    toggleCustomUploadModal();
    setEditInfoData(false);
    setUploadData({
      documentName: '',
      documentType: '',
      selectedFile: [],
    });
  };

  const { mutate: uploadDocumentMutation, isLoading: isLoadingUpload } = useUploadFileToS3(onSuccess);
  const { mutate: updateDocumentInfo, isLoading: isLoadingUpdateInfo } = useEditDocumentInfo(handleCloseModal);

  const handleInputData = (e) => {
    const { name, value } = e.target;

    if (editInfoData) {
      setEditInfoData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setUploadData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      documentName: '',
    }));
  };

  const handleSelectDocumentType = (value) => {
    if (editInfoData) {
      setEditInfoData((prev) => ({ ...prev, documentType: value.value }));
    } else {
      setUploadData((prev) => ({ ...prev, documentType: value.value }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      documentType: '',
    }));
  };

  const uploadDocument = () => {
    const data = editInfoData || uploadData;

    if (!data.documentName || !data.documentType) {
      const errorsValidation = validateDataBeforePosting(data);
      if (errorsValidation) {
        setErrors(errorsValidation);
        return;
      }
      return;
    }

    if (editInfoData) {
      updateDocumentInfo({ ...editInfoData, unitNumber });
    } else {
      if (!data.selectedFile.length) {
        setErrors((prev) => ({ ...prev, noDocument: true }));
        return notificationDangerCustomTime('Please attach a document before uploading!', 5000);
      }

      const formData = new FormData();
      formData.append('documentName', data.documentName);
      formData.append('documentType', data.documentType);
      formData.append('unitNumber', unitNumber);
      formData.append('document', data.selectedFile[0]);

      uploadDocumentMutation(formData);
    }
  };

  const validateDataBeforePosting = (data) => {
    const postingDataValidator = Joi.object()
      .options({ abortEarly: false })
      .keys({
        documentName: Joi.string().required().messages({
          'string.empty': '*Document Name Is Required',
        }),
        documentType: Joi.string().required().messages({
          'string.empty': '*Document Type Is Required',
        }),
      })
      .unknown(true);

    const validationResult = postingDataValidator.validate(data);

    if (validationResult.error) {
      const errors = {};
      validationResult.error.details.forEach((error) => {
        errors[error.context.label] = error.message;
      });
      return errors;
    }

    return null;
  };

  const handleDeleteFile = () => {
    setUploadData((prev) => ({ ...prev, selectedFile: [] }));
  };

  React.useEffect(() => {
    if (!isOpen) {
      setUploadData({
        documentName: '',
        documentType: '',
        selectedFile: [],
      });
      setEditInfoData(false);
    }
  }, [isOpen]);

  return (
    <div>
      {(isLoadingUpload || isLoadingDeleteDocument || isLoadingUpdateInfo) && <Loader isFullScreen />}

      <CustomModal
        modalTitle={editInfoData ? 'Edit Document Info' : modalTitle}
        modalSize='sm'
        toggleCustomModal={toggleCustomUploadModal}
        isOpen={isOpen}
        actionButtons={
          <div className='d-flex justify-content-between w-100'>
            <Button color='error' onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button disabled={isLoadingUpload} color='primary' onClick={uploadDocument}>
              {editInfoData ? 'Save Changes' : 'Upload'}
            </Button>
          </div>
        }
      >
        <CustomInput
          label='Document Name'
          onChange={handleInputData}
          name='documentName'
          fullWidth
          variant='outlined'
          type='text'
          placeholder='Enter Document Name'
          value={editInfoData ? editInfoData.documentName : uploadData.documentName}
          errorMess={errors?.documentName}
        />
        <CustomSelect
          defaultValue={editInfoData ? editInfoData.documentType : uploadData.documentType}
          options={selectOptions}
          onChange={handleSelectDocumentType}
          label='Document Type'
          name='documentType'
          errorMess={errors?.documentType}
        />
        {!editInfoData && (
          <>
            <Divider sx={{ mt: 2, mb: 2 }} />
            {!uploadData.selectedFile.length && (
              <Dropzone
                onDrop={(acceptedFile) => {
                  const allowedExtensions = ['pdf', 'jpeg', 'jpg', 'png', 'csv', 'xls', 'xlsx'];
                  const fileExtension = acceptedFile[0].name.split('.').pop();

                  if (allowedExtensions.includes(fileExtension)) {
                    setUploadData((prev) => ({
                      ...prev,
                      selectedFile: acceptedFile,
                    }));
                  } else {
                    return notificationDangerCustomTime(`Format '${fileExtension}' is not alowed!`, 5000);
                  }
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '6em',
                        backgroundColor: 'transparent',
                        border: '1px dashed #000',
                        color: 'gray',
                        width: '100%',
                      }}
                      color='inherit'
                    >
                      <Typography color={errors?.noDocument ? 'error' : 'inherent'}>Drag and drop file here, or click to select file</Typography>
                      <DriveFolderUploadIcon color={errors?.noDocument ? 'error' : 'inherent'} />
                    </Button>
                  </div>
                )}
              </Dropzone>
            )}
            {uploadData.selectedFile.length > 0 && (
              <Paper
                style={{
                  position: 'relative',
                  padding: '20px',
                  backgroundColor: '#ad9d53',
                  height: '80px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant='h6' style={{ color: '#333' }}>
                  {uploadData.selectedFile[0].name}
                </Typography>
                <Tooltip title='Delete Item' arrow followCursor>
                  <IconButton style={{ position: 'absolute', top: '5px', right: '5px', color: '#333' }} onClick={() => handleDeleteFile()}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Paper>
            )}{' '}
          </>
        )}
      </CustomModal>
    </div>
  );
};

export { UploadDocumentModal, useCustomUploadModalToggle };
