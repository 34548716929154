import * as React from 'react';
import PropTypes from 'prop-types';
import { styled, Palette } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, DialogActions, DialogContent, DialogTitle, Dialog, Button } from '@mui/material';

const BootstrapDialog = styled((props) => {
  return <Dialog {...props} />;
})(({ theme, addStyles }) => {
  const styles = {
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
      overflowY: 'visible',
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  };
  if (addStyles.PaperStyle) styles['& .MuiPaper-root'] = addStyles.PaperStyle;
  if (addStyles.ContainerStyle) styles['& .MuiDialog-container'] = addStyles.ContainerStyle;
  return styles;
});

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   '& .MuiDialogContent-root': {
//     padding: theme.spacing(2),
//     overflowY: 'visible',
//   },
//   '& .MuiDialogActions-root': {
//     padding: theme.spacing(1),
//   },
// }));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.error,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const useCustomModalToggle = () => {
  const [open, setOpen] = React.useState(false);

  const toggleCustomModal = () => {
    setOpen(!open);
  };

  return { open, toggleCustomModal };
};

const CustomModal = ({
  modalSize,
  saveButtonTitle,
  modalTitle,
  onSave,
  children,
  toggleCustomModal,
  isOpen,
  buttonSaveColor,
  addStyles,
  actionButtons,
}) => {
  return (
    <BootstrapDialog
      aria-labelledby='customized-dialog-title'
      open={isOpen}
      maxWidth={modalSize}
      fullWidth={modalSize}
      addStyles={addStyles}
      className='styled-custom-dialog'
    >
      <BootstrapDialogTitle id='customized-dialog-title' onClose={toggleCustomModal}>
        {modalTitle}
      </BootstrapDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        {actionButtons ? (
          actionButtons
        ) : (
          <Button autoFocus onClick={onSave} color={buttonSaveColor} size='small'>
            {saveButtonTitle}
          </Button>
        )}
      </DialogActions>
    </BootstrapDialog>
  );
};

CustomModal.propTypes = {
  modalSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  saveButtonTitle: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  toggleCustomModal: PropTypes.func,
  isOpen: PropTypes.bool,
  buttonSaveColor: PropTypes.string,
};

CustomModal.defaultProps = {
  modalSize: 'sm',
  onSave: () => {},
  toggleCustomModal: () => {},
  isOpen: false,
  buttonSaveColor: 'success',
  saveButtonTitle: 'Save',
  addStyles: {},
};

export { CustomModal, useCustomModalToggle };
