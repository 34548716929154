import Joi from 'joi';

export const validateBankRecoData = (data) => {
  const bankRecoModel = Joi.object()
    .options({ abortEarly: false })
    .keys({
      account: Joi.number().required().messages({
        'number.base': 'Account is required',
      }),
      statment_date: Joi.alternatives(Joi.date(), Joi.string()).required().messages({
        'date.base': 'Statement Date is required',
        'string.base': 'Statement Date is required',
      }),
      ending_balance: Joi.custom((value, helper) => {
        const state = helper.state.ancestors[0];
        if (!state.ending_balance) {
          return helper.message('Ending Balance is required');
        }
        return value;
      }),
    })
    .unknown(true);

  const validationResult = bankRecoModel.validate(data);

  if (validationResult.error) {
    const errors = {};
    validationResult.error.details.forEach((error) => {
      errors[error.context.label] = error.message;
    });
    return errors;
  }

  return null;
};
