import { Button } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { CustomInput } from '../../../../../common/CustomInput';
import { CustomSelect } from '../../../../../common/CustomSelect';
import { DatePickerComponent } from '../../../../../common/DatePicker/DatePicker';
import { CustomCurrencyInput } from '../../../../../common/Inputs/CurrencyInput';
import { CREDIT_CARD as BANK_CREDIT_CARD } from '../../../../../constants/bankPaymentSource.constants';
import { CREDIT_CARD } from '../../../../../constants/paymentTypeSource.constants';
import { BANK_PAYMENT_SOURCE, PAYMENT_SOURCE } from '../../../../../constants/supportTables.constants';
import { useGetBankAccount, useGetSupportTablesForSelect } from '../../../../../hooks/commonApiHooks';
import { useBillingPayment, useCreateBillingPayment, useUpdateBillingPayment } from '../hooks';
import { extractValidationErrorMessage } from '../utils';

export const BillingPaymentForm = ({ id = null, isEdit }) => {
  const history = useHistory();
  const { clientType } = useParams();

  const blankForm = useMemo(() => {
    return {
      bank_account_number: null,
      payment_source: null,
      bank_payment_source: null,
      posting_date: moment().format('MM/DD/YYYY'),
      total_amount: 0,
      total_billing_items_amount: 0,
      difference: 0,
    };
  }, []);

  const [form, setForm] = useState(blankForm);

  const bankAccountDisabled = useMemo(() => {
    return form.payment_source === CREDIT_CARD && form.bank_payment_source === BANK_CREDIT_CARD;
  }, [form.payment_source, form.bank_payment_source]);

  const createBillingPaymentSuccessCallback = (billingPaymentId) => {
    setForm(blankForm);
    if (billingPaymentId) {
      history.push(`/client/${clientType}/payment/${billingPaymentId}`);
    }
  };

  const { data: { data: supportTableOptions = {} } = {} } = useGetSupportTablesForSelect({
    table_names: [PAYMENT_SOURCE, BANK_PAYMENT_SOURCE],
  });
  const { data: billingPaymentData } = useBillingPayment(clientType, id);
  const { data: bankAccountOptions } = useGetBankAccount();
  const { mutate: createBillingPayment, error: errorCreate } = useCreateBillingPayment(clientType, {
    successCallback: createBillingPaymentSuccessCallback,
  });
  const { mutate: updateBillingPayment, error: errorUpdate } = useUpdateBillingPayment(id, clientType, {});

  useEffect(() => {
    if (billingPaymentData?.data) {
      const data = billingPaymentData.data;
      const formData = {
        total_amount: data.amount,
        bank_account_number: data.bank_account_number,
        payment_source: data.payment_source,
        bank_payment_source: data.bank_payment_source,
        document_number: data.document_number,
        posting_date: data.posting_date ? moment(data.posting_date).format('YYYY-MM-DD') : null,
        total_billing_items_amount: +data.billing_items_amount,
        difference: +(+data.amount - +data.billing_items_amount).toFixed(2),
      };

      setForm(formData);
    }
  }, [billingPaymentData]);

  useEffect(() => {
    if (form.payment_source === CREDIT_CARD) {
      setForm((prevValue) => ({ ...prevValue, bank_payment_source: BANK_CREDIT_CARD, bank_account_number: null }));
    }
  }, [form.payment_source]);

  const handleChange = (e) => {
    try {
      if (e.target.value.constructor === {}.constructor) {
        setForm((prev) => ({ ...prev, ...e.target.value }));
      } else {
        setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      }
    } catch (error) {
      setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const handleSelectChange = (val, e) => {
    if (e.action === 'select-option') {
      let o = { target: { name: e.name, value: val.value } };

      if (e.name === 'bal_account_number') {
        setBalAccountLabelValue(`${val.value} - ${val?.client_name}`);
      }
      handleChange(o);
      return;
    }
    if (e.action === 'clear') {
      let o = { target: { name: e.name, value: null } };
      handleChange(o);
    }
  };

  const handleDateChange = (date, name) => {
    setForm((prev) => ({
      ...prev,
      [name]: date,
    }));
  };

  const handleClientPaymentCreateOrUpdate = () => {
    const data = {
      ...form,
      posting_date: form.posting_date ? moment(form.posting_date).format('YYYY-MM-DD') : null,
      document_date: form.posting_date ? moment(form.posting_date).format('YYYY-MM-DD') : null,
      billing_date: form.posting_date ? moment(form.posting_date).format('YYYY-MM-DD') : null,
    };

    if (isEdit) {
      updateBillingPayment({ ...data });
    } else {
      createBillingPayment({ ...data });
    }
  };

  return (
    <>
      <Row>
        <Col>
          <CustomInput
            type='number'
            label='Payment Number'
            disabled
            onChange={handleChange}
            name='document_number'
            value={form['document_number'] || null}
            errorMess={extractValidationErrorMessage([errorCreate, errorUpdate], 'document_number')}
          />
          <CustomSelect
            isClearable
            disabled={bankAccountDisabled}
            options={bankAccountOptions?.data || []}
            defaultValue={form.bank_account_number}
            onChange={handleSelectChange}
            label='Bank Account'
            name={'bank_account_number'}
            errorMess={extractValidationErrorMessage([errorCreate, errorUpdate], 'bank_account_number')}
          />
          <CustomSelect
            isClearable
            options={supportTableOptions[PAYMENT_SOURCE] ? supportTableOptions[PAYMENT_SOURCE].filter((item) => !item.label.includes('Bounced')) : []}
            defaultValue={form.payment_source}
            onChange={handleSelectChange}
            label='Payment Method'
            name={'payment_source'}
            errorMess={extractValidationErrorMessage([errorCreate, errorUpdate], 'payment_source')}
          />
          <CustomSelect
            isClearable
            options={supportTableOptions[BANK_PAYMENT_SOURCE] || []}
            defaultValue={form.bank_payment_source}
            onChange={handleSelectChange}
            label='Bank Payment Method'
            name={'bank_payment_source'}
            errorMess={extractValidationErrorMessage([errorCreate, errorUpdate], 'bank_payment_source')}
          />
        </Col>
        <Col>
          <CustomCurrencyInput
            name='total_amount'
            value={form.total_amount || 0}
            onChange={(value) => {
              const e = { target: { name: 'total_amount', value } };
              handleChange(e);
            }}
            label='Total Payment Amount'
            prefix={'$ '}
            placeholder=''
            errorMess={extractValidationErrorMessage([errorCreate, errorUpdate], 'total_amount')}
          />
          <CustomCurrencyInput
            name='total_billing_items_amount'
            value={form.total_billing_items_amount}
            onChange={() => {}}
            prefix={'$ '}
            label='Total Payment Lines'
            placeholder='Total Payment Lines'
            disabled
          />
          <CustomCurrencyInput
            name='difference'
            value={form.difference}
            onChange={() => {}}
            label='Difference'
            prefix={'$ '}
            placeholder='Difference'
            disabled
          />
          <CustomCurrencyInput
            name='total_billing_items_amount'
            value={form.total_billing_items_amount}
            onChange={() => {}}
            prefix={'$ '}
            label='Total Applied Amount'
            placeholder='Total Cleared Amount'
            disabled
          />
          <CustomCurrencyInput
            name='total_uncleared_amount'
            value={+(form.total_amount - form.total_billing_items_amount).toFixed(2)}
            onChange={() => {}}
            prefix={'$ '}
            label='Total Unapplied Amount'
            placeholder='Total Uncleared Amount'
            disabled
          />
        </Col>
        <Col>
          <DatePickerComponent
            showYearDropdown
            name='posting_date'
            selected={form?.posting_date}
            onChange={(date) => handleDateChange(date, 'posting_date')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Posting Date'
            errorMess={extractValidationErrorMessage([errorCreate, errorUpdate], 'posting_date')}
          />

          <DatePickerComponent
            showYearDropdown
            disabled
            name='document_date'
            selected={form?.posting_date}
            onChange={() => {}}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Document Date'
          />
          <DatePickerComponent
            showYearDropdown
            name='billing_date'
            disabled
            selected={form?.posting_date}
            onChange={() => {}}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Payment Date'
          />
        </Col>
      </Row>
      <Row className='mt-3 mb-3'>
        <Col className='d-flex justify-content-end'>
          <Button onClick={handleClientPaymentCreateOrUpdate} color='success' disabled={billingPaymentData?.data?.finished}>
            Save
          </Button>
        </Col>
      </Row>
    </>
  );
};
