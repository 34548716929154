import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { CircularProgress, Box } from '@mui/material';

const StyledBackgroundFullScreen = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 10000,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0,0,0,0.3)',
}));

export const Loader = ({ isFullScreen }) => {
  return isFullScreen ? (
    <StyledBackgroundFullScreen>
      <CircularProgress color='primary' />
    </StyledBackgroundFullScreen>
  ) : (
    <CircularProgress color='primary' />
  );
};

Loader.propTypes = {
  isFullScreen: PropTypes.bool,
};
