export const TRUCK_RENTAL = 2;
export const TRAILER_LEASE = 5;

const TenantMapper = new Map();
TenantMapper.set(2, 'Compass Truck Rental');
TenantMapper.set(5, 'Compass Trailer Rental');

export const getTenantName = (id) => {
  return TenantMapper.get(id);
};
