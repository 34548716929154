// { document_type_domain_id: 1, name: "Customer" },
// { document_type_domain_id: 2, name: "Vendor" },
// { document_type_domain_id: 3, name: "Other Income" },

export const ACC_TYPE_CUSTOMER = 1;
export const ACC_TYPE_VENDOR = 2;
export const ACC_TYPE_GL_ACCOUNT = 3;

export const accountTypeOptions = [
  { label: 'Customer', value: ACC_TYPE_CUSTOMER },
  { label: 'Vendor', value: ACC_TYPE_VENDOR },
  { label: 'G/L Account', value: ACC_TYPE_GL_ACCOUNT },
];

export const vendorPaymentAccountTypeOptions = [
  { label: 'Customer', value: ACC_TYPE_CUSTOMER },
  { label: 'Vendor', value: ACC_TYPE_VENDOR },
  // { label: 'G/L Account', value: ACC_TYPE_GL_ACCOUNT },
];

export const vendorBalancePaymentAccountTypeOptions = [
  { label: 'Customer', value: ACC_TYPE_CUSTOMER },
  // { label: 'Vendor', value: ACC_TYPE_VENDOR },
  { label: 'G/L Account', value: ACC_TYPE_GL_ACCOUNT },
];

const accountTypeMapper = new Map();
accountTypeMapper.set(1, 'Customer');
accountTypeMapper.set(2, 'Vendor');
accountTypeMapper.set(3, 'GL Account');

export const getAccountType = (id) => {
  return accountTypeMapper.get(id);
};
