import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import DataGridComponent from '../../../../component/DataTable/component/DataTableComponent';
import { CollapsableCard } from '../../../../common/Card/CollapsableCard';
import { TransactionsTableColumns } from '../tableColumns/FixedAssetsTransactionsTableColumns';
import { ExpendGeneralLedgerDataTableComponent } from '../../../../component/DataTable/ExpendComponent/ExpendGeneralLedgerDataTableComponent';
import { Button } from '@mui/material';

export const TransactionsFixedAssets = ({ tableData }) => {
  const history = useHistory();
  const [filters, setFilters] = React.useState({
    page: 0,
    pageSize: 100,
  });

  const onFilterChange = (e) => {
    setFilters({ ...filters, ...e });
  };

  const columns = React.useMemo(() => TransactionsTableColumns(), []);
  const cleanDataSource = React.useMemo(() => ({ data: tableData, page: 0, pageSize: 100, pageTotal: 0 }));

  function SubComponentGl({
    row: {
      _original: { gls },
    },
  }) {
    return <ExpendGeneralLedgerDataTableComponent gls={gls} />;
  }

  return (
    // <CollapsableCard
    //   headerTitle={'Transactions'}
    //   titleTypographyProps={{ fontSize: '16px' }}
    //   sxCardHeader={{ padding: '12px' }}
    //   addStyles={{ marginTop: '20px' }}
    // >
    <Row>
      <Col>
        <DataGridComponent
          columns={columns}
          loading={false}
          dataSource={cleanDataSource}
          onFilterChange={onFilterChange}
          SubComponent={SubComponentGl}
        />
      </Col>
    </Row>
    // </CollapsableCard>
  );
};
