import { Workbook } from "exceljs";
import csv2Json from "../../../../utils/csv2Json";

export const onExcelRead = (e) => {
  if (!e.target.result) return false;
  const wb = new Workbook();
  wb.xlsx.load(e.target.result).then((workbook) => {
    const number = workbook.worksheets.length;
    workbook.eachSheet((sheet, id) => {
      console.log(sheet.id + " - " + sheet.name);
    });
    if (number === 1) {
      const sheet = workbook.getWorksheet(1);
      const column = sheet.columnCount;
      const row = sheet.rowCount;
      console.log(column + " x " + row);
    } else {
      const sheet = workbook.getWorksheet(1);
      const column = sheet.columnCount;
      const row = sheet.rowCount;
      let startRow = null;
      let endRow = null;
      for (let i = 1; i < row; i++) {
        if (startRow) {
          if (sheet.getRow(i).cellCount === column) {
            endRow = i;
          } else {
            break;
          }
        } else {
          if (sheet.getRow(i).cellCount === column) {
            startRow = i;
          }
        }
      }
      console.log(column + " x " + row);
      console.log("zaglavlje:" + startRow + " data:" + (startRow + 1) + " kraj: " + endRow);
      //provjera zadnjeg reda
      const lastRow = sheet.getRow(endRow);
      let error = 0;
      for (let i = 1; i <= column; i++) {
        if (!lastRow.getCell(i).value) {
          error++;
        }
      }
      if (error > 1) {
        endRow--;
      }
      console.log(sheet.getRow(endRow).values);
      console.log("zaglavlje:" + startRow + " data:" + (startRow + 1) + " kraj: " + endRow);
      let header = ([] = sheet.getRow(startRow).values);
      let json = [];
      for (let i = startRow + 1; i <= endRow; i++) {
        let obj = {};
        let rowObj = sheet.getRow(i).values;
        for (let a = 1; a <= column; a++) {
          obj = { ...obj, [header[a]]: [rowObj[a]] };
        }
        json.push(obj);
      }
      console.log(json);
    }
  });
};

export const onCsvFileRead = (e) => {
  if (e.target.result) {
    let json = csv2Json(e.target.result);
    let jsonHeader = Object.keys(json[0]);
    let tableHeaderColumn = [];
    for (const i of jsonHeader) {
      let obj = {
        id: i,
        accessor: (d) => d[`${i}`],
      };
      tableHeaderColumn.push(obj);
    }
  }
};
