import { Button, Divider } from '@mui/material';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { CollapsableCard } from '../../../../../../common/Card/CollapsableCard';
import { DatePickerComponent } from '../../../../../../common/DatePicker/DatePicker';
import { formatAmount } from '../../../../../../utils/formatAmout';
import { useBillingPaymentCheck, useBillingPaymentCheckVoiding } from '../../hooks';
import { extractValidationErrorMessage } from '../../utils';

export const VoidCheckForm = ({ clientType, checkId, closeModal = () => {} }) => {
  const blankForm = useMemo(() => {
    return {
      void_date: moment().format('MM/DD/YYYY'),
      voided: true,
    };
  }, []);

  const [form, setForm] = useState(blankForm);

  const { data: checkData } = useBillingPaymentCheck(clientType, checkId);
  const {
    mutate: voidCheck,
    error: errorVoiding,
    isLoading,
  } = useBillingPaymentCheckVoiding({
    clientType,
    callbacks: {
      successCallback: closeModal,
    },
  });

  const handleDateChange = (date, name) => {
    setForm((prev) => ({
      ...prev,
      [name]: date,
    }));
  };

  const handleVoidCheck = () => {
    const data = {
      ...form,
      void_date: form.void_date ? moment(form.void_date).format('YYYY-MM-DD') : null,
    };
    voidCheck({ checkId, data });
  };

  return (
    <>
      <Row className='mb-3'>
        <Col md={12}>
          <DatePickerComponent
            showYearDropdown
            name='void_date'
            selected={form?.void_date}
            onChange={(date) => handleDateChange(date, 'void_date')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Void Date'
            errorMess={extractValidationErrorMessage([errorVoiding], 'void_date')}
          />
        </Col>
      </Row>
      {checkData?.data && (
        <CollapsableCard headerTitle='Details' isExpanded>
          <Row>
            <Col>Balance Account:</Col>
            <Col>
              {checkData.data.balance_account.number} - {checkData.data.balance_account.name}
            </Col>
          </Row>
          <Row>
            <Col>Check No:</Col>
            <Col>{checkData.data['billing_payment_checks.check_number']}</Col>
          </Row>
          <Row>
            <Col>Vendor No:</Col>
            <Col>
              {checkData.data.account.number} - {checkData.data.account.name}
            </Col>
          </Row>
          <Row>
            <Col>Amount:</Col>
            <Col>{formatAmount(checkData.data['billing_payment_checks.amount'])}</Col>
          </Row>
        </CollapsableCard>
      )}
      <Row className='mb-4 mt-4'>
        <Col>
          <Divider />
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col className='d-flex justify-content-end'>
          <Button onClick={() => handleVoidCheck(form)} disabled={isLoading}>
            Void
          </Button>
        </Col>
      </Row>
    </>
  );
};
