import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import { Navigator } from './Navigator';
import { Header } from './Header';
import { routes } from './routes';
import { PrivateRouter } from '../config/router/PrivateRouter';
import {
  systemAccountLoad,
  systemBranchLoad,
  systemInvoiceTypeLoad,
  systemPaymentTermLoad,
  systemPaymentTypeLoad,
  systemTaxCodeLoad,
  systemTaxItemLoad,
} from '../app/redax/Sys/SysAction';
import { RestrictedRoute } from '../config/router/RestrictedRoute';

const drawerWidth = 250;

const CustomizedPaper = styled((props) => <Paper {...props} />)(({ theme, isSidebarOpen }) => ({
  flex: 1,
  padding: theme.spacing(3, 3),
  alignItems: 'stretch',
  marginLeft: isSidebarOpen ? drawerWidth : 0,
  marginTop: '30px',
  // background: theme.palette.mode === 'dark' ? '#424242' : '#eaeff1',
}));

const Homepage = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const screenSmPlus = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const repositoryLoad = () => {
      dispatch(systemAccountLoad());
      dispatch(systemPaymentTypeLoad());
      dispatch(systemInvoiceTypeLoad());
      dispatch(systemBranchLoad());
      dispatch(systemPaymentTermLoad());
      dispatch(systemTaxCodeLoad());
      dispatch(systemTaxItemLoad());
    };
    repositoryLoad();
  }, []);

  useEffect(() => {
    if (!screenSmPlus) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [screenSmPlus]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <div style={{ display: 'flex', minHeight: '100vh' }}>
        <nav
          style={{
            backgroundColor: 'red',
            [theme.breakpoints.up('sm')]: {
              width: drawerWidth,
              flexShrink: 0,
            },
          }}
        >
          <Navigator setOpenSidebar={setOpen} customStyles={{ display: open ? 'block' : 'none' }} />
        </nav>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflowX: 'hidden' }}>
          <Header onDrawerToggle={handleDrawerToggle} isSidebarOpen={open} />
          <CustomizedPaper isSidebarOpen={open}>
            <Switch>
              <>
                {routes.map((route, i) => {
                  return (
                    <Route
                      key={`${route.name}-${i}`}
                      path={route.path}
                      name={route.name}
                      exact={route.exact}
                      render={(props) => (
                        <RestrictedRoute functionalities={route.functionality}>
                          <route.component {...props} />
                        </RestrictedRoute>
                      )}
                    />
                  );
                })}
                {/* <Route key={'page-not-found'} path={`/page-not-found`} name={'page-not-found'} element={<NotFound />} />
                <Route path='*' element={<Navigate to={'/not-found'} replace />} /> */}
              </>
            </Switch>
          </CustomizedPaper>
          <Divider />
          <footer
            style={{
              padding: theme.spacing(1),
              background: theme.palette.appBar.footer,
              display: 'flex',
              justifyContent: 'start',
              marginLeft: open ? drawerWidth : 0,
            }}
          >
            <Typography variant='body2' align='center'>
              Powered by Compass Holding dev team {new Date().getFullYear()}
              {'.'}
            </Typography>
          </footer>
        </div>
      </div>
    </>
  );
};

export { Homepage };
