import { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * default options for url params parsing
 */
export const queryStringParseOptions = { arrayFormat: 'comma', parseBooleans: true, parseNumbers: true };

export const useTablePagination = (defaultFilters = {}) => {
  const history = useHistory();
  const location = useLocation();

  const [options, setOptions] = useState({
    ...defaultFilters,
  });
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    const { sort: sortKey, sortOrder, page, pageSize, ...filters } = queryString.parse(location.search, queryStringParseOptions);

    if (page) {
      setOptions((prevState) => ({
        ...prevState,
        page,
      }));
    }

    if (pageSize) {
      setOptions((prevState) => ({
        ...prevState,
        pageSize,
      }));
    }

    if (sortKey && sortOrder) {
      setOptions((prevState) => ({
        ...prevState,
        sort_by: sortKey,
        order_by: sortOrder,
      }));
    }

    setOptions((prevState) => ({
      ...prevState,
      ...filters,
    }));
  }, []);

  /**
   *
   * @param {number} page
   */
  const onPageChange = (page) => {
    setOptions((prevState) => ({
      ...prevState,
      page,
    }));
    setSelectedRows([]);
    updateQueryParams({ page });
  };

  /**
   *
   * @param {number} rows
   */
  const onRowsPerPageChange = (rows) => {
    setOptions((prevState) => ({
      ...prevState,
      pageSize: rows,
    }));
    updateQueryParams({ pageSize: rows });
  };

  const onFilterChange = (filterData) => {
    setOptions((prevState) => ({
      ...prevState,
      ...filterData,
    }));

    updateQueryParams(filterData);
  };

  /**
   *
   * @param {[{id:number,  desc: boolean}]} tableSort
   */
  const onSortChange = (tableSort) => {
    const sort_by = tableSort[0].id;
    const order_by = tableSort[0].desc ? 'DESC' : 'ASC';

    setOptions((prevState) => ({ ...prevState, sort_by, order_by }));

    updateQueryParams({ sort: sort_by, sortOrder: order_by });
  };

  const updateQueryParams = (params) => {
    const currentParams = queryString.parse(location.search, queryStringParseOptions);

    // keep url clean
    Object.keys(params).map((key) => {
      if (params[key] == null || params[key] === '') {
        delete currentParams[key];
        delete params[key];
      }
    });

    const updatedParams = { ...currentParams, ...params };
    const queryParams = queryString.stringify(updatedParams, { arrayFormat: 'comma' });
    history.push(`?${queryParams}`);
  };

  const resetOptionsState = () => {
    setOptions({
      pagination: { pageSize: 5, page: 0 },
      sort: defaultSort || {},
      filters: { ...defaultFilters },
    });
    history.push(location.pathname);
  };

  return {
    options,
    setOptions,
    onPageChange,
    onSortChange,
    onRowsPerPageChange,
    onFilterChange,
    resetOptionsState,
    setSelectedRows,
    selectedRows,
  };
};
