import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const getSupportTablePagination = (filter) => {
  return axios.get('/me/repository/support-table', { params: filter });
};

const updateSupportTable = (data) => {
  const { id, name, table_name } = data;
  return axios.patch(`/me/repository/support-table/${id}`, { name, table_name });
};

const createSupportTable = (data) => {
  return axios.post('/me/repository/support-table', data);
};

const deleteSupportTable = (data) => {
  return axios.delete(`/me/repository/support-table/${data.id}`, { params: { table_name: data.table_name } });
};

const getSupportTableForSelect = (table_names) => {
  return axios.get('/me/repository/support-table/select', { params: { table_names } });
};

export const useGetSupportTablePagination = (filter) => {
  return useQuery(
    ['support-table-pagination', filter.page, filter.pageSize, filter.sort_by, filter.order_by, filter.table_name],
    () => getSupportTablePagination(filter),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!filter.table_name,
    }
  );
};

export const useUpdateSupportTableMutation = (filter, callback) => {
  const queryClient = useQueryClient();
  return useMutation(updateSupportTable, {
    onSuccess: () => {
      queryClient.invalidateQueries(['support-table-pagination', filter.page, filter.pageSize, filter.sort_by, filter.order_by, filter.table_name]);
      callback && callback();
    },
  });
};

export const useCreateSupportTableMutation = (filter, callback) => {
  const queryClient = useQueryClient();
  return useMutation(createSupportTable, {
    onSuccess: () => {
      queryClient.invalidateQueries(['support-table-pagination', filter.page, filter.pageSize, filter.sort_by, filter.order_by, filter.table_name]);
      callback && callback();
    },
  });
};

export const useDeleteSupportTableMutation = (filter) => {
  const queryClient = useQueryClient();
  return useMutation(deleteSupportTable, {
    onSuccess: () => {
      queryClient.invalidateQueries(['support-table-pagination', filter.page, filter.pageSize, filter.sort_by, filter.order_by, filter.table_name]);
    },
  });
};

export const useGetSupportTableForSelect = (table_names) => {
  return useQuery(['support-table-for-select', table_names], () => getSupportTableForSelect(table_names), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!table_names && !!table_names.length,
  });
};
