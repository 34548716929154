import { TransactionDataGridColumns } from './Columns/TransactionsDataGridColumns';
import React, { useEffect, useState } from 'react';
import DataTableComponent from './component/DataTableComponent';
import { ExpendGeneralLedgerDataTableComponent } from './ExpendComponent/ExpendGeneralLedgerDataTableComponent';

export default function TransactionsDataTable({
  dataSource = { data: [], page: 1, pageSize: 10, pageTotal: 0 },
  onFilterChange,
  columns = TransactionDataGridColumns({}),
  expandedAll,
  ...props
}) {
  const [openRow, setOpenRows] = useState([]);

  useEffect(() => {
    setOpenRows([]);
  }, [dataSource]);

  useEffect(() => {
    if (expandedAll === true) {
      const after = dataSource.data.map(() => {
        return true;
      });
      setOpenRows(after);
    } else {
      setOpenRows([]);
    }
  }, [expandedAll]);
  useEffect(() => {}, []);

  const onExpandedChange = (rows) => {
    setOpenRows(rows);
  };

  function SubComponentGl({
    row: {
      _original: { gls, detailed_gls },
    },
  }) {
    return <ExpendGeneralLedgerDataTableComponent gls={gls} detailedGls={detailed_gls} />;
  }

  return (
    <DataTableComponent
      dataSource={dataSource}
      onFilterChange={onFilterChange}
      columns={columns}
      onExpandedChange={onExpandedChange}
      SubComponent={SubComponentGl}
      expanded={openRow}
      {...props}
    />
  );
}
