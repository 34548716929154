import React from 'react';
import { Row, Col } from 'reactstrap';
import { IconButton, Button } from '@mui/material';
import { ModeEditOutline, DeleteOutline, AddCircleOutline } from '@mui/icons-material';

import DataTableComponent from '../../../../component/DataTable/component/DataTableComponent';
import { CustomCard } from '../../../../common/Card/CustomCard';
import { CustomSelect } from '../../../../common/CustomSelect';
import { supportTableOptions } from '../../../../constants/supportTables.constants';
import SupportTableForm from './components/SupportTableForm';

import { useGetSupportTablePagination, useDeleteSupportTableMutation } from './supportTableApiHooks';
import { hasPermissionHook } from '../../../../hooks/hasPermission';
import { EDIT, SUPPORT_TABLES } from '../../../../constants/permissions.constants';

const SupportTablesView = (props) => {
  const { hasPermission } = hasPermissionHook();
  const blankData = React.useMemo(() => {
    return { data: [], page: 0, pageSize: 10, pageTotal: 0 };
  }, []);
  const [filter, setFilter] = React.useState({
    table_name: null,
    sort_by: 'name',
    order_by: 'ASC',
    page: 0,
    pageSize: 10,
  });
  const [loader, setLoader] = React.useState(false);

  const { data: supportTableData } = useGetSupportTablePagination(filter);
  const { mutate: deleteMutation } = useDeleteSupportTableMutation(filter);

  const handleChangeSelect = (val, e) => {
    setFilter((oldFilter) => ({ ...oldFilter, table_name: val?.value ? val.value : null }));
  };

  const onFilterChange = (input) => {
    setFilter((oldFilter) => ({ ...oldFilter, ...input }));
  };

  const onDeleteItem = (id) => {
    deleteMutation({ id, table_name: filter.table_name });
  };

  const columns = React.useMemo(() => {
    return [
      {
        accessor: 'id',
        id: 'id',
        Header: 'ID',
        Cell: (row) => row.value,
        sort: true,
        minWidth: 30,
        maxWidth: 50,
      },
      {
        accessor: 'name',
        id: 'name',
        Header: 'Name',
        Cell: (row) => row.value,
        sort: true,
      },
      {
        filterable: false,
        sortable: false,
        accessor: (row) => {
          return (
            <>
              <SupportTableForm action='update' filter={filter} id={row.id} nameProps={row.name} disabled={!hasPermission(SUPPORT_TABLES, EDIT)} />
              <IconButton color='error' onClick={() => onDeleteItem(row.id)} disabled={!hasPermission(SUPPORT_TABLES, EDIT)}>
                <DeleteOutline />
              </IconButton>
            </>
          );
        },
        id: 'btnAction',
        Header: 'Actions',
        minWidth: 150,
        maxWidth: 250,
      },
    ];
  }, [supportTableData?.data]);

  return (
    <CustomCard headerTitle='Support Tables' sxCardContainer={{ mt: 2 }}>
      <Row>
        <Col md='4'>
          <CustomSelect
            defaultValue={filter.table_name}
            onChange={handleChangeSelect}
            name='table_name'
            isClearable
            label={'Select Table'}
            options={supportTableOptions}
          />
        </Col>
        <Col></Col>
        <Col md='3' className='d-flex align-items-end justify-content-end mb-3'>
          <SupportTableForm action='create' filter={filter} disabled={!hasPermission(SUPPORT_TABLES, EDIT)} />
        </Col>
      </Row>
      <Row>
        <Col>
          <DataTableComponent
            dataSource={filter.table_name ? supportTableData?.data || blankData : blankData}
            onFilterChange={onFilterChange}
            columns={columns}
            loading={loader}
          />
        </Col>
      </Row>
    </CustomCard>
  );
};

export default SupportTablesView;
