import React from 'react';
import { Button } from 'reactstrap';
import { formatAmount } from '../../../utils/formatAmout';

export const ChartOfAccountColumns = ({ onActions, disabled = false }) => {
  return [
    {
      Header: <div className='leftHeader'>Account</div>,
      accessor: 'number',
    },
    {
      accessor: 'name',
      Header: () => <div className='leftHeader'>Account name</div>,
    },
    // {
    //   accessor: (row) => {
    //     return row.account_normal_balance_id === 1 ? 'CREDIT' : 'DEBIT';
    //   },
    //   Header: () => <div className='leftHeader'>Normal Balance</div>,
    //   id: 'account_normal_balance_id',
    // },
    {
      accessor: 'debit',
      Header: () => <div className='leftHeader'>Debit</div>,
      Cell: (row) => <div style={{ textAlign: 'right' }}>{formatAmount(row.value)}</div>,
    },
    {
      accessor: 'credit',
      Header: () => <div className='leftHeader'>Credit</div>,
      Cell: (row) => <div style={{ textAlign: 'right' }}>{formatAmount(row.value)}</div>,
    },
    {
      accessor: 'total',
      id: 'total',
      Header: (props) => <div className='leftHeader'>Total</div>,
      Cell: (row) => <div style={{ textAlign: 'right' }}>{formatAmount(row.value)}</div>,
    },

    {
      id: 'actionBtn',
      sortable: false,
      Header: (props) => <div className='leftHeader'>Action</div>,
      accessor: (row) => {
        return (
          <div className='d-flex justify-content-center'>
            <Button
              title={'Show Transactions For ' + row.number}
              id='showTransactions'
              type='button'
              className='btn btn-success btn-sm mr-2'
              onClick={(e) => {
                e.preventDefault();
                onActions({ name: 'journalEntry', id: row.number });
              }}
            >
              <i className='fas fa-info-circle'> </i>{' '}
            </Button>
            <Button
              title={'Edit Account  ' + row.number}
              type='button'
              className='btn btn-warning btn-sm ml-2'
              onClick={(e) => {
                e.preventDefault();
                onActions({ name: 'edit', id: row.number });
              }}
              disabled={disabled}
            >
              <i className='fas fa-edit' color='white' />
            </Button>
            <Button
              title={'Delete Account  ' + row.number}
              type='button'
              className='btn btn-danger btn-sm ml-2'
              onClick={(e) => {
                e.preventDefault();
                onActions({ event: e, name: 'delete', id: row.number });
              }}
              disabled={disabled}
            >
              <i className='fa fa-trash' color='danger'>
                {' '}
              </i>
            </Button>
          </div>
        );
      },
    },
  ];
};
