import './App.scss';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarProvider, useSnackbar } from 'notistack';
import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import './App.scss';
import PageLogin from './app/page/LoginPage';
import { isAuth } from './config/auth/auth';
import { httpApiConfig } from './config/http/HttpApiConfig';
import ReduxStore from './config/Redax/ReduxStore';
import { PrivateRouter } from './config/router/PrivateRouter';
import { theme } from './config/theme';
import { Homepage } from './layout/Homepage';

httpApiConfig(ReduxStore);
isAuth(ReduxStore);

const queryClient = new QueryClient();

const CloseSnackbarButton = ({ id }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <button
      onClick={() => {
        closeSnackbar(id);
      }}
      class='fas fa-times'
      style={{ backgroundColor: 'transparent', border: 'none', color: 'white' }}
    />
  );
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={ReduxStore}>
        <ToastContainer />
        <ThemeProvider theme={theme()}>
          <SnackbarProvider maxSnack={3} action={(id) => <CloseSnackbarButton id={id} />}>
            <Switch>
              <Route path='/login' component={PageLogin} />
              <PrivateRouter path='/' component={Homepage} />

              {/*postaviti router za admin*/}
            </Switch>
          </SnackbarProvider>
        </ThemeProvider>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
    </QueryClientProvider>
  );
}

export default App;
