import React from 'react';
import { Col, Row } from 'reactstrap';
import { Loader } from '../../../../../../common/Loader';
import { formatAmount } from '../../../../../../utils/formatAmout';

export default function AgingTotalHeaderComponent({ isLoading, total }) {
  if (isLoading)
    return (
      <div className='p-1 m-5 d-flex justify-content-center'>
        <Loader />
      </div>
    );

  return (
    <Row className='p-2'>
      <Col>
        <BalanceBox
          title={total.debit_upto30 && total.total_balance ? `${Math.abs(((total.debit_upto30 * 100) / total.total_balance).toFixed(2))}%` : `0%`}
          value={formatAmount(total.debit_upto30)}
          label={`0 to 30 days`}
          colorClass='bg-lime'
          icon='fas fa-stopwatch'
        />
      </Col>
      <Col>
        <BalanceBox
          title={total.debit_upto60 && total.total_balance ? `${Math.abs(((total.debit_upto60 * 100) / total.total_balance).toFixed(2))}%` : '0%'}
          value={formatAmount(total.debit_upto60)}
          label='31 to 60 days'
          colorClass='bg-warning'
          icon='fas fa-stopwatch'
        />
      </Col>
      <Col>
        <BalanceBox
          title={total.debit_upto90 && total.total_balance ? `${Math.abs(((total.debit_upto90 * 100) / total.total_balance).toFixed(2))}%` : '0%'}
          value={formatAmount(total.debit_upto90)}
          label='61 to 90 days'
          colorClass='bg-orange'
          icon='fas fa-stopwatch'
        />
      </Col>
      <Col>
        <BalanceBox
          title={total.debit_over90 && total.total_balance ? `${Math.abs(((total.debit_over90 * 100) / total.total_balance).toFixed(2))}%` : '0%'}
          value={formatAmount(total.debit_over90)}
          label='90+ days'
          colorClass='bg-danger'
          icon='fas fa-exclamation'
        />
      </Col>
      <Col>
        <BalanceBox title='TOTAL BALANCE' value={formatAmount(total.total_balance)} label='for all' icon='fas fa-calculator' />
      </Col>
    </Row>
  );
}

const BalanceBox = ({ title, label, value, icon, colorClass }) => {
  return (
    <div className={`small-box ${colorClass ?? 'bg-info'} m-0`}>
      <div className='inner'>
        <h4>{title}</h4>
        <h5>{value}</h5>
        <p>{label}</p>
      </div>
      <div className='icon'>
        <i className={icon} />
      </div>
    </div>
  );
};
