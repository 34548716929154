import { Button, Divider } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { CustomInput } from '../../../../../common/CustomInput';
import { CustomSelect } from '../../../../../common/CustomSelect';
import { CustomInputMask } from '../../../../../common/Inputs/CustomInputMask';
import { CustomPageableAsyncSelect } from '../../../../../common/Inputs/CustomPageableAsyncSelect';
import { CustomToggleButtonGroup } from '../../../../../common/Inputs/CustomToggleButtonGroup';
import { IRS_1099_CODE, VENDOR_TYPE } from '../../../../../constants/supportTables.constants';
import { useGetSupportTablesForSelect, useStateOptions } from '../../../../../hooks/commonApiHooks';
import { useAsyncPageable } from '../../../../../hooks/useAsyncPageable';
import { clientString2Int } from '../../../../../utils/fastTools';
import { clientTypeNameSingular } from '../../utils';

export const Filters = ({ filters, onChange, onSelectChange, onButtonGroupChange, onSearch }) => {
  const { clientType } = useParams();

  const [isCustomer, setIsCustomer] = useState(clientType?.toLowerCase() === 'customers');
  const [init, setInit] = useState(false);

  const { fetchOptions } = useAsyncPageable();

  const { data: { data: stateOptions = [] } = {} } = useStateOptions();
  const { data: { data: supportTableOptions = {} } = {} } = useGetSupportTablesForSelect({ table_names: [VENDOR_TYPE, IRS_1099_CODE] });

  useEffect(() => {
    setIsCustomer(clientType?.toLowerCase() === 'customers');

    setInit(false);

    //force rerendering of async inputs
    setTimeout(() => {
      setInit(true);
    }, 1);
  }, [clientType]);

  const initClientFilters = useMemo(() => {
    return { client_type_id: clientString2Int(clientType) };
  }, [clientType]);

  const loadClientExternalIdOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/client/customer/options-number-for-select', { q: inputValue, ...options });
    return data;
  };

  const loadClientRmiIdOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/client/customer/options-rmi-id-for-select', { q: inputValue, ...options });
    return data;
  };

  return (
    <>
      <Row>
        <Col xs={12} md={4} lg={3}>
          {init && (
            <CustomPageableAsyncSelect
              label={clientTypeNameSingular(clientType) + ' No.'}
              isClearable
              onChange={onSelectChange}
              loadOptions={loadClientExternalIdOptions}
              name='client.id'
              defaultValue={filters['client.id'] || null}
              initFilters={initClientFilters}
            />
          )}
        </Col>
        <Col xs={12} md={4} lg={3}>
          {init && (
            <CustomPageableAsyncSelect
              label={'RMI No.'}
              isClearable
              onChange={onSelectChange}
              loadOptions={loadClientRmiIdOptions}
              name='client.rmi_number'
              defaultValue={filters['client.rmi_number'] || null}
              initFilters={initClientFilters}
            />
          )}
        </Col>

        {isCustomer && (
          <Col xs={12} md={4} lg={3}>
            <CustomInput label={'MC No.'} name='client.mc_number' onChange={onChange} value={filters['client.mc_number'] || ''} />
          </Col>
        )}

        {isCustomer && (
          <Col xs={12} md={4} lg={3}>
            <CustomInput label={'DOT No.'} name='client.dot_number' onChange={onChange} value={filters['client.dot_number'] || ''} />
          </Col>
        )}
        <Col xs={12} md={4} lg={3}>
          <CustomInput label={'Name'} name='client.name' onChange={onChange} value={filters['client.name'] || ''} />
        </Col>

        {!isCustomer && (
          <Col xs={12} md={4} lg={3}>
            <CustomSelect
              label='Vendor Type'
              isClearable
              onChange={onSelectChange}
              name='client.vendor_type'
              defaultValue={filters['client.vendor_type'] || null}
              options={supportTableOptions[VENDOR_TYPE] || []}
            />
          </Col>
        )}

        {/* same component different position */}
        {!isCustomer && (
          <Col xs={12} md={4} lg={3}>
            <CustomToggleButtonGroup
              size='small'
              label='Blocked'
              name='client.blocked'
              value={filters['client.blocked'] ?? null}
              onChange={onButtonGroupChange}
            />
          </Col>
        )}

        <Col xs={12} md={4} lg={3}>
          <CustomSelect
            label='State'
            isClearable
            onChange={onSelectChange}
            name='client.address_state'
            defaultValue={filters['client.address_state'] || null}
            options={stateOptions || []}
          />
        </Col>
        <Col xs={12} md={4} lg={3}>
          <CustomInputMask type='tel' label='Phone Number' name='phone_number' value={filters?.phone_number || ''} onChange={onChange} />
        </Col>
        <Col xs={12} md={4} lg={3}>
          <CustomInput label={'Email'} name='email' onChange={onChange} value={filters?.email || ''} />
        </Col>

        {isCustomer && (
          <Col xs={12} md={4} lg={3}>
            <CustomInput label={'Owner'} name='client.owner_name' onChange={onChange} value={filters['client.owner_name'] || ''} />
          </Col>
        )}

        {isCustomer && (
          <Col xs={12} md={4} lg={3}>
            <CustomToggleButtonGroup
              size='small'
              label='CFS'
              name='client.cfs'
              value={filters['client.cfs'] ?? null}
              onChange={onButtonGroupChange}
            />
          </Col>
        )}

        {isCustomer && (
          <Col xs={12} md={4} lg={3}>
            <CustomToggleButtonGroup
              size='small'
              label='ACH'
              name='client.ach'
              value={filters['client.ach'] ?? null}
              onChange={onButtonGroupChange}
            />
          </Col>
        )}

        {isCustomer && (
          <Col xs={12} md={4} lg={3}>
            <CustomToggleButtonGroup
              size='small'
              label='Blocked'
              name='client.blocked'
              value={filters['client.blocked'] ?? null}
              onChange={onButtonGroupChange}
            />
          </Col>
        )}

        {!isCustomer && (
          <Col xs={12} md={4} lg={3}>
            <CustomSelect
              label='IRS 1099 Code'
              isClearable
              name='client.irs_1099_code_id'
              defaultValue={filters['client.irs_1099_code_id'] || null}
              options={supportTableOptions[IRS_1099_CODE] || []}
              onChange={onSelectChange}
            />
          </Col>
        )}
      </Row>
      <Divider className='mt-3 mb-3' />
      <Row>
        <Col className='d-flex justify-content-end'>
          <Button onClick={onSearch}>Search</Button>
        </Col>
      </Row>
    </>
  );
};
