import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Button, Box, Stepper, Step, StepLabel, Tooltip, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';

import { CustomCard } from '../../../common/Card/CustomCard';
import { BeginReconciliation } from './components/BeginReconciliation';
import { Reconsile } from './components/Reconcile';
import { Reports } from './components/Reports';
import { useGetBancRecoData, useGetLastBancRecoData } from './BankRecoApiHooks';

const BankReconciliationComponent = () => {
  const history = useHistory();
  const params = useParams();
  const [step, setStep] = React.useState(0);
  const [account, setAccount] = React.useState(null);
  const stepperSteps = React.useMemo(() => ['Begin Reconciliation', 'Reconcile', 'Reports']);

  const {
    data: { data: bankRecoData } = {},
    refetch: refetchGetBancRecoData,
    remove: removeFetchedBancRecoData,
  } = useGetBancRecoData({ account, id: params.recoId });
  const { data: { data: lastBancRecoData = {} } = {}, remove: removeLastBancRecoData } = useGetLastBancRecoData({ account });

  React.useEffect(() => {
    removeFetchedBancRecoData();
    removeLastBancRecoData();
  }, []);

  React.useEffect(() => {
    if (params.reconciliationId) {
      setStep(2);
    }
    if (params.recoId) {
      refetchGetBancRecoData({ id: params.recoId });
      setStep(1);
    }
  }, [params]);

  return (
    <>
      <Button
        size='small'
        sx={{ mt: 1, backgroundColor: grey[800], '&:hover': { backgroundColor: grey[700] } }}
        onClick={() => {
          removeFetchedBancRecoData();
          history.push('/bank-reconciliation');
        }}
      >
        <i className='fas fa-angle-left mr-2'></i>Exit Reconciliation Without Save
      </Button>
      <CustomCard headerTitle={<span>Bank Reconciliation</span>} sxCardContainer={{ marginTop: '15px' }}>
        <Row>
          <Col>
            <Box sx={{ width: '100%' }}>
              <Stepper activeStep={step} alternativeLabel>
                {stepperSteps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Col>
        </Row>
        {step === 0 && (
          <Row className='mt-3'>
            <Col>
              <BeginReconciliation
                setStep={setStep}
                setAccount={setAccount}
                removeFetchedBancRecoData={removeFetchedBancRecoData}
                bankRecoData={bankRecoData}
                refetchGetBancRecoData={refetchGetBancRecoData}
                lastBancRecoData={lastBancRecoData}
                removeLastBancRecoData={removeLastBancRecoData}
              />
            </Col>
          </Row>
        )}
        {step === 1 && (
          <Row className='mt-3'>
            <Col>
              <Reconsile setStep={setStep} bankRecoData={bankRecoData} accountNumber={account} refetchGetBancRecoData={refetchGetBancRecoData} />
            </Col>
          </Row>
        )}
        {step === 2 && (
          <Row className='mt-3'>
            <Col>
              <Reports />
            </Col>
          </Row>
        )}
      </CustomCard>
    </>
  );
};

export default BankReconciliationComponent;
