import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { authenticationSetUser } from '../redax/Authorization/AuthorizationAction';
import { setAuthToken } from '../../config/auth/auth';
import jwt_decode from 'jwt-decode';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';

export default function PageLogin() {
  const [values, setValues] = useState({ email: '', password: '', domain: '' });
  const [domainList, setDomainList] = useState([]);
  const [errorMsg, setErrorMsg] = useState([]);
  const [activeLabels, setActiveLabels] = useState({
    email: false,
    password: false,
  });

  const auth = useSelector((state) => state.auth);
  const history = useHistory();
  const callReduxAction = useDispatch();
  const onChange = ({ target: { name, value } }) => {
    const v = { ...values, [name]: value };
    if (value != '') {
      setActiveLabels({ ...activeLabels, [name]: true });
    } else {
      setActiveLabels({ ...activeLabels, [name]: false });
    }
    setValues(v);
  };
  const getDomainList = () => {
    return axios
      .get('/login/domain')
      .then((res) => {
        if (res.status === 200) {
          setDomainList(res.data);
        } else {
          setErrorMsg(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (auth.isAuthenticated) history.push('/');
  }, [auth, history]);
  useEffect(() => {
    getDomainList().then((r) => {});
  }, []);
  useEffect(() => {
    console.log(errorMsg);
  }, [errorMsg]);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    axios
      .post('/login/', values)
      .then((res) => {
        if (res.status === 200) {
          const { token } = res.data;
          localStorage.setItem('legalToken', token);
          setAuthToken(token);
          const decoded = jwt_decode(token);
          callReduxAction(authenticationSetUser(decoded));
        } else {
          const { message } = JSON.parse(res.request.response);
          setErrorMsg(message);
        }
      })
      .catch((e) => {
        console.log(e.response.data);
      });
  };
  return (
    <div className='login-page'>
      <img src='/assets/img/logo/logo.png' alt='logo.png' />

      <Form onSubmit={onFormSubmit}>
        <h1>Welcome back!</h1>
        <p>Please enter your email and password to login</p>

        <FormGroup id='login-email'>
          <Input type='email' name='email' value={values.email} onChange={onChange} />
          <Label className={activeLabels.email ? 'active-label' : ''} for='login-email'>
            Email
          </Label>
        </FormGroup>

        {/*<div className="input-group mb-3">
           <input
            onChange={onChange}
            name="email"
            value={values.email}
            type="email"
            className="form-control"
            placeholder="Email"
          /> 
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-envelope"> </span>
            </div>
          </div> 
          </div> */}
        <FormGroup id='login-password'>
          <Input type='password' name='password' value={values.password} onChange={onChange} />
          <Label className={activeLabels.password ? 'active-label' : ''} for='login-password'>
            Password
          </Label>
        </FormGroup>
        {/* <div className="input-group mb-3">
          <input
            onChange={onChange}
            name="password"
            value={values.password}
            type="password"
            className="form-control"
            placeholder="Password"
          />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-lock"> </span>
            </div>
          </div>
        </div> */}
        <FormGroup>
          <Input type='select' name='domain' value={values.domain} onChange={onChange}>
            {domainList.map((item, key) => (
              <option key={key} value={item.domain}>
                {' '}
                {item.domain}{' '}
              </option>
            ))}
          </Input>
        </FormGroup>
        {/* <div className="input-group mb-3">
          <select name="domain" className="form-control" value={values.domain} onChange={onChange}>
            {domainList.map((item, key) => (
              <option key={key} value={item.domain}>
                {" "}
                {item.domain}{" "}
              </option>
            ))}
          </select>
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-box"> </span>
            </div>
          </div>
        </div> */}

        <Row>
          <Col xs={12}>
            <button type='submit' onClick={onFormSubmit} className='btn btn-info btn-block'>
              Sign In
            </button>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p className='login-box-msg text-danger'>{errorMsg}</p>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
