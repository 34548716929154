const defaultNumberOptions = {
  digits: 2,
};

export const formatAmount = (value) => {
  if (!value && value != 0) return value;

  if (value.toString() === '$ 0') {
    return '$ 0,00';
  }

  return (
    '$ ' +
    parseFloat(value)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  );
};

/**
 * @description Use it for preview in tables
 */
export const formatNumber = (value, options = defaultNumberOptions) => {
  return Number.parseFloat(value).toFixed(options.digits);
};

/**
 * Round up number to {options.digits} digits
 * default to 2 digits
 */
export const roundNumber = (value, options = defaultNumberOptions) => {
  const number = Number.parseFloat(value);

  if (isNaN(number)) {
    return NaN;
  }

  return Number.parseFloat(number?.toFixed(options?.digits || 2));
};
