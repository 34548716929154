import React from 'react';
import { NavLink, Route, Switch, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Warning } from '../../../../common/Warning';
import { useClient } from '../clientHooks';
import { ClientCard } from '../component/ClientCard';
import ClientInvoiceTrackerComponent from './component/report/InvoiceTracker/InvoiceTrackerComponent';
import ClientTransactionComponent from './component/transaction/ClientTransactionComponent';
import ClientCreditMemoTransactionComponent from './component/transaction/creditmemo/ClientCreditMemoTransactionComponent';
import ClientDebitMemoTransactionComponent from './component/transaction/debitmemo/ClientDebitMemoTransactionComponent';
import ClientInvoiceTransactionComponent from './component/transaction/invoice/ClientInvoiceTransactionComponent';
import ClientPaymentTransactionComponent from './component/transaction/payment/ClientPaymentTransactionComponent';
import ClientRefundTransactionComponent from './component/transaction/refund/ClientRefundTransactionComponent';
import {
  C_TRANSACTIONS,
  C_INVOICES,
  C_PAYMENT,
  C_CREDIT_MEMO,
  C_DEBIT_MEMO,
  C_REFUND,
  EDIT,
  VIEW,
  CUSTOMERS,
  V_TRANSACTIONS,
  V_INVOICES,
  V_PAYMENT,
  V_CREDIT_MEMO,
  V_DEBIT_MEMO,
  V_REFUND,
  C_HISTORY,
  V_HISTORY,
} from '../../../../constants/permissions.constants';
import { hasPermissionHook } from '../../../../hooks/hasPermission';
import { RestrictedRoute } from '../../../../config/router/RestrictedRoute';
import { ClientTransactionsHistory } from './component/transaction/history/ClientTransactionsHistory';

export default function Client360View() {
  const { hasPermission } = hasPermissionHook();
  let { clientType, clientId } = useParams();
  const url = React.useMemo(() => `/client/${clientType}/360/${clientId}`, [clientId, clientType]);

  const { data: clientData, refetch: refetchClient } = useClient(clientId, clientType);

  const onRefresh = () => {
    refetchClient();
  };

  return (
    <>
      <ClientCard />
      <div>
        <ul className='nav nav-pills'>
          {hasPermission(clientType === 'customers' ? C_TRANSACTIONS : V_TRANSACTIONS, VIEW) && (
            <li>
              <NavLink
                activeClassName='shadow'
                className='nav-link'
                to={`${url}/transactions`}
                isActive={(match, location) => {
                  if (match) return true;
                  return RegExp('^\\/client\\/(customers|vendors)\\/\\d+\\/\\d+\\/(transactions|$)').test(match);
                }}
              >
                Transactions
              </NavLink>
            </li>
          )}
          {hasPermission(clientType === 'customers' ? C_INVOICES : V_INVOICES, VIEW) && (
            <li>
              <NavLink activeClassName='shadow ' className='nav-link' to={`${url}/invoice`}>
                Invoices
              </NavLink>
            </li>
          )}
          {hasPermission(clientType === 'customers' ? C_PAYMENT : V_PAYMENT, VIEW) && (
            <li>
              <NavLink activeClassName='shadow ' className='nav-link' to={`${url}/payment`}>
                Payment
              </NavLink>
            </li>
          )}
          {hasPermission(clientType === 'customers' ? C_CREDIT_MEMO : V_CREDIT_MEMO, VIEW) && (
            <li>
              <NavLink activeClassName='shadow ' className='nav-link' to={`${url}/credit-memo`}>
                Credit Memo
              </NavLink>
            </li>
          )}
          {hasPermission(clientType === 'customers' ? C_DEBIT_MEMO : V_DEBIT_MEMO, VIEW) && (
            <li>
              <NavLink activeClassName='shadow ' className='nav-link' to={`${url}/debit-memo`}>
                Debit Memo
              </NavLink>
            </li>
          )}
          {hasPermission(clientType === 'customers' ? C_REFUND : V_REFUND, VIEW) && (
            <li>
              <NavLink activeClassName='shadow ' className='nav-link' to={`${url}/refund`}>
                Refund
              </NavLink>
            </li>
          )}
          {hasPermission(clientType === 'customers' ? C_HISTORY : V_HISTORY, VIEW) && (
            <li>
              <NavLink activeClassName='shadow ' className='nav-link' to={`${url}/history`}>
                History
              </NavLink>
            </li>
          )}
          {/* <li>
            <NavLink activeClassName='shadow ' className='nav-link' to={`${url}/invoices-tracker`}>
              <Row>
                <Warning />
                Tracker
              </Row>
            </NavLink>
          </li> */}
        </ul>
      </div>
      <Row className='mt-3'>
        <Col md={12}>
          <Switch>
            <Route
              exact
              path={['/client/:clientType(customers|vendors)/360/:clientId/transactions', '/client/:clientType(customers|vendors)/360/:clientId/']}
            >
              <RestrictedRoute functionalities={[C_TRANSACTIONS, V_TRANSACTIONS]}>
                <ClientTransactionComponent clinetType={clientType} clinetId={clientId} onRefresh={onRefresh} />
              </RestrictedRoute>
            </Route>
            <Route exact path='/client/:clientType(customers|vendors)/360/:clientId/payment'>
              <RestrictedRoute functionalities={[C_PAYMENT, V_PAYMENT]}>
                <ClientPaymentTransactionComponent clinetType={clientType} clinetId={clientId} onRefresh={onRefresh} />
              </RestrictedRoute>
            </Route>
            <Route exact path='/client/:clientType(customers|vendors)/360/:clientId/invoice'>
              <RestrictedRoute functionalities={[C_PAYMENT, V_PAYMENT]}>
                <ClientInvoiceTransactionComponent clinetType={clientType} clinetId={clientId} onRefresh={onRefresh} />
              </RestrictedRoute>
            </Route>
            <Route exact path='/client/:clientType(customers|vendors)/360/:clientId/credit-memo'>
              <RestrictedRoute functionalities={[C_CREDIT_MEMO, V_CREDIT_MEMO]}>
                <ClientCreditMemoTransactionComponent clinetType={clientType} clinetId={clientId} onRefresh={onRefresh} />
              </RestrictedRoute>
            </Route>
            <Route exact path='/client/:clientType(customers|vendors)/360/:clientId/debit-memo'>
              <RestrictedRoute functionalities={[C_DEBIT_MEMO, V_DEBIT_MEMO]}>
                <ClientDebitMemoTransactionComponent clinetType={clientType} clinetId={clientId} onRefresh={onRefresh} />
              </RestrictedRoute>
            </Route>
            <Route exact path='/client/:clientType(customers|vendors)/360/:clientId/refund'>
              <RestrictedRoute functionalities={[C_REFUND, V_REFUND]}>
                <ClientRefundTransactionComponent clinetType={clientType} clinetId={clientId} onRefresh={onRefresh} />
              </RestrictedRoute>
            </Route>
            <Route exact path='/client/:clientType(customers|vendors)/360/:clientId/history'>
              <RestrictedRoute functionalities={[C_HISTORY, V_HISTORY]}>
                <ClientTransactionsHistory clinetType={clientType} clinetId={clientId} onRefresh={onRefresh} />
              </RestrictedRoute>
            </Route>
            {/* <Route exact path='/client/:clientType(customers|vendors)/360/:clientId/invoices-tracker'>
              <ClientInvoiceTrackerComponent clinetType={clientType} clinetId={clientId} />
            </Route> */}
          </Switch>
        </Col>
      </Row>
    </>
  );
}
