import CodeIcon from '@mui/icons-material/Code';
import moment from 'moment';
import { formatAmount } from '../../../../utils/formatAmout';
import { formatDateForTable } from '../../../../utils/formatDataForTable';

export const JournalEntryDataGridColumns = ({ openClientTransactionPage }) => {
  const openFixedAssetsPage = (row) => {
    if (row?.original['fixed_assets.id']) {
      window.open(`/fixed-assets/${row?.original['fixed_assets.id']}`);
    }
  };

  const subaccountColumn = (row) => {
    return (
      <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => openClientTransactionPage(row, { isClient: true })}>
        {row.value}
        {!row?.original?.is_client_transaction && <CodeIcon fontSize='small' color='success' />}
      </div>
    );
  };

  return [
    {
      accessor: (d) => d['transactions.document_number'],
      id: 'transactions.document_number',
      Header: ' Document Number',
      Cell: (row) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() =>
            openClientTransactionPage(row, {
              isDeposit: !!row?.original['bank_payment_wizard.id'],
            })
          }
        >
          {row.value}
        </div>
      ),
      sort: true,
    },
    {
      Header: <div className='leftHeader'>Document Type</div>,
      Cell: (row) => <div>{row.value}</div>,
      accessor: (d) => d['document_type.name'],
      id: 'document_type.name',
      sort: true,
    },
    {
      Header: <div className='leftHeader'>Account</div>,
      Cell: ({ value, row }) => {
        return (
          <>
            <div className='mb-2'>{value}</div>
            <div>{row?._original['account.name']}</div>
          </>
        );
      },
      accessor: (d) => d['account.number'],
      id: 'account.number',
      sort: true,
    },
    //   {
    //     Header: <div className='leftHeader'>Account Name</div>,
    //     Cell: (row) => <div style={{ textAlign: 'center' }}>{row.value}</div>,
    //     accessor: (d) => d['account.name'],
    //     id: 'account.name',
    //     sort: true,
    //   },
    {
      Header: <div className='leftHeader'>Description</div>,
      Cell: (row) => <div style={{ textAlign: 'center' }}>{row.value}</div>,
      accessor: (d) => d['general_ledger.description'],
      id: 'general_ledger.description',
      sort: true,
    },
    //   {
    //     accessor: (d) => d['general_ledger.document_date'],
    //     id: 'general_ledger.document_date',
    //     Header: 'Document Date',
    //     Cell: (row) => <div style={{ textAlign: 'center' }}>{formatDateForTable(row.value)}</div>,
    //     sort: true,
    //   },

    {
      accessor: (d) => d['client.name'],
      id: 'client.name',
      Header: 'Subaccount Name',
      Cell: subaccountColumn,
      sort: true,
      width: 120,
    },
    {
      accessor: (d) => d['order_number'],
      id: 'order_number',
      Header: (props) => 'Rental Order Number',
      sort: true,
    },
    {
      accessor: (d) => d['stock_no'],
      id: 'stock_no',
      Header: 'Fixed Asset',
      Cell: (row) => (
        <div style={{ cursor: 'pointer' }} onClick={() => openFixedAssetsPage(row)}>
          {row.value}
        </div>
      ),
      sort: true,
    },
    {
      accessor: (d) => d['posting_date'],
      id: 'posting_date',
      Header: 'Posting Date',
      Cell: (row) => formatDateForTable(row.value),
      sort: true,
    },
    {
      accessor: (d) => d['general_ledger.debit_amount'],
      id: 'general_ledger.debit_amount',
      Header: 'Debit Amount',
      Cell: (row) => formatAmount(row.value),
      sort: true,
    },
    {
      accessor: (d) => d['general_ledger.credit_amount'],
      id: 'general_ledger.credit_amount',
      Header: 'Credit Amount',
      Cell: (row) => formatAmount(row.value),
      sort: true,
    },
    {
      accessor: (d) => d['general_ledger.total_amount'],
      id: 'general_ledger.total_amount',
      Header: 'Total',
      Cell: (row) => formatAmount(row.value),
      sort: true,
    },
    {
      accessor: (d) => d['transaction_source.name'],
      id: 'transaction_source.name',
      Header: 'Source',
      sort: true,
    },
    {
      accessor: (d) => d['is_reversed'],
      id: 'is_reversed',
      Header: 'Is Reversed',
      Cell: (row) => {
        if (row.value === true) {
          return 'Yes';
        }

        return 'No';
      },
      sort: true,
    },
    {
      accessor: (d) => d['general_ledger.created_at'],
      id: 'general_ledger.created_at',
      Header: 'Date Created',
      Cell: (row) => moment(row.value).format('MM/DD/YYYY'),
      sort: true,
    },
    {
      accessor: (d) => d['users.name'],
      id: 'users.name',
      Header: 'Created By',
      Cell: (row) => row.value,
      sort: true,
    },
    {
      accessor: (d) => d['branch.name'],
      id: 'branch.name',
      Header: 'Branch',
      sort: true,
    },
    {
      accessor: (d) => d['transactions_id'],
      id: 'transactions_id',
      Header: 'Transaction ID',
      Cell: (row) => row.value,
      sort: true,
    },
    {
      accessor: (d) => d['general_ledger.id'],
      id: 'general_ledger.id',
      Header: 'Line ID',
      Cell: (row) => row.value,
      sort: true,
    },
  ];
};

export const excelHeaderColumns = [
  { header: 'DOCUMENT NUMBER', key: 'transactions.document_number', style: { alignment: { vertical: 'middle', horizontal: 'left' } } },
  { header: 'DOCUMENT TYPE', key: 'document_type.name', style: { alignment: { vertical: 'middle', horizontal: 'left' } } },
  {
    header: 'G/L ACCOUNT #',
    key: 'account.number',
    style: { alignment: { vertical: 'middle', horizontal: 'left' } },
  },
  {
    header: 'G/L ACCOUNT NAME',
    key: 'account.name',
    style: { alignment: { vertical: 'middle', horizontal: 'left' } },
  },
  { header: 'DESCRIPTION', key: 'general_ledger.description', style: { alignment: { vertical: 'middle', horizontal: 'left' } } },
  { header: 'SOURCE NAME', key: 'client.name', style: { alignment: { vertical: 'middle', horizontal: 'left' } } },
  { header: 'RENTAL ORDER NUMBER', key: 'order_number', style: { alignment: { vertical: 'middle', horizontal: 'left' } } },
  { header: 'FIXED ASSET #', key: 'stock_no', style: { alignment: { vertical: 'middle', horizontal: 'left' } } },
  { header: 'POSTING DATE', key: 'posting_date', style: { alignment: { vertical: 'middle', horizontal: 'left' } } },
  {
    header: 'DEBIT AMOUNT',
    key: 'general_ledger.debit_amount',
    style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' },
  },
  {
    header: 'CREDIT AMOUNT',
    key: 'general_ledger.credit_amount',
    style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' },
  },
  {
    header: 'TOTAL',
    key: 'general_ledger.total_amount',
    style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' },
  },
  { header: 'SOURCE', key: 'transaction_source.name', style: { alignment: { vertical: 'middle', horizontal: 'left' } } },
  { header: 'IS REVERSED', key: 'is_reversed', style: { alignment: { vertical: 'middle', horizontal: 'left' } } },
  { header: 'CREATED AT', key: 'general_ledger.created_at', style: { alignment: { vertical: 'middle', horizontal: 'left' } } },
  { header: 'CREATED BY', key: 'users.name', style: { alignment: { vertical: 'middle', horizontal: 'left' } } },
  { header: 'BRANCH', key: 'branch.name', style: { alignment: { vertical: 'middle', horizontal: 'left' } } },
  { header: 'TRANSACTION ID', key: 'transactions_id', style: { alignment: { vertical: 'middle', horizontal: 'left' } } },
];
