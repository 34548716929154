import { formatAmount } from '../../../../utils/formatAmout';

export const TrialBalanceColumns = ({ onAccountClick = () => {} }) => [
  {
    Header: <div className='leftHeader'>Account</div>,
    accessor: 'number',
    Cell: (row) => (
      <div style={{ textAlign: 'center', cursor: 'pointer', color: 'blue' }} onClick={() => onAccountClick(row)}>
        {row.value}
      </div>
    ),
  },
  {
    accessor: 'name',
    Header: () => <div className='leftHeader'>Account name</div>,
  },
  {
    accessor: 'beginning_balance',
    Header: () => <div className='leftHeader'>Beginning Balance</div>,
    Cell: (row) => <div style={{ textAlign: 'right' }}>{formatAmount(row.value)}</div>,
  },
  {
    accessor: 'total_debit_activities',
    Header: () => <div className='leftHeader'>Total Debit Activities</div>,
    Cell: (row) => <div style={{ textAlign: 'right' }}>{formatAmount(row.value)}</div>,
  },
  {
    accessor: 'total_credit_activities',
    Header: () => <div className='leftHeader'>Total Credit Activities</div>,
    Cell: (row) => <div style={{ textAlign: 'right' }}>{formatAmount(row.value)}</div>,
  },
  {
    accessor: 'net_change',
    Header: () => <div className='leftHeader'>Net Change</div>,
    Cell: (row) => <div style={{ textAlign: 'right' }}>{formatAmount(row.value)}</div>,
  },
  {
    accessor: 'total',
    id: 'total',
    Header: () => <div className='leftHeader'>Total</div>,
    Cell: (row) => <div style={{ textAlign: 'right' }}>{formatAmount(row.value)}</div>,
  },
];
