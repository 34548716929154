import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Tooltip } from '@mui/material';
import React, { useCallback } from 'react';

export const ClientTransactionsHistoryActions = ({ row, onUpdate, onDelete, visible }) => {
  const getReason = useCallback(() => {
    let reason = null;
    if (row?.original?.reason == 1) {
      reason = 2;
    } else if (row?.original?.reason == 2) {
      reason = 1;
    } else {
      reason = 1;
    }

    return reason;
  }, [row]);

  if (!visible) {
    return <></>;
  }

  return (
    <div>
      <Tooltip title='Change Delete Reason' arrow followCursor>
        <IconButton color='warning' onClick={() => onUpdate({ id: row.original.id, data: { reason: getReason() } })}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title='Delete Status' arrow followCursor>
        <IconButton disabled={row?.original?.reason != 1} color='error' onClick={() => onDelete(row.original.id)}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};
