import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Box, Button, DialogActions, DialogContent, IconButton } from '@mui/material';
import { DeleteForeverOutlined, ClearOutlined } from '@mui/icons-material';

import DataGridComponent from '../../../component/DataTable/component/DataTableComponent';
import { CustomCard } from '../../../common/Card/CustomCard';
import { recurringJournalEntryColumns } from './columns/recurringJournalEntryColumns';
import { useGetRecurringJLTableData, useDeleteRecurringJLMutation } from './apiHooks';
import { CustomPopup } from '../../../common/Popup/CustomPopup';
import { hasPermissionHook } from '../../../hooks/hasPermission';
import { EDIT, RECURRING_JOURNAL_ENTRY } from '../../../constants/permissions.constants';

const RecurringEntryTable = (props) => {
  const { hasPermission } = hasPermissionHook();
  const history = useHistory();
  const [filters, setFilters] = React.useState({
    sort_by: 'id',
    order_by: 'DESC',
  });
  const [deleteData, setDeleteData] = React.useState({
    deleteAnchor: null,
  });

  const { data: tableData } = useGetRecurringJLTableData(filters);
  const { mutate: deleteRecurringEntryMutation } = useDeleteRecurringJLMutation();

  const onFilterChange = (e) => {
    setFilters({ ...filters, ...e });
  };

  const onDeleteClick = (e, rowData) => {
    setDeleteData({
      deleteAnchor: e.currentTarget,
      openDeletePopup: true,
      depositId: rowData.original.id,
    });
  };

  const columns = React.useMemo(() => recurringJournalEntryColumns({ onDeleteClick, disabled: !hasPermission(RECURRING_JOURNAL_ENTRY, EDIT) }), []);
  const cleanDataSource = React.useMemo(() => ({ data: [], page: 0, pageSize: 10, pageTotal: 0 }));

  const handleConfirmDeleteDepositClick = () => {
    deleteRecurringEntryMutation(deleteData.depositId);
    setDeleteData({ deleteAnchor: null });
  };

  return (
    <>
      <CustomCard headerTitle='Recurring Journal Entries' sxCardContainer={{ mt: 2 }}>
        <Row className='mb-2'>
          <Col className='d-flex justify-content-end'>
            <Button color='success' onClick={() => history.push('/recurring/add/new')} disabled={!hasPermission(RECURRING_JOURNAL_ENTRY, EDIT)}>
              Add New Recurring JL
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <DataGridComponent columns={columns} loading={false} dataSource={tableData?.data || cleanDataSource} onFilterChange={onFilterChange} />
          </Col>
        </Row>
      </CustomCard>
      <CustomPopup
        anchor={deleteData.deleteAnchor}
        content={
          <DeleteDepositPopupContent onConfirm={handleConfirmDeleteDepositClick} onCancel={() => setDeleteData((prev) => ({ deleteAnchor: null }))} />
        }
        arrow
        placement='bottom'
        onClose={() => setDeleteData((prev) => ({ deleteAnchor: null }))}
        open={deleteData.openDeletePopup}
      />
    </>
  );
};

const DeleteDepositPopupContent = ({ onConfirm, onCancel, message = `Are you sure you want to delete this recurring entry?` }) => {
  return (
    <>
      <Box>
        <DialogContent sx={{ padding: '5px' }}>{message}</DialogContent>
        <DialogActions sx={{ padding: '5px' }}>
          <IconButton color='error' onClick={onConfirm}>
            <DeleteForeverOutlined />
          </IconButton>
          <IconButton onClick={onCancel}>
            <ClearOutlined />
          </IconButton>
        </DialogActions>
      </Box>
    </>
  );
};

export default RecurringEntryTable;
