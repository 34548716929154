import { createTheme } from '@mui/material/styles';

export const theme = () => {
  const theme = createTheme({
    palette: {
      label: {
        main: 'rgba(0,0,0, 1)',
      },
      primary: {
        main: '#ceac02',
        light: '#ceac02',
        dark: '#ceac02',
      },
      dark: {
        main: '#5A6268',
        light: '#23272B',
        dark: '#2f353a',
      },
      neutral: {
        main: '#686A6B',
        contrastText: 'white',
      },
      modal: {
        main: '#c1121f',
        dark: '#404040',
        light: '#eaeff1',
      },
      appBar: {
        main: '#eaeff1',
        dark: '#eaeff1',
        sidebar: '#eaeff1',
        footer: '#eaeff1',
        user: '#010c2c',
      },
      background: {
        paper: '#FFF',
      },
    },
    typography: {
      fontFamily: '"Montserrat", sans-serif',
    },
    options: {
      appBar: {
        height: '3.2rem',
      },
      modal: {
        zIndex: 1150,
        overlayPopperZIndex: 1302,
      },
      popper: {
        overlayModalZIndex: 1301,
        zIndex: 10,
        borderRadius: '5px',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '*': {
            margin: 0,
            padding: 0,
            boxSizing: 'inherit',
            '&::before': {
              margin: 0,
              padding: 0,
              boxSizing: 'inherit',
            },
            '&::after': {
              margin: 0,
              padding: 0,
              boxSizing: 'inherit',
            },
          },
          html: {
            boxSizing: 'border-box',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 600,
            color: '#fff',
          },
        },
        defaultProps: {
          // The props to change the default for.
          variant: 'contained',
          size: 'small',
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: '#eaeff1',
            overflowY: 'visible',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            overflow: 'visible',
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            border: '2px solid grey',
          },

          'Mui-active': {
            styleOverrides: {
              root: {
                color: 'white',
              },
            },
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(171, 109, 35, .8)',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            display: 'flex',
            alignItems: 'center',
          },
          head: {
            fontWeight: 600,
            borderRight: '1px solid #dfdfdf',
            color: '#fff',
          },
          body: {
            borderRight: '1px solid #dfdfdf',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          sizeSmall: {
            fontSize: '14px',
          },
          filled: {
            fontSize: '14px !important',
          },
          root: {
            color: 'rgba(0,0,0,.8)',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          inputSizeSmall: {
            fontSize: '14px',
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            color: '#fff !important',
          },
          icon: {
            width: '14px',
            height: '14px',
            margin: '0px',
            fill: '#fff',
          },
        },
      },
    },
  });

  return theme;
};
