import { Button, Divider } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { CustomSelect } from '../../../../../common/CustomSelect';
import { DatePickerComponent } from '../../../../../common/DatePicker/DatePicker';
import { CustomPageableAsyncSelect } from '../../../../../common/Inputs/CustomPageableAsyncSelect';
import { documentTypeOptions, getDocumentType } from '../../../../../constants/documentType.constants';
import { PENDING_TRANSACTIONS_STATUS, TRANSACTION_SOURCE } from '../../../../../constants/supportTables.constants';
import { useGetSupportTablesForSelect } from '../../../../../hooks/commonApiHooks';
import { useAsyncPageable } from '../../../../../hooks/useAsyncPageable';
import { useOperationalUsersOptions } from '../../hooks';

export const PendingTransactionsFilter = ({ filter, onSelectChange, onDateChange, onFilterActionClick }) => {
  const { fetchOptions } = useAsyncPageable();
  const [ready, setReady] = useState(true);

  const loadClientExternalIdOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/client/customer/options-number-for-select', { q: inputValue, ...options });
    return data;
  };

  const loadClientRmiIdOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/client/customer/options-rmi-id-for-select', { q: inputValue, ...options });
    return data;
  };

  const loadDocumentNumberOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/transaction/pending/options-for-select-dn', { q: inputValue, ...options });
    return data;
  };

  const { data: { data: supportTableOptions = {} } = {} } = useGetSupportTablesForSelect({
    table_names: [PENDING_TRANSACTIONS_STATUS, TRANSACTION_SOURCE],
  });

  const { data: { data: userOptions = [] } = {} } = useOperationalUsersOptions();

  const pendingStatusOptions = useMemo(() => {
    if (supportTableOptions[PENDING_TRANSACTIONS_STATUS]) {
      return [{ label: 'All', value: null }, ...supportTableOptions[PENDING_TRANSACTIONS_STATUS]];
    }
  }, [supportTableOptions[PENDING_TRANSACTIONS_STATUS]]);

  const handlerDocumentTypeSelect = (val, e) => {
    const docTypeValue = getDocumentType(val?.value || null) || {};

    if (Object.keys(docTypeValue).length) {
      Object.keys(docTypeValue).forEach((key) => {
        // transactions.propName
        const [, prop] = key.split('.');
        // set dependent prop values [pending_transactions.document_type_journal_entry_id, pending_transactions.document_type_domain_id]
        onSelectChange({ value: docTypeValue[key] }, { name: `pending_transactions.${prop}`, action: e.action });
      });
    }
    // set document_type
    onSelectChange(val, e);
  };

  const forceSelectReset = () => {
    setReady(false);
    setTimeout(() => {
      setReady(true);
    }, 1);
  };

  return (
    <>
      <Row>
        <Col xs={12} md={4} lg={3}>
          {ready ? (
            <CustomPageableAsyncSelect
              label={'Client No.'}
              isClearable
              onChange={onSelectChange}
              loadOptions={loadClientExternalIdOptions}
              name='client.external_id'
              defaultValue={filter['client.external_id'] || null}
            />
          ) : (
            <MemoizedPlaceholder label={'Client No.'} />
          )}
        </Col>
        <Col xs={12} md={4} lg={3}>
          {ready ? (
            <CustomPageableAsyncSelect
              label={'RMI No.'}
              isClearable
              onChange={onSelectChange}
              loadOptions={loadClientRmiIdOptions}
              name='client.rmi_number'
              defaultValue={filter['client.rmi_number'] || null}
            />
          ) : (
            <MemoizedPlaceholder label={'RMI No.'} />
          )}
        </Col>
        <Col xs={12} md={4} lg={3}>
          {ready ? (
            <CustomPageableAsyncSelect
              label='Document Number'
              isClearable
              onChange={onSelectChange}
              loadOptions={loadDocumentNumberOptions}
              name='pending_transactions.document_number'
              defaultValue={filter['pending_transactions.document_number']}
            />
          ) : (
            <MemoizedPlaceholder label={'Document Number'} />
          )}
        </Col>
        <Col xs={12} md={4} lg={3}>
          <CustomSelect
            label='Document Type'
            isClearable
            onChange={handlerDocumentTypeSelect}
            name='document_type'
            defaultValue={filter['document_type'] || null}
            options={documentTypeOptions || []}
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <DatePickerComponent
            showYearDropdown
            name='posting_date_from'
            selected={filter['posting_date_from']}
            onChange={(date) => onDateChange(date, 'posting_date_from')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Posting Date From'
            isClearable
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <DatePickerComponent
            showYearDropdown
            name='posting_date_to'
            selected={filter['posting_date_to']}
            onChange={(date) => onDateChange(date, 'posting_date_to')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Posting Date To'
            isClearable
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <CustomSelect
            name='pending_status'
            label='Pending Status'
            isClearable
            onChange={onSelectChange}
            defaultValue={filter['pending_status'] || null}
            options={pendingStatusOptions || []}
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <CustomSelect
            name='transaction_source.id'
            label='Source'
            isClearable
            onChange={onSelectChange}
            defaultValue={filter['transaction_source.id'] || null}
            options={supportTableOptions[TRANSACTION_SOURCE] || []}
          />
        </Col>
        <Col lg={3} md={4} sm={12}>
          <CustomSelect
            name='pending_transactions.created_by_raw'
            label='Created By'
            isClearable
            onChange={onSelectChange}
            defaultValue={filter['pending_transactions.created_by_raw'] || null}
            options={userOptions || []}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <Divider />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col className='d-flex justify-content-end'>
          <Button
            sx={{ marginRight: '1rem' }}
            color='success'
            onClick={(e) => {
              e.preventDefault();
              e.target.blur();
              forceSelectReset();
              onFilterActionClick('reset');
            }}
          >
            Reset
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.target.blur();
              onFilterActionClick('search');
            }}
          >
            Search
          </Button>
        </Col>
      </Row>
    </>
  );
};

const Placeholder = ({ label }) => {
  return <CustomSelect label={label} onChange={() => {}} name='' defaultValue={null} options={[]} />;
};

const MemoizedPlaceholder = React.memo(Placeholder);
