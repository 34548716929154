import { IconButton, Tooltip, Typography } from '@mui/material';
import { ContentCopy, Delete, Edit } from '@mui/icons-material';
import { formatDateForTable } from '../../../utils/formatDataForTable';

export const journalEntryDraftsColumns = ({ toggleCreateModal, setDraftId, onDeleteClick, onDuplicateClick, setBaseDraftId }) => {
  return [
    {
      id: 'name',
      accessor: 'name',
      Header: 'Drafts Name',
      Cell: (row) => {
        return (
          <Typography
            sx={{ textAlign: 'left', cursor: 'pointer', color: 'green' }}
            onClick={() => {
              toggleCreateModal();
              setDraftId(row.index);
              setBaseDraftId(row.original.id);
            }}
          >
            {row.value}
          </Typography>
        );
      },
    },
    {
      id: 'created_by',
      accessor: 'created_by',
      Header: 'Created By',
    },
    {
      id: 'created_at',
      accessor: 'created_at',
      Header: 'Created On',
      Cell: (row) => {
        return formatDateForTable(row.value);
      },
    },
    {
      id: 'actions',
      sort: false,
      Header: 'Actions',
      accessor: (row) => {
        return (
          <div className='d-flex justify-content-start'>
            <Tooltip title='Delete Draft' placement='top' arrow followCursor>
              <IconButton onClick={() => onDeleteClick(row.id)} color='error'>
                <Delete />
              </IconButton>
            </Tooltip>
            <Tooltip title='Duplicate Draft' placement='top' arrow followCursor>
              <IconButton onClick={() => onDuplicateClick(row.id)} color='success'>
                <ContentCopy />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];
};
