import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { CustomSwitch } from '../../../../../../common/Inputs/CustomSwitch';
import { useBankChecksPreview } from '../../hooks';

export const BankChecksPreview = ({ initFilters }) => {
  const { clientType } = useParams();

  const columns = useMemo(() => {
    return [
      { label: '001', name: 'Constant' },
      { label: '002-004', name: 'Bank Number' },
      { label: '005', name: 'Zero' },
      { label: '006', name: 'Zero' },
      { label: '007-016', name: 'Account Number' },
      { label: '017', name: 'Space' },
      { label: '018', name: 'Transaction Type' },
      { label: '019', name: 'Action Indicator' },
      { label: '020', name: 'Space' },
      { label: '021-030', name: 'Check Serial Number' },
      { label: '031-040', name: 'Check Amount' },
      { label: '041-046', name: 'Issue Date' },
      { label: '047-066', name: 'User Info' },
      { label: '067-194', name: 'Payee' },
    ];
  }, []);

  const splitIndexes = useMemo(() => [1, 4, 5, 6, 16, 17, 18, 19, 20, 30, 40, 46, 66, 194], []);

  const splitStringAtIndexes = useCallback((string, indexes) => {
    return indexes.map((index, i) => string.slice(indexes[i - 1] || 0, index));
  }, []);

  const { data: { data: checkLines = [] } = {} } = useBankChecksPreview({
    clientType,
    filters: { ...initFilters, posted: true },
  });

  const [detailed, setDetailed] = useState(false);

  const lines = useMemo(() => {
    return checkLines.map((line) => {
      return { line, meta: splitStringAtIndexes(line, splitIndexes) };
    });
  }, [checkLines]);

  if (!checkLines.length)
    return (
      <>
        <span>No Posted Checks</span>
        <br />
        <small>Please Check If Bank Account Is Configured</small>
      </>
    );
  return (
    <div style={{ maxHeight: '500px', padding: '15px', transition: '0.3s' }}>
      <Row>
        <Col className='d-flex justify-content-end'>
          <CustomSwitch label='Detailed' name='account.with_no_activity' checked={detailed} onChange={() => setDetailed((p) => !p)} />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          {!detailed && lines.map((line) => <p key={JSON.stringify(line)}>{line.line}</p>)}
          {detailed && (
            <table style={{ fontSize: '10px', border: '1px solid grey' }}>
              <thead style={{ border: '1px solid grey' }}>
                <tr>
                  {columns.map((col) => (
                    <th key={JSON.stringify(col)} title={col.label} abbr={col.name} style={{ border: '1px solid grey' }}>
                      {col.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody style={{ border: '1px solid grey' }}>
                {lines.map((line) => (
                  <tr key={JSON.stringify(line)}>
                    {line.meta.map((part) => (
                      <td style={{ border: '1px solid grey' }}>{part}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Col>
      </Row>
    </div>
  );
};
