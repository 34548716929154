import { useEffect, useState } from 'react';
import {
  CustomerCreditMemoTransactionsDataTableColumns,
  CustomerDebitMemoTransactionsDataTableColumns,
  CustomerInvoiceTransactionsDataTableColumns,
  CustomerPaymentTransactionsDataTableColumns,
  CustomerRefundTransactionsDataTableColumns,
  CustomerTransactionsDataTableColumns,
  VendorCreditMemoTransactionsDataTableColumns,
  VendorDebitMemoTransactionsDataTableColumns,
  VendorInvoiceTransactionsDataTableColumns,
  VendorPaymentTransactionsDataTableColumns,
  VendorRefundTransactionsDataTableColumns,
  VendorTransactionsDataTableColumns,
} from './Client360/component/transaction/clientTransactionsTableColumns';
import { transactionTableTypes } from './constants';

/** row data will be provided in Column functions */
const useClientTableColumns = (clientType, tableType, Actions, Actions2, refetchClientTransactions, filter) => {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setTableColumns(clientType, tableType);
  }, [clientType, tableType]);

  const setTableColumns = (clientType, tableType) => {
    if (clientType.toLowerCase() === 'customers') {
      switch (tableType) {
        case transactionTableTypes.transactions:
          setColumns(CustomerTransactionsDataTableColumns(Actions, Actions2, refetchClientTransactions, filter));
          break;
        case transactionTableTypes.invoices:
          setColumns(CustomerInvoiceTransactionsDataTableColumns(Actions));
          break;
        case transactionTableTypes.payments:
          setColumns(CustomerPaymentTransactionsDataTableColumns(Actions, Actions2));
          break;
        case transactionTableTypes.creditMemos:
          setColumns(CustomerCreditMemoTransactionsDataTableColumns(Actions));
          break;
        case transactionTableTypes.debitMemos:
          setColumns(CustomerDebitMemoTransactionsDataTableColumns(Actions));
          break;
        case transactionTableTypes.refunds:
          setColumns(CustomerRefundTransactionsDataTableColumns(Actions));
          break;
        default:
          setColumns([]);
      }
    } else if (clientType.toLowerCase() === 'vendors') {
      switch (tableType) {
        case transactionTableTypes.transactions:
          setColumns(VendorTransactionsDataTableColumns(Actions, Actions2, refetchClientTransactions, filter));
          break;
        case transactionTableTypes.invoices:
          setColumns(VendorInvoiceTransactionsDataTableColumns(Actions));
          break;
        case transactionTableTypes.payments:
          setColumns(VendorPaymentTransactionsDataTableColumns(Actions, Actions2));
          break;
        case transactionTableTypes.creditMemos:
          setColumns(VendorCreditMemoTransactionsDataTableColumns(Actions));
          break;
        case transactionTableTypes.debitMemos:
          setColumns(VendorDebitMemoTransactionsDataTableColumns(Actions));
          break;
        case transactionTableTypes.refunds:
          setColumns(VendorRefundTransactionsDataTableColumns);
          break;
        default:
          setColumns([]);
      }
    }
  };

  return { columns };
};

export { useClientTableColumns };
