import { SET_PAGINATION } from './PaginationActions';

const initialState = {};

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case SET_PAGINATION:
      const { payload: { target, location } = {} } = action;
      return {
        ...state,
        [target]: location,
      };
    default:
      return state;
  }
}
