import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { authenticationSetUser } from '../../app/redax/Authorization/AuthorizationAction';

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

export const isAuth = (store) => {
  const token = localStorage.legalToken;
  if (token) {
    setAuthToken(token);
    const decoded = jwt_decode(token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp - currentTime < 1) {
      logoutUser(store);
    } else {
      store.dispatch(authenticationSetUser(decoded));
    }
  }
};

export const logoutUser = () => async (dispatch) => {
  localStorage.removeItem('legalToken');
  setAuthToken(false);
  dispatch(authenticationSetUser());
};
