import { DeleteForever, LayersClearOutlined, ModeEditOutline } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Box, Button, DialogActions, DialogContent, IconButton, Tooltip } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { CustomCard } from '../../../../../../common/Card/CustomCard';
import { CustomDialog } from '../../../../../../common/Dialog/CustomDialog';
import { CustomPopup } from '../../../../../../common/Popup/CustomPopup';
import { ExpendGeneralLedgerDataTableComponent } from '../../../../../../component/DataTable/ExpendComponent/ExpendGeneralLedgerDataTableComponent';
import DataGridComponent from '../../../../../../component/DataTable/component/DataTableComponent';
import { downloaderFiles } from '../../../../../../utils/downloaderFiles';
import { columns } from '../../columns/billingPaymentItemColumns';
import { composeBaseUrl, useBillingPaymentItems, useDeleteBillingPaymentItem } from '../../hooks';
import { BillingPaymentItemForm } from './BillingPaymentItemForm';

/**
 *
 * @param {*} disabledActions true If Payment is Posted
 */
export const BillingPaymentItems = ({ clientType, billingPaymentId, disabledActions, billingPayment, setPaymentItems }) => {
  const history = useHistory();
  const blankData = useMemo(() => {
    return { data: [], page: 0, pageSize: 10, pageTotal: 0 };
  }, []);

  const blankFilter = useMemo(() => {
    return {
      start: '',
      end: '',
      page: 0,
      pageSize: 10,
    };
  }, []);

  const [modalOptions, setModalOptions] = useState({
    itemForm: { open: false },
    editItemForm: { open: false },
  });

  const [buttonOptions, setButtonOptions] = useState({
    excel: { disabled: false },
  });

  const [popupOptions, setPopupOptions] = useState({
    deleteOne: { anchor: null, open: false },
  });

  const [filter, setFilter] = useState(blankFilter);
  const [selectedBill, setSelectedBill] = useState(null);
  const [deleteBillingPaymentItemId, setDeleteBillingPaymentItemId] = useState(null);

  const url = useMemo(() => {
    const url = composeBaseUrl(clientType);
    return `${url}/billings/${billingPaymentId}/items`;
  }, [clientType, billingPaymentId]);

  const { data: itemsData } = useBillingPaymentItems(clientType, billingPaymentId, filter);
  const { mutate: deleteBillingPaymentItem } = useDeleteBillingPaymentItem(clientType);

  useEffect(() => {
    if (itemsData?.data) {
      setPaymentItems(itemsData?.data?.data);
    }
  }, [itemsData?.data]);

  const handleModalOptionsChange = (modal, options = {}) => {
    setModalOptions((p) => ({
      ...p,
      [modal]: {
        ...p[modal],
        ...options,
      },
    }));
  };

  const handleModalClose = (modal) => {
    handleModalOptionsChange(modal, { open: false });
  };

  const handleTableFilterChange = (input) => {
    setFilter((prev) => ({ ...prev, ...input }));
  };

  const handlePaymentItemApply = (row) => {
    const id = row['billing_payment_items.id'];
    if (!id) return;

    history.push(`/client/${clientType}/payment/${billingPaymentId}/items/${id}/clearing`);
  };

  const handlePaymentItemEdit = (row) => {
    setSelectedBill(row['billing_payment_items.id']);
    handleModalOptionsChange('editItemForm', { open: true });
  };

  const handlePaymentItemDelete = (e, row) => {
    const anchor = e.currentTarget;
    setDeleteBillingPaymentItemId(row['billing_payment_items.id']);
    setPopupOptions((p) => ({ ...p, deleteOne: { ...p.deleteOne, anchor, open: true } }));
  };

  const handleEditModalClose = () => {
    setSelectedBill(null);
    handleModalClose('editItemForm');
  };

  const handleConfirmDeleteBillingPaymentItemClick = () => {
    if (deleteBillingPaymentItemId) {
      deleteBillingPaymentItem({ id: deleteBillingPaymentItemId, billingId: billingPaymentId });
      setPopupOptions((p) => ({ ...p, deleteOne: { ...p.deleteOne, open: false } }));
      setDeleteBillingPaymentItemId(null);
    }
  };

  const handleCancelDeleteBillingPaymentItemClick = () => {
    setPopupOptions((p) => ({ ...p, deleteOne: { ...p.deleteOne, open: false } }));
    setDeleteBillingPaymentItemId(null);
  };

  const handleItemFormModalOpen = () => {
    handleModalOptionsChange('itemForm', { open: true });
  };

  const handleExcelExportButtonClick = (e) => {
    setButtonOptions((prevValue) => ({
      ...prevValue,
      excel: {
        ...prevValue.excel,
        disabled: true,
      },
    }));

    e.preventDefault();
    e.target.blur();
    onFilterActionBtn('EXCEL');
  };

  const resetExcelButton = () => {
    setButtonOptions((prevValue) => ({
      ...prevValue,
      excel: {
        ...prevValue.excel,
        disabled: false,
      },
    }));
  };

  const onFilterActionBtn = (e) => {
    if (e.toString() === 'PDF' || e.toString() === 'EXCEL') {
      downloaderFiles({ filter, type: e, url, cbLoad: resetExcelButton, name: 'Payment Items' });
    }
  };

  const SubComponentGl = ({ original }) => {
    return <ExpendGeneralLedgerDataTableComponent gls={original?.gls || []} detailedGls={original?.detailed_gls || []} />;
  };

  const deleteOnePopup = useMemo(() => {
    return (
      <CustomPopup
        anchor={popupOptions.deleteOne.anchor}
        content={
          <MemoizedDeleteBillingPaymentItemPopupContent
            onConfirm={handleConfirmDeleteBillingPaymentItemClick}
            onCancel={handleCancelDeleteBillingPaymentItemClick}
          />
        }
        arrow
        placement='bottom'
        onClose={handleCancelDeleteBillingPaymentItemClick}
        open={popupOptions.deleteOne.open}
      />
    );
  }, [popupOptions.deleteOne]);

  const itemFormModal = useMemo(() => {
    return (
      <CustomDialog
        fullWidth
        isOpen={modalOptions.itemForm.open}
        actionButtonDisabled
        disableEscapeKeyDown
        disableBackdropClick
        hideActions
        hideTitleButton
        contentStyle={{ overflowY: 'visible' }}
        modalSize={'xl'}
        modalTitle={'Payment Item'}
        onClose={() => handleModalClose('itemForm')}
      >
        <BillingPaymentItemForm
          defaultPostingDate={billingPayment.posting_date}
          closeModal={() => handleModalClose('itemForm')}
          billingId={billingPaymentId}
          disabled={disabledActions}
        />
      </CustomDialog>
    );
  }, [modalOptions.itemForm.open, billingPaymentId, disabledActions]);

  const editItemForm = useMemo(() => {
    return (
      <CustomDialog
        fullWidth
        isOpen={modalOptions.editItemForm.open}
        actionButtonDisabled
        disableEscapeKeyDown
        disableBackdropClick
        hideActions
        hideTitleButton
        contentStyle={{ overflowY: 'visible' }}
        modalSize={'xl'}
        modalTitle={'Update Payment Item'}
        onClose={handleEditModalClose}
      >
        <BillingPaymentItemForm
          closeModal={handleEditModalClose}
          id={selectedBill}
          isEdit
          billingId={billingPaymentId}
          defaultPostingDate={billingPayment.posting_date}
        />
      </CustomDialog>
    );
  }, [modalOptions.editItemForm, selectedBill, billingPaymentId]);

  return (
    <>
      {deleteOnePopup}
      {itemFormModal}
      {editItemForm}
      <CustomCard
        headerTitle={
          <div className='d-flex justify-content-between align-items-center'>
            <span>Payment Items</span>
            <span>
              <Tooltip title='Export To EXCEL' arrow>
                <IconButton disabled={buttonOptions.excel.disabled} onClick={handleExcelExportButtonClick}>
                  <i
                    className='fas fa-file-excel'
                    style={{ color: buttonOptions.excel.disabled ? 'grey' : '#ceac02', fontSize: '1.2rem', width: '20px', height: '20px' }}
                  />
                </IconButton>
              </Tooltip>
            </span>
          </div>
        }
      >
        <Row className='mb-3'>
          <Col className='d-flex justify-content-end'>
            <Button color='success' onClick={handleItemFormModalOpen} disabled={disabledActions}>
              Add New Item
            </Button>
          </Col>
        </Row>
        <div>
          <DataGridComponent
            dataSource={itemsData?.data || blankData}
            onFilterChange={handleTableFilterChange}
            columns={columns({
              Actions: (
                <MemoizedBillingPaymentItemsActions
                  disabled={disabledActions}
                  onApply={handlePaymentItemApply}
                  onEdit={handlePaymentItemEdit}
                  onDelete={handlePaymentItemDelete}
                />
              ),
            })}
            SubComponent={disabledActions ? SubComponentGl : null}
          />
        </div>
      </CustomCard>
    </>
  );
};

const BillingPaymentItemsActions = ({ row, onApply, onEdit, onDelete, disabled }) => {
  return (
    <>
      <Row>
        <Tooltip arrow title='Apply'>
          <span>
            <IconButton onClick={() => onApply(row)} disabled={disabled} color='success'>
              <LayersClearOutlined fontSize='small' />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip arrow title='Edit'>
          <span>
            <IconButton onClick={() => onEdit(row)} disabled={disabled} color='warning'>
              <ModeEditOutline fontSize='small' />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip arrow title='Cancel'>
          <span>
            <IconButton onClick={(e) => onDelete(e, row)} disabled={disabled} color='error'>
              <RemoveCircleOutlineIcon fontSize='small' />
            </IconButton>
          </span>
        </Tooltip>
      </Row>
    </>
  );
};

const MemoizedBillingPaymentItemsActions = React.memo(BillingPaymentItemsActions);

const DeleteBillingPaymentItemPopupContent = ({ onConfirm, onCancel, message = `Confirm payment removal`, disabled }) => {
  return (
    <>
      <Box>
        <DialogContent sx={{ padding: '5px' }}>{message}</DialogContent>
        <DialogActions sx={{ padding: 0 }}>
          <IconButton color='primary' onClick={onConfirm} disabled={disabled}>
            <DeleteForever fontSize='small' />
          </IconButton>
          <IconButton onClick={onCancel}>
            <ClearIcon fontSize='small' />
          </IconButton>
        </DialogActions>
      </Box>
    </>
  );
};

const MemoizedDeleteBillingPaymentItemPopupContent = React.memo(DeleteBillingPaymentItemPopupContent);
