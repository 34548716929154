import React from 'react';
import { Row, Col } from 'reactstrap';
import { Button, Divider, Paper, ToggleButton, ToggleButtonGroup } from '@mui/material';

import { CustomSelect } from '../../../../common/CustomSelect';
import { DatePickerComponent } from '../../../../common/DatePicker/DatePicker';
import { CustomCurrencyInput } from '../../../../common/Inputs/CurrencyInput';
import { CustomPageableAsyncSelect } from '../../../../common/Inputs/CustomPageableAsyncSelect';
import { amountOptions, getCurrencyInputName } from './selectOptionsAndMappers';
import { useAsyncPageable } from '../../../../hooks/useAsyncPageable';

export const ReportFilters = ({ bankRecoData, setTableFilters, tableFilters }) => {
  const [commonFilters, setCommonFilters] = React.useState({
    shouldApplyToTable: '1',
    amountType: {
      type: null,
      amountEqual: null,
      amountLess: null,
      amountGreater: null,
      amountFrom: null,
      amountTo: null,
    },
    dateFrom: null,
    dateTo: null,
    check: null,
    customer: null,
  });
  const [selectKeys, setSelectKeys] = React.useState({ check: Date.now(), deposit: Date.now() + 1 });
  const { fetchOptions } = useAsyncPageable();

  const loadCheckOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/bank/bank-reconciliation/get-checks-for-select', {
      q: inputValue,
      ...options,
      gls_ids: [...bankRecoData.deposits_ids, ...bankRecoData.checks_ids],
    });

    return data;
  };

  const loadCustomersOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/bank/bank-reconciliation/get-customers-for-select', {
      q: inputValue,
      ...options,
      gls_ids: [...bankRecoData.deposits_ids, ...bankRecoData.checks_ids],
    });

    return data;
  };

  const currencyInputName = React.useMemo(() => {
    setCommonFilters((oldFilters) => ({
      ...oldFilters,
      amountType: { ...oldFilters.amountType, amountEqual: null, amountLess: null, amountGreater: null, amountFrom: null, amountTo: null },
    }));
    if (commonFilters.amountType.type) {
      return getCurrencyInputName(commonFilters.amountType.type)[0];
    } else {
      return '';
    }
  }, [commonFilters.amountType.type]);

  const onChangeAmountSelect = (value, e) => {
    setCommonFilters((oldFilters) => ({ ...oldFilters, amountType: { ...oldFilters.amountType, type: value ? value.value : null } }));
  };

  const handleCurrencyInputChange = (value, name) => {
    setCommonFilters((oldFilters) => ({ ...oldFilters, amountType: { ...oldFilters.amountType, [name]: value } }));
  };

  const handleDateChange = (date, name) => {
    setCommonFilters((oldFilters) => ({ ...oldFilters, [name]: date }));
  };

  const handleChangeButtonGroup = (e) => {
    const { value } = e.target;
    setCommonFilters((oldFilters) => ({ ...oldFilters, shouldApplyToTable: value }));
  };

  const onChangeAsyncSelect = (value, e) => {
    const { name } = e;
    setCommonFilters((oldFilters) => ({ ...oldFilters, [name]: value ? value.value : null }));
  };

  const onSearchClick = () => {
    let checksTableFilters = { ...tableFilters.checksAndPayments.reportFilters };
    let depositsTableFilters = { ...tableFilters.depositAndOtherCredits.reportFilters };

    const preparedCommonFilters = {
      amountEqual: commonFilters.amountType.amountEqual,
      amountLess: commonFilters.amountType.amountLess,
      amountGreater: commonFilters.amountType.amountGreater,
      amountFrom: commonFilters.amountType.amountFrom,
      amountTo: commonFilters.amountType.amountTo,
      dateFrom: commonFilters.dateFrom,
      dateTo: commonFilters.dateTo,
      check: commonFilters.check,
      customer: commonFilters.customer,
    };

    if (+commonFilters.shouldApplyToTable === 1) {
      checksTableFilters = {
        ...checksTableFilters,
        ...preparedCommonFilters,
      };
      depositsTableFilters = {
        ...depositsTableFilters,
        ...preparedCommonFilters,
      };
      setTableFilters((oldFilters) => ({
        checksAndPayments: {
          ...oldFilters.checksAndPayments,
          reportFilters: { ...oldFilters.checksAndPayments.reportFilters, ...checksTableFilters },
        },
        depositAndOtherCredits: {
          ...oldFilters.depositAndOtherCredits,
          reportFilters: { ...oldFilters.depositAndOtherCredits.reportFilters, ...depositsTableFilters },
        },
      }));
    } else if (+commonFilters.shouldApplyToTable === 2) {
      depositsTableFilters = {
        ...depositsTableFilters,
        ...preparedCommonFilters,
      };
      setTableFilters((oldFilters) => ({
        ...oldFilters,
        depositAndOtherCredits: {
          ...oldFilters.depositAndOtherCredits,
          reportFilters: { ...oldFilters.depositAndOtherCredits.reportFilters, ...depositsTableFilters },
        },
      }));
    } else {
      checksTableFilters = {
        ...checksTableFilters,
        ...preparedCommonFilters,
      };
      setTableFilters((oldFilters) => ({
        ...oldFilters,
        checksAndPayments: {
          ...oldFilters.checksAndPayments,
          reportFilters: { ...oldFilters.checksAndPayments.reportFilters, ...checksTableFilters },
        },
      }));
    }
  };

  const onResetFilters = () => {
    setCommonFilters({
      shouldApplyToTable: '1',
      amountType: {
        type: null,
        amountEqual: null,
        amountLess: null,
        amountGreater: null,
        amountFrom: null,
        amountTo: null,
      },
      dateFrom: null,
      dateTo: null,
      check: null,
      customer: null,
    });
    setTableFilters((oldFilters) => ({
      ...oldFilters,
      depositAndOtherCredits: {
        ...oldFilters.depositAndOtherCredits,
        reportFilters: { deposits_ids: oldFilters.depositAndOtherCredits.reportFilters.deposits_ids },
      },
      checksAndPayments: {
        ...oldFilters.checksAndPayments,
        reportFilters: { checks_ids: oldFilters.checksAndPayments.reportFilters.checks_ids },
      },
    }));
    setSelectKeys({ check: Date.now(), deposit: Date.now() + 1 });
  };

  return (
    <>
      <Row>
        <Col>
          <Paper elevation={2} sx={{ p: 1 }}>
            Apply filters to:
            <ToggleButtonGroup
              color='primary'
              value={commonFilters.shouldApplyToTable}
              exclusive
              onChange={handleChangeButtonGroup}
              size='small'
              sx={{ ml: 2 }}
            >
              <ToggleButton value={'1'} sx={{ fontWeight: 'bold' }}>
                Both Tables
              </ToggleButton>
              <ToggleButton value={'2'} sx={{ fontWeight: 'bold' }}>
                Deposits Table
              </ToggleButton>
              <ToggleButton value={'3'} sx={{ fontWeight: 'bold' }}>
                Checks Table
              </ToggleButton>
            </ToggleButtonGroup>
          </Paper>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col md='3'>
          <Paper elevation={2} sx={{ p: 1 }}>
            <CustomSelect
              options={amountOptions}
              defaultValue={commonFilters.amountType.type}
              onChange={onChangeAmountSelect}
              label='Amount'
              isClearable
            />
            {commonFilters.amountType.type && (
              <CustomCurrencyInput
                key={currencyInputName}
                formClassName='m-0'
                name={currencyInputName}
                value={commonFilters.amountType[currencyInputName]}
                onChange={handleCurrencyInputChange}
                prefix={'$ '}
              />
            )}
            {commonFilters.amountType.type === 4 && (
              <CustomCurrencyInput
                key={'amountTo'}
                formClassName='mt-1'
                name={'amountTo'}
                value={commonFilters.amountType['amountTo']}
                onChange={handleCurrencyInputChange}
                prefix={'$ '}
              />
            )}
          </Paper>
        </Col>
        <Col md='3'>
          <Paper elevation={2} sx={{ p: 1 }}>
            <DatePickerComponent
              onChange={(date) => handleDateChange(date, 'dateFrom')}
              showYearDropdown
              isClearable
              name='dateFrom'
              selected={commonFilters.dateFrom}
              placeholder='mm/dd/yyyy'
              dateFormat={'MM/dd/yyyy'}
              label='Date From'
            />
            <DatePickerComponent
              onChange={(date) => handleDateChange(date, 'dateTo')}
              showYearDropdown
              isClearable
              name='dateTo'
              selected={commonFilters.dateTo}
              placeholder='mm/dd/yyyy'
              dateFormat={'MM/dd/yyyy'}
              label='Date To'
            />
          </Paper>
        </Col>
        <Col md='3'>
          <Paper elevation={2} sx={{ p: 1 }}>
            <CustomPageableAsyncSelect
              key={selectKeys.check}
              label='Check#'
              isClearable
              onChange={onChangeAsyncSelect}
              loadOptions={loadCheckOptions}
              name='check'
              defaultValue={commonFilters['check']}
            />
          </Paper>
        </Col>
        <Col md='3'>
          <Paper elevation={2} sx={{ p: 1 }}>
            <CustomPageableAsyncSelect
              key={selectKeys.customer}
              label='Customer'
              isClearable
              onChange={onChangeAsyncSelect}
              loadOptions={loadCustomersOptions}
              name='customer'
              defaultValue={commonFilters['customer']}
            />
          </Paper>
        </Col>
      </Row>
      <Row>
        <Col>
          <Divider sx={{ my: 2 }} />
        </Col>
      </Row>
      <Row>
        <Col className='d-flex align-items-center justify-content-between'>
          <Button color='error' onClick={onResetFilters}>
            Reset Filters
          </Button>
          <Button color='primary' onClick={onSearchClick}>
            Search
          </Button>
        </Col>
      </Row>
    </>
  );
};
