import { Button, IconButton, styled, Tooltip } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row, Button as RsButton } from 'reactstrap';
import { CollapsableCard } from '../../../../../common/Card/CollapsableCard';
import { useCustomModalToggle } from '../../../../../common/Dialog/CustomDialog';
import { ACH as BANK_ACH, COMPUTER_CHECK } from '../../../../../constants/bankPaymentSource.constants';
import { ACH, CHECK } from '../../../../../constants/paymentTypeSource.constants';
import { notificationDanger, notificationDangerCustomTime } from '../../../../../utils/toastify';
import { useBillingPayment, useBillingPaymentPosting, useHasPrintedChecks, useNachaDownload } from '../hooks';
import { BillingPaymentForm } from './BillingPaymentForm';
import { BillingPaymentChecks } from './checks/BillingPaymentChecks';
import { BillingPaymentClients } from './clients/BillingPaymentClients';
import { BillingPaymentItems } from './items/BillingPaymentItems';
import { NachaModal } from './NachaModal';
import { hasPermissionHook } from '../../../../../hooks/hasPermission';
import { useSelector } from 'react-redux';
import { PaginationKeys } from '../../../../redax/Pagination/PaginationActions';

const StyledButton = styled(Button)(() => ({
  '&.Mui-disabled': {
    backgroundColor: '#9b8632',
    cursor: 'pointer',
  },
}));

export const BillingPayment = () => {
  const { isPostingDisabled } = hasPermissionHook();
  const { billingId, clientType } = useParams();
  const history = useHistory();
  const { billingPaymentsLocation } = useSelector((state) => ({
    billingPaymentsLocation: state.pagination[PaginationKeys.BillingPayments],
  }));

  const [paymentItems, setPaymentItems] = useState([]);

  const { open: isOpen, toggleCustomModal: toggleModal } = useCustomModalToggle();

  const { data: { data: billingPayment = {} } = {} } = useBillingPayment(clientType, billingId);

  const { data: { data: { has_printed_checks: hasPrintedChecks = false } = {} } = {} } = useHasPrintedChecks(clientType, billingId);

  const isNachaDownloadDisabled = useMemo(() => {
    return billingPayment?.payment_source !== ACH && billingPayment?.bank_payment_source !== BANK_ACH;
  }, [billingPayment]);

  const isCheckPayment = useMemo(() => {
    return billingPayment.bank_payment_source === COMPUTER_CHECK && billingPayment.payment_source === CHECK;
  }, [billingPayment]);

  const postingDisabledMessage = useMemo(() => {
    const isAllowed = +billingPayment?.amount === +billingPayment?.billing_items_amount;

    if (!isAllowed) {
      return 'Not Allowed! Total Payment Lines must be equal to Total Payment Amount';
    }

    if (billingPayment?.finished) {
      return 'Payment Has Been Posted Already';
    }

    if (isCheckPayment && !hasPrintedChecks) {
      return 'Please Print Checks Before Posting';
    }

    return '';
  }, [billingPayment, isCheckPayment, hasPrintedChecks]);

  const {
    mutate: postBillingPayment,
    error: errorPosting,
    isLoading: postingInProgress,
  } = useBillingPaymentPosting({
    clientType,
    options: { postPrintedOnly: isCheckPayment && hasPrintedChecks },
    callbacks: {
      successCallback: () => {
        history.push(`/client/${clientType}/payment`);
      },
      errorCallback: (error) => {
        notificationDangerCustomTime(error, 10000);
      },
    },
  });

  const { mutate: downloadNachaFile } = useNachaDownload({ billingId, clientType, filters: {} });

  const handleBackClick = () => {
    let url = `/client/${clientType}/payment`;
    if (billingPaymentsLocation) {
      const { pathname, search } = billingPaymentsLocation;
      url = `${pathname}${search}`;
    }
    history.push(url);
  };

  const handleCheckPostClick = (row) => {
    if (isPostingDisabled([billingPayment?.posting_date])) {
      notificationDangerCustomTime('You are not allowed to post in this period. Please contact your superior for more info!', 10000);
      return;
    }

    if (
      +billingPayment?.amount !== +billingPayment?.billing_items_amount ||
      billingPayment?.billing_items_amount === 0 ||
      billingPayment?.amount === 0
    ) {
      return notificationDanger('Not Allowed!');
    }

    let data = { id: billingId };

    if (isCheckPayment) {
      data.checkData = {
        posting_date: billingPayment.posting_date,
        posted: true,
      };
    }

    postBillingPayment(data);
  };

  const handleNachaDownload = () => {
    downloadNachaFile({});
  };

  const headerTitle = useMemo(() => {
    const isAllowed = +billingPayment?.amount === +billingPayment?.billing_items_amount;
    return (
      <>
        <Row>
          <Col className='d-flex align-items-center justify-content-between mr-3'>
            <span className='m-0'>Payment Info</span>
            <Row>
              <Col className='d-flex justify-content-end'>
                {billingPayment?.finished && (
                  <span className='mr-2'>
                    <Tooltip arrow title={`Download NACHA File`}>
                      <IconButton onClick={toggleModal} disabled={isNachaDownloadDisabled}>
                        <i
                          className='far fa-file-alt'
                          style={{
                            color: !isNachaDownloadDisabled && billingPayment?.finished ? '#ceac02' : 'grey',
                            fontSize: '1.2rem',
                            width: '20px',
                            height: '20px',
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </span>
                )}
                <Tooltip title={postingDisabledMessage} placement='top' arrow followCursor>
                  <div>
                    <StyledButton
                      onClick={handleCheckPostClick}
                      disabled={postingInProgress || billingPayment?.finished || (isCheckPayment && !hasPrintedChecks)}
                    >
                      Post Payment
                    </StyledButton>
                  </div>
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }, [postingInProgress, billingPayment, hasPrintedChecks, handleCheckPostClick, handleNachaDownload, isNachaDownloadDisabled]);

  return (
    <>
      <NachaModal isOpen={isOpen} toggleModal={toggleModal} />
      <RsButton className='btn btn-sm mb-2' onClick={handleBackClick}>
        <i className='fas fa-angle-left mr-2'></i>Go Back To Payments Table
      </RsButton>

      <Row className='mt-3'>
        <Col>
          <CollapsableCard
            headerTitle={headerTitle}
            titleTypographyProps={{ fontSize: '18px' }}
            sxCardContent={{ padding: '16px' }}
            sxCardHeader={{ padding: '16px' }}
            isExpanded={false}
          >
            <BillingPaymentForm id={billingId} isEdit />
          </CollapsableCard>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col>
          <BillingPaymentItems
            billingPaymentId={billingId}
            billingPayment={billingPayment}
            clientType={clientType}
            disabledActions={billingPayment?.finished}
            setPaymentItems={setPaymentItems}
          />
        </Col>
      </Row>
      {!billingPayment?.finished && (
        <Row>
          <Col>
            <BillingPaymentClients />
          </Col>
        </Row>
      )}
      {isCheckPayment && (
        <Row className='mt-3'>
          <Col>
            <BillingPaymentChecks billingChecks />
          </Col>
        </Row>
      )}
    </>
  );
};
