import React from 'react';
import { useTransactionPdfPreview } from './clientTransactionsHooks';

//* display SSR-ed pdf template
const TransactionPdfPreview = ({ transactionId, clientId, clientType, transactionType }) => {
  const { data } = useTransactionPdfPreview(transactionId, clientId, clientType, transactionType);

  if (!data?.data) return <>No Preview Available</>;

  return <div dangerouslySetInnerHTML={{ __html: data.data }} style={{ margin: '2rem' }} />;
};

export { TransactionPdfPreview };
