export const PAYMENT_TYPE_TABLE = 'payment_source_type';
export const INVOICE_TYPE_TABLE = 'invoice_type';
export const BRANCH_TABLE = 'branch';
export const TAX_ITEM_TABLE = 'tax_item';
export const TAX_CODE_TABLE = 'tax_code';
export const PAYMENT_TERM_TABLE = 'payment_term';
export const DEPOSIT_CATEGORIES_TABLE = 'deposit_categories';
export const TRANSACTION_TYPES = 'transaction_types';
export const PAYMENT_SOURCE = 'payment_source';
export const BANK_PAYMENT_SOURCE = 'bank_payment_source';
export const IRS_1099_CODE = 'irs_1099_code';
export const VENDOR_TYPE = 'vendor_type';
export const CLIENT_TYPE = 'client_type';
export const BUSINESS_ENTITY_TYPES = 'business_entity_types';
export const LLC_TYPES = 'llc_types';
export const PENDING_TRANSACTIONS_STATUS = 'pending_transactions_status';
export const TRANSACTION_SOURCE = 'transaction_source';
export const VENDOR_BANK_ACCOUNT_TYPE = 'vendor_bank_account_type';

export const supportTableOptions = [
  { label: 'Payment Source', value: PAYMENT_SOURCE },
  { label: 'Payment Type', value: PAYMENT_TYPE_TABLE },
  { label: 'Invoice Type', value: INVOICE_TYPE_TABLE },
  { label: 'Branch', value: BRANCH_TABLE },
  { label: 'Tax Item', value: TAX_ITEM_TABLE },
  { label: 'Tax Code', value: TAX_CODE_TABLE },
  { label: 'Payment Term', value: PAYMENT_TERM_TABLE },
  { label: 'Deposit Category', value: DEPOSIT_CATEGORIES_TABLE },
  { label: 'Transaction Types', value: TRANSACTION_TYPES },
  { label: 'IRS 1099 Code', value: IRS_1099_CODE },
  { label: 'Vendor Type', value: VENDOR_TYPE },
  { label: 'Business Entity Types', value: BUSINESS_ENTITY_TYPES },
  { label: 'LLC Types', value: LLC_TYPES },
  { label: 'Vendor Bank Account Type', value: VENDOR_BANK_ACCOUNT_TYPE },
];
