import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { CollapsableCard } from '../../../../../../../common/Card/CollapsableCard';
import { CustomCard } from '../../../../../../../common/Card/CustomCard';
import ExportButton from '../../../../../../../component/Buttons/ExportButton';
import TransactionsDataTable from '../../../../../../../component/DataTable/TransactionsDataTable';
import { downloaderFiles } from '../../../../../../../utils/downloaderFiles';
import { exportExcel } from '../../../../../../../utils/exportExcel';
import { exportPdf } from '../../../../../../../utils/exportPdf';
import { transactionTableTypes } from '../../../../constants';
import { useClientTableColumns } from '../../../../useClientTableColumns';
import { ClientTransactionsFilter } from '../ClientTransactionsFilter';
import { useClientDebitMemo, useSendTransactionEmail, useTransactionPdfExporter } from '../clientTransactionsHooks';
import { clientDebitMemoTransactionsExcelHeader } from '../excelHeaders';
import { TransactionTableActions } from '../TransactionTableActions';
import { emailSendErrorCallback, emailSendSuccessCallback, pdfExportErrorCallback } from '../utils/pdfAndEmailCallbackUtils';

export default function ClientDebitMemoTransactionComponent() {
  const { clientType, clientId } = useParams();

  const transactionType = useMemo(() => 'Debit Memo', []);
  const blankFilter = useMemo(() => {
    return {
      'client.external_id': null,
      'client.rmi_number': null,
      'transactions.document_number': null,
      posting_date_from: null,
      posting_date_to: null,
      invoice_date_from: null,
      invoice_date_to: null,
      start: '',
      end: '',
      page: 0,
      pageSize: 10,
      sort_by: 'transactions.id',
      order_by: 'DESC',
    };
  }, []);

  const blankData = useMemo(() => {
    return { data: [], page: 0, pageSize: 10, pageTotal: 0 };
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  const [filter, setFilter] = useState(blankFilter);
  const [tableData, setTableData] = useState(blankData);
  const [loader, setLoader] = useState(false);
  const [container, setContainer] = useState();

  const url = useMemo(
    () => '/me/client/' + clientType.toLowerCase().slice(0, -1) + '/' + clientId + '/transactions/debit-memo',
    [clientType, clientId]
  );

  const { data: clientDebitMemoData, isLoading, refetch } = useClientDebitMemo(clientId, clientType, filter);

  const { mutate: sendTransactionEmail } = useSendTransactionEmail(clientId, clientType, transactionType, {
    successCallback: () => emailSendSuccessCallback({ enqueueSnackbar }),
    errorCallback: () => emailSendErrorCallback({ enqueueSnackbar }),
  });
  const { mutate: exportTransactionPdf } = useTransactionPdfExporter(clientId, clientType, transactionType, {
    errorCallback: () => pdfExportErrorCallback({ enqueueSnackbar }),
  });

  const { columns: tableColumns } = useClientTableColumns(
    clientType,
    transactionTableTypes.debitMemos,
    <TransactionTableActions
      exportPdf={exportTransactionPdf}
      sendEmail={sendTransactionEmail}
      clientId={clientId}
      clientType={clientType}
      transactionType={transactionType}
    />
  );

  useEffect(() => {
    if (clientDebitMemoData?.status === 200 && clientDebitMemoData?.data) {
      setTableData(clientDebitMemoData.data);
    }
  }, [clientDebitMemoData]);

  useEffect(() => {
    setLoader(isLoading);
  }, [isLoading]);

  const onFilterHandler = (e) => {
    try {
      if (e.target.value.constructor === {}.constructor) {
        const f = { ...filter, ...e.target.value };
        setFilter(f);
      } else {
        const f = { ...filter, [e.target.name]: e.target.value };
        setFilter(f);
      }
    } catch (error) {
      const f = { ...filter, [e.target.name]: e.target.value };
      setFilter(f);
    }
  };

  const onFilterChange = (input) => {
    const f = { ...filter, ...input };
    setFilter(f);
  };

  const onFilterActionBtn = (e) => {
    if (e.toString() === 'PDF' || e.toString() === 'EXCEL') {
      downloaderFiles({ filter, type: e, url, cbLoad: setLoader, name: 'Client Transactions' });
    }
    if (e.toString() === 'reset') setFilter(blankFilter);
    if (e.toString() === 'add') onFormToggle(true);
    if (e.toString() === 'search') refetch();
  };

  const handleChange = (e) => {
    try {
      if (e.target.value.constructor === {}.constructor) {
        setFilter((prev) => ({ ...prev, ...e.target.value }));
      } else {
        setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      }
    } catch (error) {
      setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const handleSelectChange = (val, e) => {
    if (e.action === 'select-option') {
      let o = { target: { name: e.name, value: val.value || val.id } };
      handleChange(o);
      return;
    }
    if (e.action === 'clear') {
      let o = { target: { name: e.name, value: null } };
      handleChange(o);
    }
  };

  const handleDateChange = (date, name) => {
    setFilter((prev) => ({
      ...prev,
      [name]: date,
    }));
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <CustomCard
            headerTitle={
              <div className='d-flex align-items-center justify-content-between'>
                <span>Debit Memos</span>
                <ExportButton
                  disabled={loader}
                  exportTablePDF={() => {
                    onFilterActionBtn('PDF');
                  }}
                  exportTableEXCEL={() => {
                    onFilterActionBtn('EXCEL');
                  }}
                  exportPagePDF={() => exportPdf(container, 'Client-' + clientId + '- DebitMemoTransactions')}
                  exportPageEXCEL={() =>
                    exportExcel(tableData.data, clientDebitMemoTransactionsExcelHeader, 'Client-' + clientId + '-DebitMemoTransactions')
                  }
                />
              </div>
            }
            sxCardContainer={{ mt: 2 }}
          >
            <Row className='mb-3'>
              <Col md={12}>
                <CollapsableCard
                  headerTitle='Filters'
                  isExpanded={false}
                  titleTypographyProps={{ fontSize: '18px' }}
                  sxCardContent={{ padding: '16px' }}
                  sxCardHeader={{ padding: '16px' }}
                >
                  <ClientTransactionsFilter
                    loader={loader}
                    clientType={clientType}
                    filter={filter}
                    blankFilter={blankFilter}
                    onFilterActionClick={onFilterActionBtn}
                    onFilterChangeHandler={onFilterHandler}
                    onChange={handleChange}
                    onSelectChange={handleSelectChange}
                    onDateChange={handleDateChange}
                    transactionType={transactionTableTypes.debitMemos}
                  />
                </CollapsableCard>
              </Col>
            </Row>
            <div
              className='row'
              ref={(instance) => {
                setContainer(instance);
              }}
            >
              <Col>
                <TransactionsDataTable loader={loader} dataSource={tableData} onFilterChange={onFilterChange} columns={tableColumns} />
              </Col>
            </div>
          </CustomCard>
        </Col>
      </Row>
    </>
  );
}
