import { savePDF } from '@progress/kendo-react-pdf';
import ReactDOM from 'react-dom';

export const exportPdf = (container, name) => {
  return savePDF(ReactDOM.findDOMNode(container), {
    scale: 0.4,
    margin: '0.5cm',
    paperSize: 'A4',
    fileName: `${name} ${new Date().getFullYear()}`,
  });
};
