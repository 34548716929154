import React from 'react';
import { Input, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import PropTypes from 'prop-types';

function CustomInput({
  onChange,
  onClick,
  name,
  label,
  value,
  type,
  placeholder,
  autoComplete,
  readOnly,
  invalid,
  errorMess,
  error,
  className,
  disabled,
  formClassName,
  style,
  min,
  max,
  prependAddon,
  rows,
  infoText,
  size,
  onKeyPress,
}) {
  return (
    <FormGroup className={formClassName}>
      {label ? <Label>{label}</Label> : null}
      <InputGroup>
        {prependAddon && (
          <InputGroupAddon addonType='prepend'>
            <InputGroupText style={{ padding: '0 5px' }}>{prependAddon}</InputGroupText>
          </InputGroupAddon>
        )}
        <Input
          size={size || 'md'}
          rows={rows}
          min={min}
          max={max}
          style={style}
          className={className}
          onChange={onChange}
          name={name}
          readOnly={readOnly}
          value={value}
          type={type}
          placeholder={placeholder}
          autoComplete={autoComplete}
          invalid={invalid}
          disabled={disabled}
          onClick={onClick}
          onKeyPress={onKeyPress}
        />
      </InputGroup>
      <small className={infoText ? 'text-muted' : 'text-danger'}>
        {errorMess || (error && error[name] && error[name].errorMessage)}
        {!errorMess && infoText ? infoText : ''}
      </small>
    </FormGroup>
  );
}

CustomInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  readOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  removeLabel: PropTypes.bool,
  autoComplete: PropTypes.string,
  errorMess: PropTypes.string,
  error: PropTypes.object,
  className: PropTypes.string,
  infoText: PropTypes.string,
};

CustomInput.defaultProps = {
  readOnly: false,
  type: 'text',
};

export { CustomInput };
