import { Box, Button } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { CollapsableCard } from '../../../../common/Card/CollapsableCard';
import { CustomCard } from '../../../../common/Card/CustomCard';
import { CustomInput } from '../../../../common/CustomInput';
import { useCustomModalToggle } from '../../../../common/Dialog/CustomDialog';
import { CustomModal } from '../../../../common/Dialog/CustomModal';
import { CustomCheckbox } from '../../../../common/Inputs/CustomCheckbox';
import { Loader } from '../../../../common/Loader';
import ExportButton from '../../../../component/Buttons/ExportButton';
import { APPROVED, DECLINED, PENDING } from '../../../../constants/pendingTransactionsStatus.constants';
import { EDIT, PENDING_TRANSACTIONS } from '../../../../constants/permissions.constants';
import { hasPermissionHook } from '../../../../hooks/hasPermission';
import { downloaderFiles } from '../../../../utils/downloaderFiles';
import { exportPdf } from '../../../../utils/exportPdf';
import { useGetAllPendingIds, usePendingTransactions, usePendingTransactionsStatusChanges } from '../hooks';
import { PendingTransactionsDataTableColumns } from './components/PendingTransactionsColumns';
import { PendingTransactionsFilter } from './components/PendingTransactionsFilter';
import { PendingTransactionsDataTable } from './components/PendingTransactionsTable';
import { PendingTransactionsTableActions } from './components/PendingTransactionsTableActions';
import { notificationDangerCustomTime } from '../../../../utils/toastify';

export const PendingTransactionsView = () => {
  const { hasPermission, isPostingDisabled } = hasPermissionHook();
  const blankFilter = useMemo(() => {
    return {
      'client.external_id': null,
      'client.rmi_number': null,
      'pending_transactions.document_number': null,
      'pending_transactions.created_by_raw': null,
      'pending_transactions.document_type_journal_entry_id': null,
      'pending_transactions.document_type_domain_id': null,
      'transaction_source.id': null,
      posting_date_from: null,
      posting_date_to: null,
      pending_status: null,
      document_type: null,
      start: '',
      end: '',
      page: 0,
      pageSize: 10,
      sort_by: 'pending_transactions.posting_date',
      order_by: 'DESC',
    };
  }, []);

  const blankData = useMemo(() => {
    return { data: [], page: 0, pageSize: 10, pageTotal: 0 };
  }, []);

  const [reset, setReset] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filter, setFilter] = useState(blankFilter);
  const [selectedRowsDates, setSelectedRowsDates] = useState([]);

  const { data: { data: transactions = {} } = {}, refetch: fetchTransactions, isLoading } = usePendingTransactions(filter);
  const { open: isNotesModalOpen, setOpen: setNotesModalOpen } = useCustomModalToggle();
  const { data: allPendingIds, refetch: refetchIdsForSelectAll } = useGetAllPendingIds(filter);

  const blankPendingStatusData = useMemo(() => {
    return { notes: '', status: PENDING };
  });

  const [pendingStatusData, setPendingStatusData] = useState(blankPendingStatusData);
  const [buttonOptions, setButtonOptions] = useState({
    post: { disabled: false },
  });

  useEffect(() => {
    if (reset) {
      fetchTransactions();
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    setButtonOptions((prevValue) => ({
      ...prevValue,
      post: {
        ...prevValue.post,
        disabled: !selectedRows.length || !hasPermission(PENDING_TRANSACTIONS, EDIT),
      },
    }));

    const postingDates = selectedRows.map((selectedId) => {
      const selectedTransaction = allPendingIds?.data?.data.find((item) => item['id'] === selectedId);

      return selectedTransaction['posting_date'];
    });

    setSelectedRowsDates(postingDates);
  }, [selectedRows]);

  const resetData = () => {
    setSelectedRows([]);
    setPendingStatusData(blankPendingStatusData);
  };

  const { mutate: changeStatuses, isLoading: postingInProgress } = usePendingTransactionsStatusChanges({
    callbacks: {
      success: resetData,
      error: resetData,
    },
  });

  const handleFilterChange = (input) => {
    const f = { ...filter, ...input };
    setFilter(f);
  };

  const handleFilterActionButtonClick = (e) => {
    if (e.toString() === 'PDF' || e.toString() === 'EXCEL') {
    }
    if (e.toString() === 'reset') {
      setFilter(blankFilter);
      setReset(true);
    }

    if (e.toString() === 'search') {
      fetchTransactions();
      refetchIdsForSelectAll();
    }
  };

  const handleChange = (e) => {
    try {
      if (e.target.value.constructor === {}.constructor) {
        setFilter((prev) => ({ ...prev, ...e.target.value }));
      } else {
        setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      }
    } catch (error) {
      setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const handleSelectChange = (val, e) => {
    if (e.action === 'select-option') {
      let o = { target: { name: e.name, value: val.value || val.id } };
      handleChange(o);
      return;
    }
    if (e.action === 'clear') {
      let o = { target: { name: e.name, value: null } };
      handleChange(o);
    }
  };

  const handleDateChange = (date, name) => {
    setFilter((prev) => ({
      ...prev,
      [name]: date,
    }));
  };

  const handleCheckboxChange = (row, selectMultiple) => {
    if (!selectMultiple) {
      const pendingTransactionId = row.original['pending_transactions.id'];
      const uniqueSelectedTransactions = new Set(selectedRows);

      const isDisabled = row?.original['pending_status_id'] !== PENDING;
      if (pendingTransactionId) {
        if (!isDisabled) {
          if (uniqueSelectedTransactions.has(pendingTransactionId)) {
            uniqueSelectedTransactions.delete(pendingTransactionId);
          } else {
            uniqueSelectedTransactions.add(pendingTransactionId);
          }

          setSelectedRows(Array.from(uniqueSelectedTransactions));
        }
      }
    } else {
      if (transactions?.data) {
        selectedRows.length ? setSelectedRows([]) : setSelectedRows(allPendingIds?.data?.ids);
      }
    }
  };

  const handleClientClick = ({ row }) => {
    const clientId = row?.original['client.id'];
    const clientType = row?.original['client.client_type_id'] === 1 ? 'customers' : 'vendors';
    if (clientId) {
      window.open(`/client/${clientType}/360/${clientId}`, '_blank');
    }
  };

  const handleApproveManyClick = () => {
    setPendingStatusData((p) => ({ ...p, status: APPROVED }));
    setNotesModalOpen(true);
  };

  const handleDeclineManyClick = () => {
    setPendingStatusData((p) => ({ ...p, status: DECLINED }));
    setNotesModalOpen(true);
  };

  const handleNotesModalConfirmClick = () => {
    if (isPostingDisabled(selectedRowsDates)) {
      notificationDangerCustomTime('You are not allowed to post in this period. Please contact your superior for more info!', 10000);
      return;
    }

    if (selectedRows.length) {
      const { notes, status } = pendingStatusData;
      setButtonOptions((prevValue) => ({
        ...prevValue,
        post: {
          ...prevValue.post,
          disabled: true,
        },
      }));
      const ids = [...selectedRows];
      changeStatuses({ ids, data: { status, notes } });
    }
    setNotesModalOpen(false);
  };

  const closeNotesModal = () => {
    setPendingStatusData(blankPendingStatusData);
    setSelectedRows([]);
    setNotesModalOpen(false);
    setSelectedRowsDates([]);
  };

  const onFilterActionBtn = (e, page) => {
    if (e.toString() === 'PDF' || e.toString() === 'EXCEL') {
      let pendingGlIds = null;
      if (page) {
        pendingGlIds = [];
        transactions.data.forEach((transaction) => {
          transaction.gls.forEach((gl) => pendingGlIds.push(gl.id));
        });
      }

      downloaderFiles({
        filter,
        type: e,
        url: '/me/transaction/pending',
        cbLoad: () => {},
        name: `Pending Transactions`,
        page: pendingGlIds,
      });
    }
    if (e.toString() === 'reset') {
      setFilter(blankFilter);
    }
    // if (e.toString() === 'search') refetch();
  };

  return (
    <>
      {postingInProgress && (
        <Box>
          <Loader isFullScreen />
        </Box>
      )}

      <CustomModal
        modalTitle='Transaction Notes'
        modalSize='md'
        toggleCustomModal={closeNotesModal}
        isOpen={isNotesModalOpen}
        actionButtons={
          <div className='d-flex justify-content-between w-100'>
            <Button color='error' onClick={closeNotesModal}>
              Cancel
            </Button>
            <Button color='success' disabled={buttonOptions.post.disabled} onClick={handleNotesModalConfirmClick}>
              Save
            </Button>
          </div>
        }
      >
        <Row>
          <Col md='12'>
            <CustomInput
              rows={3}
              label='Notes'
              type='textarea'
              name='notes'
              value={pendingStatusData.notes}
              placeholder=''
              onChange={(e) => setPendingStatusData((p) => ({ ...p, notes: e.target.value }))}
            />
          </Col>
        </Row>
      </CustomModal>
      <Row>
        <Col md={12}>
          <CustomCard
            headerTitle={
              <div className='d-flex align-items-center justify-content-between'>
                <span>Pending Transactions</span>
                <div className='d-flex align-items-center'>
                  <div className='mr-3'>
                    <Button sx={{ marginRight: '1rem' }} color='success' onClick={handleApproveManyClick} disabled={buttonOptions.post.disabled}>
                      Approve
                    </Button>
                    <Button color='error' onClick={handleDeclineManyClick} disabled={buttonOptions.post.disabled}>
                      Decline
                    </Button>
                  </div>
                  <ExportButton
                    disabled={isLoading}
                    exportTablePDF={() => {
                      onFilterActionBtn('PDF');
                    }}
                    exportTableEXCEL={() => {
                      onFilterActionBtn('EXCEL');
                    }}
                    exportPagePDF={() => exportPdf(container, `Pending Transactions: ${moment().format('YYYY/MM/DD HH:mm')}`)}
                    exportPageEXCEL={() => onFilterActionBtn('EXCEL', 'page')}
                  />
                </div>
              </div>
            }
          >
            <Row className='mb-3'>
              <Col md={12}>
                <CollapsableCard
                  headerTitle='Filters'
                  isExpanded={false}
                  titleTypographyProps={{ fontSize: '18px' }}
                  sxCardContent={{ padding: '16px' }}
                  sxCardHeader={{ padding: '16px' }}
                >
                  <PendingTransactionsFilter
                    filter={filter}
                    blankFilter={blankFilter}
                    onFilterActionClick={handleFilterActionButtonClick}
                    onChange={handleChange}
                    onSelectChange={handleSelectChange}
                    onDateChange={handleDateChange}
                  />
                </CollapsableCard>
              </Col>
            </Row>
            <Row>
              <Col>
                <PendingTransactionsDataTable
                  dataSource={transactions || blankData}
                  onFilterChange={handleFilterChange}
                  columns={PendingTransactionsDataTableColumns({
                    Actions: (
                      <PendingTransactionsTableActions
                        setSelectedRows={setSelectedRows}
                        onApprove={handleApproveManyClick}
                        onDecline={handleDeclineManyClick}
                        onCancel={() => {
                          setPendingStatusData(blankPendingStatusData);
                        }}
                      />
                    ),
                    SelectRow: <MemoizedSelectRow name='selectedTransactions' onChange={handleCheckboxChange} selectedRows={selectedRows} />,
                    callbacks: {
                      onClientClick: handleClientClick,
                    },
                  })}
                />
              </Col>
            </Row>
          </CustomCard>
        </Col>
      </Row>
    </>
  );
};

const SelectRow = ({ row, name, onChange, selectedRows = [], selectMultiple }) => {
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!selectMultiple) {
      setChecked(!!selectedRows.includes(row.original['pending_transactions.id']));
    } else {
      setChecked(!!selectedRows.length);
    }
  }, [selectMultiple, selectedRows, row]);

  useEffect(() => {
    setDisabled(!selectMultiple && row?.original['pending_status_id'] !== PENDING);
  }, [selectMultiple, row]);

  return <CustomCheckbox name={name} checked={checked} disabled={disabled} onChange={() => onChange(row, selectMultiple)} />;
};

const MemoizedSelectRow = React.memo(SelectRow);
