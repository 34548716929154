import React, { useEffect, useMemo, useState } from 'react';

import DataGridComponent from '../../../../component/DataTable/component/DataTableComponent';
import { useGetFixedAssetDocuments } from '../FixedAsettesApiRoutes';
import { FixedAssetDocumentsTableColumns } from '../tableColumns/FixedAssetDocumentsTableColumns';
import { Loader } from '../../../../common/Loader';
import { previewFile } from '../../../../utils/previewFile';

export const FixedAssetDocTable = ({ unitNumber, toggleCustomUploadModal, deleteFileFromS3, setEditInfoData }) => {
  const blankFilter = useMemo(
    () => ({
      page: 0,
      pageSize: 10,
      sort_by: 'document_name',
      order_by: 'ASC',
    }),
    []
  );
  const [filters, setFilters] = useState(blankFilter);
  const [previewInfo, setPreviewInfo] = useState({
    isLoading: false,
  });

  const onFilterChange = (input) => {
    setFilters({ ...filters, ...input });
  };

  useEffect(() => {
    if (previewInfo.documentName) {
      previewFile(`/me/fixed-assets/download-file/${previewInfo.previewKey}`, 'get', null, previewInfo.documentName).finally(() => {
        setPreviewInfo({ isLoading: false });
      });
    } else {
      previewFile(`/me/fixed-assets/download-file/${previewInfo.previewKey}`, 'get').finally(() => {
        setPreviewInfo({ isLoading: false });
      });
    }
  }, [previewInfo.previewKey]);

  const { data } = useGetFixedAssetDocuments({ unitNumber, filters });

  const columns = React.useMemo(
    () => FixedAssetDocumentsTableColumns({ toggleCustomUploadModal, deleteFileFromS3, setEditInfoData, setPreviewInfo }),
    []
  );

  return (
    <>
      {previewInfo.isLoading && <Loader isFullScreen />}
      <DataGridComponent onFilterChange={onFilterChange} columns={columns} loading={false} dataSource={data?.data} />;
    </>
  );
};
