import React from 'react';
import { Col, Row } from 'reactstrap';
import {
  tableCellClasses,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  Tooltip,
  Button,
  Checkbox,
  Stack,
  Typography,
} from '@mui/material';
import { DeleteForeverOutlined, ContentCopyOutlined, LayersClearOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import { CustomSelect } from '../../../../common/CustomSelect';
import { DatePickerComponent } from '../../../../common/DatePicker/DatePicker';
import { CustomInput } from '../../../../common/CustomInput';
import { CustomCurrencyInput } from '../../../../common/Inputs/CurrencyInput';
import { useAsyncPageable } from '../../../../hooks/useAsyncPageable';
import { CustomPageableAsyncSelect } from '../../../../common/Inputs/v2/AsyncSelect2';
import { BRANCH_TABLE } from '../../../../constants/supportTables.constants';
import { useGetSupportTablesForSelect } from '../../../../hooks/commonApiHooks';
import { useApplyToDocument, useDeleteRecurringItem, useExportExcel, useGetClearingTransactions, useInvoiceForSubAccount } from '../apiHooks';
import { ACC_TYPE_CUSTOMER, ACC_TYPE_GL_ACCOUNT, ACC_TYPE_VENDOR, accountTypeOptions } from '../../../../constants/accountType.constants';
import * as transactionConstants from '../../../../constants/transactionTypesRecurring.constants';
import { recurringMethodOptions } from '../../../../constants/recurringMethods.constants';
import { getAccNumPerTenant } from '../constants';
import { hasPermissionHook } from '../../../../hooks/hasPermission';
import { EDIT, RECURRING_JOURNAL_ENTRY } from '../../../../constants/permissions.constants';
import UploadEntries from './UploadEntries';
import { CustomModal, useCustomModalToggle } from '../../../../common/Dialog/CustomModal';
import { notificationDanger } from '../../../../utils/toastify';

export const RecurringJournalEntries = ({ tableData, setTableData, totalData, validationErrors, recurringId, recurringData }) => {
  const { hasPermission } = hasPermissionHook();
  const { fetchOptions } = useAsyncPageable();
  const { user_tenant } = useSelector((state) => ({ user_tenant: state.auth.user.tenant_id }));
  const { toggleCustomModal, open } = useCustomModalToggle();
  const [client, setClient] = React.useState({});
  const [selectedInvoices, setSelectedInvoices] = React.useState([]);
  const [transaction, setTransaction] = React.useState([]);

  const { mutate: deleteRecurringItemMutation } = useDeleteRecurringItem();
  const { mutate: exportExcelMutation } = useExportExcel();
  const { data: { data: supportTableOptions = {} } = {} } = useGetSupportTablesForSelect({
    table_names: [BRANCH_TABLE],
  });
  const { data: invoiceOptions, refetch } = useInvoiceForSubAccount(client);
  const { data: clearingTransactionsData, refetch: refetchClearingTr } = useGetClearingTransactions(recurringId);
  const { mutateAsync: applyMutation } = useApplyToDocument(onSuccessCb);

  function onSuccessCb() {
    toggleCustomModal();
  }

  React.useEffect(() => {
    if (client.clientId && client.clientType) {
      refetch();
    }
  }, [client]);

  React.useEffect(() => {
    if (recurringId) refetchClearingTr();
  }, [recurringId]);

  // LOAD ASYNC SELECT OPTIONS
  const AccountOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/account/options/accounts', { q: inputValue, ...options });
    return data;
  };
  const VendorOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/client/vendor/options-number-for-select', { q: inputValue, ...options, client_type_id: 2 });
    return data;
  };
  const CustomerOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/client/customer/options-number-for-select', { q: inputValue, ...options, client_type_id: 1 });
    return data;
  };

  //   export const CUSTOMER_INVOICE = 1;
  // export const VENDOR_INVOICE = 2;
  // export const JOURNAL_ENTRY = 3;
  // export const CUSTOMER_CREDIT_MEMO = 4;
  // export const VENDOR_CREDIT_MEMO = 5;
  // export const CUSTOMER_PAYMENT = 6;
  // export const VENDOR_PAYMENT = 7;

  const accountTypeFilteredOptions = React.useMemo(() => {
    const {
      CUSTOMER_INVOICE,
      CUSTOMER_CREDIT_MEMO,
      CUSTOMER_PAYMENT,
      VENDOR_CREDIT_MEMO,
      VENDOR_INVOICE,
      VENDOR_PAYMENT,
      CUSTOMER_REVERSING_PAYMENT,
      VENDOR_REVERSING_PAYMENT,
    } = transactionConstants;
    if ([CUSTOMER_INVOICE, CUSTOMER_CREDIT_MEMO, CUSTOMER_PAYMENT, CUSTOMER_REVERSING_PAYMENT].includes(recurringData.recurring_transaction_type)) {
      return accountTypeOptions.filter((item) => item.value !== ACC_TYPE_VENDOR);
    } else if ([VENDOR_CREDIT_MEMO, VENDOR_INVOICE, VENDOR_PAYMENT, VENDOR_REVERSING_PAYMENT].includes(recurringData.recurring_transaction_type)) {
      return accountTypeOptions.filter((item) => item.value !== ACC_TYPE_CUSTOMER);
    } else {
      return accountTypeOptions.filter((item) => item.value === ACC_TYPE_GL_ACCOUNT);
    }
  }, [recurringData.recurring_transaction_type]);

  // CHANGE HANDLERS
  /**
   * @param idx to replace/delete/insert
   * @param tableRow if it is specified it will replace or insert starting from idx
   * @param insert if it's specified it will insert on idx position
   */
  const replaceDeleteInsertRow = (idx, tableRow, insert) => {
    let tableDataReplica = [...tableData];
    if (tableRow) {
      tableDataReplica.splice(idx, insert ? 0 : 1, tableRow);
    } else {
      tableDataReplica.splice(idx, insert ? 0 : 1);
    }

    setTableData(tableDataReplica);
  };

  const getRowData = (row) => {
    const idx = tableData.findIndex((tableRowParam) => {
      if (tableRowParam.uuid) {
        return tableRowParam.uuid === row.uuid;
      } else {
        return tableRowParam.id === row.id;
      }
    });
    let tableRow = tableData[idx];

    return { tableRow, idx };
  };

  const changeAsyncSelect = (val, e, row) => {
    const { tableRow, idx } = getRowData(row);
    let tableRowDoc;
    if (e.name === 'client_id') {
      tableRowDoc = {
        ...tableRow,
        [e.name]: val?.value ? val.value : null,
        // description: val?.client_name ? val.client_name : '',
        client_external_id: val?.client_external_id,
      };
    } else {
      tableRowDoc = {
        ...tableRow,
        [e.name]: val?.value ? val.value : null,
        account_raw: val?.client_name ? `${val.value} - ${val.client_name}` : '',
      };
      if (tableRow.client_type === 3) {
        tableRowDoc = {
          ...tableRowDoc,
          // description: val?.client_name ? val.client_name : '',
        };
      }
    }
    replaceDeleteInsertRow(idx, tableRowDoc);
  };

  const changeSelect = (val, e, row) => {
    const { tableRow, idx } = getRowData(row);
    let tableRowDoc;
    if (e.name === 'client_type') {
      tableRowDoc = {
        ...tableRow,
        [e.name]: val?.value ? val.value : null,
        client_id: null,
        account_number: val?.value ? getAccNumPerTenant(user_tenant, val?.value) : null,
        // description: '',
        client_name_raw: '',
      };
    } else {
      tableRowDoc = { ...tableRow, [e.name]: val?.value ? val.value : null };
    }
    replaceDeleteInsertRow(idx, tableRowDoc);
  };

  const changeInput = (e, row) => {
    const { tableRow, idx } = getRowData(row);
    const tableRowDoc = { ...tableRow, [e.target.name]: e.target.value };
    replaceDeleteInsertRow(idx, tableRowDoc);
  };

  const handleCurrencyInputChange = (value, name, row) => {
    const { tableRow, idx } = getRowData(row);
    const tableRowDoc = { ...tableRow, [name]: value };
    replaceDeleteInsertRow(idx, tableRowDoc);
  };

  const handleDateChange = (date, name, row) => {
    const { tableRow, idx } = getRowData(row);
    const tableRowDoc = { ...tableRow, [name]: date };
    replaceDeleteInsertRow(idx, tableRowDoc);
  };

  const checkboxChange = (e, row) => {
    const { tableRow, idx } = getRowData(row);
    const tableRowDoc = { ...tableRow, [e.target.name]: e.target.checked };
    replaceDeleteInsertRow(idx, tableRowDoc);
  };

  /**** ACCOUNT ***/
  const renderAccountNumber = (row, idx) => {
    return (
      <CustomPageableAsyncSelect
        defKey={`${row.account_number}`}
        isClearable={false}
        onChange={(val, e) => changeAsyncSelect(val, e, row)}
        loadOptions={AccountOptions}
        name='account_number'
        classNamePrefix='react-select-prefix'
        initValue={row.account_number || null}
        errorMess={validationErrors[idx]?.account_number || undefined}
      />
    );
  };

  /**** CLIENT ****/
  const renderSubbaccountNumber = (row, idx) => {
    return (
      <>
        {row.client_type === ACC_TYPE_VENDOR && (
          <CustomPageableAsyncSelect
            initValue={row.client_id || ''}
            defKey={`${row.client_id}`}
            isClearable={false}
            onChange={(val, e) => changeAsyncSelect(val, e, row)}
            loadOptions={VendorOptions}
            name='client_id'
            classNamePrefix='react-select-prefix'
            errorMess={validationErrors[idx]?.client_id || undefined}
          />
        )}
        {row.client_type === ACC_TYPE_CUSTOMER && (
          <CustomPageableAsyncSelect
            initValue={row.client_id || ''}
            defKey={`${row.client_id}`}
            isClearable={false}
            onChange={(val, e) => changeAsyncSelect(val, e, row)}
            loadOptions={CustomerOptions}
            name='client_id'
            classNamePrefix='react-select-prefix'
            errorMess={validationErrors[idx]?.client_id || undefined}
          />
        )}
      </>
    );
  };

  const deleteRow = (row) => {
    const { idx } = getRowData(row);
    replaceDeleteInsertRow(idx);
    if (row.id) {
      deleteRecurringItemMutation(row.id);
    }
  };

  const duplicateRow = (row) => {
    const { idx, tableRow } = getRowData(row);
    const uuid = Date.now();
    const { id, ...rest } = tableRow;
    replaceDeleteInsertRow(idx, { ...rest, uuid }, true);
  };

  const addNewEntry = () => {
    setTableData((prev) => [...prev, { uuid: Date.now(), description: recurringData.description }]);
  };

  const onApply = async () => {
    if (!selectedInvoices.length) {
      notificationDanger('You must select at least one invoice.');
      return;
    }
    const { document_number, posting_date } = transaction[0];
    await applyMutation({ document_number, posting_date, apply_to: selectedInvoices, recurringId });
    refetch();
    refetchClearingTr();
  };

  const onApplyModal = (docNumber) => {
    const clearingTransactions = [];
    if (clearingTransactionsData.data) {
      for (const el of clearingTransactionsData.data) {
        if (el.document_number === docNumber) {
          const applyTo = el.apply_to;
          for (const item of applyTo) {
            clearingTransactions.push(item.toString());
          }
        }
      }
      setSelectedInvoices(clearingTransactions);
    }

    const transaction = tableData.filter((el) => el.document_number === docNumber);
    const trSubAccount = transaction.filter((el) => el.client_id);
    setTransaction(transaction);

    if (trSubAccount.length) {
      const allMatching = trSubAccount.every((el) => el.client_id === trSubAccount[0].client_id);

      if (!allMatching) {
        notificationDanger('The transaction cannot have a different Subaccount.');
        return;
      }
    }

    setClient((state) => ({ ...state, clientId: trSubAccount[0].client_id, clientType: trSubAccount[0].client_type }));
    toggleCustomModal();
  };

  const changeInvoiceSelect = (value, event) => {
    if (event.action === 'select-option') {
      const ids = value.map((el) => el.id + '');
      setSelectedInvoices(ids);
    }
    if (event.action === 'clear') {
      setSelectedInvoices([]);
    }
    if (event.action === 'remove-value') {
      const invoices = selectedInvoices.filter((el) => el !== event.removedValue.id);
      setSelectedInvoices(invoices);
    }
  };

  return (
    <>
      <Row className='mb-2'>
        <Col md='2'>
          <CustomCurrencyInput className='currency-input-small' value={totalData.totalDebit} prefix={'$ '} label='Total Debit' disabled size={'sm'} />
        </Col>
        <Col md='2'>
          <CustomCurrencyInput
            className='currency-input-small'
            value={totalData.totalCredit}
            prefix={'$ '}
            label='Total Credit'
            disabled
            size={'sm'}
          />
        </Col>
        <Col md='2'>
          <CustomCurrencyInput className='currency-input-small' value={totalData.difference} prefix={'$ '} label='Difference' disabled size={'sm'} />
        </Col>
        <Col md='1'></Col>
        <Col md='5' className='d-flex justify-content-end align-items-center'>
          {recurringId && (
            <Button sx={{ mr: 2 }} color='success' onClick={() => exportExcelMutation(recurringId)}>
              Export Excel
            </Button>
          )}
          <UploadEntries disabled={!hasPermission(RECURRING_JOURNAL_ENTRY, EDIT)} recurringGeneralData={recurringData} />
          <Button sx={{ mr: 2 }} color='primary' onClick={addNewEntry} disabled={!hasPermission(RECURRING_JOURNAL_ENTRY, EDIT)}>
            Add Entry
          </Button>
        </Col>
      </Row>
      <TableContainer component={Paper} className='editable-table'>
        <Table sx={{ minWidth: 1900 }} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ width: '100px' }}>Actions</StyledTableCell>
              <StyledTableCell sx={{ width: '80px' }}>Recurring Method</StyledTableCell>
              <StyledTableCell sx={{ width: '200px' }}>Document Number</StyledTableCell>
              <StyledTableCell sx={{ width: '140px' }}>Account Type</StyledTableCell>
              <StyledTableCell sx={{ width: '300px' }}>Account</StyledTableCell>
              <StyledTableCell sx={{ width: '300px' }}>Subbaccount</StyledTableCell>
              <StyledTableCell sx={{ width: '150px' }}>Branch</StyledTableCell>
              <StyledTableCell sx={{ width: '180px' }}>Description</StyledTableCell>
              <StyledTableCell sx={{ width: '140px' }}>Amount</StyledTableCell>
              <StyledTableCell sx={{ width: '120px' }}>Posting Date</StyledTableCell>
              <StyledTableCell sx={{ width: '100px' }}>Apply to Document</StyledTableCell>
              {/* <StyledTableCell sx={{ width: '130px' }}>Document Date</StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, idx) => (
              <StyledTableRow
                key={row.uuid}
                sx={{ border: validationErrors[idx] && Object.keys(validationErrors[idx]).length ? '2px dashed red' : 'none' }}
              >
                <StyledTableCell sx={{ width: '120px' }}>
                  <Tooltip title='Delete Row' placement='top' arrow followCursor>
                    <IconButton size='small' color='error' onClick={() => deleteRow(row)}>
                      <DeleteForeverOutlined fontSize='small' />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Duplicate Row' placement='top' arrow followCursor>
                    <IconButton size='small' color='success' onClick={() => duplicateRow(row)}>
                      <ContentCopyOutlined fontSize='small' />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Select For Recurring' placement='top' arrow followCursor>
                    <Checkbox name='selected' checked={row.selected} onChange={(e) => checkboxChange(e, row)} />
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell>
                  <CustomSelect
                    onChange={(val, e) => changeSelect(val, e, row)}
                    options={recurringMethodOptions}
                    defaultValue={row.recurring_method || null}
                    classNamePrefix='react-select-prefix'
                    name='recurring_method'
                    errorMess={validationErrors[idx]?.recurring_method || undefined}
                    isClearable
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <CustomInput
                    onChange={(e) => changeInput(e, row)}
                    value={row.document_number}
                    name='document_number'
                    placeholder={'Doc. Num'}
                    size='sm'
                    type='textarea'
                    errorMess={validationErrors[idx]?.document_number || undefined}
                  />
                </StyledTableCell>
                <StyledTableCell sx={{ width: '140px' }}>
                  <CustomSelect
                    name='client_type'
                    defaultValue={row.client_type || null}
                    onChange={(val, e) => changeSelect(val, e, row)}
                    options={accountTypeFilteredOptions}
                    classNamePrefix='react-select-prefix'
                    isClearable
                  />
                </StyledTableCell>
                <StyledTableCell sx={{ maxWidth: '150px', minWidth: '150px' }}>{renderAccountNumber(row, idx)}</StyledTableCell>
                <StyledTableCell sx={{ maxWidth: '150px', minWidth: '150px' }}>{renderSubbaccountNumber(row, idx)}</StyledTableCell>
                <StyledTableCell sx={{ width: '130px' }}>
                  <CustomSelect
                    options={supportTableOptions[BRANCH_TABLE] || []}
                    defaultValue={row.branch_id || null}
                    name='branch_id'
                    onChange={(val, e) => changeSelect(val, e, row)}
                    classNamePrefix='react-select-prefix'
                    errorMess={validationErrors[idx]?.branch_id || undefined}
                    isClearable
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <CustomInput onChange={(e) => changeInput(e, row)} type='textarea' value={row.description} name='description' rows={2} size='sm' />
                </StyledTableCell>
                <StyledTableCell>
                  <CustomCurrencyInput
                    className='currency-input-small'
                    name='amount'
                    value={row.amount}
                    onChange={(value, name) => {
                      handleCurrencyInputChange(value, name, row);
                    }}
                    prefix={'$ '}
                    placeholder='Amount'
                    errorMess={validationErrors[idx]?.amount || undefined}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <DatePickerComponent
                    showYearDropdown
                    name='posting_date'
                    selected={row.posting_date}
                    onChange={(date) => handleDateChange(date, 'posting_date', row)}
                    placeholder='mm/dd/yyyy'
                    dateFormat={'MM/dd/yyyy'}
                    errorMess={validationErrors[idx]?.posting_date || undefined}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  {row.client_id &&
                  (recurringData.recurring_transaction_type === transactionConstants.CUSTOMER_PAYMENT ||
                    recurringData.recurring_transaction_type === transactionConstants.VENDOR_PAYMENT) ? (
                    <Stack direction='row' justifyContent='center'>
                      <Tooltip arrow title='Apply'>
                        <IconButton onClick={() => onApplyModal(row.document_number)} color='success'>
                          <LayersClearOutlined fontSize='small' />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  ) : null}
                </StyledTableCell>
                {/* <StyledTableCell sx={{ width: '130px' }}>
                  {' '}
                  <DatePickerComponent
                    showYearDropdown
                    name='document_date'
                    selected={row.document_date}
                    onChange={(date) => handleDateChange(date, 'document_date', row)}
                    placeholder='mm/dd/yyyy'
                    dateFormat={'MM/dd/yyyy'}
                  />
                </StyledTableCell> */}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomModal
        modalTitle='Apply to document'
        modalSize='sm'
        toggleCustomModal={toggleCustomModal}
        isOpen={open}
        addStyles={{ PaperStyle: { overflowY: 'scroll' } }}
        saveButtonTitle='Apply'
        onSave={onApply}
      >
        <Typography variant='body2' mb={2}>
          *Please save the record before applying. If you saved everything you wanted, ignore this message.
        </Typography>
        <CustomSelect
          isMulti
          defaultValue={selectedInvoices}
          name='invoices'
          label='Invoice Number'
          onChange={(value, e) => changeInvoiceSelect(value, e)}
          options={invoiceOptions?.data ?? []}
          isClearable
          closeMenuOnSelect={false}
        />
      </CustomModal>
    </>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    display: 'table-cell',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    display: 'table-cell',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
