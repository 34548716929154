import { Button, Divider, Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { CustomInput } from '../../../../../../common/CustomInput';
import { DatePickerComponent } from '../../../../../../common/DatePicker/DatePicker';
import { useBillingPaymentCheckNumber, useBillingPaymentChecksPrinting } from '../../hooks';
import { extractValidationErrorMessage } from '../../utils';

/**
 * @param {Boolean} isPrint if false reprint
 */
export const PrintCheckForm = ({ checkId, clientType, closeModal = () => {}, multiplePrint = false, selectedCheckIds = [], isPrint = true }) => {
  const blankForm = useMemo(() => {
    return {
      print_date: moment().format('MM/DD/YYYY'),
      printed: true,
      check_number: null,
      isPrint,
    };
  }, [isPrint]);

  const [form, setForm] = useState(blankForm);

  const {
    mutate: printChecks,
    isLoading: printingInProgress,
    error: errorPrinting,
  } = useBillingPaymentChecksPrinting({
    clientType,
    callbacks: {
      success: closeModal,
    },
  });

  const { data: { data: { next_number: nextCheckNumber = null } = {} } = {} } = useBillingPaymentCheckNumber(clientType, true);

  useEffect(() => {
    return () => {
      setForm(blankForm);
    };
  }, []);

  useEffect(() => {
    if (nextCheckNumber) {
      setForm((p) => ({ ...p, check_number: nextCheckNumber }));
    }
  }, [nextCheckNumber]);

  const handleChange = (e) => {
    try {
      if (e.target.value.constructor === {}.constructor) {
        setForm((prev) => ({ ...prev, ...e.target.value }));
      } else {
        setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      }
    } catch (error) {
      setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const handleDateChange = (date, name) => {
    setForm((prev) => ({
      ...prev,
      [name]: date,
    }));
  };

  const handlePrintClick = () => {
    let data = {
      print_date: form.print_date ? moment(form.print_date).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY'),
      printed: true,
      check_number: form.check_number,
      isPrint: form.isPrint,
    };

    if (multiplePrint) {
      printChecks({ data: { ...data, ids: selectedCheckIds } });
    } else {
      printChecks({ data: { ...data, ids: [checkId] } });
    }
  };

  const checkNumberLabel = useMemo(() => {
    return (
      <div>
        Check Number
        <Tooltip
          title='On print check number will be set only if check has no number. On reprint check number will be overridden.'
          placement='top'
          arrow
          followCursor
        >
          <i className='fas fa-info-circle ml-2' />
        </Tooltip>
      </div>
    );
  }, [isPrint]);

  return (
    <>
      <Row>
        <Col md={12}>
          <DatePickerComponent
            showYearDropdown
            name='print_date'
            selected={form?.print_date}
            onChange={(date) => handleDateChange(date, 'print_date')}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Printing Date'
            errorMess={extractValidationErrorMessage([errorPrinting], 'print_date')}
          />
        </Col>
        <Col md={12}>
          <CustomInput
            type='number'
            label={checkNumberLabel}
            min={0}
            onChange={handleChange}
            name='check_number'
            value={form['check_number'] || ''}
            errorMess={extractValidationErrorMessage([errorPrinting], 'check_number')}
          />
        </Col>
      </Row>
      <Row className='mb-3 mt-3'>
        <Col>
          <Divider />
        </Col>
      </Row>
      <Row>
        <Col className='d-flex justify-content-end'>
          <Button disabled={printingInProgress} onClick={handlePrintClick}>
            {isPrint ? 'Print' : 'Reprint'}
          </Button>
        </Col>
      </Row>
    </>
  );
};
