import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { CollapsableCard } from '../../../../common/Card/CollapsableCard';
import { CustomCard } from '../../../../common/Card/CustomCard';
import ExportButton from '../../../../component/Buttons/ExportButton';
import TransactionsDataTable from '../../../../component/DataTable/TransactionsDataTable';
import { downloaderFiles } from '../../../../utils/downloaderFiles';
import { exportExcel } from '../../../../utils/exportExcel';
import { exportPdf } from '../../../../utils/exportPdf';
import { AllTransactionsTableActions } from './component/AllTransactionsTableActions';
import { transactionExcelHeader, TransactionsDataTableColumns } from './component/TransactionsDataGridColumns';
import { TransactionFilter } from './component/TransactionsFilter';
import { useAllTransactions } from '../hooks';

export default function TransactionsView() {
  const blankFilter = useMemo(() => {
    return {
      'client_transactions.client_id': '',
      'transactions.id': '',
      'transactions.document_type_journal_entry_id': '',
      'transactions.document_type_domain_id': '',
      'client.external_id': null,
      'client.rmi_number': null,
      'transactions.document_number': null,
      posting_date_from: null,
      posting_date_to: null,
      invoice_date_from: null,
      invoice_date_to: null,
      invoice_due_date_from: null,
      invoice_due_date_to: null,
      start: '',
      end: '',
      page: 0,
      pageSize: 10,
    };
  }, []);

  const blankData = useMemo(() => {
    return { data: [], page: 0, pageSize: 10, pageTotal: 0 };
  }, []);

  const [filter, setFilter] = useState(blankFilter);
  const [loader, setLoader] = useState(false);
  const [container, setContainer] = useState();
  const url = useMemo(() => '/me/transaction/', []);

  const { data: { data: transactions = {} } = {}, refetch: fetchTransactions } = useAllTransactions(filter);

  const onFilterHandler = (e) => {
    try {
      if (e.target.value.constructor === {}.constructor) {
        const f = { ...filter, ...e.target.value };
        setFilter(f);
      } else {
        const f = { ...filter, [e.target.name]: e.target.value };
        setFilter(f);
      }
    } catch (error) {
      const f = { ...filter, [e.target.name]: e.target.value };
      setFilter(f);
    }
  };

  const onFilterChange = (input) => {
    const f = { ...filter, ...input };
    setFilter(f);
  };

  const onFilterActionBtn = (e) => {
    if (e.toString() === 'PDF' || e.toString() === 'EXCEL') {
      downloaderFiles({ filter, type: e, url, cbLoad: setLoader, name: 'Transactions' });
    }
    if (e.toString() === 'reset') setFilter(blankFilter);

    if (e.toString() === 'search') {
      fetchTransactions();
    }
  };

  const handleChange = (e) => {
    try {
      if (e.target.value.constructor === {}.constructor) {
        setFilter((prev) => ({ ...prev, ...e.target.value }));
      } else {
        setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      }
    } catch (error) {
      setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const handleSelectChange = (val, e) => {
    if (e.action === 'select-option') {
      let o = { target: { name: e.name, value: val.value || val.id } };
      handleChange(o);
      return;
    }
    if (e.action === 'clear') {
      let o = { target: { name: e.name, value: null } };
      handleChange(o);
    }
  };

  const handleDateChange = (date, name) => {
    setFilter((prev) => ({
      ...prev,
      [name]: date,
    }));
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <CustomCard
            headerTitle={
              <div className='d-flex align-items-center justify-content-between'>
                <span>Transactions</span>
                <ExportButton
                  disabled={loader}
                  exportTablePDF={() => {
                    onFilterActionBtn('PDF');
                  }}
                  exportTableEXCEL={() => {
                    onFilterActionBtn('EXCEL');
                  }}
                  exportPagePDF={() => exportPdf(container, 'Transactions')}
                  exportPageEXCEL={() => exportExcel(transactions.data, transactionExcelHeader, 'Transactions')}
                />
              </div>
            }
          >
            <Row className='mb-3'>
              <Col md={12}>
                <CollapsableCard
                  headerTitle='Filters'
                  isExpanded={false}
                  titleTypographyProps={{ fontSize: '18px' }}
                  sxCardContent={{ padding: '16px' }}
                  sxCardHeader={{ padding: '16px' }}
                >
                  <TransactionFilter
                    loader={loader}
                    filter={filter}
                    blankFilter={blankFilter}
                    onFilterActionClick={onFilterActionBtn}
                    onFilterChangeHandler={onFilterHandler}
                    onChange={handleChange}
                    onSelectChange={handleSelectChange}
                    onDateChange={handleDateChange}
                  />
                </CollapsableCard>
              </Col>
            </Row>
            <Row
              ref={(instance) => {
                setContainer(instance);
              }}
            >
              <Col>
                <TransactionsDataTable
                  loader={loader}
                  dataSource={transactions || blankData}
                  onFilterChange={onFilterChange}
                  columns={TransactionsDataTableColumns({ Actions: <AllTransactionsTableActions />, refetch: fetchTransactions, filter })}
                />
              </Col>
            </Row>
          </CustomCard>
        </Col>
      </Row>
    </>
  );
}
