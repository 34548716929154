import { Button } from '@mui/material';
import { Col, Label, Row } from 'reactstrap';
import { CustomSelect } from '../../../../../common/CustomSelect';
import { CustomPageableAsyncSelect } from '../../../../../common/Inputs/CustomPageableAsyncSelect';
import DateRangeSelectBox from '../../../../../component/SelectBox/DataRangeSelectBox';
import { MAKE, TRUCK_TYPE, YEAR } from '../../../../../constants/fixedAssetsCodebookType.constants';
import { showColumnOptions } from '../../../../../constants/showColumnOptions.constants';
import { BRANCH_TABLE } from '../../../../../constants/supportTables.constants';
import { useGetSupportTablesForSelect } from '../../../../../hooks/commonApiHooks';
import { useAsyncPageable } from '../../../../../hooks/useAsyncPageable';
import { useGetFixedAssetsCodebooks } from '../../../FixedAssets/FixedAsettesApiRoutes';

export const Filters = ({ filter, onSelectChange, onChange, onSearch }) => {
  const { data: { data: supportTableOptions = {} } = {} } = useGetSupportTablesForSelect({
    table_names: [BRANCH_TABLE],
  });

  const { fetchOptions } = useAsyncPageable();
  const { data: { data: codebooks = {} } = {} } = useGetFixedAssetsCodebooks([MAKE, TRUCK_TYPE, YEAR]);

  const loadUnitNumberOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/fixed-assets/unit-num-for-select', {
      q: inputValue,
      ...options,
    });

    return data;
  };

  return (
    <>
      <Row>
        <Col md={3}>
          <Label>Date Range</Label>
          <DateRangeSelectBox name='name' field={{ start: filter['start'], end: filter['end'] }} onChange={onChange} isDateRange />
        </Col>
        <Col md={3}>
          <CustomSelect
            label='Branch'
            isClearable
            onChange={onSelectChange}
            name='general_ledger.branch_id'
            defaultValue={filter['general_ledger.branch_id'] || null}
            options={supportTableOptions[BRANCH_TABLE] || []}
          />
        </Col>
        <Col md={3}>
          <CustomSelect
            label='Show Columns'
            isClearable
            onChange={onSelectChange}
            name='series_by'
            disabled={!filter['start']}
            defaultValue={filter['series_by'] || null}
            options={showColumnOptions || []}
          />
        </Col>
        <Col md={3}>
          <CustomPageableAsyncSelect
            isClearable
            label='Unit #'
            onChange={onSelectChange}
            loadOptions={loadUnitNumberOptions}
            name='fixed_assets.unit_number'
            defaultValue={filter['fixed_assets.unit_number'] || null}
          />
        </Col>
        <Col md={3}>
          <CustomSelect
            isClearable
            label='Truck Year'
            defaultValue={filter['fixed_assets.year'] || null}
            onChange={onSelectChange}
            name='fixed_assets.year'
            options={codebooks[YEAR] || []}
          />
        </Col>
        <Col md={3}>
          <CustomSelect
            isClearable
            label='Truck Type'
            name='fixed_assets.truck_type'
            defaultValue={filter['fixed_assets.truck_type'] || null}
            onChange={onSelectChange}
            options={codebooks[TRUCK_TYPE] || []}
          />
        </Col>
        <Col md={3}>
          <CustomSelect
            isClearable
            label='Make'
            name='fixed_assets.make'
            defaultValue={filter['fixed_assets.make'] || null}
            onChange={onSelectChange}
            options={codebooks[MAKE] || []}
          />
        </Col>
      </Row>
      <Row>
        <Col className='d-flex justify-content-end'>
          <Button onClick={onSearch}>Search</Button>
        </Col>
      </Row>
    </>
  );
};
