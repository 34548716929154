import { DoDisturbOnOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

export const PaymentInfoActions = ({ row, onDelete = () => {} }) => {
  return (
    <div>
      {+row.original['tc.amount'] ? (
        <Tooltip title='Cancel Application' placement='top' arrow followCursor>
          <IconButton onClick={(e) => onDelete(e, row)} color='error'>
            <DoDisturbOnOutlined />
          </IconButton>
        </Tooltip>
      ) : null}
    </div>
  );
};
