export const normalizeClientType = (clientType) => clientType.toLowerCase().slice(0, -1);

const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

export const clientTypeNameSingular = (clientType) => {
  if (!clientType) return 'Client';

  const type = capitalize(clientType);
  return type.slice(0, type.length - 1);
};

export const clientTypeName = (clientType) => {
  if (!clientType) return 'Clients';

  const clientTypeName = capitalize(clientType);
  return clientTypeName;
};
