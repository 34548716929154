export const RM_FIXED = 1;
export const RM_VARIABLE = 2;
export const RM_REVERSING_FIXED = 3;
export const RM_REVERSING_VARIABLE = 4;

export const recurringMethodOptions = [
  { label: 'F', value: RM_FIXED },
  { label: 'V', value: RM_VARIABLE },
  { label: 'RF', value: RM_REVERSING_FIXED },
  { label: 'RV', value: RM_REVERSING_VARIABLE },
];

const recurringMethodMapper = new Map();
recurringMethodMapper.set(RM_FIXED, 'Fixed');
recurringMethodMapper.set(RM_VARIABLE, 'Variable');
recurringMethodMapper.set(RM_REVERSING_FIXED, 'Reversing Fixed');
recurringMethodMapper.set(RM_REVERSING_VARIABLE, 'Reversing Variable');

export const getRecurringMethod = (id) => {
  return recurringMethodMapper.get(id);
};
