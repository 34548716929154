import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DialogActions, DialogContent, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import React, { useMemo, useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { CustomButton } from '../../../common/Button/CustomButton';
import { CollapsableCard } from '../../../common/Card/CollapsableCard';
import { CustomCard } from '../../../common/Card/CustomCard';
import { CustomPopup } from '../../../common/Popup/CustomPopup';
import ExportButton from '../../../component/Buttons/ExportButton';
import { ChartOfAccountColumns } from '../../../component/DataTable/Columns/ChartOfAccountColumns';
import DataTable from '../../../component/DataTable/component/DataTableComponent';
import { downloaderFiles } from '../../../utils/downloaderFiles';
import { exportExcel } from '../../../utils/exportExcel';
import { exportPdf } from '../../../utils/exportPdf';
import AccountForm from '../Account/component/AccountFormModal';
import JournalEntryModal from '../JournalEntry/component/JournalEntryModal';
import { useCanDeleteAccount, useChartOfCAccountTableData, useDeleteAccount } from './ChartOfAccountApi';
import { ChartOfAccountFilter } from './component/ChartOfAccountFilter';
import { hasPermissionHook } from '../../../hooks/hasPermission';
import { CHART_OF_ACCOUNTS, EDIT } from '../../../constants/permissions.constants';

const ChartOfAccountTable = ({ account_type = null, account_number = null, account_normal_balance_id = null, account_with_no_activity = false }) => {
  const { hasPermission } = hasPermissionHook();
  const blankFilter = useMemo(() => {
    return {
      'account.with_no_activity': account_with_no_activity,
      'account.type': account_type,
      'account.number': account_number,
      'account.account_normal_balance_id': account_normal_balance_id,
      start: '',
      end: '',
      page: 0,
      pageSize: 10,
      sort_by: 'number',
      order_by: 'ASC',
    };
  }, [account_number, account_normal_balance_id, account_type, account_with_no_activity]);

  const blankData = useMemo(() => {
    return { data: [], page: 0, pageSize: 10, pageTotal: 0 };
  }, []);

  const url = useMemo(() => '/me/account/chart-of-account/', []);

  const [deleteAnchor, setDeleteAnchor] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [deleteAccountId, setDeleteAccountId] = useState(null);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [filter, setFilter] = useState(blankFilter);
  const [loader, setLoader] = useState(false);
  const [container, setContainer] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [openJournalEntry, setOpenJournalEntry] = useState({ open: false, account: null });

  const { data: canDeleteAccountData, refetch: checkCanDelete } = useCanDeleteAccount(deleteAccountId);
  const { data: tableData, refetch: getTableData } = useChartOfCAccountTableData(filter);
  const { mutate: deleteAccount } = useDeleteAccount(filter);

  useEffect(() => {
    setOpenDeletePopup(false);
  }, [filter]);

  useEffect(() => {
    if (deleteAccountId) {
      checkCanDelete();
    }
  }, [deleteAccountId]);

  useEffect(() => {
    if (canDeleteAccountData?.data) {
      const { can_delete, id } = canDeleteAccountData.data;
      if (!can_delete) {
        setDeleteMessage(`Account ${id} cannot be deleted!`);
      } else {
        setDeleteMessage('');
      }
    }
  }, [canDeleteAccountData?.data]);

  const modalToggle = (v) => {
    if (typeof v === 'boolean') {
      setModalOpen(v);
    } else {
      setModalOpen((prev) => !prev);
    }
  };

  const onFilterDateRangeChange = (e) => {
    setFilter((prev) => ({ ...prev, ...e.target.value }));
  };

  const onFilterHandler = (e) => {
    try {
      if (e.target.value.constructor === {}.constructor) {
        const f = { ...filter, ...e.target.value };
        setFilter(f);
      } else {
        const f = { ...filter, [e.target.name]: e.target.value };
        setFilter(f);
      }
    } catch (error) {
      const f = { ...filter, [e.target.name]: e.target.value };
      setFilter(f);
    }
  };

  const onSwitchChange = (e) => {
    const { name, checked } = e.target;
    setFilter((prev) => ({ ...prev, [name]: checked }));
  };

  const onFilterChange = (input) => {
    const f = { ...filter, ...input };
    setFilter(f);
  };

  const onSearch = () => {
    getTableData(filter);
  };

  const excelHeader = [
    { header: 'NUMBER', key: 'number' },
    { header: 'NAME', key: 'name' },
    { header: 'DEBIT SUM', key: 'debit', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
    { header: 'CREDIT SUM', key: 'credit', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
    { header: 'TOTAL SUM', key: 'total', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
  ];

  const onFilterActionBtn = (e) => {
    if (e.toString() === 'PDF' || e.toString() === 'EXCEL') {
      downloaderFiles({ filter, type: e, url, cbLoad: setLoader, name: 'Chart-Of-Account' });
    }
    if (e.toString() === 'reset') setFilter(blankFilter);
    if (e.toString() === 'add') onActionAddAccount();
    if (e.toString() === 'search') onSearch();
  };

  const onEditAccount = (id) => {
    setSelectedAccount(id);
    setModalOpen(true);
  };

  const onDeleteAccountActionButton = (e, id) => {
    setDeleteAccountId(id);
    setDeleteAnchor(e.currentTarget);
    setOpenDeletePopup(true);
  };

  const onActionAddAccount = () => {
    setSelectedAccount(null);
    setModalOpen(true);
  };

  const onTableActions = ({ name, id, event }) => {
    if (name.toString() === 'edit') {
      onEditAccount(id);
    }
    if (name.toString() === 'journalEntry') {
      setOpenJournalEntry({ open: true, account: id });
    }
    if (name.toString() === 'delete') {
      onDeleteAccountActionButton(event, id);
    }
  };

  const handleConfirmDeleteAccountClick = () => {
    if (deleteAccountId) {
      deleteAccount(deleteAccountId);
      setOpenDeletePopup(false);
      setDeleteAccountId(null);
    }
  };

  const handleCancelDeleteAccountClick = () => {
    setOpenDeletePopup(false);
    setDeleteAccountId(null);
  };

  const myColumn = useMemo(() => {
    return ChartOfAccountColumns({ onActions: onTableActions, disabled: !hasPermission(CHART_OF_ACCOUNTS, EDIT) });
  }, []);

  return (
    <>
      <JournalEntryModal
        {...openJournalEntry}
        initialFilters={{ postingDate: { from: filter.start, to: filter.end } }}
        displayFullLedgers
        onToggle={(e) => {
          setOpenJournalEntry({ open: false, account: null });
        }}
      />

      <AccountForm open={modalOpen} onToggle={modalToggle} number={selectedAccount} setRefresh={getTableData} />
      <CustomPopup
        anchor={deleteAnchor}
        content={
          <DeleteAccountPopupContent
            disabled={!canDeleteAccountData?.data?.can_delete}
            onConfirm={handleConfirmDeleteAccountClick}
            onCancel={handleCancelDeleteAccountClick}
            message={deleteMessage ? deleteMessage : `Are you sure you want to delete account ${deleteAccountId}?`}
          />
        }
        arrow
        placement='bottom'
        onClose={() => setOpenDeletePopup(false)}
        open={openDeletePopup}
      />
      <Row className='mt-2'>
        <Col xs={12}>
          <CustomCard
            headerTitle={
              <div className='d-flex align-items-center justify-content-between'>
                <span>Accounts</span>
                <div className='d-flex align-items-center justify-content-end'>
                  <CustomButton
                    color='info'
                    className='add-button'
                    icon='fas fa-file-invoice-dollar'
                    style={{ marginLeft: 'auto', marginRight: '1rem' }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.target.blur();
                      onFilterActionBtn('add');
                    }}
                    disabled={!hasPermission(CHART_OF_ACCOUNTS, EDIT)}
                  >
                    Add New
                  </CustomButton>
                  <ExportButton
                    disabled={loader}
                    exportTablePDF={() => {
                      onFilterActionBtn('PDF');
                    }}
                    exportTableEXCEL={() => {
                      onFilterActionBtn('EXCEL');
                    }}
                    exportPagePDF={() => exportPdf(container, 'ChartOfAccount')}
                    exportPageEXCEL={() => exportExcel(tableData.data, excelHeader, 'ChartOfAccount')}
                  />
                </div>
              </div>
            }
            sxCardContainer={{ mt: 2 }}
          >
            <Row className='mb-3'>
              <Col md={12}>
                <CollapsableCard
                  headerTitle='Filters'
                  isExpanded={false}
                  titleTypographyProps={{ fontSize: '18px' }}
                  sxCardContent={{ padding: '16px' }}
                  sxCardHeader={{ padding: '16px' }}
                >
                  <ChartOfAccountFilter
                    loader={loader}
                    filter={filter}
                    blankFilter={blankFilter}
                    onSwitchChange={onSwitchChange}
                    onFilterActionClick={onFilterActionBtn}
                    onFilterChangeHandler={onFilterHandler}
                    onFilterDateRangeChange={onFilterDateRangeChange}
                    exportPagePDF={() => exportPdf(container, 'ChartOfAccount')}
                    exportPageEXCEL={() => exportExcel(tableData.data, excelHeader, 'ChartOfAccount')}
                  />
                </CollapsableCard>
              </Col>
            </Row>
            <div
              ref={(instance) => {
                setContainer(instance);
              }}
            >
              <DataTable dataSource={tableData?.data || blankData} onFilterChange={onFilterChange} columns={myColumn} loading={loader} />
            </div>
          </CustomCard>
        </Col>
      </Row>
    </>
  );
};

const DeleteAccountPopupContent = ({ onConfirm, onCancel, message = `Are you sure you want to delete this account?`, disabled }) => {
  return (
    <>
      <Box>
        <DialogContent sx={{ padding: '5px' }}>{message}</DialogContent>
        <DialogActions sx={{ padding: '5px' }}>
          <IconButton color='primary' onClick={onConfirm} disabled={disabled}>
            <DeleteForeverIcon />
          </IconButton>
          <IconButton onClick={onCancel}>
            <ClearIcon />
          </IconButton>
        </DialogActions>
      </Box>
    </>
  );
};

export { ChartOfAccountTable };
