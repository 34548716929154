import React from 'react';
import PropTypes from 'prop-types';

const defaultButton = (props) => <button {...props}>{props.children}</button>;

export default class DataTablePagination extends React.Component {
  constructor(props) {
    super(props);

    this.changePage = this.changePage.bind(this);

    this.state = {
      visiblePages: this.getVisiblePages(null, props.pages),
    };
  }

  static propTypes = {
    pages: PropTypes.number,
    page: PropTypes.number,
    PageButtonComponent: PropTypes.any,
    onPageChange: PropTypes.func,
    previousText: PropTypes.string,
    nextText: PropTypes.string,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.pages !== nextProps.pages) {
      this.setState(
        {
          visiblePages: this.getVisiblePages(1, nextProps.pages),
        },
        () => {
          const visiblePages = this.getVisiblePages(1, nextProps.pages);
          this.setState({
            visiblePages: this.filterPages(visiblePages, nextProps.pages),
          });
        }
      );
      this.changePage(nextProps.page + 1);
    }
  }

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter((page) => page <= totalPages);
  };

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  changePage(page) {
    const activePage = this.props.page + 1;

    if (page === activePage) {
      return;
    }

    const visiblePages = this.getVisiblePages(page, this.props.pages);

    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages),
    });

    this.props.onPageChange(page - 1);
  }
  onToggleDropDown = (option) => {
    this.props.onPageSizeChange(Number(option.target.value));
  };

  render() {
    const { PageButtonComponent = defaultButton } = this.props;
    const { visiblePages } = this.state;
    const activePage = this.props.page + 1;

    const pageSizeOptions = [...this.props.pageSizeOptions, 500];

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{}}>
          <select id='select' name='group' value={this.props.pageSize} className='form-control input-sm' size='1' onChange={this.onToggleDropDown}>
            {pageSizeOptions.map((x, key) => {
              return (
                <option key={key} value={x}>
                  {x}
                </option>
              );
            })}
          </select>
        </div>
        <div style={{}} className='Table__pagination'>
          <div className='Table__prevPageWrapper'>
            <PageButtonComponent
              className='Table__pageButton'
              onClick={() => {
                if (activePage === 1) return;
                this.changePage(activePage - 1);
              }}
              disabled={activePage === 1}
            >
              {this.props.previousText}
            </PageButtonComponent>
          </div>
          <div className='Table__visiblePagesWrapper'>
            {visiblePages.map((page, index, array) => {
              return (
                <PageButtonComponent
                  key={index}
                  className={activePage === page ? 'Table__pageButton Table__pageButton--active' : 'Table__pageButton'}
                  onClick={this.changePage.bind(null, page)}
                >
                  {array[index - 1] + 2 < page ? `...${page}` : page}
                </PageButtonComponent>
              );
            })}
          </div>
          <div className='Table__nextPageWrapper'>
            <PageButtonComponent
              className='Table__pageButton'
              onClick={() => {
                if (activePage + 1 > this.props.pages) return;
                this.changePage(activePage + 1);
              }}
              disabled={activePage + 1 > this.props.pages}
            >
              {this.props.nextText}
            </PageButtonComponent>
          </div>
        </div>
      </div>
    );
  }
}
