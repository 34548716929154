import { Box, Button, Divider } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { CustomInput } from '../../../../../../common/CustomInput';
import { CustomSelect } from '../../../../../../common/CustomSelect';
import { DatePickerComponent } from '../../../../../../common/DatePicker/DatePicker';
import { CustomCurrencyInput } from '../../../../../../common/Inputs/CurrencyInput';
import { CustomCheckbox } from '../../../../../../common/Inputs/CustomCheckbox';
import { CustomPageableAsyncSelect } from '../../../../../../common/Inputs/CustomPageableAsyncSelect';
import {
  ACC_TYPE_CUSTOMER,
  ACC_TYPE_GL_ACCOUNT,
  ACC_TYPE_VENDOR,
  vendorBalancePaymentAccountTypeOptions,
  vendorPaymentAccountTypeOptions,
} from '../../../../../../constants/accountType.constants';
import { CREDIT_CARD as BANK_CREDIT_CARD } from '../../../../../../constants/bankPaymentSource.constants';
import { CREDIT_CARD } from '../../../../../../constants/paymentTypeSource.constants';
import { BANK_PAYMENT_SOURCE, BRANCH_TABLE, PAYMENT_SOURCE, TRANSACTION_TYPES } from '../../../../../../constants/supportTables.constants';
import { useGetSupportTablesForSelect } from '../../../../../../hooks/commonApiHooks';
import { useAsyncPageable } from '../../../../../../hooks/useAsyncPageable';
import { notificationDanger } from '../../../../../../utils/toastify';
import {
  useBillingPayment,
  useBillingPaymentClient,
  useBillingPaymentItem,
  useBillingPaymentItemDocumentNumber,
  useClientUnpaidTransactionsOptions,
  useCreateBillingPaymentItems,
  useSelectedBalanceAccount,
  useUpdateBillingPaymentItem,
} from '../../hooks';
import { extractValidationErrorMessage } from '../../utils';
import { UnpaidTransactionsInfo } from '../clients/components/UnpaidTransactionsInfo';
import { roundNumber } from '../../../../../../utils/formatAmout';

/**
 * @property linkToPayment flag indicates linking payment item with payment
 */
export const BillingPaymentItemForm = ({
  id = null,
  clientId = null,
  linkToPayment = false,
  isEdit = false,
  billingId = null,
  transactionDueDate = null, // fetch transactions with due_date
  closeModal = () => {},
  multiple,
  multipleInsertData = [],
  callbacks = { mandatory: () => {}, success: () => {}, error: () => {} },
  defaultPostingDate,
}) => {
  const { clientType } = useParams();

  const blankForm = useMemo(() => {
    return {
      billing_payment_id: null,
      account_type: null,
      account_number: null,
      payment_source: null,
      document_number: null,
      transaction_type: null,
      posting_date: defaultPostingDate ? defaultPostingDate : null,
      description: '',
      amount: null,
      branch_id: null,
      bank_payment_source: null,
      balance_account_type: null,
      balance_account_number: null,
      invoices: [],
      // additional transaction data
      at_document_number: null,
      at_document_date: null,
      at_amount: null, // auto calculated
      at_description: null,
      pay_to_account_number: null,
    };
  }, []);

  const { fetchOptions } = useAsyncPageable();

  // flags
  const [shouldResetAccountNumber, setShouldResetAccountNumber] = useState(false);
  const [shouldResetPayToAccountNumber, setShouldResetPayToAccountNumber] = useState(false);
  const [shouldResetBalanceAccountNumber, setShouldResetBalanceAccountNumber] = useState(false);
  const [init, setInit] = useState(false);

  const [balanceAccountInputClicked, setBalanceAccountInputClicked] = useState(false);
  const [accountInputClicked, setAccountInputClicked] = useState(false);
  const [payToInputClicked, setPayToInputClicked] = useState(false);

  const [isAdditionalTransactionRequired, setIsAdditionalPaymentRequired] = useState(false);

  const [selectedClientId, setSelectedClientId] = useState(linkToPayment ? clientId : null);
  const [form, setForm] = useState(blankForm);

  // input labels
  const [accountLabel, setAccountLabel] = useState('');
  const [balAccountLabel, setBalAccountLabel] = useState('');
  const [payToAccountNumberLabel, setPayToAccountNumberLabel] = useState('');

  const { data: { data: supportTableOptions = {} } = {} } = useGetSupportTablesForSelect({
    table_names: [TRANSACTION_TYPES, PAYMENT_SOURCE, BANK_PAYMENT_SOURCE, BRANCH_TABLE],
  });
  const { data: billingPaymentData } = useBillingPayment(clientType, billingId);
  const { data: nextDocumentNumberData } = useBillingPaymentItemDocumentNumber(clientType, form.billing_payment_id);
  const { data: billingPaymentItemData, remove: clearBillingPaymentItemCache } = useBillingPaymentItem(clientType, id, billingId);
  const { data: balanceAccountData } = useSelectedBalanceAccount(clientType, form.billing_payment_id);
  const { data: clientData } = useBillingPaymentClient(clientType, selectedClientId);

  const { data: unpaidTransactionsOptionsData } = useClientUnpaidTransactionsOptions({
    clientType: form?.account_type == 1 ? 'customers' : clientType, // fetch customer options, or vendor by default
    clientId: selectedClientId,
    billingId,
    dueDate: linkToPayment ? transactionDueDate : null, // only from suggested vendors
  });

  const unpaidTransactionsOptions = useMemo(() => {
    // customer refund and vendor payment supported only
    return unpaidTransactionsOptionsData?.data;
  }, [unpaidTransactionsOptionsData]);

  const isChargeCardPayment = useMemo(() => {
    return billingPaymentData?.data.payment_source === CREDIT_CARD && billingPaymentData?.data.bank_payment_source === BANK_CREDIT_CARD;
  }, [billingPaymentData?.data]);

  const isDataInitialized = useMemo(() => {
    if (!isEdit) return true;

    if (isEdit && init) return true;

    return false;
  }, [isEdit, init]);

  const documentTypeDisabled = useMemo(() => {
    if ([ACC_TYPE_CUSTOMER, ACC_TYPE_VENDOR].includes(form.account_type)) {
      return true;
    }

    return false;
  }, [form.account_type]);

  const createOrUpdateSuccessCallback = () => {
    closeModal();
    callbacks.mandatory();
    setForm(blankForm);
  };

  const {
    mutate: createClientPaymentItems,
    error: errorCreate,
    isLoading,
  } = useCreateBillingPaymentItems({
    multiple,
    clientType,
    billingId: linkToPayment ? form['billing_payment_id'] : billingId,
    callbacks: {
      successCallback: createOrUpdateSuccessCallback,
    },
  });

  const disabledSave = useMemo(() => {
    return isLoading || (linkToPayment ? !form['billing_payment_id'] : !billingId);
  }, [isLoading, linkToPayment, billingId]);

  const { mutate: updateClientPaymentItem, error: errorUpdate } = useUpdateBillingPaymentItem(clientType, id, billingId, {
    successCallback: createOrUpdateSuccessCallback,
  });

  const calculateSelectedInvoiceSum = (options, selectedArray) => {
    const normalizedSelectedArray = selectedArray.map((inv) => +inv);
    const selectedInvoices = options?.filter((invoice) => normalizedSelectedArray.includes(+invoice.id));

    const currentAmount = selectedInvoices?.reduce((sum, invoice) => {
      // take partially applied amount of full amount
      const partiallyClearedAmount = invoice.partially_cleared_amount !== 0 ? invoice.partially_cleared_amount : Infinity;
      const balance = invoice.balance != 0 ? invoice.balance : Infinity;

      if (balance < 0 && partiallyClearedAmount < 0) {
        // both are negative, get greater
        sum += Math.max(+balance, +partiallyClearedAmount);
      } else {
        sum += Math.min(+balance, +partiallyClearedAmount);
      }
      return sum;
    }, 0.0);

    return roundNumber(currentAmount); // round to 2 decimals or set null if amount is zero
  };

  const billingPaymentOptions = useMemo(() => {
    return [{ name: form.billing_payment_id, value: form.billing_payment_id }];
  }, [form.billing_payment_id]);

  useEffect(() => {
    return () => {
      setForm(blankForm);
      clearBillingPaymentItemCache();
    };
  }, []);

  useEffect(() => {
    // auto select invoices
    if (clientData?.data?.payment_priority && unpaidTransactionsOptions?.length && !isEdit) {
      setForm((prev) => ({
        ...prev,
        invoices: unpaidTransactionsOptions.map((invoice) => invoice.id) || [],
      }));
    }

    if (clientData?.data) {
      const clientLabel = `${clientData.data.external_id} - ${clientData.data.name}`;
      setAccountLabel(clientLabel);

      if (isDataInitialized) {
        const parentLabel = `${clientData.data?.parent?.external_id} - ${clientData.data?.parent?.name}`;
        setPayToAccountNumberLabel(clientData.data?.parent?.external_id ? parentLabel : clientLabel);

        let description;

        if (isEdit) {
          description = form.description || '';
        } else {
          description = clientData.data?.name || '';
        }

        setForm((prev) => ({
          ...prev,
          description,
          pay_to_account_number: clientData.data?.parent?.id || clientData.data?.id,
        }));
      }
    }
  }, [isEdit, isDataInitialized, clientData?.data, unpaidTransactionsOptions]);

  useEffect(() => {
    if (billingPaymentData?.data && !isEdit) {
      const { payment_source, bank_payment_source } = billingPaymentData.data;
      setForm((prev) => ({
        ...prev,
        payment_source,
        bank_payment_source,
      }));
    }
  }, [billingPaymentData?.data, isEdit]);

  useEffect(() => {
    if (!isEdit) {
      setForm((prev) => ({ ...prev, billing_payment_id: billingId }));
    }
  }, [billingId, isEdit]);

  useEffect(() => {
    if (!isEdit) {
      // set default account/transaction type
      if (clientType === 'vendors') {
        setForm((prev) => ({
          ...prev,
          account_type: ACC_TYPE_VENDOR,
          transaction_type: 1,
        }));
      } else if (clientType === 'customers') {
        setForm((prev) => ({
          ...prev,
          account_type: ACC_TYPE_CUSTOMER,
          transaction_type: 2,
        }));
      }
    }
  }, [isEdit, clientType]);

  useEffect(() => {
    if (isDataInitialized) {
      if (form.account_type === ACC_TYPE_VENDOR) {
        setForm((prev) => ({ ...prev, transaction_type: 1 }));
      } else if (form.account_type === ACC_TYPE_CUSTOMER) {
        setForm((prev) => ({ ...prev, transaction_type: 2 }));
      }
    }
  }, [isDataInitialized, form.account_type]);

  useEffect(() => {
    if (!isEdit) {
      if (balanceAccountData?.data) {
        setForm((prev) => ({
          ...prev,
          balance_account_type: ACC_TYPE_GL_ACCOUNT,
          balance_account_number: balanceAccountData.data.number,
        }));

        setBalAccountLabel(`${balanceAccountData.data?.number} - ${balanceAccountData.data?.name}`);
      }
    }
  }, [isEdit, balanceAccountData?.data]);

  useEffect(() => {
    if (form['account_type'] !== ACC_TYPE_GL_ACCOUNT && !linkToPayment) {
      setSelectedClientId(form['account_number']);
    } else if (form['account_type'] === ACC_TYPE_GL_ACCOUNT && !linkToPayment) {
      setSelectedClientId(null);
    }
  }, [form['account_type'], form['account_number'], linkToPayment]);

  useEffect(() => {
    if (clientId && linkToPayment && !isEdit) {
      setSelectedClientId(clientId);

      setForm((prev) => ({
        ...prev,
        account_number: clientId,
      }));
    }
  }, [isEdit, clientId, linkToPayment]);

  useEffect(() => {
    if (isEdit && billingPaymentItemData?.data) {
      const { data } = billingPaymentItemData;

      setAccountLabel(`${data?.account?.number} - ${data?.account?.name}`);

      if (data?.pay_to_account) {
        setPayToAccountNumberLabel(`${data?.pay_to_account?.number} - ${data?.pay_to_account?.name}`);
      }

      setBalAccountLabel(`${data?.balance_account?.number} - ${data?.balance_account?.name}`);

      const formData = {
        account_number: data.account_number || data.client_id,
        account_type: data.account_type,
        amount: +data.amount,
        balance_account_number: data.balance_account_number || data.balance_client_id,
        balance_account_type: data.balance_account_type,
        bank_payment_source: data.bank_payment_source,
        payment_source: data.payment_source,
        document_number: data.document_number,
        transaction_type: data.transaction_type,
        posting_date: data.posting_date ? moment(data.posting_date).format('YYYY-MM-DD') : null,
        description: data.description,
        branch_id: data.branch_id,
        invoices: data.invoices.map((inv) => inv.toString()),
        // additional transaction data
        at_document_number: data.at_document_number?.trim() ?? data.document_number?.trim(),
        at_document_date: data.at_document_date,
        at_amount: data.at_amount,
        at_description: data.at_description,
        pay_to_account_number: data.pay_to_account_number || data.pay_to_client_id,
      };

      setForm(formData);
      setIsAdditionalPaymentRequired(!!billingPaymentItemData?.data.at_amount);
      setInit(true);
    }
  }, [billingPaymentItemData?.data, isEdit]);

  useEffect(() => {
    if (nextDocumentNumberData?.data) {
      const documentNumber = nextDocumentNumberData.data.next_number;

      if (!isEdit && form.document_number != documentNumber) {
        // keep document_number as integer, required for auto incrementing in create many mode
        setForm((prev) => ({ ...prev, document_number: documentNumber, at_document_number: documentNumber }));
      }
    }
  }, [isEdit, nextDocumentNumberData]);

  useEffect(() => {
    if (shouldResetBalanceAccountNumber) {
      setBalanceAccountInputClicked(!!form.balance_account_type);
      setForm((prevValue) => ({ ...prevValue, balance_account_number: null }));
      setBalAccountLabel('');
    }
  }, [form.balance_account_type, shouldResetBalanceAccountNumber]);

  useEffect(() => {
    if (shouldResetAccountNumber) {
      setAccountInputClicked(!!form.account_type);
      setForm((prevValue) => ({ ...prevValue, account_number: null }));
      setAccountLabel('');
    }
  }, [form.account_type, shouldResetAccountNumber]);

  useEffect(() => {
    if (shouldResetPayToAccountNumber) {
      setPayToInputClicked(false);
      setForm((prevValue) => ({ ...prevValue, pay_to_account_number: null }));
      setPayToAccountNumberLabel('');
    }
  }, [form.account_type, shouldResetPayToAccountNumber]);

  useEffect(() => {
    const currentAppliedAmount = calculateSelectedInvoiceSum(unpaidTransactionsOptions, form.invoices);

    const additional = isAdditionalTransactionRequired ? (form.at_amount ? form.at_amount : 0) : 0;
    const amount = roundNumber(currentAppliedAmount + +additional);

    setForm((prev) => ({
      ...prev,
      amount,
    }));
  }, [isAdditionalTransactionRequired, form.at_amount, form.invoices, unpaidTransactionsOptions]);

  const getAccountOptions = useCallback((accountType) => {
    if (accountType === ACC_TYPE_CUSTOMER) {
      return (inputValue, options) => fetchOptions('/me/client/customer/options-number-for-select', { q: inputValue, ...options, client_type_id: 1 });
    } else if (accountType === ACC_TYPE_VENDOR) {
      return (inputValue, options) => fetchOptions('/me/client/vendor/options-number-for-select', { q: inputValue, ...options, client_type_id: 2 });
    } else if (accountType === ACC_TYPE_GL_ACCOUNT) {
      return (inputValue, options) => fetchOptions('/me/account/options/accounts', { q: inputValue, ...options });
    } else {
      return () => ({
        options: [],
        hasMore: false,
      });
    }
  }, []);

  const handleChange = (e) => {
    const { type, name, value, checked } = e.target;
    const f = { ...form, [name]: type === 'radio' ? Number(value) : type === 'checkbox' ? checked : value };

    setForm((prevValue) => ({
      ...prevValue,
      [name]: type === 'radio' ? Number(value) : type === 'checkbox' ? checked : value,
    }));
  };

  const handleSelectChange = (val, e, isMulti) => {
    let event;

    if (e.action === 'select-option') {
      if (isMulti) {
        const values = val.map((v) => v.value || v.id);
        event = { target: { name: e.name, value: values } };
      } else {
        event = { target: { name: e.name, value: val.value } };
      }

      if (e.name === 'balance_account_number') {
        setBalAccountLabel(val.label);
      }

      if (e.name === 'account_number') {
        setAccountLabel(val.label);
      }

      if (e.name === 'pay_to_account_number') {
        setPayToAccountNumberLabel(val.label);
      }

      if (e.name === 'balance_account_type') {
        setShouldResetBalanceAccountNumber(true);
      }

      if (e.name === 'account_type') {
        setShouldResetAccountNumber(true);
        setShouldResetPayToAccountNumber(true);
      }

      handleChange(event);
      return;
    }

    if (e.action === 'remove-value') {
      let o;
      if (isMulti) {
        // value is removed in custom select component
        const values = val?.length ? val.map((v) => v.value || v.id) : [];
        o = { target: { name: e.name, value: values } };
      }
      handleChange(o);
    }

    if (e.action === 'clear') {
      let o;
      if (isMulti) {
        o = { target: { name: e.name, value: [] } };
      } else {
        o = { target: { name: e.name, value: null } };
      }
      handleChange(o);
    }
  };

  const handleDateChange = (date, name) => {
    setForm((prev) => ({
      ...prev,
      [name]: date,
    }));
  };

  const handleBillingPaymentItemCreateOrUpdate = () => {
    if (multiple) {
      const commonData = {
        account_type: form.account_type,
        balance_account_number: form.balance_account_number,
        balance_account_type: form.balance_account_type,
        bank_payment_source: form.bank_payment_source,
        billing_payment_id: form.billing_payment_id,
        payment_source: form.payment_source,
        transaction_type: form.transaction_type,
        posting_date: form.posting_date ? moment(form.posting_date).format('YYYY-MM-DD') : null,
        isAdditionalTransactionRequired, // always false for this use case?
      };

      if (multipleInsertData?.data?.length) {
        const normalizeDescription = (value) => {
          if (!value) {
            return '';
          }

          // split at 1st appearance of char
          const [_, description] = value.split(/-(.*)/s);

          return description;
        };

        const insertData = multipleInsertData.data
          .map((vendor, index) => {
            if (!vendor.invoices.length) {
              return;
            }

            return {
              ...commonData,
              account_number: vendor['client.id'],
              amount: vendor.total,
              description: normalizeDescription(vendor['client.name']),
              document_number: form.document_number + index,
              pay_to_account_number: vendor['client.vendor_parent'] || vendor['client.id'],
              invoices: [...vendor.invoices.map((invoice) => invoice['transactions.id'])],
            };
          })
          .filter((paymentData) => !!paymentData);

        createClientPaymentItems(insertData);
      }
    } else {
      const data = {
        ...form,
        posting_date: form.posting_date ? moment(form.posting_date).format('YYYY-MM-DD') : null,
        at_document_date: form.at_document_date ? moment(form.at_document_date).format('YYYY-MM-DD') : null,
        isAdditionalTransactionRequired,
        document_number: form?.document_number?.toString()?.trim(),
        at_document_number: form?.at_document_number?.toString()?.trim() || null,
      };

      if (!isAdditionalTransactionRequired) {
        delete data.at_amount;
        delete data.at_document_number;
        delete data.at_document_date;
        delete data.at_description;
      }

      delete data.billing_payment_id;

      const invoicesTotal = calculateSelectedInvoiceSum(unpaidTransactionsOptions, form.invoices);
      if (+invoicesTotal > +form.amount) {
        notificationDanger('Please increase amount!');
        return;
      }

      if (isEdit) {
        updateClientPaymentItem({ ...data });
      } else {
        createClientPaymentItems([{ ...data }]);
      }
    }
  };

  const shouldStyleTransactionOption = useCallback((value) => {
    const { partially_cleared_amount, balance } = value;
    return partially_cleared_amount && partially_cleared_amount != balance;
  }, []);

  const accountNumberInput = useMemo(() => {
    const selectProps = {
      label: 'Account Number',
      isClearable: true,
      onChange: handleSelectChange,
      name: 'account_number',
      errorMess: extractValidationErrorMessage([errorCreate, errorUpdate], 'account_number'),
    };

    if (!billingPaymentItemData?.data) {
      if (linkToPayment) {
        return <CustomInput {...selectProps} value={accountLabel} onClick={() => {}} onChange={() => {}} />;
      }

      return <CustomPageableAsyncSelect {...selectProps} defaultValue={form.account_number} loadOptions={getAccountOptions(form.account_type)} />;
    }

    if (accountInputClicked) {
      return (
        <CustomPageableAsyncSelect
          {...selectProps}
          defaultValue={form.account_number}
          onFocusOut={() => setAccountInputClicked(false)}
          loadOptions={getAccountOptions(form.account_type)}
        />
      );
    }

    return <CustomInput {...selectProps} value={accountLabel} onClick={() => setAccountInputClicked(true)} onChange={() => {}} />;
  }, [form, billingPaymentItemData, accountInputClicked, accountLabel, errorCreate, errorUpdate]);

  const payToAccountInput = useMemo(() => {
    const selectProps = {
      label: 'Pay To Account Number',
      isClearable: true,
      onChange: handleSelectChange,
      name: 'pay_to_account_number',
      errorMess: extractValidationErrorMessage([errorCreate, errorUpdate], 'pay_to_account_number'),
    };

    if (payToInputClicked) {
      return (
        <CustomPageableAsyncSelect
          {...selectProps}
          defaultValue={form.pay_to_account_number}
          onFocusOut={() => setPayToInputClicked(false)}
          loadOptions={getAccountOptions(form.account_type)}
        />
      );
    }

    return <CustomInput {...selectProps} value={payToAccountNumberLabel} onClick={() => setPayToInputClicked(true)} onChange={() => {}} />;
  }, [form, billingPaymentItemData, payToInputClicked, payToAccountNumberLabel, errorCreate, errorUpdate]);

  const balAccountNumberInput = useMemo(() => {
    const selectProps = {
      label: 'Bal. Account Number',
      isClearable: true,
      onChange: handleSelectChange,
      name: 'balance_account_number',
      errorMess: extractValidationErrorMessage([errorCreate, errorUpdate], 'balance_account_number'),
    };
    // cant change value on linkToPayment when is not charge card payment
    if ((linkToPayment || multiple) && !isChargeCardPayment) {
      return <CustomInput {...selectProps} value={balAccountLabel} onClick={() => setBalanceAccountInputClicked(true)} onChange={() => {}} />;
    }

    if (balanceAccountInputClicked) {
      return (
        <CustomPageableAsyncSelect
          {...selectProps}
          defaultValue={form.balance_account_number}
          loadOptions={getAccountOptions(form.balance_account_type)}
          onFocusOut={() => setBalanceAccountInputClicked(false)}
        />
      );
    }

    return (
      <CustomInput
        {...selectProps}
        value={balAccountLabel}
        disabled={!form.balance_account_type}
        onClick={() => setBalanceAccountInputClicked(true)}
        onChange={() => {}}
      />
    );
  }, [form, billingPaymentItemData, balanceAccountInputClicked, linkToPayment, balAccountLabel, errorCreate, errorUpdate]);

  return (
    <>
      <Row>
        <Col>
          {/* same picker rendered on 2 different positions depends on form */}
          {!linkToPayment && !multiple && (
            <DatePickerComponent
              showYearDropdown
              name='posting_date'
              selected={defaultPostingDate || null}
              onChange={(date) => handleDateChange(date, 'posting_date')}
              placeholder='mm/dd/yyyy'
              dateFormat={'MM/dd/yyyy'}
              label='Posting Date'
              errorMess={extractValidationErrorMessage([errorCreate, errorUpdate], 'posting_date')}
              disabled
            />
          )}
          {(linkToPayment || multiple) && (
            <CustomSelect
              label='Payment Id'
              onChange={handleSelectChange}
              name='billing_payment_id'
              defaultValue={form['billing_payment_id'] || null}
              options={billingPaymentOptions || []}
              errorMess={extractValidationErrorMessage([errorCreate, errorUpdate], 'billing_payment_id')}
            />
          )}
          <CustomSelect
            label='Account Type'
            onChange={(val, e) => {
              if (!linkToPayment && !multiple) {
                handleSelectChange(val, e);
              } else {
                () => {};
              }
            }}
            name='account_type'
            defaultValue={form['account_type'] || null}
            options={vendorPaymentAccountTypeOptions || []}
            errorMess={extractValidationErrorMessage([errorCreate, errorUpdate], 'account_type')}
          />
          <CustomSelect
            label='Payment Method'
            disabled={!!form.payment_source}
            onChange={handleSelectChange}
            name='payment_source'
            defaultValue={form['payment_source'] || null}
            options={supportTableOptions[PAYMENT_SOURCE] || []}
            errorMess={extractValidationErrorMessage([errorCreate, errorUpdate], 'payment_source')}
          />
          <CustomSelect
            label='Bal. Account Type'
            onChange={(val, e) => {
              if ((linkToPayment || multiple) && !isChargeCardPayment) {
                return () => {};
              } else {
                return handleSelectChange(val, e);
              }
            }}
            name='balance_account_type'
            defaultValue={form['balance_account_type'] || null}
            options={vendorBalancePaymentAccountTypeOptions || []}
            errorMess={extractValidationErrorMessage([errorCreate, errorUpdate], 'balance_account_type')}
          />
          {!multiple && (
            <CustomCurrencyInput
              name='amount'
              value={form.amount || ''}
              onChange={(value) => {
                const e = { target: { name: 'amount', value } };
                handleChange(e);
              }}
              label='Amount'
              decimalsLimit={2}
              prefix={'$ '}
              disabled
              placeholder=''
              errorMess={extractValidationErrorMessage([errorCreate, errorUpdate], 'amount')}
            />
          )}
        </Col>
        {/* 2nd tab */}
        <Col>
          <CustomSelect
            label='Document Type'
            onChange={handleSelectChange}
            name='transaction_type'
            disabled={documentTypeDisabled}
            defaultValue={form['transaction_type'] || null}
            options={supportTableOptions[TRANSACTION_TYPES] || []}
            errorMess={extractValidationErrorMessage([errorCreate, errorUpdate], 'transaction_type')}
          />
          {!multiple && accountNumberInput}
          {!multiple && payToAccountInput}
          <CustomSelect
            label='Bank Payment Method'
            disabled={!!form.bank_payment_source}
            onChange={handleSelectChange}
            name='bank_payment_source'
            defaultValue={form['bank_payment_source'] || null}
            options={supportTableOptions[BANK_PAYMENT_SOURCE] || []}
            errorMess={extractValidationErrorMessage([errorCreate, errorUpdate], 'bank_payment_source')}
          />
          {balAccountNumberInput}
        </Col>
        {/* 3rd tab */}
        <Col>
          <CustomInput
            label='Document Number'
            min={0}
            onChange={handleChange}
            name='document_number'
            value={form.document_number || ''}
            errorMess={extractValidationErrorMessage([errorCreate, errorUpdate], 'document_number')}
          />

          {!multiple && (
            <CustomSelect
              markedOptions={{ marked: shouldStyleTransactionOption }}
              isMulti
              closeMenuOnSelect={false}
              name='invoices'
              defaultValue={form.invoices}
              label='Invoice Number'
              onChange={(val, e) => handleSelectChange(val, e, true)}
              options={unpaidTransactionsOptions || []}
              isClearable
            />
          )}

          {(linkToPayment || multiple) && (
            <DatePickerComponent
              showYearDropdown
              name='posting_date'
              selected={defaultPostingDate || null}
              onChange={(date) => handleDateChange(date, 'posting_date')}
              placeholder='mm/dd/yyyy'
              dateFormat={'MM/dd/yyyy'}
              label='Posting Date'
              errorMess={extractValidationErrorMessage([errorCreate, errorUpdate], 'posting_date')}
              disabled
            />
          )}

          {!multiple && <CustomInput label='Description' onChange={handleChange} name='description' value={form['description'] || ''} />}
          {form.account_type === ACC_TYPE_GL_ACCOUNT && (
            <CustomSelect
              name='branch_id'
              defaultValue={form.branch_id}
              label='Branch'
              onChange={handleSelectChange}
              options={supportTableOptions[BRANCH_TABLE]}
              isClearable
            />
          )}
        </Col>
      </Row>
      {!multiple && (
        <>
          <Divider className='mt-3 mb-3' />
          <Row>
            <Col md={3}>
              <CustomCheckbox
                label='Additional Payment'
                name='subAccount'
                checked={isAdditionalTransactionRequired}
                onChange={(e) => {
                  setIsAdditionalPaymentRequired(e.target.checked);
                }}
              />
            </Col>
          </Row>
        </>
      )}

      <Box sx={{ minHeight: '175px' }}>
        {isAdditionalTransactionRequired && (
          <>
            <Divider className='mt-3 mb-3' />
            <Row>
              <Col>Additional Payment</Col>
            </Row>
            <Row className='mb-3 mt-3'>
              <Col>
                <CustomInput
                  label='Document Number'
                  min={0}
                  onChange={handleChange}
                  name='at_document_number'
                  value={form?.at_document_number || ''}
                  errorMess={extractValidationErrorMessage([errorCreate, errorUpdate], 'at_document_number')}
                />
              </Col>
              <Col>
                <DatePickerComponent
                  showYearDropdown
                  name='at_document_date'
                  selected={form.at_document_date}
                  onChange={(date) => handleDateChange(date, 'at_document_date')}
                  placeholder='mm/dd/yyyy'
                  dateFormat={'MM/dd/yyyy'}
                  label='Document Date'
                  errorMess={extractValidationErrorMessage([errorCreate, errorUpdate], 'at_document_date')}
                />
              </Col>
              <Col>
                <CustomInput label='Description' onChange={handleChange} name='at_description' value={form['at_description'] || ''} />
              </Col>
              <Col>
                <CustomCurrencyInput
                  name='at_amount'
                  value={form.at_amount || null}
                  onChange={(value) => {
                    const e = { target: { name: 'at_amount', value: value ?? null } };
                    handleChange(e);
                  }}
                  label='Amount'
                  decimalsLimit={2}
                  prefix={'$ '}
                  placeholder=''
                  errorMess={extractValidationErrorMessage([errorCreate, errorUpdate], 'at_amount')}
                />
              </Col>
            </Row>
          </>
        )}
        <Divider className='mt-3 mb-3' />
        {multiple && (
          <>
            <UnpaidTransactionsInfo data={multipleInsertData} />
            <Divider className='mt-3 mb-3' />
          </>
        )}
      </Box>
      <Row>
        {/*  */}
        <Col className='d-flex justify-content-end'>
          <Button onClick={handleBillingPaymentItemCreateOrUpdate} color='success' disabled={disabledSave}>
            Save
          </Button>
        </Col>
      </Row>
    </>
  );
};
