import { useQueries, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { accountProfitLossType } from '../../../../constants/accountProfitLossType.constants';

const composeUrl = (accountType) => {
  const baseUrl = '/me/report/profit-loss';

  if (accountType) {
    return `${baseUrl}/${accountType}`;
  }

  return baseUrl;
};

const getProfitLoss = (filters) => {
  const url = composeUrl();

  return axios.get(url, { params: { ...filters } });
};

export const useProfitLoss = (filters) => {
  return useQuery(['reports.profit-loss', filters], () => getProfitLoss(filters), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const getByAccountType = (accountType, filters) => {
  const url = composeUrl(accountType);
  return axios.get(url, { params: { ...filters } });
};

export const useProfitLossByAccountType = (accountType, filters, { enabled }) => {
  const { page, pageSize, sort_by, order_by } = filters;
  // returns all accounts
  return useQuery([`reports.profit-loss.${accountType}`, sort_by, order_by], () => getByAccountType(accountType, filters), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // suspense: true,
    enabled,
    retry: 1,
  });
};

export const useProfitLossByAccountTypes = (filters) =>
  useQueries({
    queries: Object.keys(accountProfitLossType).map((accountType) => {
      return {
        queryKey: [`reports.profit-loss.${accountType}`, accountType],
        queryFn: () => getByAccountType(accountType, filters),
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        suspense: true,
        retry: 1,
      };
    }),
  });

const getProfitLostTotal = (filters) => {
  const baseUrl = composeUrl();
  return axios.get(`${baseUrl}/total`, { params: { ...filters } });
};

export const useProfitLossTotal = (filters) => {
  const { page, pageSize, sort_by, order_by, ...otherFilters } = filters;
  return useQuery(['reports.profit-loss.total', otherFilters], () => getProfitLostTotal(filters), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};
