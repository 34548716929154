import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { notificationSuccess } from '../../../utils/toastify';
import { customDownloader } from '../../../utils/downloaderFiles';

const createBankReco = (data) => {
  return axios.post('/me/bank/bank-reconciliation', data);
};

export const useCreateBankReco = (successCb) => {
  return useMutation(createBankReco, {
    onSuccess: (data) => {
      notificationSuccess('Successfully created Bank Reconciliation');
      successCb && successCb(data?.data[0]);
    },
  });
};

const getBankRecoTableData = (filter) => {
  return axios.get('/me/bank/bank-reconciliation', { params: filter });
};

export const useGetBankRecoTableData = (filter) => {
  return useQuery(['bank-reco-table', filter.page, filter.pageSize, filter.sort_by, filter.order_by], () => getBankRecoTableData(filter), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const getBancRecoData = (filter) => {
  return axios.get('/me/bank/bank-reconciliation/get-one', { params: filter });
};

export const useGetBancRecoData = (filter) => {
  return useQuery(['banc-reco-data', filter.account], () => getBancRecoData(filter), {
    refetchOnWindowFocus: false,
    enabled: !!filter.id || !!filter.account,
  });
};

const getLastBancRecoData = (filter) => {
  return axios.get('/me/bank/bank-reconciliation/get-last-reco', { params: filter });
};

export const useGetLastBancRecoData = (filter) => {
  return useQuery(['last-banc-reco-data', filter.account], () => getLastBancRecoData(filter), {
    refetchOnWindowFocus: false,
    enabled: !!filter.account,
  });
};

const updateBancReco = (data) => {
  const { id, ...rest } = data;
  return axios.patch(`/me/bank/bank-reconciliation/${id}`, rest);
};

export const useUpdateBancReco = (successCb, account) => {
  const queryClient = useQueryClient();
  return useMutation(updateBancReco, {
    onSuccess: () => {
      queryClient.invalidateQueries(['banc-reco-data', account]);
      successCb && successCb();
    },
  });
};

const reopenReconciliation = (data) => {
  const { id, ...rest } = data;
  return axios.patch(`/me/bank/bank-reconciliation/${id}/reopen`, rest);
};

export const useReopenReco = () => {
  const queryClient = useQueryClient();
  return useMutation(reopenReconciliation, {
    onSuccess: () => {
      queryClient.invalidateQueries(['bank-reco-table']);
    },
  });
};

const deleteBancReco = (id) => {
  return axios.delete(`/me/bank/bank-reconciliation/${id}`);
};

export const useDeleteBancReco = (successCb) => {
  const queryClient = useQueryClient();
  return useMutation(deleteBancReco, {
    onSuccess: () => {
      queryClient.invalidateQueries(['bank-reco-table']);
      notificationSuccess('Successfully deleted reconciliation entry!');
      successCb && successCb();
    },
  });
};

const getGlTable = (filter) => {
  return axios.get('/me/bank/bank-reconciliation/get-gl-table', { params: filter });
};

export const useGetGlTable = (filter) => {
  return useQuery(
    ['get-gl-table', filter.page, filter.pageSize, filter.sort_by, filter.order_by, filter.tableType, filter.statment_date, filter.reportFilters],
    () => getGlTable(filter),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!filter.account,
    }
  );
};

const getMarkAll = (filter) => {
  return axios.get('/me/bank/bank-reconciliation/get-mark-all', { params: filter });
};

export const useGetMarkAll = (successCb) => {
  return useMutation(getMarkAll, {
    onSuccess: (data) => {
      successCb && successCb(data.data);
    },
  });
};

const reconciliate = (data) => {
  return axios.post('/me/bank/bank-reconciliation/reconciliate', data);
};

export const useReconciliateMutation = (successCb) => {
  return useMutation(reconciliate, {
    onSuccess: () => {
      successCb && successCb();
    },
  });
};

const summaryPreview = (query) => {
  return axios.get('/me/bank/bank-reconciliation/report/summary-preview', { params: query });
};

export const useSummaryReportPreview = (successCb) => {
  return useMutation(summaryPreview, {
    onSuccess: () => {
      successCb && successCb();
    },
  });
};

const summaryDownload = (query) => {
  return customDownloader({ url: '/me/bank/bank-reconciliation/report/summary', fileType: query?.exportType || 'PDF', params: query });
};

export const useSummaryReportDownload = (successCb) => {
  return useMutation(summaryDownload, {
    onSuccess: () => {
      successCb && successCb();
    },
  });
};

const downloadDetailedRReport = (query) => {
  return customDownloader({ url: '/me/bank/bank-reconciliation/report/detail', fileType: 'PDF', params: query });
};

export const useDetailedReportDownload = (successCb) => {
  return useMutation(downloadDetailedRReport, {
    onSuccess: () => {
      successCb && successCb();
    },
  });
};

const downloadRecoItems = (data) => {
  const { id, ...options } = data;
  return customDownloader({ url: `/me/bank/bank-reconciliation/${id}/download-items`, fileType: 'EXCEL', params: options });
};

export const useDownloadRecoItems = (id) => {
  return useMutation((data) => downloadRecoItems({ ...data, id }));
};
