import { Checkbox } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatAmount } from '../../../../../utils/formatAmout';
import { formatDateForTable } from '../../../../../utils/formatDataForTable';

export const columns = (clientType, Actions) => [
  {
    id: 'document_number',
    Header: <div>Batch Number</div>,
    Cell: (row) => <Link to={`/client/${clientType}/payment/${row.original.id}`}>{row.value}</Link>,
    accessor: (d) => d['document_number'],
    sortable: true,
  },
  {
    id: 'amount',
    Header: <div>Total Amount</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    accessor: (d) => d['amount'],
    sortable: true,
  },
  {
    id: 'bank_account_number',
    Header: <div>Bank Account</div>,
    Cell: (row) => {
      const displayValue = `${row?.original?.bank_account?.number ?? ''} - ${row?.original?.bank_account?.name ?? ''}`;
      return <div>{displayValue}</div>;
    },
    accessor: (d) => d['bank_account_number'],
    sortable: true,
  },
  {
    id: 'payment_source',
    Header: <div>Payment Method</div>,
    Cell: (row) => {
      return <>{row?.original?.payment_method?.name}</>;
    },
    accessor: (d) => d['payment_method.name'],
    sortable: true,
  },
  {
    id: 'document_date',
    Header: <div>Document Date</div>,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    accessor: (d) => d['document_date'],
    sortable: true,
  },
  {
    id: 'posting_date',
    Header: <div>Posting Date</div>,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    accessor: (d) => d['posting_date'],
    sortable: true,
  },
  {
    id: 'billing_date',
    Header: <div>Billing Date</div>,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    accessor: (d) => d['billing_date'],
    sortable: true,
  },
  {
    id: 'create_at',
    Header: <div>Created Date</div>,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    accessor: (d) => d['create_at'],
    sortable: true,
  },
  {
    id: 'finished',
    Header: <div>Posted</div>,
    Cell: (row) => {
      return <div>{<Checkbox checked={row.value} onClick={() => {}} />}</div>;
    },
    accessor: (d) => d['finished'],
    sortable: true,
  },
  {
    id: 'actions',
    sortable: false,
    Header: 'Actions',
    accessor: (row) => {
      return <div className='d-flex justify-content-center'>{Actions && React.cloneElement(Actions, { row })}</div>;
    },
  },
];

export const excelHeaders = [
  { header: 'DOCUMENT NUMBER', key: 'document_number' },
  { header: 'TOTAL AMOUNT', key: 'amount', style: { numFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00' } },
  { header: 'BANK ACCOUNT', key: 'bank_account_details' },
  { header: 'DOCUMENT DATE', key: 'document_date' },
  { header: 'POSTING DATE', key: 'posting_date' },
  { header: 'BILLING DATE', key: 'billing_date' },
  { header: 'CREATED DATE', key: 'create_at' },
  { header: 'FINISHED', key: 'finished' },
];
