import React from 'react';
import { Row, Col } from 'reactstrap';

import { CollapsableCard } from '../../../../common/Card/CollapsableCard';
import { CustomSelect } from '../../../../common/CustomSelect';
import { CustomPageableAsyncSelect } from '../../../../common/Inputs/CustomPageableAsyncSelect';
import { CustomInput } from '../../../../common/CustomInput';
import { DatePickerComponent } from '../../../../common/DatePicker/DatePicker';
import { useAsyncPageable } from '../../../../hooks/useAsyncPageable';
import { useGetFixedAssetsCodebooks } from '../FixedAsettesApiRoutes';
import { useGetSupportTableForSelect } from '../../Settings/SupportTables/supportTableApiHooks';
import { ASSET_TYPE, LIEN_HOLDER } from '../../../../constants/fixedAssetsCodebookType.constants';
import { Button, Divider } from '@mui/material';

export const FixedAssetsTableFilters = ({ setFilters, filters, refetch, exportToExcel }) => {
  const yesNoOptions = React.useMemo(
    () => [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    []
  );

  const { fetchOptions } = useAsyncPageable();
  const { data: { data: codebooks = {} } = {} } = useGetFixedAssetsCodebooks([ASSET_TYPE, LIEN_HOLDER]);
  const { data: { data: supportTables = {} } = {} } = useGetSupportTableForSelect(['branch']);

  const loadUnitNumberOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/fixed-assets/unit-num-for-select', {
      q: inputValue,
      ...options,
    });

    return data;
  };

  const loadFixedAssetsVendors = async (inputValue, options) => {
    const data = await fetchOptions('/me/fixed-assets/vendors-for-select', {
      q: inputValue,
      ...options,
    });

    return data;
  };

  const onChangeSelect = (val, e) => {
    const { name } = e;
    setFilters((oldFilters) => ({ ...oldFilters, [name]: val ? val.value : null }));
  };

  const onChangeAsyncSelect = (val, e) => {
    const { name } = e;
    setFilters((oldFilters) => ({ ...oldFilters, [name]: val ? val.value : null }));
  };

  const handleDateChange = (date, name) => {
    setFilters((oldFilters) => ({ ...oldFilters, [name]: date }));
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setFilters((oldFilters) => ({ ...oldFilters, [name]: value }));
  };

  return (
    <CollapsableCard isExpanded={false} headerTitle='Filters' titleTypographyProps={{ fontSize: '16px' }} sxCardHeader={{ padding: '12px' }}>
      <Row>
        <Col md='3'>
          <CustomSelect
            isClearable
            label='Asset Type'
            defaultValue={filters.asset_type || null}
            onChange={onChangeSelect}
            name='asset_type'
            options={codebooks[ASSET_TYPE] || []}
          />
        </Col>
        <Col md='3'>
          <CustomPageableAsyncSelect
            label='Unit #'
            isClearable
            onChange={onChangeAsyncSelect}
            loadOptions={loadUnitNumberOptions}
            name='unit_number'
            defaultValue={filters.unit_number || null}
          />
        </Col>
        <Col md='3'>
          <CustomSelect
            isClearable
            label='Branch'
            defaultValue={filters.branch || null}
            onChange={onChangeSelect}
            name='branch'
            options={supportTables['branch'] || []}
          />
        </Col>
        <Col md='3'>
          <CustomSelect
            isClearable
            label='Active'
            defaultValue={filters.active || null}
            onChange={onChangeSelect}
            name='active'
            options={yesNoOptions}
          />
        </Col>
      </Row>
      <Row>
        <Col md='3'>
          <CustomSelect
            isClearable
            label='On Rent'
            defaultValue={filters.on_rent || null}
            onChange={onChangeSelect}
            name='on_rent'
            options={yesNoOptions}
          />
        </Col>
        <Col md='3'>
          <CustomSelect
            isClearable
            label='Disposed'
            defaultValue={filters.disposed || null}
            onChange={onChangeSelect}
            name='disposed'
            options={yesNoOptions}
          />
        </Col>
        <Col md='3'>
          <DatePickerComponent
            onChange={(date) => handleDateChange(date, 'purchase_date')}
            showYearDropdown
            isClearable
            name='purchase_date'
            selected={filters.purchaseDate || null}
            placeholder='mm/dd/yyyy'
            dateFormat={'MM/dd/yyyy'}
            label='Purchase Date'
          />
        </Col>
        <Col md='3'>
          <CustomInput
            label='Book Value'
            type='text'
            name='book_value'
            value={filters.book_value}
            placeholder='Book Value'
            onChange={onChangeInput}
          />
        </Col>
      </Row>
      <Row>
        <Col md='3'>
          <CustomPageableAsyncSelect
            label='Vendor'
            isClearable
            onChange={onChangeAsyncSelect}
            loadOptions={loadFixedAssetsVendors}
            name='vendor'
            defaultValue={filters.vendor || null}
          />
        </Col>
        <Col md='3'>
          <CustomSelect
            isClearable
            label='Lien Holder'
            defaultValue={filters.lien_holder || null}
            onChange={onChangeSelect}
            name='lien_holder'
            options={codebooks[LIEN_HOLDER] || []}
          />
        </Col>
      </Row>
      <Row className='mb-1'>
        <Col>
          <Divider />
        </Col>
      </Row>
      <Row>
        <Col className='d-flex justify-content-end'>
          <Button color='success' className='mr-2' onClick={exportToExcel}>
            Export Excel
          </Button>
          <Button color='primary' onClick={refetch}>
            Search
          </Button>
        </Col>
      </Row>
    </CollapsableCard>
  );
};
