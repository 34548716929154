import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const getDocumentNumberData = (filter) => {
  return axios.get(`/me/transaction/s/`, { params: { ...filter } });
};

const getBankAccounts = () => {
  return axios.get('/me/account/bank-account-for-select');
};

const getSupportTablesForSelect = (filter) => {
  return axios.get('/me/repository/support-table/select', { params: { ...filter } });
};

export const useGetDocumentNumberData = (filter) => {
  return useQuery(['transaction/s', filter], () => getDocumentNumberData(filter), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

export const useGetBankAccount = () => {
  return useQuery(['/account/bank-account-for-select'], () => getBankAccounts(), {
    refetchOnWindowFocus: false,
  });
};

export const useGetSupportTablesForSelect = (filter) => {
  return useQuery(['support-tables-for-select', filter], () => getSupportTablesForSelect(filter), {
    refetchOnWindowFocus: false,
  });
};

const getStateOptions = () => {
  return axios.get('/me/repository/states');
};

export const useStateOptions = () => {
  return useQuery(['options.states'], () => getStateOptions(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const getNationalities = () => {
  return axios.get('/me/repository/nationalities');
};

export const useNationalityOptions = () => {
  return useQuery(['options.nationalities'], () => getNationalities(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const getAccountTypeOptions = (code) => {
  return axios.get('/me/repository/account-types', { params: { code } });
};

export const useBalanceSheetOptions = () => {
  return useQuery(['options.account-types.balance-sheet'], () => getAccountTypeOptions('balance sheet'), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

export const useCashFlowOptions = () => {
  return useQuery(['options.account-types.cash-flow'], () => getAccountTypeOptions('cash flow'), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

export const useProfitLossOptions = () => {
  return useQuery(['options.account-types.profit-loss'], () => getAccountTypeOptions('profit loss'), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const getGroupedAccountTypeOptions = () => {
  return axios.get('/me/repository/account-types/grouped');
};

export const useGroupedAccountTypeOptions = () => {
  return useQuery(['options.account-types.grouped'], () => getGroupedAccountTypeOptions(), { keepPreviousData: true, refetchOnWindowFocus: false });
};

const getNormalBalanceOptions = () => {
  return axios.get(`me/repository/account-types/balance`);
};

export const useNormalBalanceOptions = () => {
  return useQuery(['options.account.normal-balance'], () => getNormalBalanceOptions(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const getPaymentTypes = () => {
  return axios.get('/me/bank/payment-wizard/payment-types-select');
};

export const useGetPaymentTypes = () => {
  return useQuery(['/bank/payment-wizard/payment-types-select'], () => getPaymentTypes(), {
    refetchOnWindowFocus: false,
  });
};
