import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

export const exportExcel = async (data, metaData, name) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet(name);
  worksheet.columns = metaData;
  worksheet.getRow(1).eachCell((cell, number) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '4167B8' },
      bgColor: { argb: '' },
    };
    cell.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
    };
  });
  for (const r of data) {
    worksheet.addRow(r);
  }
  worksheet.columns.forEach((column) => {
    column.width = column.header.length < 12 ? 16 : column.header.length + 4;
  });
  worksheet.addRow([]);
  const xls64 = await workbook.xlsx.writeBuffer({ base64: true });
  saveAs(
    new Blob([xls64], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
    `${name} ${new Date().getFullYear()}.xlsx`
  );
};
