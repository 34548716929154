import React, { useEffect, useState } from 'react';
import { Alert, Col, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { CustomButton } from '../../../../common/Button/CustomButton';
import { useCreateClient } from '../clientHooks';

const defaultMessage = { msg: '', show: false, color: 'primary' };

export default function ClientCreateForm({ clientType, open, onToggle, filter }) {
  const [form, setForm] = useState({ name: '', external_id: '' });
  const [buttonOptions, setButtonOptions] = useState(true);
  const [errorMsg, setErrorMsg] = useState(defaultMessage);

  const { mutate: createClient, isSuccess, isIdle } = useCreateClient(clientType, filter);

  useEffect(() => {
    if (isSuccess && !isIdle) {
      setErrorMsg({ msg: 'Success Created', show: true, color: 'success' });
    } else if (!isSuccess && !isIdle) {
      setErrorMsg({ msg: 'Error', show: true, color: 'danger' });
    }

    setButtonOptions(!isSuccess);
  }, [isSuccess, isIdle]);

  useEffect(() => {
    if (!open) {
      setErrorMsg(defaultMessage);
      setButtonOptions(true);
    }

    setForm({ name: '', external_id: '' });
  }, [open]);

  const onChange = (e) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onFormSubmit = (e) => {
    setButtonOptions(false);
    createClient(form);
  };

  return (
    <>
      <Modal size='sm' toggle={onToggle} isOpen={open}>
        <ModalHeader className='bg-info' toggle={onToggle}>
          New {clientType.slice(0, -1)}{' '}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12} className='mb-2'>
              <Label> Client Name:</Label>
              <Input type='text' name='name' value={form['name']} onChange={onChange} />
            </Col>
            <Col md={12} className='mb-2'>
              <Label> External_id:</Label>
              <Input type='text' name='external_id' value={form['external_id']} onChange={onChange} />
            </Col>
            <Col md={12} className='mb-2'>
              <Alert className='p-0 align-center mt-2' color={errorMsg.color} isOpen={errorMsg.show}>
                <p className='align-center text-center m-0'> {errorMsg.msg}</p>
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <CustomButton
                hidden={!buttonOptions}
                onClick={onFormSubmit}
                disabled={form['name'].length < 4}
                style={{ margin: '0 auto', display: 'block' }}
                className='add-button btn box-shadow'
                color='info'
                icon='fa fa-plus'
              >
                Add
              </CustomButton>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}
