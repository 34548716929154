import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { PaymentOutlined } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import PreviewIcon from '@mui/icons-material/Preview';
import { Box, DialogActions, DialogContent, IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useEffect, useMemo } from 'react';
import { CustomDialog } from '../../../../../../common/Dialog/CustomDialog';
import { CustomPopup } from '../../../../../../common/Popup/CustomPopup';
import { TransactionPdfPreview } from './TransactionPdfPreview';
import { useTransactionPdfExporter, useSendTransactionEmail } from './clientTransactionsHooks';
import { emailSendErrorCallback, emailSendSuccessCallback, pdfExportErrorCallback } from './utils/pdfAndEmailCallbackUtils';
import { hasPermissionHook } from '../../../../../../hooks/hasPermission';
import { ALL_TRANSACTIONS, C_TRANSACTIONS, EDIT, V_TRANSACTIONS } from '../../../../../../constants/permissions.constants';

/**
 *
 * @param {boolean} overlayModal display Popper over open Modal
 * @param {boolean} overlayPopup display Modal over open Popper, case with 2 modals open
 */
const TransactionTableActions = ({ row, transactionType = 'Transaction', overlayModal, overlayPopup, fromAllTransactionTable }) => {
  const { hasPermission } = hasPermissionHook();
  const params = useParams();
  const [emailButtonAnchor, setEmailButtonAnchor] = useState(null);
  const [pdfButtonAnchor, setPdfButtonAnchor] = useState(null);
  const [emailPopupOpen, setEmailPopupOpen] = useState(false);
  const [pdfPopupOpen, setPdfPopupOpen] = useState(false);
  const [sendEmailClicked, setSendEmailClicked] = useState(false);
  const clientType = useMemo(() => {
    return row?.original['client.client_type_id'] === 1 ? 'customers' : 'vendors';
  }, [row?.original['client.client_type_id']]);
  const clientId = useMemo(() => {
    return row?.original['client.id'];
  }, [row?.original['client.id']]);

  const [docType, setDocType] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: sendTransactionEmail } = useSendTransactionEmail(clientId, clientType, transactionType, {
    successCallback: () => emailSendSuccessCallback({ enqueueSnackbar }),
    errorCallback: () => emailSendErrorCallback({ enqueueSnackbar }),
  });
  const { mutate: exportTransactionPdf } = useTransactionPdfExporter(clientId, clientType, transactionType, {
    errorCallback: () => pdfExportErrorCallback({ enqueueSnackbar }),
  });

  useEffect(() => {
    if (transactionType) {
      setDocType(transactionType);
    }

    const type = row.original['document_type.name'];
    if (type) {
      if (type.includes('Customer') || type.includes('Vendor')) {
        const [, normalizedType] = type.split(/(?<=^\S+)\s/);
        setDocType(normalizedType);
      } else {
        setDocType(row.original['document_type.name']);
      }
    }
  }, [row.original['document_type.name'], transactionType]);

  const handleEmailConfirm = () => {
    setSendEmailClicked(true);
    sendTransactionEmail(row.original['transactions.id']);
    // sendEmail(row.original['transactions.id']);
  };

  const handleEmailCancel = () => {
    setSendEmailClicked(false);
    setEmailPopupOpen(false);
  };

  const handlePdfDownload = () => {
    exportTransactionPdf(row.original['transactions.id']);
    // exportPdf(row.original['transactions.id']);
  };

  const handlePdfCancel = () => {
    setPdfPopupOpen(false);
  };

  const handlePopupClose = () => {
    setEmailPopupOpen(false);
    setSendEmailClicked(false);
  };

  const onOpenPaymentInfo = () => {
    // /client/:clientType(customers|vendors)/360/:clientId/application-of-payments/:invoiceId
    window.open(`/client/${params.clientType || clientType}/360/${clientId}/invoice/payment-info/${row?.original['transactions.id']}`, '_blank');
  };

  return (
    <>
      <CustomPopup
        overlayModal={overlayModal}
        anchor={emailButtonAnchor}
        content={
          <EmailPopupContent
            disabled={sendEmailClicked}
            onConfirm={handleEmailConfirm}
            onCancel={handleEmailCancel}
            message={`Confirm sending ${docType} #${row.original['transactions.document_number']} to ${clientType.substring(
              0,
              clientType.length - 1
            )}`}
          />
        }
        arrow
        placement='bottom'
        onClose={handlePopupClose}
        open={emailPopupOpen}
      />

      <CustomPopup
        overlayModal={overlayModal}
        anchor={pdfButtonAnchor}
        content={
          <PdfPopupContent
            overlayPopup={overlayPopup}
            row={row}
            onDownload={handlePdfDownload}
            onCancel={handlePdfCancel}
            clientId={clientId}
            clientType={clientType}
            transactionId={row.original['transactions.id']}
            transactionType={docType}
          />
        }
        arrow
        placement='bottom'
        onClose={() => setPdfPopupOpen(false)}
        open={pdfPopupOpen}
      />

      <Tooltip placement='top' arrow followCursor title={`Send ${docType} #${row.original['transactions.document_number'] ?? ''} to Client`}>
        <IconButton
          size='small'
          onClick={(e) => {
            setEmailButtonAnchor(e.currentTarget);
            setEmailPopupOpen((p) => !p);
          }}
        >
          <EmailIcon />
        </IconButton>
      </Tooltip>
      <IconButton
        size='small'
        onClick={(e) => {
          setPdfButtonAnchor(e.currentTarget);
          setPdfPopupOpen((p) => !p);
        }}
      >
        <PdfIcon />
      </IconButton>
      {row?.original['transactions.amount'] !== row?.original['transactions_balances.balance'] &&
      !row?.original['document_type.name']?.includes('Payment') &&
      !row?.original['document_type.name']?.includes('Customer Refund') ? (
        <Tooltip title='Payment Info' placement='top' arrow followCursor>
          <IconButton onClick={onOpenPaymentInfo} disabled={false}>
            <PaymentOutlined />
          </IconButton>
        </Tooltip>
      ) : null}
    </>
  );
};

const EmailPopupContent = ({ onConfirm, onCancel, message = `Are you sure you want to send email to client?`, disabled }) => {
  return (
    <>
      <Box>
        <DialogContent sx={{ padding: '10px' }}>{message}</DialogContent>
        <DialogActions sx={{ padding: 0 }}>
          <IconButton size='small' color='primary' onClick={onConfirm} disabled={disabled}>
            <CheckIcon />
          </IconButton>
          <IconButton size='small' color='default' onClick={onCancel}>
            <ClearIcon />
          </IconButton>
        </DialogActions>
      </Box>
    </>
  );
};

const PdfPopupContent = ({ row, message, onDownload, onCancel, clientId, clientType, transactionId, transactionType, overlayPopup }) => {
  return (
    <>
      <Box>
        {message && <DialogContent>{message}</DialogContent>}
        <DialogActions sx={{ padding: '2px' }}>
          <CustomDialog
            overlayPopup={overlayPopup}
            hideActions
            disableBackdropClick
            disableEscapeKeyDown
            buttonSx={{ backgroundColor: 'transparent' }}
            customTitleButton={
              <Tooltip arrow title={`Preview ${transactionType} #${row.original['transactions.document_number'] ?? ''}`}>
                <IconButton size='small'>
                  <PreviewIcon color='primary' />
                </IconButton>
              </Tooltip>
            }
            modalSize={'lg'}
            modalTitle={`Preview ${transactionType} #${row.original['transactions.document_number'] ?? ''}`}
          >
            <TransactionPdfPreview clientId={clientId} clientType={clientType} transactionId={transactionId} transactionType={transactionType} />
          </CustomDialog>

          <Tooltip arrow title={`Export ${transactionType} #${row.original['transactions.document_number'] ?? ''} to PDF`}>
            <IconButton size='small' onClick={onDownload}>
              <DownloadIcon color='primary' />
            </IconButton>
          </Tooltip>
          <IconButton size='small' onClick={onCancel}>
            <ClearIcon />
          </IconButton>
        </DialogActions>
      </Box>
    </>
  );
};

export { TransactionTableActions };
