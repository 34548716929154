import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { normalizeClientType } from '../utils';

export const clientAgingBaseUrl = (clientType) => {
  const normalizedClientType = normalizeClientType(clientType);
  return `/me/client/${normalizedClientType}/aging`;
};

const listAgingTransactions = (clientId, clientType, filter) => {
  const baseUrl = clientAgingBaseUrl(clientType);
  const url = `${baseUrl}/list-transactions/${clientId}`;

  return axios.get(url, { params: { ...filter } });
};

export const useListAgingTransactions = (clientId, clientType, filter, location) => {
  const { page, pageSize, sort_by, order_by } = filter;
  let realFilter;
  if (location?.search) {
    const search = queryString.parse(location.search);
    realFilter = { ...filter, ...search };
  } else {
    realFilter = { ...filter };
  }
  return useQuery(
    ['list-aging-transactions', page, pageSize, sort_by, order_by, clientId, clientType, location?.search],
    () => listAgingTransactions(clientId, clientType, realFilter),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const getClientAgingReport = (clientType, filter) => {
  const baseUrl = clientAgingBaseUrl(clientType);
  const url = `${baseUrl}/list`;

  return axios.get(url, { params: { ...filter } });
};

export const useClientAgingReport = (clientType, filter) => {
  const { start, end, sort_by, order_by, page, pageSize, detailed } = filter;
  return useQuery(
    ['client-aging.report', clientType, start, end, sort_by, order_by, page, pageSize, detailed],
    () => getClientAgingReport(clientType, filter),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const getClientAgingTotal = (clientType, filter) => {
  const url = clientAgingBaseUrl(clientType);

  return axios.get(url, { params: { ...filter } });
};

export const useClientAgingTotal = (clientType, filter, callbacks) => {
  const { start, end } = filter;
  return useQuery(['client-aging.total', clientType, start, end], () => getClientAgingTotal(clientType, filter), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      if (callbacks?.successCallback && typeof callbacks.successCallback === 'function') {
        callbacks?.successCallback();
      }
    },
    onError: () => {
      if (callbacks?.errorCallback && typeof callbacks.errorCallback === 'function') {
        callbacks?.errorCallback();
      }
    },
  });
};
