import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import DataTableComponent from '../../../../../../../component/DataTable/component/DataTableComponent';
import { C_HISTORY, EDIT, V_HISTORY } from '../../../../../../../constants/permissions.constants';
import { hasPermissionHook } from '../../../../../../../hooks/hasPermission';
import { useChangeClientTransactionHistoryStatus, useClientTransactionsHistory, useDeleteClientTransactionHistory } from '../clientTransactionsHooks';
import { ClientTransactionsHistoryActions } from './ClientTransactionsHistoryActions';
import { ClientTransactionsHistoryTableColumns } from './clientTransactionsHistoryColumns';

export const ClientTransactionsHistory = () => {
  const { clientId, clientType } = useParams();

  const { hasPermission } = hasPermissionHook();

  const displayActions = useMemo(() => {
    if (clientType == 'customers') {
      return hasPermission(C_HISTORY, EDIT);
    } else if (clientType == 'vendors') {
      return hasPermission(V_HISTORY, EDIT);
    } else {
      return false;
    }
  }, [clientType]);

  const blankFilter = useMemo(() => {
    return {
      page: 0,
      pageSize: 10,
      sort_by: 'id',
      order_by: 'DESC',
    };
  }, []);

  const [filter, setFilter] = useState(blankFilter);

  const onFilterChange = (input) => {
    setFilter((prev) => ({ ...prev, ...input }));
  };

  const { data: { data: historyData = {} } = {} } = useClientTransactionsHistory({ clientId, clientType, filter });

  const { mutate: changeStatus } = useChangeClientTransactionHistoryStatus(clientId, clientType);
  const { mutate: deleteHistory } = useDeleteClientTransactionHistory(clientId, clientType);

  return (
    <div>
      <DataTableComponent
        dataSource={historyData}
        columns={ClientTransactionsHistoryTableColumns(
          <ClientTransactionsHistoryActions onUpdate={changeStatus} onDelete={deleteHistory} visible={displayActions} />
        )}
        onFilterChange={onFilterChange}
      />
    </div>
  );
};
