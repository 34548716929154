import { DeleteForever } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, DialogActions, DialogContent, IconButton, Tooltip } from '@mui/material';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { CollapsableCard } from '../../../../../common/Card/CollapsableCard';
import { CustomPopup } from '../../../../../common/Popup/CustomPopup';
import ExportButton from '../../../../../component/Buttons/ExportButton';
import { PageableTable } from '../../../../../component/DataTable/component/PageableTable';
import { EDIT, PAY_BILLS } from '../../../../../constants/permissions.constants';
import { hasPermissionHook } from '../../../../../hooks/hasPermission';
import { usePopup } from '../../../../../hooks/usePopup';
import { queryStringParseOptions, useTablePagination } from '../../../../../hooks/useTablePagination';
import { downloaderFiles } from '../../../../../utils/downloaderFiles';
import { exportExcel } from '../../../../../utils/exportExcel';
import { exportPdf } from '../../../../../utils/exportPdf';
import { notificationDangerCustomTime } from '../../../../../utils/toastify';
import { columns, excelHeaders } from '../columns/billingPaymentColumns';
import { composeBaseUrl, useBillingPayments, useDeleteBillingPayment, useDeleteNotPostedBillingPayments } from '../hooks';
import { mapBillingPaymentExcelData } from '../utils';
import { BillingPaymentFilters } from './BillingPaymentFilters';
import { useDispatch } from 'react-redux';
import { PaginationKeys, setPagination } from '../../../../redax/Pagination/PaginationActions';

export const BillingPayments = ({}) => {
  const { hasPermission } = hasPermissionHook();
  const { clientType } = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const blankFilter = useMemo(() => {
    return {
      finished: undefined,
      batch_number: null, // batch number
      create_at_from: null,
      create_at_to: null,
      posting_date_from: null,
      posting_date_to: null,
      document_date: null,
      payment_source: null,
      bank_account_number: null,

      page: 0,
      pageSize: 10,
      sort_by: 'finished',
      order_by: 'ASC',
    };
  }, []);

  const { options, setOptions, onPageChange, onSortChange, onRowsPerPageChange, onFilterChange } = useTablePagination(blankFilter);

  const blankData = useMemo(() => {
    return { data: [], page: 0, pageSize: 10, pageTotal: 0 };
  }, []);

  const url = useMemo(() => {
    const url = composeBaseUrl(clientType);
    return url;
  }, [clientType]);

  const [container, setContainer] = useState(null);
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);
  const [initialized, setInitialized] = useState(false);

  const { options: deletePopupOptions, onClose: onDeletePopupClose, onOpen: onDeletePopupOpen } = usePopup();
  const { options: deleteManyPopupOptions, onClose: onDeleteManyPopupClose, onOpen: onDeleteManyPopupOpen } = usePopup();

  const handleDeleteClick = (e, row) => {
    setSelectedPaymentId(row.id);
    onDeletePopupOpen(e);
  };

  const handleDeleteConfirm = () => {
    deleteBillingPayment(selectedPaymentId);
  };

  const handleDeleteManyConfirm = () => {
    deleteNotPostedBillingPayments();
  };

  const { mutate: deleteBillingPayment } = useDeleteBillingPayment({
    clientType,
    callbacks: {
      successCallback: onDeletePopupClose,
    },
  });

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    dispatch(setPagination({ target: PaginationKeys.BillingPayments, location }));
  }, [location]);

  const { data: billingPaymentsData, refetch } = useBillingPayments(clientType, options, { enabled: initialized });

  const { mutate: deleteNotPostedBillingPayments, isLoading: deletingManyInProgress } = useDeleteNotPostedBillingPayments({
    clientType,
    successCallback: onDeleteManyPopupClose,
  });

  useEffect(() => {
    const params = queryString.parse(location.search, queryStringParseOptions);

    if (Object.keys(params).length) {
      setOptions((prevValue) => ({ ...prevValue, ...params }));
    }
  }, []);

  const onFilterActionBtn = (e) => {
    if (e.toString() === 'PDF' || e.toString() === 'EXCEL') {
      downloaderFiles({ filter: options, type: e, url, cbLoad: () => {}, name: 'Payment' });
    }
  };

  const handleAddNewPaymentClick = () => {
    history.push(`/client/${clientType}/payment/new`);
  };

  const handleSearch = () => {
    refetch();
  };

  return (
    <>
      <CustomPopup
        anchor={deletePopupOptions.anchor}
        content={<MemoizedDeleteBillingPaymentPopupContent onConfirm={handleDeleteConfirm} onCancel={onDeletePopupClose} />}
        arrow
        placement='bottom'
        open={deletePopupOptions.open}
        onClose={onDeletePopupClose}
      />

      <CustomPopup
        anchor={deleteManyPopupOptions.anchor}
        content={
          <MemoizedDeleteNotPostedBillingPaymentsContent
            disabled={deletingManyInProgress}
            onConfirm={handleDeleteManyConfirm}
            onCancel={onDeleteManyPopupClose}
            message={`Confirm Deletion of Not Posted Payments (You will delete only your batches)`}
          />
        }
        arrow
        placement='bottom'
        onClose={onDeleteManyPopupClose}
        open={deleteManyPopupOptions.open}
      />

      <Row className='mt-3'>
        <Col>
          <CollapsableCard
            titleTypographyProps={{ fontSize: '18px' }}
            headerTitle={
              <div className='d-flex justify-content-between align-items-center'>
                <span>Payments</span>
                <span className='d-flex'>
                  <span>
                    <ExportButton
                      exportTablePDF={() => {
                        onFilterActionBtn('PDF');
                      }}
                      exportTableEXCEL={() => {
                        onFilterActionBtn('EXCEL');
                      }}
                      exportPagePDF={() => exportPdf(container, 'Payment')}
                      exportPageEXCEL={() => exportExcel(mapBillingPaymentExcelData(billingPaymentsData?.data?.data), excelHeaders, 'Payments')}
                    />
                  </span>
                </span>
              </div>
            }
          >
            <BillingPaymentFilters filters={options} onFilterChange={onFilterChange} onSearch={handleSearch} />
            <Row className='mt-3 mb-1'>
              <Col className='d-flex justify-content-end'>
                <Tooltip arrow title='Add New Payment'>
                  <IconButton color='success' onClick={handleAddNewPaymentClick} disabled={!hasPermission(PAY_BILLS, EDIT)}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow title='Delete Your Not Posted Payments'>
                  <IconButton className='mr-2' color='error' onClick={onDeleteManyPopupOpen} disabled={!hasPermission(PAY_BILLS, EDIT)}>
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              </Col>
            </Row>
            <div
              ref={(instance) => {
                setContainer(instance);
              }}
            >
              <PageableTable
                dataSource={billingPaymentsData?.data || blankData}
                onPageChange={onPageChange}
                onSortChange={onSortChange}
                onPageSizeChange={onRowsPerPageChange}
                columns={columns(clientType, <MemoizedBillingPaymentActions onDelete={handleDeleteClick} />)}
              />
            </div>
          </CollapsableCard>
        </Col>
      </Row>
    </>
  );
};

const DeleteNotPostedBillingPaymentsContent = ({ onConfirm, onCancel, message = `Are you sure you want to send email to client?`, disabled }) => {
  return (
    <>
      <Box>
        <DialogContent sx={{ padding: '10px' }}>{message}</DialogContent>
        <DialogActions sx={{ padding: 0 }}>
          <IconButton color='primary' onClick={onConfirm} disabled={disabled}>
            <DeleteForever fontSize='small' />
          </IconButton>
          <IconButton color='default' onClick={onCancel}>
            <ClearIcon fontSize='small' />
          </IconButton>
        </DialogActions>
      </Box>
    </>
  );
};

const MemoizedDeleteNotPostedBillingPaymentsContent = React.memo(DeleteNotPostedBillingPaymentsContent);

const BillingPaymentActions = ({ row, onDelete }) => {
  return (
    <>
      <Row>
        <Tooltip arrow title='Remove'>
          <span>
            {!row.finished && (
              <IconButton onClick={(e) => onDelete(e, row)} disabled={row.finished} color='error'>
                <DeleteForever fontSize='small' />
              </IconButton>
            )}
          </span>
        </Tooltip>
      </Row>
    </>
  );
};

const MemoizedBillingPaymentActions = React.memo(BillingPaymentActions);

const DeleteBillingPaymentPopupContent = ({ onConfirm, onCancel, message = `Confirm payment removal`, disabled }) => {
  return (
    <>
      <Box>
        <DialogContent sx={{ padding: '5px' }}>{message}</DialogContent>
        <DialogActions sx={{ padding: 0 }}>
          <IconButton color='primary' onClick={onConfirm} disabled={disabled}>
            <DeleteForever fontSize='small' />
          </IconButton>
          <IconButton onClick={onCancel}>
            <ClearIcon fontSize='small' />
          </IconButton>
        </DialogActions>
      </Box>
    </>
  );
};

const MemoizedDeleteBillingPaymentPopupContent = React.memo(DeleteBillingPaymentPopupContent);
