import DataTableComponent from '../../../../../../component/DataTable/component/DataTableComponent';
import React from 'react';
import { formatAmount } from '../../../../../../utils/formatAmout';
import { formatDateForTable } from '../../../../../../utils/formatDataForTable';

export default function InvoiceTrackerDataGrid({
  dataSource = { data: [], page: 1, pageSize: 10, pageTotal: 0 },
  onFilterChange,
  loader = true,
  ...props
}) {
  return <DataTableComponent dataSource={dataSource} onFilterChange={onFilterChange} columns={columns} loading={loader} {...props} />;
}

const columns = [
  {
    accessor: (d) => d['client.name'],
    id: 'client.name',
    Header: <div className='leftHeader'>Client</div>,
  },
  {
    accessor: (d) => d['transactions.document_number'],
    id: 'transactions.document_number',
    Header: () => <div className='leftHeader'>Document Number</div>,
  },
  {
    accessor: (d) => d['transactions_due_date.amount'],
    id: 'transactions_due_date.amount',
    Header: () => <div className='leftHeader'>Amount</div>,
    Cell: (row) => <div style={{ textAlign: 'right' }}>{formatAmount(row.value)}</div>,
  },
  {
    accessor: (d) => d['transactions_due_date.balance'],
    id: 'transactions_due_date.balance',
    Header: () => <div className='leftHeader'>Balance</div>,
    Cell: (row) => <div style={{ textAlign: 'right' }}>{formatAmount(row.value)}</div>,
  },
  {
    accessor: (d) => d['transactions_due_date.due_date'],
    id: 'transactions_due_date.due_date',
    Header: () => <div className='leftHeader'>Due Date</div>,
    Cell: (row) => <div style={{ textAlign: 'right' }}>{formatDateForTable(row.value)}</div>,
  },
  {
    accessor: (d) => d['transactions_due_date.release_date'],
    id: 'transactions_due_date.release_date',
    Header: () => <div className='leftHeader'>Release date Date</div>,
    Cell: (row) => <div style={{ textAlign: 'right' }}>{formatDateForTable(row.value)}</div>,
  },
  {
    accessor: (d) => d['transaction_import_method.name'],
    id: 'transaction_import_method.name',
    Header: () => <div className='leftHeader'>Import Source</div>,
  },
  {
    accessor: (d) => d['branch.name'],
    id: 'branch.name',
    Header: () => <div className='leftHeader'>Branch</div>,
  },
  {
    accessor: (d) => d['users.name'],
    id: 'users.name',
    Header: () => <div className='leftHeader'>Create By</div>,
  },
];
