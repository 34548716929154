import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import WarningIcon from '@mui/icons-material/Warning';
import { formatAmount } from '../../../../../../utils/formatAmout';
import { formatDateForTable } from '../../../../../../utils/formatDataForTable';
import { Tooltip } from '@mui/material';

// *************************************** helper functions ***************************************
const isVoidVendorPayment = (row) => {
  return row.original['transactions.void'];
};

export const isCheckVoided = (row) => {
  return row.original.vendor_payment_check?.is_check && row.original.vendor_payment_check?.voided;
};

const isVendorPaymentReversed = (row) => {
  return row.original['client.client_type_id'] === 2 && row.original['transactions.payment_reversed'];
};

const isVendorCheckVoidedOrVendorPaymentReversed = (row) => {
  return isCheckVoided(row) || isVendorPaymentReversed(row) || row?.original['transactions.void'];
};

export const shouldHidePaymentActionsAndDescription = (row) => isVoidVendorPayment(row) || isVendorCheckVoidedOrVendorPaymentReversed(row);

// *************************************** columns ************************************************
export const statusColumn = (row) => {
  if ([2, 5].includes(row?.original['transactions.document_type_journal_entry_id'])) {
    return '';
  }

  const amount = row.original['transactions_balances.balance'];

  if (amount === 0) {
    return 'Paid';
  }
  return 'Unpaid';
};

export const paymentMethodColumn = (row) => {
  if (!row?.original?.payment_source?.name) {
    return <div></div>;
  }

  return <div>{row.original.payment_source.name}</div>;
};

export const isVendorPaymentVoidedColumn = (row) => {
  const isPayment = row?.original['transactions.document_type_journal_entry_id'] === 2;
  if (!isPayment) {
    return <div></div>;
  }

  if (row?.original?.is_partially_reversed) {
    return (
      <Tooltip arrow followCursor title='Payment is Partially Reversed!' placement='top'>
        <div className='d-flex justify-content-center'>
          <WarningIcon color='warning' />
        </div>
      </Tooltip>
    );
  }

  return (
    <div className='d-flex justify-content-center'>
      {isVendorCheckVoidedOrVendorPaymentReversed(row) ? <CheckIcon color='success' /> : <RemoveIcon color='error' />}
    </div>
  );
};

export const vendorRemainingBalanceColumn = (row) => {
  // if (isCheckVoided(row)) {
  // return <div>{formatAmount(0)}</div>;
  // }

  return <div>{formatAmount(row.value)}</div>;
};

export const vendorPaymentInfoColumn = (row) => {
  if (row.original.vendor_payment_check?.is_check && isCheckVoided(row)) {
    return (
      <div>
        <div>Check</div>
        <div>
          {row.original.vendor_payment_check?.voided && 'Voided'}
          {row.original.vendor_payment_check?.void_date && ` on ${formatDateForTable(row.original.vendor_payment_check.void_date)}`}
          {row.original.vendor_payment_check.voided_by?.name && ` by ${row.original.vendor_payment_check.voided_by?.name}`}
        </div>
      </div>
    );
  }
  return <div></div>;
};

export const isVendorParentTransactionColumn = (row) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {row?.original?.is_parent_transaction ? <CheckIcon color='success' /> : <RemoveIcon color='error' />}
    </div>
  );
};
