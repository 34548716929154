import React, { useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useCheckIfPostingIsDisabled } from '../app/view/Settings/DisablePosting/apiHooks';

export const hasPermissionHook = () => {
  const { data: { data: isDisabledData } = {} } = useCheckIfPostingIsDisabled();
  const { user } = useSelector((state) => ({ user: state.auth.user }));

  const hasPermission = (functionality, permission) => {
    if (!user.functionalities) {
      return false;
    }

    if (functionality && permission) {
      const hasPermission =
        user.functionalities && user.functionalities[functionality] && user.functionalities[functionality].perm.includes(permission);

      if (hasPermission) {
        return true;
      }

      return false;
    }

    if (functionality) {
      const hasFunctionality = user.functionalities && user.functionalities[functionality] && user.functionalities[functionality].active;

      if (hasFunctionality) {
        return true;
      }

      return false;
    }

    return false;
  };

  const isPostingDisabled = (datesArray) => {
    const allDatesInRange = datesArray.every((date) => {
      const currentDate = moment(date);
      const startDate = moment(isDisabledData.date_from);
      const endDate = moment(isDisabledData.date_to);

      return currentDate.isBetween(startDate, endDate, null, '[]');
    });

    return !allDatesInRange;
  };

  return {
    hasPermission,
    isPostingDisabled,
  };
};
