import React from 'react';
import { Button } from '@mui/material';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Label, Row } from 'reactstrap';
import { CollapsableCard } from '../../../../common/Card/CollapsableCard';
import { CustomInput } from '../../../../common/CustomInput';
import { CustomSelect } from '../../../../common/CustomSelect';
import { CustomInputMask } from '../../../../common/Inputs/CustomInputMask';
import { CustomSwitch } from '../../../../common/Inputs/CustomSwitch';
import { CustomPageableAsyncSelect } from '../../../../common/Inputs/v2/AsyncSelect2';
import {
  BRANCH_TABLE,
  BUSINESS_ENTITY_TYPES,
  IRS_1099_CODE,
  LLC_TYPES,
  PAYMENT_SOURCE,
  PAYMENT_TERM_TABLE,
  VENDOR_BANK_ACCOUNT_TYPE,
  VENDOR_TYPE,
} from '../../../../constants/supportTables.constants';
import { useGetSupportTablesForSelect, useStateOptions } from '../../../../hooks/commonApiHooks';
import { useAsyncPageable } from '../../../../hooks/useAsyncPageable';
import { useUpdateClient } from '../clientHooks';
import { extractValidationErrorMessage } from '../ClientPayment/utils';

export const VendorForm = ({ client, fetchClient }) => {
  const { clientId, clientType } = useParams();
  const [form, setForm] = useState({});

  const { fetchOptions } = useAsyncPageable();

  // async select wont display value as selected without waiting
  const [init, setInit] = useState(false);

  const loadOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/account/options/accounts', { q: inputValue, ...options });
    return data;
  };

  const loadVendors = async (inputValue, options) => {
    const data = await fetchOptions('/me/client/vendor/options-number-for-select', {
      q: inputValue,
      ...options,
      client_type_id: 2,
      // ignorable_ids: [clientId],
    });
    return data;
  };

  const labelStyle = useMemo(() => {
    return { display: 'flex', justifyContent: 'start', fontSize: '1.2rem' };
  }, []);

  const { data: stateOptionsData } = useStateOptions();

  const { data: { data: supportTableOptions = {} } = {} } = useGetSupportTablesForSelect({
    table_names: [
      IRS_1099_CODE,
      PAYMENT_TERM_TABLE,
      PAYMENT_SOURCE,
      VENDOR_TYPE,
      BRANCH_TABLE,
      BUSINESS_ENTITY_TYPES,
      LLC_TYPES,
      VENDOR_BANK_ACCOUNT_TYPE,
    ],
  });

  const isLLC = React.useMemo(() => {
    if (supportTableOptions[BUSINESS_ENTITY_TYPES] && supportTableOptions[BUSINESS_ENTITY_TYPES].length) {
      const value = supportTableOptions[BUSINESS_ENTITY_TYPES].find((item) => +item.value === +form['business_entity_type_id']);

      if (value && value.label === 'LLC') return true;
      else return false;
    }

    return false;
  }, [form['business_entity_type_id'], supportTableOptions[BUSINESS_ENTITY_TYPES]]);

  const { mutate: updateClient, error: updateErrors } = useUpdateClient({
    successCallback: () => {
      fetchClient();
    },
  });

  useEffect(() => {
    if (Object.keys(client || {}).length) {
      setForm(client);
      setInit(true);
    }
  }, [client]);

  const onChange = (e) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onSelectChange = (val, e) => {
    if (e.action === 'select-option') {
      let o = { target: { name: e.name, value: val.value || val.id } };
      onChange(o);
      return;
    }
    if (e.action === 'clear') {
      let o = { target: { name: e.name, value: null } };
      onChange(o);
    }
  };

  const onSwitchChange = (e) => {
    const { name, checked } = e.target;
    setForm((prev) => ({ ...prev, [name]: checked }));
  };

  const onSave = () => {
    const { id, client_id, balance, branch_name, latest_modifier_name, parent, ...data } = form;

    updateClient({ clientId, clientType, data });
  };

  const formatDateForPreview = (date) => {
    if (!date) return '';
    return moment(date).utcOffset(0, false).format('MM/DD/YYYY h:mm a');
  };

  return (
    <>
      <Row>
        <Col>
          <CustomInput disabled placeholder='' type='text' label='ID' name='id' value={form?.external_id || ''} onChange={() => {}} />
        </Col>
        <Col>
          <CustomInput placeholder='' type='text' label='RMI Number' name='rmi_number' value={form?.rmi_number || ''} onChange={onChange} />
        </Col>
        <Col>
          <CustomInput
            placeholder=''
            type='text'
            label='Federal ID Number'
            name='federal_tax_id'
            value={form?.federal_tax_id || ''}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md='4' sm='12'>
          <CustomInput
            placeholder=''
            type='text'
            label='Alternate Vendor Code'
            name='alternate_vendor_code'
            value={form?.alternate_vendor_code || ''}
            onChange={onChange}
          />
        </Col>
        <Col md='4' sm='12'>
          <CustomSelect
            label='IRS 1099 Code'
            isClearable
            name='irs_1099_code_id'
            defaultValue={form['irs_1099_code_id'] || null}
            options={supportTableOptions[IRS_1099_CODE] || []}
            onChange={onSelectChange}
          />
        </Col>
        <Col md='4' sm='12'>
          <CustomSelect
            label='Payment Terms'
            isClearable
            name='payment_terms'
            defaultValue={form['payment_terms'] || null}
            options={supportTableOptions[PAYMENT_TERM_TABLE] || []}
            onChange={onSelectChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md='4' sm='12'>
          <CustomSelect
            label='Vendor Type'
            isClearable
            name='vendor_type'
            defaultValue={form['vendor_type'] || null}
            options={supportTableOptions[VENDOR_TYPE] || []}
            onChange={onSelectChange}
          />
        </Col>
        <Col md='4' sm='12'>
          {init && (
            <CustomPageableAsyncSelect
              defKey={`${form['default_expense_account']}`}
              label={'Default Expense Account'}
              isClearable
              onChange={onSelectChange}
              loadOptions={loadOptions}
              name='default_expense_account'
              initValue={form['default_expense_account'] || null}
            />
          )}
        </Col>
        <Col md='4' sm='12'>
          <CustomSelect
            label='Vendor Branch'
            isClearable
            name='vendor_branch'
            defaultValue={form['vendor_branch'] || null}
            options={supportTableOptions[BRANCH_TABLE] || []}
            onChange={onSelectChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md='4' sm='12'>
          <CustomSelect
            label='Preferred Payment Method'
            isClearable
            name='preferred_payment_method'
            defaultValue={form['preferred_payment_method']}
            options={supportTableOptions[PAYMENT_SOURCE] || []}
            onChange={onSelectChange}
          />
        </Col>
        <Col md='4' sm='12'>
          {init && (
            <CustomPageableAsyncSelect
              defKey={`${form['vendor_parent']}`}
              label={'Vendor Parent'}
              isClearable
              onChange={onSelectChange}
              loadOptions={loadVendors}
              name='vendor_parent'
              initValue={form['vendor_parent'] || null}
              errorMess={extractValidationErrorMessage([updateErrors], 'vendor_parent')}
            />
          )}
        </Col>
        <Col md='4' sm='12' style={{ display: 'flex', alignItems: 'center' }}>
          <CustomSwitch labelPosition='top' label='Taxable' name='taxable' checked={form?.taxable} onChange={onSwitchChange} />
          <div className='ml-5'>
            <CustomSwitch
              labelPosition='top'
              label='Payment Priority'
              name='payment_priority'
              checked={form?.payment_priority}
              onChange={onSwitchChange}
            />
          </div>
          <div className='ml-5'>
            <CustomSwitch labelPosition='top' label='Blocked' name='blocked' checked={form?.blocked} onChange={onSwitchChange} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md='4' sm='12'>
          <CustomSelect
            label='Business Entity Type'
            isClearable
            name='business_entity_type_id'
            defaultValue={form['business_entity_type_id'] || null}
            options={supportTableOptions[BUSINESS_ENTITY_TYPES] || []}
            onChange={onSelectChange}
          />
        </Col>
        {isLLC && (
          <Col md='4' sm='12'>
            <CustomSelect
              label='LLC'
              isClearable
              name='llc_type_id'
              defaultValue={form['llc_type_id'] || null}
              options={supportTableOptions[LLC_TYPES] || []}
              onChange={onSelectChange}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col md='4' sm='12'>
          <CustomInput placeholder='' type='text' label='Bank Name' name='bank_name' value={form?.bank_name || ''} onChange={onChange} />
        </Col>
        <Col md='4' sm='12'>
          <CustomInput
            placeholder=''
            type='text'
            label='Account Number'
            name='account_number'
            value={form?.account_number || ''}
            onChange={onChange}
          />
        </Col>
        <Col md='4' sm='12'>
          <CustomInput
            placeholder=''
            type='text'
            label='Routing Number'
            name='routing_number'
            value={form?.routing_number || ''}
            onChange={onChange}
          />
        </Col>
        <Col md='4' sm='12'>
          <CustomSelect
            label='Account Type'
            isClearable
            name='vendor_bank_account_type'
            defaultValue={form['vendor_bank_account_type'] || null}
            options={supportTableOptions[VENDOR_BANK_ACCOUNT_TYPE] || []}
            onChange={onSelectChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md='6' sm='12'>
          <CustomInput
            placeholder=''
            type='textarea'
            rows={3}
            label='Blocked Notes'
            name='blocked_notes'
            value={form?.blocked_notes || ''}
            onChange={onChange}
          />
        </Col>
        <Col md='6' sm='12'>
          <CustomInput placeholder='' type='textarea' rows={3} label='Comments' name='comments' value={form?.comments || ''} onChange={onChange} />
        </Col>
      </Row>
      <Row>
        <Col md='4' sm='12'>
          <CustomInput
            disabled
            placeholder=''
            type='text'
            name='last_modified_by'
            label='Last Modified By'
            value={form?.latest_modifier_name || ''}
            onChange={() => {}}
          />
        </Col>
        <Col md='4' sm='12'>
          <CustomInput
            disabled
            type='text'
            name='last_modified_date'
            placeholder={'mm/dd/yyyy'}
            label='Last Modified Date'
            value={formatDateForPreview(form?.last_modified_date)}
            onChange={() => {}}
          />
        </Col>
      </Row>

      {/* Contact Persons */}
      <Row className='mt-3'>
        <Col style={{ padding: '4px' }}>
          <CollapsableCard
            isExpanded={false}
            headerTitle={
              <Row>
                <Col md={6} lg={6}>
                  <Label style={labelStyle}>Contact Person Information</Label>
                </Col>
                <Col md={6} lg={6}>
                  <Label style={labelStyle}>Sales Rep Contact Information</Label>
                </Col>
              </Row>
            }
          >
            <Row>
              <Col md={6} lg={6}>
                <Col>
                  <CustomInput
                    placeholder=''
                    type='text'
                    label='Contact Person Name'
                    name='primary_contact_name'
                    value={form?.primary_contact_name || ''}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <CustomInputMask
                    type='tel'
                    label='Business Phone Number'
                    name='primary_contact_business_phone'
                    value={form?.primary_contact_business_phone || ''}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <CustomInputMask
                    type='tel'
                    label='Fax Phone Number'
                    name='primary_contact_fax'
                    value={form?.primary_contact_fax}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <CustomInput
                    placeholder=''
                    type='text'
                    label='Email'
                    name='primary_contact_email'
                    value={form?.primary_contact_email || ''}
                    onChange={onChange}
                  />
                </Col>
              </Col>
              {/* 2nd col */}
              <Col md={6} lg={6}>
                <Col>
                  <CustomInput
                    placeholder=''
                    type='text'
                    label='Sales Rep Name'
                    name='sales_rep_name'
                    value={form?.sales_rep_name || ''}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <CustomInputMask
                    type='tel'
                    label='Business Phone Number'
                    name='sales_rep_business_phone'
                    value={form?.sales_rep_business_phone || ''}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <CustomInputMask type='tel' label='Fax Phone Number' name='sales_rep_fax' value={form?.sales_rep_fax || ''} onChange={onChange} />
                </Col>
                <Col>
                  <CustomInput
                    placeholder=''
                    type='text'
                    label='Email'
                    name='sales_rep_email'
                    value={form?.sales_rep_email || ''}
                    onChange={onChange}
                  />
                </Col>
              </Col>
            </Row>
          </CollapsableCard>
        </Col>
      </Row>

      {/* Address */}
      <Row className='mt-3 mb-3'>
        <Col className='m-0' style={{ padding: '4px' }}>
          <CollapsableCard
            isExpanded={false}
            headerTitle={
              <Row>
                <Col>
                  <Label style={labelStyle}>Physical Address</Label>
                </Col>
                <Col>
                  <Label style={labelStyle}>Remit To Address</Label>
                </Col>
              </Row>
            }
          >
            <Row>
              {/* Physical */}
              <Col sm={12} lg={6} className='mb-1'>
                <Col>
                  <CustomInput
                    placeholder=''
                    type='text'
                    label='Street1'
                    name='address_street1'
                    value={form?.address_street1 || ''}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <CustomInput
                    placeholder=''
                    type='text'
                    label='Street2'
                    name='address_street2'
                    value={form?.address_street2 || ''}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <CustomInput placeholder='' type='text' label='City' name='address_city' value={form?.address_city || ''} onChange={onChange} />
                </Col>
                <Col>
                  <CustomSelect
                    label='State'
                    isClearable
                    onChange={onSelectChange}
                    name='address_state'
                    defaultValue={form['address_state'] || null}
                    options={stateOptionsData?.data || []}
                  />
                </Col>
                <Col>
                  <CustomInput placeholder='' type='text' label='Zip' name='address_zip' value={form?.address_zip || ''} onChange={onChange} />
                </Col>
              </Col>

              {/* Remit to */}
              <Col sm={12} lg={6}>
                <Col>
                  <CustomInput
                    placeholder=''
                    type='text'
                    label='Street1'
                    name='address2_street1'
                    value={form?.address2_street1 || ''}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <CustomInput
                    placeholder=''
                    type='text'
                    label='Street2'
                    name='address2_street2'
                    value={form?.address2_street2 || ''}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <CustomInput placeholder='' type='text' label='City' name='address2_city' value={form?.address2_city || ''} onChange={onChange} />
                </Col>
                <Col>
                  <CustomSelect
                    label='State'
                    isClearable
                    onChange={onSelectChange}
                    name='address2_state'
                    defaultValue={form['address2_state'] || null}
                    options={stateOptionsData?.data || []}
                  />
                </Col>
                <Col>
                  <CustomInput placeholder='' type='text' label='Zip' name='address2_zip' value={form?.address2_zip || ''} onChange={onChange} />
                </Col>
              </Col>
            </Row>
            <Row></Row>
          </CollapsableCard>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex justify-content-end'>
          <Button onClick={onSave}>Save</Button>
        </Col>
      </Row>
    </>
  );
};
