import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import queryString from 'query-string';
import axios from 'axios';
import { downloader } from '../../../../../../utils/downloaderFiles.js';
import { normalizeClientType } from '../../../utils.js';

export const composeClientTransactionsUrl = (clientId, clientType, urlFragment) => {
  const normalizedClientType = normalizeClientType(clientType);
  const baseUrl = `/me/client/${normalizedClientType}/${clientId}/transactions${urlFragment ? `${urlFragment}` : ''}`;

  return baseUrl;
};

const getClientTransactions = (clientId, clientType, filter) => {
  const url = composeClientTransactionsUrl(clientId, clientType);

  return axios.get(url, { params: { ...filter } });
};

export const useClientTransactions = (clientId, clientType, filter, location) => {
  const { page, pageSize, sort_by, order_by } = filter;
  let realFilter;
  if (location?.search) {
    const search = queryString.parse(location.search);
    realFilter = { ...filter, ...search };
  } else {
    realFilter = { ...filter };
  }

  return useQuery(
    ['client.transactions', page, pageSize, sort_by, order_by, clientId, clientType, location?.search],
    () => getClientTransactions(clientId, clientType, realFilter),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const getClientInvoices = (clientId, clientType, filter) => {
  const url = composeClientTransactionsUrl(clientId, clientType, '/invoice');
  return axios.get(url, { params: { ...filter } });
};

const createClientInvoice = (clientId, clientType, data) => {
  const url = composeClientTransactionsUrl(clientId, clientType, '/invoice');

  return axios.post(url, data);
};

export const useCreateClientInvoice = () => {
  const queryClient = useQueryClient();

  return useMutation(({ clientId, clientType, data }) => createClientInvoice(clientId, clientType, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['client.invoices']);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export const useClientInvoices = (clientId, clientType, filter) => {
  const { page, pageSize, sort_by, order_by } = filter;
  return useQuery(
    ['client.invoices', page, pageSize, sort_by, order_by, clientId, clientType],
    () => getClientInvoices(clientId, clientType, filter),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const getClientPayments = (clientId, clientType, filter) => {
  const url = composeClientTransactionsUrl(clientId, clientType, '/payment');
  return axios.get(url, { params: { ...filter } });
};

const createCreditPayment = (clientId, clientType, data) => {
  const url = composeClientTransactionsUrl(clientId, clientType, '/payment');
  return axios.post(url, data);
};

export const useCreateCreditPayment = () => {
  const queryClient = useQueryClient();

  return useMutation(({ clientId, clientType, data }) => createCreditPayment(clientId, clientType, data), {
    retry: 0,
    onSuccess: () => {
      queryClient.invalidateQueries(['client.payments']);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export const useClientPayments = (clientId, clientType, filter) => {
  const { page, pageSize, sort_by, order_by } = filter;
  return useQuery(
    ['client.payments', page, pageSize, sort_by, order_by, clientId, clientType],
    () => getClientPayments(clientId, clientType, filter),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const getClientCreditMemo = (clientId, clientType, filter) => {
  const url = composeClientTransactionsUrl(clientId, clientType, '/credit-memo');
  return axios.get(url, { params: { ...filter } });
};

const createClientCreditMemo = (clientId, clientType, data) => {
  const url = composeClientTransactionsUrl(clientId, clientType, '/credit-memo');

  return axios.post(url, data);
};

export const useCreateCreditMemo = () => {
  const queryClient = useQueryClient();
  return useMutation(({ clientId, clientType, data }) => createClientCreditMemo(clientId, clientType, data), {
    retry: 0,
    onSuccess: () => {
      queryClient.invalidateQueries(['client.creditMemos']);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export const useClientCreditMemo = (clientId, clientType, filter) => {
  const { page, pageSize, sort_by, order_by } = filter;
  return useQuery(
    ['client.creditMemos', page, pageSize, sort_by, order_by, clientId, clientType],
    () => getClientCreditMemo(clientId, clientType, filter),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const getClientDebitMemo = (clientId, clientType, filter) => {
  const url = composeClientTransactionsUrl(clientId, clientType, '/debit-memo');
  return axios.get(url, { params: { ...filter } });
};

const createDebitMemo = (clientId, clientType, data) => {
  const url = composeClientTransactionsUrl(clientId, clientType, '/debit-memo');
  return axios.post(url, data);
};

export const useCreateDebitMemo = () => {
  const queryClient = useQueryClient();

  return useMutation(({ clientId, clientType, data }) => createDebitMemo(clientId, clientType, data), {
    retry: 0,
    onSuccess: () => {
      queryClient.invalidateQueries(['client.debitMemos']);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export const useClientDebitMemo = (clientId, clientType, filter) => {
  const { page, pageSize, sort_by, order_by } = filter;
  return useQuery(
    ['client.debitMemos', page, pageSize, sort_by, order_by, clientId, clientType],
    () => getClientDebitMemo(clientId, clientType, filter),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const getClientRefunds = (clientId, clientType, filter) => {
  const url = composeClientTransactionsUrl(clientId, clientType, '/refund');
  return axios.get(url, { params: { ...filter } });
};

const createCreditRefund = (clientId, clientType, data) => {
  const url = composeClientTransactionsUrl(clientId, clientType, '/refund');
  return axios.post(url, data);
};

export const useCreateCreditRefund = () => {
  const queryClient = useQueryClient();

  return useMutation(({ clientId, clientType, data }) => createCreditRefund(clientId, clientType, data), {
    retry: 0,
    onSuccess: () => {
      queryClient.invalidateQueries('client.refunds');
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export const useClientRefunds = (clientId, clientType, filter) => {
  const { page, pageSize, sort_by, order_by } = filter;
  return useQuery(['client.refunds', page, pageSize, sort_by, order_by, clientId, clientType], () => getClientRefunds(clientId, clientType, filter), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const getInvoiceTracker = (clientId, clientType, filter) => {
  const normalizedClientType = normalizeClientType(clientType);
  const url = `/me/client/${normalizedClientType}/${clientId}/report/invoices-tracker`;

  return axios.get(url, { params: { ...filter } });
};

export const useInvoiceTracker = (clientId, clientType, filter) => {
  const { page, pageSize, sort_by, order_by } = filter;
  return useQuery(
    ['client.invoiceTracker', page, pageSize, sort_by, order_by, clientId, clientType],
    () => getInvoiceTracker(clientId, clientType, filter),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const sendTransactionEmail = (transactionId, clientId, clientType, transactionType) => {
  const normalizedClientType = normalizeClientType(clientType);
  const url = `me/client/${normalizedClientType}/${clientId}/transactions/${transactionId}/email`;
  return axios.get(url, { params: { type: transactionType } });
};

export const useSendTransactionEmail = (clientId, clientType, transactionType, callbacks = {}) => {
  return useMutation((transactionId) => sendTransactionEmail(transactionId, clientId, clientType, transactionType), {
    onSuccess: () => {
      if (callbacks?.successCallback && typeof callbacks?.successCallback === 'function') {
        callbacks.successCallback();
      }
    },
    onError: () => {
      if (callbacks?.errorCallback && typeof callbacks?.errorCallback === 'function') {
        callbacks.errorCallback();
      }
    },
  });
};

const exportTransactionPdf = (transactionId, clientId, clientType, transactionType) => {
  const normalizedClientType = normalizeClientType(clientType);
  const url = `me/client/${normalizedClientType}/${clientId}/transactions/${transactionId}/pdf`;
  return downloader({ url, fileType: 'PDF', transactionType });
};

export const useTransactionPdfExporter = (clientId, clientType, transactionType, callbacks = {}) => {
  return useMutation((invoiceId) => exportTransactionPdf(invoiceId, clientId, clientType, transactionType), {
    onSuccess: () => {
      if (callbacks?.successCallback && typeof callbacks?.successCallback === 'function') {
        callbacks.successCallback();
      }
    },
    onError: () => {
      if (callbacks?.errorCallback && typeof callbacks?.errorCallback === 'function') {
        callbacks.errorCallback();
      }
    },
  });
};

const getTransactionPdfPreview = (transactionId, clientId, clientType, transactionType) => {
  const normalizedClientType = normalizeClientType(clientType);
  const url = `me/client/${normalizedClientType}/${clientId}/transactions/${transactionId}/pdf/preview`;
  return axios.get(url, { params: { type: transactionType } });
};

export const useTransactionPdfPreview = (transactionId, clientId, clientType, transactionType) => {
  return useQuery(
    ['client.transactionPdfPreview', transactionId, clientId, clientType, transactionType],
    () => getTransactionPdfPreview(transactionId, clientId, clientType, transactionType),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const getDetailedTransactions = ({ clientId, clientType, transactionId }) => {
  const url = composeClientTransactionsUrl(clientId, clientType, `/${transactionId}/detailed`);

  return axios.get(url);
};

export const useDetailedTransactions = ({ clientId, clientType, transactionId, enabled }) => {
  return useQuery(
    ['client.transactions.detailed', clientId, transactionId, clientType],
    () => getDetailedTransactions({ clientId, clientType, transactionId }),
    {
      enabled,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

// client transactions application history (audit log)
const getClientTransactionsHistory = ({ clientId, clientType, filter }) => {
  const url = composeClientTransactionsUrl(clientId, clientType, '/history');

  return axios.get(url, {
    params: {
      ...filter,
    },
  });
};

export const useClientTransactionsHistory = ({ clientId, clientType, filter }) => {
  const { page, pageSize, sort_by, order_by } = filter;
  return useQuery(
    ['client.transactions.history', clientId, clientType, page, pageSize, sort_by, order_by],
    () => getClientTransactionsHistory({ clientId, clientType, filter }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const changeClientTransactionHistoryStatus = ({ id, data, clientId, clientType }) => {
  const url = composeClientTransactionsUrl(clientId, clientType, `/history/${id}`);
  return axios.post(url, data);
};

export const useChangeClientTransactionHistoryStatus = (clientId, clientType) => {
  const queryClient = useQueryClient();
  return useMutation(({ id, data }) => changeClientTransactionHistoryStatus({ id, data, clientId, clientType }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['client.transactions.history']);
    },
  });
};

const deleteClientTransactionHistory = ({ id, clientId, clientType }) => {
  const url = composeClientTransactionsUrl(clientId, clientType, `/history/${id}`);
  return axios.delete(url);
};

export const useDeleteClientTransactionHistory = (clientId, clientType) => {
  const queryClient = useQueryClient();
  return useMutation((id) => deleteClientTransactionHistory({ id, clientId, clientType }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['client.transactions.history']);
    },
  });
};
