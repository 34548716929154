import PdfIcon from '@mui/icons-material/PictureAsPdf';
import { IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { CollapsableCard } from '../../../../common/Card/CollapsableCard';
import { accountProfitLossType } from '../../../../constants/accountProfitLossType.constants';
import { downloaderFiles } from '../../../../utils/downloaderFiles';
import { exportPdf } from '../../../../utils/exportPdf';
import { formatAmount } from '../../../../utils/formatAmout';
import { Filters } from './components/Filters';
import { ProfitLossSection } from './components/ProfitLossSection';
import { useProfitLossTotal } from './hooks';

export default function ProfitLossView() {
  const blankFilter = useMemo(() => {
    return {
      start: moment().startOf('year').format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
      page: 0,
      pageSize: 10,
      percentageIncluded: true,
    };
  }, []);

  const [total, setTotal] = useState({});
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);

  const [filter, setFilter] = useState(blankFilter);
  const [container, setContainer] = useState();
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const { data: { data: profitLossTotal = [] } = {} } = useProfitLossTotal(filter);

  const url = useMemo(() => '/me/report/profit-loss', []);

  useEffect(() => {
    if (profitLossTotal) {
      let income = 0;
      let expense = 0;

      const total = profitLossTotal.reduce((partialSum, a) => {
        if (a.label.toLowerCase() === 'income') {
          income += +a.total;
          return (partialSum += +a.total);
        } else {
          expense += +a.total;
          return (partialSum -= +a.total);
        }
      }, 0);

      setTotalIncome(income);
      setTotalExpense(expense);
      setTotal(total || 0);
    }
  }, [profitLossTotal]);

  useEffect(() => {
    if (!filter.start) {
      setFilter((p) => ({
        ...p,
        series_by: null,
      }));
    }
  }, [filter.start]);

  const onChange = (e) => {
    try {
      if (e.target.value.constructor === {}.constructor) {
        setFilter((prev) => ({ ...prev, ...e.target.value }));
      } else {
        setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      }
    } catch (error) {
      setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const onSelectChange = (val, e) => {
    if (e.action === 'select-option') {
      let o = { target: { name: e.name, value: val.value } };
      onChange(o);
      return;
    }
    if (e.action === 'clear') {
      let o = { target: { name: e.name, value: null } };
      onChange(o);
    }
  };

  const onFilterActionBtn = (e) => {
    if (e.toString() === 'PDF' || e.toString() === 'EXCEL') {
      if (e.toString() === 'PDF') exportPdf(container, 'ProfitLoss');
      if (e.toString() === 'EXCEL')
        downloaderFiles({
          filter: { ...filter, excel_creation_date: moment().format('YYYY-MM-DD HH:mm:ss') },
          type: e,
          url,
          cbLoad: () => {},
          name: 'Profit Loss Report',
        });
    }
    if (e.toString() === 'reset') setFilter(blankFilter);
  };

  const onSearch = () => {
    setShouldRefetch((p) => !p);
  };

  const composeDateRange = () => {
    const { start, end } = filter;
    if (start && end) {
      return `From ${moment(start).format('MM/DD/YY')} through ${moment(end).format('MM/DD/YY')}`;
    } else if (start && !end) {
      return `From ${moment(start).format('MM/DD/YY')} through to end`;
    } else if (!start && end) {
      return `From beginning through to ${moment(end).format('MM/DD/YY')}`;
    } else {
      return 'From beginning through to end';
    }
  };

  return (
    <>
      <Row className='mt-5 mb-3'>
        <Col>
          <CollapsableCard isExpanded={false} headerTitle='Filters'>
            <Filters filter={filter} onSelectChange={onSelectChange} onChange={onChange} onSearch={onSearch} />
          </CollapsableCard>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex justify-content-end'>
          <Tooltip arrow title={`Display % | ${filter.percentageIncluded ? 'ON' : 'OFF'}`}>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                e.target.blur();
                setFilter((prevValue) => ({
                  ...prevValue,
                  percentageIncluded: !prevValue.percentageIncluded,
                }));
              }}
            >
              <i
                class='fas fa-percentage'
                style={{ color: filter.percentageIncluded ? '#ceac02' : '#686A6B', fontSize: '1.2rem', width: '20px', height: '20px' }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title='Export report to EXCEL'>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                e.target.blur();
                onFilterActionBtn('EXCEL');
              }}
            >
              <i className='fas fa-file-excel' style={{ color: '#686A6B', fontSize: '1.2rem', width: '20px', height: '20px' }} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title='Export report to PDF'>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                e.target.blur();
                onFilterActionBtn('PDF');
              }}
            >
              <PdfIcon />
            </IconButton>
          </Tooltip>
        </Col>
      </Row>
      <div className=''>
        <div ref={(container) => setContainer(container)} style={{ border: '0px solid black', backgroundColor: 'white' }}>
          <Row>
            <Col>
              <h3 className='title' style={{ paddingTop: '15px', textAlign: 'center', fontWeight: 'bold' }}>
                Profit & Loss
              </h3>
              <h5
                className='title'
                style={{
                  paddingTop: '10px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                {composeDateRange()}
              </h5>
              <Col>
                {Object.keys(accountProfitLossType).map((accountType, index) => {
                  if (accountProfitLossType[accountType] !== accountProfitLossType.NONE) {
                    return (
                      <>
                        <ProfitLossSection
                          key={index}
                          shouldRefetch={shouldRefetch}
                          totalIncome={totalIncome}
                          totalExpense={totalExpense}
                          total={profitLossTotal}
                          accountType={accountProfitLossType[accountType]}
                          filters={{
                            ...filter,
                          }}
                        />
                      </>
                    );
                  }
                })}
              </Col>
              <Col md={12}>
                <div
                  style={{
                    backgroundColor: '#E8E8E8',
                    margin: '20px 0 35px 0',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '5px',
                  }}
                >
                  <span style={{ fontSize: '1.5rem' }}>Net income </span>
                  <span style={{ fontSize: '1.5rem' }}> {formatAmount(total)}</span>
                </div>
              </Col>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
