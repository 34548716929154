import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const getAllPendingIds = (filters) => {
  const url = '/me/transaction/pending/all-pending-ids';

  return axios.get(url, { params: { ...filters } });
};

export const useGetAllPendingIds = (filters) => {
  return useQuery(['transactions.pending'], () => getAllPendingIds(filters), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

const getAllTransactions = (filters) => {
  const url = '/me/transaction';

  return axios.get(url, { params: { ...filters } });
};

export const useAllTransactions = (filters) => {
  const { page, pageSize, sort_by, order_by } = filters;
  return useQuery(['transactions.all', page, pageSize, sort_by, order_by], () => getAllTransactions(filters), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

// pending
const getPendingTransactions = (filters) => {
  const url = 'me/transaction/pending';

  return axios.get(url, { params: { ...filters } });
};

export const usePendingTransactions = (filters) => {
  const { page, pageSize, sort_by, order_by } = filters;
  const normalizedFilters = { ...filters };
  delete normalizedFilters.document_type;

  return useQuery(['transactions.pending', page, pageSize, sort_by, order_by], () => getPendingTransactions(normalizedFilters), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const changeStatuses = (ids, data) => {
  const baseUrl = 'me/transaction/pending';
  const url = `${baseUrl}/change-statuses`;
  return axios.patch(url, { ids, data });
};

export const usePendingTransactionsStatusChanges = ({ callbacks }) => {
  const queryClient = useQueryClient();
  return useMutation(({ ids, data }) => changeStatuses(ids, data), {
    onSuccess: () => {
      if (typeof callbacks?.success === 'function') {
        callbacks.success();
      }

      queryClient.invalidateQueries(['transactions.pending']);
    },
    onError: () => {
      if (typeof callbacks?.error === 'function') {
        callbacks.error();
      }

      queryClient.invalidateQueries(['transactions.pending']);
    },
  });
};

const getOperationalUsers = () => {
  const baseUrl = 'me/transaction/pending';
  const url = `${baseUrl}/operational-users-options`;
  return axios.get(url);
};

export const useOperationalUsersOptions = () => {
  return useQuery(['transactions.pending.users-options'], () => getOperationalUsers(), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};
