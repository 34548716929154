import { DoDisturbOnOutlined, LayersClearOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { formatAmount } from '../../../../../utils/formatAmout';
import { formatDateForTable } from '../../../../../utils/formatDataForTable';
import { CUSTOMER_INVOICE } from '../../../../../constants/documentType.constants';

const NoCallbackFunc = () => {};

export const columns = ({
  onClearClick = NoCallbackFunc,
  onCancelClearClick = NoCallbackFunc,
  isClearingDisabled,
  accountTypeId,
  Actions,
  actionsStyle,
  isRefund,
}) => [
  {
    id: 'transactions.document_number',
    Header: <div>Document Number</div>,
    Cell: (row) => <div>{row.value}</div>,
    accessor: (d) => d['transactions.document_number'],
    sortable: true,
    maxWidth: 200,
  },
  {
    id: 'transactions.document_date',
    accessor: (d) => d['transactions.document_date'],
    Header: () => <div className='leftHeader'>Date</div>,
    sort: true,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    maxWidth: 120,
  },
  {
    id: 'transactions_due_date.due_date',
    Header: <div>Due Date</div>,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    accessor: (d) => d['transactions_due_date.due_date'],
    sortable: true,
    maxWidth: 120,
  },
  {
    id: 'transactions_due_date.amount',
    accessor: (d) => d['transactions_due_date.amount'],
    Header: () => <div className='leftHeader'>Amount</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sort: true,
    maxWidth: 150,
  },
  {
    id: 'document_type.name',
    Header: <div>Document Type</div>,
    Cell: (row) => <div>{row.value}</div>,
    accessor: (d) => d['document_type.name'],
    sortable: true,
    maxWidth: 150,
  },
  {
    id: 'transactions_due_date.balance',
    Header: <div>Remaining Balance</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    accessor: (d) => d['transactions_due_date.balance'],
    sortable: true,
    maxWidth: 150,
  },
  {
    id: 'add_balance',
    Header: <div>Applied Amount</div>,
    Cell: (row) => (
      <div className={row['original']['billing_payment_clearing_invoice.amount'] ? 'bg-success' : ''}>{formatAmount(row.value || 0)}</div>
    ),
    accessor: (d) => d['billing_payment_clearing_invoice.amount'],
    sortable: false,
    maxWidth: 150,
  },
  {
    id: 'end_balance',
    accessor: (d) => {
      return d['transactions_due_date.balance'] - d['billing_payment_clearing_invoice.amount'];
    },
    Header: () => <div className='leftHeader'>Ending Balance</div>,
    Cell: (row) => (
      <div className={row['original']['billing_payment_clearing_invoice.amount'] ? 'bg-success' : ''}>{formatAmount(row.value || 0)}</div>
    ),
    sortable: false,
    maxWidth: 150,
  },
  {
    id: 'total_balance',
    accessor: (d) => {
      return d['transactions_due_date.balance'] - d['total_clear_balance'];
    },
    Header: () => <div className='leftHeader'>Total Ending Balance</div>,
    Cell: (row) => (
      <div className={row['original']['total_clear_balance'] !== row['original']['billing_payment_clearing_invoice.amount'] ? 'bg-warning' : ''}>
        {formatAmount(row.value || 0)}
      </div>
    ),
    sortable: false,
    maxWidth: 150,
  },
  {
    id: 'actions',
    sortable: false,
    Header: 'Actions',
    accessor: (row) => {
      const isRefundOnInvoice = isRefund && row['transactions.document_type_journal_entry_id'] === CUSTOMER_INVOICE;

      if (isRefundOnInvoice) return <></>;

      return (
        <div className='d-flex' style={actionsStyle?.scaleDown ? { transform: `scale(${actionsStyle.scaleDown || 1})`, transformOrigin: '0 0' } : {}}>
          <Tooltip title='Apply' placement='top' arrow followCursor>
            <IconButton
              size='small'
              onClick={() => onClearClick(row)}
              color='success'
              disabled={row['transactions_due_date.balance'] - row['total_clear_balance'] === 0}
            >
              <LayersClearOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title='Cancel Application' placement='top' arrow followCursor>
            <IconButton
              size='small'
              onClick={(e) => onCancelClearClick(e, row)}
              color='error'
              disabled={+row['billing_payment_clearing_invoice.amount'] === 0}
            >
              <DoDisturbOnOutlined />
            </IconButton>
          </Tooltip>
          {accountTypeId !== 3 && Actions && React.cloneElement(Actions, { row: { original: row } })}
        </div>
      );
    },
  },
];
