import React, { useState } from 'react';
import { formatDateForTable } from '../../../../utils/formatDataForTable';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const DeleteDocumentPopup = ({ onDeleteConfirm, onClose, isOpen }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <Typography>Are you sure you want to delete this document?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onDeleteConfirm} color='error'>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const FixedAssetDocumentsTableColumns = ({ toggleCustomUploadModal, deleteFileFromS3, setEditInfoData, setPreviewInfo }) => {
  const handleDelete = (key) => {
    deleteFileFromS3(key);
  };

  return [
    {
      accessor: 'document_name',
      Header: 'Name',
      Cell: (row) => {
        return row?.value;
      },
    },
    {
      accessor: 'document_type',
      Header: 'Type',
      Cell: (row) => {
        return row?.value;
      },
    },
    {
      accessor: 'created_by',
      Header: 'Created by',
      Cell: (row) => {
        return row?.value;
      },
    },
    {
      accessor: 'created_at',
      Header: 'Created At',
      Cell: (row) => formatDateForTable(row.value),
    },
    {
      accessor: 'key',
      Header: 'Actions',
      sortable: false,
      Cell: (row) => {
        const key = row.original.key;
        const fileExtension = row.original.key.split('.').pop();

        const [deleteData, setDeleteData] = useState({
          showDeletePopup: false,
          deleteKey: null,
        });

        const handleDeleteConfirm = () => {
          handleDelete(deleteData.deleteKey);
          setDeleteData({
            showDeletePopup: false,
            deleteKey: null,
          });
        };

        const handleDeleteCancel = () => {
          setDeleteData({
            showDeletePopup: false,
            deleteKey: null,
          });
        };

        return (
          <>
            {['jpg', 'pdf', 'jpeg', 'png'].includes(fileExtension) && (
              <Tooltip title='Preview' placement='top' arrow followCursor>
                <IconButton
                  color='info'
                  onClick={() => {
                    setPreviewInfo({ previewKey: key, isLoading: true });
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title='Download' placement='top' arrow followCursor>
              <IconButton color='success'>
                <FileDownloadIcon
                  onClick={() => {
                    setPreviewInfo({ previewKey: key, isLoading: true, documentName: row.original.document_name });
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title='Edit' placement='top' arrow followCursor>
              <IconButton
                color='primary'
                onClick={() => {
                  setEditInfoData({
                    id: row.original.id,
                    documentName: row.original.document_name,
                    documentType: row.original.document_type_id,
                  });
                  toggleCustomUploadModal();
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Delete' placement='top' arrow followCursor>
              <IconButton
                color='error'
                onClick={() => {
                  setDeleteData({
                    showDeletePopup: true,
                    deleteKey: key,
                  });
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <DeleteDocumentPopup onDeleteConfirm={handleDeleteConfirm} onClose={handleDeleteCancel} isOpen={deleteData.showDeletePopup} />
          </>
        );
      },
    },
  ];
};
