import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Typography, Box, useMediaQuery, ListSubheader } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import logo from '../assets/logo/logo.png';
import { navigator } from './navigator';
import { hasPermissionHook } from '../hooks/hasPermission';

const LogoContainer = styled(Box)(({ theme }) => ({
  height: theme.options.appBar.height,
  fontSize: '24px',
  color: theme.palette.common.white,
  background: theme.palette.appBar.dark,
  // backgroundColor: '#fff',
  // boxShadow: '0 -1px 0 #404854 inset',
  padding: theme.spacing(1),
  cursor: 'pointer',
  width: '250px',
}));

const LogoImg = styled('img')(() => ({
  display: 'block',
  width: '170px',
  height: '2rem',
  margin: '3px auto',
}));

export const Navigator = ({ customStyles, setOpenSidebar }) => {
  const [open, setOpen] = useState({});
  const screenSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const { hasPermission } = hasPermissionHook();
  const [selectedIndex, setSelectedIndex] = useState(0);
  // const { functionalities } = useSelector((state) => ({
  //   functionalities: state.User.user.functionalities,
  // }));

  const handleOpenClick = (id) => {
    setOpen({ ...open, [id]: !open[id] });
  };

  const handleListItemClick = (id, hasChildren) => {
    setSelectedIndex(id);
    if (!screenSmUp && !hasChildren) {
      setOpenSidebar(false);
    }
  };

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, right: 'auto' }}>
      <LogoContainer>
        <LogoImg src={logo} />
      </LogoContainer>
      <List
        sx={(theme) => ({
          height: `calc(100vh - ${theme.options.appBar.height})`,
          width: '100%',
          maxWidth: 250,
          bgcolor: '#2f353a',
          color: '#fff',
          overflowY: 'auto',
          ...customStyles,
        })}
        disablePadding
        component='nav'
        aria-labelledby='nested-list-subheader'
      >
        {navigator.map((route) => {
          if (route.children && hasPermission(route.functionality)) {
            return (
              <React.Fragment key={route.functionality}>
                <ListItemButton
                  selected={selectedIndex === route.functionality}
                  onClick={() => {
                    handleOpenClick(route.functionality);
                    handleListItemClick(route.functionality, true);
                  }}
                >
                  <ListItemIcon sx={{ color: '#ddd', minWidth: '35px' }}>{route.icon}</ListItemIcon>
                  <ListItemText primary={<Typography variant='body2'> {route.name} </Typography>} />
                  {open[route.functionality] ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open[route.functionality]} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding sx={{ bgcolor: '#19282F' }}>
                    {route.children.map((childRoute) => {
                      if (hasPermission(childRoute.functionality)) {
                        return (
                          <NavLink key={childRoute.id} to={childRoute.url} style={{ color: '#eaeff1', textDecoration: 'none' }}>
                            <ListItemButton
                              sx={{ pl: 3 }}
                              selected={selectedIndex === childRoute.id}
                              onClick={() => handleListItemClick(childRoute.id)}
                            >
                              <ListItemIcon sx={{ color: '#ddd', minWidth: '35px' }}>{childRoute.icon}</ListItemIcon>
                              <ListItemText primary={<Typography variant='body2'> {childRoute.name} </Typography>} />
                            </ListItemButton>
                          </NavLink>
                        );
                      }

                      return null;
                    })}
                  </List>
                </Collapse>
              </React.Fragment>
            );
          }

          if (hasPermission(route.functionality)) {
            return (
              <NavLink key={route.functionality} to={route.url} style={{ color: '#eaeff1', textDecoration: 'none' }}>
                <ListItemButton selected={selectedIndex === route.functionality} onClick={() => handleListItemClick(route.functionality)}>
                  <ListItemIcon sx={{ color: '#ddd', minWidth: '35px' }}>{route.icon}</ListItemIcon>
                  <ListItemText primary={<Typography variant='body2'> {route.name} </Typography>} />
                </ListItemButton>
              </NavLink>
            );
          }
        })}
      </List>
    </div>
  );
};

Navigator.propTypes = {
  customStyles: PropTypes.object.isRequired,
  setOpenSidebar: PropTypes.func.isRequired,
};
