import { darken } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Select, { components } from 'react-select';
import { FormGroup, Label } from 'reactstrap';

const CustomSelect = ({
  isGrouped,
  label,
  onChange,
  options,
  errorMess,
  invalid,
  placeholder,
  className,
  name,
  isClearable,
  defaultValue,
  isMulti,
  disabled,
  customStyle,
  borderBottomOnly,
  closeMenuOnSelect,
  customValueStyle,
  markedOptions,
  ...rest
}) => {
  let formGroupStyle = {};
  if (errorMess && customStyle) {
    formGroupStyle = {
      ...customStyle,
      marginBottom: '-6px',
    };
  } else if (errorMess) {
    formGroupStyle = {
      marginBottom: '-6px',
    };
  } else if (customStyle) {
    formGroupStyle = {
      ...customStyle,
    };
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: isMulti ? 'auto' : '32px', // needs to extend height on multiselect
      // minHeight: "32px",
      // maxHeight: "32px",
      // marginBottom: '10px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '32px',
      maxHeight: '32px',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '5px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '5px',
    }),
    multiValue: (provided, state) => {
      if (customValueStyle) {
        return {
          ...provided,
          backgroundColor: darken('#ceac02', 0.3),
          borderRadius: '10px',
          padding: '0px 0px',
        };
      }

      return {
        ...provided,
      };
    },
    multiValueLabel: (provided, state) => {
      if (customValueStyle) {
        return {
          ...provided,
          color: 'white',
          fontWeight: 'bold',
          padding: 0,
        };
      }

      // emphasize text of the option which meets condition
      if (markedOptions?.marked) {
        const isMarked = markedOptions.marked(state.data);
        return { ...provided, fontWeight: isMarked ? 'bold' : 'inherit' };
      }

      return {
        ...provided,
      };
    },
    multiValueRemove: (provided, state) => {
      if (customValueStyle) {
        return {
          ...provided,
          cursor: 'pointer',
          color: 'white',
          ':hover': {
            backgroundColor: darken('#ceac02', 0.2),
            color: 'white',
          },
        };
      }

      return {
        ...provided,
        cursor: 'pointer',
      };
    },
    valueContainer: (provided) => ({
      ...provided,
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px 2px',
      paddingTop: '0px',
    }),
    menu: (provided) => {
      const result = { ...provided, zIndex: 100000000, color: '#000' };
      return result;
    },
    menuPortal: (base) => ({ ...base, zIndex: 9999999 }),
  };
  if (borderBottomOnly) {
    customStyles.control = (provided) => ({
      ...provided,
      minHeight: '32px',
      height: '32px',
      borderBottom: '1px solid lightgray',
      borderTop: '0px',
      borderRight: '0px',
      borderLeft: '0px',
      borderRadius: '0px',
    });
  }

  if (isGrouped) {
    customStyles.groupHeading = (base) => ({
      ...base,
      flex: '1 1',
      background: 'purple',
      color: 'white',
      padding: '10px',
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'center',
    });
  }

  const CustomGroupHeading = (props) => {
    return (
      <div style={{}}>
        <components.GroupHeading {...props} />
      </div>
    );
  };

  const handleValue = () => {
    if (isGrouped) {
      let flattenOptions = options.reduce((acc, curVal) => {
        return acc.concat(curVal.options);
      }, []);

      return isMulti
        ? flattenOptions.filter((option) => {
            return defaultValue.includes(option.id) || defaultValue.includes(option.value);
          })
        : flattenOptions.find((option) => option.value === defaultValue || option.id === defaultValue) || 0;
    } else {
      return isMulti
        ? options.filter((option) => {
            return defaultValue.includes(option.id) || defaultValue.includes(option.value);
          })
        : options.find((option) => option.value === defaultValue || option.id === defaultValue) || 0;
    }
  };

  return (
    <FormGroup style={formGroupStyle} className={`${className} ${invalid ? 'isInvalid' : ''}`}>
      {label ? <Label>{label}</Label> : null}
      <Select
        // {...rest}
        closeMenuOnSelect={closeMenuOnSelect}
        isMulti={isMulti}
        isClearable={isClearable}
        onChange={onChange}
        options={options}
        isDisabled={disabled}
        invalid={invalid}
        name={name}
        placeholder={placeholder}
        required={true}
        getOptionValue={(option) => option.id || option.value}
        getOptionLabel={(option) => option.name || option.label}
        styles={customStyles}
        components={{ GroupHeading: CustomGroupHeading }}
        value={handleValue()}
        classNamePrefix={rest.classNamePrefix || ''}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        menuPlacement={'bottom'}
      />

      <small className='text-danger'>{errorMess}</small>
    </FormGroup>
  );
};

CustomSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.array, PropTypes.string]),
  options: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  errorMess: PropTypes.string,
  className: PropTypes.string,
  isClearable: PropTypes.bool,
  required: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
};

CustomSelect.defaultProps = {
  required: false,
  disabled: false,
  closeMenuOnSelect: true,
};

export { CustomSelect };
