import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { CollapsableCard } from '../../../../common/Card/CollapsableCard';
import { CustomSelect } from '../../../../common/CustomSelect';
import { CustomSwitch } from '../../../../common/Inputs/CustomSwitch';
import { CustomPageableAsyncSelect } from '../../../../common/Inputs/CustomPageableAsyncSelect';
import { CustomCurrencyInput } from '../../../../common/Inputs/CurrencyInput';
import { CustomInput } from '../../../../common/CustomInput';
import { DatePickerComponent } from '../../../../common/DatePicker/DatePicker';
import { useDeleteFileFromS3, useGetFixedAsset, useGetFixedAssetsCodebooks, useUpdateFixedAssets, useUploadFileToS3 } from '../FixedAsettesApiRoutes';
import {
  ACC_TRAILER_TYPE,
  ASSET_TYPE,
  DEPRECIATION_METHOD,
  EQUIPMENT_CLASS,
  LIEN_HOLDER,
  MAKE,
  MODEL,
  TRUCK_TYPE,
  YEAR,
  STATUS,
  CONDITION,
  RENTAL_STATUS,
  MECHANICAL_STATUS,
  TIRE_STATUS,
  ACC_TRUCK_TYPE,
} from '../../../../constants/fixedAssetsCodebookType.constants';
import { useAsyncPageable } from '../../../../hooks/useAsyncPageable';
import { DepreciationItemsTable } from './DepreciationItemsTable';
import { useGetSupportTablesForSelect } from '../../../../hooks/commonApiHooks';
import { Button, Divider } from '@mui/material';
import { TransactionsFixedAssets } from '../transactionTables/TransactionsFixedAssets';
import { hasPermissionHook } from '../../../../hooks/hasPermission';
import { CHART_OF_ACCOUNTS, FIXED_ASSETS_TABLE, EDIT } from '../../../../constants/permissions.constants';
import { FixedAssetDocTable } from './FixedAssetDocTable';
import { UploadDocumentModal, useCustomUploadModalToggle } from './UploadDocumentModal';
import { uploadSelectOptions } from '../../../../constants/uploadSelectOptionsFAUploadDoc.constants';

export const FixedAssetsInfo = () => {
  const { hasPermission } = hasPermissionHook();
  const params = useParams();
  const { tenant_id } = useSelector((state) => ({ tenant_id: state.auth?.user?.tenant_id }));
  const [inputClicked, setInputClicked] = React.useState({});
  const [data, setData] = React.useState({ fixedAssetsData: {}, depreciationData: {} });
  const [unitNumber, setUnitNumber] = React.useState();
  const [editInfoData, setEditInfoData] = React.useState({ documentName: '', documentType: '' });

  const { fetchOptions } = useAsyncPageable();
  const { mutate: updateFixedAsset } = useUpdateFixedAssets(params.id);
  const { data: { data: fixedAssetData = {} } = {} } = useGetFixedAsset(params.id);
  const { data: { data: supportTableOptions = {} } = {} } = useGetSupportTablesForSelect({
    table_names: ['branch'],
  });
  const { data: { data: codebooks = {} } = {} } = useGetFixedAssetsCodebooks([
    ASSET_TYPE,
    DEPRECIATION_METHOD,
    EQUIPMENT_CLASS,
    LIEN_HOLDER,
    TRUCK_TYPE,
    YEAR,
    MAKE,
    MODEL,
    ACC_TRAILER_TYPE,
    STATUS,
    CONDITION,
    RENTAL_STATUS,
    MECHANICAL_STATUS,
    TIRE_STATUS,
    ACC_TRUCK_TYPE,
  ]);
  const { open, toggleCustomUploadModal } = useCustomUploadModalToggle();

  React.useEffect(() => {
    if (Object.keys(fixedAssetData).length) {
      const { depreciation_data, ...faData } = fixedAssetData;
      setData({
        fixedAssetsData: faData,
        depreciationData: depreciation_data,
      });

      setUnitNumber(faData?.unit_number);
    }
  }, [fixedAssetData]);

  const loadAccountOptions = async (inputValue, options) => {
    const data = await fetchOptions('/me/account/options/accounts', { q: inputValue, ...options });
    return data;
  };
  const loadVendors = async (inputValue, options) => {
    const data = await fetchOptions('/me/client/vendor/options-number-for-select', { q: inputValue, ...options, client_type_id: 2 });
    return data;
  };
  const loadCustomers = async (inputValue, options) => {
    const data = await fetchOptions('/me/client/customer/options-number-for-select', { q: inputValue, ...options, client_type_id: 1 });
    return data;
  };
  const loadVendorsAndCustomers = async (inputValue, options) => {
    const data = await fetchOptions('/me/client/customer/options-number-for-select', { q: inputValue, ...options });
    return data;
  };

  const onUpdateClick = () => {
    const {
      accumulated_depreciation_data,
      asset_account_data,
      depreciation_expense_data,
      vendor_name,
      sold_to_data,
      transactions,
      vendor_external_id,
      ...restFixedAssetsData
    } = data.fixedAssetsData;

    updateFixedAsset({ fixedAssetsData: restFixedAssetsData, depreciationData: data.depreciationData, id: params.id });
  };

  const changeInputClicked = (type) => {
    setInputClicked((oldData) => ({ ...oldData, [type]: !oldData[type] }));
  };

  const handleCurrencyInputChange = (value, name, type) => {
    console.log(name);
    console.log(value);
    setData((oldData) => ({ ...oldData, [type]: { ...oldData[type], [name]: value || null } }));
  };

  const onChangeSelect = (value, name, type) => {
    setData((oldData) => ({ ...oldData, [type]: { ...oldData[type], [name.name]: value ? value.value : null } }));
  };

  const onChangeAsyncSelect = (value, name, type) => {
    setData((oldData) => ({ ...oldData, [type]: { ...oldData[type], [name.name]: value ? value.value : null } }));
  };

  const handleDateChange = (date, name, type) => {
    setData((oldData) => ({ ...oldData, [type]: { ...oldData[type], [name]: date } }));
  };

  const onChangeInput = (e, type) => {
    const { name, value } = e.target;
    console.log(name);
    console.log(value);
    setData((oldData) => ({ ...oldData, [type]: { ...oldData[type], [name]: value } }));
  };

  const onSwitchChange = (e, type) => {
    const { name, checked } = e.target;
    setData((oldData) => ({ ...oldData, [type]: { ...oldData[type], [name]: checked } }));
  };

  const { mutate: deleteFileFromS3, isLoading: isLoadingDeleteDocument } = useDeleteFileFromS3();

  return (
    <CollapsableCard
      isExpanded={true}
      headerTitle={
        <div className='d-flex align-items-center justify-content-between'>
          <div>Fixed Asset: {data.fixedAssetsData.vin}</div>
          <div>Balance: $ 0.00</div>
        </div>
      }
      titleTypographyProps={{ fontSize: '22px' }}
      sxCardHeader={{ padding: '12px' }}
      sxCardContent={{ padding: '0px' }}
    >
      <div className='p-3'>
        <Row className='d-flex align-items-center'>
          <Col md='3'>
            <CustomSelect
              label='Asset Type'
              defaultValue={data.fixedAssetsData.asset_type || null}
              onChange={(e, name) => onChangeSelect(e, name, 'fixedAssetsData')}
              name='asset_type'
              options={codebooks[ASSET_TYPE] || []}
            />
          </Col>
          <Col md='3'>
            <CustomInput
              label='VIN'
              type='text'
              name='vin'
              value={data.fixedAssetsData.vin || null}
              placeholder='VIN'
              onChange={(e) => onChangeInput(e, 'fixedAssetsData')}
              disabled
            />
          </Col>
          <Col md='3'>
            <CustomInput
              rows={3}
              label='Description'
              type='textarea'
              name='description'
              value={data.fixedAssetsData.description || null}
              placeholder='Description'
              onChange={(e) => onChangeInput(e, 'fixedAssetsData')}
            />
          </Col>
          <Col md='3'>
            <CustomInput
              label='Unit Number'
              type='text'
              name='unit_number'
              value={data.fixedAssetsData.unit_number || null}
              placeholder='Unit Number'
              onChange={(e) => onChangeInput(e, 'fixedAssetsData')}
            />
          </Col>
        </Row>
        <Row>
          <Col md='3'>
            <CustomSelect
              label='Year'
              defaultValue={data.fixedAssetsData.year || null}
              onChange={(e, name) => onChangeSelect(e, name, 'fixedAssetsData')}
              name='year'
              options={codebooks[YEAR] || []}
            />
          </Col>
          <Col md='3'>
            <CustomSelect
              label='Make'
              defaultValue={data.fixedAssetsData.make || null}
              onChange={(e, name) => onChangeSelect(e, name, 'fixedAssetsData')}
              name='make'
              options={codebooks[MAKE] || []}
            />
          </Col>
          <Col md='3'>
            <CustomSelect
              label='Model'
              defaultValue={data.fixedAssetsData.model || null}
              onChange={(e, name) => onChangeSelect(e, name, 'fixedAssetsData')}
              name='model'
              options={codebooks[MODEL] || []}
            />
          </Col>
          <Col md='3'>
            <CustomInput label='Customer' onChange={() => {}} value={data.fixedAssetsData?.customer_data?.client_name || null} disabled />
          </Col>
        </Row>
        <Row className='d-flex align-items-center'>
          <Col md='3'>
            {tenant_id === 5 ? (
              <CustomSelect
                label='Acc Trailer Type'
                defaultValue={data.fixedAssetsData.acc_trailer_type || null}
                onChange={(e, name) => onChangeSelect(e, name, 'fixedAssetsData')}
                name='acc_trailer_type'
                options={codebooks[ACC_TRAILER_TYPE] || []}
              />
            ) : (
              <CustomSelect
                label='Truck Type'
                defaultValue={data.fixedAssetsData.acc_truck_type || null}
                onChange={(e, name) => onChangeSelect(e, name, 'fixedAssetsData')}
                name='acc_truck_type'
                options={codebooks[ACC_TRUCK_TYPE] || []}
              />
            )}
          </Col>
          <Col md='3'>
            <CustomSelect
              label='Lien Holder'
              defaultValue={data.fixedAssetsData.lien_holder || null}
              onChange={(e, name) => onChangeSelect(e, name, 'fixedAssetsData')}
              name='lien_holder'
              options={codebooks[LIEN_HOLDER] || []}
            />
          </Col>
          <Col md='3'>
            <CustomSelect
              label='Branch'
              defaultValue={data.fixedAssetsData.branch || null}
              onChange={(e, name) => onChangeSelect(e, name, 'fixedAssetsData')}
              name='branch'
              options={supportTableOptions['branch'] || []}
            />
          </Col>
          <Col md='3'>
            <CustomInput
              label='Active Rental Order Number'
              type='text'
              name='active_rental_order_number'
              value={data.fixedAssetsData.active_rental_order_number || null}
              placeholder='order number'
              onChange={(e) => onChangeInput(e, 'fixedAssetsData')}
            />
          </Col>
          {tenant_id === 2 && (
            <>
              <Col md='3'>
                <CustomSelect
                  label='Status'
                  defaultValue={data.fixedAssetsData.status || null}
                  onChange={(e, name) => onChangeSelect(e, name, 'fixedAssetsData')}
                  name='status'
                  options={codebooks[STATUS] || []}
                />
              </Col>
              <Col md='3'>
                <CustomSelect
                  label='Condition'
                  defaultValue={data.fixedAssetsData.condition || null}
                  onChange={(e, name) => onChangeSelect(e, name, 'fixedAssetsData')}
                  name='condition'
                  options={codebooks[CONDITION] || []}
                />
              </Col>
            </>
          )}
          {tenant_id === 5 && (
            <>
              <Col md='3'>
                <CustomSelect
                  label='Rental Status'
                  defaultValue={data.fixedAssetsData.rental_status || null}
                  onChange={(e, name) => onChangeSelect(e, name, 'fixedAssetsData')}
                  name='rental_status'
                  options={codebooks[RENTAL_STATUS] || []}
                />
              </Col>
              <Col md='3'>
                <CustomSelect
                  label='Mechanical Status'
                  defaultValue={data.fixedAssetsData.mechanical_status || null}
                  onChange={(e, name) => onChangeSelect(e, name, 'fixedAssetsData')}
                  name='mechanical_status'
                  options={codebooks[MECHANICAL_STATUS] || []}
                />
              </Col>
              <Col md='3'>
                <CustomSelect
                  label='Tire Status'
                  defaultValue={data.fixedAssetsData.tire_status || null}
                  onChange={(e, name) => onChangeSelect(e, name, 'fixedAssetsData')}
                  name='tire_status'
                  options={codebooks[TIRE_STATUS] || []}
                />
              </Col>
            </>
          )}
        </Row>
        <Row className='d-flex align-items-center'>
          <Col md='3'>
            <CustomSwitch
              label='On Rent'
              name='on_rent'
              checked={data.fixedAssetsData.on_rent}
              onChange={(e) => onSwitchChange(e, 'fixedAssetsData')}
              labelPosition='top'
            />
          </Col>
          <Col md='3'>
            <CustomSwitch
              label='Mileage'
              name='mileage'
              checked={data.fixedAssetsData.mileage}
              onChange={(e) => onSwitchChange(e, 'fixedAssetsData')}
              labelPosition='top'
            />
          </Col>
          <Col md='3'>
            <CustomSwitch
              label='Disposed'
              name='disposed'
              checked={data.fixedAssetsData.disposed}
              onChange={(e) => onSwitchChange(e, 'fixedAssetsData')}
              labelPosition='top'
            />
          </Col>
          <Col md='3'>
            <CustomSwitch
              label='Active'
              name='active'
              checked={data.fixedAssetsData.active}
              onChange={(e) => onSwitchChange(e, 'fixedAssetsData')}
              labelPosition='top'
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CustomInput
              rows={3}
              label='Comments'
              type='textarea'
              name='comments'
              value={data.fixedAssetsData.comments || null}
              placeholder='Comments'
              onChange={(e) => onChangeInput(e, 'fixedAssetsData')}
            />
          </Col>
        </Row>
      </div>
      <CollapsableCard
        isExpanded
        headerTitle={'Depreciation'}
        titleTypographyProps={{ fontSize: '16px' }}
        sxCardHeader={{ padding: '12px' }}
        addStyles={{ marginBottom: '5px' }}
      >
        <Row>
          <Col md='3'>
            <CustomSelect
              label='Depreciation Method'
              defaultValue={data.depreciationData?.depreciation_method || null}
              onChange={(e, name) => onChangeSelect(e, name, 'depreciationData')}
              name='depreciation_method'
              options={codebooks[DEPRECIATION_METHOD] || []}
            />
          </Col>
          <Col md='3'>
            <DatePickerComponent
              onChange={(date) => handleDateChange(date, 'begining_depreciation_date', 'depreciationData')}
              showYearDropdown
              isClearable
              name='begining_depreciation_date'
              selected={data.depreciationData?.begining_depreciation_date}
              placeholder='mm/dd/yyyy'
              dateFormat={'MM/dd/yyyy'}
              label='Begining Depreciation Date'
            />
          </Col>
          <Col md='3'>
            <DatePickerComponent
              onChange={(date) => handleDateChange(date, 'end_depreciation_date', 'depreciationData')}
              showYearDropdown
              isClearable
              name='end_depreciation_date'
              selected={data.depreciationData?.end_depreciation_date}
              placeholder='mm/dd/yyyy'
              dateFormat={'MM/dd/yyyy'}
              label='End Depreciation Date'
            />
          </Col>
          <Col md='3'>
            <CustomInput
              rows={3}
              label='Salvage Value'
              type='text'
              name='salvage_value'
              value={data.depreciationData?.salvage_value || null}
              placeholder='Salvage Value'
              onChange={(e) => onChangeInput(e, 'depreciationData')}
            />
          </Col>
        </Row>
        <Row>
          <Col md='3'>
            <CustomInput
              rows={3}
              label='Depreciation Period'
              type='text'
              name='depreciation_period'
              value={data.depreciationData?.depreciation_period || null}
              placeholder='Depreciation Period'
              onChange={(e) => onChangeInput(e, 'depreciationData')}
            />
          </Col>
          <Col md='3'>
            <CustomCurrencyInput
              label='Monthly Depreciation Expense'
              onChange={(value, name) => handleCurrencyInputChange(value, name, 'depreciationData')}
              name='monthly_depreciation_expense'
              value={data.depreciationData?.monthly_depreciation_expense}
              prefix={'$ '}
            />
          </Col>
          <Col md='3'>
            {inputClicked.asset_account ? (
              <CustomPageableAsyncSelect
                label='Asset Account'
                isClearable
                onChange={(e, name) => onChangeAsyncSelect(e, name, 'depreciationData')}
                loadOptions={loadAccountOptions}
                name='asset_account'
                defaultValue={data.depreciationData?.asset_account || null}
              />
            ) : (
              <CustomInput
                label='Asset Account'
                value={
                  data.fixedAssetsData.asset_account_data?.account_number
                    ? data.fixedAssetsData.asset_account_data?.account_number + ' - ' + data.fixedAssetsData.asset_account_data?.account_name
                    : ''
                }
                isClearable
                onClick={() => changeInputClicked('asset_account')}
                onChange={() => {}}
                name='asset_account'
              />
            )}
          </Col>
          <Col md='3'>
            {inputClicked.depreciation_expense ? (
              <CustomPageableAsyncSelect
                label='Depreciation Expense'
                isClearable
                onChange={(e, name) => onChangeAsyncSelect(e, name, 'depreciationData')}
                loadOptions={loadAccountOptions}
                name='depreciation_expense'
                defaultValue={data.depreciationData?.depreciation_expense || null}
              />
            ) : (
              <CustomInput
                label='Depreciation Expense'
                value={
                  data.fixedAssetsData.depreciation_expense_data?.account_number
                    ? data.fixedAssetsData.depreciation_expense_data?.account_number +
                      ' - ' +
                      data.fixedAssetsData.depreciation_expense_data?.account_name
                    : ''
                }
                isClearable
                onClick={() => changeInputClicked('depreciation_expense')}
                onChange={() => {}}
                name='depreciation_expense'
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col md='3'>
            {inputClicked.accumulated_depreciation ? (
              <CustomPageableAsyncSelect
                label='Accumulated Depreciation'
                isClearable
                onChange={(e, name) => onChangeAsyncSelect(e, name, 'depreciationData')}
                loadOptions={loadAccountOptions}
                name='accumulated_depreciation'
                defaultValue={data.depreciationData?.accumulated_depreciation || null}
              />
            ) : (
              <CustomInput
                label='Accumulated Depreciation'
                value={
                  data.fixedAssetsData.accumulated_depreciation_data?.account_number
                    ? data.fixedAssetsData.accumulated_depreciation_data?.account_number +
                      ' - ' +
                      data.fixedAssetsData.accumulated_depreciation_data?.account_name
                    : ''
                }
                isClearable
                onClick={() => changeInputClicked('accumulated_depreciation')}
                onChange={() => {}}
                name='accumulated_depreciation'
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <DepreciationItemsTable fixed_asset_depreciation_id={data.depreciationData?.id} />
          </Col>
        </Row>
      </CollapsableCard>
      <CollapsableCard
        isExpanded={true}
        headerTitle={'Transactions'}
        titleTypographyProps={{ fontSize: '16px' }}
        sxCardHeader={{ padding: '12px' }}
        addStyles={{ marginBottom: '5px' }}
      >
        <TransactionsFixedAssets tableData={data?.fixedAssetsData?.transactions || []} />
      </CollapsableCard>
      <CollapsableCard
        isExpanded={false}
        headerTitle={'Purchase Information'}
        titleTypographyProps={{ fontSize: '16px' }}
        sxCardHeader={{ padding: '12px' }}
        addStyles={{ marginBottom: '5px' }}
      >
        <Row>
          <Col md='3'>
            <CustomCurrencyInput
              label='Book Value'
              onChange={(value, name) => handleCurrencyInputChange(value, name, 'fixedAssetsData')}
              name='book_value'
              value={data.fixedAssetsData.book_value}
              prefix={'$ '}
            />
          </Col>
          <Col md='3'>
            {inputClicked.vendor ? (
              <CustomPageableAsyncSelect
                label='Vendor'
                isClearable
                onChange={(e, name) => onChangeAsyncSelect(e, name, 'fixedAssetsData')}
                loadOptions={loadVendors}
                name='vendor'
                defaultValue={data.fixedAssetsData.vendor || null}
              />
            ) : (
              <CustomInput
                label='Vendor'
                value={data.fixedAssetsData.vendor ? data.fixedAssetsData.vendor_external_id + ' - ' + data.fixedAssetsData.vendor_name : ''}
                isClearable
                onClick={() => changeInputClicked('vendor')}
                onChange={() => {}}
                name='vendor'
              />
            )}
          </Col>
          <Col md='3'>
            <DatePickerComponent
              onChange={(date) => handleDateChange(date, 'purchase_date', 'fixedAssetsData')}
              showYearDropdown
              isClearable
              name='purchase_date'
              selected={data.fixedAssetsData.purchase_date}
              placeholder='mm/dd/yyyy'
              dateFormat={'MM/dd/yyyy'}
              label='Purchase Date'
            />
          </Col>
          <Col md='3'>
            <CustomInput
              rows={3}
              label='Invoice Number'
              type='text'
              name='invoice_number'
              value={data.fixedAssetsData.invoice_number || null}
              placeholder='Invoice Number'
              onChange={(e) => onChangeInput(e, 'fixedAssetsData')}
            />
          </Col>
        </Row>
        <Row>
          <Col md='3'>
            <CustomCurrencyInput
              label='Acquisition Cost'
              onChange={(value, name) => handleCurrencyInputChange(value, name, 'fixedAssetsData')}
              name='acquisition_cost'
              value={data.fixedAssetsData.acquisition_cost}
              prefix={'$ '}
            />
          </Col>
        </Row>
      </CollapsableCard>
      <CollapsableCard
        isExpanded={false}
        headerTitle={'Disposal Information'}
        titleTypographyProps={{ fontSize: '16px' }}
        sxCardHeader={{ padding: '12px' }}
        addStyles={{ marginBottom: '5px' }}
      >
        <Row>
          <Col md='3'>
            <CustomCurrencyInput
              label='Sold For'
              name='sold_for'
              value={data.fixedAssetsData?.sold_for || null}
              placeholder='Sold For'
              onChange={(value, name) => handleCurrencyInputChange(value, name, 'fixedAssetsData')}
              prefix={'$ '}
            />
          </Col>
          <Col md='3'>
            <DatePickerComponent
              onChange={(date) => handleDateChange(date, 'sales_date', 'fixedAssetsData')}
              showYearDropdown
              isClearable
              name='sales_date'
              selected={data.fixedAssetsData?.sales_date}
              placeholder='mm/dd/yyyy'
              dateFormat={'MM/dd/yyyy'}
              label='Sales Date'
            />
          </Col>
          <Col md='3'>
            <CustomCurrencyInput
              label='Sales Expenses'
              onChange={(value, name) => handleCurrencyInputChange(value, name, 'fixedAssetsData')}
              name='sales_expenses'
              value={data.fixedAssetsData?.sales_expenses}
              prefix={'$ '}
            />
          </Col>
          <Col md='3'>
            {inputClicked.sold_to ? (
              <CustomPageableAsyncSelect
                label='Sold To'
                isClearable
                onChange={(e, name) => onChangeAsyncSelect(e, name, 'fixedAssetsData')}
                loadOptions={loadVendorsAndCustomers}
                name='sold_to'
                defaultValue={data.fixedAssetsData.sold_to || null}
              />
            ) : (
              <CustomInput
                label='Sold To'
                value={
                  data.fixedAssetsData.sold_to_data?.client_id
                    ? data.fixedAssetsData.sold_to_data?.external_id + ' - ' + data.fixedAssetsData.sold_to_data?.client_name
                    : ''
                }
                isClearable
                onClick={() => changeInputClicked('sold_to')}
                onChange={() => {}}
                name='sold_to'
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col md='3'>
            <CustomSwitch
              label='Claim'
              name='claim'
              checked={data.fixedAssetsData?.claim}
              onChange={(e) => onSwitchChange(e, 'fixedAssetsData')}
              labelPosition='top'
            />
          </Col>
          <Col md='3'>
            <CustomSwitch
              label='Sold'
              name='sold'
              checked={data.fixedAssetsData?.sold}
              onChange={(e) => onSwitchChange(e, 'fixedAssetsData')}
              labelPosition='top'
            />
          </Col>
        </Row>
      </CollapsableCard>
      <CollapsableCard isExpanded={false} headerTitle={'Documents'} titleTypographyProps={{ fontSize: '16px' }} sxCardHeader={{ padding: '12px' }}>
        <Col className='d-flex justify-content-end mb-2'>
          <Button onClick={toggleCustomUploadModal}>Upload Document</Button>
        </Col>
        <FixedAssetDocTable
          deleteFileFromS3={deleteFileFromS3}
          unitNumber={unitNumber}
          toggleCustomUploadModal={toggleCustomUploadModal}
          setEditInfoData={setEditInfoData}
        />
        <UploadDocumentModal
          isOpen={open}
          toggleCustomUploadModal={toggleCustomUploadModal}
          selectOptions={uploadSelectOptions}
          unitNumber={unitNumber}
          isLoadingDeleteDocument={isLoadingDeleteDocument}
          editInfoData={editInfoData}
          setEditInfoData={setEditInfoData}
        />
      </CollapsableCard>
      <Row className='mt-3'>
        <Col>
          <Divider />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col className='d-flex justify-content-end'>
          <Button className='mr-2' onClick={onUpdateClick} disabled={!hasPermission(FIXED_ASSETS_TABLE, EDIT)}>
            Update
          </Button>
        </Col>
      </Row>
    </CollapsableCard>
  );
};
