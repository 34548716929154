import React from 'react';
import ReactTable from 'react-table';
import { PendingGeneralLedgerSubColumns } from './PendingLedgersSubColumns';

export const ExpendPendingGeneralLedgerDataTable = ({ gls }) => {
  return (
    <div className='m-3 p-2 box-shadow'>
      <h6>Pending General Ledger</h6>
      <ReactTable
        data={gls}
        columns={PendingGeneralLedgerSubColumns}
        filterable={false}
        defaultPageSize={gls.length || 2}
        showPagination={false}
        defaultSorted={[{ desc: true, id: 'debit_amount' }]}
      />
    </div>
  );
};
