import { Box, Button, Divider, FormControlLabel, Paper, Radio, RadioGroup, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { CollapsableCard } from '../../../../common/Card/CollapsableCard';
import DataGridComponent from '../../../../component/DataTable/component/DataTableComponent';
import { ReportFilters } from './ReportFilters';

import { notificationDangerCustomTime } from '../../../../utils/toastify';
import { useDetailedReportDownload, useGetBancRecoData, useGetGlTable, useSummaryReportDownload, useSummaryReportPreview } from '../BankRecoApiHooks';
import { PaymentsTableColumns } from '../columns/PaymentsTableColumns';

export const Reports = () => {
  const params = useParams();

  const [tableFilters, setTableFilters] = React.useState({
    checksAndPayments: {
      page: 0,
      pageSize: 10,
      sort_by: 'posting_date',
      order_by: 'ASC',
      tableType: 'checks',
      reportFilters: {},
    },
    depositAndOtherCredits: {
      page: 0,
      pageSize: 10,
      sort_by: 'posting_date',
      order_by: 'ASC',
      tableType: 'deposits',
      reportFilters: {},
    },
  });
  const [reportType, setPdfReportType] = React.useState(null);

  const { data: { data: bankRecoData } = {} } = useGetBancRecoData({ id: params.reconciliationId });
  const { data: { data: checksAndPaymentsTableData = {} } = {} } = useGetGlTable(tableFilters.checksAndPayments);
  const { data: { data: depositAndOtherCreditsTableData = {} } = {} } = useGetGlTable(tableFilters.depositAndOtherCredits);
  const { mutate: downloadSummaryReport, isLoading: downloadSummaryLoading } = useSummaryReportDownload();
  const { mutate: previewSummaryReport, data: { data: summaryHTMLReport } = {}, isLoading: previrewSummaryLoading } = useSummaryReportPreview();
  const { mutate: downloadDetailedReport, isLoading: downloadDetailLoading } = useDetailedReportDownload();

  const emptyGlTableData = React.useMemo(() => ({ data: [], page: 0, pageSize: 10, pageTotal: 0 }), []);
  const tableColumns = React.useMemo(() => PaymentsTableColumns({ report: true }), []);

  React.useEffect(() => {
    if (bankRecoData && Object.keys(bankRecoData).length) {
      setTableFilters((oldFilters) => ({
        checksAndPayments: {
          ...oldFilters.checksAndPayments,
          account: bankRecoData.account,
          reportFilters: { bankReconciliationId: params.reconciliationId },
        },
        depositAndOtherCredits: {
          ...oldFilters.depositAndOtherCredits,
          account: bankRecoData.account,
          reportFilters: { bankReconciliationId: params.reconciliationId },
        },
      }));
    }
  }, [bankRecoData]);

  const onTableFilterChange = (input, name) => {
    if (name === 'checks') {
      setTableFilters((oldFilters) => ({ ...oldFilters, checksAndPayments: { ...oldFilters.checksAndPayments, ...input } }));
    } else {
      setTableFilters((oldFilters) => ({ ...oldFilters, depositAndOtherCredits: { ...oldFilters.depositAndOtherCredits, ...input } }));
    }
  };

  const onChangeRadio = (e) => {
    setPdfReportType(e.target.value);
  };

  const onGenerateReport = () => {
    if (!reportType) {
      notificationDangerCustomTime('Please choose type of report first!', 5000);
      return;
    }

    const creationDate = moment().format('MM/DD/YYYY');
    const creationTime = moment().format('hh:mm A');

    if (+reportType === 1) {
      previewSummaryReport({ bankRecoId: bankRecoData.id, creationDate, creationTime });
    } else if (+reportType === 2) {
      downloadDetailedReport({ bankRecoId: bankRecoData.id, creationDate, creationTime });
    } else if (+reportType === 3) {
      previewSummaryReport({ bankRecoId: bankRecoData.id, creationDate, creationTime });
      downloadDetailedReport({ bankRecoId: bankRecoData.id, creationDate, creationTime });
    }
  };

  const downloadReport = (exportType = 'PDF') => {
    const creationDate = moment().format('MM/DD/YYYY');
    const creationTime = moment().format('hh:mm A');

    downloadSummaryReport({ bankRecoId: bankRecoData.id, creationDate, creationTime, exportType });
  };

  return (
    <Box sx={{ boxShadow: 2 }}>
      <Row>
        <Col>
          <CollapsableCard headerTitle={<Typography sx={{ fontSize: '18px' }}>Reports</Typography>}>
            <Row className='mb-3'>
              <Col>
                <CollapsableCard headerTitle='Deposits and Checks Table Filters' isExpanded={false}>
                  <ReportFilters bankRecoData={bankRecoData} setTableFilters={setTableFilters} tableFilters={tableFilters} />
                </CollapsableCard>
              </Col>
            </Row>
            <Row>
              <Col>
                <CollapsableCard
                  isExpanded={false}
                  headerTitle={
                    <div>
                      <span className='mr-3'>Checks and Payments</span>
                    </div>
                  }
                >
                  <DataGridComponent
                    dataSource={checksAndPaymentsTableData || emptyGlTableData}
                    onFilterChange={(e) => onTableFilterChange(e, 'checks')}
                    columns={tableColumns}
                    loading={false}
                  />
                </CollapsableCard>
              </Col>
              <Col>
                <CollapsableCard
                  isExpanded={false}
                  headerTitle={
                    <div>
                      <span className='mr-3'>Deposits and Other Credits</span>
                    </div>
                  }
                >
                  <DataGridComponent
                    dataSource={depositAndOtherCreditsTableData || emptyGlTableData}
                    onFilterChange={(e) => onTableFilterChange(e, 'deposits')}
                    columns={tableColumns}
                    loading={false}
                  />
                </CollapsableCard>
              </Col>
            </Row>
          </CollapsableCard>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col>
          <CollapsableCard headerTitle={<Typography sx={{ fontSize: '18px' }}>Generate PDF Reports</Typography>}>
            <Row>
              <Col>
                <Paper elevation={2} sx={{ p: 1 }}>
                  <Typography variant='subtitle1'>Choose Type of Report</Typography>
                  <RadioGroup row onChange={onChangeRadio} value={reportType}>
                    <FormControlLabel value='1' control={<Radio />} label='Summary' />
                    <FormControlLabel value='2' control={<Radio sx={{ ml: 2 }} />} label='Detail (automatically download pdf)' />
                    <FormControlLabel value='3' control={<Radio sx={{ ml: 2 }} />} label='Both' />
                  </RadioGroup>
                </Paper>
              </Col>
            </Row>
            <Row>
              <Col className='d-flex justify-content-end'>
                <Button color='primary' onClick={onGenerateReport} disabled={downloadDetailLoading || previrewSummaryLoading}>
                  Generate Report
                </Button>
              </Col>
            </Row>
            {summaryHTMLReport && (
              <>
                <Row className='mt-3'>
                  <Col>
                    <Paper elevation={2} sx={{ p: 1 }}>
                      <Row>
                        <Col></Col>
                        <Col md='8'>
                          <div dangerouslySetInnerHTML={{ __html: summaryHTMLReport }} />
                        </Col>
                        <Col></Col>
                      </Row>
                      <Row>
                        <Col>
                          <Divider sx={{ mt: 3, mb: 1 }} />
                        </Col>
                      </Row>
                      <Row>
                        <Col></Col>
                        <Col md='8' className='d-flex justify-content-end'>
                          <Button color='success' onClick={() => downloadReport('PDF')} disabled={downloadSummaryLoading}>
                            Download PDF
                          </Button>
                          <Button className='ml-2' color='warning' onClick={() => downloadReport('EXCEL')} disabled={downloadSummaryLoading}>
                            Download Excel
                          </Button>
                        </Col>
                        <Col></Col>
                      </Row>
                    </Paper>
                  </Col>
                </Row>
              </>
            )}
          </CollapsableCard>
        </Col>
      </Row>
    </Box>
  );
};
