import { LayersClearOutlined, ModeEditOutline, ClearOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { formatAmount } from '../../../../utils/formatAmout';
import { formatDateForTable } from '../../../../utils/formatDataForTable';

export const lineInfoTableColumns = ({ onEditClick, onClearingClick, isFinished, onDeleteClick, disabled }) => {
  const composeDepositTypeColumn = (row) => {
    const labelStyle = {
      display: 'flex',
      alignItems: 'center',
    };

    return (
      <>
        <div>
          {row.original.claim_dep && (
            <div className='d-flex justify-content-between'>
              <span style={labelStyle}>Claim</span>
            </div>
          )}
          {row.original.down_payment_dep && (
            <div className='d-flex justify-content-between'>
              <span style={labelStyle}>Down Payment</span>
            </div>
          )}
          {row.original.fixed_asset_dep && (
            <div className='d-flex justify-content-between'>
              <span style={labelStyle}>Fixed Asset</span>
            </div>
          )}
          {row.original.legal_dep && (
            <div className='d-flex justify-content-between'>
              <span style={labelStyle}>Legal</span>
            </div>
          )}
          {row.original.security_dep && (
            <div className='d-flex justify-content-between'>
              <span style={labelStyle}>Security</span>
            </div>
          )}
        </div>
      </>
    );
  };

  return [
    {
      accessor: 'account_types.name',
      Header: 'Account Type',
      Cell: (row) => {
        return <div>{row.value}</div>;
      },
    },
    {
      accessor: 'account.number',
      Header: 'Account',
      Cell: (row) => {
        const value =
          (row.original?.account?.external_id ? row.original?.account?.external_id : row.original?.account?.number) +
          ' - ' +
          row.original?.account?.name;

        let openClient = () => {};
        if (row.original?.account_type_id === 1) {
          openClient = () => {
            window.open(`/client/customers/360/${row.original?.account?.number}`, '_blank');
          };
        } else if (row.original?.account_type_id === 2) {
          openClient = () => {
            window.open(`/client/vendors/360/${row.original?.account?.number}`, '_blank');
          };
        }

        return (
          <div onClick={openClient} style={{ cursor: 'pointer' }}>
            {value}
          </div>
        );
      },
    },

    {
      accessor: 'deposit_categories.name',
      Header: 'Deposit Category',
      Cell: (row) => <div>{row.value}</div>,
    },
    {
      accessor: 'document_date',
      Header: 'Check (Document) Date',
      Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    },
    {
      accessor: 'transaction_types.name',
      Header: 'Transaction Type',
      Cell: (row) => <div>{row.value}</div>,
    },
    {
      accessor: 'payment_types.name',
      Header: 'Payment Type',
      Cell: (row) => <div>{row.value}</div>,
    },
    {
      accessor: 'document_number',
      Header: 'Check (Document) Number',
      Cell: (row) => <div>{row.value}</div>,
    },
    {
      accessor: 'description',
      Header: 'Description',
      Cell: (row) => (
        <div>
          {row?.value?.substring(0, 40)}
          {row?.value?.length > 40 ? '...' : ''}
        </div>
      ),
    },
    {
      accessor: 'amount',
      Header: 'Credit Amount',
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
    },
    {
      accessor: 'cleared_amount',
      Header: 'Applied Amount',
      Cell: (row) => <div>{formatAmount(row.value)}</div>,
    },
    {
      Header: 'Deposit Type',
      Cell: (row) => <div>{composeDepositTypeColumn(row)}</div>,
      sortable: false,
    },
    {
      filterable: false,
      sortable: false,
      accessor: (row) => {
        return (
          <div className='d-flex'>
            <Tooltip title='Applying' placement='top' arrow followCursor>
              <IconButton onClick={() => onClearingClick(row)} color='success' disabled={isFinished || disabled}>
                <LayersClearOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title='Edit' placement='top' arrow followCursor>
              <IconButton onClick={() => onEditClick(row)} color='primary' disabled={isFinished || disabled}>
                <ModeEditOutline />
              </IconButton>
            </Tooltip>
            <Tooltip title='Delete' placement='top' arrow followCursor>
              <IconButton onClick={(e) => onDeleteClick(e, row)} color='error' disabled={isFinished || disabled}>
                <ClearOutlined />
              </IconButton>
            </Tooltip>
          </div>
          // <div
          //   style={{
          //     minWidth: '100%',
          //     display: 'flex',
          //     justifyContent: 'flex-end',
          //   }}
          // >
          //   <Button
          //     onClick={(e) => {
          //       onEditClick(row.id);
          //     }}
          //     size='sm'
          //     color='primary'
          //   >
          //     <i className='fas fa-link'></i>
          //   </Button>
          // </div>
        );
      },
      id: 'actionBtn',
      Header: (props) => <div className='leftHeader'>Action</div>,
      // width: '200px',
    },
  ];
};
