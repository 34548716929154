import React from 'react';
import DataTablePagination from './DataTablePagination';
import { DataTableShadowComponent } from './DataTableShadowComponent';
import ReactTable from 'react-table';

export const PageableTable = ({
  dataSource = { data: [], page: 1, pageSize: 10, pageTotal: 0 },
  onPageChange = () => {},
  onPageSizeChange = () => {},
  onSortChange = () => {},
  columns = [],
  loading = false,
  pagination = true,
  className = '',
  defaultPageSize,
  ...props
}) => {
  return (
    <ReactTable
      showPaginationBottom={pagination}
      PaginationComponent={DataTablePagination}
      TableComponent={DataTableShadowComponent}
      className={`-striped -highlight ${className}`}
      getFooterProps={() => ({ style: { display: 'block' } })}
      defaultSortDesc
      data={dataSource.data}
      columns={columns}
      page={dataSource.page}
      pageSize={dataSource.pageSize}
      pages={dataSource.pageTotal}
      minRows={dataSource.data && dataSource.data.length > 5 ? 5 : dataSource.data ? dataSource.data.length : 1}
      defaultPageSize={defaultPageSize || 10}
      showPageSizeOptions
      NoDataComponent={() => null}
      manual
      onPageSizeChange={onPageSizeChange}
      onPageChange={onPageChange}
      onSortedChange={onSortChange}
      loading={loading}
      {...props}
    />
  );
};
