import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import PropTypes from 'prop-types';

export const CustomRadioGroup = ({ label, name, items, selected, onChange }) => {
  return (
    <FormControl>
      <FormLabel id={`controlled-radio-buttons-group-${name}`} sx={{ color: 'black' }}>
        {label}
      </FormLabel>
      <RadioGroup aria-labelledby={`controlled-radio-buttons-group-${name}`} name={name} value={selected} selected={selected} onChange={onChange}>
        {items.map((item, index) => (
          <FormControlLabel
            key={`${name}-${index}-${item.id || item.value}`}
            control={<Radio />}
            label={item.name || item.label}
            value={item.value || item.id}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

CustomRadioGroup.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  selected: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};

CustomRadioGroup.defaultProps = {};
