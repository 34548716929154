import { useState } from 'react';

const usePopup = () => {
  const [options, setOptions] = useState({
    open: false,
    anchor: null,
  });

  const handleClose = () => {
    setOptions((p) => ({ ...p, open: false, anchor: null }));
  };

  const handleOpen = (e = null) => {
    const anchor = e?.currentTarget;

    setOptions((p) => ({
      ...p,
      anchor,
      open: true,
    }));
  };

  return { options, onClose: handleClose, onOpen: handleOpen };
};

export { usePopup };
