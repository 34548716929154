export const SYS_SET_ACCOUNT = 'SYS_SET_ACCOUNT';
export const SYS_SET_PAYMENT_TYPE = 'SYS_SET_PAYMENT_TYPE';
export const SYS_SET_INVOICE_TYPE = 'SYS_SET_INVOICE_TYPE';
export const SYS_SET_BRANCH_CODE = 'SYS_SET_BRANCH_CODE';
export const SYS_SET_TAX_CODE = 'SYS_SET_TAX_CODE';
export const SYS_SET_TAX_ITEM = 'SYS_SET_TAX_ITEM';
export const SYS_SET_PAYMENT_TERM = 'SYS_SET_PAYMENT_TERM';

const initialState = {
    account: [],
    paymentType:[],
    invoiceType:[],
    branchCode:[],
    taxCode:[],
    taxItem:[],
    paymentTerm:[]
};
export default function(state = initialState, action) {
    //  console.log(action);
    switch (action.type) {
        case SYS_SET_ACCOUNT:
            return {
                ...state,
                account: action.payload||[]
            };
        case SYS_SET_PAYMENT_TYPE:return{
            ...state,
            paymentType: action.payload||[]
        };
        case SYS_SET_INVOICE_TYPE:return{
            ...state,
            invoiceType: action.payload||[]
        };
        case SYS_SET_BRANCH_CODE:return{
            ...state,
            branchCode: action.payload||[]
        };
        case SYS_SET_TAX_CODE:return{
            ...state,
            taxCode: action.payload||[]
        };
        case SYS_SET_TAX_ITEM:return{
            ...state,
            taxItem: action.payload||[]
        };
        case SYS_SET_PAYMENT_TERM:return{
            ...state,
            paymentTerm: action.payload||[]
        };
        case "ERROR":return {...state};
        default:
            return state;
    }
}